import React, { useState, useEffect } from 'react';

import { Company } from '../../types/types';

import AddCompanyModal from './AddCompanyModal';
import AddCompanyBanner from './newAddCompanyBanner';
import useComponentVisible from '../../widgets/IsComponentVisible/isComponentVisible';
import MessageModal from '../../widgets/MessageModal/MessageModal';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_COMPANIES_TO_WATCHLIST, WATCHLISTS_QUERY } from '../../queries/Watchlist.queries';

interface AddCompanyContainerProps {
	company: Company;
}

enum Result {
	Success,
	Error
}

const AddCompanyContainer: React.FunctionComponent<AddCompanyContainerProps> = ({ company }) => {
	const {
		isComponentVisible: isModalVisible,
		setIsComponentVisible: setIsModalVisible
	} = useComponentVisible(false);
	const [isBannerVisible, setBannerVisible] = useState(false);
	const [result, setResult] = useState<Result>(undefined);
	const [addCompaniesMutation] = useMutation(ADD_COMPANIES_TO_WATCHLIST);
	const { data } = useQuery(WATCHLISTS_QUERY, {
		fetchPolicy: 'cache-and-network'
	});

	const watchlists = data?.watchlists;

	const handleAddPortfolioClick = () => {
		setBannerVisible(false);
		setIsModalVisible(true);
	};

	const addCompaniesToWatchlist = async (company: Company, watchlistList: string[]) => {
		try {
			await Promise.all(
				watchlistList.map(watchlistId =>
					addCompaniesMutation({
						variables: {
							id: watchlistId,
							companies: [company.id]
						}
					})
				)
			);

			setResult(Result.Success);
		} catch (e) {
			console.error(e);
			setResult(Result.Error);
		}
	};

	const handleSave = (watchlistIds: string[]) => {
		setIsModalVisible(false);

		addCompaniesToWatchlist(company, watchlistIds);
	};

	useEffect(() => {
		if (data && company) {
			const watchlistWithCompany = data.watchlists.find(
				watchlist =>
					!!watchlist.companyEntities?.find(existingCompany => company.id === existingCompany?.id)
			);
			setBannerVisible(!watchlistWithCompany);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [true, data]);

	if (!company) {
		return <div />;
	}

	if (!data) {
		return <div />;
	}

	return (
		<React.Fragment>
			<AddCompanyBanner
				company={company}
				addAction={handleAddPortfolioClick}
				close={() => setBannerVisible(false)}
				show={isBannerVisible}
			/>
			<AddCompanyModal
				isModalOpen={isModalVisible}
				save={handleSave}
				close={() => setIsModalVisible(false)}
				watchlists={watchlists}
			/>
			<MessageModal
				open={result === Result.Success}
				closeBtnText='Close'
				close={() => setResult(undefined)}
				text={`${company?.name} has been added to the selected watchlists`}
				type='success'
			/>
			<MessageModal
				open={result === Result.Error}
				closeBtnText='Close'
				close={() => setResult(undefined)}
				title='We are unable to update your watchlist'
				text='If the problem persists please contact support via the chat window'
				type='error'
			/>
		</React.Fragment>
	);
};

export default AddCompanyContainer;
