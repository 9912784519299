import * as React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import UploadFile from './uploadFile';
import UserTracking from '../../services/userTracking.service';
import { USER_TRACKING as UT, UPLOAD_LINK } from '../../constants';
import * as ids from '../../../id.constants.js';
import ModalWithHeader from '../../../components/ModalWithHeader/ModalWithHeader';

const UTC = UT.USER_TRACKING_CATEGORIES;
const UTA = UT.USER_TRACKING_ACTIONS;

const Content = styled.div`
	display: flex;
	height: 100%;
	padding: 20px;
	box-sizing: border-box;
`;

const LeftContainer = styled.div`
	height: 100%;
	width: 486px;
	margin-right: 20px;
`;

interface RightContainerProps {
	finished: boolean;
}
const RightContainer = styled.div<RightContainerProps>`
	height: 100%;
	width: ${props => (props.finished ? '100%' : '487px')};
`;

interface VideoContainerProps {
	src: string;
	width: string;
	height: string;
	frameborder: string;
	allow: string;
	allowfullscreen: boolean;
}
const VideoContainer = styled.iframe<VideoContainerProps>`
	width: 486px;
	height: 273px;
	border: 1px solid #dde5e7;
`;

const InstructionsContainer = styled.div`
	margin-bottom: 15px;
	color: #1f1e29;
`;

const InstructionsTitle = styled.div`
	font-size: 23px;
	font-weight: bold;
	font-family: Assistant SemiBold;
	line-height: 17px;
	height: 24px;
`;

const InstructionWrapper = styled.div`
	min-height: 26px;
	margin-top: 8px;
`;

const InstructionNumber = styled.div`
	height: 26px;
	width: 26px;
	line-height: 26px;
	text-align: center;
	float: left;
	margin-right: 10px;
	font-size: 13px;
	font-weight: bold;
	border-radius: 50%;
	background-color: #dde5e7;
`;

const InstructionText = styled.div`
	display: inline-block;
	width: calc(100% - 26px - 10px);
	font-size: 15px;
`;

const Bold = styled.span`
	font-weight: bold;
`;

interface BloombergModalProps {
	isExcelImport: boolean;
	isModalOpen: boolean;
	closeModal: Function;
	reloadPortfolio: Function;
	portfolioName?: string;
	portfolioId?: number | string;
	createWatchlist?: Function;
}

interface BloombergModalState {
	finished: boolean;
	uploadAnswer: any;
	uploadInProgress: boolean;
	closeEvent: boolean;
	portfolioIdUpload: number | string;
}

export default class BloombergModal extends React.Component<
	BloombergModalProps,
	BloombergModalState
> {
	constructor(props: BloombergModalProps) {
		super(props);
		Modal.setAppElement('#root');
		this.state = {
			uploadInProgress: false,
			finished: false,
			uploadAnswer: undefined,
			closeEvent: false,
			portfolioIdUpload: undefined
		};
	}

	close = () => {
		const { finished, uploadAnswer } = this.state;
		if (finished) {
			const { succeeded, failed } = uploadAnswer.data;
			const ut = new UserTracking();
			ut.setEvent(
				UTC.TICKER_UPLOAD,
				UTA.USER_EXITED_AFTER,
				`${succeeded} succeeded ${failed} failed`
			);
			this.closeAndReload();
		} else {
			this.setState({ closeEvent: true, finished: false }, () => {
				setTimeout(() => {
					this.setState({ closeEvent: false, uploadAnswer: undefined });
				}, 200);
			});
			const ut = new UserTracking();
			ut.setEvent(UTC.TICKER_UPLOAD, UTA.USER_EXITED_BEFORE);
			this.props.closeModal();
		}
	};

	toggleFinished = (isFinished: boolean, uploadAnswer?: any, portfolioId?: number | string) => {
		this.setState({
			uploadAnswer,
			finished: isFinished,
			portfolioIdUpload: portfolioId
		});
	};

	closeAndReload = () => {
		this.setState({ closeEvent: true, finished: false }, () => {
			setTimeout(() => {
				this.setState({ closeEvent: false, uploadAnswer: undefined });
			}, 200);
		});
		this.props.reloadPortfolio(this.state.portfolioIdUpload);
		this.props.closeModal();
	};

	render() {
		const { finished, closeEvent } = this.state;
		const modalStyle = {
			content: {
				width: finished ? '696px' : '1034px',
				height: '528px'
			}
		};
		return (
			<ModalWithHeader
				title='Import Your Watchlist'
				isModalOpen={this.props.isModalOpen}
				close={this.close}
				modalStyle={modalStyle}
			>
				<Content>
					{!finished && (
						<LeftContainer>
							<InstructionsContainer>
								<InstructionsTitle>Import Instructions</InstructionsTitle>
								<InstructionWrapper>
									<InstructionNumber>1</InstructionNumber>
									{this.props.isExcelImport && (
										<InstructionText id={ids.BLOOMBERG_MODAL.FIRST_INSTRUCTION_EXCEL}>
											Create an excel file containing the tickers to be uploaded, according to the
											instructions in the video below.
										</InstructionText>
									)}
									{!this.props.isExcelImport && (
										<InstructionText id={ids.BLOOMBERG_MODAL.FIRST_INSTRUCTION_BLOOMBERG}>
											In <Bold>Bloomberg Terminal</Bold> export your watchlist, according to the
											instructions in the video below.
										</InstructionText>
									)}
								</InstructionWrapper>
								<InstructionWrapper>
									<InstructionNumber>2</InstructionNumber>
									<InstructionText>
										<Bold>Drag and drop</Bold> the file to the box on the right.
									</InstructionText>
								</InstructionWrapper>
								<InstructionWrapper>
									<InstructionNumber>3</InstructionNumber>
									<InstructionText>The file will be automatically imported.</InstructionText>
								</InstructionWrapper>
							</InstructionsContainer>
							<VideoContainer
								id={
									this.props.isExcelImport
										? ids.BLOOMBERG_MODAL.VIDEO_EXCEL
										: ids.BLOOMBERG_MODAL.VIDEO_BLOOMBERG
								}
								src={this.props.isExcelImport ? UPLOAD_LINK.EXCEL : UPLOAD_LINK.BLOOMBERG}
								width='486'
								height='273'
								frameborder='0'
								allow='autoplay; fullscreen'
								allowfullscreen
							/>
						</LeftContainer>
					)}
					<RightContainer finished={finished}>
						<UploadFile
							portfolioName={this.props.portfolioName}
							portfolioId={this.props.portfolioId}
							reload={this.props.reloadPortfolio}
							close={this.closeAndReload}
							closeEvent={closeEvent}
							toggleFinish={this.toggleFinished}
							isExcelImport={this.props.isExcelImport}
							createWatchlist={this.props.createWatchlist}
						/>
					</RightContainer>
				</Content>
			</ModalWithHeader>
		);
	}
}
