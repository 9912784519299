import * as React from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import _ from 'lodash';
import { Button } from '@amenityllc/amenity-components';

import { Credentials, MobileNotSupportedMessage } from '../../types/types';
import { STORE_AUTH, USER_TRACKING as UT } from '../../constants';
import Images from '../../shared/images';
import {
	capitalizeFirstLetter,
	isPasswordStrong,
	isEmailValid,
	getParamsFromURL,
	checkEmailForSymbols
} from '../../services/util.service';
import AuthStore from '../../stores/auth.store';
import {
	InputContainer,
	ValidationError
} from '../../shared/styled/authentication-pages-components';
import NavigationService from '../../services/navigation.service';
import SalesforceSrv from '../../services/salesforce.service';
import { error } from '../../services/logger.service';

import { Logo } from '../../../components/Logo/Logo';
import { Input, InputType } from '../../../components/Input/Input';
import { LogoAppearance, Colors, Sizes } from '../../../components/shared/styles';
import signupBackground from '../../../assets/images/signup_background.jpg';

const UTA = UT.USER_TRACKING_ACTIONS;

const LeftSide = styled.div`
	position: relative;
	display: inline-block;
	width: 43%;
	height: 100%;
	@media screen and (max-width: 991px) {
		width: 50%;
	}
	@media screen and (max-width: 767px) {
		width: 100%;
	}
`;

const RightSide = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: 57%;
	height: 100%;
	background-color: rgba(0, 174, 239, 0.15);
	@media screen and (max-width: 991px) {
		width: 50%;
	}
	@media screen and (max-width: 767px) {
		display: none;
	}
`;

const RightSideBg = styled.div`
	width: 100%;
	height: 100%;
	background-image: url(${signupBackground});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
`;

const LeftContainer = styled.div`
	position: absolute;
	left: 50%;
	margin: auto;
	width: calc(100% - 120px);
	top: 50%;
	margin-top: -260px;
	margin-left: calc(60px - 50%);
	font-family: Assistant;
	@media screen and (max-width: 767px) {
		width: calc(100% - 80px);
		margin-left: calc(40px - 50%);
	}
`;

const LastInputContainer = styled(InputContainer)`
	margin-bottom: 17px;
`;

const SubmitBtn = styled(Button)<{ success: boolean }>`
	margin-top: 5px;
	margin-bottom: 15px;
`;

interface TitleProps {
	bold?: boolean;
	marginBottom: number;
}
const Title = styled.div<TitleProps>`
	font-size: 30px;
	font-family: ${props => (props.bold ? 'Assistant Bold' : 'Assistant')};
	color: #274877;
	line-height: 1.13;
	margin-bottom: ${props => props.marginBottom}px;
`;

const SubTitle = styled.div`
	font-size: 18px;
	color: #274877;
	margin-bottom: 19px;
`;

const Container = styled.div`
	background-color: ${Colors.white};
	width: 100%;
	height: 100%;
	box-shadow: 0px 5px 4.8px 0.3px;
`;

const StyledLogo = styled(Logo)`
	position: absolute;
	top: 4%;
	left: 15%;
`;

const SubContainer = styled.div`
	position: relative;
	width: 73%;
	height: 84%;
	margin: auto;
	top: 10%;
	z-index: 20;
	border: 1px solid ${Colors.lightGray};
	border-radius: 4px;
	background-color: #fff;
	box-shadow: 0 29px 36px 0 ${Colors.darkGray};
	@media screen and (max-width: 767px) {
		height: 85%;
	}
`;
const PaddingWrapper = styled.div`
	padding: 0 3px;
`;

const CheckboxWrap = styled.div`
	margin-bottom: 11px;
`;

interface CheckboxInputProps {
	error?: boolean;
	disabled: boolean;
}
const CheckboxInput = styled.input<CheckboxInputProps>`
	margin: 0;
	opacity: ${props => (props.disabled ? '0.3' : '1')};
	outline: ${props => (props.error ? '1px solid #d23f31' : 'none')};
	outline-offset: -1px;
`;

const CheckboxLabel = styled.span`
	font-size: 13px;
	padding-left: 5px;
	a {
		text-decoration: none;
		color: #0052cc;
	}
`;

const TextWithImg = styled.div`
	> img,
	> span {
		vertical-align: middle;
	}
	> span {
		margin-left: 4px;
	}
`;

const LoginLink = styled.div`
	font-size: 14px;
	opacity: 0.7;
	text-align: center;
	> a {
		text-decoration: none;
	}
`;

interface State {
	email: string;
	firstName: string;
	lastName: string;
	password1: string;
	password2: string;
	termsCheck: boolean;
	privacyPolicyCheck: boolean;
	notifCheck: boolean;
	lengthErr: boolean;
	strengthErr: boolean;
	noMatchErr: boolean;
	missingPassword1Err: boolean;
	missingPassword2Err: boolean;
	serverErrorMsg: string;
	missingEmailErr: boolean;
	invalidEmailErr: boolean;
	termsErr: boolean;
	privacyPolicyErr: boolean;
	success: boolean;
}

@inject(STORE_AUTH)
@observer
export default class SignUpPage extends React.Component<{}, State> {
	navService;
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			firstName: '',
			lastName: '',
			password1: '',
			password2: '',
			termsCheck: false,
			privacyPolicyCheck: false,
			notifCheck: true,
			lengthErr: false,
			strengthErr: false,
			noMatchErr: false,
			missingPassword1Err: false,
			missingPassword2Err: false,
			serverErrorMsg: '',
			missingEmailErr: false,
			invalidEmailErr: false,
			termsErr: false,
			privacyPolicyErr: false,
			success: false
		};

		this.navService = NavigationService.instance;
	}

	componentWillMount() {
		const params = getParamsFromURL(window.location.href);
		const email = params['email'];
		if (email && checkEmailForSymbols(email)) {
			params['email'] = email.replace('%40', '@');
		}

		this.setState({
			email: params['email'] ? params['email'] : '',
			firstName: params['firstname'] || params['firstName'] || '',
			lastName: params['lastname'] || params['lastName'] || ''
		});
	}

	submit = () => {
		const { email, password1, firstName, lastName, termsCheck, privacyPolicyCheck } = this.state;
		this.setState({
			lengthErr: false,
			strengthErr: false,
			noMatchErr: false,
			missingPassword1Err: false,
			missingPassword2Err: false,
			missingEmailErr: false,
			invalidEmailErr: false,
			termsErr: false,
			privacyPolicyErr: false,
			serverErrorMsg: ''
		});
		if (!email || email === '') {
			this.setState({ missingEmailErr: true });
			return;
		}
		if (!isEmailValid(email)) {
			this.setState({ invalidEmailErr: true });
			return;
		}
		if (this.state.password1 === '') {
			this.setState({ missingPassword1Err: this.state.password1 === '' });
			return;
		}
		if (this.state.password2 === '') {
			this.setState({ missingPassword2Err: this.state.password2 === '' });
			return;
		}
		if (this.state.password2 !== this.state.password1) {
			this.setState({ noMatchErr: true });
			return;
		}
		if (this.state.password1 !== '' && this.state.password1.length < 8) {
			this.setState({ lengthErr: true });
			return;
		}
		if (this.state.password1 !== '' && !isPasswordStrong(this.state.password1)) {
			this.setState({ strengthErr: true });
			return;
		}
		if (!termsCheck) {
			this.setState({ termsErr: true });
			return;
		}
		if (!privacyPolicyCheck) {
			this.setState({ privacyPolicyErr: true });
			return;
		}

		const credentials: Credentials = {
			username: email,
			password: password1,
			firstname: firstName,
			lastname: lastName
		};
		(this.props[STORE_AUTH] as AuthStore).signup(credentials, UTA.SET_PASSWORD).then(
			() => {
				if (this.navService.isMobile()) {
					this.navService.goToMobileNotSupported(MobileNotSupportedMessage.NOT_SUPPORTED_SIGNUP);
					return;
				}
				if (this.navService.isIE()) {
					this.navService.goToNotSupported(true);
					return;
				}
				this.sendSalesforceContact();
				this.setState({ success: true }, () =>
					(this.props[STORE_AUTH] as AuthStore).universalLogin()
				);
			},
			err => {
				error({
					message: `Error while signing up: ${JSON.stringify(err)}`,
					file: 'setPasswordExternal',
					functionName: 'submit'
				});
				const serverError = err.message
					? capitalizeFirstLetter(err.message)
					: 'Something went wrong';
				this.setState({ serverErrorMsg: serverError });
			}
		);
	};

	login = () => {
		let credentials: Credentials = {
			username: this.state.email,
			password: this.state.password1
		};
		this.props[STORE_AUTH].login(credentials).then(
			() => {
				credentials = undefined;
				this.setState({ password1: '', password2: '' });
				this.navService.goToRoute('');
			},
			err =>
				error({
					message: `Error logging in: ${JSON.stringify(err)}`,
					file: 'setPasswordExternal',
					functionName: 'login'
				})
		);
	};

	goToLoginPage = () => {
		this.navService.goToLogin();
	};

	googleSignUp() {
		this.props[STORE_AUTH].googleLogin('signup');
	}

	setEmail(e: any) {
		this.setState({ email: e.target.value });
	}

	setPassword(e: any) {
		this.setState({ password1: e.target.value });
	}

	setVerifyPassword(e: any) {
		this.setState({ password2: e.target.value });
	}

	sendSalesforceContact = () => {
		const sfSrv = new SalesforceSrv();
		const contactData = {
			email: this.state.email,
			firstName: this.state.firstName,
			lastName: this.state.lastName !== '' ? this.state.lastName : 'NOT_ENTERED',
			sendNotifications: this.state.notifCheck
		};
		sfSrv.sendContactOnSignup(contactData);
	};

	handleCheckbox = (event: any) => {
		const target = event.target;
		const value: boolean = target.checked;
		const name = target.name;
		const state = {};
		state[name] = value;
		this.setState(state);
	};

	passwordError = () => {
		const {
			missingPassword2Err,
			noMatchErr,
			missingPassword1Err,
			lengthErr,
			strengthErr
		} = this.state;
		return missingPassword2Err || noMatchErr || missingPassword1Err || lengthErr || strengthErr;
	};

	submitDisabled = () => {
		const { password1, password2 } = this.state;
		return _.isEmpty(password1) || _.isEmpty(password2);
	};

	render() {
		const { firstName } = this.state;
		return (
			<Container>
				<StyledLogo logoAppearance={LogoAppearance.DarkGroup} />
				<SubContainer>
					<LeftSide>
						<LeftContainer>
							{firstName ? (
								<Title bold marginBottom={5}>{`Hi ${this.state.firstName},`}</Title>
							) : (
								undefined
							)}
							<Title marginBottom={26}>Welcome to Amenity Insights Platform</Title>
							<PaddingWrapper>
								<SubTitle>Setup your password</SubTitle>
								<form onSubmit={e => e.preventDefault()}>
									<InputContainer>
										<Input
											id='PASSWORD1'
											type={InputType.password}
											value={this.state.password1}
											placeholder='Password'
											onChange={this.setPassword.bind(this)}
											autoComplete={false}
											disabled={this.state.success}
											error={this.passwordError()}
										/>
									</InputContainer>
									<LastInputContainer>
										<Input
											id='PASSWORD2'
											type={InputType.password}
											value={this.state.password2}
											placeholder='Retype Password'
											onChange={this.setVerifyPassword.bind(this)}
											autoComplete={false}
											disabled={this.state.success}
											error={this.passwordError()}
										/>
										{this.state.missingPassword2Err && (
											<ValidationError>Please enter a confirmation password</ValidationError>
										)}
										{this.state.noMatchErr && (
											<ValidationError>Passwords do not match</ValidationError>
										)}
										{this.state.serverErrorMsg !== '' && (
											<ValidationError>{this.state.serverErrorMsg}</ValidationError>
										)}
										{this.state.missingEmailErr && (
											<ValidationError>Please enter email</ValidationError>
										)}
										{this.state.invalidEmailErr && (
											<ValidationError>Please enter a valid email address</ValidationError>
										)}
										{this.state.missingPassword1Err && (
											<ValidationError>Please enter password</ValidationError>
										)}
										{this.state.lengthErr && (
											<ValidationError>
												Password should be at least 8 characters in length
											</ValidationError>
										)}
										{this.state.termsErr && (
											<ValidationError>You must accept the terms and conditions</ValidationError>
										)}
										{this.state.privacyPolicyErr && (
											<ValidationError>You must accept the privacy policy</ValidationError>
										)}
										{this.state.strengthErr && (
											<ValidationError>
												Should contain: lower case letters (a-z), upper case letters (A-Z), numbers
												(i.e. 0-9)
											</ValidationError>
										)}
									</LastInputContainer>
									<CheckboxWrap>
										<CheckboxInput
											id='TERMS_CHECKBOX'
											type='checkbox'
											name='termsCheck'
											checked={this.state.termsCheck}
											onChange={this.handleCheckbox}
											disabled={this.state.success}
											error={this.state.termsErr}
										/>
										<CheckboxLabel>
											I certify that I agree to the{' '}
											<a
												href='https://www.amenityanalytics.com/page/terms-and-conditions'
												target='_blank'
												rel='noopener noreferrer'
											>{`terms & conditions`}</a>
										</CheckboxLabel>
									</CheckboxWrap>
									<CheckboxWrap>
										<CheckboxInput
											id='PRIVACY_POLICY'
											type='checkbox'
											name='privacyPolicyCheck'
											checked={this.state.privacyPolicyCheck}
											onChange={this.handleCheckbox}
											disabled={this.state.success}
											error={this.state.privacyPolicyErr}
										/>
										<CheckboxLabel>
											I consent to the processing of my personal data in accordance with the{' '}
											<a
												href='https://www.amenityanalytics.com/terms-and-conditions'
												target='_blank'
												rel='noopener noreferrer'
											>{`Privacy Policy`}</a>
										</CheckboxLabel>
									</CheckboxWrap>
									<CheckboxWrap>
										<CheckboxInput
											id='NOTIF_CHECKBOX'
											type='checkbox'
											name='notifCheck'
											checked={this.state.notifCheck}
											onChange={this.handleCheckbox}
											disabled={this.state.success}
										/>
										<CheckboxLabel>
											I’m interested in receiving premium market commentary from Amenity via email.
										</CheckboxLabel>
									</CheckboxWrap>
									<SubmitBtn
										size={Sizes.large}
										id='SUBMIT_BTN'
										onClick={this.submit}
										success={this.state.success}
										disabled={this.submitDisabled()}
									>
										{this.state.success ? (
											<TextWithImg>
												<img alt='' src={Images.check} />
												<span>Password set</span>
											</TextWithImg>
										) : (
											'Set password'
										)}
									</SubmitBtn>
								</form>
								<LoginLink>
									Already have an account?{' '}
									<a href='/' onClick={this.goToLoginPage}>
										Log in
									</a>
								</LoginLink>
							</PaddingWrapper>
						</LeftContainer>
					</LeftSide>
					<RightSide>
						<RightSideBg />
					</RightSide>
				</SubContainer>
			</Container>
		);
	}
}
