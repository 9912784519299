import * as React from 'react';
import styled from 'styled-components';

import Images from '../../shared/images';
import { APP_COLORS } from '../../constants';
import NavigationService from '../../services/navigation.service';
import { Colors } from '../../../components/shared/styles';

const NotFoundContainer = styled.div`
	width: 415px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const ImageContainer = styled.div`
	width: 200px;
	margin: 0 auto;
`;

const TextContainer = styled.div`
	margin-top: 18px;
	width: 100%;
	color: ${APP_COLORS.LIGHT_GRAY_DDE5E7};
	font-size: 16px;
	font-weight: bold;
	text-align: center;
`;

interface TextProps {
	color?: string;
	underline?: boolean;
	pointer?: boolean;
}
const Text = styled.span<TextProps>`
	color: ${props => (props.color ? props.color : 'inherit')};
	text-decoration: ${props => (props.underline ? 'underline' : 'initial')};
	cursor: ${props => (props.pointer ? 'pointer' : 'initial')};
`;

export default class NotFoundPage extends React.Component<{}, {}> {
	navService: NavigationService;

	constructor(props) {
		super(props);
		this.navService = NavigationService.instance;
	}

	goToRootPage = () => {
		this.navService.goToRoot();
	};

	render() {
		return (
			<NotFoundContainer id='PAGE_NOT_FOUND_CONTAINER'>
				<ImageContainer>
					<img alt='' src={Images.notFound404} />
				</ImageContainer>
				<TextContainer>
					<Text>Could not find the requested document</Text>
					<br />
					<Text>Try searching for the document on your </Text>
					<Text color={Colors.blue} underline pointer onClick={() => this.goToRootPage()}>
						Watchlist Page
					</Text>
				</TextContainer>
			</NotFoundContainer>
		);
	}
}
