import { action, computed, observable, runInAction } from 'mobx';
import * as _ from 'lodash';
import jwt from 'jsonwebtoken';

import { LOCAL_STORAGE_KEYS, USER_TRACKING as UT } from '../constants';
import { Credentials, Features, Profile } from '../types/types';

import AuthSrv from '../services/auth.service';
import { userLogId, getDomainFromEmail } from '../services/util.service';
import { info, error } from '../services/logger.service';
import IntercomService from '../services/intercom.service';
import UserTracking from '../services/userTracking.service';
import SalesforceService from '../services/salesforce.service';
import * as localStorageSrv from '../services/localstorage.service';

const UTC = UT.USER_TRACKING_CATEGORIES;
const UTA = UT.USER_TRACKING_ACTIONS;
const UTL = UT.USER_TRACKING_LABELS;

export default class AuthStore {
	@observable token: string;
	@observable profile: Profile;
	@observable userFeaturePermissions: Features;
	afterLogin: boolean = false;

	private static instance: AuthStore;

	static getInstance() {
		return AuthStore.instance || new AuthStore();
	}

	constructor() {
		if (AuthStore.instance) {
			return AuthStore.instance;
		}
		AuthStore.instance = this;

		this.token = localStorageSrv.getString(LOCAL_STORAGE_KEYS.TOKEN);
		this.profile = {
			username: localStorageSrv.getString(LOCAL_STORAGE_KEYS.USERNAME),
			fullName: localStorageSrv.getString(LOCAL_STORAGE_KEYS.USER_FULL_NAME),
			createdAt: +localStorageSrv.getString(LOCAL_STORAGE_KEYS.USER_CREATED_AT),
			role: localStorageSrv.getString(LOCAL_STORAGE_KEYS.ROLE),
			sub: localStorageSrv.getString(LOCAL_STORAGE_KEYS.SUB),
			salesforceAccountId: localStorageSrv.getString(LOCAL_STORAGE_KEYS.SF_ACCOUNT_ID)
		};
		if (this.profile.username) {
			const ut = new UserTracking();
			ut.identify(this.getIdentityObject(this.profile));
		}
	}

	@action
	setUserFeaturePermissions = (data: any) => {
		this.userFeaturePermissions = data;
	};

	@action
	setToken = (value: string) => {
		this.token = value;
		localStorageSrv.setString(LOCAL_STORAGE_KEYS.TOKEN, this.token);
	};

	@action
	setProfile = (
		username: string,
		role: string,
		sub: string,
		firstName: string,
		lastName: string,
		createdAt: number,
		intercom_hash?: string,
		readmeio_token?: string
	) => {
		const fullName = this.getFullNameOrEmail(firstName, lastName, username);
		const icomSrv = new IntercomService();
		const ut = new UserTracking();
		this.profile = {
			username,
			createdAt,
			fullName,
			firstName,
			lastName,
			role,
			sub
		};
		localStorageSrv.setString(LOCAL_STORAGE_KEYS.USERNAME, this.profile.username);
		localStorageSrv.setString(LOCAL_STORAGE_KEYS.USER_FULL_NAME, this.profile.fullName);
		localStorageSrv.setString(
			LOCAL_STORAGE_KEYS.USER_CREATED_AT,
			this.profile.createdAt.toString()
		);
		localStorageSrv.setString(LOCAL_STORAGE_KEYS.ROLE, this.profile.role);
		localStorageSrv.setString(LOCAL_STORAGE_KEYS.SUB, this.profile.sub);
		localStorageSrv.setString(LOCAL_STORAGE_KEYS.INTERCOM_USER_HASH, intercom_hash);
		localStorageSrv.setString(LOCAL_STORAGE_KEYS.READMEIO_TOEKN, readmeio_token);
		const sfSrv = new SalesforceService();
		sfSrv.sendContactOnLogin({ email: this.profile.username }, res => {
			this.profile.salesforceAccountId = _.get(res, 'accountId', '');
			localStorageSrv.setString(LOCAL_STORAGE_KEYS.SF_ACCOUNT_ID, this.profile.salesforceAccountId);
			ut.identify(this.getIdentityObject(this.profile));
		});

		icomSrv.boot();
	};

	getIdentityObject = (profile: Profile) => ({
		email: profile?.username,
		domain: getDomainFromEmail(profile?.username || ''),
		sub: profile?.sub,
		salesforceAccountId: profile?.salesforceAccountId
	});

	@computed
	get isAuthenticated(): boolean {
		if (this.token) {
			const decoded = jwt.decode(this.token, { complete: true });
			// @ts-ignore
			return decoded.payload.exp > Date.now() / 1000;
		}
		return false;
	}

	@computed
	get getUsername(): string {
		if (this.profile) {
			return this.profile.username;
		}
		return '';
	}

	@computed
	get isAdmin(): boolean {
		return this.profile && this.profile.role && this.profile.role === 'admin';
	}

	@action
	async login(user: Credentials) {
		const functionName = 'login';
		const ut = new UserTracking();
		try {
			const authSrv = new AuthSrv();
			const response: any = await authSrv.externalLogin(user);
			runInAction('Update User data', () => {
				this.setToken(response.idToken);
				const { role, sub, createdAt, first_name, last_name } = response;
				this.setProfile(user.username, role, sub, first_name, last_name, createdAt);
				info({
					message: 'Logged in successfully',
					file: 'AuthStore',
					functionName
				});
			});
			ut.setEvent(UTC.USER, UTA.LOGIN, UTL.SUCCESS);
		} catch (e) {
			ut.setEvent(UTC.USER, UTA.LOGIN, UTL.FAIL);
			// if has sub in message - the error is from auth 0 rule
			const errorMessage: string = e.message.sub ? JSON.parse(e.message).error : e;
			error({
				message: `Error logging in: ${errorMessage}`,
				file: 'AuthStore',
				functionName
			});
			throw e;
		}
	}

	@action
	getFullNameOrEmail = (firstName: string, lastName: string, email: string) => {
		let res = '';
		if (firstName) {
			res += firstName;
		}
		if (lastName) {
			if (res.length > 0) {
				res += ' ';
			}
			res += lastName;
		}
		if (res.length > 0) {
			return res;
		}
		return email;
	};

	@action
	async googleLogin(origin?: string) {
		try {
			const authSrv = new AuthSrv();
			if (origin) {
				localStorageSrv.setString(LOCAL_STORAGE_KEYS.GOOGLE_SIGNUP_ORIGIN, origin);
			}
			authSrv.externalLoginWithGoogle();
		} catch (e) {
			throw e;
		}
	}

	@action
	async universalLogin() {
		try {
			const authSrv = new AuthSrv();
			authSrv.externalLoginUniversal();
		} catch (e) {
			throw e;
		}
	}

	@action
	async signup(user: Credentials, eventAction: string) {
		const ut = new UserTracking();
		try {
			const authSrv = new AuthSrv();
			const response: any = await authSrv.externalSignUp(user);
			if (response.Id && response.email) {
				ut.identify(
					this.getIdentityObject({ username: response.email, createdAt: new Date().getDate() })
				);
				ut.setEvent(
					UTC.USER,
					eventAction,
					`${UTL.SUCCESS}_${userLogId(response.email, 'auth0|' + response.Id)}`
				);
				return response;
			}
		} catch (e) {
			ut.setEvent(UTC.USER, eventAction, UTL.FAIL);
			throw e;
		}
	}

	@action
	async logout() {
		const ut = new UserTracking();
		const authSrv = new AuthSrv();
		const icomSrv = new IntercomService();
		localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
		localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.USERNAME);
		localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.USER_FULL_NAME);
		localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.USER_CREATED_AT);
		localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.ROLE);
		localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.SUB);
		localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.EVENTS_SEARCH_DATA);
		localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.EVENTS_SEARCH_RESULTS);
		localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.IS_REFRESH);
		localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.PREV_VISITED_PAGE);
		localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.CURRENT_VISITED_PAGE);
		localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.DIRECT_LINK);
		localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.DOCUMENT_META_DATA);
		localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.SF_ACCOUNT_ID);
		localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.INTERCOM_USER_HASH);
		localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.READMEIO_TOEKN);
		authSrv.externalLogout();
		ut.setEvent(UTC.USER, UTA.LOGOUT, '');
		icomSrv.shutdown();
	}

	@action
	async changePassword(email) {
		const authSrv = new AuthSrv();
		const response = await authSrv.resetPassword(email);
		return response;
	}

	@computed
	get userHasAnalyticsPermissions(): boolean {
		return this.userFeaturePermissions && this.userFeaturePermissions.analytics_dashboard;
	}

	@computed
	get userProfile(): any {
		return this.profile;
	}

	@computed
	get userAttributes(): { email: string; domain: string } {
		const { username } = this.profile;
		return {
			email: username,
			domain: getDomainFromEmail(username)
		};
	}
}
