import DataService from './data.service';
import { error } from './logger.service';
import * as Util from './util.service';
import * as Types from '../types/types';

export default class WatchlistService {
	private static instance: WatchlistService;
	private static dataService: DataService;

	constructor() {
		if (!WatchlistService.instance) {
			WatchlistService.instance = this;
			WatchlistService.dataService = DataService.getInstance();
		}
		return WatchlistService.instance;
	}

	static getInstance() {
		return WatchlistService.instance || new WatchlistService();
	}

	fetchUserCompanies = (portfolioId: string | number): Promise<Types.SymbologyCompany[]> =>
		new Promise((resolve, reject) => {
			const params = { portfolioId };
			WatchlistService.dataService.getPortfolioUserCompanies(
				params,
				(companies: Types.SymbologyCompany[]) => {
					resolve(companies);
				},
				(err: any) => {
					error({
						message: `Failed fetching all user companies`,
						functionName: 'fetchUserCompanies',
						file: 'watchlist.service'
					});
					reject(err);
				}
			);
		});

	fetchCompaniesData = (
		flow: Types.ModelFlow,
		documentType: string,
		factsetIds: string[]
	): Promise<Types.PortfolioCompany[]> =>
		new Promise((resolve, reject) => {
			WatchlistService.dataService.getCompaniesData(
				{ flow, documentType, factsetIds },
				res => {
					resolve(Util.parsePortfolio(res));
				},
				(err: any) => {
					error({
						message: `Failed fetching companies data`,
						functionName: 'fetchCompaniesData',
						file: 'watchlist.service'
					});
					reject(err);
				}
			);
		});
}
