import { WebAuth } from 'auth0-js';
import { DocumentTypeCategoryLegacy } from './DocTypes.types';

export interface Credentials {
	username: string;
	password: string;
	firstname?: string;
	lastname?: string;
	webAuth?: WebAuth;
}

export interface ProviderCompanyId {
	providerId: number;
	amenityProviderCompanyId: string;
	providerDisplayName?: string;
}

export interface Company {
	id: number | string;
	name: string;
	ticker: string;
	region: string;
	companyIds?: ProviderCompanyId[];
	isInPortfolio?: boolean;
	factsetId?: string;
}

export interface Ticker {
	ticker: string;
	region: string;
}

export interface SymbologyCompany {
	id: string;
	name: string;
	ticker: string;
	region: string;
}

export interface Organization {
	id: number;
	domain: string;
	client_id: number;
	token: string;
	collection_name?: string;
	features: Features;
}

export interface Features {
	analytics_dashboard: boolean;
}

// Add any additional feature flags with prefix - viewer
export interface SplitFeatures {
	viewer_heatmap: boolean;
	viewer_news_article: boolean;
	viewer_playground: boolean;
	viewer_newarc_migration: boolean;
	viewer_document_type_flow_history: boolean;
	viewer_document_filters: boolean;
	viewer_collapse_all_rows: boolean;
	viewer_tickers_collapsed_mode: boolean;
	viewer_dtc_email: boolean;
	viewer_sec_filings_v2: boolean;
}

// Generic interface mapping feature name to a boolean value
export interface FeatureDictionary {
	[Key: string]: boolean;
}

export interface PortfolioCompany extends Company {
	documents: Document[];
	latestDocument: Document;
	sector: string;
}

export interface Driver {
	category_name: string;
	change: number;
}

export class DriverTotals {
	category_name: string;
	total_pos: number;
	total_neg: number;
	total_neutral: number;

	constructor(category_name: string) {
		this.category_name = category_name;
		this.total_pos = 0;
		this.total_neg = 0;
		this.total_neutral = 0;
	}
}

export class Document {
	publicationDate: Date;
	title: string;
	documentId?: number;
	total_daily_positives?: number;
	total_daily_negatives?: number;
	total_daily_sentiment_score?: number;
	deception_score?: number;
	analysisDate?: Date;
	retrievalDate?: Date;
	userEvents?: Event[];
	systemEvents?: Event[];
	allEvents?: Event[];
	eventDate?: Date;
	vendor?: string;
	driver_totals?: DriverTotals[];
	flowId?: number;
	documentType?: string;
	fiscalQuarter?: string;
	fiscalYear?: string;
	documentDisplayName?: string;
	source?: string;
	sourceUrl?: string;
	mediaType?: string;
	constructor(
		documentId: number,
		total_daily_positives: number,
		total_daily_negatives: number,
		total_daily_sentiment_score: number,
		analysisDate: Date,
		publicationDate: Date,
		retrievalDate: Date,
		title: string,
		userEvents: Event[],
		systemEvents: Event[],
		allEvents: Event[],
		eventDate: Date,
		vendor: string,
		deception_score: number,
		driver_totals: DriverTotals[],
		flowId?: number,
		documentType?: string,
		fiscalQuarter?: string,
		fiscalYear?: string,
		documentDisplayName?: string
	) {
		this.documentId = documentId;
		this.total_daily_positives = total_daily_positives;
		this.total_daily_negatives = total_daily_negatives;
		this.total_daily_sentiment_score = total_daily_sentiment_score;
		this.analysisDate = analysisDate;
		this.publicationDate = publicationDate;
		this.retrievalDate = retrievalDate;
		this.title = title;
		this.userEvents = userEvents;
		this.systemEvents = systemEvents;
		this.allEvents = allEvents;
		this.eventDate = eventDate;
		this.vendor = vendor;
		this.deception_score = deception_score;
		this.driver_totals = driver_totals || [];
		this.flowId = flowId;
		this.documentType = documentType;
		this.fiscalQuarter = fiscalQuarter;
		this.fiscalYear = fiscalYear;
		this.documentDisplayName = documentDisplayName;
	}
}

export enum Polarity {
	negative,
	positive,
	neutral
}

export enum Status {
	new,
	ignored,
	embedded,
	in_evaluation
}

export interface UserCompanies {
	has_data: boolean;
	companies: Company[];
}

export interface ModelFlow {
	id: number;
	clientId: number;
	modelId: number;
	modelName: string;
	modelVersion: string;
	dataSourceIds: string[];
	dataSourcesDetails: DataSource[];
	startDate: string;
	modelDisplayName: string;
}

type Vendor = 'FactSet' | 'S&P';
export type DocumentTypeName =
	| '10-Q'
	| '10-K'
	| '8-K'
	| 'Sales and Revenue Call'
	| 'Earnings Call'
	| 'Presentation (Investor conference)'
	| 'Special Situation (like M&A)'
	| 'Guidance Call'
	| 'Analyst'
	| 'News'
	| 'Company Conference Presentations'
	| 'Shareholder/Analyst Calls'
	| 'Analyst/Investor Day'
	| 'Special Calls'
	| 'M&A Calls'
	| 'Sales/Trading Statement Calls'
	| 'Guidance/Update Calls'
	| 'Interim Management Statement Calls'
	| 'Operating Results Calls'
	| 'Fixed Income Calls';

export interface DataSource {
	dataSourceName: string;
	providerId: number;
	providerName: Vendor;
	documentTypeId: number;
	documentTypeName: DocumentTypeName;
}

export interface UserDocumentTypesCategories {
	transcripts: DocumentTypeCategoryLegacy;
	secFilings: DocumentTypeCategoryLegacy;
}

export interface DropDownItem {
	title: string;
	subtitle?: string;
	id: number | string;
	searchItem?: string; // Searches in the drop down are done on this property
	originalIndex?: number;
}

export class Event {
	id?: number;
	eventId?: string;
	startIndex: number;
	endIndex: number;
	startIndexAPI?: number;
	endIndexAPI?: number;
	text: string;
	polarity: number;
	typeName: string;
	transcriptId: number;
	categoryName: string;
	documentViewDescription?: string;
	description: string;
	isSubsection?: boolean;
	section?: string;
	isUserDefined?: boolean;
	hide?: boolean;
	createdAt?: Date;
	userName?: string;
	status?: number;
	flow?: number;
	documentTitle?: string;
	clientName?: string;
	indexInTranscript?: number;
	systemEventId?: string;
	sentence?: string; // Full sentence which contains the event
	ticker?: string;
	region?: string;
	countOfSimilarEvents?: number;
	similarityScore?: number;
	fullSimilarityInfo?: SimilarEventsInfo;
	paragraph?: Paragraph;
	keyDrivers?: string[];
	name?: string;

	constructor(
		id: number,
		startIndex: number,
		endIndex: number,
		text: string,
		polarity: number,
		typeName: string,
		transcriptId: number,
		categoryName: string,
		description: string,
		isUserDefined: boolean,
		sentence: string,
		keyDrivers?: string[]
	) {
		this.id = id;
		this.startIndex = startIndex;
		this.endIndex = endIndex;
		this.text = text;
		this.polarity = polarity;
		this.typeName = typeName;
		this.transcriptId = transcriptId;
		this.categoryName = categoryName;
		this.description = description;
		this.isUserDefined = isUserDefined;
		this.sentence = sentence;
		this.keyDrivers = keyDrivers;
	}
}

export interface SimilarEventEntry {
	eventText: string;
	eventType: string;
	companyName: string;
	documentType: string;
	documentGroupId: number;
	flowId: number;
	offsetStartInText: number;
	polarity: string;
	publicationDate: string;
	sentence: string;
	ticker: string;
	sequenceNumber: number;
}

export interface SimilarEventsInfo {
	resultEntries: SimilarEventResultEntry[];
	searchEntry: SimilarEventEntry;
}

export interface SimilarEventResultEntry {
	flatReportEntry: SimilarEventEntry;
	similarityScore: number;
}

export interface SystemEventContainerNew {
	endIndex: number;
	id: number;
	isUserDefined: boolean;
	polarity: string;
	sentence: string;
	startIndex: number;
	text: string;
	transcriptId: number;
	typeName: string;
	keyDriver?: string;
}

export interface SystemEvent {
	id: number;
	name: string;
	offsetEndInSentence: number;
	offsetStartInSentence: number;
	polarity: string;
}

export interface EventType {
	id?: number;
	categoryName: string;
	eventName: string;
	collectionName?: string;
	chartDescription?: string;
	documentViewDescription?: string;
}

export interface Category {
	id: number;
	name: string;
	collection_id: number;
	collection_name?: string;
	chart_description?: string;
	document_view_description?: string;
}

export interface Collection {
	id: number;
	name: string;
}

export interface Dashboard {
	id: number;
	name: string;
	appId: string;
	theme: string;
	watchlistsEnabled: boolean;
}

export interface EventListCategory {
	id: number;
	name: string;
	positiveCount: number;
	negativeCount: number;
	netScore: number;
	total: number;
	events: Event[];
	open: boolean;
	documentViewDescription?: string;
	containsSelectedEvent?: boolean;
}

/**
 * Type for text annotations
 */
export interface SelectionData {
	fromTo: number[];
	text: string;
	valid?: boolean;
}

export interface FormattedText {
	date: Date;
	title: string;
	sections: Section[];
	participants: Participant[];
	vendor: string;
	isEditedCopy: boolean;
}

export interface TenKNavigation {
	events: OutboundEvent[];
	sections: {
		parts: Part[];
	};
	showSort: boolean;
}

export interface Part {
	name: string;
	navigationId: number;
	items: PartItem[];
}

export interface PartItem {
	name: string;
	navigationId: string;
}

export interface OutboundEvent {
	typeName: string;
	categoryName?: string;
	id: number;
	polarity: string;
	text: string;
	hash: string;
	isSubsection?: boolean;
	html?: string;
	title?: string;
	section?: string;
	keyDrivers?: string[];
}

export interface Section {
	name: string;
	speakers: Speaker[];
	textLength?: number;
}

export interface Speaker {
	speakingIndex?: number; // Order of speaker in text. Need this index because a participant can speak more than once in a section
	participantId?: number;
	offsetInText?: number;
	textLength?: number;
	paragraphs?: Paragraph[];
	type: string;
	title: string;
	affiliation?: string;
	affiliationEntity?: string;
	entity: string;
	name: string;
	company?: string;
}

export interface Paragraph {
	id: number;
	text: string;
	textLength: number;
	offsetInText: number;
}

export interface Participant {
	id: number;
	type: string;
	name: string;
	title: string;
	affiliation: string;
	affiliationEntity: string;
	entity: string;
}

export interface QNA {
	index: number;
	question: QNAElement;
	answerElements: QNAElement[];
}

export interface QNAElement {
	id: number;
	name: string;
	title: string;
	affiliation: string;
	type: string;
}

export interface SearchedEventForDocument {
	amenityCompanyProviderId: string;
	companyName: string;
	documentGroupId: number;
	eventText: string;
	eventType: string;
	flowId: number;
	offsetStartInText: number;
	offsetEndInText: number;
	polarity: string;
	publicationDate: string;
	sentence: string;
}

export enum MobileNotSupportedMessage {
	NO_SHOW,
	NOT_SUPPORTED_GENERAL,
	NOT_SUPPORTED_SIGNUP
}

export interface EventElement {
	id: string | number;
	element: any;
}

export interface UserActionData {
	documentId: number;
	flowId: number;
	action: UserAction;
}

export enum UserAction {
	SEARCH_EVENTS_MATCHES_CLICKED,
	TRANSCRIPT_OPENED,
	COMPANY_ADDED
}

export interface PrintLayout {
	label: string;
	pageHeight: number;
	maxNumOfEventsOnPage: number;
}

export enum AnalyticsErrorTypes {
	UNKNOWN,
	NETWORK,
	ON_LICENSE_ACCESS_DENIED,
	EMPTY_WATCHLIST,
	IFRAMES_CRASHED,
	NO_DATA_TO_EXPORT
}

export interface AnalyticsError {
	type: AnalyticsErrorTypes;
	message: string;
	acceptButtonText: string;
	dismissButtonText: string;
	logout: boolean;
}

export interface App {
	id: string;
	name: string;
	sheets: AppSheet[];
	isOpen: boolean;
	loadingSpinner: boolean;
	watchlistFilterEnabled: boolean;
	bookmarks: Bookmark[];
	isLoaded: boolean;
	appObj: any;
}

export interface Bookmark {
	id: string;
	title: string;
	description: string;
	isPublic: boolean;
}

export interface AppSheet {
	id: string;
	title: string;
	description: string;
	rank: string;
	cells: SheetCell[];
}

export interface SheetCell {
	id: string;
	type: string;
}

export class AnalyticsDirectLinkParams {
	application: string;
	sheet: string;
	select?: string[];
	bookmark?: string;
	afterLogin?: boolean;

	constructor() {
		this.application = undefined;
		this.sheet = undefined;
		this.select = undefined;
		this.bookmark = undefined;
	}
}

export interface LoginExtractedData {
	role: string;
	sub: string;
	first_name: string;
	last_name: string;
	createdAt: number;
	intercom_hash: string;
	readmeio_token: string;
}

export interface KeyDriverChart {
	title: {
		text: string;
	};
	series: {
		name: string;
		data: number[];
		color?: string;
	}[];
	xAxis: {
		categories: {
			documentId?: number;
			date: string;
			fiscalYear: string;
			fiscalQuarter: string;
		}[];
	};
	yAxis: {
		max: number;
	};
}

export interface Setting {
	name: string;
	setting_id: number;
	value: boolean;
}

export interface CompanyDocumentData {
	data: Document[];
	keyDriversData: KeyDriverChart[];
	amenityScoreData: { y: number; x: number }[];
	flowId: number;
	documentType: string;
	updateDate: number;
}

export interface MatchingSelector {
	selector: string;
	matchIndex: number;
	originalInput: string;
	isFullWord: boolean;
}

export interface Watchlist {
	id: string;
	size: number;
	name: string;
}

export interface LeanPortfolioResponse {
	portfolio: PortfolioCompany[];
	startTime: string;
	endTime: string;
	portfolioInfo: { id: number; name: string };
}

export interface TableColumn {
	headerId: string;
	name: string;
	displayName: string;
	path: string[];
	primary?: boolean;
	width?: string;
	marginLeft?: string;
	padding?: string;
	showTooltip?: boolean;
	infoIcon?: {
		dataTip: string;
		dataFor: string;
		id: string;
	};
}

export enum PortfolioActionModalType {
	Delete,
	Edit
}
export type PortfolioActionModalAction = 'erase' | 'rename' | 'create';

export interface PortfolioActionModalProps {
	open: boolean;
	action: PortfolioActionModalAction;
	type: PortfolioActionModalType;
	icon: string;
	confirmText: string;
	error: string;
	title?: string;
	text?: string;
	defaultValue?: string;
}

export interface MessageModalProps {
	open: boolean;
	title?: string;
	icon?: string;
	text?: string;
	type?: MessageModalType;
	titleStyle?: React.CSSProperties;
	confirmBtnText?: string;
	closeBtnText?: string;
	confirm?: () => void;
	close: () => void;
}

export type MessageModalType = 'success' | 'error';

export interface DropdownOption {
	label?: string;
	renderOption?: () => any;
	id: number | string;
	elementId: string;
}

export interface SectionDropdownOptions {
	id: string;
	name: string;
	action?: {
		label: string;
		onClick: () => void;
	};
	options?: DropdownOption[];
}

export interface LocalStorageObject {
	[key: string]: any;
}

export interface Identity {
	email: string;
	domain: string;
	sub: string;
	salesforceAccountId: string;
}

export interface Profile {
	username: string;
	createdAt: number;
	sub?: string;
	fullName?: string;
	firstName?: string;
	lastName?: string;
	role?: string;
	salesforceAccountId?: string;
	intercom_hash?: string;
	readmeio_token?: string;
}

export type SelectFunction = (index: number, type: number) => void;
