import { TOOL_TIPS } from '../../constants';
import { WACTHLIST_PAGE, TOOLTIP } from '../../../id.constants';

const LEFT_PADDING = 60;
const ACTIONS_WIDTH = 155;

export const ALL_PORTFOLIOS_TABLE_COLUMNS = [
	{
		headerId: 'ALL_PORTFOLIOS_BLANK',
		name: '',
		displayName: '',
		path: [''],
		width: `${LEFT_PADDING}px`
	},
	{
		headerId: 'ALL_PORTFOLIOS_PORTFOLIO_NAME',
		name: 'name',
		displayName: 'Watchlists',
		path: ['name'],
		primary: true,
		width: `calc((100% - ${LEFT_PADDING}px - ${ACTIONS_WIDTH}px) * 0.25)`,
		showTooltip: true
	},
	{
		headerId: 'ALL_PORTFOLIOS_SIZE',
		name: 'size',
		displayName: 'No. of tickers',
		path: ['size'],
		width: `calc((100% - ${LEFT_PADDING}px - ${ACTIONS_WIDTH}px) * 0.75)`,
		padding: '0 0 0 30px'
	},
	{
		headerId: 'ALL_PORTFOLIOS_ACTIONS',
		name: 'actions',
		displayName: '',
		path: [''],
		width: `${ACTIONS_WIDTH}px`
	}
];

const BASIC_PORTFOLIO_TABLE_COLUMNS = [
	{
		headerId: WACTHLIST_PAGE.PORTFOLIO_TABLE_TICKER,
		name: 'ticker',
		displayName: 'Ticker',
		path: ['ticker'],
		width: 'calc((100% - 60px - 60px - 130px) * 0.1)',
		marginLeft: '50px'
	},
	{
		headerId: 'PORTFOLIO_TABLE_COMPANY_NAME',
		name: 'company_name',
		displayName: 'Company name',
		path: ['name'],
		width: 'calc((100% - 60px - 60px - 130px) * 0.22)'
	},
	{
		headerId: 'PORTFOLIO_TABLE_REGION',
		name: 'region',
		displayName: 'Region',
		path: ['region'],
		width: 'calc((100% - 60px - 60px - 130px) * 0.08)'
	},
	{
		headerId: 'PORTFOLIO_TABLE_SECTOR',
		name: 'sector',
		displayName: 'Sector',
		path: ['sector'],
		width: 'calc((100% - 60px - 60px - 130px) * 0.16)'
	},
	{
		headerId: 'PORTFOLIO_TABLE_LAST_DOCUMENT',
		name: 'call_date',
		displayName: 'Last Document',
		path: ['latestDocument', 'eventDate'],
		width: 'calc((100% - 60px - 60px - 130px) * 0.15)'
	}
];

export const PORTFOLIO_TABLE_COLUMNS = [
	...BASIC_PORTFOLIO_TABLE_COLUMNS,
	{
		headerId: 'PORTFOLIO_TABLE_AMENITY_SCORE',
		name: 'sentiment',
		displayName: 'Amenity Score',
		path: ['latestDocument', 'total_daily_sentiment_score'],
		width: 'calc((100% - 60px - 60px - 130px) * 0.25)',
		infoIcon: {
			dataTip: TOOL_TIPS.AMENITY_SCORE,
			dataFor: 'AMENITY_SCORE_TOOL_TIP',
			id: TOOLTIP.AMENITY_SCORE_ICON
		}
	},
	{
		headerId: 'PORTFOLIO_TABLE_AMENITY_SCORE_CHANGE',
		name: 'amenity_score_change',
		displayName: 'Amenity Score Change',
		path: ['latestDocument', 'amenity_score_change'],
		width: 'calc((100% - 60px - 60px - 130px) * 0.2)',
		infoIcon: {
			dataTip: TOOL_TIPS.SCORE_CHANGE,
			dataFor: 'AMENITY_SCORE_TOOL_TIP',
			id: TOOLTIP.AMENITY_CHANGE_ICON
		}
	}
];

export const PORTFOLIO_TRANSCRIPT_TABLE_COLUMNS = [
	...BASIC_PORTFOLIO_TABLE_COLUMNS,
	{
		headerId: 'PORTFOLIO_TABLE_AMENITY_SCORE',
		name: 'sentiment',
		displayName: 'Amenity Score',
		path: ['latestDocument', 'total_daily_sentiment_score'],
		width: 'calc((100% - 60px - 60px - 130px) * 0.25)',
		infoIcon: {
			dataTip: TOOL_TIPS.AMENITY_SCORE_TRANSCRIPT,
			dataFor: 'AMENITY_SCORE_TOOL_TIP',
			id: TOOLTIP.AMENITY_SCORE_ICON
		}
	},
	{
		headerId: 'PORTFOLIO_TABLE_AMENITY_SCORE_CHANGE',
		name: 'amenity_score_change',
		displayName: 'Amenity Score Change',
		path: ['latestDocument', 'amenity_score_change'],
		width: '130',
		infoIcon: {
			dataTip: TOOL_TIPS.SCORE_CHANGE_TRANSCRIPT,
			dataFor: 'AMENITY_SCORE_TOOL_TIP',
			id: TOOLTIP.AMENITY_CHANGE_ICON
		}
	}
];

export const WATCHLIST_COLUMNS = [
	...BASIC_PORTFOLIO_TABLE_COLUMNS.slice(0, 6),
	{
		headerId: 'PORTFOLIO_TABLE_AMENITY_SCORE_CHANGE',
		name: 'amenity_score_change',
		displayName: 'Amenity Score Change',
		path: ['latestDocument', 'amenity_score_change'],
		width: '130',
		infoIcon: {
			dataTip: TOOL_TIPS.SCORE_CHANGE,
			dataFor: 'AMENITY_SCORE_TOOL_TIP',
			id: TOOLTIP.AMENITY_CHANGE_ICON
		}
	}
];
