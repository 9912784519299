import { Event, Polarity } from '../../types/types';
import { strToPolarity } from '../../../components/shared/util';
import * as _ from 'lodash';

/**
 * filters events by:
 * - neutral polarity
 * - ui visiblity flag
 *
 * @param events
 * @param showNeutralExtractions
 */
const EventsFilters = (events: Event[], showNeutralExtractions: boolean): Event[] => {
	const onlyVisibleEvents: Event[] = filterNoShowEvents(events);
	const updatedNeutrality: Event[] = showNeutralExtractions
		? onlyVisibleEvents
		: filterNeutralEvents(onlyVisibleEvents);
	return updatedNeutrality;
};

const filterNoShowEvents = (events: Event[]): Event[] => events?.filter(uiVisibleCheck);

const uiVisibleCheck = (event: Event): boolean => {
	const eventProps: any[] = event['properties'] ?? [];
	const uivisible: any = eventProps.find((prop: any) => prop.key === 'uivisible');
	if (uivisible && uivisible.value === 'false') {
		return false;
	}
	return true;
};

/**
 * Removes event with neutral polarity
 */
const filterNeutralEvents = (events: Event[]) =>
	_.filter(events, (event: Event) => {
		const polarity: any = event.polarity;
		return strToPolarity(polarity) !== Polarity.neutral;
	});

export default EventsFilters;
