import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useRecoilValueLoadable } from 'recoil';
import { getWatchlist } from '../../recoil/watchlist';
import WatchlistPage from './WatchlistPage';
import { STORE_FEATURES } from '../../constants';
import FeaturesStore from '../../stores/features.store';
import { FEATURES_TYPE_SPLIT } from '../../constants';
import { useFeature } from '../../hooks/useFeature';
import WatchlistPageNewarc from './WatchlistPageNewarc';

interface PortfolioProviderProps {
	match: any;
	watchlistId: string;
	[STORE_FEATURES]?: FeaturesStore;
}

const WatchlistProvider = observer((props: PortfolioProviderProps) => {
	const ffViewerNewarc = useFeature(FEATURES_TYPE_SPLIT.VIEWER_NEWARC_MIGRATION);
	const { id: watchlistId } = props?.match?.params;
	const watchlist = useRecoilValueLoadable<any>(getWatchlist(watchlistId));
	const [loaded, setLoaded] = useState(false);
	const [Watchlist, setWatchlist] = useState<any>();

	useEffect(() => {
		if (ffViewerNewarc !== undefined) {
			setWatchlist(ffViewerNewarc ? WatchlistPageNewarc : WatchlistPage);
		}
	}, [ffViewerNewarc]);

	useEffect(() => {
		if (watchlist.state === 'hasValue' && Watchlist) {
			setLoaded(true);
		}
	}, [watchlist.state, Watchlist]);

	if (!loaded) {
		return undefined;
	}

	return <Watchlist match={props.match} watchlist={watchlist.contents} />;
});

export default inject(STORE_FEATURES)(WatchlistProvider);
