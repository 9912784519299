import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../../../components/shared/styles';

const Container = styled.div`
	width: 100%;
	height: 100%;
`;

const Section = styled.div`
	box-sizing: border-box;
	padding: 11px 13px;
`;
interface ImageProps {
	myUrl: string;
}

const Image = styled.div<ImageProps>`
	height: 10px;
	  mask-image: url(${props => props.myUrl});
	  mask-size: contain;
	  mask-position: center;
	  -webkit-mask-repeat: no-repeat;
	display: inline-block;
	position: relative;
	width: 13px;
	margin-right: 5px;
	background-color: ${Colors.blue};
`;

interface TextSpanProps {
	fontWeight: string;
	noWrapText?: boolean;
}

const Text = styled.span<TextSpanProps>`
	display: inline-block;
	position: relative;
	color: #1f1e29;
	white-space: ${props => props.noWrapText && 'nowrap'};
	font-family: Assistant;
	font-weight: ${props => props.fontWeight};
`;

const DocumentViewerToolTip = (props: {
	text: string;
	imageName?: string;
	title?: string;
	noWrapText?: boolean;
}) => {
	return (
		<Container>
			<Section>
				{props.imageName && <Image myUrl={props.imageName} />}
				{props.title && (
					<Text fontWeight={'bold'} noWrapText={props.noWrapText}>
						{props.title}
						<br />
					</Text>
				)}
				<Text fontWeight={'normal'} noWrapText={props.noWrapText}>
					{props.text}
				</Text>
			</Section>
		</Container>
	);
};

export default DocumentViewerToolTip;
