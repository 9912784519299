import * as React from 'react';
import * as _ from 'lodash';
import ReactHighcharts from 'react-highcharts';

import { KeyDriverChartNewArk } from '../../types/types_keydrivers';
import { getQuarterFromDate } from '../../services/util.service';
import './columnChart.css';

interface HighchartProps {
	data: KeyDriverChartNewArk;
	isFiscalYear: boolean;
}

const setDefaultConfig = () => ({
	chart: {
		backgroundColor: '#FBFBFB',
		type: 'column',
		height: 130,
		marginLeft: 15,
		marginRight: 15,
		marginBottom: 40
	},
	series: [],
	title: {
		align: 'left',
		style: {
			fontSize: '14px',
			fontFamily: 'Assistant',
			height: '27px',
			color: '#666',
			fontWeight: 'bold',
			marginBottom: '5px'
		},
		margin: 10
	},
	tooltip: {
		enabled: true,
		style: { fontSize: '11px' },
		// don't change to arrow, there is problem with 'this'
		formatter: function() {
			// @ts-ignore
			return `${this.point.y} ${this.series.name.toLowerCase()} ${
				// @ts-ignore
				this.point.y === 1 ? 'event' : 'events'
			}`;
		}
	},
	xAxis: {
		categories: [],
		visible: true,
		tickInterval: 1,
		labels: {},
		tickColor: 'transparent'
	},
	yAxis: {
		gridLineColor: '#d5d5d5',
		gridLineWidth: 0,
		lineWidth: 1
	},
	plotOptions: {
		gauge: { dataLabels: { style: { display: 'none' } } },
		column: {
			pointPadding: 0,
			borderWidth: 0,
			groupPadding: 0,
			minPointLength: 0,
			events: { click: (event: any) => console.log(event) }
		},
		series: {
			pointPadding: 0,
			groupPadding: 0.1,
			borderWidth: 0,
			pointWidth: 19
		}
	},
	legend: {
		enabled: false
	}
});

const ColumnChartNewark = (props: HighchartProps) => {
	const config = setDefaultConfig();
	const series = _.map(props.data.series, s => ({
		...s,
		name: `${s.name} ${props.data.title.text}`
	}));
	config.series = series;
	config.plotOptions.column = {
		...config.plotOptions.column
	};
	_.each(['title', 'xAxis', 'yAxis'], (key: string) => {
		config[key] = { ...config[key], ...props.data[key] };
	});
	config.xAxis.labels = {
		zIndex: -1,
		style: { fontSize: '10px', fontFamily: 'Assistant' },
		formatter: (v: {
			value: {
				documentId: number;
				date: string;
				fiscalYear: string;
				fiscalQuarter: string;
			};
		}) => {
			if (props.isFiscalYear) {
				return `${v.value.fiscalYear}-${v.value.fiscalQuarter}`;
			} else {
				const date = new Date(v.value.date);
				return `${date.getFullYear()}-Q${getQuarterFromDate(date)}`;
			}
		}
	};
	return <ReactHighcharts config={config} />;
};

export default ColumnChartNewark;
