import styled from 'styled-components';
import { APP_COLORS } from '../constants';

export const SubHeader = styled.div<{ isTwoLines?: boolean }>`
	display: flex;
	flex-direction: ${props => (props.isTwoLines ? 'column' : 'row')};
	justify-content: space-between;
	position: relative;
	width: 100%;
	height: 100px;
	box-sizing: border-box;
	padding: ${props => (props.isTwoLines ? '' : '25px 0px')};
	padding-right: 20px;
	color: #4a4a4a;
	background-color: ${APP_COLORS.LIGHT_BLUE_F4F7F7};
`;
export const BackButtonContainer = styled.div<{ width?: string }>`
	display: flex;
	float: left;
	height: 50px;
	width: ${props => props.width || '580px'};
`;

export const BackButtonImg = styled.div<{ size: number; src: string }>`
	float: left;
	width: 35px;
	height: 100%;
	padding: 0 0 0 20px;
	cursor: pointer;
	line-height: 50px;
	border-right: 1px solid #dde5e7;
	background-image: url(${props => props.src});
	background-position: center;
	background-repeat: no-repeat;
	background-size: ${props => props.size}px;
`;

export const BackButtonTextContainer = styled.div`
	position: relative;
	float: left;
	height: 50px;
	box-sizing: border-box;
	line-height: 50px;
	width: 480px;
`;
export const ToolBarContainer = styled.div`
	height: 50px;
	box-sizing: border-box;
	padding: 5px 0 5px 5px;
	float: right;
`;
export const TextBlock = styled.div<{
	color?: string;
	fontSize?: string;
	lineHeight?: string;
}>`
	line-height: ${props => props.lineHeight};
	color: ${props => props.color};
	font-size: ${props => props.fontSize};
`;

export const Link = styled.a<{ color?: string; fontSize?: string }>`
	color: ${props => props.color || APP_COLORS.LINK_BLUE};
	font-size: ${props => props.fontSize};
`;

export const FloatingText = styled(TextBlock)<{ direction: string }>`
	float: ${props => props.direction};
`;

export const BlockWithSpacing = styled.div<{
	margin?: string;
	padding?: string;
}>`
	margin: ${props => props.margin};
	padding: ${props => props.padding};
`;
