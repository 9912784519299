export const TOOL_TIPS = {
	LINK: {
		COPY: 'Copy link to clipboard',
		COPIED: 'Link Copied to clipboard'
	},
	ANNOTATION: {
		text:
			'Missing a highlight? Click this button and select text in the transcript to add your own highlights.',
		title: 'Making these changes will help us improve your highlights going forward.'
	},
	KEY_DRIVER_ANNOTATION: {
		text: 'Annotations are not yet available on our key-drivers model.',
		title: 'Stay tuned for updated on this feature in our upcoming releases.'
	}
};
