import * as React from 'react';
import * as _ from 'lodash';
import { inject, observer } from 'mobx-react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import BackOfficeStore from '../../stores/backOffice.store';
import Images from '../../shared/images';
import styled from 'styled-components';
import { STORE_BACK_OFFICE } from '../../constants';
import { EventType, Collection } from '../../types/types';
import { withStyles } from '@material-ui/core/styles';
import AddOrUpdateEventPopUp from '../../components/BackofficePopups/addOrUpdateEventPopUp';
import DeletePopUp from '../../components/BackofficePopups/backOfficeDeletePopUp';

const Icons = styled.img`
	cursor: pointer;
`;

const TableCell = styled.td`
	overflow: hidden;
	max-width: 305px;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: rgba(0, 0, 0, 0.87);
	font-size: 0.8125rem;
	font-weight: 400;
	display: table-cell;
	padding: 4px 56px 4px 24px;
	text-align: left;
	border-bottom: 1px solid rgba(224, 224, 224, 1);
	vertical-align: inherit;
`;

const WarpTable = styled.div`
	margin: 20px;
`;

const ButtonWrap = styled.div`
	float: right;
`;

const PageHeader = styled.div`
	margin: 20px;
`;

const PageTitle = styled.div`
	font-size: 16px;
	padding-top: 30px;
	padding-left: 22px;
`;

const Input = styled.input`
	height: 25px;
	width: 170px;
	padding-left: 10px;
`;

const styles = {
	paper: {
		maxHeight: '680px',
		overflow: 'auto'
	}
};

interface EventsTabProps {
	classes: any;
}

interface EventsTabState {
	isAddPopUpOpen: boolean;
	isEditPopUpOpen: boolean;
	isDeletePopUpOpen: boolean;
	currentEvent: EventType;
	currentCollection: Collection;
	deletedEventId: number;
	deletedEventName: string;
	searchText: string;
}

@inject(STORE_BACK_OFFICE)
@observer
class EventsTab extends React.Component<EventsTabProps, EventsTabState> {
	constructor(props: EventsTabProps) {
		super(props);
		this.state = {
			isAddPopUpOpen: false,
			isEditPopUpOpen: false,
			isDeletePopUpOpen: false,
			deletedEventId: 0,
			deletedEventName: '',
			searchText: '',
			currentEvent: this.getEmptyEvent(),
			currentCollection: undefined
		};
		(this.props[STORE_BACK_OFFICE] as BackOfficeStore).getAllCategories();
		(this.props[STORE_BACK_OFFICE] as BackOfficeStore).getEventTypes();
		(this.props[STORE_BACK_OFFICE] as BackOfficeStore).getAllCollections();
	}

	getEmptyEvent = (): EventType => {
		return {
			id: -1,
			categoryName: '',
			eventName: ''
		};
	};

	closeAddPopup = () => {
		this.setState({ isAddPopUpOpen: false });
	};

	closeEditPopup = () => {
		this.setState({ isEditPopUpOpen: false });
	};

	closeDeletePopup = () => {
		this.setState({ isDeletePopUpOpen: false });
	};

	deleteCallBack = () => {
		(this.props[STORE_BACK_OFFICE] as BackOfficeStore).deleteEvent(
			this.state.deletedEventId,
			this.state.deletedEventName
		);
	};

	deleteEvent = (id: number, name: string) => {
		this.setState({
			deletedEventId: id,
			isDeletePopUpOpen: true,
			deletedEventName: name
		});
	};

	openAddPopup = () => {
		this.setState({ isAddPopUpOpen: true });
	};

	openEditPopup = (id, eventName, categoryName, collectionName) => {
		const currentEvent = { ...this.state.currentEvent };
		currentEvent.id = id;
		currentEvent.eventName = eventName;
		currentEvent.categoryName = categoryName;
		const currentCollection = { ...this.state.currentCollection };
		const collection = _.find(
			(this.props[STORE_BACK_OFFICE] as BackOfficeStore).collections,
			col => col.name === collectionName
		);
		currentCollection.id = collection.id;
		currentCollection.name = collectionName;
		this.setState({
			currentEvent: currentEvent,
			isEditPopUpOpen: true,
			currentCollection: currentCollection
		});
	};

	handleKeyPress = event => {
		this.setState({ searchText: event.target.value });
	};

	addEvent = (event: EventType) => {
		(this.props[STORE_BACK_OFFICE] as BackOfficeStore).addEvent(event);
		this.setState({ isAddPopUpOpen: false });
	};

	UpdateEvent = (event: EventType) => {
		(this.props[STORE_BACK_OFFICE] as BackOfficeStore).updateEvent(event);
		this.setState({ isEditPopUpOpen: false });
	};

	renderUpperSection = () => {
		return (
			<div>
				<PageTitle>Events</PageTitle>
				<PageHeader>
					<Input
						type='text'
						placeholder='Search'
						onChange={e => {
							this.handleKeyPress(e);
						}}
						id='BACKOFFICE_EVENTS_SEARCH_BAR'
					/>
					<ButtonWrap>
						<Button
							variant='contained'
							size='large'
							style={{
								backgroundColor: '#21a6f2',
								color: '#ffff',
								boxShadow: 'none'
							}}
							onClick={() => this.openAddPopup()}
							id='BACKOFFICE_EVENTS_NEW_EVENT_BUTTON'
						>
							New Event
						</Button>
					</ButtonWrap>
				</PageHeader>
			</div>
		);
	};

	renderTableBody = (filteredEventTypes: EventType[], categoriesWithCollection: any[]) => {
		return (
			<TableBody id='EVENTS_TABLE'>
				{_.map(filteredEventTypes, (e, index) => {
					return (
						<TableRow key={'Backoffice_Event_' + e.id} id={`ROW_NUM_${index}`}>
							<TableCell>{e.id}</TableCell>
							<TableCell id={`BACKOFFICE_EVENTS_EVENT_NAME_ROW_${index}`}>{e.eventName}</TableCell>
							<TableCell>{e.categoryName}</TableCell>
							<TableCell>{e.collectionName}</TableCell>
							<TableCell>
								<Icons
									src={Images.deleteTranscript}
									onClick={() => this.deleteEvent(e.id, e.eventName)}
									id={`BACKOFFICE_EVENTS_DELETE_EVENT_ROW_${index}`}
								/>
							</TableCell>
							<TableCell>
								<Icons
									src={Images.editIcon}
									onClick={() =>
										this.openEditPopup(e.id, e.eventName, e.categoryName, e.collectionName)
									}
									id={`BACKOFFICE_EVENTS_EDIT_EVENT_ROW_${index}`}
								/>
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		);
	};

	renderTable = (filteredEventTypes: EventType[], categoriesWithCollection: any[]) => {
		const { classes } = this.props;
		return (
			<div>
				<WarpTable>
					<Paper className={classes.paper}>
						<Table className={classes.table}>
							<TableHead>
								<TableRow>
									<TableCell>Id</TableCell>
									<TableCell>Event Name</TableCell>
									<TableCell>Category Name</TableCell>
									<TableCell>Collection Name</TableCell>
									<TableCell />
									<TableCell />
								</TableRow>
							</TableHead>
							{this.renderTableBody(filteredEventTypes, categoriesWithCollection)}
						</Table>
					</Paper>
				</WarpTable>
			</div>
		);
	};

	render() {
		const { categories, event_types, collections } = this.props[
			STORE_BACK_OFFICE
		] as BackOfficeStore;
		const collectionsByCategories = _.map(categories, cat => {
			const collectionOfCat = _.find(collections, col => col.id === cat.collection_id);
			return {
				...cat,
				collection_name: collectionOfCat ? collectionOfCat.name : ''
			};
		});
		const {
			isAddPopUpOpen,
			isEditPopUpOpen,
			currentEvent,
			currentCollection,
			isDeletePopUpOpen
		} = this.state;
		const searchTerm = this.state.searchText.toLowerCase();
		const filteredEventTypes = _.filter(_.orderBy(event_types, 'id'), (e: EventType) => {
			return (
				(e.eventName && e.eventName.toLowerCase().indexOf(searchTerm) !== -1) ||
				(e.categoryName && e.categoryName.toLowerCase().indexOf(searchTerm) !== -1)
			);
		});
		return (
			<div>
				{this.renderUpperSection()}
				{this.renderTable(filteredEventTypes, collectionsByCategories)}
				{isAddPopUpOpen && (
					<AddOrUpdateEventPopUp
						type={0}
						modalIsOpen={isAddPopUpOpen}
						closePopup={() => this.closeAddPopup()}
						addOrEditFunc={this.addEvent}
						categories={categories}
						collections={collections}
						eventTypes={event_types}
					/>
				)}
				{isEditPopUpOpen && (
					<AddOrUpdateEventPopUp
						type={1}
						modalIsOpen={isEditPopUpOpen}
						closePopup={() => this.closeEditPopup()}
						event={currentEvent}
						addOrEditFunc={this.UpdateEvent}
						categories={categories}
						collections={collections}
						collection={currentCollection}
						eventTypes={event_types}
					/>
				)}
				{isDeletePopUpOpen && (
					<DeletePopUp
						modalIsOpen={isDeletePopUpOpen}
						closePopup={() => this.closeDeletePopup()}
						deleteFunc={this.deleteCallBack}
						name={'event'}
					/>
				)}
			</div>
		);
	}
}

export default withStyles(styles)(EventsTab);
