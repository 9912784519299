import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
	Appearance,
	Colors,
	FontFamilies,
	FontSizes,
	Sizes
} from '../../../components/shared/styles';
import Images from '../../shared/images';
import { FEATURES_TYPE_SPLIT, USER_TRACKING as UT } from '../../constants';
import UserTracking from '../../services/userTracking.service';
import { useDTCSubscribe } from './DtcData.service';
import { useLocation } from 'react-router-dom';
import { Button, Modal } from '@amenityllc/amenity-components';
import { Link } from '../../../components';
import NavigationService from '../../services/navigation.service';
import { useFeature } from '../../hooks/useFeature';
import { useOrganizationFeatures } from '../../hooks/useOrganizationFeatures';
import Spinner from '../../widgets/Spinner/spinner';

const Page = styled.div`
	height: 100%;
	width: 100%;
	overflow-y: auto;
	background-color: ${Colors.lightGray};
	padding-left: 21px;
`;

const Header = styled.div`
	padding-top: 28px;
	padding-bottom: 18px;
	font-family: ${FontFamilies.semiBold};
	font-size: ${FontSizes.s4};
	color: ${Colors.dark};
`;

const StatusContainer = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 400px;
	height: 200px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	text-align: center;
`;

const Status = styled.div`
	font-family: ${FontFamilies.regular};
	font-size: ${FontSizes.s4};
	color: ${Colors.dark};
	padding-top: 18px;
	padding-bottom: 10px;
`;

const InfoText = styled.div`
	font-family: ${FontFamilies.light};
	font-size: ${FontSizes.s1};
	color: ${Colors.dark};
	margin-bottom: 24px;
`;

const UTC = UT.USER_TRACKING_CATEGORIES;
const UTA = UT.USER_TRACKING_ACTIONS;

const renderModalButtons = () => {
	const navService = NavigationService.getInstance();
	return (
		<Button
			key='support'
			size={Sizes.medium}
			appearance={Appearance.primary}
			onClick={() => {
				navService.openSupportPage();
				navService.goToRoot();
			}}
		>
			Contact Support
		</Button>
	);
};

const EmailSubscription: FunctionComponent = () => {
	const { isSubscribed, toggleSubscription, loading } = useDTCSubscribe();
	const location = useLocation();
	const isRegistrationLink = location.pathname.includes('subscribe');
	const ffViewerDtcEmail = useFeature(FEATURES_TYPE_SPLIT.VIEWER_DTC_EMAIL);
	const orgFeatures = useOrganizationFeatures();

	const shouldRegisterToDTC = orgFeatures?.dtc && ffViewerDtcEmail && isRegistrationLink;
	const [error, setError] = useState<boolean>(false);

	useEffect(() => {
		if (orgFeatures?.dtc === false || ffViewerDtcEmail === false) {
			setError(true);
		}
	}, [orgFeatures, ffViewerDtcEmail]);

	useEffect(() => {
		if (isSubscribed && isRegistrationLink) {
			NavigationService.getInstance().goToEmailSubscription();
		}
	}, [isSubscribed, isRegistrationLink]);

	useEffect(() => {
		if (shouldRegisterToDTC && !loading && !isSubscribed) {
			toggleSubscription()
				.then(() => {
					const ut = new UserTracking();
					ut.setEvent(UTC.EMAIL_ALERTS, UTA.DTC_EMAIL_SUBSCRIBE);
				})
				.catch(error => {
					setError(true);
				});
		}

		// eslint-disable-next-line
	}, [isSubscribed, loading, ffViewerDtcEmail]);

	const onClick = () => {
		toggleSubscription()
			.then(() => {
				const ut = new UserTracking();
				ut.setEvent(
					UTC.EMAIL_ALERTS,
					isSubscribed ? UTA.DTC_HIGHLIGHTS_UNSUBSCRIBE : UTA.DTC_EMAIL_SUBSCRIBE
				);
			})
			.catch(error => {
				setError(true);
			});
	};

	const subscripbedStatusText = 'You are subscribed to DTC highlights email';
	const unsubscripbedStatusText = 'You are unsubscribed from the DTC highlights email';
	const nextEmailAt = 'Next email will be sent at 8:00AM tomorrow';
	const viewDashboardText = 'To view DTC Insights in our DTC dashboard ';

	return (
		<Page>
			<Header>Email subscription preferences</Header>
			<StatusContainer>
				{loading || isRegistrationLink ? (
					<Spinner />
				) : (
					<>
						<img src={isSubscribed ? Images.emailHappy : Images.emailSad} alt='email status' />
						<Status>{isSubscribed ? subscripbedStatusText : unsubscripbedStatusText}</Status>
						<InfoText>
							{nextEmailAt}
							<br />
							{viewDashboardText}
							<Link
								size={FontSizes.s1}
								onClick={() => NavigationService.getInstance().goToAnalytics()}
							>
								Click Here
							</Link>
						</InfoText>
						<Button size={Sizes.medium} appearance={Appearance.primary} onClick={onClick}>
							{isSubscribed ? 'Unsubscribe' : 'Subscribe'}
						</Button>
					</>
				)}
			</StatusContainer>
			<Modal
				title={'An Error Occurred'}
				text={'Subscription failed, click bellow to contact our support'}
				isModalOpen={error}
				modalButtons={renderModalButtons()}
				zIndex={1001}
				fixedContentWidth={false}
				close={() => setError(false)}
			/>
		</Page>
	);
};

export default EmailSubscription;
