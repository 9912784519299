import React from 'react';
import ContentLoader from 'react-content-loader';

const DashboardsMenuSkeleton = props => (
	<ContentLoader
		speed={2}
		width={153}
		height={433}
		viewBox='0 0 153 433'
		backgroundColor='#f3f3f3'
		foregroundColor='#eeeeee'
		style={{ width: '100%' }}
		{...props}
	>
		<rect x='0' y='12' rx='1' ry='1' width='153' height='20' />
		<rect x='10' y='52' rx='1' ry='1' width='141' height='19' />
		<rect x='10' y='85' rx='1' ry='1' width='141' height='19' />
		<rect x='10' y='118' rx='1' ry='1' width='141' height='19' />
		<rect x='0' y='153' rx='1' ry='1' width='153' height='20' />
		<rect x='0' y='190' rx='1' ry='1' width='153' height='20' />
		<rect x='0' y='226' rx='1' ry='1' width='153' height='20' />
		<rect x='0' y='262' rx='1' ry='1' width='153' height='20' />
		<rect x='0' y='298' rx='1' ry='1' width='153' height='20' />
	</ContentLoader>
);

export default DashboardsMenuSkeleton;
