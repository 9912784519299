import * as _ from 'lodash';
import { Event, Polarity } from '../../types/types';
import { error, warn } from '../../services/logger.service';

/**
 * Method to split string on event indices
 * @param str - original string
 * @param events - objects containing the start and end indices of event
 * @param strOffsetInText - the string offset in the text. Need this because events indices are relative
 * @param globalTextOffset - whether the whole text has an offset. This happens because of delimiter start of text
 */
export const stringToArraySplit = (
	str: string,
	events: Event[],
	strOffsetInText: number,
	globalTextOffset: number,
	logErrors: boolean
): {
	res: {
		text: string;
		pol: Polarity;
		isEvent: boolean;
		eventId: number;
		indexInTranscript?: number;
	}[];
	lostEvents: Event[];
} => {
	const res: {
		text: string;
		pol: Polarity;
		isEvent: boolean;
		eventId: number;
		indexInTranscript?: number;
	}[] = [];
	const lostEvents: Event[] = [];

	// Check that last split index is not larger the str length
	if (!events || events.length === 0) {
		return { res, lostEvents };
	}

	let restOfTheText = str;
	let strCutAtIndex = 0;

	const eventsWithPositions = events
		.map(event => {
			const eventStartInTheRestOfTheText = restOfTheText.indexOf(event.text);
			const eventEndInTheRestOfTheText = eventStartInTheRestOfTheText + event.text.length;
			const eventStartInParagraph =
				eventStartInTheRestOfTheText > -1 ? strCutAtIndex + eventStartInTheRestOfTheText : -1;
			const eventEndInParagraph = eventStartInParagraph + event.text.length;
			restOfTheText =
				eventStartInParagraph > -1
					? restOfTheText.substring(eventEndInTheRestOfTheText, restOfTheText.length)
					: restOfTheText;
			strCutAtIndex = eventStartInParagraph > -1 ? eventEndInParagraph : strCutAtIndex;
			return {
				...event,
				eventStartInParagraph,
				eventEndInParagraph
			};
		})
		.filter(event => event.eventStartInParagraph > -1);

	if (logErrors && eventsWithPositions.length < events.length) {
		_.each(
			events.filter(e => !_.some(eventsWithPositions, { id: e.id })),
			event => {
				lostEvents.push(event);
				warn({
					message: `Highlight not found in paragraph, highlight { id: ${event.id}, text: "${event.text}" }; transcript id: ${event.transcriptId}`,
					file: 'transcript.util.ts',
					functionName: 'stringToArraySplit'
				});
			}
		);
	}

	for (let i = 0; i < eventsWithPositions.length; i++) {
		// User defined indices are not including a delimiter at start of text as events from API
		// const globalOffset = (events[i].isUserDefined ? 0 : globalTextOffset);
		// const eventStartInParagraph = events[i].startIndex - strOffsetInText - globalOffset;
		// const eventEndInParagraph = events[i].endIndex - strOffsetInText - globalOffset;

		// Temporary fix: So events don't have a shift from start/end of word
		const currentEvent = eventsWithPositions[i];
		const { eventStartInParagraph, eventEndInParagraph } = currentEvent;

		// Check if event is out of text bounds
		// if (isEventOutOfTextBounds(str, eventStartInParagraph, eventEndInParagraph)) {
		// 	continue;
		// }

		// First event
		if (i === 0) {
			// Text before first event
			if (eventStartInParagraph > 0) {
				res.push({
					text: str.substring(0, eventStartInParagraph),
					pol: undefined,
					isEvent: false,
					eventId: -1
				});
			}
		}

		// Event text
		res.push({
			text: str.substring(eventStartInParagraph, eventEndInParagraph),
			pol: currentEvent.polarity,
			isEvent: true,
			eventId: currentEvent.id,
			indexInTranscript: currentEvent.indexInTranscript
		});

		// If not last event
		if (i !== eventsWithPositions.length - 1) {
			// const nextEventStartInParagraph = events[i + 1].startIndex - strOffsetInText - (events[i + 1].isUserDefined ? 0 : globalTextOffset);
			const currentEventEnd = currentEvent.eventEndInParagraph;
			const nextEventText = eventsWithPositions[i + 1].text;
			const nextEventStartInParagraph =
				currentEventEnd + str.substring(currentEventEnd, str.length).indexOf(nextEventText);
			// Normal text after event to next event
			if (nextEventStartInParagraph > 0) {
				res.push({
					text: str.substring(eventEndInParagraph, nextEventStartInParagraph),
					pol: undefined,
					isEvent: false,
					eventId: -1
				});
			}
		} else {
			// Last event - check if there is text after event and take it
			if (eventEndInParagraph < str.length) {
				res.push({
					text: str.substring(eventEndInParagraph),
					pol: undefined,
					isEvent: false,
					eventId: -1
				});
			}
		}
	}
	return { res, lostEvents };
};

export const isEventOutOfTextBounds = (str: string, startIndex: number, endIndex: number) => {
	return startIndex < 0 || startIndex > str.length || endIndex < 0 || endIndex > str.length;
};

export const logLostEvents = (events: Event[], foundEvents: Event[]) => {
	const lostEvents = _.chain(events)
		.filter((e: Event) => !_.some(foundEvents, { id: e.id }))
		.map((e: Event) => `{ id: ${e.id}, text: "${e.text}" }`)
		.value();
	if (lostEvents.length === 0) {
		return;
	}
	error({
		message: `Highlights got lost between paragraphs; transcript id: ${
			events[0].transcriptId
		}, highlights: ${lostEvents.join(', ')}`,
		file: 'transcript.util.ts',
		functionName: 'logLostEvents'
	});
};
