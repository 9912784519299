// Read before adding support for a new document type:
// You'll need to add your type information throughout a few places in this file.
// Note: you shouldn't need to update legacy document types (below).
// For your convenience, the places you need to change are:
// 1) Add the human-readable label as it should appear in UI to the enum declaration
// 2) Add the corresponding API code to the DocumentTypeCode enum
// 3) Add the pair of new enum members to the switch case in documentTypeForCode
// 4) Similarly, add the inverse pair to the switch case in codeForDocumentType
// 5) Add the enum member to its relevant grouping in getDocumentTypeGrouping
// 6) If it needs to be shown in the sidebar, add it within the ordering for the relevant grouping
//    in documentTypeOrderForGrouping

// Enum containing all supported document types. Enum values are human-readable labels
export enum DocumentType {
	Unknown = 'Unknown', // used as a failsafe for missing types
	EarningsCall = 'Earnings Call',
	Presentation = 'Presentation (Investor conference)',
	SpecialSituation = 'Special Situation, M&A and Other',
	SalesAndRevenueCall = 'Sales and Revenue Call',
	Analyst = 'Shareholder/Analyst Call',
	GuidanceCall = 'Guidance Call',
	GuidanceUpdateCall = 'Guidance/Update Call',
	ShareholderAnalystCall = 'Shareholder/Analyst Call',
	MergerAndAcquisitionCall = 'M&A Call',
	SalesTradingCall = 'Sales/Trading Statement Call',
	SpecialCall = 'Special Call',
	OperatingResultsCall = 'Operating Results Call',
	InterimManagementCall = 'Interim Management Statement Call',
	FixedIncomeCall = 'Fixed Income Call',
	AnalystInvestorDay = 'Analyst/Investor Day',
	CompanyConferencePresentation = 'Company Conference Presentation',
	TenK = '10-K',
	EightK = '8-K',
	TenQ = '10-Q', // not in GQL enum atm
	SixK = '6-K', // not in GQL enum atm
	TwentyF = '20-F', // not in GQL enum atm
	FortyF = '40-F' // not in GQL enum atm
}

// Enum containing all API codes for supported document types. Should mirror DocumentType.
// Values here correspond to the enum values returned by GQL for the "DocumentType" enum.
// This enum is used as the "source of truth" enum override for our GQL codegen, so that we can
// make use of it within query variables and responses directly, without needing to cast to/from
// a generated type.
export enum DocumentTypeCode {
	Unknown = 'UNKNOWN', // used as a failsafe for missing/uninitialized types
	EarningsCall = 'EARNINGS_CALL',
	Presentation = 'PRESENTATION',
	SpecialSituation = 'SPECIAL_SITUATION',
	SalesAndRevenueCall = 'SALES_AND_REVENUE_CALL',
	Analyst = 'ANALYST',
	GuidanceCall = 'GUIDANCE_CALL',
	GuidanceUpdateCall = 'GUIDANCE_UPDATE_CALL',
	ShareholderAnalystCall = 'SHAREHOLDER_ANALYST_CALL',
	MergerAndAcquisitionCall = 'M_A_CALL',
	SalesTradingCall = 'SALES_TRADING_CALL',
	SpecialCall = 'SPECIAL_CALL',
	OperatingResultsCall = 'OPERATING_RESULTS_CALL',
	InterimManagementCall = 'INTERIM_MANAGEMENT_CALL',
	FixedIncomeCall = 'FIXED_INCOME_CALL',
	AnalystInvestorDay = 'ANALYST_INVESTOR_DAY',
	CompanyConferencePresentation = 'COMPANY_CONFERENCE_PRESENTATION',
	TenK = 'SEC_10_K',
	EightK = 'SEC_8_K',
	TenQ = 'SEC_10_Q', // not in GQL enum atm
	SixK = 'SEC_6_K', // not in GQL enum atm
	TwentyF = 'SEC_20_F', // not in GQL enum atm
	FortyF = 'SEC_40_F' // not in GQL enum atm
}

// Enum describing the grouping of document types. Each document type belongs to one group here
export enum DocumentTypeGrouping {
	Unknown = 'Unknown', // used as a failsafe for missing/uninitialized types
	Transcripts = 'Transcripts',
	SecFilings = 'SEC Filings',
	News = 'News' // currently unused
}

// Returns a DocumentType based on the given type code (as returned by the GQL API)
// This is the exact inverse of codeFromDocumentType, and should always mirror it
export const documentTypefromCode = (code: string | DocumentTypeCode): DocumentType => {
	switch (code) {
		// codes to add more types can be found in:
		// https://github.com/amenityllc/common-constants/blob/master/src/common_constants/enums.py
		case DocumentTypeCode.EarningsCall:
			return DocumentType.EarningsCall;
		case DocumentTypeCode.Presentation:
			return DocumentType.Presentation;
		case DocumentTypeCode.SpecialSituation:
			return DocumentType.SpecialSituation;
		case DocumentTypeCode.SalesAndRevenueCall:
			return DocumentType.SalesAndRevenueCall;
		case DocumentTypeCode.Analyst:
			return DocumentType.Analyst;
		case DocumentTypeCode.GuidanceCall:
			return DocumentType.GuidanceCall;
		case DocumentTypeCode.GuidanceUpdateCall:
			return DocumentType.GuidanceUpdateCall;
		case DocumentTypeCode.ShareholderAnalystCall:
			return DocumentType.ShareholderAnalystCall;
		case DocumentTypeCode.MergerAndAcquisitionCall:
			return DocumentType.MergerAndAcquisitionCall;
		case DocumentTypeCode.SalesTradingCall:
			return DocumentType.SalesTradingCall;
		case DocumentTypeCode.SpecialCall:
			return DocumentType.SpecialCall;
		case DocumentTypeCode.OperatingResultsCall:
			return DocumentType.OperatingResultsCall;
		case DocumentTypeCode.InterimManagementCall:
			return DocumentType.InterimManagementCall;
		case DocumentTypeCode.FixedIncomeCall:
			return DocumentType.FixedIncomeCall;
		case DocumentTypeCode.AnalystInvestorDay:
			return DocumentType.AnalystInvestorDay;
		case DocumentTypeCode.CompanyConferencePresentation:
			return DocumentType.CompanyConferencePresentation;
		case DocumentTypeCode.TenK:
			return DocumentType.TenK;
		case DocumentTypeCode.EightK:
			return DocumentType.EightK;
		case DocumentTypeCode.TenQ:
			return DocumentType.TenQ;
		case DocumentTypeCode.SixK:
			return DocumentType.SixK;
		case DocumentTypeCode.TwentyF:
			return DocumentType.TwentyF;
		case DocumentTypeCode.FortyF:
			return DocumentType.FortyF;
	}

	// Since changes in flows may cause future disruptions, we can't throw an error here
	return DocumentType.Unknown;
};

// Returns a code that can be used in GQL queries to filter on a specific document type.
// This is the exact inverse of documentTypeFromCode, and should always mirror it
export const codeFromDocumentType = (
	documentType: DocumentType,
	provider: string
): DocumentTypeCode => {
	if (provider === 'SNP') {
		switch (documentType) {
			case DocumentType.AnalystInvestorDay:
				return DocumentTypeCode.AnalystInvestorDay;
			case DocumentType.EarningsCall:
				return DocumentTypeCode.EarningsCall;
			case DocumentType.Presentation:
				return DocumentTypeCode.CompanyConferencePresentation;
			case DocumentType.ShareholderAnalystCall:
				return DocumentTypeCode.ShareholderAnalystCall;
		}
	}
	switch (documentType) {
		// codes to add more types can be found in:
		// https://github.com/amenityllc/common-constants/blob/master/src/common_constants/enums.py
		case DocumentType.EarningsCall:
			return DocumentTypeCode.EarningsCall;
		case DocumentType.Presentation:
			return DocumentTypeCode.Presentation;
		case DocumentType.SpecialSituation:
			return DocumentTypeCode.SpecialSituation;
		case DocumentType.SalesAndRevenueCall:
			return DocumentTypeCode.SalesAndRevenueCall;
		case DocumentType.ShareholderAnalystCall:
			return DocumentTypeCode.Analyst;
		case DocumentType.Analyst:
			return DocumentTypeCode.Analyst;
		case DocumentType.GuidanceCall:
			return DocumentTypeCode.GuidanceCall;
		case DocumentType.GuidanceUpdateCall:
			return DocumentTypeCode.GuidanceUpdateCall;
		case DocumentType.MergerAndAcquisitionCall:
			return DocumentTypeCode.MergerAndAcquisitionCall;
		case DocumentType.SalesTradingCall:
			return DocumentTypeCode.SalesTradingCall;
		case DocumentType.SpecialCall:
			return DocumentTypeCode.SpecialCall;
		case DocumentType.OperatingResultsCall:
			return DocumentTypeCode.OperatingResultsCall;
		case DocumentType.InterimManagementCall:
			return DocumentTypeCode.InterimManagementCall;
		case DocumentType.FixedIncomeCall:
			return DocumentTypeCode.FixedIncomeCall;
		case DocumentType.AnalystInvestorDay:
			return DocumentTypeCode.AnalystInvestorDay;
		case DocumentType.CompanyConferencePresentation:
			return DocumentTypeCode.CompanyConferencePresentation;
		case DocumentType.TenK:
			return DocumentTypeCode.TenK;
		case DocumentType.EightK:
			return DocumentTypeCode.EightK;
		case DocumentType.TenQ:
			return DocumentTypeCode.TenQ;
		case DocumentType.SixK:
			return DocumentTypeCode.SixK;
		case DocumentType.TwentyF:
			return DocumentTypeCode.TwentyF;
		case DocumentType.FortyF:
			return DocumentTypeCode.FortyF;
	}

	return DocumentTypeCode.Unknown;
};

// Returns the grouping of the given document type.
export const getDocumentTypeGrouping = (documentType: DocumentType): DocumentTypeGrouping => {
	switch (documentType) {
		case DocumentType.EarningsCall:
		case DocumentType.Presentation:
		case DocumentType.SpecialSituation:
		case DocumentType.SalesAndRevenueCall:
		case DocumentType.Analyst:
		case DocumentType.GuidanceCall:
		case DocumentType.GuidanceUpdateCall:
		case DocumentType.ShareholderAnalystCall:
		case DocumentType.MergerAndAcquisitionCall:
		case DocumentType.SalesTradingCall:
		case DocumentType.SpecialCall:
		case DocumentType.OperatingResultsCall:
		case DocumentType.InterimManagementCall:
		case DocumentType.FixedIncomeCall:
		case DocumentType.AnalystInvestorDay:
		case DocumentType.CompanyConferencePresentation:
			return DocumentTypeGrouping.Transcripts;
		case DocumentType.TenK:
		case DocumentType.EightK:
		case DocumentType.TenQ:
		case DocumentType.SixK:
		case DocumentType.TwentyF:
		case DocumentType.FortyF:
			return DocumentTypeGrouping.SecFilings;
	}

	return DocumentTypeGrouping.Unknown;
};

// Returns true if the given document type belongs in the given grouping, false otherwise.
export const isDocumentTypeInGrouping = (
	documentType: DocumentType,
	grouping: DocumentTypeGrouping
): boolean => {
	return getDocumentTypeGrouping(documentType) === grouping;
};

// Returns the predetermined ordering of document types within the given grouping.
// This directly affects which categories and types are shown in the sidebar.
export const documentTypeOrderForGrouping = (grouping: DocumentTypeGrouping): DocumentType[] => {
	switch (grouping) {
		case DocumentTypeGrouping.Transcripts:
			return [DocumentType.EarningsCall, DocumentType.Presentation, DocumentType.Analyst];
		case DocumentTypeGrouping.SecFilings:
			return [
				DocumentType.TenK,
				DocumentType.EightK,
				DocumentType.TenQ,
				DocumentType.TwentyF,
				DocumentType.FortyF
			];
	}

	throw new Error(`unknown ordering for document type grouping: ${grouping}`);
};

// Provides an easy-to-access interface to all displayable document types, in-order
export interface OrderedDocumentTypeGroupings {
	groupOrdering: {
		grouping: DocumentTypeGrouping;
		documentTypes: DocumentType[];
	}[];
}

// Gets all document types to be displayed alongside their categories, in-order
export const getAllOrderedDocumentTypes = (): OrderedDocumentTypeGroupings => {
	return {
		groupOrdering: [DocumentTypeGrouping.Transcripts, DocumentTypeGrouping.SecFilings].map(
			grouping => ({
				grouping: grouping,
				documentTypes: documentTypeOrderForGrouping(grouping)
			})
		)
	};
};

// -----------------------------
// Legacy doctypes-related code below this point!
// Don't feel obligated to add anything here.
// -----------------------------

export interface DocumentTypeLegacy {
	API_ID: number;
	NAME: string;
	IS_TRANSCRIPT: boolean;
	isSelected?: boolean; // For UI purposes only - document filter etc.
	enabled?: boolean; // For UI purposes only - if exist in user flows
}

export interface DocumentTypeCategoryLegacy {
	name: string;
	id: number;
	types: DocumentTypeLegacy[];
}

export const DOCUMENT_TYPES_LEGACY = {
	TEN_Q: { API_ID: 1, NAME: '10Q', IS_TRANSCRIPT: false },
	TEN_K: { API_ID: 2, NAME: '10K', IS_TRANSCRIPT: false },
	EIGHT_K: { API_ID: 3, NAME: '8K', IS_TRANSCRIPT: false },
	SALES_AND_REVENUE_CALL: {
		API_ID: 4,
		NAME: 'Sales and Revenue Call',
		IS_TRANSCRIPT: true
	},
	EARNINGS_CALL: { API_ID: 5, NAME: 'Earnings Call', IS_TRANSCRIPT: true },
	PRESENTATION: {
		API_ID: 6,
		NAME: 'Presentation (Investor conference)',
		IS_TRANSCRIPT: true
	},
	SPECIAL_SITUATION: {
		API_ID: 7,
		NAME: 'Special Situation (like M&A)',
		IS_TRANSCRIPT: true
	},
	GUIDANCE_CALL: { API_ID: 8, NAME: 'Guidance Call', IS_TRANSCRIPT: true },
	ANALYST: { API_ID: 9, NAME: 'Analyst', IS_TRANSCRIPT: true },
	NEWS: { API_ID: 10, NAME: 'News', IS_TRANSCRIPT: true },
	COMPANY_CONFERENCE_PRESENTATIONS: {
		API_ID: 11,
		NAME: 'Company Conference Presentations',
		IS_TRANSCRIPT: true
	},
	SHAREHOLDER_ANALYST_CALLS: {
		API_ID: 12,
		NAME: 'Shareholder/Analyst Calls',
		IS_TRANSCRIPT: true
	},
	ANALYST_INVESTOR_DAY: {
		API_ID: 13,
		NAME: 'Analyst/Investor Day',
		IS_TRANSCRIPT: true
	},
	SPECIAL_CALLS: { API_ID: 14, NAME: 'Special Calls', IS_TRANSCRIPT: true },
	M_A_CALLS: { API_ID: 15, NAME: 'M&A Calls', IS_TRANSCRIPT: true },
	SALES_TRADING_CALLS: {
		API_ID: 16,
		NAME: 'Sales/Trading Statement Calls',
		IS_TRANSCRIPT: true
	},
	GUIDANCE_UPDATE_CALLS: {
		API_ID: 17,
		NAME: 'Guidance/Update Calls',
		IS_TRANSCRIPT: true
	},
	INTERIM_MANAGEMENT_CALLS: {
		API_ID: 18,
		NAME: 'Interim Management Statement Calls',
		IS_TRANSCRIPT: true
	},
	OPERATING_RESULTS_CALLS: {
		API_ID: 19,
		NAME: 'Operating Results Calls',
		IS_TRANSCRIPT: true
	},
	FIXED_INCOME_CALLS: {
		API_ID: 20,
		NAME: 'Fixed Income Calls',
		IS_TRANSCRIPT: true
	}
};

export const TRANSCRIPT_SORT_LEGACY = [
	DOCUMENT_TYPES_LEGACY.EARNINGS_CALL,
	DOCUMENT_TYPES_LEGACY.SALES_AND_REVENUE_CALL,
	DOCUMENT_TYPES_LEGACY.PRESENTATION,
	DOCUMENT_TYPES_LEGACY.SPECIAL_SITUATION,
	DOCUMENT_TYPES_LEGACY.GUIDANCE_CALL,
	DOCUMENT_TYPES_LEGACY.ANALYST,
	DOCUMENT_TYPES_LEGACY.NEWS,
	DOCUMENT_TYPES_LEGACY.COMPANY_CONFERENCE_PRESENTATIONS,
	DOCUMENT_TYPES_LEGACY.SHAREHOLDER_ANALYST_CALLS,
	DOCUMENT_TYPES_LEGACY.ANALYST_INVESTOR_DAY,
	DOCUMENT_TYPES_LEGACY.SPECIAL_CALLS,
	DOCUMENT_TYPES_LEGACY.M_A_CALLS,
	DOCUMENT_TYPES_LEGACY.SALES_TRADING_CALLS,
	DOCUMENT_TYPES_LEGACY.GUIDANCE_UPDATE_CALLS,
	DOCUMENT_TYPES_LEGACY.INTERIM_MANAGEMENT_CALLS,
	DOCUMENT_TYPES_LEGACY.OPERATING_RESULTS_CALLS,
	DOCUMENT_TYPES_LEGACY.FIXED_INCOME_CALLS
];

export const TEN_K_DOC_TYPES_LEGACY = {
	SUBSECTION_HIGHLIGHT: 'subsection_highlight'
};

export const SEC_SORT_LEGACY = [
	DOCUMENT_TYPES_LEGACY.TEN_K,
	DOCUMENT_TYPES_LEGACY.TEN_Q,
	DOCUMENT_TYPES_LEGACY.EIGHT_K
	// DOCUMENT_TYPES_LEGACY.TWENTY_F,
	// DOCUMENT_TYPES_LEGACY.FORTY_F,
	// DOCUMENT_TYPES_LEGACY.SIX_K
];
