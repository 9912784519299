import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import currentSubscriptionState from '../../recoil/currentSubscription';
import * as ids from '../../../id.constants';
import { Text } from '../../../components';
import { Colors, FontFamilies, FontSizes } from '../../../components/shared/styles';
import { PortfolioCompany } from '../../types/types';
import * as keyDriversTypes from '../../types/types_keydrivers';
import DocumentListNewarc from './documentListNewarc';
import AmenityScoreGraph from './AmenityScoreGraph';
import {
	getDataForKeyDriverChartsNewark,
	getDataForAmenityScoreGraphNewark
} from './utils/utilsNewark';
import NavigationService from '../../services/navigation.service';
import { useCompanyDocuments } from './companyDocuments.service';
import KeyDriverChartsNewark from './keyDriversChartsNewark';
import NoDocumentsMessage from '../Table/noDocumentsMessage';
import UserTracking from '../../services/userTracking.service';
import { USER_TRACKING as UT } from '../../constants';
import currentProviderState from '../../recoil/currentProvider';

const UTC = UT.USER_TRACKING_CATEGORIES;
const UTA = UT.USER_TRACKING_ACTIONS;

const ExpandedRowContainer = styled.div<{ name: string }>`
	background-color: #fbfbfb;
	padding: 20px 0;
	width: 100%;
	position: relative;
	border: 1px solid #eee;
	box-sizing: border-box;
	box-shadow: 0 11px 22px -11px rgba(130, 169, 179, 0.5);
`;

const Title = styled.h3`
	margin: 10px 25px;
	height: 27px;
	line-height: 27px;
`;

const NoDocuments = styled.div`
	padding-left: 25px;
`;

const RecentTranscripts = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 10px 25px 0;
`;

interface ExpandRowProps {
	company: PortfolioCompany;
	categories: any[];
}

const ExpandedRow: FC<ExpandRowProps> = (props: ExpandRowProps) => {
	const currentSubscriptionId = useRecoilValue(currentSubscriptionState);
	const currentProvider = useRecoilValue(currentProviderState);
	const userTracking: UserTracking = UserTracking.getInstance();
	const [allDocuments, setAllDocuments] = useState<any[]>([]);
	const { company, categories } = props;
	const companyId: string = company.id.toString();
	const [cursor, setCursor] = useState('');
	const { loading, documents, companyData, nextCursor } = useCompanyDocuments(
		currentSubscriptionId,
		companyId,
		company.name,
		cursor
	);

	useEffect(() => {
		if (!loading) {
			setAllDocuments([...allDocuments, ...documents]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	const amenityScoreChartData = getDataForAmenityScoreGraphNewark(allDocuments);
	const documentsExist = documents?.length > 0;
	const keyDriversData: keyDriversTypes.KeyDriverChartNewArk[] = documentsExist
		? getDataForKeyDriverChartsNewark(companyData)
		: [];

	const getMoreDocuments = () => {
		setCursor(nextCursor);
	};

	const onClickTranscript = (documentId, documentTypePath) => {
		NavigationService.getInstance().goToSubscriptionDocument(
			currentSubscriptionId,
			documentId,
			documentTypePath
		);
		userTracking.setEvent(UTC.TRANSCRIPT, UTA.TRANSCRIPT_OPENED, company.ticker, {
			documentId,
			documentName: allDocuments.find(doc => doc.documentId === documentId)?.title,
			provider: currentProvider
		});
	};

	return (
		<ExpandedRowContainer
			id={ids.WACTHLIST_PAGE.EXPAND_ROW_CONTAINER}
			name={`${ids.WACTHLIST_PAGE.EXPAND_ROW_CONTAINER}_${company.ticker}`}
		>
			<Title>
				<Text size={FontSizes.m1} color={Colors.black} fontFamily={FontFamilies.bold}>
					{company.name}
				</Text>
			</Title>
			{keyDriversData.length > 0 && (
				<KeyDriverChartsNewark
					company={company}
					keyDriversData={keyDriversData}
					categories={categories}
				/>
			)}
			{documentsExist && (
				<RecentTranscripts>
					<DocumentListNewarc
						onClickTranscript={onClickTranscript}
						company={company}
						companyDocuments={{
							updateDate: 1,
							data: allDocuments
						}}
						subscriptionId={currentSubscriptionId}
						cursor={nextCursor}
						getMoreDocuments={getMoreDocuments}
					/>
					{amenityScoreChartData.length > 1 && (
						<AmenityScoreGraph amenityScoreChartData={amenityScoreChartData} />
					)}
				</RecentTranscripts>
			)}
			<NoDocuments>{!loading && !documentsExist && <NoDocumentsMessage />}</NoDocuments>
		</ExpandedRowContainer>
	);
};

export default ExpandedRow;
