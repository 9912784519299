import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { LogoAppearance } from '../shared/styles';
import darkLogoImg from '../../assets/images/Logo-Amenity-Dark.svg';
import darkLogoGroupImg from '../../assets/images/Logo-Amenity-Dark-Group.svg';
import lightLogoImg from '../../assets/images/Logo-Amenity-Light.svg';
import lightLogoGroupImg from '../../assets/images/Logo-Amenity-Light-Group.svg';

interface StyledLogoProps {
	onClick?(): void;
	src: string;
}

const StyledLogo = styled.img<StyledLogoProps>`
	cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

const DarkLogo = styled(StyledLogo).attrs({
	src: darkLogoImg
})`
	height: 37px;
	width: 262px;
`;

const DarkLogoGroup = styled(StyledLogo).attrs({
	src: darkLogoGroupImg
})`
	height: 24px;
	width: 335px;
`;

const LightLogo = styled(StyledLogo).attrs({
	src: lightLogoImg
})`
	height: 36px;
	width: 260px;
`;

const LightLogoGroup = styled(StyledLogo).attrs({
	src: lightLogoGroupImg
})`
	height: 23px;
	width: 335px;
`;

export interface LogoProps {
	className?: string;
	logoAppearance?: LogoAppearance;
	onClick?(): void;
}

export const Logo: FunctionComponent<LogoProps> = ({
	className,
	logoAppearance = LogoAppearance.Dark,
	onClick
}) => {
	const logoMap = {
		[LogoAppearance.Dark]: <DarkLogo className={className} onClick={onClick} />,
		[LogoAppearance.DarkGroup]: <DarkLogoGroup className={className} onClick={onClick} />,
		[LogoAppearance.Light]: <LightLogo className={className} onClick={onClick} />,
		[LogoAppearance.LightGroup]: <LightLogoGroup className={className} onClick={onClick} />
	};
	return logoMap[logoAppearance];
};
