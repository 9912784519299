import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observe } from 'mobx';
import styled from 'styled-components';
import * as _ from 'lodash';

import Images from '../../shared/images';
import * as ids from '../../../id.constants.js';
import {
	APP_COLORS,
	ARTICLE_MEDIA_TYPES,
	ATTRIBUTIONS,
	DOCUMENT_PROVIDERS,
	DOCUMENT_RIGHT_OFFSET,
	DOCUMENT_SIDEBAR_MARGIN,
	DOCUMENT_SIDEBAR_RIGHT_WIDTH,
	DOCUMENT_SIDEBAR_WIDTH,
	DOCUMENT_SIDEBAR_WIDTH_CLOSED,
	EVENT_NAVIGATION_PREFIX_TEN_K,
	IFRAME_DOCUMENT_TYPE,
	LOCAL_STORAGE_KEYS,
	SIDE_BAR_TABS,
	STORE_AUTH,
	STORE_DATA,
	STORE_FEATURES,
	STORE_GLOBAL,
	STORE_UI,
	USER_TRACKING
} from '../../constants';
import { TEN_K_DOC_TYPES_LEGACY } from '../../types/DocTypes.types';
import * as DocumentUtil from '../document/document.util';
import {
	MatchingSelector,
	OutboundEvent,
	Part,
	PartItem,
	Polarity,
	TenKNavigation,
	UserAction,
	UserActionData
} from '../../types/types';

import DataStore from '../../stores/data.store';
import GlobalStore from '../../stores/global.store';
import UIStore from '../../stores/ui.store';
import AuthStore from '../../stores/auth.store';
import FeaturesStore from '../../stores/features.store';

import * as Util from '../../services/util.service';
import DataService from '../../services/data.service';
import UserTracking from '../../services/userTracking.service';
import * as localStorageSrv from '../../services/localstorage.service';
import { setObject } from '../../services/localstorage.service';
import NavigationService from '../../services/navigation.service';

import Toolbar from '../../components/Toolbar/toolbar';
import GenericPopup from '../../widgets/GenericPopup/genericPopup';
import RecentDocumentsDropDown from '../document/recentDocumentsDropDown';
import { DocumentDetail } from '../../shared/styled/text-layout-components';
import DocumentSideBar from '../../components/DocumentSideBar/documentSideBar';
import { BackButtonContainer, BackButtonImg, SubHeader } from '../pageElements';
import AddCompanyContainer from '../../components/AddCompanyBanner/AddCompanyContainer';
import IframePlaceHolder, {
	documentIframeRef
} from '../../components/DocumentIframe/documentIframe';
import { Colors } from '../../../components/shared/styles';
import DocumentRightSidebar from '../../components/DocumentSideBar/documentRightSidebar';
import { strToPolarity } from '../../../components/shared/util';

const UTC = USER_TRACKING.USER_TRACKING_CATEGORIES;
const UTA = USER_TRACKING.USER_TRACKING_ACTIONS;

const Container = styled.div`
	width: 100%;
	height: 100%;
`;

const BodyContainer = styled.div`
	height: calc(100% - 100px);
	background-color: ${Colors.lightGray};
`;

interface LayoutProps {
	isEventsPanelOpened: boolean;
	ffViewerDocumentFilters?: boolean;
	isRightSidePanelOpened?: boolean;
}

const DocumentSectionContainer = styled.div<LayoutProps>`
	display: inline-block;
	position: relative;
	height: 100%;
	width: ${({ isEventsPanelOpened, ffViewerDocumentFilters, isRightSidePanelOpened }) => {
		if (ffViewerDocumentFilters) {
			if (isRightSidePanelOpened) {
				return isEventsPanelOpened
					? `calc(100% - ${DOCUMENT_SIDEBAR_WIDTH +
							DOCUMENT_SIDEBAR_RIGHT_WIDTH +
							DOCUMENT_SIDEBAR_MARGIN * 2 -
							DOCUMENT_RIGHT_OFFSET}px)`
					: `calc(100% - ${DOCUMENT_SIDEBAR_RIGHT_WIDTH +
							DOCUMENT_SIDEBAR_MARGIN -
							DOCUMENT_RIGHT_OFFSET}px)`;
			}
			return isEventsPanelOpened
				? `calc(100% - ${DOCUMENT_SIDEBAR_WIDTH +
						DOCUMENT_SIDEBAR_WIDTH_CLOSED +
						DOCUMENT_SIDEBAR_MARGIN * 2 -
						DOCUMENT_RIGHT_OFFSET}px)`
				: `calc(100% - ${DOCUMENT_SIDEBAR_WIDTH_CLOSED +
						DOCUMENT_SIDEBAR_MARGIN -
						DOCUMENT_RIGHT_OFFSET}px)`;
		}
		return isEventsPanelOpened
			? `calc(100% - ${DOCUMENT_SIDEBAR_WIDTH + DOCUMENT_SIDEBAR_MARGIN}px)`
			: `calc(100% - ${DOCUMENT_SIDEBAR_MARGIN}px)`;
	}};
	overflow: hidden;
	box-sizing: border-box;
	background-color: ${Colors.lightGray};
`;

const DocumentInnerContainer = styled.div`
	height: 100%;
	background-color: white;
	margin: 0 ${DOCUMENT_SIDEBAR_MARGIN}px;
	border: 1px solid ${APP_COLORS.LIGHT_GREY_D1D1D1};
`;

const DocumentHeaderContainer = styled.div`
	height: 50px;
	min-width: 550px;
	padding: 20px 0 0 20px;
	box-sizing: border-box;
	border-bottom: 1px solid #dde5e7;
`;

const OpenCloseEventsPanel = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 17px;
	height: 35px;
	cursor: pointer;
	border-radius: 4px;
	border: solid 1px ${APP_COLORS.LIGHT_GRAY_DDE5E7};
	background-color: ${Colors.lightGray};
	position: absolute;
	top: 50%;
	left: 8px;
	border-left: solid 1px ${Colors.lightGray};
	box-shadow: 3px 2px 4px -1px rgba(0, 0, 0, 0.15);
`;

const ArrowOpenCloseEventsPanel = styled.div<{ isEventsPanelOpened: boolean }>`
	width: 14px;
	height: 21px;
	mask-image: url(${Images.arrowDownDropdown});
	transform: rotate(${props => (props.isEventsPanelOpened ? '90deg' : '-90deg')});
	mask-size: contain;
	mask-position: center;
	-webkit-mask-repeat: no-repeat;
	background-color: ${Colors.blue};
	margin-top: 2px;
`;

const DocumentTextScroll = styled.div<{ isTranscript?: boolean }>`
	height: ${props => (props.isTranscript ? 'calc(100% - 50px)' : 'calc(100% - 42px)')};
	box-sizing: border-box;
	overflow-y: auto;
	padding-left: 40px;
	padding-top: ${props => (props.isTranscript ? '' : '2px')};
`;

const SidebarContainer = styled.div<{ isEventsPanelOpened: boolean }>`
	width: 380px;
	height: 100%;
	border: 1px solid ${APP_COLORS.LIGHT_GREY_D1D1D1};
	overflow-y: hidden;
	box-sizing: border-box;
	display: ${props => (props.isEventsPanelOpened ? 'inline-block' : 'none')};
	background-color: ${Colors.white};
`;

interface DocumentPageProps {
	flowId: number;
	modelId?: number;
	eventId?: string;
	documentId: number;
	documentType: string;
	fetchDocument?: (document: any) => void;
}

interface DocumentPageState {
	tenKNavigation: TenKNavigation;
	currentViewedEventId: number;
	selectedEventId: number;
	openCategoryContainingEvent: boolean;
	navReloadCounter: number;
	loadingEvents: boolean;
	loadingSections: boolean;
	selectedMatch: number;
	totalMatches: number;
	matchingSelectors: MatchingSelector[];
	errorPopup: boolean;
	errorPopupDescription: string;
}

@inject(STORE_DATA, STORE_GLOBAL, STORE_AUTH, STORE_FEATURES, STORE_UI)
@observer
export default class Document extends React.Component<DocumentPageProps, DocumentPageState> {
	navService: NavigationService;
	history: any;
	userTracking: any;
	neutralEventsObserver: any;

	constructor(props) {
		super(props);
		this.state = {
			tenKNavigation: undefined,
			currentViewedEventId: -1,
			selectedEventId: undefined,
			openCategoryContainingEvent: false,
			navReloadCounter: 0,
			loadingEvents: props.documentType !== IFRAME_DOCUMENT_TYPE.ARTICLE,
			loadingSections: true,
			selectedMatch: undefined,
			totalMatches: undefined,
			matchingSelectors: [],
			errorPopup: false,
			errorPopupDescription: ''
		};
		this.navService = NavigationService.instance;
		this.history = this.navService.history;
		this.userTracking = new UserTracking();
		this.neutralEventsObserver = observe(
			this.props[STORE_GLOBAL],
			'showNeutralExtractions',
			(change: any) => {
				this.toggleNeutralEvents(change.newValue);
			}
		);
	}

	componentDidMount() {
		window.addEventListener('message', this.onReceiveMsg);
		const { eventId } = this.props;
		if (eventId) {
			this.scrollToEventByOffset(eventId);
		}
		this.toggleSpinner(true);
	}

	componentWillUnmount() {
		window.removeEventListener('message', this.onReceiveMsg);
		DocumentUtil.removeHighlightedMatches(ids.SEARCH_HIGHLIGHT_STYLE);
		DocumentUtil.removeHighlightedMatches(ids.SEARCH_SELECTED_HIGHLIGHT_STYLE);
		this.neutralEventsObserver();
	}

	clearData = () => {
		this.setState({
			tenKNavigation: undefined,
			loadingEvents: false,
			loadingSections: false
		});
		documentIframeRef && (documentIframeRef.src = '');
		const el: any = document.getElementById('DOCUMENT_SEARCH_INPUT');
		if (el) {
			el.value = '';
		}
	};

	changeTenKDocument = (document: any) => {
		const { setShowSpinner } = this.props[STORE_UI] as UIStore;

		setShowSpinner(true);
		this.clearData();
		this.props.fetchDocument && this.props.fetchDocument(document);
		setTimeout(() => {
			setShowSpinner(false);
			if (_.isEmpty(this.state.tenKNavigation)) {
				this.setState({ loadingEvents: true, loadingSections: true });
			}
		}, 2000);
	};

	postMessageToIframe = (dataToSend: any) => {
		const iframeWin = window.frames['document_iframe'];
		iframeWin?.postMessage(dataToSend, '*');
	};

	onReceiveMsg = event => {
		console.log('onReceiveMsg', event);
		if (!Util.isAllowedOriginDocIframe(event.origin)) {
			return;
		}
		const { msg_type, documentId } = event.data;
		if (this.props.documentId !== documentId) {
			return;
		}
		switch (msg_type) {
			case 'SECTIONS':
				this.setTenKSectionNavigation(event.data.sections);
				break;
			case 'EVENTS':
				this.setTenKEventsNavigation(event.data.events);
				break;
			case 'GET_DOCUMENT_TYPE':
				this.setShowEventsSort(event.data.docType);
				break;
			case 'SEARCH_PERFORMED':
				this.setTenKSearchState(event.data.state);
				break;
			case 'ON_LOAD':
				this.toggleSpinner(false);
				this.setAttributionToIFrame();
				this.toggleNeutralEvents(true);
				break;
			case 'ERROR':
				this.toggleSpinner(false);
				this.navService.goToNotFound();
				break;
			case 'METADATA':
				this.setMetadata(event.data.metadata);
				break;
			case 'FEATURES':
				this.sendFeaturesEligibility();
				break;
		}
	};

	toggleSpinner = (on: boolean) => {
		if (this.props.documentType !== IFRAME_DOCUMENT_TYPE.ARTICLE) {
			return;
		}
		const { setShowSpinner } = this.props[STORE_UI] as UIStore;
		setShowSpinner(on, true);
	};

	sendFeaturesEligibility = () => {
		const interval = setInterval(() => {
			const { userFeaturePermissions } = this.props[STORE_FEATURES] as FeaturesStore;
			if (userFeaturePermissions) {
				clearInterval(interval);
				this.postMessageToIframe({
					action: 'set_features',
					data: userFeaturePermissions
				});
			}
		}, 200);
	};

	setMetadata = (metadata: {
		publicationDate: string;
		title: string;
		source: string;
		sourceUrl: string;
		mediaType: string;
	}) => {
		const { publicationDate, title, source, sourceUrl, mediaType } = metadata;
		(this.props[STORE_DATA] as DataStore).setCurrentDocumentMetaData({
			publicationDate: new Date(publicationDate),
			title,
			source,
			sourceUrl,
			mediaType
		});
		this.userTracking.setEvent(UTC.NEWS, UTA.ARTICLE_OPENED, title, {
			provider: DOCUMENT_PROVIDERS.LEXIS_NEXIS,
			documentName: title
		});
	};

	setShowEventsSort = (docType: string) => {
		const tenKNavigation = { ...this.state.tenKNavigation };
		if (docType === TEN_K_DOC_TYPES_LEGACY.SUBSECTION_HIGHLIGHT) {
			tenKNavigation.showSort = false;
		} else {
			tenKNavigation.showSort = true;
		}
		this.setState({ tenKNavigation, loadingSections: false });
	};

	setTenKEventsNavigation = (events: OutboundEvent[]) => {
		const { eventTypes, setIsKeyDriverModel } = this.props[STORE_DATA] as DataStore;

		const isKeyDriverModel = _.some(events, e => !!e.keyDrivers);
		setIsKeyDriverModel(isKeyDriverModel);

		const parsedEvents = Util.parseTenKEvents(eventTypes, events, isKeyDriverModel);
		this.postMessageToIframe({
			action: 'events_with_types',
			data: parsedEvents
		});
		const tenKNavigation = { ...this.state.tenKNavigation };
		tenKNavigation.events = parsedEvents;
		this.postMessageToIframe({
			action: 'check_document_type',
			data: TEN_K_DOC_TYPES_LEGACY.SUBSECTION_HIGHLIGHT
		});

		this.setState({ tenKNavigation, loadingEvents: false });
	};

	setTenKSectionNavigation = (sections: Part[]) => {
		const tenKNavigation = { ...this.state.tenKNavigation };
		tenKNavigation.sections = { parts: sections };
		this.setState({ tenKNavigation, loadingSections: false });
	};

	scrollToEventByOffset = (offset: string) => {
		const TIMEOUT_SEC = 60;
		const scrollToEventInterval = setInterval(() => {
			const events = this.state.tenKNavigation ? this.state.tenKNavigation.events : [];
			const foundEvent = _.find(events, (event: OutboundEvent) => event.hash === offset);
			if (foundEvent) {
				if (strToPolarity(foundEvent.polarity) === Polarity.neutral) {
					(this.props[STORE_GLOBAL] as GlobalStore).setShowNeutralExtractions(true);
				}
				this.onEventClicked(foundEvent, true);
				clearInterval(scrollToEventInterval);
				clearTimeout(stopIntervalTimeOut);
			}
		}, 1 * 1000);
		const stopIntervalTimeOut = setTimeout(() => {
			clearInterval(scrollToEventInterval);
		}, TIMEOUT_SEC * 1000);
	};

	navigateBack = () => {
		const prevRoute = localStorageSrv.getString(LOCAL_STORAGE_KEYS.PREV_VISITED_PAGE);

		if (prevRoute && !prevRoute.includes('events_summary')) {
			this.navService.goToRoot();
		} else {
			this.navService.back();
		}
	};

	onEventClicked = (event: OutboundEvent, shouldOpenCategory?: boolean) => {
		const lastClickedEvent = (this.props[STORE_GLOBAL] as GlobalStore).navigatedEvent;

		this.changeTenKEventBackColor(lastClickedEvent, false);
		this.changeTenKEventBackColor(event, true);
		this.navigateToClickedElmTenK(event.id);

		(this.props[STORE_GLOBAL] as GlobalStore).setNavigatedEvent(event);
		if (shouldOpenCategory) {
			this.setState({ selectedEventId: event.id, openCategoryContainingEvent: true }, () => {
				// need this so that the category won't be reopened on re-render
				setTimeout(() => {
					const element = document.getElementById(`event-navigation-${event.id}`);
					if (element) {
						element.scrollIntoView();
					}
				}, 0);
				this.setState({ openCategoryContainingEvent: false });
			});
		} else {
			this.setState({ selectedEventId: event.id });
		}

		const title = (this.props[STORE_DATA] as DataStore).currentDocumentMetaData.title;
		const label = `Document ${title}_${event.id}`;
		this.userTracking.setEvent(UTC.TEN_K, UTA.TEN_K_EVENT_NAVIGATION_CLICKED, label, {
			documentName: title
		});
	};

	changeTenKEventBackColor = (event: OutboundEvent, highlight: boolean) => {
		if (!event) {
			return;
		}
		const data = {
			action: 'highlight_backgroud',
			data: {
				navigationId: event.id,
				highlight
			}
		};
		this.postMessageToIframe(data);
	};

	onSectionClickedTenK = (item: PartItem) => {
		this.navigateToClickedElmTenK(item.navigationId);
		const title = (this.props[STORE_DATA] as DataStore).currentDocumentMetaData.title;
		const label = `Document ${title}_${item.name}`;
		this.userTracking.setEvent(UTC.TEN_K, UTA.TEN_K_SECTION_CLICKED, label);
	};

	navigateToClickedElmTenK = (elmId: string | number) => {
		const data = {
			action: 'scroll',
			data: elmId
		};
		this.postMessageToIframe(data);
	};

	printArticle = () => {
		const { currentDocumentMetaData } = this.props[STORE_DATA] as DataStore;
		const { showNeutralExtractions } = this.props[STORE_GLOBAL] as GlobalStore;
		const { flowId, modelId, documentId, eventId } = this.props;
		this.userTracking.setEvent(UTC.NEWS, UTA.ARTICLE_PRINT, currentDocumentMetaData.title, {
			documentName: currentDocumentMetaData.title
		});
		this.navService.goToPrintArticle(flowId, modelId, documentId, eventId, showNeutralExtractions);
	};

	print10KSummary = () => {
		const { currentCompany, currentDocumentMetaData } = this.props[STORE_DATA] as DataStore;
		let publishTime = '';
		if (
			currentDocumentMetaData &&
			currentDocumentMetaData.eventDate &&
			currentDocumentMetaData.publicationDate
		) {
			publishTime = Util.formatDate(currentDocumentMetaData.publicationDate);
		}
		const vendor = currentDocumentMetaData && currentDocumentMetaData.vendor;
		const footnote = (vendor && ATTRIBUTIONS[vendor]) || '';
		const { tenKNavigation } = this.state;
		const sections = Util.buildPrintSummaryData(tenKNavigation.events);
		const printData = {
			companyName: `${currentCompany.name} (${currentCompany.ticker})`,
			publishTime,
			sections,
			footnote
		};
		const success = setObject(LOCAL_STORAGE_KEYS.PRINT_DATA, printData);
		if (success) {
			this.userTracking.setEvent(
				UTC.TEN_K_SUMMARY,
				UTA.SUMMARY_PRINTED,
				`${currentCompany.name}_${currentDocumentMetaData.documentId}`
			);
			window.open(`${window.location.origin}/print_summary`);
		} else {
			this.toggleErrorPopup(true, 'The summary could not be created.');
		}
	};

	sendUserAction = () => {
		const ds = DataService.getInstance();
		const params: UserActionData = {
			documentId: (this.props[STORE_DATA] as DataStore).currentDocumentMetaData.documentId,
			flowId: (this.props[STORE_DATA] as DataStore).currentDocumentMetaData.flowId,
			action: UserAction.COMPANY_ADDED
		};
		ds.userActionCompanyAdded(
			params,
			() => console.log('SUCCESS sending user action'),
			err => console.log('FAIL. res ? ', err)
		);
	};

	toggleNeutralEvents = (state: boolean) => {
		this.postMessageToIframe({
			action: 'toggle_neutral',
			data: state
		});
	};

	setAttributionToIFrame = () => {
		if (this.props.documentType === IFRAME_DOCUMENT_TYPE.ARTICLE) {
			return;
		}
		const { currentDocumentMetaData } = this.props[STORE_DATA] as DataStore;
		const vendor = currentDocumentMetaData && currentDocumentMetaData.vendor;
		const attr = vendor && ATTRIBUTIONS[vendor];
		if (!attr) {
			return;
		}
		this.postMessageToIframe({
			action: 'set_attribution',
			data: attr
		});
	};

	navigateSearchInTenK = (direction: number) => {
		const { selectedMatch, totalMatches } = this.state;
		let newIdx = selectedMatch + direction / Math.abs(direction);
		if (newIdx === totalMatches) {
			newIdx = 0;
		} else if (newIdx < 0) {
			newIdx = totalMatches - 1;
		}
		this.postMessageToIframe({ action: 'navigate_search', data: newIdx });
		this.setState({ selectedMatch: newIdx });
	};

	searchInTenK = _.debounce((text: string) => {
		if (text && text.length > 1 && text.trim().length > 0) {
			this.searchInDocumentSendEvent(text);
		}
		this.postMessageToIframe({ action: 'text_search', data: text });
	}, 200);

	setTenKSearchState = (state: { matches: any; selectedMatch: number }) => {
		const { matches, selectedMatch } = state;
		this.setState({
			selectedMatch,
			totalMatches: matches.length,
			matchingSelectors: []
		});
	};

	searchInDocumentSendEvent = (text: string) => {
		const { currentDocumentMetaData } = this.props[STORE_DATA] as DataStore;
		const docTitle = currentDocumentMetaData && currentDocumentMetaData.title;
		if (this.props.documentType === IFRAME_DOCUMENT_TYPE.ARTICLE) {
			this.userTracking.setEvent(UTC.NEWS, UTA.ARTICLE_SEARCH, docTitle, {
				documentName: docTitle
			});
		} else {
			this.userTracking.setEvent(
				UTC.DOCUMENT_VIEW,
				UTA.SEARCH_ON_DOCUMENT,
				`${docTitle}_${text}_document`,
				{ documentName: docTitle }
			);
		}
	};

	toggleErrorPopup = (state: boolean, message?: string) =>
		this.setState({ errorPopup: state, errorPopupDescription: message });

	getSideBarTabs = (documentType: string, ffViewerDocumentFilters: boolean) => {
		switch (documentType) {
			case IFRAME_DOCUMENT_TYPE.SEC_FILINGS:
				if (ffViewerDocumentFilters) {
					return { [SIDE_BAR_TABS.KeyDrivers]: '37%' };
				}
				return {
					[SIDE_BAR_TABS.KeyDrivers]: '50%',
					[SIDE_BAR_TABS.Sections]: '50%'
				};
			case IFRAME_DOCUMENT_TYPE.ARTICLE:
				return { [SIDE_BAR_TABS.KeyDrivers]: '37%' };
			default:
				return [];
		}
	};

	render() {
		const { userFeaturePermissions } = this.props[STORE_FEATURES] as FeaturesStore;
		const { profile } = this.props[STORE_AUTH] as AuthStore;
		const {
			currentDocumentMetaData,
			currentCompany,
			currentFlow,
			companyDocuments,
			isKeyDriverModel
		} = this.props[STORE_DATA] as DataStore;
		const {
			isEventsPanelOpened,
			isRightSidePanelOpened,
			toggleEventsPanel,
			showNeutralExtractions,
			toggleRightSidePanelOpened
		} = this.props[STORE_GLOBAL] as GlobalStore;
		const { tenKNavigation, selectedMatch, totalMatches } = this.state;

		const ffViewerDocumentFilters = userFeaturePermissions?.viewer_document_filters;

		const isArticleType = this.props.documentType === IFRAME_DOCUMENT_TYPE.ARTICLE;

		const title = currentDocumentMetaData.documentDisplayName || currentDocumentMetaData.title;
		const { source, mediaType } = currentDocumentMetaData;
		const sourceUrl =
			mediaType?.toLowerCase() !== ARTICLE_MEDIA_TYPES.PRINT && currentDocumentMetaData.sourceUrl;
		let publicationTime, callTime, transcriptTime;
		if (isArticleType) {
			publicationTime =
				currentDocumentMetaData &&
				currentDocumentMetaData.publicationDate &&
				Util.formatDate(currentDocumentMetaData.publicationDate);
		} else {
			publicationTime =
				currentDocumentMetaData.eventDate &&
				currentDocumentMetaData.publicationDate &&
				Util.formatDate(currentDocumentMetaData.publicationDate);
			callTime =
				currentDocumentMetaData.eventDate || currentDocumentMetaData.publicationDate
					? Util.formatDate(
							currentDocumentMetaData.eventDate
								? currentDocumentMetaData.eventDate
								: currentDocumentMetaData.publicationDate
					  )
					: '';
			transcriptTime = currentDocumentMetaData.analysisDate
				? Util.formatDate(currentDocumentMetaData.analysisDate)
				: '';
		}

		const onlySubsectionEvents =
			tenKNavigation &&
			tenKNavigation.events &&
			tenKNavigation.events[0] &&
			tenKNavigation.events[0].isSubsection;

		const currentCompanyDocuments =
			currentCompany && currentCompany.id && companyDocuments[currentCompany.id];

		const events = showNeutralExtractions
			? tenKNavigation?.events
			: _.filter(tenKNavigation?.events, (event: OutboundEvent) => event.polarity !== 'neutral');

		const leftSidebarTabs = this.getSideBarTabs(this.props.documentType, ffViewerDocumentFilters);

		const rightSidebarTabs = { [SIDE_BAR_TABS.Sections]: '50%' };

		return (
			<Container>
				<SubHeader>
					<BackButtonContainer width={isArticleType ? 'calc(100% - 410px)' : undefined}>
						{!isArticleType && (
							<BackButtonImg
								id='DOCUMENT_PAGE_NAVIGATE_BACK'
								onClick={this.navigateBack}
								src={Images.backIcon}
								size={15}
							/>
						)}
						<RecentDocumentsDropDown
							companyDocuments={currentCompanyDocuments ? currentCompanyDocuments.data : []}
							currentCompany={currentCompany}
							documentFlow={currentFlow}
							isTranscriptDocument={false}
							documentTitle={title}
							currentDocumentId={currentDocumentMetaData.documentId}
							changeDocument={this.changeTenKDocument}
							isInvisible={isArticleType}
							source={source}
							sourceUrl={sourceUrl}
						/>
					</BackButtonContainer>
					{/* TODO: change this in refactor, move subHeader to new component and */}
					<Toolbar
						toggleEditMode={undefined}
						printTranscript={() => (isArticleType ? this.printArticle() : this.print10KSummary())}
						searchText={this.searchInTenK}
						navigateSearch={this.navigateSearchInTenK}
						editMode={false}
						hideEdit={isArticleType}
						printLabel={isArticleType ? 'Print Article' : 'Print Summary'}
						disablePrint={!isArticleType && !onlySubsectionEvents}
						disableEdit={true}
						selectedMatch={selectedMatch + 1}
						totalMatches={totalMatches}
					/>
				</SubHeader>
				<BodyContainer>
					<SidebarContainer isEventsPanelOpened={isEventsPanelOpened}>
						<DocumentSideBar
							tabs={leftSidebarTabs}
							events={events}
							sections={tenKNavigation && tenKNavigation.sections && tenKNavigation.sections.parts}
							selectedEventId={this.state.selectedEventId}
							elementIdPrefix={EVENT_NAVIGATION_PREFIX_TEN_K}
							openCategoryContainingEvent={this.state.openCategoryContainingEvent}
							showSort={tenKNavigation && tenKNavigation.showSort}
							loadingEvents={this.state.loadingEvents}
							loadingSections={this.state.loadingSections}
							navReloadCounter={this.state.navReloadCounter}
							onSectionClick={this.onSectionClickedTenK}
							onEventSelectionList={event => this.onEventClicked(event)}
							keyDriverModel={isKeyDriverModel}
							initialTab={SIDE_BAR_TABS.KeyDrivers}
						/>
					</SidebarContainer>
					<DocumentSectionContainer
						isEventsPanelOpened={isEventsPanelOpened}
						ffViewerDocumentFilters={!isArticleType && ffViewerDocumentFilters}
						isRightSidePanelOpened={isRightSidePanelOpened}
					>
						<DocumentInnerContainer>
							<DocumentHeaderContainer>
								{callTime && <DocumentDetail>Event Time: {callTime}</DocumentDetail>}
								{transcriptTime && <DocumentDetail>Analysis Time: {transcriptTime}</DocumentDetail>}
								{publicationTime && (
									<DocumentDetail>Publish Time: {publicationTime}</DocumentDetail>
								)}
							</DocumentHeaderContainer>
							<DocumentTextScroll id='TRANSCRIPT_DOCUMENT_CONTAINER'>
								<OpenCloseEventsPanel onClick={() => toggleEventsPanel()}>
									<ArrowOpenCloseEventsPanel isEventsPanelOpened={isEventsPanelOpened} />
								</OpenCloseEventsPanel>
								<IframePlaceHolder
									documentId={this.props.documentId}
									flowId={this.props.flowId}
									modelId={this.props.modelId}
									eventId={this.props.eventId}
									documentType={this.props.documentType}
									username={profile.username}
								/>
							</DocumentTextScroll>
						</DocumentInnerContainer>
					</DocumentSectionContainer>
					{!isArticleType && ffViewerDocumentFilters ? (
						<DocumentRightSidebar
							isOpen={isRightSidePanelOpened}
							onToggle={toggleRightSidePanelOpened}
							label={`Sections`}
						>
							<DocumentSideBar
								tabs={rightSidebarTabs}
								sections={
									tenKNavigation && tenKNavigation.sections && tenKNavigation.sections.parts
								}
								elementIdPrefix={EVENT_NAVIGATION_PREFIX_TEN_K}
								loadingSections={this.state.loadingSections}
								navReloadCounter={this.state.navReloadCounter}
								onSectionClick={this.onSectionClickedTenK}
								keyDriverModel={isKeyDriverModel}
								initialTab={SIDE_BAR_TABS.Sections}
							/>
						</DocumentRightSidebar>
					) : (
						undefined
					)}
				</BodyContainer>
				{this.state.errorPopup && (
					<GenericPopup
						isOpen={this.state.errorPopup}
						message={this.state.errorPopupDescription}
						acceptCallback={() => this.toggleErrorPopup(false)}
						acceptButtonText={'OK'}
						dismissCallback={() => {
							Util.openContact();
							this.toggleErrorPopup(false);
						}}
						dismissButtonText={'Contact us'}
						clickOutsideCallback={() => this.toggleErrorPopup(false)}
					/>
				)}
				<AddCompanyContainer company={currentCompany} />
			</Container>
		);
	}
}
