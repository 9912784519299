import * as React from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import Images from '../../shared/images';
import { STORE_GLOBAL } from '../../constants';
import GlobalStore from '../../stores/global.store';
import { MobileNotSupportedMessage } from '../../types/types';
import { Colors } from '../../../components/shared/styles';
import NavigationService from '../../services/navigation.service';

const Container = styled.div`
	width: 100%;
	height: 100%;
	text-align: center;
	font-family: Assistant;
`;

const Header = styled.div`
	width: 100%;
	height: 60px;
	padding-top: 20px;
	background-color: #274877;
	box-sizing: border-box;
`;

const Content = styled.div`
	width: 100%;
	height: calc(100% - 60px);
	padding: 0 40px 0 40px;
	box-sizing: border-box;
	padding-top: 50px;
`;

const ThankYou = styled.span`
	font-size: 23px;
	font-weight: bold;
	color: ${Colors.blue};
	letter-spacing: 1px;
`;

const SigningUp = styled.span`
	font-size: 16px;
	color: #a6ce39;
`;

const ImgContainer = styled.div`
	margin-top: 50px;
`;

const Text = styled.div`
	margin-top: 50px;
	color: #2c3035;
	font-size: 15px;
`;

@inject(STORE_GLOBAL)
@observer
export default class MobileNotSupported extends React.Component<{}, {}> {
	navService: NavigationService;

	constructor(props) {
		super(props);
		this.navService = NavigationService.instance;
	}

	render() {
		const { mobileNotSupported } = this.props[STORE_GLOBAL] as GlobalStore;

		let notSupportedText = '';
		if (mobileNotSupported === MobileNotSupportedMessage.NOT_SUPPORTED_GENERAL) {
			notSupportedText = 'The mobile version of the Viewer is still under construction';
		} else if (mobileNotSupported === MobileNotSupportedMessage.NOT_SUPPORTED_SIGNUP) {
			notSupportedText =
				'We sent you an email with a link to the Viewer to be opened on a desktop computer';
		}

		return (
			<Container>
				<Header>
					<img alt='logo' src={Images.logoWhite} />
				</Header>
				<Content>
					{mobileNotSupported === MobileNotSupportedMessage.NOT_SUPPORTED_SIGNUP && (
						<div>
							<ThankYou>THANK YOU</ThankYou>
							<br />
							<SigningUp>FOR SIGNING UP</SigningUp>
						</div>
					)}
					<ImgContainer>
						<img alt='not found' src={Images.searchNotFound} />
					</ImgContainer>
					<Text id='notSupportedText'>{notSupportedText}</Text>
				</Content>
			</Container>
		);
	}
}
