import { QueryResult } from '@apollo/client';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import {
	useGetSecFilingQuery,
	useGetTranscriptQuery,
	useGetRecentDocumentsQuery
} from '../../queries/autogenerate/hooks';
import { Speaker } from '../../types/types';
import { FinancialSentence } from '../../queries/autogenerate/schemas';
import currentDocumentTypeState from '../../recoil/currentDocumentType';
import {
	codeFromDocumentType,
	DocumentType,
	DocumentTypeGrouping,
	documentTypeOrderForGrouping,
	getDocumentTypeGrouping
} from '../../types/DocTypes.types';
import {
	GetSecFilingQuery,
	GetSecFilingQueryVariables,
	GetTranscriptQuery,
	GetTranscriptQueryVariables
} from '../../queries/autogenerate/operations';

const GENERAL_KEY_DRIVER = 'General';
const MANAGEMENT_SECTION_NAME = [
	'MANAGEMENT DISCUSSION SECTION',
	'Presentation Operator Message',
	'Presenter Speech'
];
const QNA_SECTION_NAME = [
	'q',
	'a',
	'Q&A',
	'Question',
	'Answer',
	'Question and Answer Section',
	'Question and Answer Operator Message'
];

function getSentenceSpeaker(sentence) {
	const result = {
		name: sentence.speaker.name,
		affiliation: sentence.speaker.companyEntityName,
		affiliationEntity: sentence.speaker.companyEntityId,
		title: sentence.speaker.title,
		entity: undefined,
		type: sentence.section
	};
	return result;
}

function findSpeakersForSection(lastDocument, sectionNames: string[], startIndex = 0) {
	const speakers: Speaker[] = lastDocument?.documentAnalysisResult?.sentences
		.map(sentence => {
			if (!sectionNames.includes(sentence.section)) {
				return [];
			}

			return getSentenceSpeaker(sentence);
			// @ts-ignore
		})
		.filter(speaker => !!speaker?.name);

	for (let i = startIndex; i < speakers?.length; i++) {
		speakers[i].speakingIndex = i;
	}

	return _.uniqBy(speakers, speaker => speaker.name);
}

function getSpeakerFromList(sentence: FinancialSentence, speakers: Speaker[]) {
	const speakerName = sentence.speaker?.name;
	return speakers.find(speaker => speaker.name === speakerName);
}

function getSections(lastDocument, sectionNames: string[], speakers) {
	const sentences = lastDocument?.documentAnalysisResult?.sentences;
	if (!sentences) {
		return [];
	}

	let currentSpeaker = undefined;
	const sections = [];

	for (const sentence of sentences) {
		if (sectionNames.includes(sentence.section)) {
			const speaker = getSpeakerFromList(sentence, speakers);
			if (speaker && speaker?.name !== currentSpeaker?.name) {
				currentSpeaker = speaker;
				sections.push({ speaker, sentences: [] });
			}
			if (sections.length) {
				sections[sections.length - 1].sentences.push(sentence);
			}
		}
	}

	return sections;
}

function extractEvents(lastDocument) {
	let index = 0;
	const deepClonedDocument = _.cloneDeep(lastDocument);
	const events = deepClonedDocument?.documentAnalysisResult?.sentences
		.map(sentence =>
			sentence.events
				.sort((event1, event2) => {
					return event1.startPosition > event2.startPosition ? 1 : -1;
				})
				.map(event => ({
					...event,
					sentenceStartPosition: sentence.startPosition
				}))
		)
		// @ts-ignore
		.flat()
		.map(event => {
			if (!event.indexInTranscript) {
				event.indexInTranscript = index;
				index++;
			}

			const keyDrivers = event.keyDrivers?.length > 0 ? event.keyDrivers : [GENERAL_KEY_DRIVER];
			return { ...event, keyDrivers };
		});
	return events;
}

export interface CommonDocumentData {
	title: string;
	events: any[];
	updateTime: any;
	eventTime: any;
	currentCompany: any;
	currentCompanyDocuments: any;
	vendor: string;
}

interface TranscriptSection {
	name: string;
	speakers: Speaker[];
	sentences: any[];
}

export interface TranscriptData extends CommonDocumentData {
	documentData: Partial<QueryResult<GetTranscriptQuery, GetTranscriptQueryVariables>>;
	qnaSection: TranscriptSection;
	discussionSection: TranscriptSection;
}

export interface SecFilingData extends CommonDocumentData {
	documentData: Partial<QueryResult<GetSecFilingQuery, GetSecFilingQueryVariables>>;
	htmlUrl: string;
}

export function useTranscriptData(
	subscriptionId: string,
	documentId: string,
	skip: boolean,
	provider: string
) {
	const documentType = useBestEffortDocumentType(DocumentTypeGrouping.Transcripts);
	const documentData = useGetTranscriptQuery({
		skip,
		variables: { subscriptionId: subscriptionId, documentId: documentId }
	});

	const document = documentData?.data?.organizationSubscription.financialDocument;
	const currentCompany = document?.entity;

	const companyDocuments = useGetRecentDocumentsQuery({
		skip: skip || !currentCompany || !documentType,
		variables: {
			subscriptionId: subscriptionId,
			companyId: currentCompany?.id,
			documentType: codeFromDocumentType(documentType, provider)
		}
	});

	const currentCompanyDocuments = companyDocuments?.data?.organizationSubscription.companyEntity?.searchFinancialDocuments?.documents.map(
		document => ({
			title: document.documentVersions[0].title,
			documentId: document.id,
			fiscalQuarter: document.fiscalPeriod?.quarterEstimated,
			fiscalYear: document.fiscalPeriod?.yearEstimated,
			publicationDate: document.eventTime,
			eventDate: document.eventTime
		})
	);

	const updateTime = document?.updateTime;
	const eventTime = document?.eventTime;
	const vendor = document?.provider;

	const lastDocument = document?.documentVersions[0];
	const title = lastDocument?.title;

	const events = extractEvents(lastDocument);

	const discussionSpeakers = findSpeakersForSection(lastDocument, MANAGEMENT_SECTION_NAME);
	const qnaSpeakers = findSpeakersForSection(
		lastDocument,
		QNA_SECTION_NAME,
		discussionSpeakers.length
	);

	const discussionSection = {
		name: 'discussion',
		speakers: discussionSpeakers,
		sentences: getSections(lastDocument, MANAGEMENT_SECTION_NAME, discussionSpeakers)
	};

	const qnaSection = {
		name: 'qna',
		speakers: qnaSpeakers,
		sentences: getSections(lastDocument, QNA_SECTION_NAME, qnaSpeakers)
	};

	return {
		documentData,
		title,
		events,
		updateTime,
		eventTime,
		discussionSection,
		qnaSection,
		currentCompany,
		currentCompanyDocuments,
		vendor
	};
}

export function useSecFilingData(
	subscriptionId: string,
	documentId: string,
	skip: boolean,
	provider: string
) {
	const documentType = useBestEffortDocumentType(DocumentTypeGrouping.SecFilings);
	const documentData = useGetSecFilingQuery({
		skip,
		variables: { subscriptionId: subscriptionId, documentId: documentId }
	});

	const document = documentData?.data?.organizationSubscription.financialDocument;
	const currentCompany = document?.entity;

	const companyDocuments = useGetRecentDocumentsQuery({
		skip: skip || !currentCompany || !documentType,
		variables: {
			subscriptionId: subscriptionId,
			companyId: currentCompany?.id,
			documentType: codeFromDocumentType(documentType, provider)
		}
	});

	const currentCompanyDocuments = companyDocuments?.data?.organizationSubscription.companyEntity?.searchFinancialDocuments?.documents.map(
		document => ({
			title: document.documentVersions[0].title,
			documentId: document.id,
			fiscalQuarter: document.fiscalPeriod?.quarterEstimated,
			fiscalYear: document.fiscalPeriod?.yearEstimated,
			publicationDate: document.eventTime,
			eventDate: document.eventTime
		})
	);

	const updateTime = document?.updateTime;
	const eventTime = document?.eventTime;
	const vendor = document?.provider;

	const lastDocument = document?.documentVersions[0];
	const title = lastDocument?.title;
	const htmlUrl = lastDocument?.htmlUrl;

	const events = extractEvents(lastDocument);

	return {
		documentData,
		title,
		events,
		updateTime,
		eventTime,
		currentCompany,
		currentCompanyDocuments,
		vendor,
		htmlUrl
	};
}

export function sortEventsInSentence(sentence) {
	const sortedSentece = _.cloneDeep(sentence);
	sortedSentece.events.sort((event1, event2) =>
		event1.startPosition > event2.startPosition ? 1 : -1
	);
	return sortedSentece;
}

// Returns the "best available" document type for the currently viewed page.
// The logic for this is as follows:
// 1) If the user comes to the page from the watchlist view, a current document type has
//    been selected in the sidebar. In this case, just make sure its grouping corresponds
//    to the one associated with the page, and return it
// 2) If for any reason we can't do that, default to the first available document type
//    according to the predetermined ordering defined in documentTypeOrderForGrouping
// 3) Fall back to Unknown if we somehow got a type that isn't exposed in UI (should never happen)
export function useBestEffortDocumentType(grouping: DocumentTypeGrouping): DocumentType {
	// 1) try to get the one from global state
	const docTypeFromGlobalState = useRecoilValue(currentDocumentTypeState);
	if (getDocumentTypeGrouping(docTypeFromGlobalState) === grouping) {
		return docTypeFromGlobalState;
	}

	// 2) fall back to the first one of the given grouping
	const documentTypesInGrouping = documentTypeOrderForGrouping(grouping);
	if (documentTypesInGrouping.length) {
		return documentTypesInGrouping[0];
	}

	// 3) fall back to Default
	return DocumentType.Unknown;
}
