import * as React from 'react';
import styled from 'styled-components';
import { Colors, FontFamilies, FontSizes } from '../../../components/shared/styles';
import SearchIconSVG from '../../../assets/icons/search.svg';
import { Icon } from '../../../components/Icon/Icon';

const SearchBarContainer = styled.div`
	display: flex;
	height: 40px;
	width: 305px;
	border: 1px solid #e9f2fa;
	border-radius: 50px;
	background-color: ${Colors.white};
	font-size: 16px;
	font-family: ${FontFamilies.regular};
	float: left;
	color: ${Colors.darkGray};
`;

const SearchIcon = styled(Icon)`
	margin-left: 12px;
	margin-top: auto;
	margin-bottom: auto;
`;

const SearchMessage = styled.div`
	margin-top: auto;
	margin-bottom: auto;
	margin-left: 8px;
	color: ${Colors.darkGray};
	font-size: ${FontSizes.s3};
`;

const SearchBarDocument: React.FunctionComponent = () => {
	return (
		<SearchBarContainer>
			<SearchIcon src={SearchIconSVG} alt='search icon' width={15} />
			<SearchMessage id='DOCUMENT_SEARCH_INPUT'>
				Press ctrl+f to search on the document
			</SearchMessage>
		</SearchBarContainer>
	);
};

export default SearchBarDocument;
