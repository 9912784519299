import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export const GetAvailableDashboardsDocument = gql`
	query GetAvailableDashboards {
		me {
			availableDashboards {
				id
				name
				embedUrl
			}
		}
	}
`;

/**
 * __useGetAvailableDashboardsQuery__
 *
 * To run a query within a React component, call `useGetAvailableDashboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableDashboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableDashboardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAvailableDashboardsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.GetAvailableDashboardsQuery,
		Types.GetAvailableDashboardsQueryVariables
	>
) {
	return Apollo.useQuery<
		Types.GetAvailableDashboardsQuery,
		Types.GetAvailableDashboardsQueryVariables
	>(GetAvailableDashboardsDocument, baseOptions);
}
export function useGetAvailableDashboardsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetAvailableDashboardsQuery,
		Types.GetAvailableDashboardsQueryVariables
	>
) {
	return Apollo.useLazyQuery<
		Types.GetAvailableDashboardsQuery,
		Types.GetAvailableDashboardsQueryVariables
	>(GetAvailableDashboardsDocument, baseOptions);
}
export type GetAvailableDashboardsQueryHookResult = ReturnType<
	typeof useGetAvailableDashboardsQuery
>;
export type GetAvailableDashboardsLazyQueryHookResult = ReturnType<
	typeof useGetAvailableDashboardsLazyQuery
>;
export type GetAvailableDashboardsQueryResult = Apollo.QueryResult<
	Types.GetAvailableDashboardsQuery,
	Types.GetAvailableDashboardsQueryVariables
>;
export const GetCompanyDocumentsDocument = gql`
	query GetCompanyDocuments(
		$subscriptionId: String!
		$companyId: String!
		$documentType: DocumentType!
		$cursor: String
	) {
		organizationSubscription(id: $subscriptionId) {
			companyEntity(id: $companyId) {
				searchFinancialDocuments(
					startTime: "01-01-2010"
					documentTypes: [$documentType]
					cursor: $cursor
					first: 8
				) {
					cursor
					keyDriverTrends {
						keyDriver
						trends {
							year
							quarter
							positiveEventCount
							negativeEventCount
						}
					}
					documents {
						id
						eventTime
						updateTime
						fiscalPeriod {
							quarterEnd
							yearEnd
						}
						documentVersions: versions {
							documentId
							title: displayName
							aggregatedSections {
								name
								score
								keyDrivers {
									name
									negativeEventCount
									positiveEventCount
								}
							}
						}
					}
				}
			}
		}
	}
`;

/**
 * __useGetCompanyDocumentsQuery__
 *
 * To run a query within a React component, call `useGetCompanyDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyDocumentsQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      companyId: // value for 'companyId'
 *      documentType: // value for 'documentType'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetCompanyDocumentsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.GetCompanyDocumentsQuery,
		Types.GetCompanyDocumentsQueryVariables
	>
) {
	return Apollo.useQuery<Types.GetCompanyDocumentsQuery, Types.GetCompanyDocumentsQueryVariables>(
		GetCompanyDocumentsDocument,
		baseOptions
	);
}
export function useGetCompanyDocumentsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetCompanyDocumentsQuery,
		Types.GetCompanyDocumentsQueryVariables
	>
) {
	return Apollo.useLazyQuery<
		Types.GetCompanyDocumentsQuery,
		Types.GetCompanyDocumentsQueryVariables
	>(GetCompanyDocumentsDocument, baseOptions);
}
export type GetCompanyDocumentsQueryHookResult = ReturnType<typeof useGetCompanyDocumentsQuery>;
export type GetCompanyDocumentsLazyQueryHookResult = ReturnType<
	typeof useGetCompanyDocumentsLazyQuery
>;
export type GetCompanyDocumentsQueryResult = Apollo.QueryResult<
	Types.GetCompanyDocumentsQuery,
	Types.GetCompanyDocumentsQueryVariables
>;
export const GetUrlForDashboardDocument = gql`
	query GetUrlForDashboard($embedUrl: String!) {
		me {
			embedUrlForDashboard(embedUrl: $embedUrl)
		}
	}
`;

/**
 * __useGetUrlForDashboardQuery__
 *
 * To run a query within a React component, call `useGetUrlForDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUrlForDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUrlForDashboardQuery({
 *   variables: {
 *      embedUrl: // value for 'embedUrl'
 *   },
 * });
 */
export function useGetUrlForDashboardQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.GetUrlForDashboardQuery,
		Types.GetUrlForDashboardQueryVariables
	>
) {
	return Apollo.useQuery<Types.GetUrlForDashboardQuery, Types.GetUrlForDashboardQueryVariables>(
		GetUrlForDashboardDocument,
		baseOptions
	);
}
export function useGetUrlForDashboardLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetUrlForDashboardQuery,
		Types.GetUrlForDashboardQueryVariables
	>
) {
	return Apollo.useLazyQuery<Types.GetUrlForDashboardQuery, Types.GetUrlForDashboardQueryVariables>(
		GetUrlForDashboardDocument,
		baseOptions
	);
}
export type GetUrlForDashboardQueryHookResult = ReturnType<typeof useGetUrlForDashboardQuery>;
export type GetUrlForDashboardLazyQueryHookResult = ReturnType<
	typeof useGetUrlForDashboardLazyQuery
>;
export type GetUrlForDashboardQueryResult = Apollo.QueryResult<
	Types.GetUrlForDashboardQuery,
	Types.GetUrlForDashboardQueryVariables
>;
export const GetSubscriptionDocument = gql`
	query GetSubscription($flowId: String!) {
		me {
			organization {
				subscriptionByFlowId(flowId: $flowId) {
					id
				}
			}
		}
	}
`;

/**
 * __useGetSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionQuery({
 *   variables: {
 *      flowId: // value for 'flowId'
 *   },
 * });
 */
export function useGetSubscriptionQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.GetSubscriptionQuery,
		Types.GetSubscriptionQueryVariables
	>
) {
	return Apollo.useQuery<Types.GetSubscriptionQuery, Types.GetSubscriptionQueryVariables>(
		GetSubscriptionDocument,
		baseOptions
	);
}
export function useGetSubscriptionLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetSubscriptionQuery,
		Types.GetSubscriptionQueryVariables
	>
) {
	return Apollo.useLazyQuery<Types.GetSubscriptionQuery, Types.GetSubscriptionQueryVariables>(
		GetSubscriptionDocument,
		baseOptions
	);
}
export type GetSubscriptionQueryHookResult = ReturnType<typeof useGetSubscriptionQuery>;
export type GetSubscriptionLazyQueryHookResult = ReturnType<typeof useGetSubscriptionLazyQuery>;
export type GetSubscriptionQueryResult = Apollo.QueryResult<
	Types.GetSubscriptionQuery,
	Types.GetSubscriptionQueryVariables
>;
export const GetSubscriptionsDocument = gql`
	query GetSubscriptions {
		me {
			organization {
				subscriptions {
					id
					provider
					description
					isActive
					types
				}
			}
		}
	}
`;

/**
 * __useGetSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.GetSubscriptionsQuery,
		Types.GetSubscriptionsQueryVariables
	>
) {
	return Apollo.useQuery<Types.GetSubscriptionsQuery, Types.GetSubscriptionsQueryVariables>(
		GetSubscriptionsDocument,
		baseOptions
	);
}
export function useGetSubscriptionsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetSubscriptionsQuery,
		Types.GetSubscriptionsQueryVariables
	>
) {
	return Apollo.useLazyQuery<Types.GetSubscriptionsQuery, Types.GetSubscriptionsQueryVariables>(
		GetSubscriptionsDocument,
		baseOptions
	);
}
export type GetSubscriptionsQueryHookResult = ReturnType<typeof useGetSubscriptionsQuery>;
export type GetSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetSubscriptionsLazyQuery>;
export type GetSubscriptionsQueryResult = Apollo.QueryResult<
	Types.GetSubscriptionsQuery,
	Types.GetSubscriptionsQueryVariables
>;
export const GetRecentDocumentsDocument = gql`
	query GetRecentDocuments(
		$subscriptionId: String!
		$companyId: String!
		$documentType: DocumentType!
	) {
		organizationSubscription(id: $subscriptionId) {
			companyEntity(id: $companyId) {
				searchFinancialDocuments(startTime: "01-01-2010", documentTypes: [$documentType]) {
					documents {
						id
						eventTime
						updateTime
						fiscalPeriod {
							yearEstimated
							quarterEstimated
						}
						documentVersions: versions {
							documentId
							title: displayName
						}
					}
				}
			}
		}
	}
`;

/**
 * __useGetRecentDocumentsQuery__
 *
 * To run a query within a React component, call `useGetRecentDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentDocumentsQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      companyId: // value for 'companyId'
 *      documentType: // value for 'documentType'
 *   },
 * });
 */
export function useGetRecentDocumentsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.GetRecentDocumentsQuery,
		Types.GetRecentDocumentsQueryVariables
	>
) {
	return Apollo.useQuery<Types.GetRecentDocumentsQuery, Types.GetRecentDocumentsQueryVariables>(
		GetRecentDocumentsDocument,
		baseOptions
	);
}
export function useGetRecentDocumentsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetRecentDocumentsQuery,
		Types.GetRecentDocumentsQueryVariables
	>
) {
	return Apollo.useLazyQuery<Types.GetRecentDocumentsQuery, Types.GetRecentDocumentsQueryVariables>(
		GetRecentDocumentsDocument,
		baseOptions
	);
}
export type GetRecentDocumentsQueryHookResult = ReturnType<typeof useGetRecentDocumentsQuery>;
export type GetRecentDocumentsLazyQueryHookResult = ReturnType<
	typeof useGetRecentDocumentsLazyQuery
>;
export type GetRecentDocumentsQueryResult = Apollo.QueryResult<
	Types.GetRecentDocumentsQuery,
	Types.GetRecentDocumentsQueryVariables
>;
export const GetSecFilingDocument = gql`
	query GetSecFiling($subscriptionId: String!, $documentId: String!) {
		organizationSubscription(id: $subscriptionId) {
			financialDocument(id: $documentId) {
				entity: companyEntity {
					id
				}
				eventTime
				updateTime
				provider
				documentVersions: versions {
					title: displayName
					htmlUrl
					documentAnalysisResult {
						sentences(fullText: false) {
							section
							text
							startPosition
							speaker {
								name
								companyEntityName
								companyEntityId
								title
							}
							events {
								id
								name
								text
								startPosition
								polarity
								keyDrivers
								properties {
									key
									value
								}
							}
						}
					}
				}
			}
		}
	}
`;

/**
 * __useGetSecFilingQuery__
 *
 * To run a query within a React component, call `useGetSecFilingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecFilingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecFilingQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useGetSecFilingQuery(
	baseOptions?: Apollo.QueryHookOptions<Types.GetSecFilingQuery, Types.GetSecFilingQueryVariables>
) {
	return Apollo.useQuery<Types.GetSecFilingQuery, Types.GetSecFilingQueryVariables>(
		GetSecFilingDocument,
		baseOptions
	);
}
export function useGetSecFilingLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetSecFilingQuery,
		Types.GetSecFilingQueryVariables
	>
) {
	return Apollo.useLazyQuery<Types.GetSecFilingQuery, Types.GetSecFilingQueryVariables>(
		GetSecFilingDocument,
		baseOptions
	);
}
export type GetSecFilingQueryHookResult = ReturnType<typeof useGetSecFilingQuery>;
export type GetSecFilingLazyQueryHookResult = ReturnType<typeof useGetSecFilingLazyQuery>;
export type GetSecFilingQueryResult = Apollo.QueryResult<
	Types.GetSecFilingQuery,
	Types.GetSecFilingQueryVariables
>;
export const GetTranscriptDocument = gql`
	query GetTranscript($subscriptionId: String!, $documentId: String!) {
		organizationSubscription(id: $subscriptionId) {
			financialDocument(id: $documentId) {
				entity: companyEntity {
					id
				}
				eventTime
				updateTime
				provider
				documentVersions: versions {
					title: displayName
					documentAnalysisResult {
						sentences(fullText: true) {
							section
							text
							startPosition
							speaker {
								name
								companyEntityName
								companyEntityId
								title
							}
							events {
								id
								name
								text
								startPosition
								polarity
								keyDrivers
								properties {
									key
									value
								}
							}
						}
					}
				}
			}
		}
	}
`;

/**
 * __useGetTranscriptQuery__
 *
 * To run a query within a React component, call `useGetTranscriptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTranscriptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTranscriptQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useGetTranscriptQuery(
	baseOptions?: Apollo.QueryHookOptions<Types.GetTranscriptQuery, Types.GetTranscriptQueryVariables>
) {
	return Apollo.useQuery<Types.GetTranscriptQuery, Types.GetTranscriptQueryVariables>(
		GetTranscriptDocument,
		baseOptions
	);
}
export function useGetTranscriptLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetTranscriptQuery,
		Types.GetTranscriptQueryVariables
	>
) {
	return Apollo.useLazyQuery<Types.GetTranscriptQuery, Types.GetTranscriptQueryVariables>(
		GetTranscriptDocument,
		baseOptions
	);
}
export type GetTranscriptQueryHookResult = ReturnType<typeof useGetTranscriptQuery>;
export type GetTranscriptLazyQueryHookResult = ReturnType<typeof useGetTranscriptLazyQuery>;
export type GetTranscriptQueryResult = Apollo.QueryResult<
	Types.GetTranscriptQuery,
	Types.GetTranscriptQueryVariables
>;
export const GetWatchlistCompaniesDocument = gql`
	query GetWatchlistCompanies(
		$subscriptionId: String!
		$companiesIds: [String!]!
		$documentType: DocumentType!
	) {
		organizationSubscription(id: $subscriptionId) {
			entities: companyEntities(ids: $companiesIds) {
				id
				name
				gics {
					sector
				}
				mainIdentifier {
					ticker
					region
				}
				searchFinancialDocuments(
					first: 4
					startTime: "01-01-2010"
					documentTypes: [$documentType]
				) {
					documents {
						eventTime
						documentVersions: versions {
							documentId
							title: displayName
							aggregatedSections {
								name
								score
							}
						}
					}
				}
			}
		}
	}
`;

/**
 * __useGetWatchlistCompaniesQuery__
 *
 * To run a query within a React component, call `useGetWatchlistCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWatchlistCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWatchlistCompaniesQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      companiesIds: // value for 'companiesIds'
 *      documentType: // value for 'documentType'
 *   },
 * });
 */
export function useGetWatchlistCompaniesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.GetWatchlistCompaniesQuery,
		Types.GetWatchlistCompaniesQueryVariables
	>
) {
	return Apollo.useQuery<
		Types.GetWatchlistCompaniesQuery,
		Types.GetWatchlistCompaniesQueryVariables
	>(GetWatchlistCompaniesDocument, baseOptions);
}
export function useGetWatchlistCompaniesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetWatchlistCompaniesQuery,
		Types.GetWatchlistCompaniesQueryVariables
	>
) {
	return Apollo.useLazyQuery<
		Types.GetWatchlistCompaniesQuery,
		Types.GetWatchlistCompaniesQueryVariables
	>(GetWatchlistCompaniesDocument, baseOptions);
}
export type GetWatchlistCompaniesQueryHookResult = ReturnType<typeof useGetWatchlistCompaniesQuery>;
export type GetWatchlistCompaniesLazyQueryHookResult = ReturnType<
	typeof useGetWatchlistCompaniesLazyQuery
>;
export type GetWatchlistCompaniesQueryResult = Apollo.QueryResult<
	Types.GetWatchlistCompaniesQuery,
	Types.GetWatchlistCompaniesQueryVariables
>;
