import * as React from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { Button } from '@amenityllc/amenity-components';

import Images from '../../shared/images';
import * as ids from '../../../id.constants.js';
import { STORE_FEATURES, USER_TRACKING } from '../../constants';
import { TOOL_TIPS } from './toolbar.constants';

import UserTracking from '../../services/userTracking.service';

import SearchBarDocument from './SearchBarDocument';
import DocumentViewerToolTip from './DocumentViewerToolTip';

import printIcon from '../../../assets/icons/print.svg';
import linkIcon from '../../../assets/icons/link.svg';
import editIcon from '../../../assets/icons/edit_blue.svg';
import { Appearance, Colors } from '../../../components/shared/styles';
import SearchBarDocumentNewarc from './SearchBarDocumentNewarc';
import FeaturesStore from '../../stores/features.store';
import { inject } from 'mobx-react';

const UTC = USER_TRACKING.USER_TRACKING_CATEGORIES;
const UTA = USER_TRACKING.USER_TRACKING_ACTIONS;

const ToolbarContainer = styled.div`
	height: 100%;
`;

const EditButtonContainer = styled.div`
	position: relative;
	height: 100%;
	float: left;
`;

const ButtonContainer = styled.div`
	position: relative;
	height: 100%;
	float: left;
	margin-right: 7px;
`;

const CopyLinkContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 4px;
	border: solid 1px #dde5e7;
	cursor: pointer;
	:hover {
		background-color: #ffffff;
	}
`;

interface TooltipProps {
	rightToolTip: boolean;
}

const DocumentViewerTooltipContainer = styled.div<TooltipProps>`
	position: absolute;
	top: 53px;
	height: 32px;
	z-index: 2;
	box-sizing: border-box;
	border-radius: 2px;
	box-shadow: 0.6px 0.8px 4px 0 rgba(50, 49, 59, 0.5);
	border: solid 1px #dde5e7;
	background-color: #fbfbfb;
	&:before {
		content: '';
		position: absolute;
		top: -5px;
		left: ${props => (props.rightToolTip ? '75%' : 'calc(50% - 6px)')};
		width: 10px;
		height: 10px;
		transform: rotate(45deg);
		background-color: #fbfbfb;
	}
`;

const Image = styled.div<{ imgUrl: string }>`
	display: inline-block;
	position: relative;
	height: 10px;
	width: 13px;
	margin-right: 5px;
	background-color: ${Colors.blue};
	-webkit-mask-image: url(${props => props.imgUrl});
	-webkit-mask-size: contain;
	-webkit-mask-position: center;
	-webkit-mask-repeat: no-repeat;
`;

const Tooltip = styled(ReactTooltip)`
	height: auto;
	line-height: 21px;
	opacity: 1 !important;
	box-shadow: 0.6px 0.8px 4px 0 rgba(50, 49, 59, 0.5);
	font-family: Assistant;
	color: #1f1e29;
`;

interface ToolbarState {
	showEditAnnotationTooltip: boolean; // show edit annotation tooltip when hover on the edit annotation button and not already in edit mode
	showCopyLinkTooltip: boolean;
}

interface ToolbarProps {
	toggleEditMode?: Function;
	printTranscript?: Function;
	editMode?: boolean;
	printLabel: string;
	disablePrint: boolean;
	disableEdit: boolean;
	hideEdit?: boolean;
	navigateSearch: Function;
	searchText: Function;
	selectedMatch: number;
	totalMatches: number;
	isKeyDriverModel?: boolean;
}
@inject(STORE_FEATURES)
export default class Toolbar extends React.Component<ToolbarProps, ToolbarState> {
	linkRef;
	constructor(props: ToolbarProps) {
		super(props);
		this.state = {
			showEditAnnotationTooltip: false,
			showCopyLinkTooltip: false
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state);
	}

	componentDidUpdate() {
		ReactTooltip.rebuild();
	}

	handleMouseLeave = () => {
		this.setState({ showEditAnnotationTooltip: false });
	};

	onClickEditAnnotation = () => {
		if (this.props.disableEdit) {
			return;
		}
		this.handleMouseLeave();
		this.props.toggleEditMode();
	};

	onCopyLink = (url: string) => {
		const el = document.createElement('textarea');
		el.value = url;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);

		new UserTracking().setEvent(UTC.TRANSCRIPT, UTA.LINK_COPIED, url);
		this.setState({ showCopyLinkTooltip: true }, () => {
			ReactTooltip.show(this.linkRef);
		});
	};

	handleClickOutside = () => {
		document.removeEventListener('mousedown', this.handleClickOutside);
		this.setState({ showCopyLinkTooltip: false });
	};

	copyLinkUrl = () => {
		const url = window.location.href;
		return url.indexOf('eventid') === -1 ? url : url.slice(0, url.indexOf('eventid') - 1);
	};

	handlePrint = () => {
		if (this.props.disablePrint) {
			return;
		}
		this.props.printTranscript();
	};

	render() {
		const urlToCopy = this.copyLinkUrl();
		const { showCopyLinkTooltip, showEditAnnotationTooltip } = this.state;
		const {
			disablePrint,
			disableEdit,
			editMode,
			printLabel,
			isKeyDriverModel,
			hideEdit
		} = this.props;
		const textCopyLinkToolTip = showCopyLinkTooltip ? TOOL_TIPS.LINK.COPIED : TOOL_TIPS.LINK.COPY;
		const textAnnotationToolTip = isKeyDriverModel
			? TOOL_TIPS.KEY_DRIVER_ANNOTATION.text
			: TOOL_TIPS.ANNOTATION.text;
		const titleAnnotationToolTip = isKeyDriverModel
			? TOOL_TIPS.KEY_DRIVER_ANNOTATION.title
			: TOOL_TIPS.ANNOTATION.title;
		const { userFeaturePermissions } = this.props[STORE_FEATURES] as FeaturesStore;
		const ffViewerNewarcMigartion = userFeaturePermissions?.viewer_newarc_migration;

		return (
			<ToolbarContainer>
				<ButtonContainer>
					{ffViewerNewarcMigartion ? (
						<SearchBarDocumentNewarc />
					) : (
						<SearchBarDocument
							navigateSearch={this.props.navigateSearch}
							searchText={this.props.searchText}
							selectedMatch={this.props.selectedMatch}
							totalMatches={this.props.totalMatches}
						/>
					)}
				</ButtonContainer>
				{!disablePrint && (
					<ButtonContainer>
						<Button
							appearance={Appearance.secondary}
							id={ids.DOCUMENT_PAGE.DOCUMENT_PRINT_ICON}
							icon={printIcon}
							disabled={disablePrint}
							onClick={this.handlePrint}
							data-tip={printLabel}
							data-for={'TOOL_BAR_TOOL_TIP'}
						/>
					</ButtonContainer>
				)}
				<ButtonContainer>
					<CopyLinkContainer
						ref={ref => (this.linkRef = ref)}
						onClick={() => this.onCopyLink(urlToCopy)}
						id={urlToCopy}
						data-tip={textCopyLinkToolTip}
						data-for={'TOOL_BAR_TOOL_TIP'}
						onMouseLeave={() => this.setState({ showCopyLinkTooltip: false })}
					>
						<Button appearance={Appearance.secondary} icon={linkIcon} />
					</CopyLinkContainer>
				</ButtonContainer>
				{!hideEdit && !disableEdit && (
					<EditButtonContainer>
						<Button
							appearance={Appearance.secondary}
							icon={editIcon}
							text={'Annotation'}
							disabled={disableEdit}
							active={editMode}
							onClick={() => this.onClickEditAnnotation()}
						/>
						{!editMode && showEditAnnotationTooltip && (!disableEdit || isKeyDriverModel) && (
							<DocumentViewerTooltipContainer
								id={ids.DOCUMENT_PAGE.ANNOTATION_TOOL_TIP}
								rightToolTip={true}
								style={{
									width: '303px',
									right: '0px',
									height: '102px',
									fontSize: '13px',
									lineHeight: '19px'
								}}
							>
								{DocumentViewerToolTip({
									text: titleAnnotationToolTip,
									title: textAnnotationToolTip
								})}
							</DocumentViewerTooltipContainer>
						)}
					</EditButtonContainer>
				)}
				<Tooltip
					id='TOOL_BAR_TOOL_TIP'
					place='bottom'
					effect='solid'
					type='light'
					getContent={dataTip => (
						<>
							{showCopyLinkTooltip && <Image imgUrl={Images.blackCheckMark} />}
							{dataTip}
						</>
					)}
				/>
			</ToolbarContainer>
		);
	}
}
