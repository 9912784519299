import * as _ from 'lodash';

interface FileType {
	presented_extension: string;
	extension: string;
	signatures: string[];
}

const allowedFiles: any = {
	application: [
		{
			presented_extension: 'xlsx',
			extension: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			signatures: ['504b0304', '504b0506', '504b0708', '504b34', '20466163']
		},
		{
			presented_extension: 'xls',
			extension: 'vnd.ms-excel',
			signatures: ['d0cf11e0a1b11ae1']
		}
	]
};

export const isFileAllowed = (file: File) =>
	new Promise(resolve => {
		const fileType = getFileType(file.type);
		const fileExtension = getFileExtension(file.type);
		const presentedFileExtension = getPresentedExtension(file);

		const fileReader = new FileReader();
		fileReader.readAsArrayBuffer(file.slice(0, 4));
		fileReader.onloadend = (e: any) => {
			const arr = new Uint8Array(e.target.result);
			let header = '';
			_.each(arr, val => (header += val.toString(16)));
			if (fileType.length === 0) {
				// workaround for windows - known bug when type: ''
				const allowedType: FileType = _.find(
					allowedFiles.application,
					(type: any) => type.presented_extension === presentedFileExtension
				);
				resolve(allowedType !== undefined);
			} else {
				const allowedType: FileType = _.find(
					allowedFiles[fileType],
					(type: any) => type.extension === fileExtension
				);
				resolve(allowedType !== undefined && checkHeaderSignature(header, allowedType.signatures));
			}
		};
		fileReader.onerror = () => resolve(false);
	});

const checkHeaderSignature = (fileHeader: string, signatures: string[]): boolean => {
	return _.some(
		_.filter(signatures, sign => sign.indexOf(fileHeader) !== -1 || fileHeader.indexOf(sign) !== -1)
	);
};

const getFileType = (fileType: string): string => _.split(fileType, '/')[0];

const getFileExtension = (fileType: string): string => _.split(fileType, '/')[1];

const getPresentedExtension = (file: File): string => {
	const arr = file.name.split('.');
	return arr[arr.length - 1];
};
