import * as React from 'react';
import styled from 'styled-components';
import Images from '../../shared/images';

const Container = styled.div`
	width: calc((100% - 60px - 60px) * 0.65 + 45.5px);
	display: flex;
`;

const InnerContainer = styled.div`
	display: flex;
	align-items: center;
`;

const WarningIcon = styled.div`
	height: 18px;
	width: 17px;
	background-image: url("${Images.warningIcon}");
`;

const MessageText = styled.div`
	margin: 0 5px;
`;

const LearnMore = styled.a``;

const NoDocumentsMessage = () => (
	<Container>
		<InnerContainer>
			<WarningIcon />
			<MessageText>No documents found for this company.</MessageText>
			<LearnMore
				href='https://help.amenityanalytics.com/en/articles/3297379-why-are-there-no-documents-displaying-for-a-company-in-my-portfolio'
				target='_blank'
			>
				Learn more >{' '}
			</LearnMore>
		</InnerContainer>
	</Container>
);

export default NoDocumentsMessage;
