import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

import { Appearance, Colors, FontFamilies, FontSizes } from '../shared/styles';

interface StyledTextProps {
	appearance?: Appearance;
	color?: Colors;
	size?: FontSizes;
	fontFamily?: FontFamilies;
	lineHeight?: number;
	block?: boolean;
	ellipsis?: boolean;
	title?: string;
	numberOfLines?: number;
}

export const StyledText = styled.span<StyledTextProps>`
	display: ${({ block }) => block && 'block'};
	font-family: ${({ fontFamily }) => fontFamily || FontFamilies.regular};
	font-size: ${({ size }) => size || FontSizes.s3};
	color: ${({ color }) => color || Colors.blue};
	line-height: ${({ lineHeight }) => (lineHeight ? `${lineHeight}px` : '')};
	${({ appearance }) =>
		appearance && appearance === Appearance.secondary ? 'border-bottom: 1px solid' : ''};
	${({ ellipsis, numberOfLines }) =>
		ellipsis &&
		(numberOfLines && numberOfLines > 1
			? `
					overflow: hidden;
					display: -webkit-box;
					-webkit-line-clamp: ${numberOfLines};
					-webkit-box-orient: vertical;

				`
			: `
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				`)}
`;

export interface TextProps {
	id?: string;
	appearance?: Appearance;
	color?: Colors;
	size?: FontSizes;
	fontFamily?: FontFamilies;
	lineHeight?: number;
	block?: boolean;
	ellipsis?: boolean;
	children?: ReactNode;
	className?: string;
	title?: string;
	numberOfLines?: number;
}

export const Text: FunctionComponent<TextProps> = ({
	id,
	appearance,
	color,
	size,
	fontFamily,
	lineHeight,
	block,
	ellipsis,
	children,
	className,
	title,
	numberOfLines
}) => (
	<StyledText
		id={id}
		appearance={appearance}
		color={color}
		size={size}
		fontFamily={fontFamily}
		lineHeight={lineHeight}
		block={block}
		ellipsis={ellipsis}
		className={className}
		title={title}
		numberOfLines={numberOfLines}
	>
		{children}
	</StyledText>
);
