import * as React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import ReactHighcharts from 'react-highcharts';

import Images from '../../shared/images';
import * as ids from '../../../id.constants.js';
import { APP_COLORS } from '../../constants';

const EmptyStateContainer = styled.div`
	height: 150px;
	width: 190px;
	margin: 0 auto;
	padding-top: 5px;
`;

const EmptyStateImage = styled.img`
	display: block;
	margin: 0 auto;
`;

const EmptyStateText = styled.div`
	padding-top: 20px;
	height: 50px;
	color: #e7e7e7;
	font-family: 'Open Sans';
	font-size: 12px;
	font-weight: bold;
	letter-spacing: -0.07px;
	line-height: 17px;
	text-align: center;
	white-space: normal;
`;

interface HighchartProps {
	chartsData: { y: number; x: number }[];
}

const Highchart: React.FunctionComponent<HighchartProps> = ({ chartsData }) => {
	const getThreshold = (data: any[]) => {
		const min = _.min(_.map(data, 'y'));
		return min >= 0 ? 0 : Math.ceil(Math.abs(min) / 10) * 10 * -1;
	};

	const renderEmptyState = () => (
		<EmptyStateContainer>
			<EmptyStateImage src={Images.graphEmptyState} />
			<EmptyStateText>Sentiment Data is Not Available For Previous Transcripts</EmptyStateText>
		</EmptyStateContainer>
	);

	const config = {
		chart: {
			type: 'area',
			height: 226,
			backgroundColor: `${APP_COLORS.LIGHT_WHITE_FBFBFB}`
		},
		title: {
			text: ''
		},
		tooltip: {
			enabled: true,
			useHTML: true,
			style: { fontSize: '11px' },
			formatter: function() {
				// @ts-ignore
				return `<b>Document Date:</b> ${moment(this.point.x).format('l')}
				<br/>
				<b>Amenity Score:</b> ${
					// @ts-ignore
					this.point.y.toFixed(2)
				}`;
			}
		},
		xAxis: {
			gridLineWidth: 1,
			gridLineDashStyle: 'DashDot',
			labels: {
				enabled: true,
				formatter: point => moment(point.value).format('l'),
				style: { fontFamily: 'Assistant', fontSize: '13px', color: '#666666' }
			},
			type: 'datetime'
		},
		yAxis: {
			tickInterval: 10,
			gridLineWidth: 0,
			labels: {
				enabled: true,
				style: { fontFamily: 'Assistant', fontSize: '13px', color: '#666666' }
			},
			title: {
				text: ''
			},
			min: getThreshold(chartsData)
		},
		credits: {
			enabled: false
		},
		plotOptions: {
			series: {
				marker: {
					fillColor: '#2a4d7e',
					lineWidth: 2,
					lineColor: 'white'
				}
			}
		},
		series: [
			{
				threshold: getThreshold(chartsData),
				fillColor: '#E0E5ED',
				showInLegend: false,
				lineWidth: 3,
				color: '#2a4d7e',
				name: '',
				data: chartsData
			}
		]
	};

	if (_.isEmpty(chartsData)) {
		return renderEmptyState();
	}

	return (
		<div id={ids.WACTHLIST_PAGE.HIGHTCHART}>
			<ReactHighcharts config={config} />
		</div>
	);
};

export default Highchart;
