import _ from 'lodash';
import { DashboardEvent, LookerEmbedDashboard, LookerEmbedFilterParams } from '@looker/embed-sdk';
import { LOOKER_SDK, DASHBOARDS_WITH_WATCHLIST_FILTER_DISABLED } from 'app/dashboard.constants';
import { IAmenityDashboard } from '../../types/Dashboards.types';

// for these ids we don't want to show spinner at any time
// this ids here are temp until we introduce a more robust check
const showDashboardsImmediately = [19, 35, 28, 26, 40, 42, 44];

// The dashboard service holds internal state on the process of loading a looker dashboard
// for some dashboards that are not in the exclude list we start by waiting on the run:complete event
// then we post an update with user's filter if any are stored in local storage.
// on seperate data path we store any changes made to filters and union it with local storage to preserve filters
export default class DashboardIframeStateService {
	private phase: number;
	private asyncResolver: any;
	private lookerDashboardInstance: LookerEmbedDashboard;
	private dashboardId: number;

	constructor() {
		// store the phase of the dashboard, it currently can be one of two
		// phase 0: the dashboard is yet to be finish its first run and thus not suitable to show to user
		// phase 1: the dashboard completed the first run and it will remain visible to the user until requesting another dashboard id
		this.phase = 0;
		this.asyncResolver = undefined;
	}

	// this will be resolved if the dashboard id belongs to the exclude list
	// or once the event for dashboard:run:complete fired
	public async waitStateComplete(dashboardId: number) {
		this.dashboardId = dashboardId;
		if (showDashboardsImmediately.includes(dashboardId)) {
			return new Promise(resolve => resolve(true));
		}
		return new Promise(resolve => {
			this.asyncResolver = resolve;
		});
	}

	// allow to update the filters of the dashboard from outside the service as well
	// used to pass tickers by watchlist dropdown
	public async updateFilters(filters: LookerEmbedFilterParams) {
		if (this.lookerDashboardInstance) {
			this.lookerDashboardInstance.updateFilters(filters);
		}
	}

	// setting the dashboard instance once looker signals us it's ready
	// this allows us to load different dashboards without re-establishing auth
	public setDashboardInstance(dashboard: LookerEmbedDashboard) {
		this.lookerDashboardInstance = dashboard;
	}

	// load in same iframe without re-establish auth
	public loadNewDashboard(dashboardId: string) {
		if (this.lookerDashboardInstance) {
			this.lookerDashboardInstance.loadDashboard(dashboardId);
			this.phase = 0;
		}
	}

	// handler for dashboard:filters:changed, store the user's last known filters
	public onFilterChanged(dashboard: LookerEmbedDashboard, event: DashboardEvent): void {
		if (this.phase === 0 || showDashboardsImmediately.includes(this.dashboardId)) {
			return;
		}
		const localStorageFilters: LookerEmbedFilterParams[] = JSON.parse(
			localStorage.getItem(LOOKER_SDK.LOCAL_STORAGE_FILTER)
		);
		const savedFilters = localStorageFilters?.[this.dashboardId];
		const dashboardFilters: LookerEmbedFilterParams = event.dashboard.dashboard_filters;

		if (!_.isMatch(savedFilters, dashboardFilters)) {
			// merging of the filters changed by the user and the local storage item
			// in this logic state the dashboard's filter should overwrite the local storage filters
			const filtersUnion: LookerEmbedFilterParams = _.merge(savedFilters, dashboardFilters);
			localStorage.setItem(
				LOOKER_SDK.LOCAL_STORAGE_FILTER,
				JSON.stringify({ ...localStorageFilters, [this.dashboardId]: filtersUnion })
			);
		}
	}

	// handler for dashboard:run:complete
	// there are 2 main cases here, one where we load the default dashboard
	// the second is when the load of the dashboard with user's last known filters is complete
	public onDashboardRunComplete(dashboard: LookerEmbedDashboard, event: DashboardEvent): void {
		if (this.phase !== 0 || showDashboardsImmediately.includes(this.dashboardId)) {
			return;
		}
		const localStorageFilters: LookerEmbedFilterParams[] = JSON.parse(
			localStorage.getItem(LOOKER_SDK.LOCAL_STORAGE_FILTER)
		);
		const savedFilters = localStorageFilters?.[this.dashboardId];
		const dashboardFilters: LookerEmbedFilterParams = event.dashboard.dashboard_filters;

		// if no filters are stored from previous selection or the stored filters are the same as the dashboard
		if (savedFilters === null || _.isMatch(savedFilters, dashboardFilters)) {
			// if local storage is empty ==> update it with the dashboard filter
			savedFilters === null &&
				localStorage.setItem(
					LOOKER_SDK.LOCAL_STORAGE_FILTER,
					JSON.stringify({ ...localStorageFilters, [this.dashboardId]: dashboardFilters })
				);
		} else {
			// merging of the filters changed by the user and the local storage item
			// in this logic state the the local storage filters should overwrite dashboard's filter
			const filtersUnion: LookerEmbedFilterParams = _.merge(dashboardFilters, savedFilters);
			localStorage.setItem(
				LOOKER_SDK.LOCAL_STORAGE_FILTER,
				JSON.stringify({ ...localStorageFilters, [this.dashboardId]: filtersUnion })
			);

			dashboard.updateFilters(filtersUnion);
			dashboard.run();
		}

		// resolve the client who is waiting on this event
		this.asyncResolver(true);
		this.phase++;
	}
}

export const isESGPrivate = (dashboard: IAmenityDashboard) =>
	DASHBOARDS_WITH_WATCHLIST_FILTER_DISABLED.includes(dashboard.id);
