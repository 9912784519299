import * as _ from 'lodash';
import { action, observable, toJS } from 'mobx';

import { SplitFeatures } from '../types/types';
import AuthStore from '../stores/auth.store';
import { FEATURES_TYPE_SPLIT, SPLIT_AUTH_KEY } from '../constants';
import { getDomainFromEmail } from '../services/util.service';
import InspectletService from '../services/inspectlet.service';

export default class FeaturesStore {
	initStarted = false;
	splitSdk;
	splitClient;
	splitManager;
	allAvailableSplits;
	afterInit: Function[] = [];

	@observable userFeaturePermissions: SplitFeatures;
	@observable initFinished = false;

	private static instance: FeaturesStore;

	static getInstance() {
		return FeaturesStore.instance || new FeaturesStore();
	}

	constructor() {
		if (FeaturesStore.instance) {
			return FeaturesStore.instance;
		}
		FeaturesStore.instance = this;
		this.initializeFeatures();
	}

	init = (cb?: Function) => {
		if (this.initFinished && cb) {
			cb();
			return Promise.resolve();
		}
		this.saveAfterInit(cb);
		if (this.initStarted) {
			return Promise.resolve();
		}
		const userAttributes = this.mapUserProfileToAttributes();
		if (!userAttributes || !userAttributes.email) {
			return Promise.resolve();
		}
		this.setInitStarted();
		const splitNames = this.mapFeaturesToSplits();

		return this.getSplitObject(userAttributes.email)
			.then(() => this.getManager())
			.then(() => this.getClient(userAttributes, splitNames))
			.then(() => {
				this.setInitFinished();
				this.runAfterInit();
			});
	};

	@action
	reset = () => {
		this.initStarted = false;
		this.initFinished = false;
	};

	@action
	saveAfterInit = (cb: Function) => {
		if (cb) {
			this.afterInit.push(cb);
		}
	};

	@action
	runAfterInit = () => {
		_.each(this.afterInit, (cb: Function) => cb());
		this.afterInit = [];
	};

	@action
	setInitStarted = () => {
		this.initStarted = true;
	};

	@action
	setInitFinished = () => {
		this.initFinished = true;
	};

	mapUserProfileToAttributes = () => {
		const userProfile = AuthStore.getInstance().userProfile;
		return userProfile.username
			? {
					email: userProfile.username,
					domain: getDomainFromEmail(userProfile.username)
			  }
			: {};
	};

	mapFeaturesToSplits = () => _.map(FEATURES_TYPE_SPLIT, (feature: string) => feature);

	@action
	getSplitObject = async (email: string) => {
		// Instantiate the SDK. CDN will expose splitio globally
		await this.waitForSplit();
		const splitio = (window as any).splitio;
		this.splitSdk = splitio({
			core: {
				// authorization key to split sdk, currently hard coded.
				authorizationKey: SPLIT_AUTH_KEY,
				// internal user id, or the account id that the user belongs to.
				key: email,
				// an OPTIONAL traffic type, if provided will be used for event tracking with the SDK client.
				trafficType: 'user'
			},
			startup: {
				readyTimeout: 30
			}
		});
	};

	waitForSplit = (): Promise<any> =>
		new Promise((resolve, reject) => {
			const interval = setInterval(() => {
				if ((window as any).splitio) {
					clearInterval(interval);
					resolve();
				}
			}, 100);
			setTimeout(() => {
				clearInterval(interval);
				reject();
			}, 20000);
		});

	@action
	getClient = (userAttributes: any, features: any[]) =>
		new Promise((resolve, reject) => {
			const client = this.splitSdk.client();
			this.splitClient = client;

			/**
			 * client.getTreatment('SPLIT_NAME')                      - Returns clients permissions for that split.
			 * client.getTreatments(['SPLIT_NAME_1', 'SPLIT_NAME_2']) - Returns clients permissions for array of splits.
			 */
			client.on(client.Event.SDK_READY, () => {
				const treatments = this.getAllTreatments(features, userAttributes);
				this.mapTreatmentsToFeatures(treatments);
				this.initFeatureDependentServices(userAttributes.email);
				resolve();
			});
		});

	@action
	getManager = () => {
		const manager = this.splitSdk.manager();
		this.splitManager = manager;

		/**
		 * manager.split('name-of-split') - Returns the Split registered with the SDK of this name.
		 * manager.splits()               - Retrieves the Splits that are currently registered with the SDK.
		 * manager.names()                - Returns the names of features (or Splits) registered with the SDK.
		 */
		manager.once(manager.Event.SDK_READY, () => {
			this.setAllAvailableSplits(manager.splits());
		});
	};

	@action
	mapTreatmentsToFeatures = (treatments: any[]) => {
		_.each(treatments, (t: string, index: string) => {
			this.userFeaturePermissions = {
				...this.userFeaturePermissions,
				[index]: t === 'on'
			};
		});
		console.log('features ? ', toJS(this.userFeaturePermissions));
	};

	@action
	setAllAvailableSplits = (availableSplits: any) => {
		this.allAvailableSplits = availableSplits;
	};

	@action
	initializeFeatures = () => {
		this.userFeaturePermissions = _.reduce(
			FEATURES_TYPE_SPLIT,
			(acc: any, feature: string) => ({ ...acc, [feature]: undefined }),
			{}
		);
	};

	getAllTreatments = (features: string[], userAttributes: any) => {
		return this.splitClient && this.splitClient.getTreatments(features, userAttributes);
	};

	initFeatureDependentServices = (username: string) => {
		if (this.userFeaturePermissions.viewer_heatmap) {
			InspectletService.getInstance().turnOn(username);
		}
	};
}
