import { useTranscriptData } from '../transcript/transcript.service';
import EventsFilters from '../transcript/EventsFilters';
import _ from 'lodash';

export const useTranscriptDataForPrint = (subscriptionId, documentId, provider) => {
	const {
		documentData,
		title,
		events,
		updateTime,
		eventTime,
		discussionSection,
		qnaSection,
		vendor
	} = useTranscriptData(subscriptionId, documentId, false, provider);

	// filter the events for the key drivers column
	const filteredEvents = EventsFilters(events, true);
	filteredEvents?.forEach((event, index) => (event.indexInTranscript = index));

	// filter the events for the main column (by section)
	let eventIndexInSections = 0;
	const filterEventsInSection = events => {
		return EventsFilters(events, true).map(event => {
			event.indexInTranscript = eventIndexInSections;
			eventIndexInSections++;
			return event;
		});
	};

	const sectionCloning = section => _.cloneDeep(section);

	const discussionSentences = discussionSection.sentences.map(sentence => sectionCloning(sentence));

	discussionSentences.forEach(section =>
		section.sentences.forEach(
			financialSentence =>
				(financialSentence.events = filterEventsInSection(financialSentence.events))
		)
	);

	const qnaSentences = qnaSection.sentences.map(sentence => sectionCloning(sentence));

	qnaSentences.forEach(section =>
		section.sentences.forEach(
			financialSentence =>
				(financialSentence.events = filterEventsInSection(financialSentence.events))
		)
	);

	return {
		documentData,
		title,
		filteredEvents,
		updateTime,
		eventTime,
		discussionSentences,
		qnaSentences,
		vendor
	};
};
