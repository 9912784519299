import * as React from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';
import { DropDownItem, Category, Collection } from '../../types/types';
import Images from '../../shared/images';
import Modal from 'react-modal';
import ModalStyles from '../../shared/styled/general-modal-style';
import DropDown from '../../widgets/DropDown/dropDown';

const PopTitle = styled.div`
	margin-left: 40px;
	padding-top: 20px;
	color: #fff;
	font-weight: 600;
`;

const PopUpWarpTitle = styled.div`
	height: 60px;
	width: 100%;
	top: 0;
	left: 0;
	background-color: #586876;
`;

const InputTitle = styled.div`
	height: 24px;
	width: 100%;
	margin: 20px auto;
	line-height: 24px;
	text-align: left;
	color: #4a4a4a;
	font-size: 13px;
`;

const SubmitWrap = styled.div`
	position: absolute;
	right: 63px;
	bottom: 20px;
	width: 250px;
	height: 32px;
	font-size: 13px;
	font-weight: 600;
`;

const DropDownWrap = styled.div`
	width: 156px;
	height: 24px;
`;

interface ConfirmButtonProps {
	isConfirm: boolean;
}
const ConfirmButton = styled.button<ConfirmButtonProps>`
	width: 85px;
	height: 30px;
	float: right;
	margin-left: ${props => (props.isConfirm ? '10px' : '0')};
	font-size: 13px;
	color: ${props => (props.isConfirm ? '#fff' : '#696868')};
	background-color: ${props => (props.isConfirm ? '#00aeef' : '#ccc')};
	cursor: pointer;
`;

const ClosePopUp = styled.div`
	position: absolute;
	top: 2px;
	right: -1px;
	width: 43px;
	height: 43px;
	background-image: url("${Images.close}");
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
  background-size: 13px;
  z-index:1;
`;

const Input = styled.input`
	width: 340px;
	padding: 5px;
	border: 1px solid #ccc;
`;

const InputWrap = styled.div`
	margin-top: 35px;
	margin-left: 20px;
`;

const ErrorSpan = styled.div`
	color: red;
	font-size: 10px;
`;

const Textarea = styled.textarea`
	width: 350px;
	height: 70px;
	border: 1px solid #ccc;
	padding: 5px;
	resize: none;
	box-sizing: border-box;
`;

interface AddOrUpdateCategoryPopUpProps {
	type: number;
	modalIsOpen: boolean;
	closePopup: Function;
	category?: Category;
	onConfirmFunc: Function;
	categories: Category[];
	collections: Collection[];
}

interface AddOrUpdateCategoryPopUpState {
	categoryName: string;
	currentCategory: Category;
	currentCollection: Collection;
	isCatErr: boolean;
	isCollectionErr: boolean;
	catErrMsg: string;
	chartDescription: string;
	documentViewDescription: string;
}

export default class AddOrUpdateCategoryPopUp extends React.Component<
	AddOrUpdateCategoryPopUpProps,
	AddOrUpdateCategoryPopUpState
> {
	constructor(props: AddOrUpdateCategoryPopUpProps) {
		super(props);
		Modal.setAppElement('#root');
		if (this.props.type === 0) {
			this.state = {
				currentCategory: undefined,
				currentCollection: this.props.collections[0],
				categoryName: '',
				isCatErr: false,
				isCollectionErr: false,
				catErrMsg: '',
				chartDescription: '',
				documentViewDescription: ''
			};
		} else if (this.props.type === 1) {
			this.state = {
				currentCategory: this.props.category,
				currentCollection: {
					id: this.props.category.collection_id,
					name: this.props.category.collection_name
				},
				categoryName: this.props.category.name,
				isCatErr: false,
				isCollectionErr: false,
				catErrMsg: '',
				chartDescription: this.props.category.chart_description || '',
				documentViewDescription: this.props.category.document_view_description || ''
			};
		}
	}

	updateNameInput = evt => {
		this.setState({ categoryName: evt.target.value });
	};

	updateChartDescription = evt => {
		this.setState({ chartDescription: evt.target.value });
	};

	updateDocumentViewDescription = evt => {
		this.setState({ documentViewDescription: evt.target.value });
	};

	addNewCategory = () => {
		this.setState({ isCatErr: false });
		if (!this.verifyInput()) {
			return;
		}

		const newCategory = {
			name: this.state.categoryName.trim(),
			collection_id: this.state.currentCollection.id,
			collection_name: this.state.currentCollection.name,
			chart_description: this.state.chartDescription,
			document_view_description: this.state.documentViewDescription
		};
		this.props.onConfirmFunc(newCategory);
		this.close();
	};

	editCategory = () => {
		this.setState({ isCatErr: false });
		if (!this.verifyInput()) {
			return;
		}

		const editCategory = {
			id: this.state.currentCategory.id,
			name: this.state.categoryName.trim(),
			collection_id: this.state.currentCollection.id,
			collection_name: this.state.currentCollection.name,
			chart_description: this.state.chartDescription,
			document_view_description: this.state.documentViewDescription
		};
		this.props.onConfirmFunc(editCategory);
		this.close();
	};

	close = () => {
		this.props.closePopup();
	};

	verifyInput = (): boolean => {
		const { categories, category, type } = this.props;
		const { currentCollection, categoryName } = this.state;
		// Verify unique category name only within collection - 2 different collections can have the same category name
		const filteredCategories = _.filter(categories, c => c.collection_id === currentCollection.id);

		let categoryNames = _.map(filteredCategories, c => c.name);

		let isVerified = true;
		// If this is update process AND the current collection is the original collection of the edited
		// category - don't check vs. own category name
		if (type === 1 && category.collection_name === currentCollection.name) {
			categoryNames = _.filter(categoryNames, d => d !== category.name);
		}
		const trimmedCategoryName = categoryName.trim();
		if (_.find(categoryNames, c => c.toLowerCase() === trimmedCategoryName.toLowerCase())) {
			this.setState({ isCatErr: true, catErrMsg: 'Category already exists' });
			isVerified = false;
		}
		if (trimmedCategoryName === '') {
			this.setState({ isCatErr: true, catErrMsg: 'Category cannot be empty' });
			isVerified = false;
		}
		if (!this.state.currentCollection) {
			this.setState({ isCollectionErr: true });
			isVerified = false;
		}
		return isVerified;
	};

	getTitle = () => {
		if (this.props.type === 0) {
			return 'Add Category';
		} else if (this.props.type === 1) {
			return 'Edit Category';
		}
	};

	saveCategory = () => {
		if (this.props.type === 0) {
			this.addNewCategory();
		} else if (this.props.type === 1) {
			this.editCategory();
		}
	};

	handleCollectionSelection = (collectionName: string) => {
		const selectedCollection = _.find(
			this.props.collections,
			collection => collection.name === collectionName
		);
		this.setState({
			currentCollection: {
				id: selectedCollection.id,
				name: selectedCollection.name
			}
		});
	};

	render() {
		const {
			isCatErr,
			catErrMsg,
			isCollectionErr,
			currentCollection,
			categoryName,
			chartDescription,
			documentViewDescription
		} = this.state;
		const dropDownItems: DropDownItem[] = _.map(this.props.collections, (c: Collection) => {
			const res: DropDownItem = {
				id: c.name,
				title: c.name
			};
			return res;
		});

		return (
			<Modal
				isOpen={this.props.modalIsOpen}
				onRequestClose={() => this.close()}
				style={ModalStyles(430, 670)}
			>
				<ClosePopUp
					onClick={() => this.close()}
					id='BACKOFFICE_CATEGORIES_ADDEDIT_MODAL_CLOSE_BUTTON'
				/>
				<PopUpWarpTitle>
					<PopTitle>{this.getTitle()}</PopTitle>
				</PopUpWarpTitle>
				<InputWrap>
					<InputTitle>Category name:</InputTitle>
					<Input
						type='text'
						value={categoryName}
						onChange={this.updateNameInput}
						id='BACKOFFICE_CATEGORIES_ADDEDIT_MODAL_CATEGORY_NAME_FIELD'
					/>
					{isCatErr && <ErrorSpan id='ERROR_ADDING_CATEGORY'>{catErrMsg}</ErrorSpan>}
				</InputWrap>
				<InputWrap>
					<InputTitle>Collection:</InputTitle>
					<DropDownWrap>
						<DropDown
							type={2}
							maxHeightBody={80}
							height={25}
							width={352}
							arrowTop={-2}
							mainLabelPrefix={''}
							defaultSelection={currentCollection.name}
							data={dropDownItems}
							handleDropDownChange={collectionName =>
								this.handleCollectionSelection(collectionName)
							}
							id='BACKOFFICE_CATEGORIES_ADDEDIT_MODAL_COLLECTION_DROPDOWN'
						/>
					</DropDownWrap>
					{isCollectionErr && <ErrorSpan>Collection cannot be empty</ErrorSpan>}
				</InputWrap>
				<InputWrap>
					<InputTitle>Chart Description:</InputTitle>
					<Textarea
						value={chartDescription}
						onChange={this.updateChartDescription}
						id='BACKOFFICE_CATEGORIES_CHART_DESCRIPTION_FIELD'
					/>
				</InputWrap>
				<InputWrap>
					<InputTitle>Document View Description:</InputTitle>
					<Textarea
						value={documentViewDescription}
						onChange={this.updateDocumentViewDescription}
						id='BACKOFFICE_CATEGORIES_DOCUMENT_VIEW_DESCRIPTION_FIELD'
					/>
				</InputWrap>
				<SubmitWrap>
					<ConfirmButton
						isConfirm
						onClick={() => this.saveCategory()}
						id='BACKOFFICE_CATEGORIES_ADDEDIT_MODAL_SAVE_BUTTON'
					>
						Save
					</ConfirmButton>
					<ConfirmButton
						isConfirm={false}
						onClick={() => this.close()}
						id='BACKOFFICE_CATEGORIES_ADDEDIT_MODAL_CANCEL_BUTTON'
					>
						Cancel
					</ConfirmButton>
				</SubmitWrap>
			</Modal>
		);
	}
}
