import React, { useState, useEffect } from 'react';
import './dash.css';
import DashboardIframe from './DashboardIframe';
import { LOOKER_SDK } from 'app/dashboard.constants';
import DashboardIframeStateService from './dashboardService';
import LookerWatchlistsDropDown from './LookerWatchlistsDropDown';
import { LookerEmbedFilterParams } from '@looker/embed-sdk';
import { isESGPrivate } from './dashboardService';
import { IAmenityDashboard } from '../../types/Dashboards.types';
interface DashboardLoaderProps {
	isHidden: boolean;
	dashboardService: DashboardIframeStateService;
	dashboard: IAmenityDashboard;
}

const DashboardLoader = (props: DashboardLoaderProps) => {
	// tickers that originate from one of the user's watchlists, it's external to looker
	const [tickersFromWatchlist, setTickers] = useState<string[]>([]);

	// we should not show the watchlist filter on several dashboards
	const [showWatchlistFilter, setShowWatchlistFilter] = useState(true);

	// making sure not to show the watchlist filter when necessary
	useEffect(() => {
		isESGPrivate(props.dashboard) && setShowWatchlistFilter(false);
		return () => setShowWatchlistFilter(true);
	}, [setShowWatchlistFilter, props.dashboard]);

	/**
	 * Extracting tickers & updating dashboard's filters
	 */
	useEffect(() => {
		if (tickersFromWatchlist && tickersFromWatchlist.length > 0 && props.dashboardService) {
			const tickersAsLookerFilterParams: LookerEmbedFilterParams = {
				Ticker: tickersFromWatchlist?.join(',')
			};
			props.dashboardService?.updateFilters(tickersAsLookerFilterParams);
		}
	}, [tickersFromWatchlist, props.dashboardService]);

	return (
		<>
			{showWatchlistFilter && (
				<LookerWatchlistsDropDown isHidden={props.isHidden} setTickers={setTickers} />
			)}
			<DashboardIframe
				id={LOOKER_SDK.APPENDED_ID}
				isHidden={props.isHidden}
				dashboardService={props.dashboardService}
				embedUrlPrefix={props.dashboard?.embedUrl}
			/>
		</>
	);
};

export default DashboardLoader;
