import { getString } from './localstorage.service';
import { LOCAL_STORAGE_KEYS, PRODUCT_NAME } from '../constants';
import { getDomainFromEmail } from './util.service';

export default class IntercomService {
	boot = () => {
		const email = getString(LOCAL_STORAGE_KEYS.USERNAME);
		const user_hash = getString(LOCAL_STORAGE_KEYS.INTERCOM_USER_HASH);
		const domain = email ? getDomainFromEmail(email) : '';
		(window as any).Intercom?.('boot', {
			app_id: 'lsqrupl2',
			name: getString(LOCAL_STORAGE_KEYS.USER_FULL_NAME),
			email,
			user_hash,
			created_at: getString(LOCAL_STORAGE_KEYS.USER_CREATED_AT),
			product: PRODUCT_NAME,
			qualification_role: '',
			company: {
				id: domain,
				name: domain
			}
		});
	};

	update = (newData: any) => {
		(window as any).Intercom?.('update', newData);
	};

	trackEvent = (type: string, data?: any) => {
		(window as any).Intercom?.('trackEvent', type, data);
	};

	shutdown = () => {
		(window as any).Intercom?.('shutdown');
	};
}
