import * as React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import * as ids from '../../../id.constants.js';
import { Colors } from '../../../components/shared/styles';

interface ModalContainerProps {
	width?: number;
}
const ModalContainer = styled.div<ModalContainerProps>`
	width: ${props => (props.width !== undefined ? props.width : '400')}px;
	position: relative;
	background-color: #ffffff;
	padding: 38px 20px 30px;
	box-sizing: border-box;
	border-bottom: 3px solid ${Colors.blue};
`;

const MessageContainer = styled.div`
	width: 100%;
	word-wrap: break-word;
	color: #4a4a4a;
	font-size: 16px;
	font-weight: 600;
	text-align: center;
	box-sizing: border-box;
	margin-bottom: 30px;
	white-space: pre-wrap;
`;

const ButtonsContainer = styled.div`
	width: 100%;
	height: 40px;
`;

interface ActionsContainerProps {
	isShowBothButtons: boolean;
}
const ActionsContainer = styled.div<ActionsContainerProps>`
	height: 100%;
	text-align: center;
`;

interface ActionButtonProps {
	isBlueButton?: boolean;
}
const ActionButton = styled.button<ActionButtonProps>`
	margin-right: 20px;
	min-width: 100px;
	height: 40px;
	border: ${props => (props.isBlueButton ? '0' : '1px solid #00AEEF')};
	background-color: ${props => (props.isBlueButton ? '#00AEEF' : 'rgba(255,255,255,0.99)')};
	color: ${props => (props.isBlueButton ? '#FFFFFF' : '#00AEEF')};
	font-size: 14px;
	cursor: pointer;
	padding: 0 12px;

	:last-child {
		margin-right: 0;
	}
`;

const customStyles = {
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 10,
		backgroundColor: 'rgba(0, 0, 0, 0.5)'
	},
	content: {
		padding: '0',
		border: 'none',
		boxShadow: '0 -1px 11px 0 rgba(88,104,118,0.3)',
		borderRadius: '2px',
		width: 400,
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		transform: 'translate(-50%, -50%)',
		overflow: 'hidden',
		backgroundColor: 'rgba(0,0,0,0)'
	}
};

interface GenericPopupProps {
	isOpen: boolean;
	message: string;
	acceptCallback: Function;
	acceptButtonText: string;
	dismissCallback?: Function;
	dismissButtonText?: string;
	clickOutsideCallback?: Function;
	width?: number;
}

export default class GenericPopup extends React.Component<GenericPopupProps, {}> {
	constructor(props: GenericPopupProps) {
		super(props);
		Modal.setAppElement('#root');
		if (this.props.width !== undefined) {
			customStyles.content.width = this.props.width;
		}
	}

	render() {
		const { width } = this.props;
		return (
			<Modal
				isOpen={this.props.isOpen}
				onRequestClose={() =>
					this.props.clickOutsideCallback
						? this.props.clickOutsideCallback()
						: this.props.acceptCallback()
				}
				style={customStyles}
				contentLabel={'Alert'}
			>
				<ModalContainer width={width}>
					<MessageContainer id={ids.ANNOTATION_MODAL.MESSAGE_OVERLAPPING}>
						{this.props.message}
					</MessageContainer>
					<ButtonsContainer>
						<ActionsContainer isShowBothButtons={this.props.dismissCallback ? true : false}>
							{this.props.dismissCallback && (
								<ActionButton onClick={() => this.props.dismissCallback()}>
									{this.props.dismissButtonText}
								</ActionButton>
							)}
							{
								<ActionButton
									onClick={() => this.props.acceptCallback()}
									id={`BUTTON_${this.props.acceptButtonText}`}
									isBlueButton
								>
									{this.props.acceptButtonText}
								</ActionButton>
							}
						</ActionsContainer>
					</ButtonsContainer>
				</ModalContainer>
			</Modal>
		);
	}
}
