import { useGetCompanyDocumentsQuery } from '../../queries/autogenerate/hooks';
import { Document } from '../../types/types';
import * as _ from 'lodash';
import { getQuarterFromDate } from '../../services/util.service';
import { useRecoilValue } from 'recoil';
import currentDocumentTypeState from '../../recoil/currentDocumentType';
import { DocumentType, codeFromDocumentType } from '../../types/DocTypes.types';
import currentProviderState from '../../recoil/currentProvider';

export const useCompanyDocuments = (
	subscriptionId: string,
	companyId: string,
	companyName: string,
	cursor: string = ''
) => {
	const currentDocType = useRecoilValue(currentDocumentTypeState);
	const currentProvider = useRecoilValue(currentProviderState);

	const { data: companyData, loading } = useGetCompanyDocumentsQuery({
		skip: !subscriptionId || !currentDocType || cursor === null,
		variables: {
			subscriptionId,
			companyId,
			documentType: codeFromDocumentType(currentDocType, currentProvider),
			cursor
		}
	});

	const nextCursor =
		companyData?.organizationSubscription?.companyEntity?.searchFinancialDocuments?.cursor;

	const documents: Document[] = queryDocumentsRestructured(
		companyData?.organizationSubscription.companyEntity?.searchFinancialDocuments?.documents,
		companyName,
		currentDocType
	);

	return { loading, documents, companyData, nextCursor };
};

const getPartialTitle = (title: string, companyName: string): string => {
	const index = title.indexOf(', Q') + 1;
	return companyName + ' ' + title.substring(index, index + 22);
};

const getTotalScore = aggregatedSections => {
	return aggregatedSections.find(section => {
		return section.name === 'total';
	})?.score;
};

const getKeyDriversData = aggregatedSections => {
	const keyDriversRawData = aggregatedSections.find(section => {
		return section.name === 'total';
	})?.keyDrivers;
	return keyDriversRawData?.map(keyDriver => {
		return {
			category_name: keyDriver.name,
			total_pos: keyDriver.positiveEventCount,
			total_neg: keyDriver.negativeEventCount,
			total_neutral: 0
		};
	});
};

const getYear = (date: string) => {
	return new Date(date).getFullYear().toString();
};

const getQuarter = (date: string) => {
	const quarter = getQuarterFromDate(new Date(date));
	return `Q${quarter}`;
};

export const queryDocumentsRestructured = (
	documents: any[],
	companyName: string,
	documentType: DocumentType
): Document[] => {
	return _.map(documents, doc => {
		const displayName = getPartialTitle(doc.documentVersions[0].title, companyName);
		const aggregatedSections = doc.documentVersions[0]?.aggregatedSections;
		const totalScore = getTotalScore(aggregatedSections);

		return {
			documentId: doc.id,
			title: doc.documentVersions[0].title,
			documentDisplayName: displayName,
			eventDate: doc.eventTime,
			publicationDate: doc.eventTime,
			updateTime: doc.updateDate,
			fiscalYear: getYear(doc.fiscalPeriod?.yearEnd),
			fiscalQuarter: getQuarter(doc.fiscalPeriod?.quarterEnd),
			total_daily_sentiment_score: totalScore,
			driver_totals: getKeyDriversData(aggregatedSections),
			documentType: documentType.toString()
		};
	});
};
