import { action, observable, runInAction, computed } from 'mobx';
import * as _ from 'lodash';
import moment from 'moment';

import * as Util from '../services/util.service';
import {
	getDataForKeyDriverCharts,
	getDataForAmenityScoreGraph
} from '../components/ExpandedRow/utils/utilsJarvis';
import DataService from '../services/data.service';
import { info, error } from '../services/logger.service';
import UserTracking from '../services/userTracking.service';
import * as localStorageSrv from '../services/localstorage.service';

import {
	getCombinedCompanyDataAfterQiSearch,
	fetchDocumentMetaData,
	isValidPortfolioId
} from './data.store.util';
import { isDocumentTranscriptType } from '../pages/document/document.util';

import AuthStore from './auth.store';
import FeaturesStore from './features.store';
import UIStore from './ui.store';
import GlobalStore from './global.store';

import { LOCAL_STORAGE_KEYS, USER_TRACKING as UT, PORTFOLIO_NAME_ERRORS } from '../constants';
import {
	Company,
	ModelFlow,
	PortfolioCompany,
	Document,
	Event,
	EventType,
	FormattedText,
	Status,
	SearchedEventForDocument,
	SymbologyCompany,
	Category,
	Watchlist,
	UserDocumentTypesCategories
} from '../types/types';
import { DocumentTypeLegacy, DocumentTypeCategoryLegacy } from '../types/DocTypes.types';
import NavigationService from '../services/navigation.service';

const UTC = UT.USER_TRACKING_CATEGORIES;
const UTA = UT.USER_TRACKING_ACTIONS;

interface AppData {
	username: string;
	documentTypeForFlows: { [documentId: string]: ModelFlow };
	documentType: DocumentTypeLegacy;
}
export default class DataStore {
	@observable allPortfolios: Watchlist[] = undefined;
	@observable currentPortfolioInfo: Watchlist;
	@observable portfolio: PortfolioCompany[] = [];
	@observable isSuccessfulLoad = undefined;
	@observable flows: ModelFlow[] = [];
	@observable currentFlow: ModelFlow;
	/*
	 * This is defined when a flow is retreived from the url
	 */
	@observable currentDocumentMetaData: Document;

	@observable currentCompany: Company;

	@observable formattedTranscript: FormattedText;

	@observable transcriptEvents: Event[] = [];

	@observable eventTypes: EventType[] = [];
	@observable lastHoveredEvent: Event;
	@observable searchedEventsForTranscriptData: SearchedEventForDocument[] = [];

	@observable companyDocuments: any = {};
	@observable companiesData: { [factsetId: string]: PortfolioCompany } = {};

	@observable currentDocumentType: DocumentTypeLegacy;
	@observable documentTypes: {
		transcripts: DocumentTypeCategoryLegacy;
		secFilings: DocumentTypeCategoryLegacy;
	};

	@observable allUserCompanies: PortfolioCompany[] = [];
	@observable isKeyDriverModel: boolean = true;

	appInitialized = false;
	isNewPortfolio = false;

	private static instance: DataStore;

	static getInstance() {
		return DataStore.instance || new DataStore();
	}

	constructor() {
		if (DataStore.instance) {
			return DataStore.instance;
		}
		DataStore.instance = this;
		this.currentDocumentMetaData = Util.getInitDocument();
		this.formattedTranscript = Util.getInitFormattedText();
	}

	@action
	setPortfolio = (portfolio: PortfolioCompany[]) => {
		this.portfolio = portfolio;
	};

	@action
	initAppEnv = (params: any): Promise<any> =>
		new Promise((resolve, reject) => {
			const ds = DataService.getInstance();
			const as = AuthStore.getInstance();
			const authParams = {
				afterLogin: as.afterLogin,
				firstName: as.profile.firstName,
				lastName: as.profile.lastName
			};
			ds.initAppEnv(
				{ ...params, ...authParams },
				(res: { env: string; qlikHost: string; user_permissions: any }) => {
					localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.DIRECT_LINK);
					localStorageSrv.setString(LOCAL_STORAGE_KEYS.CURRENT_API_ENV, res.env);
					AuthStore.getInstance().setUserFeaturePermissions(res.user_permissions);
					resolve();
				},
				(err: any) => {
					error({
						message: `Error initializing app env: ${JSON.stringify(err)}`,
						file: 'data.store',
						functionName: 'initAppEnv'
					});
					reject();
				}
			);
		});

	@action
	initCurrentFlow = (flow?: ModelFlow) => {
		if (this.currentFlow) {
			return;
		}
		const currentFlow = flow || Util.getCurrentFlow(this.currentDocumentType, this.flows);
		this.setCurrentFlow(currentFlow);
	};

	@action
	initDocumentTypeAndFlow = () => {
		const userDocumentTypes = Util.getDocumentTypesFromUserFlows(this.flows);
		const currentAppData = this.getAppDataFromLocalstorage();
		const currentDocType = currentAppData?.documentType;
		const currentFlowWrap =
			currentAppData?.documentTypeForFlows &&
			currentAppData?.documentTypeForFlows[currentAppData?.documentType?.API_ID];

		const finalDocumentType = this.getDocumentTypeInit(currentDocType?.API_ID, userDocumentTypes);
		const finalFlow = this.getFlowInit(currentFlowWrap?.id, finalDocumentType);

		this.setDocumentTypes(userDocumentTypes);
		this.setAppDataToLocalstorage(currentAppData, userDocumentTypes, finalDocumentType, finalFlow);
		this.setCurrentDocumentType(finalDocumentType);
		this.setCurrentFlow(finalFlow);
	};

	handleDocumentTypeChange = (clickedDocumentType: DocumentTypeLegacy) => {
		if (!clickedDocumentType) {
			return;
		}
		const currentAppData = this.getAppDataFromLocalstorage();
		const currentFlowWrap =
			currentAppData?.documentTypeForFlows &&
			currentAppData?.documentTypeForFlows[clickedDocumentType.API_ID];
		const currentFlow = this.getFlowInit(currentFlowWrap?.id, clickedDocumentType);

		this.setCurrentDocumentType(clickedDocumentType);
		this.handleFlowChange(currentFlow);
	};

	getDocumentTypeInit = (docTypeId: number, userDocumentTypes: UserDocumentTypesCategories) => {
		const documentType = Util.getDocumentTypeByDocumentId(userDocumentTypes, docTypeId);
		return documentType || Util.getCurrentDocumentType(userDocumentTypes);
	};

	getFlowInit = (flowId: number, currentDocType: DocumentTypeLegacy) => {
		const legalFlow = _.find(this.flows, flow => flow.id === flowId);
		return legalFlow || Util.getDefaultFlowForDocumentType(currentDocType, this.flows);
	};

	getAppDataFromLocalstorage = (): AppData => {
		const currentAppData: AppData = localStorageSrv.getObject(
			LOCAL_STORAGE_KEYS.DOCUMENT_TYPE_FLOW_DATA
		) as AppData;
		return currentAppData?.username === AuthStore.getInstance().getUsername
			? currentAppData
			: undefined;
	};

	setAppDataToLocalstorage = (
		currentAppData: AppData,
		userDocumentTypes: UserDocumentTypesCategories,
		currentDocumentType: DocumentTypeLegacy,
		currentFlow: ModelFlow
	) => {
		const secTypes = userDocumentTypes?.secFilings?.types || [];
		const transcriptTypes = userDocumentTypes?.transcripts?.types || [];
		const documentTypes = _.concat([...secTypes], [...transcriptTypes]);

		const documentTypeForFlows = _.reduce(
			documentTypes,
			(acc, currentType) => {
				const flow =
					currentDocumentType.API_ID === currentType.API_ID
						? currentFlow
						: currentAppData?.documentTypeForFlows &&
						  currentAppData?.documentTypeForFlows[currentType.API_ID];
				return { ...acc, [currentType.API_ID]: flow };
			},
			{}
		);
		const data: AppData = {
			username: AuthStore.getInstance().getUsername,
			documentTypeForFlows,
			documentType: currentDocumentType
		};
		localStorageSrv.setObject(LOCAL_STORAGE_KEYS.DOCUMENT_TYPE_FLOW_DATA, data);
	};

	@action
	initPortfolio = (): Promise<any> => {
		return this.getFlows()
			.then(() => {
				const featuresStore = FeaturesStore.getInstance();
				const ffViewerDocumentTypeFlowHistory =
					featuresStore?.userFeaturePermissions?.viewer_document_type_flow_history;

				if (ffViewerDocumentTypeFlowHistory) {
					this.initDocumentTypeAndFlow();
				} else {
					// Don't change init order!
					this.initDocumentType();
					this.initCurrentFlow();
				}
			})
			.then(() => {
				if (this.isNewPortfolio) {
					this.touchPortfolio();
					return Promise.resolve();
				}
			})
			.then(() => {
				const navService = NavigationService.instance;
				const pathname = navService.history.location.pathname;
				if (pathname !== '/document') {
					this.getEventTypes();
				}
			})
			.catch((err: any) => {
				error({
					message: `Error in initPortfolio: ${JSON.stringify(err)}`,
					functionName: 'initPortfolio',
					file: 'data.store.ts'
				});
				return Promise.reject(err);
			});
	};

	@action
	initApp = (params?: {
		first_name?: string;
		last_name?: string;
		flow?: ModelFlow;
		docType?: any;
		portfolioId?: number;
	}): Promise<any> =>
		new Promise(resolve => {
			if (this.appInitialized) {
				return resolve();
			}
			this.appInitialized = true;
			const fs = FeaturesStore.getInstance();
			fs.init(() => {
				return this.initAppEnv(params || {}).then(() => {
					this.fetchDataFromLocalStorage();
					resolve();
				});
			});
		});

	@action
	initQI = () =>
		this.getFlows()
			.then(() => {
				this.fetchAllUserCompanies();

				const featuresStore = FeaturesStore.getInstance();
				const ffViewerDocumentTypeFlowHistory =
					featuresStore?.userFeaturePermissions?.viewer_document_type_flow_history;

				if (ffViewerDocumentTypeFlowHistory) {
					this.initDocumentTypeAndFlow();
				} else {
					this.initDocumentType();
					this.initCurrentFlow();
				}
				return Promise.resolve();
			})
			.catch((err: any) => {
				error({
					message: `Error initializing QI: ${JSON.stringify(err)}`,
					functionName: 'initQI',
					file: 'data.store.ts'
				});
				return Promise.reject();
			});

	@action
	initDocument = (flowId: number, documentId: number) =>
		Promise.all([this.getFlow(flowId), this.getDocumentMetaData(flowId, documentId)])
			.then(res => {
				const flow = res[0];
				const document = res[1];
				const companyId = this.currentCompany.id;

				this.setCurrentFlow(flow);
				this.getDataForCompany(companyId, flow, document.documentType);
				this.getEventTypes(() => {
					this.fetchDataForDocument(flow, this.currentDocumentMetaData);
				});
				this.initApp({ flow, docType: document.documentType });
				return Promise.resolve();
			})
			.catch((err: any) => {
				error({
					message: `Error initializing document: ${JSON.stringify(err)}`,
					functionName: 'initDocument',
					file: 'data.store.ts'
				});
				return Promise.reject();
			});

	@action
	fetchDocument = (flow: ModelFlow, company: Company, document: Document) => {
		this.setCurrentDocumentMetaData(document);
		this.setCurrentCompany(company);
		this.fetchDataForDocument(flow, document);
	};

	fetchDataForDocument = (flow: ModelFlow, document: Document) => {
		const flowId = flow?.id || -1;
		const documentId = document?.documentId || -1;
		if (isDocumentTranscriptType(flow, document, this.currentDocumentType)) {
			this.clearFormattedTranscript();
			this.clearTranscriptEvents();
			this.fetchFormattedTranscript(flowId, documentId, document.vendor || '');
			this.fetchTranscriptEvents(flow, documentId);
		}
	};

	setQueriesDataFromLocalStorage = () => {
		const eventsSearchResults = localStorageSrv.getObject(
			LOCAL_STORAGE_KEYS.EVENTS_SEARCH_RESULTS
		) as SearchedEventForDocument[];
		const documentMetaData = localStorageSrv.getObject(
			LOCAL_STORAGE_KEYS.DOCUMENT_META_DATA
		) as Document;

		if (eventsSearchResults) {
			this.setSearchedEventsForDocumentData(eventsSearchResults);
		}
		if (documentMetaData) {
			this.setCurrentDocumentMetaData(documentMetaData);
		}
	};

	@action
	fetchDataFromLocalStorage = () => {
		this.setQueriesDataFromLocalStorage();
	};
	@action
	getEventTypes = (cb?: any) => {
		const ds = DataService.getInstance();
		// only_legal - event types that have a category
		ds.getEventTypes(
			{ only_legal: true },
			(eventTypes: EventType[]) => {
				this.setEventTypes(eventTypes);
				this.updateDocumentEventTypes();
				if (cb) {
					cb();
				}
			},
			(err: any) => {
				error({
					message: `Error getting event types: ${JSON.stringify(err)}`,
					file: 'data.store',
					functionName: 'getEventTypes'
				});
			}
		);
	};

	@action
	setEventTypes = (eventTypes: EventType[]) => {
		this.eventTypes = eventTypes;
	};

	@action
	updateDocumentEventTypes = () => {
		if (this.isKeyDriverModel || !this.currentDocumentMetaData.systemEvents) {
			return;
		}
		// setting event types again because sometimes the event types data loaded after the events data
		this.currentDocumentMetaData.systemEvents = observable(
			Util.setSystemEventsCategoryTypes(this.currentDocumentMetaData.systemEvents, this.eventTypes)
		);
		this.setAllEvents();
	};

	@action
	getUserFeaturesPermissions = (): Promise<any> =>
		new Promise((resolve, reject) => {
			const ds = DataService.getInstance();
			ds.getUserFeaturePermissions(
				{},
				(data: any) => {
					AuthStore.getInstance().setUserFeaturePermissions(data);
					resolve(data);
				},
				(err: any) => {
					error({
						message: `Error getting user features permissions: ${JSON.stringify(err)}`,
						file: 'data.store',
						functionName: 'getUserFeaturesPermissions'
					});
					reject(err);
				}
			);
		});

	@action
	fetchTranscriptEvents = (flow?: ModelFlow, documentId?: number) => {
		const ds = DataService.getInstance();
		const transcriptId = documentId || this.currentDocumentMetaData.documentId;
		const { id, startDate } = flow || this.currentFlow;
		const params = {
			transcriptId,
			flowId: id,
			startDate
		};
		ds.getEventsByUser(
			params,
			(events: any) => {
				runInAction(() => {
					this.currentDocumentMetaData.userEvents = _.map(events.userEvents, e => {
						return { ...e, show: true, isUserDefined: true };
					});
					const isKeyDriverModel = _.some(events.systemEvents, e => !!e.keyDriver);
					this.setIsKeyDriverModel(isKeyDriverModel);

					const parsedEvents = Util.parseEventsNew(
						events.systemEvents,
						this.eventTypes,
						isKeyDriverModel
					);
					this.currentDocumentMetaData.systemEvents = observable(parsedEvents);
					this.setAllEvents();
				});
			},
			(err: any) => {
				error({
					message: `Error fetching transcript events: ${JSON.stringify(err)}`,
					file: 'data.store',
					functionName: 'fetchTranscriptEvents'
				});
			}
		);
	};

	@action
	setIsKeyDriverModel = (state: boolean) => {
		this.isKeyDriverModel = state;
	};

	@action
	setLastHoveredEvent = (lastHoveredEvent: Event) => {
		this.lastHoveredEvent = lastHoveredEvent;
	};

	getFormattedEventData = (event: Event, isHighlight: boolean): Event => {
		const systemEventId: string = isHighlight
			? `${this.currentDocumentMetaData.documentId}-${event.startIndexAPI}-${event.endIndexAPI}`
			: '';

		const formattedEvent: Event = {
			id: event.id,
			startIndex: event.startIndex,
			endIndex: event.endIndex,
			text: event.text,
			description: event.description,
			polarity: event.polarity,
			categoryName: event.categoryName,
			isUserDefined: true,
			typeName: event.typeName ? event.typeName : '',
			flow: this.currentFlow && this.currentFlow.id,
			documentTitle: this.currentDocumentMetaData.title,
			transcriptId: this.currentDocumentMetaData.documentId,
			ticker: this.currentCompany.ticker,
			region: this.currentCompany.region,
			systemEventId: systemEventId,
			status: Status.new,
			sentence: event.sentence || ''
		};

		return formattedEvent;
	};

	@action
	updateEvent = (event: Event, isDeleted: boolean = false) => {
		const updatedData: any = {
			polarity: event.polarity,
			categoryName: event.categoryName,
			description: event.description
		};

		const params = {
			eventId: event.id,
			updatedData: updatedData,
			isDeleted: isDeleted,
			ticker: this.currentCompany.ticker,
			region: this.currentCompany.region
		};

		const ds = DataService.getInstance();

		ds.updateUserEvent(
			params,
			() => {
				this.fetchTranscriptEvents(this.currentFlow);
			},
			(err: any) => {
				const stringErr = JSON.stringify(err);
				error({
					message: isDeleted
						? `Error deleting system event: ${stringErr}`
						: `Error updating event: ${stringErr}`,
					file: 'DataStore',
					functionName: 'updateEvent'
				});
			}
		);
	};

	@action
	setAllEvents = () => {
		this.transcriptEvents = _.union(
			this.currentDocumentMetaData.userEvents || [],
			this.currentDocumentMetaData.systemEvents || []
		);
		if (this.searchedEventsForTranscriptData.length > 0 && this.transcriptEvents.length > 0) {
			this.transcriptEvents = _.map(this.transcriptEvents, event => ({
				...event,
				hide: !_.some(this.searchedEventsForTranscriptData, searchedEvent =>
					Util.eventEqualsSearchedEvent(event, searchedEvent)
				)
			}));
		}
	};

	@action
	clearTranscriptEvents = () => {
		this.transcriptEvents = [];
	};

	@action
	deleteUserEvent = (event: Event) => {
		const ds = DataService.getInstance();

		const eventId: number = event.id;
		const transcriptId: number = this.currentDocumentMetaData.documentId;
		const params = {
			eventId: eventId,
			transcriptId: transcriptId
		};

		ds.deleteEvent(
			params,
			() => {
				runInAction(() => {
					this.currentDocumentMetaData.userEvents = _.filter(
						this.currentDocumentMetaData.userEvents,
						e => e.id !== eventId
					);
					this.setAllEvents();
				});
			},
			err => {
				error({
					message: `Error deleting user event: ${JSON.stringify(err)}`,
					file: 'DataStore',
					functionName: 'deleteUserEvent'
				});
			}
		);
	};

	@action
	// deleting system event - creating an annotation instead
	deleteSystemEvent = (event: Event) => {
		this.createEvent(event, true);
	};

	@action
	createEvent = (event: Event, isDeleted: boolean = false) => {
		const isHighlight = !event.isUserDefined;
		const formattedEvent: Event = this.getFormattedEventData(event, isHighlight);

		const params = {
			event: formattedEvent,
			isDeleted: isDeleted
		};

		const ds = DataService.getInstance();
		const ut = new UserTracking();
		const isEdit = event.id && !isDeleted;

		ds.createEvent(
			params,
			() => {
				this.fetchTranscriptEvents(this.currentFlow);

				ut.setEvent(UTC.TRANSCRIPT, UTA.ANNOTATION_ADDED, this.currentDocumentMetaData.title, {
					documentName: this.currentDocumentMetaData.title
				});
			},
			err => {
				if (isEdit) {
					error({
						message: `Error editing system event: ${JSON.stringify(err)}`,
						file: 'DataStore',
						functionName: 'createEvent'
					});
				} else if (isDeleted) {
					error({
						message: `Error deleting system event: ${JSON.stringify(err)}`,
						file: 'DataStore',
						functionName: 'createEvent'
					});
				}
			}
		);
	};

	@action
	addEventToList = (event: Event) => {
		const isAlreadyInList =
			_.findIndex(this.currentDocumentMetaData.userEvents, e => e.id === event.id) !== -1;

		if (!isAlreadyInList) {
			const newTranscript = { ...this.currentDocumentMetaData };
			newTranscript.userEvents.push(event);
			this.currentDocumentMetaData = newTranscript;
		}
	};

	@action
	addCompanyToPortfolio = (company: Company | SymbologyCompany, portfolioId?: number) => {
		this.addCompanyToUserPortfolio(company, portfolioId);
	};

	@action
	addCompanyToPortfolios = (
		company: SymbologyCompany | Company,
		portfoliosIds: number[]
	): Promise<any> =>
		new Promise((resolve, reject) => {
			const ds = DataService.getInstance();
			const params: any = {
				portfoliosIds,
				company: company && JSON.stringify(company),
				company_id: company ? company.id : -1,
				is_symbology: true
			};
			ds.addCompanyToUserPortfolios(
				params,
				() => {
					info({
						message: `Company added to portfolio: ${company.id}`,
						file: 'DataStore',
						functionName: 'addCompanyToUserPortfolio'
					});

					if (this.currentDocumentMetaData && this.currentCompany) {
						this.setCurrentCompany({
							...this.currentCompany,
							isInPortfolio: true
						});
					}
					resolve();
				},
				err => {
					error({
						message: `Error adding company to portfolio: ${JSON.stringify(err)}`,
						file: 'DataStore',
						functionName: 'addCompanyToUserPortfolio'
					});
					reject();
				}
			);
		});

	@action
	deleteCompanyFromPortfolio = (companyId: number | string) => {
		const ds = DataService.getInstance();

		const params: any = {
			company_id: companyId,
			is_symbology: true,
			id: this.currentPortfolioInfo && this.currentPortfolioInfo.id
		};

		ds.deleteUserCompany(
			params,
			(tra: any) => {
				runInAction(() => {
					const portfolio = _.filter(
						this.portfolio,
						(item: any) => item[params.is_symbology ? 'factsetId' : 'id'] !== companyId
					);
					this.setPortfolio(portfolio);
				});
			},
			(err: any) => {
				error({
					message: `Error deleting user company`,
					file: 'data.store',
					functionName: 'deleteCompanyFromPortfolio'
				});
			}
		);
	};

	@action
	addCompanyToUserPortfolio = (company: SymbologyCompany | Company, portfolioId: number) => {
		const ds = DataService.getInstance();
		const documentType = localStorageSrv.getObject(LOCAL_STORAGE_KEYS.CURRENT_DOCUMENT_TYPE);

		const params: any = {
			company: JSON.stringify(company),
			flow: this.currentFlow,
			documentType: documentType ? documentType.NAME : '',
			is_symbology: true,
			id: portfolioId
		};

		ds.updateUserCompanies(
			params,
			(newCompanies: { portfolio: any[]; startTime: string; endTime: string }) => {
				info({
					message: `Company added to portfolio: ${company.id}`,
					file: 'DataStore',
					functionName: 'addCompanyToUserPortfolio'
				});

				if (this.currentDocumentMetaData && this.currentCompany) {
					this.setCurrentCompany({
						...this.currentCompany,
						isInPortfolio: true
					});
				}
				runInAction(() => {
					this.portfolio.push.apply(this.portfolio, Util.parsePortfolio(newCompanies.portfolio));
				});
			},
			err => {
				error({
					message: `Error adding company to portfolio: ${JSON.stringify(err)}`,
					file: 'DataStore',
					functionName: 'addCompanyToUserPortfolio'
				});
			}
		);
	};

	@action
	setSuccessfulLoad = (status: boolean) => {
		this.isSuccessfulLoad = status;
	};

	@action
	setCurrentFlow = (flow: ModelFlow) => {
		const persistantFlow = {
			user: AuthStore.getInstance().getUsername,
			flow: flow
		};
		UIStore.getInstance().resetExpandedCompanies();
		this.currentFlow = { ...flow };
		localStorageSrv.setObject(LOCAL_STORAGE_KEYS.CURRENT_FLOW, persistantFlow);
	};

	@computed
	get currentFlowProviderId(): number {
		return this.currentFlow ? this.currentFlow.dataSourcesDetails[0].providerId : -1;
	}

	@action
	setCurrentDocumentMetaData = (doc: Document) => {
		this.currentDocumentMetaData = doc;
		localStorageSrv.setObject(LOCAL_STORAGE_KEYS.DOCUMENT_META_DATA, doc);
	};

	@action
	handleFlowChange = (flow: ModelFlow) => {
		const currentAppData = this.getAppDataFromLocalstorage();
		const previousFlowId = this.currentFlow?.id;
		try {
			this.resetDataOnFlowChange();
			this.setCurrentFlow(flow);
			this.setAppDataToLocalstorage(
				currentAppData,
				this.documentTypes,
				this.currentDocumentType,
				flow
			);
		} catch (e) {
			error({
				message: `Error changing flow from ${previousFlowId} to ${flow?.id}: ${e}`,
				file: 'DataStore',
				functionName: 'handleFlowChange'
			});
		}
	};

	changeFlowById = (flowId: number) => {
		const flow = _.find(this.flows, flow => flow.id === flowId);
		if (flow?.id !== this.currentFlow?.id) {
			this.handleFlowChange(flow);
		}
	};

	resetDataOnFlowChange = () => {
		this.clearCompaniesData();
		this.clearCompaniesDocuments();
		this.setIsKeyDriverModel(false);

		const gs = GlobalStore.getInstance();
		gs.setEventFirstSearch(false);
	};

	@action
	getFlows = (): Promise<any> =>
		new Promise((resolve, reject) => {
			const functionName = 'getFlows';
			if (!_.isEmpty(this.flows)) {
				return resolve ? resolve() : Promise.resolve();
			}
			const ds = DataService.getInstance();
			return ds.getFlows(
				{},
				(res: ModelFlow[]) => {
					runInAction(() => {
						this.flows = res;
						if (this.flows.length === 0) {
							error({
								message: 'No flows found',
								file: 'DataStore',
								functionName
							});
						}
						if (resolve) {
							resolve();
						}
					});
				},
				err => {
					this.setSuccessfulLoad(false);
					error({
						message: 'Error getting flows',
						file: 'DataStore',
						functionName
					});
					reject(err);
				}
			);
		});

	@action
	getFlow = (flowId: number): Promise<any> =>
		new Promise((resolve, reject) => {
			const functionName = 'getFlow';
			const ds = DataService.getInstance();
			const params = {
				flowId: flowId
			};
			ds.getFlow(
				params,
				(flow: ModelFlow) => {
					if (resolve) {
						resolve(flow);
					}
				},
				err => {
					error({
						message: `Error getting flow, ${JSON.stringify(err)}`,
						file: 'DataStore',
						functionName
					});
					reject(err);
				}
			);
		});

	getDocumentMetaData = (
		flowId: number,
		documentId: number,
		company?: Company
	): Promise<Document> =>
		fetchDocumentMetaData(flowId, documentId)
			.then((res: { documentMetaData: Document; company: Company }) => {
				const parsedCompany = _.isEmpty(company)
					? res.company
					: getCombinedCompanyDataAfterQiSearch(res.company, company);
				this.setCurrentDocumentMetaData(res.documentMetaData);
				this.setCurrentCompany(parsedCompany);
				return res.documentMetaData;
			})
			.catch(err => {
				error({
					message: `Error in getDocumentMetaData, ${JSON.stringify(err)}`,
					file: 'DataStore',
					functionName: 'getDocumentMetaData'
				});
				this.setCurrentDocumentMetaData(undefined);
				this.setCurrentCompany(undefined);
				return undefined;
			});

	@action
	fetchFormattedTranscript = (flowId: number, documentId: number, vendor: string) => {
		const functionName = 'fetchFormattedTranscript';
		const params = {
			flowId,
			transcript: {
				id: documentId,
				vendor
			}
		};

		const ds = DataService.getInstance();
		ds.getFormattedTranscript(
			params,
			(res: FormattedText) => {
				this.setFormattedTranscript(res);
				info({
					message: `Transcript loaded, id: ${params.transcript.id}`,
					file: 'DataStore',
					functionName
				});
			},
			err => {
				error({
					message: `Error loading transcript ${JSON.stringify(err)}`,
					file: 'DataStore',
					functionName
				});

				const navService = NavigationService.instance;
				navService.goToNotFound();
			}
		);
	};

	@action
	setFormattedTranscript = (formattedTranscript: FormattedText) => {
		this.formattedTranscript = formattedTranscript;
	};

	@action
	clearFormattedTranscript = () => {
		this.setFormattedTranscript(Util.getInitFormattedText());
	};

	@action
	clearAll = () => {
		this.clearCurrentCompany();
		this.clearCurrentDocumentMetaData();
		this.clearFormattedTranscript();
		this.clearTranscriptEvents();
	};

	@action
	clearCurrentDocumentMetaData = () => {
		this.currentDocumentMetaData = Util.getInitDocument();
		localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.DOCUMENT_META_DATA);
	};

	@action
	setCurrentCompany = (company: Company) => {
		this.currentCompany = company;
	};

	@action
	setSearchedEventsForDocumentData = (
		searchedEventsForTranscriptData: SearchedEventForDocument[]
	) => {
		this.searchedEventsForTranscriptData = searchedEventsForTranscriptData;
		localStorageSrv.setObject(
			LOCAL_STORAGE_KEYS.EVENTS_SEARCH_RESULTS,
			this.searchedEventsForTranscriptData
		);
	};

	@action
	clearCurrentCompany = () => {
		this.currentCompany = undefined;
	};

	@action
	getDataForCompany = (companyId: number | string, flow: ModelFlow, documentType: string) => {
		if (companyId === undefined || companyId === null) {
			return;
		}
		const functionName = 'getDataForCompany';
		const key = companyId.toString();

		const flowStartYear = new Date(flow && flow.startDate).getUTCFullYear();
		const currentYear = new Date().getUTCFullYear();
		const startDate = `${flowStartYear}-01-01`;
		const endDate = `${currentYear}-12-31`;

		const ds = DataService.getInstance();
		const params = {
			flow,
			companyId: companyId,
			documentType,
			startDate,
			endDate
		};

		ds.getCompanyDocumentsWithoutSpinner(
			params,
			(res: Document[]) => {
				this.setCompaniesDocuments(key, res, params.flow.id, documentType);
			},
			(err: any) => {
				error({
					message: `Error loading document for company ${JSON.stringify(err)}`,
					file: 'expandedRow',
					functionName
				});
			}
		);
	};

	@action
	setCompaniesDocuments = (key: string, data: any, flowId: number, documentType: string) => {
		const keyDriversData = getDataForKeyDriverCharts(data);
		const amenityScoreData = getDataForAmenityScoreGraph(data);
		this.companyDocuments = {
			...this.companyDocuments,
			[key]: {
				data,
				keyDriversData,
				amenityScoreData,
				flowId,
				documentType,
				updateDate: moment().valueOf()
			}
		};
	};

	@action
	clearCompaniesDocuments = () => {
		this.companyDocuments = {};
	};

	@action
	searchForCompanies = async (searchQuery: string): Promise<any> =>
		new Promise((resolve, reject) => {
			const ds = DataService.getInstance();
			ds.searchCompanies(
				{ searchQuery },
				res => resolve(res),
				err => reject(err)
			);
		});

	getAllCategories = (cb: Function) => {
		const ds = DataService.getInstance();
		ds.getAllCategoriesBackoffice(
			{},
			(categories: Category[]) => {
				cb(categories);
			},
			err => {
				error({
					message: `Error getting all categories: ${JSON.stringify(err)}`,
					file: 'data.store',
					functionName: 'getAllCategories'
				});
			}
		);
	};

	@action
	setDocumentTypes = (documentTypes: {
		transcripts: DocumentTypeCategoryLegacy;
		secFilings: DocumentTypeCategoryLegacy;
	}) => {
		this.documentTypes = documentTypes;
	};

	@action
	setCurrentDocumentType = (dt: DocumentTypeLegacy) => {
		this.currentDocumentType = dt;
		localStorageSrv.setObject(LOCAL_STORAGE_KEYS.CURRENT_DOCUMENT_TYPE, dt);
	};

	@action
	initDocumentType = (documentType: string = undefined) => {
		if (!_.isEmpty(this.documentTypes) && this.currentDocumentType) {
			return;
		}
		const userDocTypes = Util.getDocumentTypesFromUserFlows(this.flows);
		let currentDocumentType;

		if (documentType) {
			currentDocumentType = Util.getDocumentTypeByDocumentName(userDocTypes, documentType);
		} else {
			currentDocumentType = Util.getCurrentDocumentType(userDocTypes, () => {
				error({
					message: 'Document type is not found - this cannot happen',
					functionName: 'initDocumentType',
					file: 'data.store'
				});
			});
		}

		this.setDocumentTypes(userDocTypes);
		this.setCurrentDocumentType(currentDocumentType);
	};

	@action
	fetchAllPortfolios = () => {
		const ds = DataService.getInstance();
		ds.getAllPortfolios(
			{},
			(portfolios: any) => {
				this.setAllPortfolios(portfolios);
			},
			(err: any) => {
				error({
					message: `Failed fetching all portfolios, ${JSON.stringify(err)}`,
					functionName: 'fetchAllPortfolios',
					file: 'data.store'
				});
			}
		);
	};

	@action
	setAllUserCompanies = (companies: PortfolioCompany[]) => {
		this.allUserCompanies = companies;
	};

	@action
	fetchAllUserCompanies = () => {
		const ds = DataService.getInstance();
		ds.getAllUserCompanies(
			{},
			(companies: any) => {
				this.setAllUserCompanies(companies);
			},
			(err: any) => {
				error({
					message: `Failed fetching all user companies`,
					functionName: 'fetchAllUserCompanies',
					file: 'data.store'
				});
			}
		);
	};

	@action
	createNewPortfolio = (name: string, onSuccess: Function, onError: Function) => {
		if (!name) {
			onError(PORTFOLIO_NAME_ERRORS.EMPTY);
			return;
		}
		if (_.some(this.allPortfolios, { name })) {
			onError(PORTFOLIO_NAME_ERRORS.DUPLICATE);
			return;
		}

		this.isNewPortfolio = true;
		this.setSuccessfulLoad(true);

		const ds = DataService.getInstance();
		const ut = new UserTracking();

		ut.setEvent(UTC.PORTFOLIO_MANAGEMENT, UTA.ADD_NEW_PORTFOLIO, '');

		ds.createNewPortfolio(
			{ name },
			(portfolio: Watchlist) => {
				this.setCurrentPortfolioInfo(portfolio);
				onSuccess(portfolio);
			},
			(err: any) => onError(err?.response?.data?.err?.message)
		);
	};

	@action
	setCurrentPortfolioById = (portfolioId: string) => {
		if (
			(this.currentPortfolioInfo && this.currentPortfolioInfo.id === portfolioId) ||
			!isValidPortfolioId(portfolioId)
		) {
			return;
		}
		const portfolio = _.find(this.allPortfolios, p => p.id === portfolioId || p.id === portfolioId);
		this.currentPortfolioInfo = portfolio
			? { ...portfolio }
			: { id: portfolioId, name: '', size: -1 };
	};

	@action
	setCurrentPortfolioInfo = (newPortfolio: Watchlist) => {
		this.currentPortfolioInfo = { ...newPortfolio };
	};

	@action
	touchPortfolio = () => {
		this.isNewPortfolio = false;
	};

	@action
	setAllPortfolios = (allPortfolios: Watchlist[]) => {
		this.allPortfolios = allPortfolios;
	};

	@action
	renamePortfolio = (id: string, name: string, onSuccess: Function, onError: Function) => {
		const [[portfolio], rest] = _.partition(
			this.allPortfolios,
			(portfolio: Watchlist) => portfolio.id === id
		);
		if (!portfolio) {
			onError('Something went wrong, please try again');
			return;
		}
		if (!name) {
			onError(PORTFOLIO_NAME_ERRORS.EMPTY);
			return;
		}
		if (_.some(rest, { name })) {
			onError(PORTFOLIO_NAME_ERRORS.DUPLICATE);
			return;
		}

		const ds = DataService.getInstance();
		ds.renamePortfolio(
			{ id, name },
			(res: any) => {
				this.setAllPortfolios([...rest, { ...portfolio, name }]);
				onSuccess();
			},
			(err: any) => {
				onError(err.response.data.err.message);
			}
		);
	};

	@action
	erasePortfolio = (id: string, onSuccess: Function, onError: Function) => {
		const [[portfolio], rest] = _.partition(
			this.allPortfolios,
			(portfolio: Watchlist) => portfolio.id === id
		);
		if (!portfolio) {
			onError('Something went wrong, please try again');
			return;
		}

		const ds = DataService.getInstance();
		ds.erasePortfolio(
			{ id },
			(res: any) => {
				this.setAllPortfolios([...rest]);
				onSuccess();
			},
			(err: any) => {
				onError(err.response.data.err.message);
			}
		);
	};

	@action
	documentCleanUp = () => {
		this.clearFormattedTranscript();
		this.clearCurrentCompany();
	};

	@action
	documentMetaDataCleanUp = () => {
		this.clearCurrentDocumentMetaData();
	};

	@action
	setCompaniesData = (companies: PortfolioCompany[]) => {
		const updatedCompaniesData = { ...this.companiesData };
		_.each(companies, company => {
			updatedCompaniesData[company.id] = company;
		});
		this.companiesData = updatedCompaniesData;
	};

	@action
	clearCompaniesData = () => {
		this.companiesData = {};
	};
}
