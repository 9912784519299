import { AnalyticsErrorTypes } from './types/types';

const DECEPTION_DASHBOARD_ID = 'd86b6499-c197-4760-b05c-4992e60cbc25';
const CAPITAL_RETURN_DASHBOARD = '6d26530d-a664-4282-a053-b0537460279e';
const CAPEX_DASHBOARD_ID = '482208bb-0f15-4b62-ac34-18589347ea53';
const EXECUTIVE_CHANGE_DASHBOARD_ID = '95395984-1361-4ad6-9878-8e1c012f7f3a';
const IRREGULARITIES_DASHBOARD_ID = 'bca34663-5891-458d-bcbb-51f75b6d304e';
const LEGAL_REGULATORY_DASHBOARD_ID = 'eb5b328f-f0ae-40ed-ac59-159ad91a91e4';
const MARGIN_DASHBOARD_ID = '5733ae35-b648-40e1-9e3e-48ea410a4c9c';
const MERGER_ACQUISITION_DASHBOARD_ID = 'a6d63397-7b0d-42e9-ad13-e2918d38553a';
const WAGE_DASHBOARD_ID = '03dc8b92-87bf-4c55-ba37-7ad135c3f306';
const EARNINGS_SENTIMENT_ANALYSIS_DASHBOARD_ID = 'd89c9327-a9cf-4ad3-882e-2c27f3875ec9';
const TOPIC_SENTIMENT_ANALYSIS_DASHBOARD_ID = 'e62c1b04-fb99-47c3-8877-366204200cab';
const TOPIC_SENTIMENT_ANALYSIS_DASHBOARD_ID2 = 'b2a9a20c-ebfd-48d5-88fe-d9ee2db8e091';
const TOPIC_SENTIMENT_ANALYSIS_DASHBOARD_BETA_ID = '0c9229a6-df10-4e18-84cc-0cecf1eb782e';
const EY_DASHBOARD_ID = 'a7c7a28b-c5c3-49fc-a9df-e490deb9f7df';
const EC_OPIC_ANALYSIS = 'cac5b8e3-7db9-4bc7-be82-108e98edabdb';
const PRIVATE_ESG_OVERVIEW = 23;

export const ARTICLE_SENTIMENT_ANALYSIS_DASHBOARD_ID = 'f6e2b505-1996-4632-b082-a2448cfa704f';

export const DASHBOARDS_WITH_WATCHLIST_FILTER_DISABLED = [PRIVATE_ESG_OVERVIEW];

export const DASHBOARDS_WITH_FILTER_ENABLED = [
	DECEPTION_DASHBOARD_ID,
	CAPITAL_RETURN_DASHBOARD,
	CAPEX_DASHBOARD_ID,
	EXECUTIVE_CHANGE_DASHBOARD_ID,
	IRREGULARITIES_DASHBOARD_ID,
	LEGAL_REGULATORY_DASHBOARD_ID,
	MARGIN_DASHBOARD_ID,
	MERGER_ACQUISITION_DASHBOARD_ID,
	WAGE_DASHBOARD_ID,
	EARNINGS_SENTIMENT_ANALYSIS_DASHBOARD_ID,
	TOPIC_SENTIMENT_ANALYSIS_DASHBOARD_ID,
	TOPIC_SENTIMENT_ANALYSIS_DASHBOARD_BETA_ID,
	TOPIC_SENTIMENT_ANALYSIS_DASHBOARD_ID2,
	EY_DASHBOARD_ID,
	EC_OPIC_ANALYSIS
];

export const ANALYTICS_ERRORS = {
	UNKNOWN: {
		type: AnalyticsErrorTypes.UNKNOWN,
		message: 'Your analytics session has timed out. Please log back in to refresh your session.',
		acceptButtonText: 'Go to Login',
		dismissButtonText: 'Retry',
		logout: true
	},
	EMPTY_WATCHLIST: {
		type: AnalyticsErrorTypes.EMPTY_WATCHLIST,
		message: 'Cannot load watchlist, the watchlist does not contain any tickers',
		acceptButtonText: 'Ok',
		dismissButtonText: 'Contact us',
		logout: false
	},
	IFRAMES_CRASHED: {
		type: AnalyticsErrorTypes.IFRAMES_CRASHED,
		message:
			'An error has occurred while loading the required dashboards, Please contact our support if the error persists',
		acceptButtonText: 'Try again',
		dismissButtonText: 'Contact us',
		logout: false
	},
	NO_DATA_TO_EXPORT: {
		type: AnalyticsErrorTypes.NO_DATA_TO_EXPORT,
		message: 'No data to export, try to add filters or contact our support if the error persists',
		acceptButtonText: 'Ok',
		dismissButtonText: 'Contact us',
		logout: false
	}
};

export const LOOKER_SDK = {
	LOCAL_STORAGE_FILTER: 'dashboardsUserFilters',
	APPENDED_ID: 'dashboardContent'
};
