import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as _ from 'lodash';
import * as Scroll from 'react-scroll';
import Tooltip from 'react-tooltip';
import styled from 'styled-components';

import { PortfolioCompany, Category } from '../../types/types';
import { DocumentTypeLegacy } from '../../types/DocTypes.types';
import Images from '../../shared/images';
import { LOCAL_STORAGE_KEYS, STORE_UI, STORE_FEATURES } from '../../constants';
import { PORTFOLIO_TRANSCRIPT_TABLE_COLUMNS } from './columnConstants';
import * as TableStyles from './commonTableStyles';
import CommonTable from './commonTable';
import * as localStorageSrv from '../../services/localstorage.service';
import WatchlistTableRow from './watchlistRow';
import UIStore from '../../stores/ui.store';
import * as ids from '../../../id.constants.js';

const EmptyIcon = styled.img`
	display: block;
	margin: auto;
`;

interface EmptyBodyProps {
	fontSize: number;
	marginTop: number;
	fontWeight: number;
	lineHeight: number;
}

const EmptyBodyTitle = styled.div<EmptyBodyProps>`
	font-size: ${props => props.fontSize}px;
	margin-top: ${props => props.marginTop}px;
	height: 22px;
	font-family: Assistant;
	font-weight: ${props => props.fontWeight};
	font-style: normal;
	font-stretch: normal;
	line-height: ${props => props.lineHeight};
	letter-spacing: normal;
	text-align: center;
	color: #4a4a4a;
	text-align: center;
`;

const ContentEmpty = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const columnSort = {
	ticker: {
		name: 'ticker',
		path: ['ticker']
	},
	companyName: {
		name: 'company_name',
		path: ['name']
	},
	region: {
		name: 'region',
		path: ['region']
	},
	sector: {
		name: 'sector',
		path: ['sector']
	},
	amenityScore: {
		name: 'sentiment',
		displayName: 'Amenity Score',
		path: ['latestDocument', 'total_daily_sentiment_score']
	},
	amenityScoreChange: {
		name: 'amenity_score_change',
		displayName: 'Amenity Score Change',
		path: ['latestDocument', 'amenity_score_change']
	},
	deceptionScore: {
		name: 'deception_score',
		displayName: 'Deception Score',
		path: ['latestDocument', 'deception_score']
	},
	deceptionScoreChange: {
		name: 'deception_score_change',
		displayName: 'Deception Score Change',
		path: ['latestDocument', 'deception_score_change']
	},
	callDate: {
		name: 'call_date',
		path: ['latestDocument', 'eventDate']
	}
};

interface TableProps {
	onDeleteCompany: (factsetId: string) => void;
	onNavigateToTicker: Function;
	companies:
		| {
				id: string;
				name: string;
				ticker: string;
				region: string;
				sector: string;
				keyDrivers: any[];
		  }[]
		| PortfolioCompany[];
	currentDocumentType: DocumentTypeLegacy;
	addedCompanyId: number | string;
	companyAddedCounter: number;
	onScrolledToRow: Function;
	categories: Category[];
	isSuccessfulLoad: boolean;
	subscriptionId?: string;
	isFetchMoreInFlight?: boolean;
}

interface TableState {
	sortedCol: any;
	isAscending: boolean;
	showAmenityScore: boolean;
	showDeceptionScore: boolean;
}

@inject(STORE_UI, STORE_FEATURES)
@observer
export default class PortfolioTable extends React.Component<TableProps, TableState> {
	scrollContainer;

	isScrollingToRow = false;
	addedCompanyId: number | string = -1;

	constructor(props: TableProps) {
		super(props);
		const sortBy = localStorageSrv.getObject(LOCAL_STORAGE_KEYS.PORTFOLIO_SORT_BY);
		this.state = {
			sortedCol: sortBy ? sortBy.sortedCol : columnSort.ticker,
			isAscending: !sortBy || sortBy.isAscending,
			showAmenityScore: true, // is Amenity Score header chosen or Amenity Score Changes
			showDeceptionScore: true // is Deception Score header chosen or Deception Score Changes
		};
	}

	componentWillReceiveProps(nextProps: TableProps) {
		if (
			this.companyWasAdded(this.props.companyAddedCounter, nextProps.companyAddedCounter) ||
			this.isScrollingToRow
		) {
			this.isScrollingToRow = true;
			this.addedCompanyId = nextProps.addedCompanyId;
		}
	}

	componentDidMount() {
		const { companies, addedCompanyId, isSuccessfulLoad } = this.props;
		if (!isSuccessfulLoad) {
			return;
		}

		if (companies && companies.length === 1 && addedCompanyId !== -1) {
			this.focusRow(addedCompanyId);
		}
	}

	focusRow = (companyId: number | string, shouldExpand?: boolean) => {
		const scrollId = `${ids.WACTHLIST_PAGE.SCROLL_ROW_PREFIX}${companyId}`;
		// @ts-ignore
		const company = _.find(this.props.companies, c => c.id === companyId);
		this.onScrolledToRow(company, shouldExpand);
		Scroll.scroller.scrollTo(scrollId, {
			duration: 1500,
			offset: -300,
			delay: 0,
			smooth: true,
			container: this.scrollContainer
		});
	};

	onClickHeader = (clickedColumn: any) => {
		const state = {
			sortedCol: clickedColumn,
			isAscending: clickedColumn.name !== this.state.sortedCol.name || !this.state.isAscending
		};
		localStorageSrv.setObject(LOCAL_STORAGE_KEYS.PORTFOLIO_SORT_BY, state);
		this.setState(state);
	};

	deleteCompanyFromPortfolio = (event: any, factsetId: string) => {
		event.stopPropagation();
		(this.props[STORE_UI] as UIStore).removeExpandedCompany(factsetId);
		this.props.onDeleteCompany(factsetId);
	};

	sortTableByColumn = (
		portfolio: { id: string; name: string; ticker: string; region: string }[] | PortfolioCompany[],
		column: any,
		isAscending: boolean
	): any[] => {
		if (portfolio?.length === 0) {
			return [];
		}

		const sortKeysPath = column.path;
		const sortedPortfolio = _.orderBy(
			portfolio,
			(pc: PortfolioCompany) => {
				let companyValueToSortBy = '';

				try {
					companyValueToSortBy =
						sortKeysPath.length > 1 ? pc[sortKeysPath[0]]?.[sortKeysPath[1]] : pc[sortKeysPath[0]];
				} finally {
					if (companyValueToSortBy === undefined || companyValueToSortBy === null) {
						companyValueToSortBy = '';
					}
				}

				return companyValueToSortBy;
			},
			isAscending ? 'asc' : 'desc'
		);
		return sortedPortfolio;
	};

	onRowClick = (company: PortfolioCompany) => {
		const { portfolioTableExpandedCompanies, removeExpandedCompany } = this.props[
			STORE_UI
		] as UIStore;
		const expanded = _.includes(portfolioTableExpandedCompanies, company.id);
		if (expanded) {
			removeExpandedCompany(company.id);
		} else {
			this.expandRow(company);
			setTimeout(() => {
				const element = document.getElementById(`SCROLL_ROW_${company.id}`);
				element && element.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
			});
		}
	};

	expandRow = (company: PortfolioCompany) => {
		const { addExpandedCompany } = this.props[STORE_UI] as UIStore;
		addExpandedCompany(company?.id);
	};

	companyWasAdded = (oldCounter: number, newCounter: number): boolean => oldCounter !== newCounter;

	isFocusCompany = (company: PortfolioCompany): boolean => {
		return this.isScrollingToRow && company['factsetId'] === this.addedCompanyId;
	};

	onScrolledToRow = (company: any | undefined, shouldExpand?: boolean | undefined) => {
		this.addedCompanyId = -1;
		this.isScrollingToRow = false;
		this.props.onScrolledToRow();
		if (shouldExpand) {
			this.expandRow(company);
		}
	};

	tableRows = (
		portfolio: PortfolioCompany[],
		portfolioTableExpandedCompanies: (number | string)[]
	) => {
		const companiesAsRows = _.map(portfolio, (company: PortfolioCompany) => (
			<WatchlistTableRow
				key={`COMPANY_ROW_PORTFOLIO_TABLE_${company.id}`}
				company={company}
				expanded={_.includes(portfolioTableExpandedCompanies, company.id)}
				onDelete={this.deleteCompanyFromPortfolio}
				onNavigateToTicker={this.props.onNavigateToTicker}
				onClick={this.onRowClick}
				isFocus={this.isFocusCompany(company)}
				scrollContainer={this.scrollContainer}
				onMountCB={() => this.onScrolledToRow(company, false)}
				categories={this.props.categories}
				subscriptionId={this.props.subscriptionId}
			/>
		));

		return companiesAsRows;
	};

	render() {
		const sortedPortfolio = this.sortTableByColumn(
			this.props.companies,
			this.state.sortedCol,
			this.state.isAscending
		);
		const { portfolioTableExpandedCompanies } = this.props[STORE_UI] as UIStore;

		return (
			<CommonTable
				tableId={ids.WACTHLIST_PAGE.PORTFOLIO_TABLE}
				columns={PORTFOLIO_TRANSCRIPT_TABLE_COLUMNS}
				nameOfSortedColumn={this.state.sortedCol.name}
				isSortedAscending={this.state.isAscending}
				sortFunction={this.onClickHeader}
				isSortable
			>
				{!this.props.isSuccessfulLoad && (
					<TableStyles.EmptyBody>
						<ContentEmpty>
							<EmptyIcon src={Images.failedLoadPortfolio} />
							<EmptyBodyTitle fontSize={24} marginTop={34} fontWeight={600} lineHeight={1.08}>
								Could not load watchlist
							</EmptyBodyTitle>
							<EmptyBodyTitle fontSize={16} marginTop={30} fontWeight={0} lineHeight={1.38}>
								Try refreshing the page, If the error persists you can contact support via the chat
								windows in the bottom right corner of the screen.
							</EmptyBodyTitle>
						</ContentEmpty>
					</TableStyles.EmptyBody>
				)}
				{this.props.isSuccessfulLoad && (
					<>
						<TableStyles.TableBody
							ref={node => (this.scrollContainer = node)}
							id={ids.WACTHLIST_PAGE.PORTFOLIO_SCROLLABLE}
						>
							{this.tableRows(sortedPortfolio, portfolioTableExpandedCompanies)}
						</TableStyles.TableBody>
						<Tooltip
							id={ids.TOOLTIP.AMENITY_SCORE_TOOL_TIP}
							place='top'
							effect='solid'
							className='tooltip-theme'
							delayHide={200}
						/>
					</>
				)}
			</CommonTable>
		);
	}
}
