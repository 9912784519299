import * as React from 'react';
import styled from 'styled-components';
import { Button } from '@amenityllc/amenity-components';

import Images from '../../shared/images';
import * as ids from '../../../id.constants.js';
import { Link, Underline } from '../../../components/Link/Link';

const Content = styled.div`
	height: 100%;
	padding-top: 30px;
	box-sizing: border-box;
`;

const SuccessIcon = styled.div`
	height: 80px;
	width: 100px;
	margin: auto;
	background-image: url("${Images.uploadSuccessIcon}");
	background-repeat: no-repeat;
	background-position: center;
`;

const SuccessTitle = styled.div`
	margin-top: 20px;
	text-align: center;
	color: #4caf50;
	font-size: 24px;
	font-family: Assistant SemiBold;
`;

const Footer = styled.div`
	display: flex;
	justify-content: space-between;
	position: absolute;
	bottom: 35px;
	width: 650px;
	padding-left: 20px;
	box-sizing: border-box;
`;

const StyledButton = styled(Button)`
	height: 45px;
	margin-right: 10px;
`;

const LinkContent = styled.div`
	display: inline-flex;
`;

const AddIcon = styled.div`
	width: 20px;
	margin-right: 6px;
	background-image: url("${Images.AddIcon}");
	background-repeat: no-repeat;
	background-position: center;
`;

const Centered = styled.div`
	text-align: center;
`;

interface MessageTextProps {
	marginTop: number;
}

const MessageText = styled.div<MessageTextProps>`
	display: inline-block;
	text-align: unset;
	margin-top: ${props => `${props.marginTop}px`};
	color: #4a4a4a;
	font-size: 16px;
	font-family: Assistant Bold;
`;

interface StatusIconProps {
	image: string;
}

const StatusIcon = styled.div<StatusIconProps>`
	height: 21px;
	width: 13px;
	float: left;
	margin-right: 6px;
	background-image: url("${props => props.image}");
	background-repeat: no-repeat;
	background-position: center;
`;

const RegularText = styled.span`
	color: #4a4a4a;
	font-size: 16px;
	font-family: Assistant;
`;

interface UploadSuccessMessageProps {
	tryAgain: any;
	reload: Function;
	close: any;
	successMessage: any;
	downloadReport: any;
}

export default class UploadSuccessMessage extends React.Component<UploadSuccessMessageProps, {}> {
	importAnother = () => {
		this.props.reload();
		this.props.tryAgain();
	};

	render() {
		const { successMessage, close } = this.props;
		return (
			<Content>
				<SuccessIcon />
				<SuccessTitle>Your watchlist is ready!</SuccessTitle>
				<Centered>
					<MessageText marginTop={40} id={ids.BLOOMBERG_MODAL.NUM_SUCCESSFUL_IMPORTED_TICKERS}>
						<StatusIcon image={Images.SuccessIcon} />
						{successMessage.succeeded} Tickers imported successfully.
					</MessageText>
				</Centered>

				{!!successMessage.failed && (
					<Centered>
						<MessageText marginTop={10} id={ids.BLOOMBERG_MODAL.NUM_UNSUCCESSFUL_IMPORTED_TICKERS}>
							<StatusIcon image={Images.ErrorIcon} />
							{successMessage.failed} Rows could not be imported
							{this.props.downloadReport && (
								<>
									<RegularText>, see why: </RegularText>
									<Link underline={Underline.hover} onClick={this.props.downloadReport}>
										download a report
									</Link>
								</>
							)}
						</MessageText>
					</Centered>
				)}
				<Footer>
					<div>
						{this.props.tryAgain && (
							<Link
								id={ids.BLOOMBERG_MODAL.IMPORT_ANOTHER_FILE}
								underline={Underline.hover}
								onClick={this.importAnother}
							>
								<LinkContent>
									<AddIcon />
									Import another file
								</LinkContent>
							</Link>
						)}
					</div>
					<StyledButton onClick={close} id={ids.BLOOMBERG_MODAL.SHOW_MY_PORTFOLIO_BTN}>
						Show my watchlist
					</StyledButton>
				</Footer>
			</Content>
		);
	}
}
