import logo from './images/logo_amenity.png';
import logoImg from './images/logo_amenity_no_text.png';
import logoWhite from './images/logo_white.png';
import logoWhiteLarge from './images/logo_white_large.png';
import facebook from './images/facebook.png';
import google from './images/google.png';
import arrowDown from './images/arrow_down.svg';
import addTicker from './images/add_ticker.png';
import envelope from './images/envelope.png';
import close from './images/close.png';
import closeWhite from './images/close_white.svg';
import deleteTranscript from './images/delete_ticker.png';
import deleteIcon from './images/delete_trash_icon.png';
import editIcon from './images/edit_icon.svg';
import spinner from './images/spinner.gif';
import loginBackground from './images/login_background.jpg';
import arrowRightGrey from './images/arrow_right_grey.svg';
import arrowRightBlue from './images/arrow_right_blue.svg';
import arrowTableDownBlack from './images/arrow_table_down_black.png';
import filterArrowBlack from './images/filter_arrow_black.png';
import arrowDownGrey from './images/arrow_down_grey.svg';
import arrowDownBlue from './images/arrow_down_blue.svg';
import arrowDownBlackBorder from './images/arrow_down_black.svg';
import arrowLeftGrey from './images/left_arrow.svg';
import backButton from './images/back_btn.svg';
import headerBackground from './images/header_background.png';
import transcriptEditIconMarker from './images/annotation_icon.svg';
import transcriptEditIconMarkerOn from './images/annotation_icon_selected.svg';
import blackAnnotation from './images/annotation_pencil.svg';
import annotationPopupEditIcon from './images/annotation_icon_white.svg';
import annotationCloseIcon from './images/annotation_popup_close_icon_white.svg';
import positiveIcon from './images/positive_icon.svg';
import negativeIcon from './images/negative_icon.svg';
import neutralIcon from './images/neutral_icon.svg';
import arrowDownBlack from './images/arrow_down_sort_table.svg';
import amenityRateSortIcon from './images/rate-portfolio-sort-icon.png';
import blackSortV from './images/black_sort_v.png';
import sortIcon from './images/sort_icon.png';
import arrowDownDropdown from './images/arrow_down_dropdown.svg';
import arrowDriversRed from './images/arrow_drivers_red.svg';
import arrowDriversGreen from './images/arrow_drivers_green.svg';
import graphEmptyState from './images/graph_empty_state.svg';
import speakerAvatarSidebar from './images/speaker_avatar_sidebar.png';
import speakerAvatarText from './images/speaker_avatar_text.png';
import eyeGray from './images/eye_gray.svg';
import eyeBlue from './images/eye_blue.svg';
import printerWhite from './images/printer_icon.svg';
import tooltipDelete from './images/tooltip_delete.svg';
import tooltipEdit from './images/tooltip_edit.svg';
import arrowPrintNegative from './images/n_arrow_print.svg';
import arrowPrintPositive from './images/p_arrow_print.svg';
import checkIconAddCompanyBanner from './images/check_icon_add_company_banner.png';
import searchArrowDown from './images/search_arrow_down.svg';
import searchEmptyState from './images/search_empty_state.svg';
import searchNotFound from './images/search_not_found.svg';
import print from './images/print_black.svg';
import portfolioTabNotActive from './images/portfolio_not_active.svg';
import portfolioTabActive from './images/portfolio_active.svg';
import queryInsightsTabNotActive from './images/query_insights_not_active.svg';
import queryInsightsTabActive from './images/query_insights_active.svg';
import radioButtonOn from './images/radio_on.svg';
import radioButtonOff from './images/radio_off.svg';
import blueCheckMark from './images/feedback_blue_check.svg';
import checkBox from './images/checkbox_icon.svg';
import checkBoxEmpty from './images/checkbox_icon_empty.svg';
import searchIconBlack from './images/search_icon.svg';
import searchIconWhite from './images/search_white.svg';
import searchIconBlue from './images/search_blue.svg';
import searchIconBlue2 from './images/search_blue2.svg';
import searchIconBlue3 from './images/search_blue3.svg';
import searchIconNavi from './images/search_navi.svg';
import clearTextInputBtn from './images/close_filter_pill.svg';
import carouselNavButton from './images/carousel_arrow.png';
import arrowTrendingSearchesToggle from './images/arrow_trending_searches_toggle.png';
import notFound404 from './images/404.png';
import blackCheckMark from './images/black_v.png';
import copyLink from './images/copy_link_icon.png';
import tenKEmptyState from './images/tenK_empty_state.svg';
import chrome from './images/chrome-logo.svg';
import closeBtnBlack from './images/exit.svg';
import exitButtonWhite from './images/close_white.svg';
import greenDot from './images/green_dot.svg';
import redDot from './images/red_dot.svg';
import likeActive from './images/like_active.svg';
import like from './images/like.svg';
import dislikeActive from './images/dislike_active.svg';
import dislike from './images/dislike.svg';
import openDocument from './images/open_document.svg';
import outlineOpenNew from './images/outline-open-in-new.png';
import hamburgerMenu from './images/hamburger_icon.svg';
import analyticsBlack from './images/analytics_black.svg';
import analyticsBlue from './images/analytics_blue.svg';
import queryBlack from './images/query_black.svg';
import queryBlue from './images/query_blue.svg';
import viewerBlack from './images/viewer_black.svg';
import viewerBlue from './images/viewer_blue.svg';
import arrowFillRightBlack from './images/arrow_full_right_black.svg';
import logoOpen from './images/logo_open.svg';
import logoClose from './images/logo_close.svg';
import viewDocument from './images/view_document.svg';
import backIcon from './images/back.svg';
import analyticsOverlay from './images/analytics_overlay.png';
import arrowUpGreen from './images/arrow_up_green.svg';
import arrowUpRed from './images/arrow_up_red.svg';
import arrowDownGreen from './images/arrow_down_green.svg';
import arrowDownRed from './images/arrow_down_red.svg';
import helpIcon from './images/help_icon.svg';
import profileIcon from './images/profile_icon.png';
import helpBlueIcon from './images/help_icon_blue.png';
import eventsSpinner from './images/events_spinner.svg';
import uploadIconGrey from './images/icon-upload-grey.png';
import failedLoadPortfolio from './images/ico-could-not-load-portfolio.svg';
import uploadSuccessIcon from './images/icon-success.png';
import uploadErrorIcon from './images/icon-error.png';
import AddIcon from './images/icon-bull-add-copy.png';
import SuccessIcon from './images/icon-bull-success.png';
import ErrorIcon from './images/icon-bull-error.png';
import playgroundIcon from './images/settings_icon.svg';
import logoutIcon from './images/logout_icon.svg';
import adminIcon from './images/admin_icon.svg';
import onBoardingLineLeft from './images/line-left.png';
import onBoardingLineRight from './images/line-right.png';
import onBoardingLineRightWide from './images/line-right-wide.png';
import onBoardingIconUpload from './images/icon-upload.png';
import onBoardingSearchTickers from './images/icon-search-tickers.png';
import logoBloomberg from './images/logo-bloomberg.svg';
import logoBg from './images/logo-bg.svg';
import signupInfographics from './images/signup_infographics.png';
import check from './images/check.png';
import warningIcon from './images/ico-warning.png';
import infoIcon from './images/ico-info.svg';
import devIcon from './images/dev_icon.png';
import bookmarkIcon from './images/bookmark_icon.png';
import saveFiltersIcon from './images/save_filters_icon.png';
import arrowDown2 from './images/arrow-down.svg';
import pencilIcon from './images/edit_pencil_icon.jpg';
import checkIcon from './images/check-icon.svg';
import pencilEditIcon from './images/pencil-edit-icon.svg';
import renamePortfolio from './images/rename-portfolio.png';
import erasePortfolio from './images/erase-portfolio.png';
import circledPlusIcon from './images/plus-icon.png';
import importIcon from './images/import-button.png';
import addCompanyIcon from './images/add_company_banner.svg';
import circleCheckIcon from './images/check-circle.svg';
import asterisk from './images/asterisk.svg';
import emptyPortfolio from './images/empty_icon.png';
import errorIcon from './images/error_icon.svg';
import plusIcon from './images/plus_icon.png';
import signupBackground from './images/insights.jpg';
import sideBarDocument from '../../assets/icons/document_sidebar.svg';
import sideBarDocumentBlue from '../../assets/icons/document_sidebar_blue.svg';
import sideBarQueryInsights from '../../assets/icons/query_insights_sidebar.svg';
import sideBarQueryInsightsBlue from '../../assets/icons/query_insights_sidebar_blue.svg';
import sideBarDashboard from '../../assets/icons/dashboard_sidebar.svg';
import sideBarDashboardBlue from '../../assets/icons/dashboard_sidebar_blue.svg';
import settings from '../../assets/icons/settings.svg';
import settingsBlue from '../../assets/icons/settings_blue.svg';
import emailHappy from '../../assets/icons/email_happy.svg';
import emailSad from '../../assets/icons/email_sad.svg';

const Images = {
	logo,
	logoImg,
	logoWhite,
	logoWhiteLarge,
	facebook,
	google,
	arrowDown,
	addTicker,
	envelope,
	close,
	closeWhite,
	deleteTranscript,
	deleteIcon,
	editIcon,
	spinner,
	loginBackground,
	arrowRightGrey,
	arrowRightBlue,
	arrowTableDownBlack,
	filterArrowBlack,
	amenityRateSortIcon,
	arrowDownGrey,
	arrowDownBlue,
	arrowDownBlackBorder,
	arrowLeftGrey,
	backButton,
	headerBackground,
	transcriptEditIconMarker,
	transcriptEditIconMarkerOn,
	blackAnnotation,
	annotationPopupEditIcon,
	annotationCloseIcon,
	positiveIcon,
	negativeIcon,
	neutralIcon,
	arrowDownBlack,
	blackSortV,
	sortIcon,
	arrowDownDropdown,
	arrowDriversRed,
	arrowDriversGreen,
	graphEmptyState,
	speakerAvatarSidebar,
	speakerAvatarText,
	eyeGray,
	eyeBlue,
	printerWhite,
	tooltipDelete,
	tooltipEdit,
	arrowPrintNegative,
	arrowPrintPositive,
	checkIconAddCompanyBanner,
	searchArrowDown,
	searchEmptyState,
	searchNotFound,
	print,
	portfolioTabNotActive,
	portfolioTabActive,
	queryInsightsTabNotActive,
	queryInsightsTabActive,
	radioButtonOn,
	radioButtonOff,
	blueCheckMark,
	checkBox,
	checkBoxEmpty,
	searchIconBlack,
	searchIconWhite,
	searchIconBlue,
	searchIconBlue2,
	searchIconBlue3,
	searchIconNavi,
	clearTextInputBtn,
	carouselNavButton,
	arrowTrendingSearchesToggle,
	notFound404,
	blackCheckMark,
	copyLink,
	tenKEmptyState,
	chrome,
	closeBtnBlack,
	exitButtonWhite,
	greenDot,
	redDot,
	likeActive,
	like,
	dislikeActive,
	dislike,
	openDocument,
	outlineOpenNew,
	hamburgerMenu,
	analyticsBlack,
	analyticsBlue,
	queryBlack,
	queryBlue,
	viewerBlack,
	viewerBlue,
	arrowFillRightBlack,
	logoOpen,
	logoClose,
	viewDocument,
	backIcon,
	analyticsOverlay,
	arrowUpGreen,
	arrowUpRed,
	arrowDownGreen,
	arrowDownRed,
	helpIcon,
	profileIcon,
	helpBlueIcon,
	uploadIconGrey,
	failedLoadPortfolio,
	uploadSuccessIcon,
	uploadErrorIcon,
	AddIcon,
	SuccessIcon,
	ErrorIcon,
	eventsSpinner,
	playgroundIcon,
	logoutIcon,
	adminIcon,
	onBoardingLineLeft,
	onBoardingLineRight,
	onBoardingLineRightWide,
	onBoardingIconUpload,
	onBoardingSearchTickers,
	logoBloomberg,
	logoBg,
	signupInfographics,
	check,
	warningIcon,
	infoIcon,
	devIcon,
	bookmarkIcon,
	arrowDown2,
	pencilIcon,
	saveFiltersIcon,
	checkIcon,
	pencilEditIcon,
	renamePortfolio,
	erasePortfolio,
	circledPlusIcon,
	importIcon,
	addCompanyIcon,
	circleCheckIcon,
	asterisk,
	emptyPortfolio,
	errorIcon,
	plusIcon,
	signupBackground,
	sideBarDocument,
	sideBarDocumentBlue,
	sideBarQueryInsights,
	sideBarQueryInsightsBlue,
	sideBarDashboard,
	sideBarDashboardBlue,
	settingsBlue,
	settings,
	emailHappy,
	emailSad
};

export default Images;
