import * as React from 'react';
import * as _ from 'lodash';
import { Element } from 'react-scroll';
import { inject, observer } from 'mobx-react';
import {
	EVENT_NAVIGATION_PREFIX_TRANSCRIPT,
	STORE_GLOBAL,
	STORE_DATA,
	STORE_TRANSCRIPT_LOG,
	EVENT_TOOLTIP_ID,
	DELIMETER_OFFSET
} from '../../constants';
import { Event, Paragraph, EventElement } from '../../types/types';
import { stringToArraySplit } from './transcript.util';
import { polarityColor } from '../../../components/shared/util';
import GlobalStore from '../../stores/global.store';
import DataStore from '../../stores/data.store';
import TranscriptLogStore from '../../stores/transcriptLog.store';
import * as ids from '../../../id.constants.js';
import { Text } from '../../shared/styled/text-layout-components';

interface ParagraphProps {
	events: Event[];
	lostEvents: Event[];
	paragraph: Paragraph;
	delimiterOffset: number;
	sectionOffset: number;
	printMode: boolean;
}

@inject(STORE_DATA, STORE_GLOBAL, STORE_TRANSCRIPT_LOG)
@observer
export default class ParagraphElement extends React.Component<ParagraphProps, {}> {
	eventElements: EventElement[] = [];

	componentDidMount() {
		if (this.eventElements && this.eventElements.length > 0) {
			(this.props[STORE_GLOBAL] as GlobalStore).setEventElements([...this.eventElements]);
		}
	}

	componentDidUpdate() {
		if (this.eventElements && this.eventElements.length > 0) {
			(this.props[STORE_GLOBAL] as GlobalStore).setEventElements([...this.eventElements]);
		}
	}

	setCurrentEvent = (currentEventHover: Event) => {
		if (!currentEventHover) {
			return;
		}

		// shallow clone
		const currentTooltipEvent: Event = { ...currentEventHover };
		currentTooltipEvent.startIndex -= currentTooltipEvent.isUserDefined ? 0 : DELIMETER_OFFSET;
		currentTooltipEvent.endIndex -= currentTooltipEvent.isUserDefined ? 0 : DELIMETER_OFFSET;

		(this.props[STORE_DATA] as DataStore).setLastHoveredEvent(currentTooltipEvent);
	};

	addElementToTheList = (element, event) => {
		if (this.props.printMode && element !== null && !_.some(this.eventElements, { id: event.id })) {
			this.eventElements.push({
				element,
				id: event.id
			});
		}
	};

	getHighlightedParagraph = (
		paragraph: Paragraph,
		events: Event[],
		lostEvents: Event[],
		delimiterOffset: number,
		logErrors: boolean
	) => {
		const { sectionOffset } = this.props;
		let currentWordIndex = paragraph.offsetInText + sectionOffset;
		if (!events || (events.length === 0 && lostEvents.length === 0)) {
			return this.getParagraphWithNoEvents(paragraph.text, currentWordIndex);
		}
		const filteredLostEvents = _.filter(lostEvents, (e: Event) =>
			paragraph.text.includes(e.sentence)
		);
		const concatEvents = _.concat(filteredLostEvents, events);
		const orderedConcatEvents = _.orderBy(concatEvents, (e: Event) => e.startIndex, 'asc');
		const result = stringToArraySplit(
			paragraph.text,
			orderedConcatEvents,
			paragraph.offsetInText + sectionOffset,
			delimiterOffset,
			logErrors
		);
		const splittedText = result.res;
		const resLostEvents = result.lostEvents;
		if (resLostEvents.length > 0) {
			(this.props[STORE_GLOBAL] as GlobalStore).addEventsToNonFound(resLostEvents, paragraph);
		}
		if (splittedText.length === 0) {
			return this.getParagraphWithNoEvents(paragraph.text, currentWordIndex);
		}
		(this.props[STORE_TRANSCRIPT_LOG] as TranscriptLogStore).addEventsToGlobalCounter(
			_.chain(splittedText)
				.filter({ isEvent: true })
				.map((t: any) => _.find(events, { id: t.eventId }))
				.value() as Event[]
		);

		return _.map(splittedText, (text, i: number) => {
			const currentEvent: Event = _.find(orderedConcatEvents, (e: Event) => e.id === text.eventId);
			const title = this.getEventTooltipDisplay(currentEvent);
			const element = currentEvent ? (
				<Text
					key={`Paragraph_Text_${i}`}
					color={polarityColor(text.pol)}
					transparentColor={polarityColor(text.pol, { transparent: true })}
					isEvent={text.isEvent}
					printMode={this.props.printMode}
					polarity={text.pol}
					eventIndex={text.indexInTranscript}
					ref={e => this.addElementToTheList(e, currentEvent)}
					onMouseOver={() => this.setCurrentEvent(currentEvent)}
					data-tip={title}
					data-for={EVENT_TOOLTIP_ID}
					data-event='mouseenter'
					data-event-off='mouseleave'
					id={'TEXT_EVENT_NAVIGATION_PREFIX_TRANSCRIPT_' + currentEvent.id}
				>
					<Element
						id={EVENT_NAVIGATION_PREFIX_TRANSCRIPT + currentEvent.id}
						name={currentEvent.startIndex.toString()}
						style={{ display: 'inline-block' }}
					/>
					{this.wrapWordsInSpan(text.text, currentWordIndex)}
				</Text>
			) : (
				<Text
					id={`PARAGRAPH_TEXT_${i}`}
					key={`Paragraph_Text_${i}`}
					color={text.isEvent ? polarityColor(text.pol) : undefined}
					transparentColor={
						text.isEvent ? polarityColor(text.pol, { transparent: true }) : undefined
					}
					isEvent={text.isEvent}
					printMode={this.props.printMode}
					polarity={text.pol}
					eventIndex={text.indexInTranscript}
				>
					{this.wrapWordsInSpan(text.text, currentWordIndex)}
				</Text>
			);

			currentWordIndex += text.text.length;
			return element;
		});
	};

	getEventTooltipDisplay = (event: Event): string => {
		if (!event) {
			return '';
		}
		if (!!event.keyDrivers) {
			return _.chain(event.keyDrivers)
				.map(key => `${key} - ${event.typeName || 'Annotation'}`)
				.join('\n')
				.value();
		}
		return `${event.categoryName} - ${event.typeName || 'Annotation'}`;
	};

	getParagraphWithNoEvents = (text: string, currentWordIndex: number) => (
		<Text>{this.wrapWordsInSpan(text, currentWordIndex)}</Text>
	);

	wrapWordsInSpan = (text: string, sectionStartIndex: number): any[] => {
		// eslint-disable-next-line
		const words = text.split(/([^,|\.|;|:|-|\s]+)/);
		let start = sectionStartIndex;
		return _.map(words, (w, i: number) => {
			const element = (
				<span
					id={ids.ANNOTATION_MODAL.WORD_IN_PARAGRAGH + i}
					key={`Word_In_Paragraph_${i}`}
					data-origin_index_start_end={JSON.stringify([start, start + w.length - 1])}
				>
					{w}
				</span>
			);
			start += w.length;
			return element;
		});
	};

	render() {
		const { logErrors } = this.props[STORE_TRANSCRIPT_LOG] as TranscriptLogStore;
		const { paragraph, events, delimiterOffset, lostEvents } = this.props;

		return (
			<div>
				{this.getHighlightedParagraph(paragraph, events, lostEvents, delimiterOffset, logErrors)}
			</div>
		);
	}
}
