import { Colors } from './styles';
import { Polarity } from './types';

export const polarityColor = (
	polarity: Polarity | string,
	options: { transparent?: boolean; isSubsection?: boolean } = {}
) => {
	const { transparent, isSubsection } = options;
	if (isSubsection) {
		return Colors.noPolarity;
	}
	const actualPolarity = getEventPolarity(polarity);
	switch (actualPolarity) {
		case Polarity.negative:
			return transparent ? Colors.transparentNegative : Colors.negative;
		case Polarity.positive:
			return transparent ? Colors.transparentPositive : Colors.positive;
		case Polarity.neutral:
			return transparent ? Colors.lightBlue : Colors.blue;
		default:
			return;
	}
};

export const strToPolarity = (polarityString: string): Polarity => {
	const map = {
		pos: Polarity.positive,
		POSITIVE: Polarity.positive,
		neg: Polarity.negative,
		NEGATIVE: Polarity.negative,
		neutral: Polarity.neutral,
		NEUTRAL: Polarity.neutral
	};
	return map[polarityString];
};

export const getEventPolarity = (polarity: any): Polarity =>
	typeof polarity === 'string' ? strToPolarity(polarity) : polarity;

export const decodeHTMLEntities = (str: string): string => {
	const indexOfFirstNonWhitespaceChar = str.search(/\S|$/);
	const leadingSpaces = ' '.repeat(indexOfFirstNonWhitespaceChar);
	const parser = new DOMParser();
	const resultDocument = parser.parseFromString(str, 'text/html');
	return leadingSpaces + resultDocument.body.textContent;
};
