export const STORE_GLOBAL = 'global';
export const STORE_ROUTER = 'router';
export const STORE_AUTH = 'auth';
export const STORE_DATA = 'data';
export const STORE_BACK_OFFICE = 'backoffice';
export const STORE_TRANSCRIPT_LOG = 'transcriptLog';
export const STORE_ANALYTICS = 'analytics';
export const STORE_FEATURES = 'features';
export const STORE_UI = 'ui';
export const STORE_CONFIG = 'config';

export const PRODUCT_NAME = 'Viewer';

export const LOCAL_SOTARGE_PREFIX = 'AmenityAnalytics_TranscriptsViewer_';
export const LOCAL_STORAGE_KEYS = {
	TOKEN: 'token',
	USERNAME: 'user',
	INTERCOM_USER_HASH: 'intercom_user_hash',
	READMEIO_TOEKN: 'readmeio_token',
	USER_FULL_NAME: 'user_full_name',
	USER_CREATED_AT: 'user_created_at',
	SUB: 'sub',
	ROLE: 'role',
	CURRENT_FLOW: 'current_flow',
	CURRENT_DOCUMENT_TYPE: 'current_document_type',
	DOCUMENT_TYPE_FLOW_DATA: 'document_type_flow_data',
	CURRENT_API_ENV: 'current_api_env',
	PRINT_DATA: 'print_data',
	EVENTS_SEARCH_DATA: 'events_search_data',
	DOCUMENT_META_DATA: 'document_meta_data',
	EVENTS_SEARCH_RESULTS: 'events_search_results',
	GOOGLE_SIGNUP_ORIGIN: 'google_signup_origin',
	DONT_SHOW_PRINT_NOTICE: 'dont_show_print_notice',
	SELECTED_PRINT_LAYOUT: 'selected_print_layout',
	DIRECT_LINK: 'direct_link',
	IS_REFRESH: 'is_refresh',
	PREV_VISITED_PAGE: 'prev_visited_page',
	CURRENT_VISITED_PAGE: 'current_visited_page',
	PORTFOLIO_SORT_BY: 'portfolio_sort_by',
	IS_PRINT: 'is_print',
	LATEST_PORTFOLIO: 'latest_opened_portfolio',
	SF_ACCOUNT_ID: 'salesforce_account_id'
};

// eslint-disable-next-line
export const EMAIL_VALIDATION_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ADD_COMPANY_DROPDOWN_PLACEHOLDER =
	'Enter Company name or ticker (Format: Disney, DIS, or $DIS)';

export const EVENT_NAVIGATION_PREFIX_TRANSCRIPT = 'TRANSCRIPT_EVENT_ID_';
export const EVENT_NAVIGATION_PREFIX_TEN_K = 'TEN_K_EVENT_ID_';
export const GENERAL_CATEGORY_NAME = 'Other';
export const GENERAL_KEY_DRIVER_NAME = 'General';

export const isProduction = () => process && process.env.REACT_APP_CLIENT_ENV === 'prod';

export const USER_TRACKING = {
	USER_TRACKING_CATEGORIES: {
		USER: 'User Management',
		WACTHLIST: 'Portfolio',
		TRANSCRIPT: 'Transcript',
		QUERY_INSIGHTS: 'Query Insights',
		TEN_K: 'Document 10K',
		EIGHT_K: 'Document 8K',
		TEN_Q: 'Document 10Q',
		SIX_K: 'Document 6K',
		TWENTY_F: 'Document 20F',
		FORTY_F: 'Document 40F',
		TOP_NAVIGATION: 'Top navigation',
		ANALYTICS: 'Analytics',
		TICKER_UPLOAD: 'Ticker Upload',
		PERFORMANCE: 'Performance',
		TEN_K_SUMMARY: '10K Summary',
		DOCUMENT_VIEW: 'Document view',
		PORTFOLIO_MANAGEMENT: 'Portfolio Management',
		NEWS: 'News',
		EMAIL_ALERTS: 'Email Alerts'
	},
	USER_TRACKING_ACTIONS: {
		LOGIN: 'Login',
		SIGNUP: 'Signup',
		LOGOUT: 'Logout',
		SET_PASSWORD: 'Set Password',
		COMPANY_ADDED: 'Company Added',
		COMPANY_REMOVED: 'Company Removed',
		COMPANY_EXPANDED: 'Company Expanded',
		TRANSCRIPT_OPENED: 'Transcript Opened',
		TRANSCRIPT_OPENED_DIRECT_LINK: 'Transcript Opened Direct Link',
		TRANSCRIPT_EVENT_NAVIGATION_CLICKED: 'Transcript Event Navigation Click',
		ANNOTATION_ADDED: 'Annotation Added',
		PRINT: 'Transcript Print',
		HIGHLIGHT_EDITED: 'Highlight Edited',
		HIGHLIGHT_DELETED: 'Highlight Deleted',
		ANNOTATION_EDITED: 'Annotation Edited',
		ANNOTATION_DELETED: 'Annotation Deleted',
		SEARCH_PERFORMED: 'Search Performed',
		LINK_COPIED: 'Link Copied',
		TEN_K_OPENED: '10K Opened',
		TEN_K_OPENED_DIRECT_LINK: '10K Opened Direct Link',
		TEN_K_EVENT_NAVIGATION_CLICKED: '10K Event Navigation Clicked',
		TEN_K_SECTION_CLICKED: '10K Section Click',
		QUERY_INSIGHTS_CLICKED: 'Query Insights clicked',
		VIEWER_CLICKED: 'Viewer clicked',
		ANALYTICS_CLICKED: 'Analytics clicked',
		ANALYTICS_DASHBOARD_CLICKED: 'Analytics Dashboard Clicked',
		ANALYTICS_CONTACT_US_CLICKED: 'Contact us clicked',
		USER_EXITED_BEFORE: 'User Exited - Before upload',
		USER_EXITED_AFTER: 'X clicked - After upload',
		UPLOAD_ERROR: 'Upload error',
		WATCHLIST_FILE_UPLOADED: 'Watchlist file loaded',
		GO_TO_PORTFOLIO_AFTER_IMPORT: 'Go to portfolio clicked',
		SUMMARY_PRINTED: 'Summary Printed',
		SEARCH_ON_DOCUMENT: 'Search on Document',
		PREVIOUS_DOCUMENT_CLICK: 'Previous Document click ',
		PREVIOUS_DOCUMENT_SELECTED: 'Previous Document selected',
		PREVIOUS_DOCUMENT_OPEN_IN_TAB: 'Previous Document open in view tab',
		SAVE_FILTERS: 'Save filters',
		LOAD_FILTERS: 'Load Filters',
		PORTFOLIO_LOADED: 'Portfolio Loaded',
		ADD_NEW_PORTFOLIO: 'Add New Portfolio',
		CREATE_EMPTY_WATCHLIST: 'Create Empty Watchlist',
		RENAME_PORTFOLIO_CLICKED: 'Rename Portfolio Clicked',
		DELETE_PORTFOLIO_CLICKED: 'Delete Portfolio Clicked',
		BACK_TO_WACTHLISTS_CLICK: 'Back to Portfolios Click',
		ARTICLE_OPENED: 'Article Opened',
		ARTICLE_PRINT: 'Article Print',
		ARTICLE_SEARCH: 'Article Search',
		EXPORT_DATA: 'Export Data',
		DTC_EMAIL_SUBSCRIBE: 'DTC subscription successful',
		DTC_HIGHLIGHTS_UNSUBSCRIBE: 'DTC Highlights Unsubscribe'
	},
	USER_TRACKING_LABELS: {
		SUCCESS: 'Success',
		FAIL: 'Fail'
	}
};

export const SCROLL_IDS = {
	SPEAKER: { NAME: 'SPEAKER_ELEMENT_', ID: 0 },
	QNA: { NAME: 'QNA_ELEMENT_', ID: 1 }
};

export const TRANSCRIPT_SECTIONS = {
	DISCUSSION_FACTSET: 'MANAGEMENT DISCUSSION SECTION',
	DISCUSSION_SNP: 'Presentation Section',
	QNA_FACTSET: 'Q&A',
	QNA_SNP: 'Question and Answer Section'
};

export const EVENT_TOOLTIP_ID = 'EVENT_TOOLTIP';
export const DELIMETER_OFFSET = 20;

export const IS_QUESTION = (val: string): boolean => {
	return val === 'q' || val === 'Question';
};

export const IS_ANSWER = (val: string): boolean => {
	return val === 'a' || val === 'Answer';
};

export const INVALID_VALUE = -999;

export const EVENT_CATEGORY_SORT = [
	{
		id: 'POSITIVES_SORT',
		title: 'Positives'
	},
	{
		id: 'NEGATIVES_SORT',
		title: 'Negatives'
	},
	{
		id: 'NET_SCORE_SORT',
		title: 'Net score'
	},
	{
		id: 'TOTAL_SORT',
		title: 'Total'
	}
];

export const APP_COLORS = {
	DISABLED_LIGHT_GRAY_EDEDED: '#ededed',
	LIGHT_GRAY_DDE5E7: '#DDE5E7',
	LIGHT_GREY_D1D1D1: '#d1d1d196',
	BLACK_32313B: '#32313B',
	LIGHT_WHITE_FBFBFB: '#FBFBFB',
	LIGHT_BLUE_F4F7F7: '#F4F7F7',
	LIGHT_GRAY_E0E7E9: '#E0E7E9',
	LIGHT_GRAY_SEARCHING_EEF1F5: '#EEF1F5',
	NEGATIVE_RED: '#d23f31',
	SUBSECTION: '#7FB7BE',
	LINK_BLUE: '#0074E8'
};

export enum DATE_RANGES {
	THREE_MONTHS,
	SIX_MONTHS,
	ONE_YEAR,
	TWO_YEARS,
	THREE_YEARS,
	FOUR_YEARS
}

export const PRINT_LAYOUTS = [
	{ label: 'letter', pageHeight: 1048, maxNumOfEventsOnPage: 14 },
	{ label: 'a4', pageHeight: 1153, maxNumOfEventsOnPage: 17 },
	{ label: 'other', pageHeight: undefined, maxNumOfEventsOnPage: undefined }
];

export enum SIDE_BAR_TABS {
	Participants,
	Sections,
	KeyDrivers,
	ManagementDiscussion,
	QnA
}

export enum TABS {
	WATCHLIST,
	ANALYTICS_LOOKER,
	EMAIL_SUBSCRIPTION,
	QUERY,
	ANALYTICS
}

export const APP_URLS = {
	ROOT: '/',
	WATCHLISTS: '/watchlists',
	QI: '/query_insights',
	ANALYTICS_LOOKER: '/analytics',
	DOCUMENT: '/document/',
	EXTERNAL_DOCUMENT: '/external_transcript',
	EVENTS_SUMMARY: '/events_summary',
	BACKOFFICE: '/backoffice',
	NOT_FOUND: '/not_found',
	LOGIN: '/login',
	LOGOUT: '/logout',
	SIGNUP: '/signup',
	PRINT_TRANSCRIPT: '/print_transcript',
	PRINT_TRANSCRIPT_NEWARC:
		'/subscription/:subscriptionId/transcript/document/:documentId/print_transcript',
	PRINT_SUMMARY: '/print_summary',
	MOBILE_NOT_SUPPORTED: '/mobile_not_supported',
	NOT_SUPPORTED: '/not_supported',
	WACTHLIST: '/portfolio/:id',
	WATCHLIST: '/watchlist/:id',
	NEWS: '/models/:modelId/news/document/:documentId',
	FLOW_TRANSCRIPT: '/flow/:flowId/transcript/document/:documentId',
	FLOW_TRANSCRIPT_WITH_EVENT_ID: '/flow/:flowId/transcript/document/:documentId/event/:eventId',
	FLOW_SEC_FILING: '/flow/:flowId/sec/document/:documentId',
	FLOW_SEC_FILING_WITH_EVENT_ID: '/flow/:flowId/sec/document/:documentId/event/:eventId',
	TRANSCRIPT: '/subscription/:subscriptionId/transcript/document/:documentId',
	TRANSCRIPT_WITH_EVENT_ID:
		'/subscription/:subscriptionId/transcript/document/:documentId/event/:eventId',
	SEC_FILING: '/subscription/:subscriptionId/sec/document/:documentId',
	SEC_FILING_WITH_EVENT_ID: '/subscription/:subscriptionId/sec/document/:documentId/event/:eventId',
	DASHBOARD: '/analytics/dashboard/:dashboardId',
	DASHBOARD_NO_PARAMS: '/analytics/dashboard',
	PLAYGROUND: '/playground',
	PRINT_NEWS: '/models/:modelId/news/:documentId/print',
	DTC_SUBSCRIBE: '/dtc/subscribe',
	EMAIL_SETTINGS: '/dtc/settings'
};

// Add any additional feature flags with prefix - viewer
export const FEATURES_TYPE_SPLIT = {
	VIEWER_PLAYGROUND: 'viewer_playground',
	VIEWER_DTC_EMAIL: 'viewer_dtc_email',
	VIEWER_NEWARC_MIGRATION: 'viewer_newarc_migration',
	VIEWER_DOCUMENT_FILTERS: 'viewer_document_filters',
	VIEWER_NEWARC_STICKY_FILTERS: 'viewer_looker_sticky_filters',
	VIEWER_SEC_FILINGS_V2: 'viewer_sec_filings_v2',
	VIEWER_NEWARC_LOAD_DASHBOARD_WITH_SDK: 'viewer_looker_sdk_filters',
	VIEWER_NEW_VERSION_DOC_BANNER: 'viewer_new_version_doc_banner'
};

export const SPLIT_AUTH_KEY = isProduction()
	? '8a8ijt4o12pa8jpmptlj2dqps4gmnb2v5935'
	: 'f80et1ocaon38r9ppf9jbrvd3a4s6t7gcnr';

export const EXCLUDED_KEY_DRIVERS = ['Financial'];
export const DECEPTION_KEY_DRIVER = 'Deception';

export const WEB_SERVER_URL = {
	PROD: 'https://insights-platform.amenityanalytics.com',
	STAGE: 'https://jstage-tv-api.amenityanalytics.com',
	DEV: 'https://dev-tv-api.amenityanalytics.com',
	LOCAL: 'http://localhost:5500',
	SEC_FILINGS_DOMAIN: 'https://outbound.amenityanalytics.com'
};

export const GRAPHQL_SERVER_URL = {
	PROD: 'https://viewergraphql.amenityanalytics.com/graphql',
	STAGE: 'https://viewergraphql.amenityanalytics.com/graphql/stage',
	DEV: 'https://viewergraphql.amenityanalytics.com/graphql',
	LOCAL: 'http://localhost:4000/graphql'
};

export const DEFAULT_UPLOAD_ERROR = {
	TITLE: 'Sorry, upload failed',
	DESCRIPTION: 'Please make sure you follow the steps in the instructions video'
};

export const GLOBAL_ERRORS = {
	TIMEOUT_ERROR: {
		TITLE: 'Timeout Error',
		DESCRIPTION: `Watchlist loading timed out due to the length of this watchlist.\nPlease contact support@amenityanalytics.com for further assistance.`,
		BUTTON: 'Ok'
	}
};

export const UPLOAD_LINK = {
	BLOOMBERG: 'https://www.youtube.com/embed/v4iFxt04ZCI',
	EXCEL: 'https://www.youtube.com/embed/U6dw3892_us'
};

export const TOOL_TIPS = {
	AMENITY_SCORE:
		'A weighted average of all positive and negative events in a document represented by a trend line ending in a score of the most recent document.',
	SCORE_CHANGE:
		'A numerical trend representing the change in the Amenity Score between the current and previous document.',
	KEY_DRIVER:
		'Highlighted commentary is grouped under categories called Key Drivers. Key Drivers are ranked by frequency. ',
	AMENITY_SCORE_TRANSCRIPT:
		'A weighted average of all positive and negative events in a document represented by a trend line ending in a score of the most recent document if there are documents of this type from the past year.',
	SCORE_CHANGE_TRANSCRIPT:
		'A numerical trend representing the change in the Amenity Score between the current and previous document if there are documents of this type from the past year.'
};
// eslint-disable-next-line
export const SEPARATOR_REGEX = /\s|,\s|\.\s|;\s|\:\s|\.|,|;|\:/g;
export const RECENT_DOCUMENTS: number = -1;

export const ATTRIBUTIONS = {
	'S&P': `Document provided by S&P Global Market Intelligence. Copyright © ${new Date().getFullYear()}. All rights reserved.`,
	FactSet: `Copyright © ${new Date().getFullYear()} FactSet Research Systems Inc. All rights reserved.`,
	'Edgar Online': `Donnelley Financial, LLC d/b/a EDGAR Online and the information providers herein make no claims concerning the validity of the information provided hereby and will not be held liable for any use of this information. The information provided herein may be displayed and printed for your internal use only and may not be reproduced, retransmitted, distributed, disseminated, sold, published, broadcast or circulated to anyone external to your company without the express written consent of Donnelley Financial d/b/a EDGAR Online.`
};

export const MAX_NUM_OF_DOCUMENTS = -4;

export const KEY_CODES = {
	ENTER: 13,
	ESCAPE: 27
};

export const ANALYTICS_FILTER_SECTIONS = {
	SAVED_FILTERS: 'saved_filters',
	WATCHLISTS: 'watchlists'
};

export const IFRAME_DOCUMENT_TYPE = {
	SEC_FILINGS: 'sec_filings',
	ARTICLE: 'article'
};

export const PORTFOLIO_NAME_ERRORS = {
	EMPTY: "Watchlist name can't be empty",
	DUPLICATE: 'A watchlist with this name already exists'
};

export const ARTICLE_MEDIA_TYPES = {
	PRINT: 'print'
};

export const DOCUMENT_PROVIDERS = {
	LEXIS_NEXIS: 'Lexis Nexis'
};

export const DOCUMENT_SIDEBAR_WIDTH = 380;
export const DOCUMENT_SIDEBAR_RIGHT_WIDTH = 270;
export const DOCUMENT_SIDEBAR_WIDTH_CLOSED = 42;
export const DOCUMENT_SIDEBAR_MARGIN = 9;
export const DOCUMENT_RIGHT_OFFSET = 18;

export const ARROW_CONTAINER_WIDTH = 60;
export const DELETE_CONTAINER_WIDTH = 60;
export const AMENITY_CHANGE_WIDTH = 130;

export const COMPANIES_CHUNK_SIZE = 50;
export const COMPANIES_FETCH_TIMEOUT_SEC = 60;

export const DATETIME_FORMAT = 'M/D/YYYY, h:mm A';
