import * as React from 'react';
import Images from '../../shared/images';
import Toolbar from '../../components/Toolbar/toolbar';
import NavigationService from '../../services/navigation.service';
import {
	BackButtonContainer,
	BackButtonImg,
	BackButtonTextContainer,
	SubHeader,
	ToolBarContainer
} from '../pageElements';
import RecentDocumentsDropDown from './recentDocumentsDropDown';

export const Header = ({
	currentCompanyDocuments,
	currentCompany,
	title,
	documentId,
	subscriptionId,
	printTranscript,
	disablePrint
}) => {
	return (
		<SubHeader isTwoLines={false}>
			<BackButtonContainer>
				<BackButtonImg
					id='DOCUMENT_PAGE_NAVIGATE_BACK'
					onClick={NavigationService.getInstance().goToRoot}
					src={Images.backIcon}
					size={15}
				/>
				<BackButtonTextContainer>
					<RecentDocumentsDropDown
						companyDocuments={currentCompanyDocuments}
						currentCompany={currentCompany}
						documentTitle={title}
						currentDocumentId={documentId}
						subscriptionId={subscriptionId}
						changeDocument={() => {}}
					/>
				</BackButtonTextContainer>
			</BackButtonContainer>
			<ToolBarContainer>
				<Toolbar
					searchText={() => {}}
					navigateSearch={() => {}}
					printLabel={'Print'}
					printTranscript={printTranscript}
					disablePrint={disablePrint}
					disableEdit={true}
					selectedMatch={0}
					totalMatches={0}
				/>
			</ToolBarContainer>
		</SubHeader>
	);
};
