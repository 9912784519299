import { action, observable, runInAction } from 'mobx';
import DataService from '../services/data.service';
import { Organization, Category, EventType, Event, Collection, Dashboard } from '../types/types';
import * as _ from 'lodash';

export default class BackOfficeStore {
	@observable organizations: Organization[] = [];
	@observable categories: Category[] = [];
	@observable collections: Collection[] = [];
	@observable event_types: EventType[] = [];
	@observable events: Event[] = [];
	@observable dashboards: Dashboard[] = [
		{
			id: 1,
			name: 'Dashboard',
			appId: 'app id',
			theme: '',
			watchlistsEnabled: true
		}
	];

	private static instance: BackOfficeStore;

	constructor() {
		if (BackOfficeStore.instance) {
			return BackOfficeStore.instance;
		}
		BackOfficeStore.instance = this;
	}

	@action
	getAllCategories = () => {
		const ds = DataService.getInstance();
		ds.getAllCategoriesBackoffice(
			{},
			(categories: Category[]) => {
				runInAction(() => {
					this.categories = categories;
				});
			},
			err => {
				console.error('err ? ', err);
			}
		);
	};

	@action
	deleteCategory = (id: number) => {
		const ds = DataService.getInstance();
		ds.deleteCategory(
			{ id: id },
			(res: number) => {
				runInAction(() => {
					this.categories = _.filter(this.categories, item => item.id !== id);
				});
			},
			err => {
				console.error('err ? ', err);
			}
		);
	};

	@action
	updateCategory = (category: Category) => {
		const newCategory: Category = {
			id: category.id,
			name: category.name,
			collection_id: category.collection_id,
			chart_description: category.chart_description,
			document_view_description: category.document_view_description
		};
		const ds = DataService.getInstance();
		ds.updateCategory(
			newCategory,
			(res: any) => {
				runInAction(() => {
					const editCategory = this.categories.find(item => item.id === category.id);
					editCategory.name = category.name;
					editCategory.collection_id = category.collection_id;
					editCategory.collection_name = category.collection_name;
					editCategory.chart_description = category.chart_description;
					editCategory.document_view_description = category.document_view_description;
				});
			},
			err => {
				console.error('err ? ', err);
			}
		);
	};

	@action
	addCategory = (category: Category) => {
		const newCategory = {
			name: category.name,
			collection_id: category.collection_id,
			chart_description: category.chart_description,
			document_view_description: category.document_view_description
		};
		const ds = DataService.getInstance();
		ds.addCategory(
			newCategory,
			(res: any) => {
				runInAction(() => {
					category.id = res.id;
					this.categories.push(category);
				});
			},
			err => {
				console.error('err ? ', err);
			}
		);
	};

	@action
	getAllCollections = () => {
		const ds = DataService.getInstance();
		ds.getAllCollections(
			{},
			(collections: Collection[]) => {
				runInAction(() => {
					this.collections = collections;
				});
			},
			err => {
				console.error('err ? ', err);
			}
		);
	};

	@action
	deleteCollection = (id: number) => {
		const ds = DataService.getInstance();
		ds.deleteCollection(
			{ id: id },
			(res: number) => {
				runInAction(() => {
					this.collections = _.filter(this.collections, item => item.id !== id);
				});
			},
			err => {
				console.error('err ? ', err);
			}
		);
	};

	@action
	updateCollection = (collection: Collection) => {
		const newCollection = {
			id: collection.id,
			name: collection.name
		};
		const ds = DataService.getInstance();
		ds.updateCollection(
			newCollection,
			(res: any) => {
				runInAction(() => {
					const editCollection = this.collections.find(item => item.id === collection.id);
					editCollection.name = collection.name;
				});
			},
			err => {
				console.error('err ? ', err);
			}
		);
	};

	@action
	addCollection = (collection: Collection) => {
		const newCollection = {
			name: collection.name
		};
		const ds = DataService.getInstance();
		ds.addCollection(
			newCollection,
			(res: any) => {
				runInAction(() => {
					collection.id = res.id;
					this.collections.push(collection);
				});
			},
			err => {
				console.error('err ? ', err);
			}
		);
	};

	@action
	getAllOrganizations = () => {
		const ds = DataService.getInstance();
		ds.getAllOrganizationsBackoffice(
			{},
			(organizations: Organization[]) => {
				runInAction(() => {
					this.organizations = organizations;
				});
			},
			err => {
				console.error('err ? ', err);
			}
		);
	};

	@action
	deleteOrganization = (id: number, domain: string) => {
		const ds = DataService.getInstance();
		ds.deleteOrganization(
			{ id: id, domain: domain },
			(res: number) => {
				runInAction(() => {
					this.organizations = _.filter(this.organizations, item => item.id !== id);
				});
			},
			err => {
				console.error('err ? ', err);
			}
		);
	};

	@action
	updateOrganization = (organization: Organization) => {
		const ds = DataService.getInstance();
		const params = {
			organization: organization
		};
		ds.updateOrganization(
			params,
			(res: any) => {
				runInAction(() => {
					const editOrganization = this.organizations.find(item => item.id === organization.id);
					editOrganization.domain = organization.domain;
					editOrganization.client_id = organization.client_id;
					editOrganization.token = organization.token;
					editOrganization.collection_name = organization.collection_name;
					editOrganization.features = organization.features;
				});
			},
			err => {
				console.error('err ? ', err);
			}
		);
	};

	@action
	getEventTypes = () => {
		const ds = DataService.getInstance();
		// only_legal - event types that have a category
		ds.getBackOfficeEventTypes(
			{ only_legal: false },
			(eventTypes: EventType[]) => {
				runInAction(() => {
					this.event_types = eventTypes;
				});
			},
			err => {
				console.error('err ? ', err);
			}
		);
	};

	@action
	addEvent = (event: any) => {
		const newEvent = {
			eventName: event.eventName,
			categoryId: event.categoryId
		};
		const ds = DataService.getInstance();
		ds.addBackOfficeEvent(
			newEvent,
			(res: any) => {
				runInAction(() => {
					event.id = res.id;
					this.event_types.push(event);
				});
			},
			err => {
				console.error('err ? ', err);
			}
		);
	};

	@action
	updateEvent = (event: any) => {
		const newEvent = {
			id: event.id,
			eventName: event.eventName,
			categoryId: event.categoryId
		};
		const ds = DataService.getInstance();
		ds.updateBackOfficeEvent(
			newEvent,
			(res: any) => {
				runInAction(() => {
					const editEvent = this.event_types.find(item => item.id === event.id);
					editEvent.eventName = event.eventName;
					editEvent.categoryName = event.categoryName;
					editEvent.collectionName = event.collectionName;
				});
			},
			err => {
				console.error('err ? ', err);
			}
		);
	};

	@action
	deleteEvent = (id: number, name: string) => {
		const ds = DataService.getInstance();
		ds.deleteBackOfficeEvent(
			{ id: id, name: name },
			(res: number) => {
				runInAction(() => {
					this.event_types = _.filter(this.event_types, item => item.id !== id);
				});
			},
			err => {
				console.error('err ? ', err);
			}
		);
	};

	@action
	addOrganization = (organization: Organization) => {
		const ds = DataService.getInstance();
		const params = {
			organization: organization
		};
		ds.addOrganization(
			params,
			(res: any) => {
				runInAction(() => {
					organization.id = res.id;
					this.organizations.push(organization);
				});
			},
			err => {
				console.error('err ? ', err);
			}
		);
	};

	@action
	getAllEvents = () => {
		const ds = DataService.getInstance();
		ds.getAllEvents(
			{},
			(events: Event[]) => {
				runInAction(() => {
					this.events = events;
				});
			},
			err => {
				console.error('err ? ', err);
			}
		);
	};

	@action
	updateEventStatus = (id: number, status: number) => {
		const saveStatus = {
			id: id,
			status: status
		};
		const ds = DataService.getInstance();
		ds.updateEventStatus(
			saveStatus,
			(res: any) => {
				runInAction(() => {
					const editEvent = this.events.find(item => item.id === id);
					editEvent.status = status;
				});
			},
			err => {
				console.error('err ? ', err);
			}
		);
	};

	@action
	setDashboards = (dashboards: Dashboard[]) => {
		this.dashboards = dashboards;
	};

	@action
	addDashboard = (dashboard: Dashboard) => {
		this.setDashboards([...this.dashboards, dashboard]);
		// TODO: send server request
		// TODO: remove ids before sending to the server
	};

	@action
	updateDashboard = (editedDashboard: Dashboard) => {
		const rest = _.filter(
			this.dashboards,
			(dashboard: Dashboard) => dashboard.id !== editedDashboard.id
		);
		this.setDashboards([...rest, editedDashboard]);
		// TODO: send server request
	};

	@action
	deleteDashboard = (id: number) => {
		const rest = _.filter(this.dashboards, (dashboard: Dashboard) => dashboard.id !== id);
		this.setDashboards([...rest]);
		// TODO: send server request
	};
}
