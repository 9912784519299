import * as React from 'react';
import styled from 'styled-components';

import Images from '../../shared/images';
import { DEFAULT_UPLOAD_ERROR } from '../../constants';
import { Colors } from '../../../components/shared/styles';

const Content = styled.div`
	height: 100%;
	padding-top: 50px;
	box-sizing: border-box;
`;

const ErrorIcon = styled.div`
	height: 80px;
	width: 100px;
	margin: auto;
	background-image: url("${Images.uploadErrorIcon}");
	background-repeat: no-repeat;
	background-position: center;
`;

const ErrorTitle = styled.div`
	width: 230px;
	margin: auto;
	margin-top: 25px;
	text-align: center;
	color: #d23f31;
	font-size: 24px;
	font-family: Assistant;
`;

const ErrorDiscription = styled.div`
	width: 400px;
	margin: auto;
	margin-top: 25px;
	text-align: center;
	color: #4a4a4a;
	font-size: 16px;
	font-family: Assistant;
`;

const HelpLink = styled.div`
	cursor: pointer;
	color: ${Colors.blue};
	font-size: 16px;
	&:hover {
		text-decoration: underline;
	}
`;

interface UploadErrorMessageProps {
	errorMessage: any;
	tryAgain: Function;
}

export default class UploadErrorMessage extends React.Component<UploadErrorMessageProps, {}> {
	render() {
		const { errorMessage, tryAgain } = this.props;
		return (
			<Content>
				<ErrorIcon />
				<ErrorTitle id='UPLOAD_FILE_ERROR_TITLE'>
					{errorMessage.title || DEFAULT_UPLOAD_ERROR.TITLE}
				</ErrorTitle>
				<ErrorDiscription>
					{errorMessage.description || DEFAULT_UPLOAD_ERROR.DESCRIPTION}
					<HelpLink onClick={() => tryAgain()} id='UPLOAD_FILE_LINK_TRY_AGAIN'>
						try again ›
					</HelpLink>
				</ErrorDiscription>
			</Content>
		);
	}
}
