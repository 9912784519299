import * as keyDriversTypes from '../../../types/types_keydrivers';
import { Colors } from '../../../../components/shared/styles';
import * as _ from 'lodash';
import moment from 'moment';
import * as Types from '../../../types/types';

/**
 * Preparing the charts in expandedRow
 * @param companyData
 */
export const getDataForKeyDriverChartsNewark = (
	companyData: any
): keyDriversTypes.KeyDriverChartNewArk[] => {
	let keyDrivers: keyDriversTypes.KeyDriverTrends[] =
		companyData?.organizationSubscription?.companyEntity?.searchFinancialDocuments?.keyDriverTrends;

	// we only care to show the last 4 for each key driver
	keyDrivers = keyDrivers.map(keyDriver => ({
		keyDriver: keyDriver.keyDriver,
		trends: keyDriver.trends.slice(keyDriver.trends.length - 4, keyDriver.trends.length)
	}));

	// if keyDrivers is larger than 5 it returns the top five according to negativeCount + positiveCount
	const topKeyDrivers: keyDriversTypes.KeyDriverTrends[] = getTopFiveKeydrivers(keyDrivers);
	let max: number = 0;
	const charts = topKeyDrivers.map((keyDriver: keyDriversTypes.KeyDriverTrends) => {
		const chart: keyDriversTypes.KeyDriverChartNewArk = {
			title: { text: keyDriver?.keyDriver },
			series: [
				{ name: 'Positive', data: [], color: Colors.positive },
				{ name: 'Negative', data: [], color: Colors.negative }
			],
			xAxis: {
				categories: []
			},
			yAxis: {
				max: 0
			}
		};
		keyDriver.trends.forEach((trend: keyDriversTypes.Trend) => {
			chart.series[0].data.push(trend?.positiveEventCount);
			chart.series[1].data.push(trend?.negativeEventCount);
			chart.xAxis.categories.push({
				fiscalQuarter: `${trend?.quarter}`,
				fiscalYear: `${trend?.year}`,
				date: getDateFromQuarterAndYear(trend?.quarter, trend?.year).toLocaleDateString('en-US')
			});
			max = Math.max(max, trend?.positiveEventCount, trend?.negativeEventCount);
		});
		return chart;
	});
	charts.forEach((chart: keyDriversTypes.KeyDriverChartNewArk) => {
		chart.yAxis.max = max;
	});
	return charts;
};

/**
 * getDataForKeyDriverChartsNewark helper function
 * Sorts the keydriversTrends and returns the top five according to
 * negative + positive count
 * NOTE - the deception needs to be always at the top
 */
const getTopFiveKeydrivers = (
	keyDrivers: keyDriversTypes.KeyDriverTrends[]
): keyDriversTypes.KeyDriverTrends[] => {
	const sortedKeyDriverTrends: keyDriversTypes.KeyDriverTrends[] = _.sortBy(
		keyDrivers,
		(keydriver: keyDriversTypes.KeyDriverTrends) => {
			let keyDriverTotalCount: number = 0;
			keydriver?.trends?.forEach((trend: keyDriversTypes.Trend) => {
				keyDriverTotalCount += trend?.negativeEventCount + trend?.positiveEventCount;
			});
			return keyDriverTotalCount;
		}
	);

	// find trend = deception and push it to the first position
	const filteredTrends = updateDeceptionLocation(sortedKeyDriverTrends);

	if (filteredTrends?.length > 5) {
		filteredTrends.splice(5, filteredTrends.length - 5);
		return filteredTrends;
	} else {
		return filteredTrends;
	}
};

// finds 'Deception' key and pushes it to the top of the list
const updateDeceptionLocation = (
	sortedKeyDriversTrends: keyDriversTypes.KeyDriverTrends[]
): keyDriversTypes.KeyDriverTrends[] => {
	const deceptionKeyDriver = _.find(sortedKeyDriversTrends, predicateForDecptionSearch);
	const filteredTrends = _.filter(sortedKeyDriversTrends, predicateForDecptionFilter);
	deceptionKeyDriver && filteredTrends.push(deceptionKeyDriver);
	deceptionKeyDriver && filteredTrends.reverse();
	return filteredTrends;
};

const predicateForDecptionSearch = (keyDriver: keyDriversTypes.KeyDriverTrends) =>
	keyDriver.keyDriver === 'Deception';

const predicateForDecptionFilter = (keyDriver: keyDriversTypes.KeyDriverTrends) =>
	keyDriver.keyDriver !== 'Deception';

/**
 * getDataForKeyDriverChartsNewark helper function
 * @param quarter
 * @param year
 */
const getDateFromQuarterAndYear = (quarter: number, year: number): Date => {
	switch (quarter) {
		case 1:
			return new Date(year, 1);
		case 2:
			return new Date(year, 4);
		case 3:
			return new Date(year, 7);
		case 4:
			return new Date(year, 10);
	}
};

export const getDataForAmenityScoreGraphNewark = (
	documents: Types.Document[]
): { y: number; x: number }[] =>
	_.map(orderDocumentsByDate(documents), t => ({
		y: t.total_daily_sentiment_score * 100,
		x: moment(t.eventDate ? t.eventDate : t.publicationDate).valueOf()
	}));

export const orderDocumentsByDate = (
	documents: Types.Document[],
	direction?: 'asc' | 'desc'
): Types.Document[] =>
	_.orderBy(
		documents,
		(t: Types.Document) => (t.eventDate ? t.eventDate : t.publicationDate),
		direction || 'asc'
	);
