import styled from 'styled-components';
import Images from '../../shared/images';
import { Colors } from '../../../components/shared/styles';

interface TitleArrowProps {
	pointTo: string;
	isHidden?: boolean;
}
export const BlueArrow = styled.div<TitleArrowProps>`
	display: ${props => (props.isHidden ? 'none' : 'block')};
	padding: 20px;
	cursor: pointer;
	mask-image: url(${Images.arrowFillRightBlack});
	background-color: ${Colors.blue};
	transform: rotate(${props => (props.pointTo === 'left' ? '180deg' : '0')});
	mask-position: center;
	-webkit-mask-repeat: no-repeat;
	-webkit-mask-size: 30px;
`;

export const CloseIcon = styled.div`
	display: block;
	padding: 10px;
	margin-right: 15px;
	cursor: pointer;
	background-color: ${Colors.blue};
	-webkit-mask-image: url(${Images.closeWhite});
	-webkit-mask-position: center;
	-webkit-mask-repeat: no-repeat;
	-webkit-mask-size: 11px;
`;
