import styled from 'styled-components';
import Images from '../images';

export const Container = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	background-color: #fff;
`;

export const LeftSide = styled.div`
	position: relative;
	display: inline-block;
	width: 50%;
	height: 100%;
	@media screen and (max-width: 700px) {
		width: 100%;
	}
`;

export const Title = styled.div`
	text-align: center;
	font-size: 16px;
	text-transform: uppercase;
	color: #00aeef;
	font-weight: bold;
	margin-bottom: 30px;
`;

export const SeparatorContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
`;

export const Separator = styled.div`
	display: inline-block;
	box-sizing: border-box;
	height: 2px;
	width: 100px;
	margin-top: 8px;
	background-color: #00aeef;
`;

export const Or = styled.div`
	display: inline-block;
	color: #9b9b9b;
	font-size: 13px;
	line-height: 18px;
`;

export const ExternalLogin = styled.div`
	height: 40px;
	width: 100%;
	padding-left: 25px;
	box-sizing: border-box;
	line-height: 39px;
	border-radius: 2px;
	background-color: #fff;
	box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24),
		0 0 2px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
	margin-bottom: 20px;
	cursor: pointer;
`;

export const ExtLoginText = styled.span`
	color: #4a4a4a;
	font-size: 15px;
	vertical-align: middle;
`;

export const InputContainer = styled.div`
	margin-bottom: 10px;
	position: relative;
`;

interface InputProps {
	error?: boolean;
	disabled?: boolean;
}
export const Input = styled.input<InputProps>`
	border: 1px solid;
	border-color: ${props => (props.error ? '#d23f31' : '#d7dee0')}
	border-radius: 4px;
	background-color: #fff;
	color: #4A4A4A;
	font-size: 18px;
	line-height: 24px;
	text-align: left;
	width: 100%;
	height: 54px;
	padding-left: 14px;
	box-sizing: border-box;
	opacity: ${props => (props.disabled ? '0.3' : '1')};
	::placeholder {
		text-align: left;
		font-size: 14px;
		color: rgba(155, 155, 155, 0.8);
	}
`;

export const SubmitBtn = styled.button`
	width: 100px;
	text-transform: uppercase;
	height: 40px;
	font-size: 15px;
	border-radius: 2px;
	background-color: #00aeef;
	text-align: center;
	margin-bottom: 11px;
	color: #fff;
	font-weight: bold;
	padding: 0;
	border: 0;
	cursor: pointer;
`;

export const SignUpLabel = styled.div`
	font-size: 15px;
	color: #696868;
`;

export const RightSide = styled.div`
	position: absolute;
	top: 0;
	width: 50%;
	height: 100%;
	background-image: url(${Images.loginBackground});
	background-size: cover;
	display: inline-block;
	@media screen and (max-width: 700px) {
		display: none;
	}
`;

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100%;
`;

export const SubTitle = styled.div`
	background-color: #00aeef;
	padding: 30px 76px;
	color: #fff;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	max-width: 600px;
`;

export const Img = styled.img`
	margin-right: 25px;
	vertical-align: middle;
`;

export const SignUpBtn = styled.a`
	color: #00aeef;
	font-weight: bold;
	cursor: pointer;
	text-transform: uppercase;
`;

export const ValidationError = styled.div`
	position: relative;
	color: #d23f31;
	font-size: 14px;
	top: 10px;
`;

export const Logo = styled.img`
	width: 256px;
	margin: 45px;
`;
