import { atom, selectorFamily } from 'recoil';
import axios from 'axios';
import { getString } from '../../services/localstorage.service';
import { error } from '../../services/logger.service';
import { GRAPHQL_SERVER_REMOTE_URL } from '../../config';
import { LOCAL_STORAGE_KEYS } from '../../constants';

const watchlistData = atom({
	key: 'watchlistData',
	default: {
		id: '',
		name: '',
		companyEntities: []
	}
});

const getWatchlistData = watchlistId =>
	new Promise(async resolve => {
		try {
			const { data: watchlistData } = await axios({
				url: GRAPHQL_SERVER_REMOTE_URL(),
				method: 'POST',
				headers: {
					Authorization: `Bearer ${getString(LOCAL_STORAGE_KEYS.TOKEN)}`,
					'x-amenity-source': 'web'
				},
				data: {
					operationName: 'GetWatchlistWithData',
					variables: {
						watchlistId: watchlistId
					},
					query: `query GetWatchlistWithData($watchlistId: String) {
					watchlist(id: $watchlistId) {
						name
						id
						companyEntities {
							id
							mainIdentifier {
								ticker
								region
							}
						}
					}
				}`
				}
			});

			resolve(watchlistData?.data?.watchlist);
		} catch (err) {
			error({
				message: `There was an error loading the watchlist: ${watchlistId}, Error: ${err}`,
				file: 'WatchlistProvider',
				functionName: 'getWatchlistData'
			});
		}
	});

const getWatchlist = selectorFamily({
	key: 'getWatchlist',
	get: watchlistId => async ({ get }) => {
		const response = await getWatchlistData(watchlistId);
		return response;
	}
});

export { watchlistData, getWatchlist };

export default watchlistData;
