import React from 'react';
import styled from 'styled-components';

import { Colors, FontSizes, FontFamilies } from '../shared/styles';

interface TagProps {
	text?: string;
	color?: Colors;
	title?: string;
	id?: string;
}

const StyledTag = styled.div<TagProps>`
	height: 25px;
	line-height: 25px;
	padding: 0 8px;
	border-radius: 4px;
	color: ${Colors.white};
	background-color: ${({ color }) => color};
	font-size: ${FontSizes.s2};
	font-family: ${FontFamilies.semiBold};
	cursor: default;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	max-width: 100%;
`;

export const Tag: React.FunctionComponent<TagProps> = ({ text, color, title, id }) => (
	<StyledTag color={color} title={title} id={id}>
		{text}
	</StyledTag>
);
