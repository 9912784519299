import * as React from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';
import { Button } from '@amenityllc/amenity-components';

import { APP_COLORS, PORTFOLIO_NAME_ERRORS } from '../../constants';
import ModalWithHeader from '../../../components/ModalWithHeader/ModalWithHeader';
import { Watchlist } from '../../types/types';
import { PRE_IMPORT } from '../../../id.constants';
import { FloatingText, BlockWithSpacing } from '../../pages/pageElements';

import { Colors, FontFamilies, Appearance } from '../../../components/shared/styles';
import { Text } from '../../../components/Text/Text';
import { MenuWrapper } from '../../../components/MenuWrapper/MenuWrapper';
import Radio from '../../../components/Radio/Radio';

enum CHOICE_STATE {
	NewPortfolio,
	ExitingPortfolio
}

const Content = styled.div`
	padding: 15px 20px;
	display: flex;
	flex-direction: column;
`;

const Title = styled(Text)`
	margin-bottom: 12px;
`;

const Box = styled.div<{
	isDisabled?: boolean;
	active: boolean;
	onClick: Function;
}>`
	width: 616px;
	height: 74px;
	padding: 17px 19px;
	margin-bottom: 20px;
	border-radius: 4px;
	border: 1px solid
		${props =>
			props.isDisabled
				? APP_COLORS.DISABLED_LIGHT_GRAY_EDEDED
				: props.active
				? Colors.darkGray
				: APP_COLORS.LIGHT_GRAY_DDE5E7};
	background-color: #ffffff;
	cursor: pointer;
	pointer-events: ${props => props.isDisabled && 'none'};
`;

const Label = styled.div<{ isDisabled?: boolean }>`
	display: inline-block;
	position: relative;
	top: -7px;
	left: 7px;
	font-family: ${FontFamilies.regular};
	font-size: 15px;
	color: ${props => (props.isDisabled ? APP_COLORS.DISABLED_LIGHT_GRAY_EDEDED : '#4a4a4a')};
	cursor: pointer;
	pointer-events: ${props => props.isDisabled && 'none'};
`;

const StyledButton = styled(Button)`
	align-self: flex-end;
`;

const Input = styled.input<{ error: boolean }>`
	display: block;
	width: 404px;
	height: 40px;
	padding: 0 13px;
	margin: 9px 27px;
	border-radius: 4px;
	border: solid 1px ${APP_COLORS.LIGHT_GRAY_DDE5E7};
	font-family: ${FontFamilies.regular};
	font-size: 16px;
	color: #1f1e29;
	outline-color: ${props => (props.error ? 'red' : 'default')};
`;

const DropDownButton = styled(Button)`
	margin: 9px 27px;
`;

const PortfolioName = styled.div`
	width: 380px;
	text-align: left;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

interface ModalProps {
	isOpen: boolean;
	portfolios: Watchlist[];
	header: string;
	onSelect: Function;
	close: Function;
}

interface ModalState {
	choice: number;
	selectedPortfolioId: string;
	nameError: boolean;
	errorText: string;
}

export default class PreImportModal extends React.Component<ModalProps, ModalState> {
	textInput: any;

	constructor(props) {
		super(props);
		this.state = {
			choice: CHOICE_STATE.NewPortfolio,
			selectedPortfolioId: props.portfolios?.[0]?.id,
			nameError: false,
			errorText: ''
		};
	}

	componentDidUpdate(prevProps) {
		if (!this.props.isOpen || prevProps.isOpen) {
			return;
		}
		setTimeout(() => {
			this.textInput && this.textInput.focus();
		});
	}

	close = () => {
		this.textInput.value = '';
		this.setState({
			choice: CHOICE_STATE.NewPortfolio,
			errorText: '',
			nameError: false
		});
		this.props.close();
	};

	select = (box: number) => {
		this.setState({ choice: box });
		if (box === 0) {
			this.textInput.focus();
		}
	};

	saveAndClose = (name: string, id: string) => {
		const { onSelect } = this.props;
		onSelect(name, id);
		this.close();
	};

	validateName = (name: string) => {
		const { portfolios } = this.props;
		if (!name) {
			return { nameError: true, errorText: PORTFOLIO_NAME_ERRORS.EMPTY };
		}
		if (_.some(portfolios, { name })) {
			return { nameError: true, errorText: PORTFOLIO_NAME_ERRORS.DUPLICATE };
		}
	};

	save = () => {
		const { portfolios } = this.props;
		const { choice, selectedPortfolioId } = this.state;
		if (choice === CHOICE_STATE.NewPortfolio) {
			let name = this.textInput.value;
			name = name && name.trim();
			const error = this.validateName(name);
			if (error) {
				this.setState(error);
				this.textInput.focus();
			} else {
				this.saveAndClose(name, undefined);
			}
		} else {
			const portfolioId = selectedPortfolioId || (portfolios[0] && portfolios[0].id);
			const portfolioName: string = portfolios.find(portfolio => portfolio.id === portfolioId).name;
			this.saveAndClose(portfolioName, portfolioId);
		}
	};

	resetNameError = () => {
		this.setState({ nameError: false, errorText: '' });
	};

	updateSelectedPortfolio = (selectedPortfolioId: string) => {
		this.setState({ selectedPortfolioId });
	};

	canGoNext = () => {
		const { choice, selectedPortfolioId } = this.state;
		return (
			(choice === CHOICE_STATE.NewPortfolio && this.textInput && this.textInput.value) ||
			(choice === CHOICE_STATE.ExitingPortfolio && selectedPortfolioId) ||
			(choice === CHOICE_STATE.ExitingPortfolio && this.props.portfolios.length > 1)
		);
	};

	isDropDownEmpty = (dropDownItems: any[]) => _.isEmpty(dropDownItems);

	isDropDownHasLessThanTwoElement = (dropDownItems: any[]) =>
		dropDownItems && dropDownItems.length < 2;

	render() {
		const { isOpen, portfolios, header } = this.props;
		const { choice, selectedPortfolioId, nameError, errorText } = this.state;
		const dropDownItems = _.map(portfolios, (portfolio: Watchlist) => ({
			id: portfolio.id,
			label: portfolio.name,
			elementId: portfolio.id.toString()
		}));
		const selectedPortfolio = _.find(portfolios, portfolio => portfolio.id === selectedPortfolioId);
		const portfolio = selectedPortfolio ?? portfolios?.[0];

		const labels = {
			[CHOICE_STATE.NewPortfolio]: 'Create a new watchlist',
			[CHOICE_STATE.ExitingPortfolio]:
				'Add to an existing watchlist (this will not delete the existing tickers in the watchlist)'
		};

		return (
			<ModalWithHeader title={header} isModalOpen={isOpen} close={this.close}>
				<Content>
					<Title color={Colors.black} fontFamily={FontFamilies.semiBold}>
						What would you like to do with the imported file?
					</Title>
					<Box active={choice === CHOICE_STATE.NewPortfolio} onClick={() => this.select(0)}>
						<Radio checked={choice === CHOICE_STATE.NewPortfolio} />
						<Label>{labels[CHOICE_STATE.NewPortfolio]}</Label>
						<Input
							data-cy='new_watchlist'
							type='text'
							ref={el => (this.textInput = el)}
							onChange={this.resetNameError}
							error={nameError}
						/>
					</Box>
					<Box
						isDisabled={this.isDropDownEmpty(dropDownItems)}
						active={choice === CHOICE_STATE.ExitingPortfolio}
						onClick={() => this.select(1)}
					>
						<Radio
							disabled={this.isDropDownEmpty(dropDownItems)}
							checked={choice === CHOICE_STATE.ExitingPortfolio}
						/>
						<Label isDisabled={this.isDropDownEmpty(dropDownItems)}>
							{labels[CHOICE_STATE.ExitingPortfolio]}
						</Label>
						<MenuWrapper
							options={dropDownItems}
							onSelect={(portfolioId: any) =>
								!this.isDropDownEmpty(dropDownItems) && this.updateSelectedPortfolio(portfolioId)
							}
							disabled={this.isDropDownHasLessThanTwoElement(dropDownItems)}
							menuPosition={{ top: 40, left: 27 }}
						>
							<DropDownButton
								appearance={Appearance.tertiary}
								withMenuArrow
								disabled={this.isDropDownHasLessThanTwoElement(dropDownItems)}
							>
								<PortfolioName>{portfolio?.name}</PortfolioName>
							</DropDownButton>
						</MenuWrapper>
					</Box>
					<ButtonContainer>
						<FloatingText direction='right' lineHeight='43px' color={APP_COLORS.NEGATIVE_RED}>
							<BlockWithSpacing margin='0 20px' id={PRE_IMPORT.ERROR}>
								{errorText}
							</BlockWithSpacing>
						</FloatingText>
						<StyledButton
							id={PRE_IMPORT.NEXT_BTN}
							disabled={!this.canGoNext()}
							onClick={() => this.canGoNext() && this.save()}
						>
							Next
						</StyledButton>
					</ButtonContainer>
				</Content>
			</ModalWithHeader>
		);
	}
}
