import React, { ReactNode } from 'react';
import GenericModal from '../../app/widgets/GenericModal/genericModal';
import { Colors, FontFamilies, FontSizes } from '../shared/styles';

const style = {
	overlay: {},
	content: {
		width: 'fit-content',
		overflow: 'visible',
		borderRadius: '4px',
		boxShadow: '0 0 5px 0 rgba(43,43,43,0.5)',
		backgroundColor: Colors.white
	},
	header: {
		backgroundColor: Colors.lightGray,
		color: Colors.blue,
		fontFamily: FontFamilies.semiBold,
		fontSize: FontSizes.m2,
		height: '64px',
		lineHeight: '64px',
		padding: '0 15px 0 18px'
	}
};

interface ModalProps {
	title?: string;
	isModalOpen: boolean;
	close: () => void;
	modalStyle?: {
		overlay?: React.CSSProperties;
		content?: React.CSSProperties;
		header?: React.CSSProperties;
	};
	children?: ReactNode;
}

const ModalWithHeader: React.FunctionComponent<ModalProps> = ({
	title,
	isModalOpen,
	close,
	modalStyle,
	children
}) => {
	const combinedStyle = {
		overlay: { ...style.overlay, ...modalStyle?.overlay },
		content: { ...style.content, ...modalStyle?.content },
		header: { ...style.header, ...modalStyle?.header }
	};
	return (
		<GenericModal
			header={title}
			isModalOpen={isModalOpen}
			closeModal={close}
			modalStyle={combinedStyle}
		>
			{children}
		</GenericModal>
	);
};

export default ModalWithHeader;
