import React from 'react';
import styled from 'styled-components';

import { Speaker, SelectFunction } from '../../../types/types';
import Images from '../../../shared/images';
import { FEATURES_TYPE_SPLIT, SCROLL_IDS } from '../../../constants';
import { useFeature } from '../../../hooks/useFeature';
import { Colors, FontSizes } from '../../../../components/shared/styles';
import avatarIcon from '../../../../assets/icons/speaker_avatar_sidebar.png';
import { Icon } from '../../../../components/Icon/Icon';
import { Text } from '../../../../components/Text/Text';

const SPEAKER_ITEM_HEIGHT = 65;

interface SpeakerDetailsProps {
	distanceFromLeftBorder: number;
	paddingTop: number;
	paddingLeft: number;
}
const SpeakerDetails = styled.div<SpeakerDetailsProps>`
	width: ${props => `calc(100% - ${props.distanceFromLeftBorder}px)`};
	height: 100%;
	float: left;
	padding-top: ${props => props.paddingTop}px;
	padding-left: ${props => props.paddingLeft}px;
	box-sizing: border-box;
`;

const SpeakerDiv = styled.div`
	height: calc(${SPEAKER_ITEM_HEIGHT}px - 20px);
	padding: 10px 20px 10px 0;
	cursor: pointer;
	:hover {
		background-color: rgb(155, 155, 155, 0.1);
	}
`;

const SpeakerAvatarContainer = styled.div`
	position: relative;
	width: 45px;
	height: 100%;
	float: left;
`;

const SpeakerAvatar = styled.img<{ left: number }>`
	position: absolute;
	top: 5px;
	left: ${props => props.left}px;
`;

const SpeakerName = styled.div`
	font-size: 15px;
	color: #4a4a4a;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const SpeakerDescription = styled.div`
	margin-top: 3px;
	font-size: 13px;
	color: #9b9b9b;
	font-family: Assistant;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const SpeakerDivNew = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 15px 10px;
	border-bottom: 1px solid ${Colors.lightGray};
	cursor: pointer;
	:hover {
		background-color: ${Colors.lightGray};
	}
`;

const SpeakerDetailsNew = styled.div`
	display: flex;
	flex-direction: column;
	width: calc(100% - 23px);
`;

interface Props {
	speaker: Speaker;
	onSelect: SelectFunction;
}

const SpeakerEntry: React.FunctionComponent<Props> = ({ speaker, onSelect }) => {
	const ffViewerDocumentFilters = useFeature(FEATURES_TYPE_SPLIT.VIEWER_DOCUMENT_FILTERS);
	return (
		<>
			{ffViewerDocumentFilters ? (
				<SpeakerDivNew
					key={`Speaker_${speaker.speakingIndex}`}
					onClick={() => onSelect(speaker.speakingIndex, SCROLL_IDS.SPEAKER.ID)}
				>
					<Icon src={avatarIcon} />
					<SpeakerDetailsNew>
						<Text size={FontSizes.s2} color={Colors.dark} title={`${speaker.name}`} ellipsis>
							{speaker.name}
						</Text>
						<Text
							size={FontSizes.s2}
							color={Colors.gray}
							title={`${speaker.title}, ${speaker.affiliation}`}
							ellipsis
						>
							{speaker.title}
							{speaker.title && speaker.affiliation && ', '}
							{speaker.affiliation}
						</Text>
					</SpeakerDetailsNew>
				</SpeakerDivNew>
			) : (
				<SpeakerDiv
					key={`Speaker_${speaker.speakingIndex}`}
					onClick={() => onSelect(speaker.speakingIndex, SCROLL_IDS.SPEAKER.ID)}
				>
					<SpeakerAvatarContainer>
						<SpeakerAvatar left={12} src={Images.speakerAvatarSidebar} />
					</SpeakerAvatarContainer>
					<SpeakerDetails paddingLeft={0} paddingTop={0} distanceFromLeftBorder={45}>
						<SpeakerName title={`${speaker.name}`}>{speaker.name}</SpeakerName>
						<SpeakerDescription title={`${speaker.title}, ${speaker.affiliation}`}>
							{speaker.title}
							{speaker.title && speaker.affiliation && ', '}
							{speaker.affiliation}
						</SpeakerDescription>
					</SpeakerDetails>
				</SpeakerDiv>
			)}
		</>
	);
};

export default SpeakerEntry;
