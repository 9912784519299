import XLSX from 'xlsx';
import { reduce, concat, isEmpty, isString } from 'lodash';

export const parseXlsx = (
	file: any,
	isExcelImport: boolean
): { factsets: string[]; tickers: string[]; tickersColumnExist: boolean; total: number } => {
	const workbook = XLSX.read(file, { type: 'buffer' });
	// Assuming only 1 sheet
	const json = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
	return formatXlsxJson(json, isExcelImport);
};

const formatXlsxJson = (
	json: any,
	isExcelImport: boolean
): { factsets: string[]; tickers: string[]; tickersColumnExist: boolean; total: number } => {
	const total = json.length;

	const result = reduce(
		json,
		(
			res: { factsets: string[]; tickers: string[]; tickersColumnExist: boolean; total: number },
			current: any
		) => {
			const ticker = formatTicker(
				current.Ticker || current.Tickers || current.ticker || current.tickers,
				isExcelImport ? ':' : ' '
			);
			const factset = (current.AmenityCompanyId || '').trim();
			return {
				tickers: ticker ? concat(res.tickers, ticker) : res.tickers,
				factsets: factset ? concat(res.factsets, factset) : res.factsets,
				tickersColumnExist: res.tickersColumnExist || !!ticker,
				total: res.total
			};
		},
		{ factsets: [], tickers: [], tickersColumnExist: false, total }
	);
	return result;
};

export const formatTicker = (ticker: string, delimiter: string): string => {
	if (!isString(ticker)) {
		return;
	}

	const trimedTicker = (ticker || '').trim();
	if (isEmpty(trimedTicker)) {
		return;
	}

	const splittedTicker = trimedTicker.split(' ');
	const region = splittedTicker[1] && splittedTicker[1].trim();
	if (region && region.length > 0) {
		return `${splittedTicker[0].trim()}${delimiter}${region}`;
	}
	return `${trimedTicker}${delimiter}US`;
};
