import styled from 'styled-components';
import { APP_COLORS } from '../../constants';
import Images from '../../shared/images';

export const TableContainer = styled.div`
	height: calc(100% - 16px);
	border: 1px solid ${APP_COLORS.LIGHT_GRAY_E0E7E9};
	border-radius: 6px;
	padding-bottom: 1px;
	box-sizing: border-box;
	white-space: nowrap;
	overflow-y: hidden;
	overflow-x: auto;
	box-shadow: 0 1px 4px 0 rgba(50, 49, 59, 0.32);
`;

interface TableRowContainerProps {
	isSelected?: boolean;
	enabled?: boolean;
}
export const TableRowContainer = styled.div<TableRowContainerProps>`
	border-bottom: 1px solid #eeeeee;
	background-color: ${props => (props.isSelected ? '#FAFAFA' : '#FFF')};
	font-family: Assistant;
`;

interface DeleteIconImgProps {
	show?: boolean;
}
export const DeleteIcon = styled.img<DeleteIconImgProps>`
	opacity: ${props => (props.show ? '1' : '0')};
	cursor: pointer;
`;

interface TableRowProps {
	enabled?: boolean;
	selected?: boolean;
	name?: string;
}
const TABLE_LINE_HEIGHT = 72;
export const TableRow = styled.div<TableRowProps>`
	display: flex;
	height: ${TABLE_LINE_HEIGHT}px;
	width: 100%;
	color: #4A4A4A;
	font-size: 14px;
	line-height: ${TABLE_LINE_HEIGHT}px;
	cursor: ${props => (props.enabled ? 'pointer' : 'default')};
	font-weight: 400;
	box-shadow: ${props =>
		props.selected
			? '-4px -2px 10px 6px rgba(214,214,214,1);'
			: '0 -1px 0 0 #DDE5E7, 0 1px 0 0 #DDE5E7;'}
	background-color: white;

	&:hover {
		background-color: ${props => (props.selected ? 'white' : '#F6F9FA')};
		.underline {
			color: #2a4d7e;
			text-decoration: underline;
		}
	}
	&:hover ${DeleteIcon} {
		opacity: 1;
	}

`;

export const TableRowHeader = styled.div`
	display: inline-block;
	height: ${TABLE_LINE_HEIGHT}px;
	min-width: 100%;
	color: #4a4a4a;
	font-size: 14px;
	line-height: ${TABLE_LINE_HEIGHT}px;
	border-bottom: 1px solid #eeeeee;
	background-color: white;
`;

interface HeaderColumnProps {
	width: string;
	clickable: boolean;
	marginLeft?: string;
	paddingLeft?: string;
	marginRight?: string;
	textAlign?: string;
	selected?: boolean;
}
export const HeaderColumn = styled.div<HeaderColumnProps>`
	width: ${props => props.width};
	cursor: ${props => (props.clickable ? 'pointer' : 'default')};
	margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
	margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
	padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
	text-align: ${props => (props.textAlign ? props.textAlign : 'inline-start')};
	position: relative;
	font-weight: 600;
	display: inline-block;
	box-sizing: border-box;
	font-family: Assistant;
	color: ${props => (props.selected ? APP_COLORS.BLACK_32313B : 'rgba(50,49,59, 0.6)')};
`;

export const TableBody = styled.div`
	height: calc(100% - ${TABLE_LINE_HEIGHT}px);
	width: 100%;
	overflow-y: auto;
`;

export const EmptyBody = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100% - ${TABLE_LINE_HEIGHT}px);
	width: 100%;
	overflow-y: auto;
`;

export const HeaderArrow = styled.img<{ isAscending: boolean }>`
	display: inline-block;
	position: relative;
	top: -3px;
	width: 10px;
	height: 5px;
	margin-left: 10px;
	transform: ${props => (props.isAscending ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const InfoIcon = styled.img.attrs({
	src: Images.infoIcon
})`
	position: relative;
	top: 4px;
	height: 17px;
	width: 17px;
	margin-left: 5px;
`;
