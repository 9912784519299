import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { uniqBy } from 'lodash';

import errorIcon from '../../shared/images/icon-error.png';
import * as Util from '../../services/util.service';
import { SplitFeatures, OutboundEvent, Part, PartItem } from '../../types/types';
import SecFilingSkeleton from './secFilingSkeleton';

const Iframe = styled.iframe`
	width: calc(100% - 32px);
	height: calc(100% - 15px);
	margin-top: 15px;
	margin-left: 22px;
	margin-right: 10px;
`;

const IframeContainer = styled.div`
	width: 100%;
	height: 100%;
`;

const ErrorContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ErrorBox = styled.div`
	display: flex;
	align-items: center;
	padding: 30px;
	background-color: rgba(150, 150, 150, 0.1);
	box-shadow: 0 0 30px 8px lightgray;
	border-radius: 10px;
`;

const ErrorText = styled.div`
	margin-left: 20px;
	font-size: 24px;
	color: gray;
`;

enum IframeIncomingMessageType {
	DocumentSections = 'SECTIONS',
	DocumentEvents = 'EVENTS',
	ScriptLoaded = 'ON_LOAD',
	RequestToSendFeatures = 'FEATURES'
}

interface IframeIncomingMessageData {
	msg_type: IframeIncomingMessageType;
	documentId: number;
	fromWorkshop: boolean;
}

interface WorkshopSection {
	name: string;
	depth: number;
	orderInParent: number;
	hash: string;
	deepest: boolean;
	parentHash?: string;
}

enum IframeOutgoingMessageType {
	SendFeatures = 'set_features',
	ScrollToElement = 'scroll',
	ToggleNeutralEvents = 'toggle_neutral'
}

interface IframeOutgoingMessageData {
	action: IframeOutgoingMessageType;
	data: any;
}

interface IframeMessage {
	origin: string;
	data: IframeIncomingMessageData;
}

export interface CrossFrameMessaging {
	onSectionsFromIframe: (sections: Part[]) => void;
	onEventsFromIframe: (events: OutboundEvent[]) => void;
}

export enum DocumentModelType {
	vip = 'vip',
	workshop = 'workshop'
}

export enum NavigationTargetType {
	event = 'event',
	section = 'section'
}

export interface NavigationTarget {
	type: NavigationTargetType;
	identifier: string | number;
}

export interface SecFilingIframeProps {
	loading: boolean;
	documentId: string;
	htmlUrl: string;
	documentModelType: DocumentModelType;
	crossFrameMessaging: CrossFrameMessaging;
	navigationTarget?: NavigationTarget;
	markNeutralEvents: boolean;
}

const SecFilingIframe = (props: SecFilingIframeProps) => {
	const {
		loading,
		documentId,
		htmlUrl,
		documentModelType,
		crossFrameMessaging,
		navigationTarget,
		markNeutralEvents
	} = props;

	// change this during development to see console logs related to cross-frame messaging
	const debug = false;
	const log = useCallback(
		(message: any, ...args: any[]) => {
			if (debug) {
				console.log(message, ...args);
			}
		},
		[debug]
	);

	const iframeName = 'document_iframe';
	const iframeId = 'DOCUMENT_IFRAME';

	// whether the JS in the loaded document is still initializing
	const [iframeScriptLoading, setIframeScriptLoading] = useState(true);

	// you can't scroll to events before one toggling of neutral extractions was done,
	// so we need this for our handle scroll effect
	const [iframeEventsScrollable, setIframeEventsScrollable] = useState(false);

	const sendMessageToIframe = (messageData: IframeOutgoingMessageData) => {
		const iframeWin: Window = window.frames[iframeName];
		iframeWin.postMessage(messageData, '*');
	};

	// a helper to adapt events from the iframe to the existing sidebar
	const parseEvents = useCallback((events: any[]): OutboundEvent[] => {
		// TODO: hook up to keyDriverModel var from documentSidebar
		return Util.parseTenKEvents([], events, true);
	}, []);

	const parseVipSections = useCallback((sections: any[]): Part[] => {
		return sections.map(
			(section, idx): Part => {
				return {
					name: section.name || 'N/A',
					navigationId: section.navigationId || idx,
					items: ((section.items as any[]) || []).map(
						(item): PartItem => ({
							name: item.name || 'N/A',
							navigationId: item.navigationId || 'N/A'
						})
					)
				};
			}
		);
	}, []);

	const parseWorkshopSections = useCallback((sections: WorkshopSection[]): Part[] => {
		// feel free to change this if you'd like more stuff to display, but generally
		// it seems like this is what people care about.
		const filterParts = true;
		const filterItems = true;

		const contentfulParentSections = uniqBy(
			sections
				.filter(section => section.deepest)
				.map(section => sections.find(parent => parent.hash === section.parentHash))
				.filter(parent => parent !== undefined),
			parent => parent.hash
		).filter(section => (filterParts ? section.name.toLowerCase().startsWith('part') : true));

		return contentfulParentSections
			.map((parent, idx) => {
				return {
					name: parent.name,
					navigationId: idx,
					// optionally re-enable this later, after we rename this to something product-approved
					// items: [{ name: '(Top of Part)', navigationId: parent.hash }].concat(
					items: [].concat(
						sections
							.filter(
								section =>
									section.parentHash === parent.hash &&
									(filterItems ? section.name.toLowerCase().startsWith('item') : true)
							)
							.sort((childA, childB) => childA.orderInParent - childB.orderInParent)
							.map(child => ({
								name: child.name,
								navigationId: child.hash
							}))
					)
				};
			})
			.filter(parent => parent.items.length > 0);
	}, []);

	// TODO: get features for realz
	const sendFeaturesToIframe = useCallback(() => {
		const userFeatures: SplitFeatures = {
			viewer_collapse_all_rows: true,
			viewer_document_filters: true,
			viewer_document_type_flow_history: true,
			viewer_dtc_email: true,
			viewer_heatmap: true,
			viewer_newarc_migration: true,
			viewer_news_article: true,
			viewer_playground: true,
			viewer_sec_filings_v2: true,
			viewer_tickers_collapsed_mode: true
		};
		sendMessageToIframe({
			action: IframeOutgoingMessageType.SendFeatures,
			data: userFeatures
		});
	}, []);

	// set up our message handler
	const handleMessageFromIframe = useCallback(
		(message: IframeMessage) => {
			// drop messages that don't come from the iframe
			if (!Util.isAllowedOriginDocIframe(message.origin)) {
				return;
			}

			const {
				msg_type: messageType,
				documentId: iframeDocumentId,
				fromWorkshop: workshopScriptIndicator
			} = message.data;
			const unstructuredMessageData = message.data as any;

			// useful in development to filter out messages from react devtools
			if (
				(documentModelType === DocumentModelType.vip && !iframeDocumentId) ||
				(documentModelType === DocumentModelType.workshop && !workshopScriptIndicator)
			) {
				return;
			}

			log(`Got message from iframe: ${message.data.msg_type}`, message);

			// for vip, if there's a document ID mismatch, ignore the message
			if (
				documentModelType === DocumentModelType.vip &&
				documentId !== iframeDocumentId.toString()
			) {
				console.warn('Document ID mismatch between iframe and page', {
					pageDocumentId: documentId,
					iframeDocumentId
				});

				return;
			}

			switch (messageType) {
				case IframeIncomingMessageType.DocumentSections:
					const parsedSections =
						documentModelType === DocumentModelType.vip
							? parseVipSections(unstructuredMessageData.sections as any[])
							: parseWorkshopSections(unstructuredMessageData.sections as WorkshopSection[]);
					crossFrameMessaging.onSectionsFromIframe(parsedSections);
					break;
				case IframeIncomingMessageType.DocumentEvents:
					const parsedEvents = parseEvents(unstructuredMessageData.events as any[]);
					crossFrameMessaging.onEventsFromIframe(parsedEvents);
					break;
				case IframeIncomingMessageType.ScriptLoaded:
					setIframeScriptLoading(false);
					break;
				case IframeIncomingMessageType.RequestToSendFeatures:
					sendFeaturesToIframe();
					break;
			}
		},
		[
			documentId,
			crossFrameMessaging,
			sendFeaturesToIframe,
			parseEvents,
			parseVipSections,
			parseWorkshopSections,
			documentModelType,
			log
		]
	);

	// register our message handler
	useEffect(() => {
		window.addEventListener('message', handleMessageFromIframe);

		// clean up on unmount
		return () => {
			window.removeEventListener('message', handleMessageFromIframe);
		};

		// only run effect once
	}, [handleMessageFromIframe]);

	// handle "neutral events" toggle (necessary for event navigation!)
	useEffect(() => {
		if (loading || iframeScriptLoading) {
			return;
		}

		if (documentModelType === DocumentModelType.vip || iframeEventsScrollable) {
			sendMessageToIframe({
				action: IframeOutgoingMessageType.ToggleNeutralEvents,
				data: markNeutralEvents
			});
		}

		// it might take the iframe a bit of time to change the names of all the
		// event tags to their scrollable event hashes, so we have to delay this a bit
		if (!iframeEventsScrollable) {
			const setEventsScrollableTimeout = setTimeout(() => {
				setIframeEventsScrollable(true);
			}, 200);

			// cleanup if unmounted
			return () => {
				clearTimeout(setEventsScrollableTimeout);
			};
		}
	}, [loading, iframeScriptLoading, markNeutralEvents, iframeEventsScrollable, documentModelType]);

	// handle "scroll to" events
	useEffect(() => {
		if (!navigationTarget) {
			return;
		}

		const sendNavigationMessageToIframe = (
			elementId: string | number,
			target: NavigationTarget
		) => {
			if (documentModelType === DocumentModelType.vip) {
				sendMessageToIframe({
					action: IframeOutgoingMessageType.ScrollToElement,
					data: elementId
				});
			} else {
				sendMessageToIframe({
					action: IframeOutgoingMessageType.ScrollToElement,
					data: target
				});
			}
		};

		switch (navigationTarget.type) {
			case NavigationTargetType.event:
				if (iframeEventsScrollable) {
					log('Navigate to event', navigationTarget.identifier);
					sendNavigationMessageToIframe(navigationTarget.identifier, navigationTarget);
				}
				break;

			case NavigationTargetType.section:
				log('Navigate to section', navigationTarget.identifier);
				sendNavigationMessageToIframe(navigationTarget.identifier, navigationTarget);
				break;
		}
	}, [navigationTarget, iframeEventsScrollable, documentModelType, log]);

	// this will allow older scriptless HTMLs to work with this iframe component, while not all
	// of them are fully supported.
	const temporaryWorkshopScriptlessSupport = documentModelType !== DocumentModelType.workshop;

	return (
		<IframeContainer>
			{(loading || (htmlUrl && iframeScriptLoading && temporaryWorkshopScriptlessSupport)) && (
				<SecFilingSkeleton />
			)}
			{!loading &&
				(htmlUrl ? (
					<Iframe id={iframeId} src={htmlUrl} name={iframeName} frameBorder='0'>
						<p>Your browser does not support iframes.</p>
					</Iframe>
				) : (
					<ErrorContainer>
						<ErrorBox>
							<img src={errorIcon} alt='Document unavailable' height='67' width='60' />
							<ErrorText>
								This document is
								<br />
								currently unavailable.
							</ErrorText>
						</ErrorBox>
					</ErrorContainer>
				))}
		</IframeContainer>
	);
};

export default SecFilingIframe;
