import React from 'react';

import { Polarity } from '../../app/types/types';
import {
	EventElementItem,
	EventNumber,
	EventTitleSection,
	EventCategoryName,
	EventTypeName
} from './printPageElements';
import { getEventPolarity } from '../shared/util';
import * as ids from '../../id.constants';

interface Props {
	hash: number;
	polarity: Polarity;
	categoryName: string;
	typeName: string;
}

const PrintEventElement: React.FunctionComponent<Props> = (props: Props) => (
	<EventElementItem id={`${ids.PRINT_TRANSCRIPT.KEY_DRIVER_EVENT}${props.hash}`}>
		<EventNumber number={props.hash} polarity={getEventPolarity(props.polarity)}>
			{props.hash}
		</EventNumber>
		<EventTitleSection>
			<EventCategoryName polarity={getEventPolarity(props.polarity)}>
				{props.categoryName}
			</EventCategoryName>
			<EventTypeName polarity={getEventPolarity(props.polarity)}>{props.typeName}</EventTypeName>
		</EventTitleSection>
	</EventElementItem>
);

export default PrintEventElement;
