import * as React from 'react';
import styled from 'styled-components';
import Images from '../../shared/images';
import { Colors } from '../../../components/shared/styles';

const Content = styled.div`
	position: relative;
	height: 100%;
	padding-top: 70px;
	box-sizing: border-box;
`;

const SpinnerIcon = styled.div`
	height: 100px;
	width: 100px;
	margin: auto;
	background-image: url("${Images.spinner}");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
`;

const Title = styled.div`
	margin-top: 20px;
	text-align: center;
	color: #2a4d7e;
	font-size: 24px;
	font-family: Assistant SemiBold;
`;

const Footer = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	border-radius: 4px;
	border-top: solid 1px #dde5e7;
`;

const CancelUploadBtn = styled.div`
	height: 45px;
	width: 100px;
	line-height: 45px;
	margin: auto;
	cursor: pointer;
	color: ${Colors.blue};
	font-size: 16px;
	font-family: Assistant;
	&:hover {
		text-decoration: underline;
	}
`;

interface UploadSpinnerProps {
	cancelUpload: any;
}

export default class UploadSpinner extends React.Component<UploadSpinnerProps, {}> {
	render() {
		const { cancelUpload } = this.props;
		return (
			<Content>
				<SpinnerIcon id='UPLOAD_SPINNER' />
				<Title>Your file is being uploaded</Title>
				<Footer>
					<CancelUploadBtn onClick={cancelUpload}>Cancel Upload</CancelUploadBtn>
				</Footer>
			</Content>
		);
	}
}
