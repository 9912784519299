import _ from 'lodash';

import * as Types from '../types/types';
import * as Util from '../services/util.service';
import DataService from '../services/data.service';
import { error } from '../services/logger.service';

export const getCombinedCompanyDataAfterQiSearch = (
	companyDocumentMetaData: Types.Company,
	companyFromQiSearch: Types.Company
): Types.Company => {
	if (_.isEmpty(companyDocumentMetaData) || _.isEmpty(companyFromQiSearch)) {
		return companyDocumentMetaData || companyFromQiSearch;
	}
	return {
		id: companyDocumentMetaData.id || companyFromQiSearch.id || -1,
		name: companyDocumentMetaData.name || companyFromQiSearch.name,
		ticker: companyDocumentMetaData.ticker || companyFromQiSearch.ticker,
		region: companyDocumentMetaData.region || companyFromQiSearch.region,
		companyIds: companyDocumentMetaData.companyIds || companyFromQiSearch.companyIds,
		isInPortfolio: companyDocumentMetaData.isInPortfolio
	};
};

export const fetchDocumentMetaData = (flowId: number, documentId: number): Promise<any> =>
	new Promise((resolve, reject) => {
		const functionName = 'getDocumentMetaData';
		const ds = DataService.getInstance();
		const params = {
			flowId,
			documentId
		};
		ds.getDocumentMetaData(
			params,
			(data: { docMetaData: any; company: any }) => {
				if (_.isEmpty(data) || _.isEmpty(data.docMetaData)) {
					resolve({ documentMetaData: undefined, company: undefined });
					return;
				}
				const documentMetaData = data.docMetaData;
				const documentType: string = documentMetaData.documentType;

				const flowId = documentMetaData.flowId;
				const vendor = documentMetaData.vendor;
				const transcriptFromAPI =
					documentMetaData.articleReportEntries && documentMetaData.articleReportEntries[0];
				const parsedTranscript = Util.parseTranscriptFromAPI(
					transcriptFromAPI,
					vendor,
					flowId,
					documentType
				);

				resolve({ documentMetaData: parsedTranscript, company: data.company });
			},
			(err: any) => {
				error({
					message: `Error loading document MetaData ${err ? err : ''}`,
					file: 'DataStore',
					functionName
				});
				reject(err);
			}
		);
	});

export const isValidPortfolioId = (id: string) => id !== undefined && id !== null;
