import * as React from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import currentDocumentTypeState from '../../recoil/currentDocumentType';
import { Colors } from '../../../components/shared/styles';
import {
	DocumentTypeGrouping,
	isDocumentTypeInGrouping,
	DocumentType
} from '../../types/DocTypes.types';
import { Document } from '../../types/types';
import { getDocumentDate } from './documentList.util';
import { getUserLocalTimeFromUTC } from '../../helpers.timezone';

const DocumentDescription = styled.div`
	color: ${Colors.blue};
	font-size: 14px;
	font-weight: 400;
	line-height: 19px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const DocumentIndex = styled.span`
	color: #000000;
`;

const DocumentTitle = styled.a`
	text-decoration: underline;
	cursor: pointer;
	color: inherit;
`;

const DocumentDate = styled.div`
	padding: 10px 0 8px 14px;
	height: 17px;
	color: #696868;
	font-size: 12px;
	line-height: 17px;
`;

interface DocumentLinkProps {
	document: Document;
	index: number;
	currentSubscriptionId: string;
	onClickDocument: Function;
}

const DocumentInList: React.FunctionComponent<DocumentLinkProps> = (props: DocumentLinkProps) => {
	const { document, index, currentSubscriptionId, onClickDocument } = props;
	const title = document.title;
	const date = getDocumentDate(document);
	const formattedDate = getUserLocalTimeFromUTC(date);
	// try to take the type from the document, but if we fail for w/e reason take from global state
	const currentDocType = useRecoilValue(currentDocumentTypeState);

	let parsedDocumentType = DocumentType[document.documentType];

	// in the absolute worst case where global state hasn't even been initialized,
	// default to what was until now assumed as a transcript type
	if (parsedDocumentType === undefined || parsedDocumentType === DocumentType.Unknown) {
		parsedDocumentType = currentDocType ?? DocumentType.EarningsCall;
	}

	// determine the target path depending on the document's type
	const documentTypePath = isDocumentTypeInGrouping(
		parsedDocumentType,
		DocumentTypeGrouping.Transcripts
	)
		? 'transcript'
		: 'sec';

	return (
		<DocumentDescription key={document.documentId}>
			<DocumentIndex>{index + 1}. </DocumentIndex>
			<DocumentTitle
				href={`/subscription/${currentSubscriptionId}/${documentTypePath}/document/${document.documentId}`}
				onClick={event => {
					event.preventDefault();
					onClickDocument(document.documentId, documentTypePath);
				}}
				title={title}
				id={`RECENT_DOCUMENTS_OPEN_inum_${index + 1}`}
			>
				{title}
			</DocumentTitle>
			<DocumentDate>{formattedDate}</DocumentDate>
		</DocumentDescription>
	);
};

export default DocumentInList;
