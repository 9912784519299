import * as React from 'react';
import styled from 'styled-components';
import { Button } from '@amenityllc/amenity-components';

import { Watchlist, PortfolioActionModalProps, PortfolioActionModalType } from '../../types/types';
import { KEY_CODES } from '../../constants';
import { inputIsFocused } from '../../services/util.service';
import { PORTFOLIO_ACTION_MODAL } from '../../../id.constants.js';
import Modal from '../../../components/Modal/Modal';
import { Appearance, Colors, Sizes } from '../../../components/shared/styles';

const Input = styled.input`
	width: calc(100% - 25px);
	height: 38px;
	margin-top: 2px;
	border-radius: 4px;
	border: solid 1px ${Colors.gray};
	padding: 0 8px 0 8px;
	font-size: 16px;
`;

const Error = styled.div<{ show: boolean }>`
	height: 30px;
	line-height: 28px;
	font-size: 11px;
	padding: 0 8px;
	text-align: left;
	color: #d23f31;
	visibility: ${props => (props.show ? 'visible' : 'hidden')};
`;

const ButtonLarge = styled(Button)`
	min-width: 200px;
`;

interface ModalProps {
	modal: PortfolioActionModalProps;
	portfolio: Watchlist;
	close: () => void;
	save: (...args: any[]) => void;
}

export default class PortfoliosActionModal extends React.Component<ModalProps, {}> {
	textInput: any;

	componentDidUpdate() {
		const { modal } = this.props;
		if (modal?.type === PortfolioActionModalType.Edit && modal.open) {
			document.addEventListener('keydown', this.handleKeyPress, true);
			setTimeout(() => {
				this.textInput?.select();
			});
		} else {
			document.removeEventListener('keydown', this.handleKeyPress, true);
		}
	}

	handleKeyPress = event => {
		if (!this.props.modal?.open) {
			return;
		}
		if (event.keyCode === KEY_CODES.ENTER && inputIsFocused(this.textInput)) {
			this.save();
		}
	};

	save = () => {
		const { modal, portfolio } = this.props;
		if (modal.type === PortfolioActionModalType.Delete) {
			this.props.save(portfolio.id);
		} else if (modal.type === PortfolioActionModalType.Edit) {
			this.props.save(portfolio?.id, this.textInput?.value);
		}
	};

	cancel = (confirmed = false) => {
		this.props.close();
	};

	renderInput = () => (
		<>
			<Input
				id={PORTFOLIO_ACTION_MODAL.INPUT}
				type='text'
				defaultValue={
					this.props.portfolio ? this.props.portfolio.name : this.props.modal.defaultValue || ''
				}
				ref={el => {
					this.textInput = el;
				}}
			/>
			<Error show={!!this.props.modal.error}>{this.props.modal.error}</Error>
		</>
	);

	renderButtons = () => [
		<ButtonLarge
			key='ACTION_MODAL_SAVE'
			id={PORTFOLIO_ACTION_MODAL.CONFIRM_BTN}
			size={Sizes.medium}
			appearance={Appearance.primary}
			text={this.props.modal.confirmText}
			onClick={() => this.save()}
		/>,
		<Button
			key='ACTION_MODAL_CANCEL'
			id={PORTFOLIO_ACTION_MODAL.CANCEL_BTN}
			size={Sizes.medium}
			appearance={Appearance.secondary}
			text={'Cancel'}
			onClick={() => this.cancel()}
		/>
	];

	render() {
		const { modal } = this.props;
		if (!modal) {
			// tslint:disable-next-line:no-null-keyword
			return null;
		}
		return (
			<Modal
				icon={modal.icon}
				title={modal.title}
				text={modal.text}
				isModalOpen={modal?.open}
				modalContent={modal.type === PortfolioActionModalType.Edit && this.renderInput()}
				modalButtons={this.renderButtons()}
				close={() => this.cancel()}
			/>
		);
	}
}
