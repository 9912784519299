import React from 'react';
import styled from 'styled-components';

import { SERVER_REMOTE_URL } from '../../config';

const Iframe = styled.iframe`
	width: 100%;
	height: calc(100% - 10px);
`;

interface DocumentIframeProps {
	username: string;
	flowId: number;
	modelId?: number;
	eventId?: string;
	documentId: number;
	documentType: string;
}

export let documentIframeRef;

const DocumentIframe: React.FunctionComponent<DocumentIframeProps> = ({
	username,
	flowId,
	modelId,
	eventId,
	documentId,
	documentType
}) => {
	const baseRoute = `${SERVER_REMOTE_URL()}/get_document_outbound?documentId=${documentId}&email=${username}&documentType=${documentType}${
		eventId ? `&eventId=${eventId}` : ''
	}`;
	const iframeUrl = modelId ? `${baseRoute}&modelId=${modelId}` : `${baseRoute}&flowId=${flowId}`;
	return (
		<Iframe
			ref={node => (documentIframeRef = node)}
			id='DOCUMENT_IFRAME'
			src={iframeUrl}
			className='iframe-doc'
			name='document_iframe'
			frameBorder='0'
		>
			<p>Your browser does not support iframes.</p>
		</Iframe>
	);
};

export default DocumentIframe;
