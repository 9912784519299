import * as React from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';
import { Element } from 'react-scroll';
import { Speaker, Event, Paragraph } from '../../types/types';
import Images from '../../shared/images';
import SpeakerText from './speakerText';
import { SCROLL_IDS } from '../../constants';

const SpeakerContainer = styled.div`
	width: 100%;
	margin-bottom: 30px;
`;

const ParagraphHeader = styled.div`
	width: 100%;
	height: 35px;
	line-height: 35px;
`;

const AvatarContainer = styled.div`
	position: relative;
	width: 32px;
	height: 100%;
	float: left;
`;

const Avatar = styled.img`
	position: absolute;
	top: 9px;
	left: 7px;
`;

const SpeakerName = styled.div`
	height: 100%;
	float: left;
	font-size: 16px;
	color: #586876;
`;

const Separator = styled.div`
	width: 1px;
	height: 13px;
	float: left;
	margin: 12px 0 0 7px;
	background-color: #9b9b9b;
`;

const SpeakerDetails = styled.div`
	height: 100%;
	float: left;
	padding-top: 1px;
	margin-left: 7px;
	font-size: 13px;
	color: #9b9b9b;
`;

const SpeakerHeaderUnderline = styled.div`
	width: calc(100% - 10px);
	height: 2px;
	background-color: #eeeeee;
`;

interface DiscussionSectionProps {
	speakers: Speaker[];
	events: Event[];
	lostEvents: Event[];
	delimiterOffset: number;
	printMode: boolean;
}

export default class DiscussionSection extends React.PureComponent<DiscussionSectionProps, {}> {
	getSpeakerElements = (speakers: Speaker[]): any[] => {
		const { delimiterOffset, printMode, events, lostEvents } = this.props;

		const speakersRender: any[] = _.map(speakers, (s: Speaker, index) => {
			const paragraphs: Paragraph[] = s.paragraphs;

			const filteredEvents = _.filter(events, (e: Event) => {
				return (
					e.startIndex - (e.isUserDefined ? 0 : delimiterOffset) >= s.offsetInText &&
					e.endIndex - (e.isUserDefined ? 0 : delimiterOffset) < s.offsetInText + s.textLength + 1
				);
			});

			return (
				<SpeakerContainer key={`Speaker_Paragraph_${index}`}>
					{this.mapSpeakerDetailsToHeader(s)}
					<SpeakerText
						paragraphs={paragraphs}
						events={filteredEvents}
						lostEvents={lostEvents}
						speaker={s}
						delimiterOffset={delimiterOffset}
						sectionOffset={0}
						type={0}
						printMode={printMode}
					/>
				</SpeakerContainer>
			);
		});

		return speakersRender;
	};

	mapSpeakerDetailsToHeader = (speaker: Speaker): any => [
		<ParagraphHeader key='ParagraphHeader'>
			<AvatarContainer>
				<Avatar src={Images.speakerAvatarText} />
			</AvatarContainer>
			<SpeakerName>
				<Element name={SCROLL_IDS.SPEAKER.NAME + String(speaker.speakingIndex)} />
				{speaker.name}
			</SpeakerName>
			{speaker.title !== '' && <Separator />}
			{speaker.title !== '' && (
				<SpeakerDetails>{`${speaker.title}, ${speaker.affiliation}`}</SpeakerDetails>
			)}
		</ParagraphHeader>,
		<SpeakerHeaderUnderline key='SpeakerHeaderUnderline' />
	];

	render() {
		return <div>{this.getSpeakerElements(this.props.speakers)}</div>;
	}
}
