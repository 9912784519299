import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { History } from 'history';

import { STORE_GLOBAL, STORE_UI, TABS } from '../../constants';

import GlobalStore from '../../stores/global.store';
import UIStore from '../../stores/ui.store';
import Transcript from './transcriptDocument';
import { DocumentTypeGrouping } from '../../types/DocTypes.types';
import { GetSubscriptionData } from '../document/subscription.service';

interface NewsContainerProps {
	history: History;
	location: any;
	match: any;
	[STORE_GLOBAL]?: GlobalStore;
	[STORE_UI]?: UIStore;
}

// Despite its name, this component renders both SEC filings and transcripts.
// It differentiates between them based on the path:
// A page rendering a transcript will be /subscription/:subId/transcript/document/:docId
// And for SEC filings, /subscription/:subId/sec/document/:docId
// Both variants can optionally be appended with /event/:eventId to autoscroll to a given event.
const TranscriptContainer = (props: NewsContainerProps) => {
	// Determine which type of document our child component should render
	const pagePath: string = props.match.path ?? '';
	const documentTypeGrouping = pagePath.includes('/sec/')
		? DocumentTypeGrouping.SecFilings
		: DocumentTypeGrouping.Transcripts;

	// Extract path params
	const params = (props.match && props.match.params) || {};
	const flowId = params.flowId;
	const documentId = params.documentId;
	const subscriptionId = params.subscriptionId
		? params.subscriptionId
		: GetSubscriptionData(flowId);

	const [eventId, setEventId] = useState(params.eventId);

	const setClickedEventId = (newEventId: string) => setEventId(newEventId);

	// update eventId in URL when one is clicked (if we started on an eventId)
	useEffect(() => {
		if (params && params.eventId && params.eventId !== eventId) {
			props.history.push(
				props.history.location.pathname.replace(/\/event\/.*$/, `/event/${eventId}`)
			);
		}
	}, [eventId, params, props.history]);

	useEffect(() => {
		const globalStore = props[STORE_GLOBAL];
		const ui = props[STORE_UI] as UIStore;
		globalStore.setCurrentTab(TABS.WATCHLIST);
		ui.setSideBarState(false);
		ui.setCanToggleSideBar(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Transcript
			subscriptionId={subscriptionId}
			documentId={documentId}
			eventId={eventId}
			setClickedEventId={setClickedEventId}
			documentTypeGrouping={documentTypeGrouping}
		/>
	);
};

export default inject(STORE_GLOBAL, STORE_UI)(observer(TranscriptContainer));
