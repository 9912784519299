import React from 'react';
import { Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import * as _ from 'lodash';

import { STORE_AUTH, STORE_GLOBAL, STORE_UI } from '../../constants';
import UIStore from '../../stores/ui.store';
import GlobalStore from '../../stores/global.store';
import InsightPlatformPage from './InsightPlatformPage';

interface Props {
	component: any;
	exact: boolean;
	path: string;
	isFullHeight?: boolean;
	generalPageProps?: any;
	bypassRoute?: boolean;
	match?: any;
}

@inject(STORE_AUTH, STORE_GLOBAL, STORE_UI)
@observer
export default class AccordionLayout extends React.Component<Props, {}> {
	render() {
		const {
			component: PageComponent,
			isFullHeight,
			generalPageProps,
			bypassRoute,
			match,
			...rest
		} = this.props;
		const { currentTab } = this.props[STORE_GLOBAL] as GlobalStore;
		const { isSideBarSubMenuOpen, canToggleSideBarSideMenu, toggleSideBar } = this.props[
			STORE_UI
		] as UIStore;
		const isToShowSideBarSubMenu = _.includes(['/backoffice', '/document'], this.props.path)
			? false
			: isSideBarSubMenuOpen;
		return (
			<Route
				{...rest}
				render={matchProps => (
					<InsightPlatformPage
						component={PageComponent}
						isFullHeight={isFullHeight}
						bypassRoute={bypassRoute}
						match={match}
						isToShowSideBarSubMenu={isToShowSideBarSubMenu}
						canToggleSideBarSideMenu={canToggleSideBarSideMenu}
						currentTab={currentTab}
						toggleSideBar={toggleSideBar}
						matchProps={matchProps}
					/>
				)}
			/>
		);
	}
}
