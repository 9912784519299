import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-D HH:mm:ss,SSS';

const info = (data: { message: string; file: string; functionName: string }) => {
	console.info(`${moment().format(DATE_FORMAT)} INFO [Client] ${data.message}`);
};

const error = (data: { message: string; file: string; functionName: string }) => {
	console.error(`${moment().format(DATE_FORMAT)} ERROR [Client] ${data.message}`);
};

const warn = (data: { message: string; file: string; functionName: string }) => {
	console.warn(`${moment().format(DATE_FORMAT)} WARN [Client] ${data.message}`);
};

const debug = (data: { message: string }) => {
	console.debug(`${moment().format(DATE_FORMAT)} DEBUG [Client] ${data.message}`);
};

const log = (data: { message: string }) => {
	console.log(`${moment().format(DATE_FORMAT)} LOG [Client] ${data.message}`);
};

export { info, error, warn, debug, log };
