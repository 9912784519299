import * as React from 'react';
import styled from 'styled-components';
import Images from '../../shared/images';

const Container = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #f8f9fa;
`;

const MiddleTile = styled.div`
	position: absolute;
	width: 864px;
	height: 544px;
	top: 50%;
	margin-top: -272px;
	left: 50%;
	margin-left: -432px;
	background-color: #ffffff;
	box-shadow: 0 -14px 18px -14px rgba(0, 0, 0, 0.2), 0 14px 18px -9px rgba(0, 0, 0, 0.2);
	border-radius: 1.6px;
`;

const TextContainer = styled.div`
	margin-left: 60px;
	margin-top: 27px;
`;

const Title = styled.div`
	color: #202123;
	font-family: Assistant Extra Light;
	font-size: 72px;
	font-weight: 200;
	line-height: 95px;
`;

const Text = styled.div`
	margin-top: 34px;
	width: 568px;
	color: #202123;
	font-family: Assistant;
	font-size: 16px;
	line-height: 21px;
`;

const Button = styled.a`
	display: block;
	text-decoration: none;
	margin: 55px auto;
	height: 46px;
	width: 200px;
	border-radius: 2px;
	background-color: #4b8bf4;
	color: #ffffff;
	cursor: pointer;
	font-family: Assistant;
	font-size: 16px;
	font-weight: bold;
	line-height: 46px;
	text-align: center;
`;

const Logo = styled.div`
	margin: 24px;
`;

const LogoImg = styled.img`
	vertical-align: middle;
	width: 52px;
	margin-right: 10px;
`;

const LogoText = styled.span`
	vertical-align: middle;
	color: #2a4d7e;
	font-size: 20px;
	font-family: Roboto;
`;

const ChromeLogo = styled.img`
	position: absolute;
	width: 69px;
	height: 69px;
	right: 60px;
	top: 51px;
`;

export default class NotSupportedPage extends React.Component<{}, {}> {
	render() {
		return (
			<Container>
				<Logo>
					<LogoImg src={Images.logoImg} />
					<LogoText>Amenity Analytics</LogoText>
				</Logo>
				<MiddleTile>
					<TextContainer>
						<Title>
							Your browser is
							<br />
							currently not
							<br />
							supported.
						</Title>
						<Text>
							Don’t worry, there is an easy fix. All you have to do is download Chrome browser below
							and follow the instructions. You’ll be able to log in to the new Amenity Analytics
							viewer in a minute.
						</Text>
					</TextContainer>
					<Button href='https://www.google.com/chrome/' target='_blank'>
						Get Chrome
					</Button>
					<ChromeLogo src={Images.chrome} />
				</MiddleTile>
			</Container>
		);
	}
}
