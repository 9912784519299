import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { DashboardEvent, LookerEmbedDashboard, LookerEmbedSDK } from '@looker/embed-sdk';
import { LOOKER_SDK } from '../../dashboard.constants';
import { useGetUrlForDashboardQuery } from '../../queries/autogenerate/hooks';
import DashboardIframeStateService from './dashboardService';

interface DashboardIframeProps {
	id: string;
	isHidden: boolean;
	dashboardService: DashboardIframeStateService;
	embedUrlPrefix: any;
}

interface DashboardDivProps {
	isHidden: boolean;
}

const DashboardContent = styled.div<DashboardDivProps>`
	width: 100%;
	height: calc(100% - 10px);
	visibility: ${props => (props.isHidden ? 'hidden' : 'visible')};
`;

const DashboardIframe: FC<DashboardIframeProps> = props => {
	const { dashboardService, embedUrlPrefix, isHidden } = props;

	// compose the embed url by the hostname (either localhost/production/otherwise)
	// and request lookerizer for a signed url, this should happen only once in the lifetime
	// of this component, when a user wishes to move to another dashboard we shouldn't do calls to lookerizer
	let embedUrl = undefined;
	if (embedUrlPrefix) {
		const embedDomainPort = window.location.port ? `:${window.location.port}` : '';
		const embedDomain = `${window.location.protocol}//${window.location.hostname}${embedDomainPort}`;
		const embedUrlSuffix: string = `?embed_domain=${embedDomain}&sdk=2`;
		embedUrl = embedUrlPrefix.concat(embedUrlSuffix);
	}
	const { loading, data: dashboardUrl } = useGetUrlForDashboardQuery({
		skip: !embedUrl,
		variables: { embedUrl }
	});

	useEffect(() => {
		if (dashboardUrl && !loading) {
			loadDashboardWithEmbedUrl(dashboardUrl, dashboardService);
		}
	}, [dashboardUrl, loading, dashboardService]);

	// must pass key to dashboardContent for the iframe to refresh properly:
	// https://stackoverflow.com/questions/48830316/how-do-you-reload-an-iframe-with-reactjs
	return (
		<>
			<DashboardContent key={embedUrl} id={LOOKER_SDK.APPENDED_ID} isHidden={isHidden} />
		</>
	);
};

// this function must run only once when arriving to analytics tab, it's responsible on authenticating
// and setting communication with the iframe of looker, from there we use event emitters to change the contents of the iframe
// including the dashboard shown
const loadDashboardWithEmbedUrl = (
	dashboardUrl: any,
	dashboardService: DashboardIframeStateService
) => {
	LookerEmbedSDK.createDashboardWithUrl(dashboardUrl.me.embedUrlForDashboard)
		.appendTo('#dashboardContent')
		.on('dashboard:filters:changed', function(this: LookerEmbedDashboard, event: DashboardEvent) {
			dashboardService.onFilterChanged(this, event);
		})
		.on('dashboard:run:complete', function(this: LookerEmbedDashboard, event: DashboardEvent) {
			dashboardService.onDashboardRunComplete(this, event);
		})
		.withClassName('DashboardStyle')
		.build()
		.connect()
		.then((dashboard: LookerEmbedDashboard) => {
			// set the instance to be able to load different dashboard inside the same iframe
			// it shouldn't be coupled with the events though to prevent race conditions
			dashboardService.setDashboardInstance(dashboard);
		})
		.catch(error => {
			console.error('An unexpected error occurred', error);
		});
};

export default DashboardIframe;
