import * as React from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';
import Images from '../../shared/images';
import { SymbologyCompany, DropDownItem } from '../../types/types';
import * as ids from '../../../id.constants.js';
import { Colors } from '../../../components/shared/styles';

const MAX_SUGGESTIONS = 100;
const MAX_WATCHLIST_SUGGESTIONS = 2;
const SEARCH_ICON_WIDTH = 26;
const UPPER_SECTION_HEIGHT = 90;
const WRAP_Y_POISTION_IN_UPPER_SECTION_MP = 42;
const WRAP_X_POISTION_IN_UPPER_SECTION = 20; // padding + margin

interface GeneralElementProps {
	inAutoCompleteWithResults: boolean;
}

interface LineProps {
	inAutoCompleteWithResults: boolean;
}

const Line = styled.div<LineProps>`
	height: 1px;
	position: relative;
	top: ${props => (props.inAutoCompleteWithResults ? '-3px' : '5px')};
	transition: border-bottom-color 0.3s;
	border-bottom: ${props =>
		props.inAutoCompleteWithResults ? '1px solid #E1E8EA' : '1px solid #f4f7f7'};
	:focus {
		border-bottom: 1px solid ${Colors.blue};
	}
`;

const FormWrap = styled.div<GeneralElementProps>`
	display: ${props => (props.inAutoCompleteWithResults ? 'block' : 'inline-block')};
	position: relative;
	height: ${props => (props.inAutoCompleteWithResults ? '40px' : '32px')};
	border: ${props => (props.inAutoCompleteWithResults ? '1px solid #E1E8EA' : 'none')};
	border-bottom: ${props =>
		props.inAutoCompleteWithResults ? '1px solid #E1E8EA' : '1px solid #f4f7f7'};
	border-radius: 2px;
	padding-left: 1px;
	box-sizing: border-box;
	width: 100%;
	min-width: 310px;
	max-width: ${props => (props.inAutoCompleteWithResults ? 'initial' : '460px')};
	opacity: initial;
	pointer-events: auto;
	:hover ${Line} {
		border-bottom: 1px solid #c4c4c4;
	}
`;

interface InputProps {
	clearBtnImg: string;
	inAutoCompleteWithResults: boolean;
}
const Input = styled.input<InputProps>`
	display: inline-block;
	position: ${props => (props.inAutoCompleteWithResults ? 'relative' : 'relative')};
	top: ${props => (props.inAutoCompleteWithResults ? '-2px' : 'initial')};
	width: calc(100% - ${SEARCH_ICON_WIDTH}px);
	height: 77%;
	background-color: unset;
	box-sizing: border-box;
	padding: 0 7px;
	padding-left: ${props => (props.inAutoCompleteWithResults ? '10px' : '7px')};
	border: ${props => (props.inAutoCompleteWithResults ? 'initial' : 'none')};
	border-right: ${props =>
		!props.inAutoCompleteWithResults && props.value ? '1px solid #DDE5E7' : ''};
	border-width: initial;
	border-style: none;
	border-color: initial;
	border-image: initial;
	outline: 0px !important;
	color: ${props => (props.inAutoCompleteWithResults ? 'initial' : '#42445D')};
	font-family: ${props => (props.inAutoCompleteWithResults ? 'unset' : 'Assistant')};
	font-size: ${props => (props.inAutoCompleteWithResults ? '14px' : '16px')};
	text-overflow: ellipsis;

	&::-webkit-input-placeholder {
		height: ${props => (props.inAutoCompleteWithResults ? '18px' : '21px')};
		width: ${props => (props.inAutoCompleteWithResults ? '187px' : '207px')};
		color: ${props => (props.inAutoCompleteWithResults ? '#9B9B9B' : 'rgba(66,68,93,0.6)')};
		font-size: ${props => (props.inAutoCompleteWithResults ? '14px' : '16px')};
		line-height: ${props => (props.inAutoCompleteWithResults ? '18px' : 'normal')};
	}
	&::-webkit-search-cancel-button {
		position:relative;
		right: 2px;
		-webkit-appearance: none;
		height: 16px;
		width: 16px;
		cursor: pointer;
		background-image: url("${props => props.clearBtnImg}");
		background-repeat: no-repeat;
  	background-size: 10px 10px;
	}
	:focus + ${Line}{
		border-bottom: 1px solid ${Colors.blue};
	}
`;

const ImgContainer = styled.div<GeneralElementProps>`
	display: inline-block;
	position: relative;
	box-sizing: border-box;
	top: ${props => (props.inAutoCompleteWithResults ? '2px' : '3px')};
	padding-top: ${props => props.inAutoCompleteWithResults && '8px'};
	height: ${props => (props.inAutoCompleteWithResults ? '35px' : 'auto')};
`;

const SearchIcon = styled.img`
	margin: 0 auto;
	cursor: initial;
`;

const Filters = styled.div<GeneralElementProps>`
	position: absolute;
	background: ${props => (props.inAutoCompleteWithResults ? 'rgb(250, 250, 250)' : '#FFF')};
	top: ${props =>
		props.inAutoCompleteWithResults
			? '43px'
			: `calc(${UPPER_SECTION_HEIGHT}px - ${WRAP_Y_POISTION_IN_UPPER_SECTION_MP}px)`};
	width: ${props =>
		props.inAutoCompleteWithResults
			? '328px'
			: `calc(100% + ${WRAP_X_POISTION_IN_UPPER_SECTION}px - 1px)`};
	left: ${props => props.inAutoCompleteWithResults && '1px'};
	height: ${props => (props.inAutoCompleteWithResults ? '250px' : 'auto')};
	padding-top: 8px;
	z-index: 2;
	min-height: 39px;
	box-shadow: ${props =>
		props.inAutoCompleteWithResults
			? 'rgba(0, 0, 0, 0.12) 0px 0px 8px 0px, rgba(0, 0, 0, 0.11) 0px 8px 8px 0px'
			: '0 4px 8px -2px rgba(0, 0, 0, 0.24)'};
	font-family: Assistant;
	max-height: 290px;
	overflow-x: hidden;
	overflow-y: auto;
	padding-bottom: 8px;
	border-radius: 0px 0px 6px 6px;
`;

const NoRes = styled.div`
	padding: 9px 20px;
	font-size: 16px;
	color: #4a4a4a;
`;

interface FilterRowProps {
	isSelected?: boolean;
	inAutoCompleteWithResults: boolean;
}

const FilterRow = styled.div<FilterRowProps>`
	min-height: ${props => (props.inAutoCompleteWithResults ? '20px' : '45px')};
	position: relative;
	color: rgba(50, 49, 59, 0.87);
	border: 1px solid #66339900;
	box-sizing: border-box;
	padding-bottom: 4px;
	background-color: ${props => (props.isSelected ? '#EEEEEE' : 'none')};
	&:hover:after {
		content: '';
		background-color: #eee;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
	}
	&:hover,
	&.focused {
		background-color: ${props =>
			props.inAutoCompleteWithResults ? 'rgb(238, 238, 238)' : '#EEF1F5'};
		cursor: pointer;
		border-width: ${props => (props.inAutoCompleteWithResults ? '1px 1px 1px 3px' : '1px')};
		border-color: ${props =>
			props.inAutoCompleteWithResults
				? 'rgba(102, 51, 153, 0) rgba(102, 51, 153, 0) rgba(102, 51, 153, 0) rgb(0, 174, 239)'
				: '#66339900'};
		border-style: solid;
		border-image: initial;
		> div {
			padding-left: ${props => (props.inAutoCompleteWithResults ? '9px' : '47px')};
		}
	}
	&.disabled {
		opacity: 0.3;
		pointer-events: none;
	}
`;
const FilterRowText = styled.div<GeneralElementProps>`
	font-size: ${props => (props.inAutoCompleteWithResults ? '13px' : '16px')};
	padding-left: ${props => (props.inAutoCompleteWithResults ? '11px' : '47px')};
	padding-top: ${props => (props.inAutoCompleteWithResults ? '5px' : '12px')};
	line-height: 1em;
`;

const DropdownSection = styled.div`
	&:not(:first-child) {
		border-top: 1px solid #e0e7e9;
		padding-top: 12px;
		margin-top: 2px;
	}
`;

const DropdownSectionTitle = styled.div`
	text-transform: uppercase;
	color: #32313b;
	font-size: 12px;
	padding: 7px 16px;
`;

export interface AutoCompleteDropDownProps {
	placeholder: string;
	onSearch: Function;
	onSelect: Function;
	isDropdownOpen: boolean;
	resultsLoaded: boolean;
	results: {
		inPortfolio: SymbologyCompany[];
		notInPortfolio: SymbologyCompany[];
	};
	inAutoCompleteWithResults?: boolean;
	closeDropdown?: Function;
	focusedIdx?: number;
	onFocus?: Function;
	itemsChosen?: DropDownItem[];
}

export default class SymbologyAutoCompleteDropDown extends React.Component<
	AutoCompleteDropDownProps
> {
	inputElement: HTMLInputElement;
	formWrap: HTMLDivElement;

	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		if (this.props.inAutoCompleteWithResults) {
			this.subscribeToEvents();
		}
	}

	componentWillUnmount() {
		if (this.props.inAutoCompleteWithResults) {
			this.unsubscribeFromEvents();
		}
	}

	handleKeyUp = event => {
		this.props.onSearch(event.target.value);
	};

	subscribeToEvents = () => {
		window.onkeyup = e => {
			const key = e.keyCode ? e.keyCode : e.which;
			if (key === 38) {
				if (this.props.focusedIdx > 0) {
					this.props.onFocus(this.props.focusedIdx - 1);
				}
			} else if (key === 40) {
				if (this.props.focusedIdx < this.props.results.notInPortfolio.length - 1) {
					this.props.onFocus(this.props.focusedIdx + 1);
				}
			} else if (key === 13) {
				const company = this.props.results.notInPortfolio[this.props.focusedIdx];
				this.onSelection(company, false);
			} else if (key === 32) {
				// prevent default when clicking space - it causes unmount on some cases
				e.preventDefault();
			}
		};

		document.addEventListener('mousedown', this.handleClick, true);
	};

	unsubscribeFromEvents = () => {
		window.onkeyup = undefined;
		document.removeEventListener('mousedown', this.handleClick, true);
	};

	onSelection = (company: SymbologyCompany, shouldAddToPortfolio: boolean) => {
		this.props.onSelect(company, shouldAddToPortfolio);
		this.inputElement.value = '';
	};

	createDropdownElement = (
		company: SymbologyCompany,
		inPortfolio: boolean,
		inAutoCompleteWithResults: boolean,
		isFocused: boolean,
		isDisabled: boolean
	) => {
		const { name, ticker, region } = company;
		let displayText = name;
		if (ticker && ticker.length > 0) {
			displayText += ` (${ticker}${region ? `:${region}` : ''})`;
		}
		return (
			<FilterRow
				key={`${company.name}_${company.id}_row`}
				onMouseDown={() => this.onSelection(company, !inPortfolio)}
				id={`PORTFOLIO_SEARCH_RESULT_ROW_${company.id}`}
				inAutoCompleteWithResults={inAutoCompleteWithResults}
				className={isDisabled ? 'disabled' : isFocused ? 'focused' : ''}
			>
				<FilterRowText inAutoCompleteWithResults={inAutoCompleteWithResults}>
					{displayText}
				</FilterRowText>
			</FilterRow>
		);
	};

	closeDropDown = () => this.props.closeDropdown && this.props.closeDropdown();

	handleClick = e => {
		if (this.formWrap && this.formWrap.contains(e.target)) {
			return;
		}
		this.closeDropDown();
	};

	renderResults = (
		results: {
			inPortfolio: SymbologyCompany[];
			notInPortfolio: SymbologyCompany[];
		},
		resultsLoaded: boolean,
		inAutoCompleteWithResults: boolean,
		focusedIdx: number,
		itemsChosen: DropDownItem[]
	) => {
		const { inPortfolio, notInPortfolio } = results;
		const inPortfolioElements = _.map(
			inPortfolio.slice(0, MAX_WATCHLIST_SUGGESTIONS),
			(c: SymbologyCompany) =>
				this.createDropdownElement(c, true, inAutoCompleteWithResults, false, false)
		);
		const notInPortfolioElements = _.map(
			notInPortfolio.slice(0, MAX_SUGGESTIONS),
			(c: SymbologyCompany, idx: number) => {
				const isDisabled = _.some(itemsChosen, (_c: any) => _c.id === c.id);
				return this.createDropdownElement(
					c,
					false,
					inAutoCompleteWithResults,
					idx === focusedIdx,
					isDisabled
				);
			}
		);
		if (resultsLoaded && inPortfolioElements.length === 0 && notInPortfolioElements.length === 0) {
			return (
				<Filters inAutoCompleteWithResults={inAutoCompleteWithResults}>
					<NoRes>No results found, Please try searching by ticker (Format: AAPL:US)</NoRes>
				</Filters>
			);
		}
		return (
			<Filters inAutoCompleteWithResults={inAutoCompleteWithResults}>
				{inPortfolioElements && inPortfolioElements.length ? (
					<div>
						{!inAutoCompleteWithResults ? (
							<DropdownSectionTitle
								id={ids.WACTHLIST_PAGE.AUTOCOMPLETE_DROPDOWN_SEARCH_BAR_RESULTS_IN_YOUR_WATCHLIST}
							>
								results in your watchlist
							</DropdownSectionTitle>
						) : (
							undefined
						)}
						{inPortfolioElements}
					</div>
				) : (
					undefined
				)}
				{notInPortfolioElements && notInPortfolioElements.length ? (
					<DropdownSection>
						{!inAutoCompleteWithResults ? (
							<DropdownSectionTitle>all companies</DropdownSectionTitle>
						) : (
							undefined
						)}
						{notInPortfolioElements}
					</DropdownSection>
				) : (
					undefined
				)}
			</Filters>
		);
	};

	render() {
		const {
			placeholder,
			isDropdownOpen,
			resultsLoaded,
			results,
			inAutoCompleteWithResults,
			focusedIdx,
			itemsChosen
		} = this.props;
		return (
			<FormWrap
				id={ids.WACTHLIST_PAGE.DIV_SEARCH_COMPANY_NAME_OR_TICKER}
				ref={node => (this.formWrap = node)}
				inAutoCompleteWithResults={inAutoCompleteWithResults}
			>
				<ImgContainer inAutoCompleteWithResults={inAutoCompleteWithResults}>
					{inAutoCompleteWithResults ? (
						<SearchIcon src={Images.searchIconBlue3} alt='search icon' />
					) : (
						<SearchIcon src={Images.searchIconBlack} alt='search icon' />
					)}
				</ImgContainer>
				<Input
					ref={node => (this.inputElement = node)}
					autoComplete='off'
					type='search'
					name='search'
					placeholder={placeholder}
					onChange={this.handleKeyUp}
					clearBtnImg={Images.close}
					id='PORTFOLIO_SEARCH_FIELD'
					inAutoCompleteWithResults={inAutoCompleteWithResults}
				/>
				<Line inAutoCompleteWithResults={inAutoCompleteWithResults} />
				{isDropdownOpen &&
					this.renderResults(
						results,
						resultsLoaded,
						inAutoCompleteWithResults,
						focusedIdx,
						itemsChosen
					)}
			</FormWrap>
		);
	}
}
