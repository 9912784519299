import * as React from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';
import Images from '../../shared/images';
import { DocumentTypeLegacy } from '../../types/DocTypes.types';
import { Colors } from '../../../components/shared/styles';
import { inject, observer } from 'mobx-react';
import { STORE_AUTH, STORE_DATA, STORE_FEATURES, STORE_GLOBAL, STORE_UI } from '../../constants';
import DataStore from '../../stores/data.store';

const Container = styled.div`
	height: 100%;
	width: 100%;
`;
const TitleText = styled.div`
	color: ${Colors.dark};
	font-family: Assistant;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	font-size: 18px;
`;

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
	padding-left: 18px;
`;

const BodyWrap = styled.div`
	height: calc(100% - 80px);
	overflow-y: auto;
`;

const CategoryHeader = styled.div`
	position: relative;
	height: 30px;
	line-height: 28px;
	border-bottom: 2px solid #ebeff1;
	box-sizing: border-box;
	cursor: pointer;
`;

interface CategoryItemProps {
	isOpen: boolean;
}
const CategoryItem = styled.div<CategoryItemProps>`
	height: ${props => (props.isOpen ? 'auto' : '30px')};
	background-color: ${Colors.white};
	box-sizing: border-box;
	overflow: hidden;
	transition: height 0.5s;
	margin-bottom: 20px;

	:(last-child) {
		margin-bottom: 0;
		padding-bottom: 10px;
	}
`;

const Center = styled.div`
	position: relative;
	width: calc(100% - 36px);
	height: 100%;
	margin: 0 auto;
`;

interface ArrowProps {
	isOpen: boolean;
	myUrl: string;
}
const Arrow = styled.div<ArrowProps>`
	position: absolute;
	top: 11px;
	right: 0px;
	width: 10px;
	height: 7px;
	transform: ${props => (props.isOpen ? 'rotate(-180deg)' : '')};
	mask-image: url(${props => props.myUrl});
	mask-size: contain;
	mask-position: center;
	-webkit-mask-repeat: no-repeat;
	background-color: ${props => (props.isOpen ? Colors.blue : Colors.darkGray)};
	transition: transform 0.5s, background-color 0.5s;
`;

interface ItemsContainerProps {
	show: boolean;
}
const ItemsContainer = styled.div<ItemsContainerProps>`
	background-color: ${Colors.white};
	opacity: ${props => (props.show ? 1 : 0)};
	transition: opacity 0.5s;
`;

const Item = styled.div`
	padding-top: 16px;
	&:first-child {
		padding-top: 13px;
	}
`;

interface ItemTextProps {
	selected: boolean;
}
const ItemText = styled.div<ItemTextProps>`
	color: ${props => (props.selected ? Colors.blue : Colors.dark)};
	font-weight: ${props => (props.selected ? '600' : 'none')};
	font-family: Assistant;
	font-size: 15px;
	cursor: pointer;

	:hover {
		color: ${Colors.blue};
	}
`;

const CloseIcon = styled.div`
	display: block;
	padding: 10px;
	margin-right: 15px;
	cursor: pointer;
	background-color: ${Colors.blue};
	-webkit-mask-image: url(${Images.closeWhite});
	-webkit-mask-position: center;
	-webkit-mask-repeat: no-repeat;
	-webkit-mask-size: 11px;
`;

interface TextProps {
	fontFamily?: string;
	fontSize?: number;
}
const Text = styled.div<TextProps>`
	color: ${props => props.color};
	font-family: ${props => (props.fontFamily ? props.fontFamily : 'Assistant')};
	font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '')};
`;

enum Category {
	Transcripts,
	Sec
}

interface ViewerSubMenuProps {
	title: string;
	closeSideBar: () => void;
}

interface ViewerSubMenuState {
	isTranscriptsCategoryOpen: boolean;
	isSecCategoryOpen: boolean;
}

@inject(STORE_GLOBAL, STORE_AUTH, STORE_DATA, STORE_UI, STORE_FEATURES)
@observer
export default class ViewerSubMenu extends React.Component<ViewerSubMenuProps, ViewerSubMenuState> {
	constructor(props: ViewerSubMenuProps) {
		super(props);

		this.state = {
			isTranscriptsCategoryOpen: true,
			isSecCategoryOpen: true
		};
	}

	toggleCategory = (categoryType: number, currentState: boolean) => {
		const updatedState = !currentState;
		if (categoryType === Category.Transcripts) {
			this.setState({ isTranscriptsCategoryOpen: updatedState });
		} else {
			this.setState({ isSecCategoryOpen: updatedState });
		}
	};

	categoryHasEnabledTypes = (category: any) => _.some(category.types, t => t.enabled);

	handleDocumentTypeChange = (
		currentDocumentType: DocumentTypeLegacy,
		clickedDocumentType: DocumentTypeLegacy
	) => {
		if (currentDocumentType.API_ID === clickedDocumentType.API_ID) {
			return;
		}

		(this.props[STORE_DATA] as DataStore).handleDocumentTypeChange(clickedDocumentType);
	};

	renderCategory = (
		category: any,
		currentDocumentType: DocumentTypeLegacy,
		isCategoryOpen: boolean,
		categoryType: number,
		onClickDocumentType: (type: DocumentTypeLegacy) => void
	): any =>
		this.categoryHasEnabledTypes(category) && (
			<CategoryItem isOpen={isCategoryOpen}>
				<CategoryHeader
					onClick={() => this.toggleCategory(categoryType, isCategoryOpen)}
					id={`VIEWER_SUB_MENU_TOP_CATEGORY_TYPE_${categoryType}`}
				>
					<Center>
						<Text fontFamily={'Assistant SemiBold'} fontSize={16} color={Colors.dark}>
							{category.name}
						</Text>
						<Arrow myUrl={Images.arrowDownGrey} isOpen={isCategoryOpen} />
					</Center>
				</CategoryHeader>
				<ItemsContainer show={isCategoryOpen}>
					<Center>
						{_.map(category.types, type => {
							const selected = currentDocumentType && type.API_ID === currentDocumentType.API_ID;
							if (type.enabled) {
								return (
									<Item
										key={`${type.API_ID}_${type.NAME}`}
										onClick={() => onClickDocumentType(type)}
										id={`VIEWER_SUB_MENU_API_ID_${type.API_ID}`}
									>
										<ItemText id={`VIEWER_SUB_MENU_ITEM_TEXT_${type.NAME}`} selected={selected}>
											{type.NAME}
										</ItemText>
									</Item>
								);
							}
						})}
					</Center>
				</ItemsContainer>
			</CategoryItem>
		);

	render() {
		const { title, closeSideBar } = this.props;
		const { isTranscriptsCategoryOpen, isSecCategoryOpen } = this.state;
		const { documentTypes, currentDocumentType } = this.props[STORE_DATA] as DataStore;
		const onClickDocumentType = (clickedDocumentType: DocumentTypeLegacy) =>
			this.handleDocumentTypeChange(currentDocumentType, clickedDocumentType);

		return (
			<Container>
				<TitleWrap>
					<TitleText>{title}</TitleText>
					<CloseIcon onClick={closeSideBar} id='SIDEBAR_TAB_CLOSE_ICON' />
				</TitleWrap>
				<BodyWrap>
					{documentTypes &&
						this.renderCategory(
							documentTypes.transcripts,
							currentDocumentType,
							isTranscriptsCategoryOpen,
							Category.Transcripts,
							onClickDocumentType
						)}
					{documentTypes &&
						this.renderCategory(
							documentTypes.secFilings,
							currentDocumentType,
							isSecCategoryOpen,
							Category.Sec,
							onClickDocumentType
						)}
				</BodyWrap>
			</Container>
		);
	}
}
