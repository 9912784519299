import * as _ from 'lodash';
import moment from 'moment';
import { matchPath } from 'react-router';
import XLSX from 'xlsx';

import * as localStorageSrv from './localstorage.service';
import {
	EMAIL_VALIDATION_REGEX,
	GENERAL_CATEGORY_NAME,
	GENERAL_KEY_DRIVER_NAME,
	INVALID_VALUE,
	IS_ANSWER,
	LOCAL_STORAGE_KEYS,
	SCROLL_IDS,
	TRANSCRIPT_SECTIONS,
	USER_TRACKING as UT,
	WEB_SERVER_URL
} from '../constants';
import {
	DocumentType,
	DocumentTypeLegacy,
	DocumentTypeCategoryLegacy,
	SEC_SORT_LEGACY,
	TRANSCRIPT_SORT_LEGACY
} from '../types/DocTypes.types';
import * as Types from '../types/types';
import { strToPolarity } from '../../components/shared/util';
import { PortfolioCompany } from '../types/types';

const UTC = UT.USER_TRACKING_CATEGORIES;

export const isPasswordStrong = (password: string) => {
	const regex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])');
	const match = password.match(regex) && password.match(regex).length > 0 && password.length > 7;
	return match === null ? false : match;
};

export const getInitCompany = (): Types.Company => {
	return {
		id: -1,
		name: '',
		region: '',
		ticker: '',
		companyIds: [],
		isInPortfolio: false
	};
};

export const getInitDocument = (): Types.Document => {
	return {
		documentId: -1,
		total_daily_negatives: -1,
		total_daily_positives: -1,
		total_daily_sentiment_score: INVALID_VALUE,
		title: '',
		analysisDate: undefined,
		publicationDate: undefined,
		retrievalDate: undefined,
		userEvents: [],
		systemEvents: [],
		allEvents: [],
		eventDate: undefined,
		vendor: '',
		deception_score: INVALID_VALUE,
		driver_totals: []
	};
};

// TODO:: unit test
export const parseTranscriptFromAPI = (
	t: any,
	vendor: string,
	flowId: number,
	documentType: string
): Types.Document => {
	return _.isEmpty(t)
		? undefined
		: new Types.Document(
				t.documentId,
				t.totalPositives,
				t.totalNegatives,
				t.sentimentScore,
				t.analysisDate,
				t.publicationDate,
				t.retrievalDate,
				t.title,
				[],
				[],
				[],
				t.eventDate,
				vendor,
				t.deception_score,
				t.driver_totals,
				flowId,
				documentType,
				t.fiscalQuarter,
				t.fiscalYear,
				t.documentDisplayName
		  );
};

export const parsePortfolio = (portfolio: any): Types.PortfolioCompany[] => {
	const res: Types.PortfolioCompany[] = portfolio;

	_.each(res, (c: Types.PortfolioCompany) => {
		const parsedTranscript: Types.Document[] = [];
		_.each(c.documents, t => {
			parsedTranscript.push({ ...t, userEvents: [], systemEvents: [] });
		});
		c.documents = parsedTranscript;
		c.latestDocument = getLatestTranscript(parsedTranscript);
		c.latestDocument['amenity_score_change'] = calcCompanySentimentChange(c.documents).change; // should add type
		c.latestDocument['deception_score_change'] = calcCompanySentimentChange(
			c.documents,
			'deception_score'
		).change;
	});

	return res;
};

export const getDomainFromEmail = (email: string): string =>
	email && email.indexOf('@') !== -1 ? email.split('@')[1].split('.')[0] : '';

export const capitalizeFirstLetter = string =>
	string[0].toUpperCase() + string.substr(1, string.length - 1);

export const getLatestTranscript = (transcripts: Types.Document[]): Types.Document => {
	if (transcripts.length === 0) {
		return getInitDocument();
	}
	return _.maxBy(transcripts, t => (new Date(t.eventDate) ? t.eventDate : t.publicationDate));
};

// TODO : fix this, add user and which api enviroment to saved flow in local storage
export const getCurrentFlow = (
	documentType: DocumentTypeLegacy,
	modelsFlow: Types.ModelFlow[]
): Types.ModelFlow => {
	const currentFlowWrap = localStorageSrv.getObject(LOCAL_STORAGE_KEYS.CURRENT_FLOW);

	if (currentFlowWrap) {
		const legalFlow = _.find(modelsFlow, flow => flow.id === currentFlowWrap.flow.id);
		if (legalFlow) {
			return legalFlow;
		} else {
			localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.CURRENT_FLOW);
		}
	}
	return getDefaultFlowForDocumentType(documentType, modelsFlow);
};

export const getDefaultFlowForDocumentType = (
	documentType: DocumentTypeLegacy,
	flows: Types.ModelFlow[]
): Types.ModelFlow => {
	const filteredFlows = filterFlowsByDocumentType(documentType, flows);
	return filteredFlows[0];
};

export const getCurrentDocumentType = (
	documentTypesObject: any,
	onNotFound?: Function
): DocumentTypeLegacy => {
	const secTypes = documentTypesObject?.secFilings?.types || [];
	const transcriptTypes = documentTypesObject?.transcripts?.types || [];
	const documentTypes = _.concat([...secTypes], [...transcriptTypes]);

	const currentDocumentTypeLocalStorage = localStorageSrv.getObject(
		LOCAL_STORAGE_KEYS.CURRENT_DOCUMENT_TYPE
	);

	if (!_.isEmpty(currentDocumentTypeLocalStorage)) {
		const legalDocumentType = _.find(
			documentTypes,
			(dt: DocumentTypeLegacy) => dt.API_ID === currentDocumentTypeLocalStorage.API_ID
		);
		if (legalDocumentType && legalDocumentType.enabled) {
			return legalDocumentType;
		} else {
			localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.CURRENT_DOCUMENT_TYPE);
		}
	}

	const enabledDocumentTypes: DocumentTypeLegacy[] = _.filter(
		documentTypes,
		(dt: DocumentTypeLegacy) => dt.enabled
	);

	// Look for the first doc type in sort which is enabled
	let res = _.find(
		TRANSCRIPT_SORT_LEGACY,
		t => _.findIndex(enabledDocumentTypes, dt => dt.API_ID === t.API_ID) !== -1
	);
	if (res) {
		return res;
	} // Found first in transcript types

	// If didn't find in transcript - look in SEC types - must find here
	res = _.find(
		SEC_SORT_LEGACY,
		s => _.findIndex(enabledDocumentTypes, dt => dt.API_ID === s.API_ID) !== -1
	);
	if (!res) {
		onNotFound && onNotFound();
	}
	return res;
};

export const parseFlow = (modelFlow: Types.ModelFlow): any => {
	if (!modelFlow) {
		return {
			title: '',
			subtitle: '',
			id: -1
		};
	}

	return {
		title: modelFlow.modelName,
		providerName:
			modelFlow.dataSourcesDetails &&
			modelFlow.dataSourcesDetails[0] &&
			modelFlow.dataSourcesDetails[0].providerName
				? modelFlow.dataSourcesDetails[0].providerName
				: '',
		displayName: modelFlow.modelDisplayName ? modelFlow.modelDisplayName : '',
		subtitle: `NLP model name: ${modelFlow.modelName}, Data Flow Identifier: ${modelFlow.id}, Version ${modelFlow.modelVersion}`,
		id: modelFlow.id,
		creationDate: modelFlow.startDate ? moment(modelFlow.startDate).format('M/D/YYYY') : ''
	};
};

export const getPolarityIndex = (polarity: Types.Polarity): number => {
	let polarityIndex: number = -1;

	switch (polarity) {
		case Types.Polarity.negative:
			polarityIndex = 0;
			break;
		case Types.Polarity.positive:
			polarityIndex = 1;
			break;
		case Types.Polarity.neutral:
			polarityIndex = 2;
			break;
	}

	return polarityIndex;
};

export const polarityToString = (polarity: number) => {
	switch (polarity) {
		case Types.Polarity.negative:
			return 'Negative';
		case Types.Polarity.positive:
			return 'Positive';
		case Types.Polarity.neutral:
			return 'Neutral';
	}
};

export const getStringWithoutSubString = (
	str: string,
	subStr: string
): { start: string; end: string } => {
	/**
	 * Below line was kept after conflict resolve between master and dev.
	 * Code in master was newer - there was a hotfix here. kept for just in case - M.K. 10.3.19
	 * if (!str || !subStr || str.search(subStr) === -1) return { start: '', end: '' };
	 */
	if (!str || !subStr || !str.includes(subStr)) {
		return { start: '', end: '' };
	}

	const strComponents = str.split(subStr);
	// we use the shift and join methods to ensure only one splitting of the str.
	// that way, (startOfStr + subStr + endOfStr = str) will apply at more than one subStr occurrences in str.
	let startOfStr = strComponents.shift(); // first element in array
	let endOfStr = strComponents.join(subStr); // all others elements. in case of more than one - joined with subStr
	// in case highlightedTextInSentence is actually all of the sentence set start and end as empty str.
	if (startOfStr === subStr) {
		startOfStr = endOfStr = '';
	}
	return { start: startOfStr, end: endOfStr };
};

export const parseCompany = (company: Types.Company): Types.DropDownItem => {
	if (!company) {
		return {
			title: '',
			id: -1
		};
	}

	return {
		title: getFullCompanyName(company),
		id: company.id,
		searchItem: company.ticker
	};
};

export const getFullCompanyName = (company: Types.Company): string => {
	let ticker, name, region;

	name = company.name || '';
	ticker = company.ticker || '';
	region = company.region ? company.region + ':' : '';

	// if (company.companyIds && company.companyIds.length === 1) {
	// 	return `${name} - ${company.companyIds[0].providerDisplayName}`;
	// }

	if (ticker) {
		return `${name} (${region}${ticker})`;
	}
	return name;
};

export const getCompaniesFromPortfolio = (portfolio: Types.PortfolioCompany[]): Types.Company[] =>
	_.map(portfolio, c => {
		return {
			id: c.id,
			companyIds: c.companyIds,
			name: c.name,
			ticker: c.ticker,
			region: c.region,
			factsetId: c.factsetId
		};
	});

export const parseEvent = (event: Types.Event): Types.DropDownItem => {
	if (!event) {
		return {
			title: '',
			id: ''
		};
	}

	return {
		title: getEventName(event),
		id: event.id
	};
};

export const getEventName = (event: Types.Event): string => `${event.typeName} - ${event.text}`;

export const findFilteredSimilarityInfoOfEvent = (
	similarityInfo: Types.SimilarEventsInfo[],
	event: any,
	isTranscript: boolean
): Types.SimilarEventsInfo => {
	let similarityInfoOfEvent;
	if (isTranscript) {
		similarityInfoOfEvent = _.find(
			similarityInfo,
			infoOfEvent => infoOfEvent.searchEntry.offsetStartInText === event.startIndex
		);
	} else {
		// If sequenceNumber field is not -1 - find by it - it should be equal to the hash from the embedded event in doc.
		// Else, find by text
		similarityInfoOfEvent = _.find(similarityInfo, (infoOfEvent: Types.SimilarEventsInfo) => {
			if (infoOfEvent.searchEntry.sequenceNumber && infoOfEvent.searchEntry.sequenceNumber !== -1) {
				return infoOfEvent.searchEntry.sequenceNumber === +event.hash;
			}
			return infoOfEvent.searchEntry.eventText.trim() === event.text.trim();
		});
	}

	// if no similarityInfoOfEvent or is neutral return undefined.
	if (
		!similarityInfoOfEvent ||
		strToPolarity(similarityInfoOfEvent.searchEntry.polarity) === Types.Polarity.neutral
	) {
		return;
	}
	// filter out neutral events.
	const filteredResultEntries = _.filter(similarityInfoOfEvent.resultEntries, entry => {
		const polarity = strToPolarity(entry.flatReportEntry.polarity);
		return polarity !== Types.Polarity.neutral;
	});
	return {
		searchEntry: similarityInfoOfEvent.searchEntry,
		resultEntries: filteredResultEntries
	};
};

// TODO: tests
export const parseSimilarityInfoOfEvent = (similarityInfoOfEvent: Types.SimilarEventsInfo) => {
	let countOfSimilarEvents, similarityScore;
	if (similarityInfoOfEvent) {
		countOfSimilarEvents = similarityInfoOfEvent.resultEntries.length;
		const maxScoreEntry = _.maxBy(
			similarityInfoOfEvent.resultEntries,
			(entry: any) => entry.similarityScore
		);
		similarityScore = maxScoreEntry ? maxScoreEntry.similarityScore : undefined;
	}
	return {
		countOfSimilarEvents,
		similarityScore
	};
};

export const parseEventsNew = (
	systemEvents: Types.SystemEventContainerNew[],
	eventTypes: Types.EventType[],
	keyDriverModel?: boolean
): Types.Event[] =>
	_.reduce(
		systemEvents,
		(res, event) => {
			let categoryDescription;
			let categoryName: string;
			let keyDrivers: string[];
			const polarity = strToPolarity(event.polarity);

			if (keyDriverModel) {
				keyDrivers = formatKeyDriversIntoArray(event.keyDriver);
			} else {
				const category = getCategoryDataFromEventType(eventTypes, event.typeName);
				categoryName = category?.categoryName;
				categoryDescription = category?.documentViewDescription;
			}

			const parsedEvent = {
				...event,
				polarity,
				categoryName,
				documentViewDescription: categoryDescription,
				startIndexAPI: event.startIndex,
				endIndexAPI: event.endIndex,
				isUserDefined: false,
				description: '',
				keyDrivers
			};
			return [...res, parsedEvent];
		},
		[]
	);

export const getCategoryNameByEventType = (
	eventTypes: Types.EventType[],
	eventTypeName: string
): string => {
	const eventType: any = _.find(
		eventTypes,
		(et: Types.EventType) =>
			et.eventName && et.eventName.toLowerCase() === eventTypeName.toLowerCase()
	);

	const categoryName = eventType ? eventType.categoryName : GENERAL_CATEGORY_NAME;
	return categoryName;
};

export const getCategoryDataFromEventType = (
	eventTypes: Types.EventType[],
	eventTypeName: string
): { categoryName: string; documentViewDescription: string } => {
	const eventType: any = _.find(
		eventTypes,
		(et: Types.EventType) =>
			et.eventName && et.eventName.toLowerCase() === eventTypeName.toLowerCase()
	);
	let categoryName;
	let documentViewDescription;

	if (eventType) {
		categoryName = eventType.categoryName;
		documentViewDescription = eventType.documentViewDescription;
	} else {
		categoryName = GENERAL_CATEGORY_NAME;
		const otherCategory = _.find(
			eventTypes,
			(et: Types.EventType) => et.categoryName === GENERAL_CATEGORY_NAME
		);
		documentViewDescription = otherCategory ? otherCategory.documentViewDescription : '';
	}
	return { categoryName, documentViewDescription };
};

export const capitalizeString = (str: string): string => {
	return str
		.split(' ')
		.map(name => capitalizeFirstLetter(name.toLowerCase()))
		.join(' ');
};

// Relevant only for old Event types(from db)
export const setSystemEventsCategoryTypes = (
	systemEvents: Types.Event[],
	eventTypes: Types.EventType[]
): Types.Event[] => {
	const res: Types.Event[] = [];
	_.each(systemEvents, e => {
		const { categoryName, documentViewDescription } = getCategoryDataFromEventType(
			eventTypes,
			e.typeName
		);
		const event: Types.Event = { ...e, categoryName, documentViewDescription };
		res.push(event);
	});

	return res;
};

export const getUniqCategories = (events: Types.EventType[]): Types.Category[] => {
	const uniqCategories = _.uniqBy(events, e => e.categoryName).map(c => c.categoryName);

	return _.map(uniqCategories, (c: string, index: number) => {
		return {
			id: index,
			name: c,
			collection_id: -1
		};
	});
};

const getDetailsFromSpeaker = (speaker: Types.Speaker): Types.QNAElement => {
	return {
		id: speaker.speakingIndex,
		name: speaker.name,
		title: speaker.title,
		affiliation: speaker.affiliation,
		type: speaker.type
	};
};

export const formatQNASpeakers = (speakers: Types.Speaker[]): Types.QNA[] => {
	let answers: Types.QNAElement[] = [];
	let question: Types.QNAElement = undefined;
	let answer: Types.QNAElement;
	let index = 0;

	const result: Types.QNA[] = [];

	if (speakers.length === 0) {
		return result;
	}

	const speakersJSArray = speakers.slice();
	for (let i = 0; i < speakersJSArray.length; i++) {
		const speaker: Types.Speaker = speakersJSArray[i];
		if (speaker.type === null && speaker.name === 'Operator') {
			continue;
		}

		if (IS_ANSWER(speaker.type) || speaker.type === null) {
			answer = getDetailsFromSpeaker(speaker);
			answers.push(answer);
		} else {
			if (question !== undefined) {
				if (question.name !== 'Operator') {
					index += 1;
				}
				result.push({
					index,
					question: question,
					answerElements: answers
				});
				answers = [];
			}
			question = getDetailsFromSpeaker(speaker);
		}
	}

	result.push({
		index,
		question: question,
		answerElements: answers
	});

	return result;
};

export const getElementNavigationId = (elementTypeClicked: number, index: number): string => {
	let result = '';

	if (elementTypeClicked === SCROLL_IDS.SPEAKER.ID) {
		result = SCROLL_IDS.SPEAKER.NAME;
	} else if (elementTypeClicked === SCROLL_IDS.QNA.ID) {
		result = SCROLL_IDS.QNA.NAME;
	}

	result += index;
	return result;
};

export const getDiscussionSection = (sections: Types.Section[]): Types.Section =>
	_.find(
		sections,
		s =>
			s.name === TRANSCRIPT_SECTIONS.DISCUSSION_FACTSET ||
			s.name === TRANSCRIPT_SECTIONS.DISCUSSION_SNP
	);

export const getQNASection = (sections: Types.Section[]): Types.Section =>
	_.find(
		sections,
		s => s.name === TRANSCRIPT_SECTIONS.QNA_FACTSET || s.name === TRANSCRIPT_SECTIONS.QNA_SNP
	);

export const getInitFormattedText = (): Types.FormattedText => {
	return {
		date: new Date(),
		participants: [],
		sections: [],
		title: '',
		vendor: '',
		isEditedCopy: false
	};
};

export const calcCompanySentimentChange = (
	transcripts: Types.Document[],
	key = 'total_daily_sentiment_score'
): { change: number; changeFactor: number } => {
	const invalidValues = { change: INVALID_VALUE, changeFactor: INVALID_VALUE };
	if (transcripts.length <= 1) {
		return invalidValues;
	}
	const sorted = _.orderBy(transcripts, (t: Types.Document) => t.publicationDate, 'desc');
	// sorted[0] is the latest, the new. sorted[1] is the previous.
	const currentScore = sorted[0][key];
	const prevScore = sorted[1][key];
	if (prevScore === -1) {
		return invalidValues;
	}
	const change = currentScore - prevScore;
	let changeFactor;
	if (prevScore === currentScore) {
		changeFactor = 0;
	} else {
		changeFactor =
			key === 'deception_score'
				? currentScore / (prevScore || 1) - 1
				: (currentScore + 1) / (prevScore + 1) - 1;
	}
	return { change, changeFactor };
};

/**
 * Method to handle decimal numbers. If number is smaller than 0.01, it will be returned with 1 digit after
 * decimal point. Else, is rounded.
 * @param decimal - decimal to transform
 */
export const fractionToPercent = (decimal: number): number => {
	if (decimal === INVALID_VALUE) {
		return decimal;
	}
	let res = '0';
	if (Math.abs(decimal) < 0.01) {
		res = (decimal * 100).toFixed(1);
	} else {
		res = (decimal * 100).toFixed(0);
	}
	return parseFloat(res);
};

export const numToPercentString = (val: number): string =>
	val === INVALID_VALUE ? 'N/A' : val + '%';

export const isEventChanged = (updatedFields: any, currentEventData: Types.Event): boolean => {
	return (
		updatedFields.polarity !== currentEventData.polarity ||
		updatedFields.categoryName !== currentEventData.categoryName ||
		updatedFields.description !== currentEventData.description
	);
};

export const isEmailValid = (email: string): boolean =>
	EMAIL_VALIDATION_REGEX.test(email.toLowerCase());

export const eventEqualsSearchedEvent = (
	event: Types.Event,
	searchedEvent: Types.SearchedEventForDocument
): boolean => event.text === searchedEvent.eventText;

export const limitArraysByLength = (arrayOfArrays: any[], limit: number) => {
	if (!limit || limit === 0) {
		return arrayOfArrays;
	}
	let remainder = [];
	return _.map(arrayOfArrays, array => {
		if (remainder.length > 0) {
			array = remainder.concat(array);
			remainder.splice(0, remainder.length);
		}
		if (array.length <= limit) {
			return array;
		}
		remainder = array.splice(limit, array.length - limit);
		return array;
	});
};

/**
 * cut provided string after maxChars, and append 3 dots. This is in order to make
 * ellipsis with more than 1 line
 * @param text - string to cut
 * @param maxChars - max allowed chars for string
 */
export const customEllipsis = (text: string, maxChars: number): string => {
	if (text.length < maxChars) {
		return text;
	}
	return text.substring(0, maxChars) + '...';
};

export const getParamsFromURL = (url: string, multipleValues: boolean = false): any => {
	const regex = /(&amp;|&|\?)([^=#]+)=([^&#]*)/g,
		params = {};
	let match = regex.exec(url);
	while (match) {
		if (multipleValues && params[match[1]]) {
			params[match[2]] = _.castArray(params[match[3]]);
			params[match[2]].push(match[3]);
		} else {
			params[match[2]] = match[3];
		}
		match = regex.exec(url);
	}
	return params;
};

export const getTabUnderlineLeftRight = (
	isLeft: boolean,
	isSelected: boolean,
	tabUnderLineMargin: number
): { left: string; right: string } => {
	let res: any = {};
	if (isLeft) {
		if (isSelected) {
			res = { left: `${tabUnderLineMargin}px`, right: '0' };
		} else {
			res = { left: '100%', right: '0' };
		}
	} else {
		if (isSelected) {
			res = { left: '0', right: `${tabUnderLineMargin}px` };
		} else {
			res = { left: '0', right: '100%' };
		}
	}
	return res;
};

export const parseTenKEvents = (
	eventTypes: Types.EventType[],
	tenKEventsAPI: any[],
	isKeyDriverModel: boolean
): Types.OutboundEvent[] => {
	const tenKEvents: Types.OutboundEvent[] = _.map(tenKEventsAPI, (event: any) => {
		let keyDrivers: string[];
		let categoryName: string;
		let documentViewDescription: string;

		if (isKeyDriverModel) {
			keyDrivers = formatKeyDriversIntoArray(event.keyDrivers);
		} else {
			const category = getCategoryDataFromEventType(eventTypes, event.eventName);
			categoryName = category?.categoryName;
			documentViewDescription = category?.documentViewDescription;
		}

		return {
			typeName: event.eventName,
			categoryName,
			keyDrivers,
			documentViewDescription,
			id: event.id,
			polarity: event.polarity,
			text: event.text,
			hash: event.hash,
			isSubsection: event.isSubsection,
			html: event.html,
			title: event.title,
			section: event.section
		};
	});
	return tenKEvents;
};

export const formatKeyDriversIntoArray = (keyDrivers: string) => {
	if (!_.isEmpty(_.trim(keyDrivers)) && _.isString(keyDrivers)) {
		return keyDrivers.split(';').map(_.trim);
	}
	return [GENERAL_KEY_DRIVER_NAME];
};

/**
 * This method is used to determine whether to remove the flow from the local storage.
 * Reasons can be not backwards compatible, or illegal in any way
 */
export const isLegalFlow = (flow: Types.ModelFlow): boolean =>
	flow && !!flow.dataSourcesDetails && !!flow.startDate;

export const getCompanyProviderIdsByProviderId = (
	companies: Types.Company[],
	providerId: number
): any[] => {
	const amenityProviderIds: string[] = [];
	_.each(companies, (c: Types.Company) => {
		const providerIdObject: Types.ProviderCompanyId = _.find(
			c.companyIds,
			(companyIdObject: Types.ProviderCompanyId) => companyIdObject.providerId === providerId
		);
		if (providerIdObject) {
			amenityProviderIds.push(providerIdObject.amenityProviderCompanyId);
		}
	});

	return amenityProviderIds;
};

export const getCompanyTickers = (companies: Types.Company[]): string[] =>
	_.chain(companies)
		.compact()
		.map((company: Types.Company) => company.ticker)
		.value();

export const docTypeToTrackingCategory = (docType: string): string => {
	let res = '';
	switch (docType) {
		case DocumentType.EarningsCall:
			res = UTC.TRANSCRIPT;
			break;
		case DocumentType.TenK:
			res = UTC.TEN_K;
			break;
		case DocumentType.EightK:
			res = UTC.EIGHT_K;
			break;
		case DocumentType.TenQ:
			res = UTC.TEN_Q;
			break;
		case DocumentType.SixK:
			res = UTC.SIX_K;
			break;
		case DocumentType.TwentyF:
			res = UTC.TWENTY_F;
			break;
		case DocumentType.FortyF:
			res = UTC.FORTY_F;
			break;
	}
	return res;
};

export const getUserDocumentTypes = (
	documentTypes: DocumentTypeLegacy[],
	flows: Types.ModelFlow[]
) => {
	return _.map(documentTypes, (dt: DocumentTypeLegacy) => {
		const isExistInUserFlows = filterFlowsByDocumentType(dt, flows).length > 0;
		return { ...dt, enabled: isExistInUserFlows };
	});
};

export const getDocumentTypesFromUserFlows = (
	flows: Types.ModelFlow[]
): {
	transcripts: DocumentTypeCategoryLegacy;
	secFilings: DocumentTypeCategoryLegacy;
} => {
	return {
		transcripts: {
			name: 'Transcripts',
			id: 1,
			types: getUserDocumentTypes(TRANSCRIPT_SORT_LEGACY, flows)
		},
		secFilings: {
			name: 'SEC filings',
			id: 2,
			types: getUserDocumentTypes(SEC_SORT_LEGACY, flows)
		}
	};
};

// Add test
export const getFlowsForEventSearch = (
	transcriptTypes: DocumentTypeLegacy[],
	SECTypes: DocumentTypeLegacy[],
	flows: Types.ModelFlow[]
): { id: number; dataSourcesDetails: Types.DataSource[] }[] => {
	let selectedTypes = _.filter(transcriptTypes, t => t.isSelected);
	selectedTypes = [..._.filter(SECTypes, t => t.isSelected), ...selectedTypes];
	const filteredFlows = filterFlowsByDocumentTypes(selectedTypes, flows);
	return _.map(filteredFlows, f => ({
		id: f.id,
		dataSourcesDetails: f.dataSourcesDetails
	}));
};

export const filterDocumentTypesWithFlows = (
	documentTypesSort: DocumentTypeLegacy[],
	flows: Types.ModelFlow[]
): DocumentTypeLegacy[] =>
	_.filter(documentTypesSort, dt => filterFlowsByDocumentType(dt, flows).length > 0);

export const isDocumentTypeExistInFlow = (
	documentType: DocumentTypeLegacy,
	flow: Types.ModelFlow
) => {
	if (!documentType) {
		return false;
	}
	return (
		_.findIndex(
			flow.dataSourcesDetails,
			(ds: Types.DataSource) => ds.documentTypeId === documentType.API_ID
		) !== -1
	);
};

export const filterFlowsByDocumentTypes = (
	dts: DocumentTypeLegacy[],
	flows: Types.ModelFlow[]
): Types.ModelFlow[] => _.filter(flows, f => _.some(dts, dt => isDocumentTypeExistInFlow(dt, f)));

export const filterFlowsByDocumentType = (
	documentType: DocumentTypeLegacy,
	flows: Types.ModelFlow[]
) => _.filter(flows, f => isDocumentTypeExistInFlow(documentType, f));

export const getDocumentTypeByDocumentName = (
	dts: {
		transcripts: DocumentTypeCategoryLegacy;
		secFilings: DocumentTypeCategoryLegacy;
	},
	documentName: string
): DocumentTypeLegacy => {
	const t1 = _.find(dts.transcripts.types, d => d.NAME === documentName);
	const t2 = _.find(dts.secFilings.types, d => d.NAME === documentName);
	return t1 || t2;
};

export const getDocumentTypeByDocumentId = (
	userDocumentTypes: Types.UserDocumentTypesCategories,
	documentId: number
): DocumentTypeLegacy => {
	const transcriptDocumentType = _.find(
		userDocumentTypes?.transcripts?.types,
		d => d.API_ID === documentId
	);
	const SecFilingsDocumentType = _.find(
		userDocumentTypes?.secFilings?.types,
		d => d.API_ID === documentId
	);
	return transcriptDocumentType || SecFilingsDocumentType;
};

export const isAllowedOriginDocIframe = (origin: string): boolean => {
	const keys = Object.keys(WEB_SERVER_URL);
	return _.findIndex(keys, k => WEB_SERVER_URL[k] === origin) !== -1;
};

export const buildPrintSummaryData = (events: Types.OutboundEvent[]) => {
	const sections = {};
	_.each(events, (e: Types.OutboundEvent) => {
		const formattedEvent = {
			id: e.id,
			text: e.html,
			title: e.title,
			keyDriver: e.categoryName,
			eventType: e.typeName
		};
		if (!sections[e.section]) {
			sections[e.section] = [];
		}
		sections[e.section].push(formattedEvent);
	});
	return sections;
};

export const isCurrentRoute = (location: any, route: string, exact: boolean = false): boolean =>
	exact
		? location.pathname === route
		: location.pathname.indexOf(route) !== -1 ||
		  !!matchPath(location.pathname, { path: route, exact: true });

export const symbologyCompaniesSearch = (searchQuery: string, self: any, dataStore: any) => {
	const toLoad = searchQuery && searchQuery.length > 0;
	self.setState({ symbologySearchInProgress: toLoad, symbologySearchFinished: false }, async () => {
		if (toLoad) {
			dataStore.searchForCompanies(searchQuery).then(res =>
				self.setState({
					symbologySearchFinished: true,
					symbologySearchResults: res
				})
			);
		} else {
			self.setState({
				symbologySearchFinished: true,
				symbologySearchResults: []
			});
		}
	});
};

export const companyToSymbologyCompany = (company: Types.Company) => ({
	id: company.factsetId,
	ticker: company.ticker,
	region: company.region,
	name: company.name
});

export const displayCategoryInfo = (category: Types.Category) =>
	category && category.chart_description;

export const getQuarterFromDate = (date: Date) => Math.ceil((date.getMonth() + 1) / 3);

export const groupDocumentsPerYears = (
	companyDocuments: Types.Document[]
): { year: number; documents: Types.Document[] }[] => {
	const fiscalYearGrouping = _.every(companyDocuments, document => !!document.fiscalYear);
	const groupedDocuments = _.groupBy(companyDocuments, d =>
		fiscalYearGrouping ? d.fiscalYear : moment(d.eventDate || d.publicationDate).year()
	);
	return _.chain(groupedDocuments)
		.map((d, y) => ({ year: Number(y), documents: d }))
		.orderBy('year', 'desc')
		.value();
};

export const stringToHTMLEntity = (str: string) =>
	str
		.replace(/&/g, '&amp;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
		.replace(/"/g, '&quot;');

export const checkEmailForSymbols = (email: string) =>
	email.indexOf('@') === -1 && email.indexOf('%40') !== -1;

export const openContact = (url?: string) => {
	const link = document.createElement('a');
	link.setAttribute('href', url ? url : 'https://help.amenityanalytics.com/en/');
	link.setAttribute('target', '_blank');
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const getTextWidth = (text: string, font: string) => {
	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');
	if (!context) {
		return 0;
	}
	context.font = font;
	const metrics = context.measureText(text);
	return metrics.width;
};

export const sortByCaseInsensitive = (
	array: any[],
	sortBy: string,
	isAscending: boolean = true
) => {
	if (_.isEmpty(array)) {
		return [];
	}
	if (_.isString(array[0][sortBy])) {
		return array.sort((first: any, second: any) => {
			const firstValue = (first[sortBy] || '').toLowerCase();
			const secondValue = (second[sortBy] || '').toLowerCase();
			return firstValue.localeCompare(secondValue) * (isAscending ? 1 : -1);
		});
	}
	return _.orderBy(array, sortBy, isAscending ? 'asc' : 'desc');
};

export const inputIsFocused = (input: any) => input && input === document.activeElement;

export const userLogId = (username: string, sub: string): string =>
	`${username && username.indexOf('@') !== -1 ? username.split('@')[1].split('.')[0] : ''}${
		sub ? '_' + sub : ''
	}`;

export const findDocumentById = (
	companyDocuments: any,
	company: PortfolioCompany,
	documentId: number | string
) => {
	const allDocuments = companyDocuments[company.id]
		? [...company.documents, ...companyDocuments[company.id].data]
		: company.documents;
	return _.find(allDocuments, (document: any) => document.documentId === documentId);
};

export const formatDate = (date: Date | string) =>
	date ? new Date(date).toLocaleString('en-US', { hour12: true }) : '';

export const exportFactsetsToExcel = (companies: Types.Company[], portfolioName: string) => {
	const factsets = _.map(companies, company => ({
		AmenityCompanyId: company.id,
		Tickers: `${company.ticker} ${company.region}`
	}));
	const date = moment().format('YYYYMMDD');
	const workbook = XLSX.utils.book_new();
	const workSheet = XLSX.utils.json_to_sheet(factsets);
	workSheet['!cols'] = [{ wch: 20 }, { wch: 10 }];
	XLSX.utils.book_append_sheet(workbook, workSheet, 'sheet1');
	XLSX.writeFile(workbook, `Watchlist-${portfolioName}-${date}.xlsx`);
};

export const calcPercents = (change: number, numOfDocs: number) => {
	if (numOfDocs === 1) {
		return '';
	} else {
		return change === INVALID_VALUE ? 'N/A' : fractionToPercent(change);
	}
};
