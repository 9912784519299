export default class InspectletService {
	private static instance: InspectletService;

	static getInstance() {
		return InspectletService.instance || new InspectletService();
	}

	constructor() {
		if (InspectletService.instance) {
			return InspectletService.instance;
		}
		InspectletService.instance = this;
	}

	turnOn = (username: string) => {
		// tslint:disable
		(function() {
			(window as any).__insp = (window as any).__insp || [];
			(window as any).__insp.push(['wid', 946457412]);
			const ldinsp = function() {
				if (typeof (window as any).__inspld !== 'undefined') return;
				(window as any).__inspld = 1;
				var insp = document.createElement('script');
				insp.type = 'text/javascript';
				insp.async = true;
				insp.id = 'inspsync';
				insp.src =
					('https:' === document.location.protocol ? 'https' : 'http') +
					'://cdn.inspectlet.com/inspectlet.js?wid=946457412&r=' +
					Math.floor(new Date().getTime() / 3600000);
				var x = document.getElementsByTagName('script')[0];
				x.parentNode.insertBefore(insp, x);
			};
			setTimeout(ldinsp, 0);
		})();
		// tslint:enable
		(window as any).__insp.push(['identify', username]);
	};
}
