import * as React from 'react';
import * as _ from 'lodash';
import { inject, observer } from 'mobx-react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import BackOfficeStore from '../../stores/backOffice.store';
import Images from '../../shared/images';
import styled from 'styled-components';
import { STORE_BACK_OFFICE } from '../../constants';
import { Organization } from '../../types/types';
import { withStyles } from '@material-ui/core/styles';
import AddOrUpdateOrganizationPopup from '../../components/BackofficePopups/addOrUpdateOrganizationPopUp';
import DeletePopup from '../../components/BackofficePopups/backOfficeDeletePopUp';

const Icons = styled.img`
	cursor: pointer;
`;

const TableCell = styled.td`
	overflow: hidden;
	max-width: 200px;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: rgba(0, 0, 0, 0.87);
	font-size: 0.8125rem;
	display: table-cell;
	padding: 4px 56px 4px 24px;
	text-align: left;
	border-bottom: 1px solid rgba(224, 224, 224);
	vertical-align: inherit;
`;

const WrapTable = styled.div`
	margin: 20px;
`;

const ButtonWrap = styled.div`
	float: right;
`;

const PageHeader = styled.div`
	margin: 20px;
`;

const PageTitle = styled.div`
	font-size: 16px;
	padding-top: 30px;
	padding-left: 22px;
`;

const Input = styled.input`
	height: 25px;
	width: 170px;
	padding-left: 10px;
`;

const styles = {
	paper: {
		maxHeight: '680px',
		overflow: 'auto'
	}
};

interface ClientDomainsProps {
	classes: any;
}

interface ClientDomainsTabState {
	isAddPopupOpen: boolean;
	isEditPopupOpen: boolean;
	isDeletePopupOpen: boolean;
	currentOrganization: Organization;
	deletedOrganizationdId: number;
	deletedOrganizationdDomain: string;
	searchText: string;
}

@inject(STORE_BACK_OFFICE)
@observer
class ClientDomainsTab extends React.Component<ClientDomainsProps, ClientDomainsTabState> {
	constructor(props: ClientDomainsProps) {
		super(props);
		this.state = {
			isAddPopupOpen: false,
			isEditPopupOpen: false,
			isDeletePopupOpen: false,
			deletedOrganizationdId: 0,
			deletedOrganizationdDomain: '',
			searchText: '',
			currentOrganization: undefined
		};
		(this.props[STORE_BACK_OFFICE] as BackOfficeStore).getAllOrganizations();
		(this.props[STORE_BACK_OFFICE] as BackOfficeStore).getAllCollections();
	}

	closeAddPopup = () => {
		this.setState({ isAddPopupOpen: false });
	};

	closeEditPopup = () => {
		this.setState({ isEditPopupOpen: false });
	};

	closeDeletePopup = () => {
		this.setState({ isDeletePopupOpen: false });
	};

	deleteDomain = () => {
		(this.props[STORE_BACK_OFFICE] as BackOfficeStore).deleteOrganization(
			this.state.deletedOrganizationdId,
			this.state.deletedOrganizationdDomain
		);
	};

	deleteOrganization = (id: number, domain: string) => {
		this.setState({
			deletedOrganizationdId: id,
			isDeletePopupOpen: true,
			deletedOrganizationdDomain: domain
		});
	};

	openAddPopup = () => {
		this.setState({ isAddPopupOpen: true });
	};

	openEditPopup = (org: Organization) => {
		this.setState({
			currentOrganization: { ...org },
			isEditPopupOpen: true
		});
	};

	handleKeyPress = event => {
		this.setState({ searchText: event.target.value });
	};

	addOrganization = (organization: Organization) => {
		(this.props[STORE_BACK_OFFICE] as BackOfficeStore).addOrganization(organization);
		this.closeAddPopup();
	};

	UpdateOrganization = (organization: Organization) => {
		(this.props[STORE_BACK_OFFICE] as BackOfficeStore).updateOrganization(organization);
		this.closeAddPopup();
	};

	renderUpperSection = () => {
		return (
			<div>
				<PageTitle>Client Domains</PageTitle>
				<PageHeader>
					<Input
						type='text'
						placeholder='Search'
						onChange={e => this.handleKeyPress(e)}
						id='BACKOFFICE_CLIENT_DOMAINS_SEARCH_BAR'
					/>
					<ButtonWrap>
						<Button
							variant='contained'
							size='large'
							style={{
								backgroundColor: '#21a6f2',
								color: '#fff',
								boxShadow: 'none'
							}}
							onClick={() => this.openAddPopup()}
							id='BACKOFFICE_CLIENT_DOMAINS_NEW_DOMAIN_BUTTON'
						>
							New Domain
						</Button>
					</ButtonWrap>
				</PageHeader>
			</div>
		);
	};

	renderTableBody = (filteredOrganizations: Organization[]) => {
		return (
			<TableBody id='DOMAINS_TABLE'>
				{_.map(filteredOrganizations, (organization, index) => {
					return (
						<TableRow key={organization.id}>
							<TableCell>{organization.domain}</TableCell>
							<TableCell>{organization.client_id}</TableCell>
							<TableCell>{organization.token}</TableCell>
							<TableCell>{organization.collection_name}</TableCell>
							<TableCell>
								<Icons
									src={Images.deleteTranscript}
									alt='Delete Organization'
									onClick={() => this.deleteOrganization(organization.id, organization.domain)}
									id={`BACKOFFICE_CLIENT_DOMAINS_DELETE_ORGANIZATION_ROW_${index}`}
								/>
							</TableCell>
							<TableCell>
								<Icons
									src={Images.editIcon}
									onClick={() => this.openEditPopup(organization)}
									id={`BACKOFFICE_CLIENT_DOMAINS_EDIT_ORGANIZATION_ROW_${index}`}
								/>
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		);
	};

	renderTable = filteredOrganizations => {
		return (
			<WrapTable>
				<Paper className={this.props.classes.paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Domain</TableCell>
								<TableCell>Client id</TableCell>
								<TableCell>Token</TableCell>
								<TableCell>Collection</TableCell>
								<TableCell />
								<TableCell />
							</TableRow>
						</TableHead>
						{this.renderTableBody(filteredOrganizations)}
					</Table>
				</Paper>
			</WrapTable>
		);
	};

	render() {
		const { organizations, collections } = this.props[STORE_BACK_OFFICE] as BackOfficeStore;
		const { isAddPopupOpen, isEditPopupOpen, currentOrganization, isDeletePopupOpen } = this.state;
		const searchTerm = this.state.searchText.toLowerCase();
		const filteredOrganizations = _.filter(
			_.orderBy(organizations, 'domain'),
			o =>
				o.domain.toLowerCase().indexOf(searchTerm) !== -1 ||
				o.client_id.toString().indexOf(searchTerm) !== -1
		);
		return (
			<div>
				{this.renderUpperSection()}
				{this.renderTable(filteredOrganizations)}
				{isAddPopupOpen && (
					<AddOrUpdateOrganizationPopup
						type={0}
						modalIsOpen={isAddPopupOpen}
						closePopup={() => this.closeAddPopup()}
						addOrEditFunc={this.addOrganization}
						existingOrganizations={organizations}
						collections={collections}
					/>
				)}
				{isEditPopupOpen && (
					<AddOrUpdateOrganizationPopup
						type={1}
						modalIsOpen={isEditPopupOpen}
						closePopup={() => this.closeEditPopup()}
						organization={currentOrganization}
						addOrEditFunc={this.UpdateOrganization}
						existingOrganizations={organizations}
						collections={collections}
					/>
				)}
				{isDeletePopupOpen && (
					<DeletePopup
						modalIsOpen={isDeletePopupOpen}
						closePopup={() => this.closeDeletePopup()}
						deleteFunc={this.deleteDomain}
						name={'organization'}
					/>
				)}
			</div>
		);
	}
}

export default withStyles(styles)(ClientDomainsTab);
