import * as React from 'react';
import Tooltip from 'react-tooltip';
import styled from 'styled-components';
import * as _ from 'lodash';

import './eventsList.css';
import { Event, EventListCategory } from '../../types/types';
import {
	aggregateEventsByCategory,
	aggregateEventsByKeyDrivers,
	sortCategories
} from './eventsList.util';
import SidebarEventCategory from '../SidebarEventCategory/SidebarEventCategory';
import * as ids from '../../../id.constants.js';

const Wrapper = styled.div`
	height: 100%;
	width: 100%;
	overflow-y: auto;
`;

interface EventsListProps {
	events: Event[];
	onEventSelection?: Function;
	sortCondition: number | string;
	showTag?: boolean;
	showFullSentence?: boolean;
	clickableEvents?: boolean;
	arrowPosRight?: boolean;
	expandAll?: boolean;
	selectedEventId?: number | string;
	openCategoryContainingEvent?: boolean;
	elementIdPrefix?: string;
	navReloadCounter?: number;
	keyDriverModel?: boolean;
	showNeutralExtractions?: boolean;
}

interface EventsListState {
	categories: EventListCategory[];
	categoriesLoaded: number[];
	prevNavReloadCounter: number;
}

export default class EventsList extends React.Component<EventsListProps, EventsListState> {
	EVENT_CATEGORY_ITEM_HEIGHT = 100;

	constructor(props: EventsListProps) {
		super(props);
		const aggregateEvents = props.keyDriverModel
			? aggregateEventsByKeyDrivers
			: aggregateEventsByCategory;
		this.state = {
			categories: aggregateEvents(props.events, [], props.expandAll),
			categoriesLoaded: [],
			prevNavReloadCounter: 0
		};
		this.EVENT_CATEGORY_ITEM_HEIGHT = props.showTag ? 100 : 70;
	}

	componentDidUpdate() {
		Tooltip.rebuild();
	}

	static getDerivedStateFromProps(
		nextProps: EventsListProps,
		state: EventsListState
	): EventsListState {
		if (nextProps.events) {
			const shouldReload = nextProps.navReloadCounter !== state.prevNavReloadCounter;
			const selectedEventId = nextProps.openCategoryContainingEvent
				? (nextProps.selectedEventId as number)
				: 0;
			const aggregateEvents = nextProps.keyDriverModel
				? aggregateEventsByKeyDrivers
				: aggregateEventsByCategory;
			const categories = aggregateEvents(
				nextProps.events,
				state.categories,
				nextProps.expandAll,
				shouldReload,
				selectedEventId
			);
			const categoriesLoaded = shouldReload ? [] : state.categoriesLoaded;
			return { categories, categoriesLoaded, prevNavReloadCounter: nextProps.navReloadCounter };
		}

		return state;
	}

	toggleCategory = (catId: number) => {
		this.setState({
			categories: this.state.categories.map(c => {
				if (c.id === catId) {
					return { ...c, open: !c.open };
				}
				return c;
			})
		});
	};

	mapCategoriesToCategoryElements = (
		categories: EventListCategory[],
		showFullSentence: boolean,
		showNeutralExtractions: boolean,
		arrowPosRight: boolean,
		selectedEventId: number | string,
		showTag: boolean,
		clickableEvents: boolean,
		onEventSelection: Function
	): any[] =>
		_.map(categories, (category: EventListCategory) => (
			<SidebarEventCategory
				key={`category-${category.id}-${category.open}-${showNeutralExtractions}`}
				category={category}
				arrowPosRight={arrowPosRight}
				selectedEventId={selectedEventId}
				showTag={showTag}
				clickableEvents={clickableEvents}
				showFullSentence={showFullSentence}
				onEventSelection={onEventSelection}
				toggleCategory={this.toggleCategory}
				showNeutralExtractions={showNeutralExtractions}
			/>
		));

	render() {
		const { categories } = this.state;
		const {
			sortCondition,
			showFullSentence,
			showNeutralExtractions,
			arrowPosRight,
			selectedEventId,
			showTag,
			clickableEvents,
			onEventSelection
		} = this.props;
		const sortedCategories = sortCategories(categories, sortCondition);
		if (sortedCategories.length === 0) {
			return <Wrapper />;
		}

		return (
			<Wrapper data-testid={ids.DOCUMENT_PAGE.EVENTS_LIST_WRAPPER}>
				{this.mapCategoriesToCategoryElements(
					sortedCategories,
					showFullSentence,
					showNeutralExtractions,
					arrowPosRight,
					selectedEventId,
					showTag,
					clickableEvents,
					onEventSelection
				)}
				<Tooltip
					id='DOCUMENT_VIEW_TOOL_TIP'
					place='top'
					effect='solid'
					className='tooltip-theme'
					delayHide={200}
				/>
			</Wrapper>
		);
	}
}
