import { userLogId } from './util.service';
import IntercomService from './intercom.service';
import { Identity } from '../types/types';
import { USER_TRACKING } from '../constants';

// arrives from segment snippet
declare var analytics: any;

const SKIP_USERS_WITH_NAMES = ['a@', 'demo@', 'textai@'];
const SKIP_USERS_DOMAINS = ['viewerautomation'];

export default class UserTracking {
	static instance;

	private identifyData: Identity;

	constructor() {
		if (UserTracking.instance) {
			return UserTracking.instance;
		}

		UserTracking.instance = this;
	}

	static getInstance() {
		return UserTracking.instance || new UserTracking();
	}

	identify(data: Identity) {
		this.identifyData = { ...data };

		if (this.shouldSkipUserReporting()) {
			return;
		}

		analytics?.identify(data.email, {
			domain: data.domain,
			email: data.email,
			salesforceAccountId: data.salesforceAccountId
		});
		analytics?.page();
	}

	setEvent(category: string, action: string, label?: string, extra?: { [key: string]: any }) {
		if (this.shouldSkipUserReporting()) {
			return;
		}

		const icomSrv = new IntercomService();
		if (!category || !action) {
			console.error('[UserTracking] setEvent missing category or action');
		}
		const userId = userLogId(this.identifyData?.email, this.identifyData?.sub);

		label = label || '';
		if (userId) {
			label = label.length > 0 ? label + `_${userId}` : `${userId}`;
		}
		// remove @ so it won't fall on "REDACTED (Potential Email Address)"
		label = label.replace(/@/g, '_');
		const data = { ...extra, category, action, label };
		analytics?.track(action, { ...data, ...this.identifyData });

		const excludeCategoriesFromIntercom = [USER_TRACKING.USER_TRACKING_CATEGORIES.PERFORMANCE];
		if (!excludeCategoriesFromIntercom.includes(category)) {
			icomSrv.trackEvent(`${category} - ${action}`, { label });
		}
	}

	shouldSkipUserReporting() {
		return (
			!!SKIP_USERS_WITH_NAMES.find(name => this.identifyData?.email?.startsWith(name)) ||
			!!SKIP_USERS_DOMAINS.find(name => this.identifyData?.email?.endsWith(name))
		);
	}
}
