export enum Actions {
	renameWatchlist = 'rename',
	deleteWatchlist = 'erase',
	unsupported = 'unsupported'
}

export interface Watchlist {
	id: string;
	name?: string;
	companies?: {
		id: string;
		name: string;
		ticker: string;
		region: string;
	}[];
	companyEntities?: {
		id: string;
		name: string;
		ticker: string;
		region: string;
	}[];
}

export interface LookerWatchlist {
	id: string;
	name: string;
	companyEntities: {
		mainIdentifier: {
			ticker: string;
			region: string;
		};
	}[];
}
