import React, { useEffect, useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { inject, observer } from 'mobx-react';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import styled from 'styled-components';
import currentDocumentTypeState from '../../recoil/currentDocumentType';
import currentSubscriptionState from '../../recoil/currentSubscription';
import watchlistDataState from '../../recoil/watchlist';
import DataStore from '../../stores/data.store';
import GlobalStore from '../../stores/global.store';
import UIStore from '../../stores/ui.store';

import {
	STORE_FEATURES,
	STORE_DATA,
	ADD_COMPANY_DROPDOWN_PLACEHOLDER,
	USER_TRACKING as UT,
	STORE_GLOBAL,
	APP_COLORS,
	STORE_UI,
	TABS,
	FEATURES_TYPE_SPLIT
} from '../../constants';
import { PortfolioCompany, ModelFlow, SymbologyCompany, Category } from '../../types/types';
import { DocumentTypeLegacy } from '../../types/DocTypes.types';
import { Watchlist } from '../../types/Watchlist.types';
import * as ids from '../../../id.constants.js';

import { error } from '../../services/logger.service';
import UserTracking from '../../services/userTracking.service';
import WatchlistService from '../../services/watchlist.service';
import NavigationService from '../../services/navigation.service';
import { filterFlowsByDocumentType, findDocumentById } from '../../services/util.service';

import PortfolioTable from '../Table/portfolioTable';
import WatchlistLens from './WatchlistLens';
import WatchlistLabel from './WatchlistLabel';
import { Empty, EmptyIcon, EmptySubTitle } from './sharedStyledComponents';
import { Appearance, Colors } from '../../../components/shared/styles';
import { SymbologySearch } from '../../widgets/PortfolioSymbologyAutoCompleteDropDown/SymbologySearch';
import {
	ADD_COMPANY_TO_WATCHLIST,
	REMOVE_COMPANY_FROM_WATCHLIST
} from '../../queries/Watchlist.queries';
import { CreateWatchlistMenu } from './CreateWatchlistMenu/CreateWatchlistMenu';
import { Link, Text } from '../../../components';
import { useFeature } from '../../hooks/useFeature';
import WatchlistLensNewarc from './WatchlistLensNewarc';
import { Spinner } from '../../pages/sideBarElements';

const UTC = UT.USER_TRACKING_CATEGORIES;
const UTA = UT.USER_TRACKING_ACTIONS;

const Body = styled.div`
	height: 100%;
`;

const UpperSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 90px;
	padding: 0 15px;
	box-sizing: border-box;
	border-bottom: 1px solid #e0e7e9;
`;

const LeftUpperSection = styled.div`
	display: flex;
	flex-direction: column;
	width: calc(100% - 450px);
	height: 100%;
	padding: 10px 0;
	box-sizing: border-box;
`;

const RightUpperSection = styled.div`
	display: flex;
	align-items: center;
`;

const BottomSection = styled.div`
	height: calc(100% - 90px);
	width: 100%;
`;

const InnerSectionContainer = styled.div`
	height: 100%;
	width: 90%;
	min-width: 1040px;
	margin: 0 auto;
	padding: 10px 0 5px 10px;
	box-sizing: border-box;
`;

interface AddTickerContainerProps {
	enableHover: boolean;
}
const AddTickerContainer = styled.div<AddTickerContainerProps>`
	width: calc(100% - 220px);
	margin-top: 10px;
	cursor: ${props => (props.enableHover ? 'text' : 'default')};
	border-radius: 5px;
`;

const Separator = styled.div`
	height: 1px;
	width: 1px;
	margin: 0 5px;
`;

const PRE_TABLE_HEADER_HEIGHT = 50;

const PreTableHeader = styled.div`
	display: flex;
	height: ${PRE_TABLE_HEADER_HEIGHT}px;
	box-sizing: border-box;
	padding-left: 18px;
	justify-content: space-between;
	align-items: center;
`;

const TableTitle = styled.div`
	float: left;
	font-family: assistant;
	font-weight: 600;
	font-size: 14px;
	color: ${APP_COLORS.BLACK_32313B};
`;

const TableContainer = styled.div`
	height: calc(100% - ${PRE_TABLE_HEADER_HEIGHT}px);
`;

const HiddenCurrentFlowFlag = styled.div`
	position: absolute;
	height: 0;
	width: 0;
	opacity: 0;
`;

const EmptyContainer = styled.div`
	margin: 31px 21px;
`;

interface PortfolioProps {
	match: any;
	watchlist: Watchlist;
	[STORE_UI]?: UIStore;
	[STORE_DATA]?: DataStore;
	[STORE_GLOBAL]?: GlobalStore;
}

const WatchlistPage: React.FunctionComponent<PortfolioProps> = (props: PortfolioProps) => {
	const dataStore = props[STORE_DATA];
	const userTracking: UserTracking = UserTracking.getInstance();
	const navService: NavigationService = NavigationService.getInstance();
	const watchlistService: WatchlistService = WatchlistService.getInstance();

	const tableRef = useRef(undefined);
	const [categories, setCategories] = useState([]);
	const [portfolioId, setWatchlistId] = useState(undefined);
	const [watchlistIdString, refreshList] = useState(props?.watchlist?.id);
	const [addCompanyCounter, setAddCompanyCounter] = useState(0);
	const [addedCompanyId, setAddedCompanyId] = useState<number | string>(-1);
	const [companies, setCompanies] = useState<any[]>(undefined);
	const [watchlistName, setWatchlistName] = useState<string>(props?.watchlist?.name);
	const currentDocType = useRecoilValue(currentDocumentTypeState);
	const currentSubscriptionId = useRecoilValue(currentSubscriptionState);
	const [addCompanyToWatchlist] = useMutation(ADD_COMPANY_TO_WATCHLIST);
	const [removeCompanyFromWatchlist] = useMutation(REMOVE_COMPANY_FROM_WATCHLIST);
	const ffLensesFromNewarc = useFeature(FEATURES_TYPE_SPLIT.VIEWER_NEWARC_MIGRATION);
	const watchlistData = useRecoilValue(watchlistDataState);

	useEffect(() => {
		const watchlistId: any = props?.watchlist?.id;
		const globalStore = props[STORE_GLOBAL];
		const uiStore = props[STORE_UI];
		setWatchlistId(watchlistId);
		setWatchlistName(watchlistData.name || props?.watchlist?.name);

		dataStore.initPortfolio();
		globalStore.setCurrentTab(TABS.WATCHLIST);
		uiStore.setSideBarState(true);
		uiStore.setCanToggleSideBar(true);
		dataStore.getAllCategories(categories => {
			setCategories(categories);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (dataStore.currentFlow && props.watchlist) {
			getAllCompaniesData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataStore.currentFlow, watchlistIdString]);

	const getAllCompaniesData = () => {
		let companiesOrEntities: any[];
		companiesOrEntities = watchlistData?.companyEntities;
		const companiesToQuery: any[] = companies || companiesOrEntities || [];
		if (companiesToQuery?.length > 0) {
			const factsetIds = _.map(companiesToQuery, 'id');
			getCompaniesData(factsetIds).then(companiesWithData => setCompanies(companiesWithData));
		} else {
			dataStore.setSuccessfulLoad(true);
			setCompanies([]);
		}
	};

	const getCompaniesData = (factsetIds: string[]): Promise<PortfolioCompany[]> => {
		const dataStore = props[STORE_DATA];
		const { companiesWithData, idsWithoutData } = groupByExistingData(factsetIds);
		return watchlistService
			.fetchCompaniesData(
				dataStore.currentFlow,
				dataStore.currentDocumentType?.NAME,
				idsWithoutData
			)
			.then((companies: PortfolioCompany[]) => {
				dataStore.setCompaniesData(companies);
				dataStore.setSuccessfulLoad(true);
				return _.concat(companiesWithData, companies);
			})
			.catch(err => {
				dataStore.setSuccessfulLoad(false);
				error({
					message: `Couldn't fetch companies data`,
					file: 'WatchlistPage',
					functionName: 'getCompaniesData'
				});
				return err;
			});
	};

	const groupByExistingData = (
		factsetIds: string[]
	): { companiesWithData: PortfolioCompany[]; idsWithoutData: string[] } => {
		return _.reduce(
			factsetIds,
			(acc: { companiesWithData: PortfolioCompany[]; idsWithoutData: string[] }, id: string) => {
				const data = props[STORE_DATA].companiesData[id];
				if (data) {
					return {
						companiesWithData: _.concat(acc.companiesWithData, [data]),
						idsWithoutData: [...acc.idsWithoutData]
					};
				}
				return {
					companiesWithData: [...acc.companiesWithData],
					idsWithoutData: _.concat(acc.idsWithoutData, [id])
				};
			},
			{ companiesWithData: [], idsWithoutData: [] }
		);
	};

	const navigateToDocument = (documentId: number, company: PortfolioCompany) => {
		const { companyDocuments, currentFlow } = props[STORE_DATA];
		const document = findDocumentById(companyDocuments, company, documentId);

		if (document) {
			navService.goToDocument(currentFlow.id, document.documentId);
		} else {
			error({
				message: `Couldn't find document ${documentId} in the list of documents.`,
				file: 'WatchlistPage',
				functionName: 'navigateToDocument'
			});
		}
	};

	const callAddCompanyToWatchlist = (company: SymbologyCompany) => {
		focusCompany(company.id);
		addCompanyToWatchlist({ variables: { portfolioId, factsetId: company.id } })
			.then(() => getCompaniesData([company.id]))
			.then((newCompanies: PortfolioCompany[]) => setCompanies(_.concat(companies, newCompanies)))
			.then(() =>
				userTracking.setEvent(UTC.WACTHLIST, UTA.COMPANY_ADDED, company.ticker, {
					totalWatchlistSize: companies.length
				})
			)
			.catch(err => {
				error({
					message: `Could not add company to watchlist, ${err}`,
					file: 'WatchlistPage',
					functionName: 'addCompanyToPortfolio'
				});
			});
	};

	const callRemoveCompanyFromWatchlist = (factsetId: string) => {
		removeCompanyFromWatchlist({ variables: { portfolioId, factsetId } })
			.then(() => {
				const updatedCompanies = [...companies];
				const index = _.findIndex(updatedCompanies, { id: factsetId });
				updatedCompanies.splice(index, 1);
				setCompanies(updatedCompanies);
			})
			.then(() =>
				userTracking.setEvent(UTC.WACTHLIST, UTA.COMPANY_REMOVED, factsetId, {
					totalWatchlistSize: companies.length
				})
			)
			.catch(err => {
				error({
					message: `Could not remove company from watchlist, ${err}`,
					file: 'WatchlistPage',
					functionName: 'callRemoveCompanyFromWatchlist'
				});
			});
	};

	const collapseAllRows = () => {
		(props[STORE_UI] as UIStore).resetExpandedCompanies();
	};

	const { portfolioTableExpandedCompanies } = props[STORE_UI] as UIStore;
	const showCollapseAllButton = !_.isEmpty(portfolioTableExpandedCompanies);
	const showCollapseAllButtonDisabled = _.isEmpty(portfolioTableExpandedCompanies);

	const focusCompany = (companyId: number | string) => {
		setAddedCompanyId(companyId);
		setAddCompanyCounter(addCompanyCounter + 1);
	};

	const sortByFlowId = (flows: ModelFlow[]) => {
		return _.sortBy(flows, f => f.id);
	};

	const goToAllWatchlists = () => {
		userTracking.setEvent(UTC.WACTHLIST, UTA.BACK_TO_WACTHLISTS_CLICK);
		navService.goToAllWatchlists();
	};

	const renderEmpty = () => (
		<EmptyContainer>
			<Empty id={ids.MULTIPORTFOLIO.EMPTY_STATE} padding={'30px 0 54px'}>
				<EmptyIcon />
				<EmptySubTitle>
					Your watchlist is empty. Add a ticker from the search box above in order to view Amenity
					Scores and Documents
				</EmptySubTitle>
			</Empty>
		</EmptyContainer>
	);

	const renderTable = (
		currentDocumentType: DocumentTypeLegacy,
		companies:
			| {
					id: string;
					name: string;
					ticker: string;
					region: string;
					sector: string;
					keyDrivers: any[];
					last_document_date: any;
					score: number;
					prev_score: number;
					documents: any[];
			  }[]
			| PortfolioCompany[],
		addCompanyCounter: number,
		categories: Category[],
		isSuccessfulLoad: boolean | undefined,
		showCollapseAllButton: boolean,
		showCollapseAllButtonDisabled: boolean
	) => {
		const currentDocumentLabel = ffLensesFromNewarc
			? currentDocType
			: currentDocumentType && currentDocumentType.NAME
			? currentDocumentType.NAME
			: '';
		return (
			<InnerSectionContainer>
				<PreTableHeader>
					<TableTitle>{currentDocumentLabel}</TableTitle>
					{showCollapseAllButton && <Link onClick={collapseAllRows}>Collapse All</Link>}
					{showCollapseAllButtonDisabled && (
						<Text appearance={Appearance.secondary} color={Colors.darkGray}>
							Collapse All
						</Text>
					)}
				</PreTableHeader>
				<TableContainer>
					{companies ? (
						<PortfolioTable
							ref={tableRef}
							onDeleteCompany={callRemoveCompanyFromWatchlist}
							onNavigateToTicker={navigateToDocument}
							companies={companies}
							currentDocumentType={currentDocumentType}
							addedCompanyId={addedCompanyId}
							companyAddedCounter={addCompanyCounter}
							onScrolledToRow={focusCompany}
							categories={categories}
							isSuccessfulLoad={isSuccessfulLoad}
							subscriptionId={currentSubscriptionId}
						/>
					) : (
						<Spinner />
					)}
				</TableContainer>
			</InnerSectionContainer>
		);
	};

	const renderContent = (
		isSuccessfulLoad: boolean,
		showOnBoarding: boolean,
		currentDocumentType: DocumentTypeLegacy,
		companies: PortfolioCompany[],
		addCompanyCounter: number,
		categories: Category[]
	) => {
		if (isSuccessfulLoad === undefined) {
			return <div />;
		}
		if (showOnBoarding && isSuccessfulLoad) {
			companyDropDownFocus();
			return renderEmpty();
		}
		return renderTable(
			currentDocumentType,
			companies,
			addCompanyCounter,
			categories,
			isSuccessfulLoad,
			showCollapseAllButton,
			showCollapseAllButtonDisabled
		);
	};

	const companyDropDownFocus = () => {
		const input = document.getElementById('PORTFOLIO_SEARCH_FIELD');
		input?.focus();
	};

	const formatSearchResults = (results: SymbologyCompany[]) => {
		const [inWatchlist, notInWatchlist] = _.partition(results, (company: SymbologyCompany) =>
			_.some(
				companies,
				(watchlistCompany: PortfolioCompany) => watchlistCompany?.factsetId === company?.id
			)
		);

		return {
			inPortfolio: inWatchlist,
			notInPortfolio: notInWatchlist
		};
	};

	const onSearchItemSelect = (company: SymbologyCompany, shouldAddToWatchlist: boolean) => {
		if (shouldAddToWatchlist) {
			callAddCompanyToWatchlist(company);
		} else {
			focusCompany(company.id);
			tableRef?.current?.focusRow(company.id, true);
		}
	};

	const { currentFlow, flows, isSuccessfulLoad, currentDocumentType, changeFlowById } = props[
		STORE_DATA
	];

	const showOnBoardingPage = companies?.length === 0 && isSuccessfulLoad;
	const filteredFlowByDocumentType = sortByFlowId(
		filterFlowsByDocumentType(currentDocumentType, flows)
	);
	const reloadWatchlist = newWatchlistIdString => {
		refreshList(watchlistIdString + newWatchlistIdString);
	};

	return (
		<Body style={{ backgroundColor: showOnBoardingPage ? '#FBFBFB' : 'unset' }}>
			{currentFlow && <HiddenCurrentFlowFlag id={ids.CURRENT_FLOW_LOADED} />}
			<UpperSection style={{ backgroundColor: Colors.lightGray }}>
				<LeftUpperSection>
					<WatchlistLabel name={props?.watchlist?.name} handleBack={goToAllWatchlists} />
					<AddTickerContainer enableHover={showOnBoardingPage} onClick={companyDropDownFocus}>
						<SymbologySearch
							placeholder={ADD_COMPANY_DROPDOWN_PLACEHOLDER}
							onSelect={onSearchItemSelect}
							formatSearchResults={formatSearchResults}
						/>
					</AddTickerContainer>
				</LeftUpperSection>
				<RightUpperSection>
					<CreateWatchlistMenu
						refetch={reloadWatchlist}
						watchlistId={props?.watchlist?.id}
						watchlistName={watchlistName}
					/>
					<Separator />
					{ffLensesFromNewarc ? (
						<WatchlistLensNewarc />
					) : (
						<WatchlistLens
							flows={filteredFlowByDocumentType}
							currentFlow={currentFlow}
							handleFlowChange={changeFlowById}
						/>
					)}
				</RightUpperSection>
			</UpperSection>
			<BottomSection>
				{renderContent(
					isSuccessfulLoad,
					showOnBoardingPage,
					currentDocumentType,
					companies,
					addCompanyCounter,
					categories
				)}
			</BottomSection>
		</Body>
	);
};

export default inject(STORE_DATA, STORE_GLOBAL, STORE_UI, STORE_FEATURES)(observer(WatchlistPage));
