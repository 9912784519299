import * as React from 'react';
import * as _ from 'lodash';

import { TableColumn } from '../../types/types';
import * as TableStyles from './commonTableStyles';
import Images from '../../shared/images';

interface TableProps {
	tableId: string;
	columns: TableColumn[];
	isSortable: boolean;
	nameOfSortedColumn?: string;
	isSortedAscending?: boolean;
	sortFunction?: Function;
	children?: any;
}

const CommonTable = (props: TableProps) => {
	const {
		tableId,
		columns,
		nameOfSortedColumn,
		isSortable,
		sortFunction,
		isSortedAscending,
		children
	} = props;

	const renderHeaders = (
		columns: TableColumn[],
		nameOfSortedColumn: string,
		isSortable: boolean,
		sortFunction: Function,
		isSortedAscending: boolean
	) =>
		_.map(columns, (column: TableColumn) => (
			<TableStyles.HeaderColumn
				key={column.name}
				selected={nameOfSortedColumn === column.name}
				width={column.width}
				marginLeft={column.marginLeft}
				clickable={isSortable}
				onClick={() => isSortable && sortFunction && sortFunction(column)}
				id={column.headerId}
			>
				{column.displayName}
				{nameOfSortedColumn === column.name && (
					<TableStyles.HeaderArrow isAscending={isSortedAscending} src={Images.filterArrowBlack} />
				)}
				{column.infoIcon && (
					<TableStyles.InfoIcon
						data-tip={column.infoIcon.dataTip}
						data-for={column.infoIcon.dataFor}
						id={column.infoIcon.id}
					/>
				)}
			</TableStyles.HeaderColumn>
		));

	return (
		<TableStyles.TableContainer id={tableId}>
			<TableStyles.TableRowHeader>
				{renderHeaders(columns, nameOfSortedColumn, isSortable, sortFunction, isSortedAscending)}
			</TableStyles.TableRowHeader>
			{children}
		</TableStyles.TableContainer>
	);
};

export default CommonTable;
