import * as React from 'react';
import { inject, observer } from 'mobx-react';
import './transcript.css';
import * as _ from 'lodash';
import styled from 'styled-components';
import { Event, FormattedText } from '../../types/types';
import Discussion from './discussion';
import QNA from './qna';
import { logLostEvents } from './transcript.util';
import { STORE_TRANSCRIPT_LOG, STORE_DATA, STORE_GLOBAL, ATTRIBUTIONS } from '../../constants';
import { getDiscussionSection, getQNASection } from '../../services/util.service';
import TranscriptLogStore from '../../stores/transcriptLog.store';
import GlobalStore from '../../stores/global.store';

const HighlighterContainer = styled.div`
	position: relative;
	padding-bottom: 20px;
`;

const Footnote = styled.div`
	margin-top: 10px;
	font-size: 9px;
`;

interface TranscriptProps {
	text: FormattedText;
	events: Event[];
	delimiterOffset: number;
	printMode?: boolean;
	editMode?: boolean;
}

interface TranscriptState {
	lostEvents: Event[];
}

@inject(STORE_DATA, STORE_GLOBAL, STORE_TRANSCRIPT_LOG)
@observer
export default class Transcript extends React.Component<TranscriptProps, TranscriptState> {
	constructor(props: TranscriptProps) {
		super(props);
		const transcriptLogStore = props[STORE_TRANSCRIPT_LOG] as TranscriptLogStore;
		transcriptLogStore.initializeGlobalCounter();
		transcriptLogStore.startLoggingErrors();
		const globalStore = props[STORE_GLOBAL] as GlobalStore;
		globalStore.initializeNonfoundEvents();

		this.state = {
			lostEvents: []
		};
	}

	componentDidMount() {
		if (this.props.events.length > 0 && this.props.text.sections.length > 0) {
			this.logLostEvents();
			const lostEvents = (this.props[STORE_GLOBAL] as GlobalStore).eventsNotFound;
			if (!_.isEqual(lostEvents, this.state.lostEvents)) {
				this.setState({ lostEvents: lostEvents }, () => {
					(this.props[STORE_GLOBAL] as GlobalStore).setEventsNotFound([]);
				});
			}
		}
	}

	componentDidUpdate() {
		const lostEvents = (this.props[STORE_GLOBAL] as GlobalStore).eventsNotFound;
		if (!_.isEqual(lostEvents, this.state.lostEvents)) {
			this.setState({ lostEvents: lostEvents }, () => {
				(this.props[STORE_GLOBAL] as GlobalStore).setEventsNotFound([]);
			});
		}
	}

	logLostEvents = () => {
		const { eventsFound } = this.props[STORE_TRANSCRIPT_LOG] as TranscriptLogStore;
		if (eventsFound.length < this.props.events.length) {
			logLostEvents(this.props.events, eventsFound);
		}
		(this.props[STORE_TRANSCRIPT_LOG] as TranscriptLogStore).stopLoggingErrors();
	};

	render() {
		const { delimiterOffset, printMode, editMode, events } = this.props;
		const { lostEvents } = this.state;

		// Get events that belong to discussion section
		const discussionSection = getDiscussionSection(this.props.text.sections);
		const qnaSection = getQNASection(this.props.text.sections);

		const vendor = this.props.text.vendor;

		return (
			<HighlighterContainer className={editMode ? 'highlighter_new_wrap' : ''}>
				{discussionSection && (
					<Discussion
						speakers={discussionSection.speakers}
						events={events}
						lostEvents={lostEvents}
						delimiterOffset={delimiterOffset}
						printMode={printMode}
					/>
				)}
				{discussionSection && qnaSection && (
					<QNA
						speakers={qnaSection.speakers}
						events={events}
						lostEvents={lostEvents}
						sectionOffset={discussionSection.textLength}
						delimiterOffset={delimiterOffset}
						printMode={printMode}
					/>
				)}
				<Footnote
					style={{
						display:
							(discussionSection || qnaSection) && Object.keys(ATTRIBUTIONS).includes(vendor)
								? 'block'
								: 'none'
					}}
				>
					{ATTRIBUTIONS[vendor]}
				</Footnote>
			</HighlighterContainer>
		);
	}
}
