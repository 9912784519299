import * as React from 'react';
import styled from 'styled-components';
import Tooltip from 'react-tooltip';
import * as _ from 'lodash';

import Images from '../../shared/images';
import { USER_TRACKING } from '../../constants';
import { Company } from '../../types/types';
import UserTracking from '../../services/userTracking.service';
import * as ids from '../../../id.constants.js';
import { Colors } from '../../../components/shared/styles';
import moment from 'moment';

const UTC = USER_TRACKING.USER_TRACKING_CATEGORIES;
const UTA = USER_TRACKING.USER_TRACKING_ACTIONS;

const DropDownContainer = styled.div<{ width?: string }>`
	width: ${props => props.width || '500px'};
	margin-left: 20px;
	position: relative;
	float: left;
	height: 50px;
	box-sizing: border-box;
	line-height: 50px;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
`;

const DropDownBody = styled.div`
	position: absolute;
	top: 50px;
	left: -22px;
	min-width: 520px;
	max-height: 352px;
	z-index: 2;
	border-radius: 3px;
	box-sizing: border-box;
	overflow-y: auto;
	overflow-x: hidden;
	box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px #dde5e7;
	background-color: #fff;
	transition: 0.3s;
	user-select: none;
`;

interface OpenDropDownButtonProps {
	isOpen: boolean;
	isInvisible?: boolean;
}
const DropdownBtn = styled.div<OpenDropDownButtonProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	float: right;
	width: 30px;
	height: 40px;
	cursor: pointer;
	border-radius: 4px;
	border: solid 1px #dde5e7;
	background-color: ${props => (props.isOpen ? '#fff' : '#F4F7F7')};
`;

const ArrowDropDownRecent = styled.div<OpenDropDownButtonProps>`
	width: 10px;
	height: 5px;
	mask-image: url(${Images.arrowDownDropdown});
	mask-size: contain;
	mask-position: center;
	-webkit-mask-repeat: no-repeat;
	border-radius: 4px;
	border: solid 1px #dde5e7;
	background-color: ${props => (props.isOpen ? Colors.blue : Colors.dark)};
`;

const RowYearContainer = styled.div`
	width: 100%;
`;

const RowYear = styled.div`
	padding-left: 20px;
	font-weight: bold;
	line-height: 35px;
	color: #32313b;
	height: 35px;
	font-size: 16px;
	font-family: Assistant bold;
	background-color: #eef1f5;
`;

const DocumentTextWrapper = styled.div`
	display: flex;
`;

interface IsSelectedProp {
	isSelected: boolean;
}

const TextQuarterly = styled.div<IsSelectedProp>`
	height: 100%;
	width: 20px;
	line-height: 46px;
	margin-left: 18px;
	font-weight: bold;
	font-family: Assistant bold;
	color: ${props => (props.isSelected ? 'rgba(50,49,59,0.87)' : '#9b9b9b')};
`;

const TextDocument = styled.a<IsSelectedProp>`
	line-height: 46px;
	margin-left: 13px;
	color: rgba(50, 49, 59, 0.87);
	font-family: Assistant;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const IconOpenNewTab = styled.div<IsSelectedProp>`
	width: 18px;
	height: 18px;
	margin: 13px 9px 14px 33px;
	mask-image: url(${Images.outlineOpenNew});
	mask-size: contain;
	mask-position: center;
	-webkit-mask-repeat: no-repeat;
	background-color: ${props => (props.isSelected ? 'rgba(50,49,59,0.87)' : '#fff')};
`;

const RowDocument = styled.div<IsSelectedProp>`
	display: flex;
	justify-content: space-between;
	height: 46px;
	font-family: Assistant;
	font-size: 16px;
	cursor: pointer;
	background-color: ${props => (props.isSelected ? '#DBDBDB' : '#fff')};

	:hover {
		background-color: #dbdbdb;
	}
	&:hover ${TextQuarterly} {
		color: rgba(50, 49, 59, 0.87);
	}
	&:hover ${TextDocument} {
		color: rgba(50, 49, 59, 0.87);
	}
	&:hover ${IconOpenNewTab} {
		background-color: rgba(50, 49, 59, 0.87);
	}
`;

const EmptyContainer = styled.div`
	height: 50px;
	color: grey;
	text-align: center;
`;

export const Title = styled.div`
	float: left;
	width: 90%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: Assistant;
	font-size: 18px;
	color: #32313b;
`;

interface CompanyDocument {
	fiscalQuarter: any;
	title: any;
	documentId: any;
	eventDate: any;
	publicationDate: any;
	fiscalYear: any;
}
interface RecentDocumentsProps {
	currentCompany: Company;
	companyDocuments: CompanyDocument[];
	documentTitle: string;
	currentDocumentId: number;
	subscriptionId: string;
	changeDocument: Function;
	isInvisible?: boolean;
	source?: string;
	sourceUrl?: string;
}

interface RecentDocumentsState {
	isDropDownOpen: boolean;
}

export default class RecentDocumentsDropDown extends React.Component<
	RecentDocumentsProps,
	RecentDocumentsState
> {
	dropDownRef: HTMLDivElement;

	constructor(props) {
		super(props);
		this.state = {
			isDropDownOpen: false
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState);
	}

	componentDidUpdate() {
		const selectedElement = document.getElementById(
			`DOCUMENT_DROP_DOWN_${this.props.currentDocumentId}`
		);
		selectedElement && selectedElement.scrollIntoView({ block: 'nearest', inline: 'start' });
	}

	toggleDropDown = () => {
		if (this.state.isDropDownOpen) {
			this.closeDropDown();
			return;
		}
		this.openDropDown();
	};

	openDropDown = () => {
		const { documentTitle } = this.props;
		this.setState({ isDropDownOpen: true });
		document.addEventListener('mousedown', this.handleDropDownClick, true);
		const ut = new UserTracking();
		ut.setEvent(UTC.DOCUMENT_VIEW, UTA.PREVIOUS_DOCUMENT_CLICK, `curr_document_${documentTitle}`);
	};

	closeDropDown = () => {
		document.removeEventListener('mousedown', this.handleDropDownClick, true);
		this.setState({ isDropDownOpen: false });
	};

	handleDropDownClick = (e: any) => {
		if (this.dropDownRef.contains(e.target)) {
			return;
		}
		this.closeDropDown();
	};

	selectDocument = (document: CompanyDocument) => {
		const { changeDocument } = this.props;
		this.toggleDropDown();
		const ut = new UserTracking();
		ut.setEvent(
			UTC.DOCUMENT_VIEW,
			UTA.PREVIOUS_DOCUMENT_SELECTED,
			`selected_document_${document.title}`
		);
		changeDocument(document);
	};

	selectDocumentNewTab = (document: CompanyDocument, subscriptionId: string) => {
		const route = `subscription/${subscriptionId}/transcript/document/${document.documentId}`;
		const ut = new UserTracking();
		ut.setEvent(
			UTC.DOCUMENT_VIEW,
			UTA.PREVIOUS_DOCUMENT_OPEN_IN_TAB,
			`selected_document_${document.title}`
		);
		window.open(`${window.location.origin}/${route}`);
	};

	groupDocumentsPerYears = (
		companyDocuments: CompanyDocument[]
	): { year: number; documents: CompanyDocument[] }[] => {
		const fiscalYearGrouping = _.every(companyDocuments, document => !!document.fiscalYear);
		const groupedDocuments = _.groupBy(companyDocuments, d =>
			fiscalYearGrouping ? d.fiscalYear : moment(d.eventDate || d.publicationDate).year()
		);
		return _.chain(groupedDocuments)
			.map((d, y) => ({ year: Number(y), documents: d }))
			.orderBy('year', 'desc')
			.value();
	};

	getTranscriptDropdownItems = () => {
		const groupedDocuments = this.groupDocumentsPerYears(this.props.companyDocuments);
		if (!groupedDocuments) {
			return;
		}
		return _.map(groupedDocuments, group => (
			<RowYearContainer key={`title_year_${group.year}`}>
				<RowYear>{group.year}</RowYear>
				{this.getRecentDocumentTranscript(
					group.year,
					group.documents,
					this.props.currentDocumentId
				)}
			</RowYearContainer>
		));
	};

	getRecentDocumentTranscript = (
		year: number,
		yearDocuments: CompanyDocument[],
		currentDocumentId: number
	) => {
		return _.map(yearDocuments, (doc, i) => (
			<RowDocument
				key={`document_${year}_${doc.documentId}`}
				id={`DOCUMENT_DROP_DOWN_${doc.documentId}`}
				isSelected={doc.documentId === currentDocumentId}
			>
				<DocumentTextWrapper
					id={`${ids.DOCUMENT_PAGE.OPEN_NEW_DOCUMENT}${i}`}
					onClick={() => this.selectDocument(doc)}
				>
					<TextQuarterly isSelected={doc.documentId === currentDocumentId}>
						{doc.fiscalQuarter}
					</TextQuarterly>
					<TextDocument
						id={`document_${year}_${doc.documentId}`}
						isSelected={doc.documentId === currentDocumentId}
					>
						{doc.title}
					</TextDocument>
				</DocumentTextWrapper>
				<IconOpenNewTab
					id={`${ids.DOCUMENT_PAGE.OPEN_NEW_TAB_NEW_DOCUMENT_ICON}${i}`}
					isSelected={doc.documentId === currentDocumentId}
					onClick={() => this.selectDocumentNewTab(doc, this.props.subscriptionId)}
				/>
			</RowDocument>
		));
	};

	emptyResults = () => <EmptyContainer>No documents</EmptyContainer>;

	getDropDownBody = (companyDocuments: CompanyDocument[]) => {
		if (companyDocuments && companyDocuments.length > 0) {
			return this.getTranscriptDropdownItems();
		}
		return this.emptyResults();
	};

	render() {
		const { isDropDownOpen } = this.state;
		const { documentTitle, companyDocuments } = this.props;
		return (
			<DropDownContainer ref={node => (this.dropDownRef = node)} width={'500px'}>
				<Header>
					<Title>{documentTitle}</Title>
					<DropdownBtn
						id={ids.DOCUMENT_PAGE.RECENT_DOCUMENT_DROP_DOWN_BUTTON}
						data-tip={`More documents for current company`}
						data-for={`DOCUMENT_DROP_DOWN_TOOL_TIP`}
						isOpen={isDropDownOpen}
						onClick={this.toggleDropDown}
					>
						<ArrowDropDownRecent isOpen={isDropDownOpen} />
					</DropdownBtn>
					<Tooltip
						id='DOCUMENT_DROP_DOWN_TOOL_TIP'
						place='bottom'
						effect='solid'
						type='light'
						className='viewer-theme'
						delayHide={200}
					/>
				</Header>
				{isDropDownOpen && (
					<DropDownBody id='DOCUMENT_DROP_DOWN'>
						{this.getDropDownBody(companyDocuments)}
					</DropDownBody>
				)}
			</DropDownContainer>
		);
	}
}
