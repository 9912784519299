// TODO: remove file after viewer_document_filters is out
import * as React from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';

import { getQNAListHeight } from './participantsList.util';
import Images from '../../shared/images';
import { QNA, Speaker, QNAElement, SelectFunction } from '../../types/types';
import { SCROLL_IDS } from '../../constants';
import {
	ListContainer,
	InnerListContainer,
	ScrollContainer,
	Arrow
} from '../../pages/sideBarElements';
import * as ids from '../../../id.constants.js';
import SpeakerEntry from '../Transcript/SidebarElements/speakerEntry';

const SPEAKER_ITEM_HEIGHT = 65;
const QNA_ITEM_HEIGHT = 65;
const QNA_ITEM_SEPARATION = 10;
const QNA_ITEM_SEPARATOR_HEIGHT = 2;

const SectionHeader = styled.div`
	position: relative;
	height: 65px;
	line-height: 65px;
	padding-left: 14px;
	box-shadow: 0 11px 11px -11px rgba(0, 0, 0, 0.2);
	font-size: 15px;
	color: #4a4a4a;
	cursor: pointer;
	user-select: none;
`;

interface SpeakerDetailsProps {
	distanceFromLeftBorder: number;
	paddingTop: number;
	paddingLeft: number;
}
const SpeakerDetails = styled.div<SpeakerDetailsProps>`
	width: ${props => `calc(100% - ${props.distanceFromLeftBorder}px)`};
	height: 100%;
	float: left;
	padding-top: ${props => props.paddingTop}px;
	padding-left: ${props => props.paddingLeft}px;
	box-sizing: border-box;
`;

/**************** Speaker styles ******************/

const SpeakerAvatarContainer = styled.div`
	position: relative;
	width: 45px;
	height: 100%;
	float: left;
`;

const SpeakerAvatar = styled.img<{ left: number }>`
	position: absolute;
	top: 5px;
	left: ${props => props.left}px;
`;

const SpeakerName = styled.div`
	font-size: 15px;
	color: #4a4a4a;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const SpeakerDescription = styled.div`
	margin-top: 3px;
	font-size: 13px;
	color: #9b9b9b;
	font-family: Assistant;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

/**********************************************/

/**************** Q&A styles ******************/

const QNAElementDiv = styled.div``;

const InnerContainer = styled.div`
	height: ${QNA_ITEM_HEIGHT}px;
	cursor: pointer;
	padding: 5px 20px 0 5px;
	box-sizing: border-box;
	:hover {
		background-color: rgb(155, 155, 155, 0.1);
	}
`;

const TypeContainer = styled.div<{ color: string }>`
	width: 55px;
	height: 100%;
	box-sizing: border-box;
	float: left;
	padding-top: 12px;
	color: ${props => props.color};
	font-size: 24px;
	font-family: Assistant Light;
	user-select: none;
`;

const TypeText = styled.div`
	height: 100%;
	padding-left: 6px;
`;

const FirstLetter = styled.span``;

const RestOfLetters = styled.span`
	font-size: 13px;
`;

const QNASeparator = styled.div`
	width: 270px;
	height: ${QNA_ITEM_SEPARATOR_HEIGHT}px;
	background-color: #eeeeee;
	margin: 10px 0 0 10px;
`;

/**********************************************/

interface ParticipantsListProps {
	show: boolean;
	speakers: Speaker[];
	qnas: QNA[];
	onSpeakerClicked: SelectFunction;
	onQNAClicked: SelectFunction;
}

interface ParticipantsListState {
	isManagementDiscussionOpen: boolean;
	isQNAOpen: boolean;
}

export default class ParticipantsList extends React.Component<
	ParticipantsListProps,
	ParticipantsListState
> {
	constructor(props) {
		super(props);
		this.state = {
			isManagementDiscussionOpen: false,
			isQNAOpen: false
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState);
	}

	toggleManagementDiscussionSection = () => {
		this.setState({
			isManagementDiscussionOpen: !this.state.isManagementDiscussionOpen
		});
	};

	toggleQNASection = () => {
		this.setState({ isQNAOpen: !this.state.isQNAOpen });
	};

	onSpeakerSelected = (speakingIndex: number) => {
		this.props.onSpeakerClicked(speakingIndex, SCROLL_IDS.SPEAKER.ID);
	};

	onQNASelected = (QNAIndex: number) => {
		this.props.onQNAClicked(QNAIndex, SCROLL_IDS.QNA.ID);
	};

	getSpeakerElements = (speakers: Speaker[]) =>
		_.map(speakers, (speaker: Speaker) => (
			<SpeakerEntry
				key={`Speaker-${speaker.speakingIndex}`}
				speaker={speaker}
				onSelect={this.props.onSpeakerClicked}
			/>
		));

	getTypeText = (QNAElement: QNAElement, index: number, isAnswer: boolean): any => {
		const firstLetter = isAnswer ? 'A' : 'Q';
		const restOfLetters = `${index}`;

		return (
			<TypeText>
				<FirstLetter>{firstLetter}</FirstLetter>
				<RestOfLetters>{restOfLetters}</RestOfLetters>
			</TypeText>
		);
	};

	getColorForType = (QNAElement: QNAElement, isAnswer: boolean): string => {
		return isAnswer ? '#F44336' : '#4A4A4A';
	};

	isQnaOperator = (QNAElement: QNAElement): boolean => {
		return QNAElement && QNAElement.name === 'Operator';
	};

	isWithoutType = (QNAElement: QNAElement): boolean => {
		return !QNAElement || QNAElement.type === null;
	};

	getInnerContainerElement = (QNAElement: QNAElement, index: number, isAnswer: boolean): any => {
		const isWithoutType = this.isWithoutType(QNAElement);

		return (
			<InnerContainer onClick={() => this.onQNASelected(QNAElement.id)}>
				<TypeContainer color={this.getColorForType(QNAElement, isAnswer)}>
					{!isWithoutType && this.getTypeText(QNAElement, index, isAnswer)}
					{isWithoutType && (
						<SpeakerAvatarContainer>
							<SpeakerAvatar left={7} src={Images.speakerAvatarSidebar} />
						</SpeakerAvatarContainer>
					)}
				</TypeContainer>
				<SpeakerDetails paddingLeft={10} paddingTop={12} distanceFromLeftBorder={55}>
					<SpeakerName title={`${QNAElement?.name}`}>{QNAElement?.name}</SpeakerName>
					<SpeakerDescription title={this.getTitle(QNAElement)}>
						{QNAElement?.title}
						{QNAElement?.title && QNAElement?.affiliation && ', '}
						{QNAElement?.affiliation}
					</SpeakerDescription>
				</SpeakerDetails>
			</InnerContainer>
		);
	};

	getTitle = (QNAElement: QNAElement): string => {
		let result = '';

		if (QNAElement?.title) {
			result += QNAElement.title;
		}
		if (QNAElement?.affiliation) {
			if (QNAElement.title) {
				result += ', ';
			}

			result += QNAElement.affiliation;
		}

		return result;
	};

	getQNAAnswers = (answers: QNAElement[], questionNumber: number): any => {
		return _.map(answers, (answer: QNAElement, j: number) => {
			return <div key={`A${j}`}>{this.getInnerContainerElement(answer, questionNumber, true)}</div>;
		});
	};

	getQNAElements = (qnas: QNA[]) => {
		let questionNumber = 0;
		return _.map(qnas, (qna: QNA, i: number) => {
			if (!this.isQnaOperator(qna.question)) {
				questionNumber++;
			}
			return (
				<QNAElementDiv key={`QNA_${i}`}>
					{this.getInnerContainerElement(qna.question, questionNumber, false)}
					{qna.answerElements.length > 0 && this.getQNAAnswers(qna.answerElements, questionNumber)}
					<QNASeparator />
				</QNAElementDiv>
			);
		});
	};

	render() {
		const { isManagementDiscussionOpen, isQNAOpen } = this.state;
		const { speakers, qnas, show } = this.props;
		return (
			<ListContainer style={{ display: show ? 'block' : 'none' }}>
				<ScrollContainer>
					<SectionHeader
						onClick={this.toggleManagementDiscussionSection}
						id={ids.DOCUMENT_PAGE.MANAGEMENT_DISCUSSION_SECTION}
					>
						Management Discussion
						<Arrow myUrl={Images.arrowDownGrey} isOpen={isManagementDiscussionOpen} />
					</SectionHeader>
					<InnerListContainer
						isOpen={isManagementDiscussionOpen}
						myHeight={SPEAKER_ITEM_HEIGHT * speakers.length}
						id={ids.DOCUMENT_PAGE.MANAGEMENT_DISCUSSION_SECTION_IS_OPEN}
					>
						{this.getSpeakerElements(speakers)}
					</InnerListContainer>
					<SectionHeader
						onClick={this.toggleQNASection}
						id={ids.DOCUMENT_PAGE.QUESTIONS_AND_ANSWERS_SECTION}
					>
						Questions & Answers
						<Arrow myUrl={Images.arrowDownGrey} isOpen={isQNAOpen} />
					</SectionHeader>
					<InnerListContainer
						isOpen={isQNAOpen}
						myHeight={getQNAListHeight(
							qnas,
							QNA_ITEM_HEIGHT,
							QNA_ITEM_SEPARATION,
							QNA_ITEM_SEPARATOR_HEIGHT
						)}
						id={ids.DOCUMENT_PAGE.QUESTIONS_AND_ANSWERS_SECTION_IS_OPEN}
					>
						{this.getQNAElements(qnas)}
					</InnerListContainer>
				</ScrollContainer>
			</ListContainer>
		);
	}
}
