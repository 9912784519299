import * as React from 'react';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { getObject } from '../../services/localstorage.service';
import { LOCAL_STORAGE_KEYS, PRINT_LAYOUTS } from '../../constants';
import { getUserLocalTimeFromUTC } from '../../helpers.timezone';
import { Event } from '../../types/types';
import { EventsList, Page } from '../../../components/Print/printPageElements';
import { limitArraysByLength } from '../../services/util.service';
import PrintEventElement from '../../../components/Print/printEventElement';
import PrintedPage from '../../../components/Print/printedPage';
import PrintPopup from '../../components/PrintLayoutPopup/printPopup';
import Transcript from '../transcript/Transcript/transcript';
import eventElementsState from '../../recoil/eventElements';
import currentProviderState from '../../recoil/currentProvider';
import Spinner from '../../widgets/Spinner/spinner';
import { useTranscriptDataForPrint } from './printTranscript.service';

const PAGE_BREAK_MARGIN = 20;

interface PrintTranscriptPageNewarcProps {
	match: any;
}

const PrintTranscriptPageNewarc: React.FunctionComponent<PrintTranscriptPageNewarcProps> = ({
	match
}) => {
	const [showPopup, setShowPopup] = useState(false);
	const [showSpinner, setShowSpinner] = useState(true);
	const [printState, setPrintState] = useState(false);
	const [readyForPrint, setReadyForPrint] = useState(false);
	const [fontsLoaded, setFontsLoaded] = useState(false);
	const [selectedLayout, setSelectedLayout] = useState(undefined);
	const eventElements = useRecoilValue(eventElementsState);
	const currentProvider = useRecoilValue(currentProviderState);
	const [eventsList, setEventsList] = useState([]);
	const params = (match && match.params) || {};
	const subscriptionId = params.subscriptionId;
	const documentId = params.documentId;

	const {
		title,
		filteredEvents: events,
		updateTime,
		eventTime,
		discussionSentences,
		qnaSentences,
		vendor
	} = useTranscriptDataForPrint(subscriptionId, documentId, currentProvider);

	window.onafterprint = event => {
		setPrintState(false);
	};

	// set layout and fonts the first time the page is rendered
	useEffect(() => {
		(document as any).fonts.ready.then(() => {
			setSelectedLayout(
				(getObject(LOCAL_STORAGE_KEYS.SELECTED_PRINT_LAYOUT) as any) || PRINT_LAYOUTS[0]
			);
			setFontsLoaded(true);
		});
	}, []);

	// when printState becomes true, open the print window of the browser
	useEffect(() => {
		readyForPrint && printState && window.print();
		// readyForPrint is not added to the dependencies list
		// because readyForPrint is not a trigger for printing
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [printState]);

	const dismissNoticePopupNew = () => {
		setShowPopup(false);
		setPrintState(true);
	};

	const groupEventsByPage = () => {
		// after the events are filtered, their indices need to be updated
		events.forEach((event, index) => (event.indexInTranscript = index));
		const obj = _.pickBy(
			// assign each event to a page
			_.groupBy(
				events,
				(e: Event) => {
					const element = _.find(eventElements, { id: e.id });
					if (element?.ref?.current && element.ref.current.getBoundingClientRect().top) {
						const top = element.ref.current.getBoundingClientRect().top;
						const pageNum = selectedLayout.pageHeight
							? Math.floor(top / selectedLayout.pageHeight) + 1
							: 1;
						const pageBreakMarginCoefficient = pageNum === 1 ? pageNum : pageNum / 2;
						const correctedTop = top + pageBreakMarginCoefficient * PAGE_BREAK_MARGIN;
						const x = selectedLayout.pageHeight
							? Math.floor(correctedTop / selectedLayout.pageHeight)
							: 0;
						return x;
					}
				}
				// remove events with no page
			),
			(value, key) => +key > -1
		);
		// insert empty pages if any
		_.range(0, _.max(Object.keys(obj).map(k => +k))).forEach((n: number) => {
			if (obj[n] === undefined) {
				obj[n] = [];
			}
		});
		return obj;
	};

	const orderEventsByPageNumber = eventsGroupedByPage => {
		const eventsOrderedByPageNumber = _.orderBy(
			Object.keys(eventsGroupedByPage).map(key => ({ key: +key, value: eventsGroupedByPage[key] })),
			'key'
		).map(o => o.value);
		return eventsOrderedByPageNumber;
	};

	const createEventsUIComponentsPerPage = (eventsOrderedByPageNumber: unknown[]) => {
		const eventsListOrderedByPage = limitArraysByLength(
			eventsOrderedByPageNumber,
			selectedLayout.maxNumOfEventsOnPage
		).map((events: Event[], i: number) => {
			const eventsOnPage = _.reduce(
				events,
				(resultOfReducingEvents, event: Event) => {
					if (!!event.keyDrivers) {
						return [...resultOfReducingEvents, ...getKeyDriversDisplay(event)];
					}
					return [
						...resultOfReducingEvents,
						getEventDisplay(
							event.indexInTranscript,
							event.polarity,
							event.categoryName,
							event.typeName
						)
					];
				},
				[]
			);
			return (
				<Page key={i} pageHeight={selectedLayout.pageHeight}>
					{eventsOnPage}
				</Page>
			);
		});
		return eventsListOrderedByPage;
	};

	// get the events list to show on the right-side column of the print page
	useEffect(() => {
		if (selectedLayout === undefined) {
			return;
		}
		if (events?.length === undefined || eventElements?.length < events?.length) {
			return;
		}
		const eventsGroupedByPage = groupEventsByPage();
		const eventsOrderedByPageNumber = orderEventsByPageNumber(eventsGroupedByPage);
		const eventsListOrderedByPage = createEventsUIComponentsPerPage(eventsOrderedByPageNumber);
		setEventsList(eventsListOrderedByPage);
		setShowSpinner(false);
		setShowPopup(true);
		setReadyForPrint(true);
		// Despite eslint warning to add to the dependencies list all of the variables used in the useEffect,
		// some of those variables (namely, events and setEventsList) are omitted:
		// - setEventsList is not expected to change
		// - If the variable events changes, then eventElements also changes so events can be omitted.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eventElements, selectedLayout, events?.length]);

	const getEventDisplay = (indexInTranscript, polarity, category, typeName) => (
		<PrintEventElement
			key={`${indexInTranscript}_${category}`}
			hash={indexInTranscript + 1}
			polarity={polarity}
			categoryName={category}
			typeName={typeName || 'Annotation'}
		/>
	);

	const getKeyDriversDisplay = (event: Event) =>
		_.map(event.keyDrivers, keyDriver =>
			getEventDisplay(event.indexInTranscript, event.polarity, keyDriver, event.typeName)
		);

	const prettifyTime = time => {
		return new Date(time).toLocaleString('en-US', { hour12: true });
	};

	const printDetails = [
		{ name: 'Event Time', value: getUserLocalTimeFromUTC(eventTime) },
		{ name: 'Transcript Analysis Time', value: prettifyTime(updateTime) },
		{ name: 'Print Time', value: new Date().toLocaleString('en-US', { hour12: true }) }
	];

	return (
		<PrintedPage
			title={title}
			details={printDetails}
			text={
				<Transcript
					vendor={vendor}
					discussionSection={discussionSentences}
					qnaSection={qnaSentences}
					selectedEventId={events ? events[0]?.eventId : '123'}
					printMode
				/>
			}
			events={
				<EventsList>{selectedLayout && fontsLoaded && events?.length && eventsList}</EventsList>
			}
			popup={showPopup && <PrintPopup dismiss={dismissNoticePopupNew} />}
			spinner={showSpinner && <Spinner />}
		/>
	);
};

export default PrintTranscriptPageNewarc;
