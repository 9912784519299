import _ from 'lodash';
import axios from 'axios';
import { Document } from '../../types/types';
import { codeFromDocumentType } from '../../types/DocTypes.types';
import {
	COMPANIES_CHUNK_SIZE,
	LOCAL_STORAGE_KEYS,
	USER_TRACKING as UT,
	COMPANIES_FETCH_TIMEOUT_SEC
} from '../../constants';
import { error } from '../../services/logger.service';
import { GRAPHQL_SERVER_REMOTE_URL } from '../../config';
import { getString } from '../../services/localstorage.service';
import UserTracking from '../../services/userTracking.service';

const UTC = UT.USER_TRACKING_CATEGORIES;
const UTA = UT.USER_TRACKING_ACTIONS;

/*
this is special case to use fetch and not apollo. (only for this scenario)
apollo hooks always returns us data and make the all components re-render.
we took all the relevant id's, chunked them, and gett all the data async.

	in the future, if we whant to support multiple request simultaneously, we will use this url: `https://viewergraphql${Math.min((idx % 5), 4)}.amenityanalytics.com/graphql`,
*/
const axiosWrapper = axios.create({
	url: GRAPHQL_SERVER_REMOTE_URL(),
	method: 'POST',
	headers: {
		'x-amenity-source': 'web'
	}
});

axiosWrapper.interceptors.response.use(undefined, err => {
	const config = err.config;

	if (!config || !config.retry) {
		return Promise.reject(err);
	}

	config.__retryCount = config.__retryCount || 0;

	if (config.__retryCount >= config.retry) {
		return Promise.reject(err);
	}

	config.__retryCount += 1;

	const backoff = new Promise(resolve => setTimeout(resolve, config.retryDelay || 1));

	return backoff.then(() => axios(config));
});

const getCompaniesById = (subscriptionId, currentDocumentType, currentProvider) => companiesIds =>
	// @ts-ignore
	axiosWrapper({
		headers: {
			Authorization: `Bearer ${getString(LOCAL_STORAGE_KEYS.TOKEN)}`
		},
		retry: 3,
		retryDelay: 1000,
		data: {
			operationName: 'GetWatchlistCompanies',
			variables: {
				subscriptionId,
				companiesIds: companiesIds,
				documentType: codeFromDocumentType(currentDocumentType, currentProvider)
			},
			query: `query GetWatchlistCompanies(
			$subscriptionId: String!
			$companiesIds: [String!]!
			$documentType: DocumentType!
		) {
			organizationSubscription(id: $subscriptionId) {
				entities: companyEntities(ids: $companiesIds) {
					id
					name
					gics {
						sector
					}
					mainIdentifier {
						ticker
						region
					}
					searchFinancialDocuments(
						first: 4
						startTime: "01-01-2010"
						documentTypes: [$documentType]
					) {
						documents {
							eventTime
							documentVersions: versions {
								documentId
								title: displayName
								aggregatedSections {
									name
									score
								}
							}
						}
					}
				}
			}
		}`
		}
	});

export const getAllCompanies = (
	watchlistId,
	subscriptionId,
	currentDocumentType,
	currentProvider
) =>
	new Promise(async (resolve, reject) => {
		const data = [];

		if (!subscriptionId || !currentDocumentType) {
			return;
		}

		let watchlistData;

		try {
			const { data } = await axiosWrapper({
				headers: {
					Authorization: `Bearer ${getString(LOCAL_STORAGE_KEYS.TOKEN)}`
				},
				data: {
					operationName: 'GetWatchlistWithData',
					variables: {
						watchlistId: watchlistId
					},
					query: `query GetWatchlistWithData($watchlistId: String) {
					watchlist(id: $watchlistId) {
						name
						id
						companyEntities {
							id
							mainIdentifier {
								ticker
								region
							}
						}
					}
				}`
				}
			});

			watchlistData = data;
		} catch (err) {
			error({
				message: `There was an error loading the watchlist: ${watchlistId}, Error: ${err}`,
				file: 'WatchlistProvider',
				functionName: 'getWatchlistData'
			});
		}

		const sortedCompanies = sortCompaniesByTicker(watchlistData?.data?.watchlist?.companyEntities);
		const fetchedIds = _.map(sortedCompanies, 'id');
		const chunkedIds = _.chunk(fetchedIds, COMPANIES_CHUNK_SIZE);

		const getCompaniesPromise = axios.all(
			chunkedIds.map(getCompaniesById(subscriptionId, currentDocumentType, currentProvider))
		);
		const fakePromise = new Promise(resolve => {
			setTimeout(resolve, COMPANIES_FETCH_TIMEOUT_SEC * 1000, undefined);
		});

		await Promise.race([getCompaniesPromise, fakePromise]).then((res: any) => {
			if (!res) {
				reject('TIMEOUT_ERROR');
				return;
			}

			res.forEach(cur => {
				const restructed = queryCompaniesRestructured(
					cur?.data?.data?.organizationSubscription?.entities
				);
				data.push(restructed);
			});

			resolve(_.flatten(data));
		});
	});

export const addCompany = (
	watchlistId,
	companyId,
	subscriptionId,
	currentDocumentType,
	currentProvider
) =>
	new Promise(async resolve => {
		if (!subscriptionId || !currentDocumentType) {
			return;
		}
		const userTracking: UserTracking = UserTracking.getInstance();

		const res = await getCompaniesById(
			subscriptionId,
			currentDocumentType,
			currentProvider
		)([companyId]);
		const restructed = queryCompaniesRestructured(
			res.data?.data?.organizationSubscription?.entities
		)[0];

		try {
			axiosWrapper({
				headers: {
					Authorization: `Bearer ${getString(LOCAL_STORAGE_KEYS.TOKEN)}`
				},
				data: {
					operationName: 'AddCompanyToWatchlist',
					variables: {
						portfolioId: watchlistId,
						factsetId: companyId
					},
					query: `mutation AddCompanyToWatchlist($portfolioId: String, $factsetId: String) {
						addCompanyToWatchlist(id: $portfolioId, company: $factsetId) {
							id
							companyEntities {
								id
							}
						}
					}
				`
				}
			});
			userTracking.setEvent(UTC.WACTHLIST, UTA.COMPANY_ADDED, restructed.ticker, {
				totalWatchlistSize: -1
			});
		} catch (err) {
			error({
				message: `Could not add company to watchlist, ${err}`,
				file: 'WatchlistPage',
				functionName: 'addCompanyToPortfolio'
			});
		}

		resolve(restructed);
	});

export const removeCompany = (watchlistId, companyId, subscriptionId, currentDocumentType) =>
	new Promise(async resolve => {
		if (!subscriptionId || !currentDocumentType) {
			return;
		}
		const userTracking: UserTracking = UserTracking.getInstance();

		try {
			const curWatchlist: any = axiosWrapper({
				headers: {
					Authorization: `Bearer ${getString(LOCAL_STORAGE_KEYS.TOKEN)}`
				},
				data: {
					operationName: 'RemoveCompanyFromWatchlist',
					variables: {
						portfolioId: watchlistId,
						factsetId: companyId
					},
					query: `mutation RemoveCompanyFromWatchlist($portfolioId: String, $factsetId: String) {
					removeCompanyFromWatchlist(id: $portfolioId, company: $factsetId) {
						id
						companyEntities {
							id
						}
					}
				}`
				}
			});

			userTracking.setEvent(UTC.WACTHLIST, UTA.COMPANY_REMOVED, companyId, {
				totalWatchlistSize: curWatchlist?.companyEntities?.length
			});
		} catch (err) {
			error({
				message: `Could not remove company from watchlist, ${err}`,
				file: 'WatchlistPage',
				functionName: 'callRemoveCompanyFromWatchlist'
			});
		}

		resolve(companyId);
	});

export const getTotalScore = aggregatedSections => {
	return aggregatedSections?.find(section => {
		return section.name === 'total';
	})?.score;
};

const restructureLatestDocument = (latestDocument: any) => {
	return {
		eventDate: latestDocument?.eventTime,
		documentVersions: latestDocument?.documentVersions
	};
};

const queryDocumentsRestructured = (documents: any[]): Document[] => {
	return _.map(documents, doc => {
		const aggregatedSections = doc.documentVersions[0]?.aggregatedSections;
		const totalScore = getTotalScore(aggregatedSections);

		return {
			documentId: doc.id,
			title: doc.documentVersions[0].title,
			eventDate: doc.eventTime,
			publicationDate: doc.eventTime,
			total_daily_sentiment_score: totalScore
		};
	});
};

export const queryCompaniesRestructured = (companies: any[]) => {
	return _.map(companies, company => {
		return {
			id: company.id,
			name: company.name,
			sector: company.gics?.sector,
			ticker: company.mainIdentifier?.ticker,
			region: company.mainIdentifier?.region,
			documents: queryDocumentsRestructured(company.searchFinancialDocuments.documents),
			score: getTotalScore(
				company.searchFinancialDocuments.documents[0]?.documentVersions[0]?.aggregatedSections
			),
			prev_score: getTotalScore(
				company.searchFinancialDocuments.documents[1]?.documentVersions[0]?.aggregatedSections
			),
			latestDocument: restructureLatestDocument(company.searchFinancialDocuments.documents[0])
		};
	});
};

export const sortCompaniesByTicker = (companies: any[]): any[] =>
	_.sortBy(companies, company => company?.mainIdentifier?.ticker);
