import * as React from 'react';
import { inject, observer } from 'mobx-react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CategoriesTab from './categoriesTab';
import ClientDomainsTab from './clientDomainsTab';
import EventsTab from './eventsTab';
import AnnotationsTab from './annotationsTab';
import DevelopmentTab from './developmentTab';
import CollectionsTab from './collectionsTab';
import ConfigurationTab from './configurationTab';
import styled from 'styled-components';
import { STORE_CONFIG } from './../../constants';
import ConfigStore from '../../stores/data.store';

const styles = {
	paper: {
		maxHeight: '300px',
		overflow: 'auto'
	},
	sidebar: {
		width: '250px',
		height: '100%',
		borderRight: '1px solid rgba(0, 0, 0, 0.12)',
		position: 'absolute' as 'absolute',
		left: '0',
		top: '0'
	},
	title: {
		height: '70px',
		lineHeight: '70px'
	},
	blue: {
		color: '#03aeef',
		fontSize: '1rem'
	},
	capitalized: {
		textTransform: 'capitalize' as 'capitalize'
	},
	selected: {
		backgroundColor: 'rgba(0, 0, 0, 0.08)'
	}
};

interface BackofficeProps {
	classes: any;
}

interface BackofficeState {
	tabs: string[];
	selected: string;
}

const ContainerDiv = styled.div`
	position: relative;
	padding-left: 250px;
	height: 100vh;
`;

@inject(STORE_CONFIG)
@observer
class BackofficePage extends React.Component<BackofficeProps, BackofficeState> {
	constructor(props: BackofficeProps) {
		super(props);
		this.state = {
			tabs: [
				'client_domains',
				'collections',
				'categories',
				'events',
				'annotations',
				'configurations',
				'development'
			],
			selected: 'client_domains'
		};
	}

	selectTab = selected => {
		this.setState({ selected });
	};

	buildTabs = (tabs, selected, classes) => {
		return tabs.map(t => {
			return (
				<ListItem
					button
					divider
					key={t}
					className={`${t === selected ? classes.selected : ''}`}
					onClick={() => this.selectTab(t)}
					id={`BACKOFFICE_NAV_BUTTON_${t.toUpperCase()}`}
				>
					<ListItemText className={`${classes.capitalized}`} primary={t.split('_').join(' ')} />
				</ListItem>
			);
		});
	};

	content = selected => {
		// @ts-ignore
		const { apiEnv } = this.props[STORE_CONFIG] as ConfigStore;
		switch (selected) {
			case 'client_domains':
				return <ClientDomainsTab />;
			case 'collections':
				return <CollectionsTab />;
			case 'categories':
				return <CategoriesTab />;
			case 'events':
				return <EventsTab />;
			case 'annotations':
				return <AnnotationsTab />;
			case 'configurations':
				return <ConfigurationTab env={apiEnv} />;
			case 'development':
				return <DevelopmentTab env={apiEnv} />;
			default:
				return <div>select tab</div>;
		}
	};

	render() {
		const { tabs, selected } = this.state;
		const { classes } = this.props;
		return (
			<ContainerDiv>
				<List component='nav' className={classes.sidebar}>
					<ListItem divider className={classes.title}>
						<ListItemText
							disableTypography
							primary={<Typography className={classes.blue}>Back Office</Typography>}
						/>
					</ListItem>
					{this.buildTabs(tabs, selected, classes)}
				</List>
				{this.content(selected)}
			</ContainerDiv>
		);
	}
}

export default withStyles(styles)(BackofficePage);
