import { atom, selector } from 'recoil';
import { DocumentType } from '../../types/DocTypes.types';

const currentDocumentType = atom<DocumentType>({
	key: 'currentDocumentType',
	default: undefined
});

const changeDocument = selector({
	key: 'changeDocument',
	get: ({ get }) => get(currentDocumentType),
	set: ({ set }, newDocumentType: any) => {
		set(currentDocumentType, newDocumentType);
	}
});

export default changeDocument;
