import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { Appearance, Colors, FontFamilies, FontSizes, Sizes } from '../shared/styles';
import { Icon } from '../Icon/Icon';

interface StyledButtonProps {
	appearance?: Appearance;
	disabled?: boolean;
	active?: boolean;
	size?: Sizes;
	withText?: boolean;
	withIcon?: boolean;
	withMenuArrow?: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
	padding: ${({ withText, withIcon, withMenuArrow }) =>
		`${withIcon ? (withText ? '0 12px' : '0') : withMenuArrow ? '0 12px' : '0 38px'}`};
	line-height: 22px;
	font-family: ${FontFamilies.semiBold}
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	user-select: none;
	min-width: 40px;
	${({ appearance, disabled, active }) => {
		switch (appearance) {
			case Appearance.primary:
				return `
				background-color: ${disabled ? Colors.gray : Colors.blue};
				border: 1px solid ${disabled ? Colors.gray : Colors.blue};
				color: ${Colors.white};
				&:hover {
					${!disabled &&
						`
						background-color: ${Colors.lightBlue};
						color: ${Colors.blue};
						border-color: ${Colors.lightBlue};
					`}
				}
			`;
			case Appearance.secondary:
				return `
				background-color: ${active ? Colors.lightBlue : 'transparent'};
				border: 1px solid ${Colors.blue};
				color: ${Colors.blue};
				opacity: ${disabled ? 0.3 : 1};
				&:hover {
					background-color: ${!disabled && Colors.lightBlue};
				}
				`;
			case Appearance.tertiary:
				return `
				background-color: transparent;
				border: 1px solid ${Colors.darkGray};
				color: ${Colors.darkGray};
				opacity: ${disabled ? 0.3 : 1};
			`;
		}
	}}
	${({ size }) => {
		switch (size) {
			case Sizes.small:
				return `
					height: 28px;
					border-radius: 5px;
					font-size: ${FontSizes.s2};
			`;
			case Sizes.medium:
				return `
					height: 40px;
					border-radius: 5px;
					font-size: ${FontSizes.s3};
			`;
			case Sizes.large:
				return `
					height: 56px;
					border-radius: 3px;
					width: 100%;
					font-size: ${FontSizes.s3};
			`;
		}
	}};
	> img,
	> span {
		vertical-align: middle;
	}
	${({ withText }) => withText && '> img { margin-right: 10px; }'}
	${({ withMenuArrow, size }) =>
		withMenuArrow &&
		`
			&:after {
				content: '\\25BE';
				margin-left: ${size === Sizes.medium ? '18px' : '12px'};
			}
		`}
`;

export interface ButtonProps {
	id?: string;
	size?: Sizes;
	appearance?: Appearance;
	text?: ReactNode;
	icon?: string;
	disabled?: boolean;
	active?: boolean;
	withMenuArrow?: boolean;
	onClick?(event?: any): void;
	className?: string;
}

export const Button: FunctionComponent<ButtonProps> = ({
	id,
	size = Sizes.medium,
	appearance = Appearance.primary,
	text,
	icon,
	onClick,
	disabled = false,
	active = false,
	withMenuArrow = false,
	className,
	children
}) => (
	<StyledButton
		id={id}
		size={size}
		appearance={appearance}
		onClick={disabled ? undefined : onClick}
		disabled={disabled}
		active={active}
		className={className}
		withText={!!text}
		withIcon={!!icon}
		withMenuArrow={withMenuArrow}
	>
		{icon && <Icon src={icon} />}
		{text && <span>{text}</span>}
		{children}
	</StyledButton>
);
