import * as React from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';
import Modal from 'react-modal';
import { EventType, DropDownItem, Category, Collection } from '../../types/types';
import Images from '../../shared/images';
import DropDown from '../../widgets/DropDown/dropDown';
import ModalStyles from '../../shared/styled/general-modal-style';

const PopTitle = styled.div`
	color: #fff;
	margin-left: 40px;
	padding-top: 20px;
	font-weight: 600;
`;

const PopUpWarpTitle = styled.div`
	background-color: #586876;
	height: 60px;
	width: 100%;
	top: 0;
	left: 0;
`;

const InputTitle = styled.div`
	height: 24px;
	color: #4a4a4a;
	font-size: 13px;
	line-height: 24px;
	text-align: left;
	margin: 20px auto 0 0;
	width: 100%;
`;

const SubmitWrap = styled.div`
	position: absolute;
	right: 63px;
	bottom: 20px;
	width: 180px;
	font-size: 13px;
	font-weight: 600;
	height: 32px;
`;

interface ConfirmButtonProps {
	isConfirm: boolean;
}

const ConfirmButton = styled.button<ConfirmButtonProps>`
	width: 85px;
	height: 30px;
	float: right;
	margin-left: ${props => (props.isConfirm ? '10px' : '0')};
	font-size: 13px;
	color: ${props => (props.isConfirm ? '#fff' : '#696868')};
	background-color: ${props => (props.isConfirm ? '#00aeef' : '#ccc')};
	cursor: pointer;
`;

const ClosePopUp = styled.div`
	background-image: url("${Images.close}");
	position: absolute;
	right: -1px;
	top: 2px;
	width: 43px;
	height: 43px;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 13px;
	z-index:1;
`;

const ErrorSpan = styled.div`
	position: absolute;
	color: red;
	font-size: 10px;
`;

const Input = styled.input`
	width: 340px;
	border: 1px solid #ccc;
	padding: 5px;
`;

const InputWrap = styled.div`
	position: relative;
	margin-top: 35px;
	margin-left: 20px;
`;

const DropDownWrap = styled.div`
	width: 156px;
	height: 24px;
`;

interface AddOrUpdateEventPopUpProps {
	type: number;
	modalIsOpen: boolean;
	closePopup: Function;
	event?: EventType;
	addOrEditFunc: Function;
	collection?: Collection;
	categories: Category[];
	eventTypes: EventType[];
	collections: Collection[];
}

interface AddOrUpdateEventPopUpState {
	id: number;
	eventName: string;
	categoryName: string;
	currentCollection: Collection;
	isEventNameErr: boolean;
	eventNameErr: string;
	categoryErr: boolean;
	sortedOptionID: number | string;
}

export default class AddOrUpdateEventPopUp extends React.Component<
	AddOrUpdateEventPopUpProps,
	AddOrUpdateEventPopUpState
> {
	constructor(props: AddOrUpdateEventPopUpProps) {
		super(props);
		Modal.setAppElement('#root');
		if (this.props.type === 0) {
			this.state = {
				id: -1,
				eventName: '',
				currentCollection: {
					id: this.props.categories[0].collection_id,
					name: this.props.categories[0].collection_name
				},
				categoryName: this.props.categories[0].name,
				categoryErr: false,
				isEventNameErr: false,
				sortedOptionID: 0,
				eventNameErr: ''
			};
		} else if (this.props.type === 1) {
			this.state = {
				id: this.props.event.id,
				eventName: this.props.event.eventName,
				categoryName: this.props.event.categoryName,
				currentCollection: this.props.collection,
				categoryErr: false,
				isEventNameErr: false,
				sortedOptionID: 0,
				eventNameErr: ''
			};
		}
	}

	updateEventNameInput = evt => {
		this.setState({ eventName: evt.target.value });
	};

	getTitle = () => {
		if (this.props.type === 0) {
			return 'Add Event';
		} else if (this.props.type === 1) {
			return 'Edit Event';
		}
	};

	saveEvent = () => {
		if (this.props.type === 0) {
			this.addNewEvent();
		} else if (this.props.type === 1) {
			this.editEvent();
		}
	};

	addNewEvent = () => {
		this.setState({ categoryErr: false, isEventNameErr: false });
		if (!this.verifyInput()) {
			return;
		}
		const cat = _.find(
			this.props.categories,
			(c: Category) =>
				c.name === this.state.categoryName &&
				c.collection_name === this.state.currentCollection.name
		);
		const newEvent = {
			eventName: this.state.eventName,
			categoryId: cat ? cat.id : -1,
			categoryName: this.state.categoryName,
			collectionName: this.state.currentCollection.name
		};
		this.props.addOrEditFunc(newEvent);
		this.props.closePopup();
	};

	editEvent = () => {
		if (!this.verifyInput()) {
			return;
		}
		const cat = _.find(
			this.props.categories,
			(c: Category) =>
				c.name === this.state.categoryName &&
				c.collection_name === this.state.currentCollection.name
		);
		const editEvent = {
			id: this.state.id,
			categoryId: cat ? cat.id : -1,
			eventName: this.state.eventName,
			categoryName: this.state.categoryName,
			collectionName: this.state.currentCollection.name
		};
		this.props.addOrEditFunc(editEvent);
		this.props.closePopup();
	};

	verifyInput = (): boolean => {
		let eventsWithSameName = _.filter(
			this.props.eventTypes,
			e => e.eventName.toLowerCase().trim() === this.state.eventName.toLowerCase().trim()
		);
		if (this.props.type === 1) {
			eventsWithSameName = _.filter(eventsWithSameName, e => e.id !== this.props.event.id);
		}
		let isValid = true;
		for (let i = 0; i < eventsWithSameName.length; i++) {
			const e = eventsWithSameName[i];
			if (
				e &&
				e.categoryName === this.state.categoryName &&
				e.collectionName === this.state.currentCollection.name
			) {
				this.setState({
					isEventNameErr: true,
					eventNameErr: 'Event with the same name already exists in this category'
				});
				isValid = false;
			}
		}

		if (!this.state.eventName.trim()) {
			this.setState({
				isEventNameErr: true,
				eventNameErr: 'Event cannot be empty'
			});
			isValid = false;
		}
		if (!this.state.categoryName) {
			this.setState({ categoryErr: true });
			isValid = false;
		}
		return isValid;
	};

	handleCategorySelection = (catId: number) => {
		const name = _.filter(this.props.categories, c => c.id === catId)[0].name;
		this.setState({ categoryName: name, categoryErr: false });
	};

	handleCollectionSelection = (collectionName: string) => {
		const collection = _.find(this.props.collections, (c: Collection) => c.name === collectionName);
		const categories = _.filter(
			this.props.categories,
			cat => cat.collection_name === collectionName
		);
		this.setState({
			currentCollection: collection,
			categoryName: categories[0].name,
			categoryErr: false
		});
	};

	render() {
		const { categories, modalIsOpen, type, collections } = this.props;
		if (categories.length === 0 || (!this.props.event && type === 1)) {
			return <div />;
		}
		const {
			categoryName,
			currentCollection,
			eventName,
			eventNameErr,
			isEventNameErr,
			categoryErr
		} = this.state;
		const filteredCats = _.filter(
			categories,
			(c: Category) => currentCollection && c.collection_id === currentCollection.id
		);
		const currentCat = _.find(filteredCats, c => c.name === categoryName);
		const categoriesDropDownItems = _.map(filteredCats, (c: Category) => {
			const res: DropDownItem = {
				id: c.id,
				title: c.name
			};
			return res;
		});
		const collectionsDropDownItems: DropDownItem[] = _.map(collections, (c: Collection) => {
			const res: DropDownItem = {
				id: c.name,
				title: c.name
			};
			return res;
		});
		return (
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => this.props.closePopup()}
				style={ModalStyles(430, 450)}
			>
				<ClosePopUp
					onClick={() => this.props.closePopup()}
					id='BACKOFFICE_EVENTS_ADDEDIT_MODAL_CLOSE_BUTTON'
				/>
				<PopUpWarpTitle>
					<PopTitle>{this.getTitle()}</PopTitle>
				</PopUpWarpTitle>
				<InputWrap>
					<InputTitle>Event Name:</InputTitle>
					<Input
						type='text'
						value={eventName}
						onChange={this.updateEventNameInput}
						id='BACKOFFICE_EVENTS_ADDEDIT_MODAL_EVENT_NAME_FIELD'
					/>
					{isEventNameErr && <ErrorSpan id='ERROR_ADDING_EVENT'>{eventNameErr}</ErrorSpan>}
				</InputWrap>
				<InputWrap>
					<InputTitle>Collection:</InputTitle>
					<DropDownWrap id='BACKOFFICE_EVENTS_ADDEDIT_MODAL_COLLECTION_NAME_DROPDOWN'>
						<DropDown
							type={2}
							maxHeightBody={160}
							height={25}
							width={352}
							arrowTop={-2}
							mainLabelPrefix={''}
							defaultSelection={currentCollection.name}
							data={collectionsDropDownItems}
							handleDropDownChange={collectionName =>
								this.handleCollectionSelection(collectionName)
							}
						/>
					</DropDownWrap>
				</InputWrap>
				<InputWrap>
					<InputTitle>Category Name:</InputTitle>
					<DropDownWrap id='BACKOFFICE_EVENTS_ADDEDIT_MODAL_CATEGORY_NAME_DROPDOWN'>
						<DropDown
							type={2}
							maxHeightBody={100}
							height={25}
							width={352}
							arrowTop={-2}
							mainLabelPrefix={''}
							defaultSelection={currentCat ? currentCat.id : -1}
							data={categoriesDropDownItems}
							handleDropDownChange={cat => this.handleCategorySelection(cat)}
						/>
					</DropDownWrap>
					{categoryErr && <ErrorSpan>Category name is required</ErrorSpan>}
				</InputWrap>
				<SubmitWrap>
					<ConfirmButton
						isConfirm
						onClick={() => this.saveEvent()}
						id='BACKOFFICE_EVENTS_ADDEDIT_MODAL_SAVE_BUTTON'
					>
						Save
					</ConfirmButton>
					<ConfirmButton
						isConfirm={false}
						onClick={() => this.props.closePopup()}
						id='BACKOFFICE_EVENTS_ADDEDIT_MODAL_CANCEL_BUTTON'
					>
						Cancel
					</ConfirmButton>
				</SubmitWrap>
			</Modal>
		);
	}
}
