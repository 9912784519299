import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import * as _ from 'lodash';
import { Organization, DropDownItem, Collection, Features } from '../../types/types';
import DropDown from '../../widgets/DropDown/dropDown';
import Images from '../../shared/images';
import ModalStyles from '../../shared/styled/general-modal-style';
import Checkbox from '@material-ui/core/Checkbox';

const HeaderContainer = styled.div`
	width: 100%;
	height: 60px;
	line-height: 60px;
	background-color: #586876;
`;

const HeaderText = styled.span`
	color: #fff;
	margin-left: 30px;
	font-weight: 600;
`;

const Content = styled.div`
	padding: 20px 60px 20px 30px;
`;

const ClosePopUpBtn = styled.div`
	background-image: url("${Images.close}");
	position: absolute;
	right: -1px;
	top: 2px;
	width: 43px;
	height: 43px;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
  background-size: 13px;
`;

const InputTitle = styled.div`
	height: 25px;
	color: #4a4a4a;
	font-size: 13px;
	line-height: 25px;
	text-align: left;
	width: 100%;
`;

const CheckboxTitle = styled.div`
	height: 25px;
	width: 150px;
	color: #4a4a4a;
	font-size: 13px;
	line-height: 25px;
	text-align: left;
	float: left;
`;

const ErrorSpan = styled.div`
	color: red;
	font-size: 10px;
`;

const Textarea = styled.textarea`
	width: 100%;
	height: 120px;
	border: 1px solid #ccc;
	padding: 5px;
	resize: none;
	box-sizing: border-box;
`;

const DropDownWrap = styled.div`
	width: 156px;
	height: 24px;
`;

interface InputContainerProps {
	isTextArea?: boolean;
}
const InputContainer = styled.div<InputContainerProps>`
	width: 100%;
	height: ${props => (props.isTextArea ? '170px' : '60px')};
`;

const FeaturesTitle = styled.div`
	width: 100%;
	height: 25px;
	line-height: 25px;
	text-align: left;
	color: #4a4a4a;
	font-size: 13px;
`;

const BoxContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 160px;
	padding: 4px 10px;
	box-sizing: border-box;
	overflow-y: auto;
	border: 1px solid #ccc;
`;

const CheckboxContainer = styled.div`
	display: inline-block;
`;

const Input = styled.input`
	width: 100%;
	border: 1px solid #ccc;
	padding: 5px;
	box-sizing: border-box;
`;

const ButtonsContainer = styled.div`
	position: relative;
	width: 100%;
	height: 50px;
	font-size: 13px;
`;

const ButtonsWrap = styled.div`
	position: absolute;
	bottom: 10px;
	right: 0;
`;

const checkboxStyle: CSSProperties = {
	float: 'left',
	height: '25px',
	padding: 0,
	marginLeft: '10px'
};

interface ConfirmButtonProps {
	isConfirm: boolean;
}
const ConfirmButton = styled.button<ConfirmButtonProps>`
	width: 85px;
	height: 30px;
	float: right;
	margin-left: ${props => (props.isConfirm ? '10px' : '0')};
	font-size: 13px;
	color: ${props => (props.isConfirm ? '#fff' : '#696868')};
	background-color: ${props => (props.isConfirm ? '#00aeef' : '#ccc')};
	cursor: pointer;
`;

interface AddOrUpdateOrganizationPopUpProps {
	type: number; // 0 - add, 1 - update
	modalIsOpen: boolean;
	closePopup: Function;
	organization?: Organization;
	addOrEditFunc: Function;
	existingOrganizations: Organization[];
	collections: Collection[];
}

interface AddOrUpdateOrganizationPopUpState {
	organizationId: number;
	domain: string;
	clientId: number;
	token: string;
	isDomainErr: boolean;
	domainErr: string;
	isClientIdErr: boolean;
	isTokenErr: boolean;
	isCollectionNameErr: boolean;
	accountId: string;
	contactId: string;
	collectionName: string;
	features: Features;
}

export default class AddOrUpdateOrganizationPopUp extends React.Component<
	AddOrUpdateOrganizationPopUpProps,
	AddOrUpdateOrganizationPopUpState
> {
	constructor(props: AddOrUpdateOrganizationPopUpProps) {
		super(props);
		Modal.setAppElement('#root');
		const org = {
			isDomainErr: false,
			domainErr: '',
			isClientIdErr: false,
			isTokenErr: false,
			isCollectionNameErr: false,
			organizationId: undefined,
			domain: undefined,
			clientId: undefined,
			token: undefined,
			accountId: undefined,
			contactId: undefined,
			collectionName: undefined,
			features: undefined
		};
		if (this.props.type === 0) {
			org.organizationId = -1;
			org.domain = '';
			org.clientId = -1;
			org.token = '';
			org.accountId = '';
			org.contactId = '';
			org.collectionName = this.props.collections[0].name; // Default is first collection
			(org.features as Features) = {
				analytics_dashboard: false
			};
		} else if (this.props.type === 1) {
			org.organizationId = this.props.organization.id;
			org.domain = this.props.organization.domain;
			org.clientId = this.props.organization.client_id;
			org.token = this.props.organization.token ? this.props.organization.token : '';
			org.collectionName = this.props.organization.collection_name;
			org.features = this.props.organization.features;
		}

		this.state = org;
	}

	// TODO: Change to arrow functions
	updateDomainInput(evt) {
		this.setState({ domain: evt.target.value });
	}

	updateClientIdInput(evt) {
		this.setState({ clientId: evt.target.value });
	}

	updateTokenInput(evt) {
		this.setState({ token: evt.target.value });
	}

	updateAccountIdInput(evt) {
		this.setState({ accountId: evt.target.value });
	}

	updateContactIdInput(evt) {
		this.setState({ contactId: evt.target.value });
	}

	handleCollectionSelection = (selectedCollectionId: string) => {
		this.setState({ collectionName: selectedCollectionId });
	};

	handleFeatureCheck = (evt: any) => {
		this.setState({
			features: {
				...this.state.features,
				[evt.target.name]: evt.target.checked
			}
		});
	};

	getTitle = () => {
		if (this.props.type === 0) {
			return 'Add Client Domain';
		} else if (this.props.type === 1) {
			return 'Edit Client Domain';
		}
	};

	saveDomain = () => {
		if (this.props.type === 0) {
			this.addNewDomain();
		} else if (this.props.type === 1) {
			this.editDomain();
		}
	};

	// TODO: check if this two functions can be merged
	addNewDomain = () => {
		this.setState({
			isDomainErr: false,
			isClientIdErr: false,
			isTokenErr: false
		});
		if (!this.verifyInput()) {
			return;
		}
		const collection = _.find(this.props.collections, c => c.name === this.state.collectionName);
		const newOrganization = {
			domain: this.state.domain.toLowerCase().trim(),
			client_id: this.state.clientId,
			token: this.state.token,
			collection_id: collection ? collection.id : -1,
			collection_name: this.state.collectionName,
			features: this.state.features
		};
		this.props.addOrEditFunc(newOrganization);
		this.close();
	};

	editDomain = () => {
		this.setState({
			isDomainErr: false,
			isClientIdErr: false,
			isTokenErr: false
		});
		if (!this.verifyInput()) {
			return;
		}
		const collection = _.find(this.props.collections, c => c.name === this.state.collectionName);
		const editOrganization = {
			id: this.state.organizationId,
			domain: this.state.domain.toLowerCase().trim(),
			client_id: this.state.clientId,
			token: this.state.token,
			collection_id: collection ? collection.id : -1,
			collection_name: this.state.collectionName,
			features: this.state.features
		};
		this.props.addOrEditFunc(editOrganization);
		this.close();
	};

	close = () => {
		this.props.closePopup();
	};

	isNumeric = n => {
		return !isNaN(parseFloat(n)) && isFinite(n);
	};

	verifyInput = (): boolean => {
		// Check if domain exists
		let domains = _.map(this.props.existingOrganizations, o => o.domain);
		// If this is update process - don't check vs. original domain name
		if (this.props.type === 1) {
			domains = _.filter(domains, d => d !== this.props.organization.domain);
		}

		let isValid = true;
		if (_.find(domains, d => d === this.state.domain.toLowerCase().trim())) {
			this.setState({ isDomainErr: true, domainErr: 'Domain already exists' });
			isValid = false;
		}
		if (!this.isNumeric(this.state.clientId)) {
			this.setState({ isClientIdErr: true });
			isValid = false;
		}
		if (!this.state.domain) {
			if (!this.state.domain) {
				this.setState({ isDomainErr: true, domainErr: 'Domain is required' });
			}
			isValid = false;
		}
		if (!this.state.collectionName || this.state.collectionName === '') {
			this.setState({ isCollectionNameErr: true });
			isValid = false;
		}
		return isValid;
	};

	render() {
		const {
			domain,
			isDomainErr,
			isCollectionNameErr,
			domainErr,
			clientId,
			isClientIdErr,
			token,
			isTokenErr,
			accountId,
			contactId,
			collectionName
		} = this.state;
		const { collections } = this.props;
		const collectionsDropDownItems: DropDownItem[] = _.map(collections, (c: Collection) => {
			const res: DropDownItem = {
				id: c.name,
				title: c.name
			};
			return res;
		});
		return (
			<Modal
				isOpen={this.props.modalIsOpen}
				onRequestClose={() => this.close()}
				style={ModalStyles(430, 800)}
			>
				<ClosePopUpBtn
					onClick={() => this.close()}
					id='BACKOFFICE_CLIENT_DOMAINS_MODAL_CLOSE_BUTTON'
				/>
				<HeaderContainer>
					<HeaderText>{this.getTitle()}</HeaderText>
				</HeaderContainer>
				<Content>
					<InputContainer>
						<InputTitle>Domain:</InputTitle>
						<Input
							type='text'
							value={domain}
							onChange={this.updateDomainInput.bind(this)}
							id='BACKOFFICE_CLIENT_DOMAINS_ADDEDIT_CLIENT_DOMAIN_MODAL_DOMAIN_FIELD'
						/>
						{isDomainErr && <ErrorSpan id='ERROR_ADDING_DOMAIN'>{domainErr}</ErrorSpan>}
					</InputContainer>
					<InputContainer>
						<InputTitle>Client id:</InputTitle>
						<Input
							type='text'
							value={clientId !== -1 ? clientId : ''}
							onChange={this.updateClientIdInput.bind(this)}
							id='BACKOFFICE_CLIENT_DOMAINS_ADDEDIT_CLIENT_DOMAIN_MODAL_CLIENT_ID_FIELD'
						/>
						{isClientIdErr && (
							<ErrorSpan id='ERROR_ADDING_CLIENT_ID'>Client id must be a number</ErrorSpan>
						)}
					</InputContainer>
					<InputContainer isTextArea>
						<InputTitle>Token:</InputTitle>
						<Textarea
							value={token}
							onChange={this.updateTokenInput.bind(this)}
							id='BACKOFFICE_CLIENT_DOMAINS_ADDEDIT_CLIENT_DOMAIN_MODAL_TOKEN_FIELD'
						/>
						{isTokenErr && <ErrorSpan id='ERROR_ADDING_TOKEN'>Token is required</ErrorSpan>}
					</InputContainer>
					<InputContainer>
						<InputTitle>Account id:</InputTitle>
						<Input
							type='text'
							value={accountId}
							onChange={this.updateAccountIdInput.bind(this)}
							id='BACKOFFICE_CLIENT_DOMAINS_ADDEDIT_CLIENT_DOMAIN_MODAL_ACCOUNT_ID_FIELD'
						/>
					</InputContainer>
					<InputContainer>
						<InputTitle>Contact id:</InputTitle>
						<Input
							type='text'
							value={contactId}
							onChange={this.updateContactIdInput.bind(this)}
							id='BACKOFFICE_CLIENT_DOMAINS_ADDEDIT_CLIENT_DOMAIN_MODAL_CONTACT_ID_FIELD'
						/>
					</InputContainer>
					<InputContainer>
						<InputTitle>Collection:</InputTitle>
						<DropDownWrap>
							<DropDown
								type={2}
								maxHeightBody={160}
								height={25}
								width={340}
								arrowTop={-2}
								mainLabelPrefix={''}
								defaultSelection={collectionName}
								data={collectionsDropDownItems}
								handleDropDownChange={collectionName =>
									this.handleCollectionSelection(collectionName)
								}
								id='BACKOFFICE_CLIENT_DOMAINS_ADDEDIT_CLIENT_DOMAIN_MODAL_COLLECTION_DROPDOWN'
							/>
						</DropDownWrap>
						{isCollectionNameErr && (
							<ErrorSpan id='ERROR_ADDING_COLLECTION'>Collection is required</ErrorSpan>
						)}
					</InputContainer>
					<FeaturesTitle>Features:</FeaturesTitle>
					<BoxContainer>
						<CheckboxContainer>
							<CheckboxTitle>Analytics:</CheckboxTitle>
							<Checkbox
								color='primary'
								style={checkboxStyle}
								checked={this.state.features.analytics_dashboard}
								onClick={this.handleFeatureCheck}
								name='analytics_dashboard'
								id='BACKOFFICE_CLIENT_DOMAINS_ADDEDIT_CLIENT_DOMAIN_MODAL_ANALYTICS_CHECKBOX'
							/>
						</CheckboxContainer>
					</BoxContainer>
					<ButtonsContainer>
						<ButtonsWrap>
							<ConfirmButton
								isConfirm
								onClick={() => this.saveDomain()}
								id='BACKOFFICE_CLIENT_DOMAINS_ADDEDIT_CLIENT_DOMAIN_MODAL_SAVE_BUTTON'
							>
								Save
							</ConfirmButton>
							<ConfirmButton
								isConfirm={false}
								onClick={() => this.close()}
								id='BACKOFFICE_CLIENT_DOMAINS_ADDEDIT_CLIENT_DOMAIN_MODAL_CANCEL_BUTTON'
							>
								Cancel
							</ConfirmButton>
						</ButtonsWrap>
					</ButtonsContainer>
				</Content>
			</Modal>
		);
	}
}
