import React, { useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Button } from '@amenityllc/amenity-components';

import { Watchlist } from '../../types/types';
import Images from '../../shared/images';
import { ADD_COMPANY_MODAL } from '../../../id.constants.js';
import Modal from '../../../components/Modal/Modal';
import { Appearance, Sizes } from '../../../components/shared/styles';

const Row = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 17px 0;
	border-bottom: 1px solid #dfe6e8;
`;

const RowText = styled.div<{ isChosen: boolean }>`
	font-family: ${props => (props.isChosen ? 'Assistant SemiBold' : 'Assistant')};
	color: ${props => (props.isChosen ? '#2A4D7E' : '#4A4A4A')};
	font-size: 16px;
`;

const Icon = styled.div<{ isChosen: boolean }>`
	height: 21px;
	width: 21px;
	background-color: ${props => (props.isChosen ? '#2a4d7e' : '#4A4A4A')};
	-webkit-mask-image: url(${props =>
		props.isChosen ? Images.circleCheckIcon : Images.circledPlusIcon});
	-webkit-mask-size: ${props => (props.isChosen ? 'auto' : 'contain')};
	-webkit-mask-repeat: no-repeat;
	-webkit-mask-position: center;
	cursor: pointer;
	:hover {
		background-color: ${props => !props.isChosen && '#2A4D7E'};
	}
`;

interface AddCompanyModalProps {
	isModalOpen: boolean;
	close: () => void;
	save: (watchlistIds: string[]) => void;
	watchlists: Watchlist[];
}

const AddCompanyModal: React.FunctionComponent<AddCompanyModalProps> = ({
	isModalOpen,
	close,
	save,
	watchlists
}) => {
	const [selectedWatchlists, setSelectedWatchlists] = useState([]);
	const toggleWatchlist = (id: string) => {
		const index = _.indexOf(selectedWatchlists, id);
		const newSelectedWatchlists = [...selectedWatchlists];
		if (index === -1) {
			newSelectedWatchlists.push(id);
		} else {
			newSelectedWatchlists.splice(index, 1);
		}
		setSelectedWatchlists(newSelectedWatchlists);
	};

	const isWatchlistChosen = (id: string) => _.indexOf(selectedWatchlists, id) !== -1;

	const renderWatchlistsList = () =>
		_.map(watchlists, p => renderRow(p.id, p.name, isWatchlistChosen(p.id)));

	const renderRow = (id: string, name: string, isChosen: boolean) => (
		<Row id={`${ADD_COMPANY_MODAL.ROW_PREFIX}${id}`} key={id}>
			<RowText isChosen={isChosen}>{name}</RowText>
			<Icon
				id={`${ADD_COMPANY_MODAL.ICON_PREFIX}${id}`}
				isChosen={isChosen}
				onClick={() => toggleWatchlist(id)}
			/>
		</Row>
	);

	const renderButtons = () => (
		<Button
			key='COMPANY_MODAL_CONFIRM'
			id={ADD_COMPANY_MODAL.CONFIRM_BTN}
			size={Sizes.medium}
			appearance={Appearance.primary}
			text={'Add'}
			disabled={selectedWatchlists.length === 0}
			onClick={() => save(selectedWatchlists)}
		/>
	);

	return (
		<Modal
			icon={Images.addCompanyIcon}
			title={'Add to Watchlist'}
			text={'Choose one or more watchlists to add to:'}
			isModalOpen={isModalOpen}
			modalContent={renderWatchlistsList()}
			fixedContentHeight={true}
			modalButtons={renderButtons()}
			close={close}
		/>
	);
};

export default AddCompanyModal;
