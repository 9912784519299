import * as React from 'react';
import ContentLoader from 'react-content-loader';

const SecFilingSkeleton = () => {
	return (
		<ContentLoader
			speed={2}
			width='100%'
			height='100%'
			viewBox='0 0 732 437'
			backgroundColor='#f3f3f3'
			foregroundColor='#ecebeb'
		>
			<rect x='310' y='7' rx='6' ry='6' width='112' height='16' />
			<rect x='194' y='29' rx='6' ry='6' width='344' height='16' />
			<rect x='324' y='50' rx='2' ry='2' width='85' height='8' />
			<rect x='326' y='72' rx='6' ry='6' width='80' height='16' />
			<path d='M 297.564 95.734 h 137.382' />
			<rect x='29' y='105' rx='2' ry='2' width='538' height='7' />
			<rect x='236' y='117' rx='2' ry='2' width='361' height='7' />
			<rect x='166' y='129' rx='2' ry='2' width='296' height='7' />
			<rect x='360' y='141' rx='2' ry='2' width='12' height='7' />
			<rect x='29' y='154' rx='2' ry='2' width='661' height='7' />
			<rect x='351' y='166' rx='2' ry='2' width='180' height='7' />
			<path d='M 297.564 179.224 h 137.382' />
			<rect x='244' y='186' rx='6' ry='6' width='244' height='16' />
			<path d='M 297.564 220.721 h 137.382' />
			<rect x='317' y='206' rx='2' ry='2' width='98' height='8' />
			<rect x='155' y='226' rx='1' ry='1' width='29' height='4' />
			<rect x='146' y='233' rx='1' ry='1' width='48' height='7' />
			<rect x='133' y='242' rx='1' ry='1' width='74' height='4' />
			<rect x='145' y='249' rx='1' ry='1' width='48' height='4' />
			<rect x='138' y='256' rx='1' ry='1' width='63' height='2' />
			<rect x='28' y='314' rx='1' ry='1' width='171' height='4' />
			<rect x='28' y='319' rx='1' ry='1' width='243' height='4' />
			<rect x='28' y='324' rx='1' ry='1' width='191' height='4' />
			<rect x='28' y='329' rx='1' ry='1' width='91' height='4' />
			<rect x='28' y='334' rx='1' ry='1' width='663' height='4' />
			<rect x='28' y='340' rx='1' ry='1' width='61' height='4' />
			<rect x='28' y='345' rx='1' ry='1' width='688' height='4' />
			<rect x='28' y='350' rx='1' ry='1' width='122' height='4' />
			<rect x='28' y='355' rx='1' ry='1' width='681' height='4' />
			<rect x='28' y='363' rx='1' ry='1' width='62' height='4' />
			<rect x='28' y='371' rx='1' ry='1' width='54' height='4' />
			<rect x='28' y='379' rx='1' ry='1' width='73' height='4' />
			<rect x='28' y='387' rx='1' ry='1' width='438' height='4' />
			<rect x='28' y='392' rx='1' ry='1' width='74' height='4' />
			<rect x='28' y='397' rx='1' ry='1' width='183' height='4' />
			<rect x='28' y='402' rx='1' ry='1' width='442' height='4' />
			<rect x='28' y='408' rx='1' ry='1' width='525' height='4' />
			<rect x='28' y='413' rx='1' ry='1' width='325' height='4' />
			<rect x='28' y='418' rx='1' ry='1' width='689' height='4' />
			<rect x='28' y='423' rx='1' ry='1' width='638' height='4' />
			<rect x='525' y='363' rx='1' ry='1' width='62' height='4' />
			<rect x='525' y='371' rx='1' ry='1' width='107' height='4' />
			<rect x='532' y='241' rx='1' ry='1' width='35' height='4' />
			<rect x='529' y='248' rx='1' ry='1' width='41' height='7' />
			<rect x='540' y='264' rx='1' ry='1' width='19' height='4' />
			<rect x='536' y='271' rx='1' ry='1' width='26' height='2' />
			<rect x='276' y='262' rx='2' ry='2' width='180' height='6' />
			<path d='M 295.427 65.115 h 141.656 v 1.755 H 295.427 z M 295.427 94.324 h 141.656 v 1.755 H 295.427 z M 295.427 177.996 h 141.656 v 1.755 H 295.427 z M 295.427 219.642 h 141.656 v 1.755 H 295.427 z M 28.389 281.252 h 681.438 v 1.755 H 28.389 z M 28.388 299 H 709.8 v 1.755 H 28.388 z M 28.491 290 H 709.8 v 1.755 H 28.491 z' />
			<path d='M 28.182 281.519 h 1.642 v 19.084 h -1.642 z M 328 281.832 h 1.642 v 18.844 H 328 z M 483 282.078 h 1.67 v 18.3 H 483 z M 708.783 281.251 h 1.642 v 19.084 h -1.642 z' />
		</ContentLoader>
	);
};

export default SecFilingSkeleton;
