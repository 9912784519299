import * as React from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';
import { Speaker } from '../../../types/types';
import Images from '../../../shared/images';
import { IS_ANSWER, IS_QUESTION, SCROLL_IDS } from '../../../constants';

const ParagraphHeader = styled.div`
	width: 100%;
	height: 35px;
	line-height: 35px;
`;

const AvatarContainer = styled.div`
	position: relative;
	width: 32px;
	height: 100%;
	float: left;
`;

const Avatar = styled.img`
	position: absolute;
	top: 9px;
	left: 7px;
`;

const SpeakerName = styled.div`
	height: 100%;
	float: left;
	font-size: 16px;
	color: #586876;
`;

const QNASeparator = styled.div`
	width: 1.5px;
	height: 20px;
	float: left;
	margin-right: 5px;
	margin-top: 7px;
	background-color: #586876;
`;

const Separator = styled.div`
	width: 1px;
	height: 13px;
	float: left;
	margin: 12px 0 0 7px;
	background-color: #9b9b9b;
`;

const SpeakerDetails = styled.div`
	height: 100%;
	float: left;
	padding-top: 1px;
	margin-left: 7px;
	font-size: 13px;
	color: #9b9b9b;
`;

const SpeakerHeaderUnderline = styled.div`
	width: calc(100% - 10px);
	height: 2px;
	background-color: #eeeeee;
`;

const QNATypeContainer = styled.div`
	height: 100%;
	margin-right: 9px;
	padding-left: 5px;
	float: left;
	position: relative;
	top: -1px;
	font-size: 14px;
	letter-spacing: 0.1px;
	box-sizing: border-box;
`;

const QuestionContainer = styled(QNATypeContainer)`
	color: #f44336;
`;

const AnswerContainer = styled(QNATypeContainer)`
	color: #4a4a4a;
`;

const FirstLetter = styled.span`
	font-size: 20px;
`;

const RestOfLetters = styled.span``;

interface SpeakerProps {
	speaker: Speaker;
	type?: string;
}

const SpeakerLine: React.FunctionComponent<SpeakerProps> = ({ speaker, type }) => {
	const isQuestion = IS_QUESTION(type);
	const isAnswer = IS_ANSWER(type);
	const speakerDetails =
		speaker?.company && speaker?.title
			? `${speaker.title}, ${speaker.company}`
			: speaker?.title || speaker?.company;

	const speakerElementId =
		isQuestion || isAnswer
			? SCROLL_IDS.QNA.NAME + String(speaker?.speakingIndex)
			: SCROLL_IDS.SPEAKER.NAME + String(speaker?.speakingIndex);

	return (
		<>
			<ParagraphHeader key='ParagraphHeader'>
				{!(isAnswer || isQuestion) && (
					<AvatarContainer>
						<Avatar src={Images.speakerAvatarText} />
					</AvatarContainer>
				)}
				{isQuestion && (
					<QuestionContainer>
						<FirstLetter>Q</FirstLetter>
						<RestOfLetters>uestion</RestOfLetters>
					</QuestionContainer>
				)}
				{isAnswer && (
					<AnswerContainer>
						<FirstLetter>A</FirstLetter>
						<RestOfLetters>nswer</RestOfLetters>
					</AnswerContainer>
				)}
				{(isAnswer || isQuestion) && <QNASeparator color={'#014A66'} />}
				<SpeakerName>
					<Element id={speakerElementId} name={speakerElementId} />
					{speaker?.name}
				</SpeakerName>
				{speakerDetails && (
					<>
						<Separator />
						<SpeakerDetails>{speakerDetails}</SpeakerDetails>
					</>
				)}
			</ParagraphHeader>
			<SpeakerHeaderUnderline key='SpeakerHeaderUnderline' />
		</>
	);
};

export default SpeakerLine;
