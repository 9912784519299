import * as _ from 'lodash';
import { QNA } from '../../types/types';

/**
 * question count + answers count (there can be more than 1 answer to a question) times item height +
 * qna count times (margin between qna sections + separator height)
 * @param qnas - qna array
 * @param qnaItemHeight - main item height
 * @param qnaItemSeparation - separation between each qna group. Note that in group there is a question and
 * can be more than 1 answer
 * @param qnaItemSeparatorHeight - separator line height. Exists between qna groups
 */

export const getQNAListHeight = (
	qnas: QNA[],
	qnaItemHeight: number,
	qnaItemSeparation: number,
	qnaItemSeparatorHeight: number
): number => {
	const itemsLength = qnas.length;
	const flaMapAnswers = _.flatMap(qnas, (item: QNA) => item.answerElements);
	return (
		(flaMapAnswers.length + itemsLength) * qnaItemHeight +
		itemsLength * (qnaItemSeparation + qnaItemSeparatorHeight)
	);
};
