import { action, observable } from 'mobx';
import * as _ from 'lodash';
import { Event } from '../types/types';

export default class TranscriptLogStore {
	@observable logErrors = true;
	@observable eventsFound: Event[] = [];

	private static instance: TranscriptLogStore;

	constructor() {
		if (TranscriptLogStore.instance) {
			return TranscriptLogStore.instance;
		}
		TranscriptLogStore.instance = this;
	}

	@action
	initializeGlobalCounter = () => {
		this.eventsFound = [];
	};

	@action
	addEventsToGlobalCounter = (events: Event[]) => {
		this.eventsFound = _.union(this.eventsFound, events);
	};

	@action
	stopLoggingErrors = () => {
		this.logErrors = false;
	};

	@action
	startLoggingErrors = () => {
		this.logErrors = true;
	};
}
