import * as React from 'react';
import styled from 'styled-components';
import Discussion, { Section } from './discussion';
import QNA from './qna';
import { ATTRIBUTIONS } from '../../../constants';

const HighlighterContainer = styled.div<{ isPrint: boolean }>`
	position: relative;
	color: #4a4a4a;
	font-size: 14px;
	line-height: 19px;
	white-space: pre-wrap;
	padding: ${props => (props.isPrint ? '15px 0px 15px 0px' : '15px 40px 15px 40px')};
	overflow-y: auto;
	height: calc(100% - 50px);
	box-sizing: border-box;
`;

const Footnote = styled.div`
	margin-top: 10px;
	font-size: 9px;
`;

interface TranscriptProps {
	vendor: string;
	discussionSection: Section[];
	qnaSection: Section[];
	printMode?: boolean;
	selectedEventId: string;
}

export default class Transcript extends React.Component<TranscriptProps> {
	render() {
		const { printMode, selectedEventId, vendor } = this.props;

		return (
			<HighlighterContainer isPrint={printMode}>
				{this.props.discussionSection && (
					<>
						<Discussion
							sections={this.props.discussionSection}
							selectedEventId={selectedEventId}
							printMode={printMode}
						/>
						<QNA
							sections={this.props.qnaSection}
							selectedEventId={selectedEventId}
							printMode={printMode}
						/>
					</>
				)}
				<Footnote
					style={{
						display:
							this.props.qnaSection && Object.keys(ATTRIBUTIONS).includes(vendor) ? 'block' : 'none'
					}}
				>
					{ATTRIBUTIONS[vendor]}
				</Footnote>
			</HighlighterContainer>
		);
	}
}
