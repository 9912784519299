import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { configure } from 'mobx';
import './index.css';

import { App } from './app/app';

// enable MobX strict mode
configure({ enforceActions: 'always' });

// render react DOM
ReactDOM.render(<App />, document.getElementById('root'));
