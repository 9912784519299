import * as React from 'react';
import * as ReactMenuAim from 'react-menu-aim';
import createClass from 'create-react-class';
import * as _ from 'lodash';
import styled from 'styled-components';
import Images from '../../shared/images';
import { DropDownItem } from '../../types/types';

interface DropDownRelativeContainerProps {
	width: number;
	height: number;
}
const DropDownRelativeContainer = styled.div<DropDownRelativeContainerProps>`
	position: relative;
	height: ${props => props.height}px;
	width: ${props => props.width}px;
`;

const DropDownHeaderContainer = styled.div<{
	disabled: boolean;
	borderRadius?: number;
}>`
	width: 100%;
	height: 100%;
	cursor: ${props => (props.disabled ? 'none' : 'pointer')};
	opacity: ${props => (props.disabled ? '0.8' : '1')};
	pointer-events: ${props => (props.disabled ? 'none' : 'initial')};
	box-sizing: border-box;
`;

const DropDownHeaderContainerType1 = styled(DropDownHeaderContainer)`
	background-color: ${props => (props.disabled ? 'inherit' : '#FFF')};
	border-radius: 2px;
	border: 1px solid #e1e8ea;
`;

const DropDownHeaderContainerType2 = styled(DropDownHeaderContainer)`
	border-radius: ${props => props.borderRadius || 1}px;
	border: 1px solid #e1e8ea;
	background-color: #fff;
`;

interface HeaderTextProps {
	lineHeight: number;
	disabled?: boolean;
}
const HeaderText = styled.div<HeaderTextProps>`
	height: 100%;
	width: calc(100% - 35px);
	box-sizing: border-box;
	margin-right: 15px;
	display: inline-block;
	vertical-align: top;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: ${props => props.lineHeight}px;
`;

const HeaderTextType1 = styled(HeaderText)`
	opacity: ${props => (props.disabled ? '0.5' : '1')};
	color: #4a4a4a;
	font-size: 12px;
	padding-left: 16px;
`;

const HeaderTextType2 = styled(HeaderText)`
	opacity: ${props => (props.disabled ? '0.5' : '1')};
	color: #32313b;
	font-family: Assistant;
	font-size: 15px;
	padding-left: 8px;
`;

interface ArrowImageProps {
	disabled: boolean;
	top: number;
}
const ArrowImage = styled.img<ArrowImageProps>`
	position: relative;
	opacity: ${props => (props.disabled ? '0.5' : '1')};
	pointer-events: ${props => (props.disabled ? 'none' : 'initial')};
	top: ${props => props.top}px;
	height: 5px;
	width: 10px;
`;

interface DropDownBodyContainerProps {
	maxHeightBody: number;
}
const DropDownBodyContainer = styled.div<DropDownBodyContainerProps>`
	max-height: ${props => props.maxHeightBody}px;
	width: 100%;
	position: absolute;
	left: -1px;
	z-index: 1;
	border: 1px solid #eee;
	border-radius: 3px;
	background-color: #fff;
	overflow-y: auto;
	box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
`;

const DropDownMainMenu = styled.div`
	border-right: 1px solid #eee;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
`;

interface MenuItemProps {
	active: boolean;
	fontWeight?: number;
}
const MenuItem = styled.div<MenuItemProps>`
	cursor: pointer;
	white-space: nowrap;
	font-size: 18px;
`;

const MenuItemType1 = styled(MenuItem)`
	height: 57px;
	line-height: 57px;
	padding: 0 15px;
	background-color: ${props => (props.active ? '#FAFAFA' : '#FFF')};

	:hover {
		background-color: #fafafa;
	}
`;

const MenuItemType2 = styled(MenuItem)`
	height: 26px;
	padding: 0 4px;
	background-color: ${props => (props.active ? '#00AEEF' : '#FFF')};

	:hover {
		background-color: #00aeef;
	}
`;

const MainMenuItemContainer = styled.div`
	height: 100%;
`;

interface MainMenuItemDivProps {
	active: boolean;
	fontWeight?: number;
}
const MainMenuItemTitle = styled.div<MainMenuItemDivProps>`
	height: 19px;
	font-weight: ${props => props.fontWeight || 600};
	line-height: 19px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const MainMenuItemTitle1 = styled(MainMenuItemTitle)`
	padding: 8px 0 0;
	font-size: 13px;
	color: #4a4a4a;
`;

const MainMenuItemTitle2 = styled(MainMenuItemTitle)`
	padding: 3px 0 3px 4px;
	font-size: 14px;
	color: ${props => (props.active ? '#FFF' : '#014A66')};
	:hover {
		color: ${props => (props.active ? '#FFF' : '#014A66')};
	}
`;

const MainMenuItemSubtitle = styled.div`
	height: 17px;
	color: #696868;
	font-size: 11px;
	line-height: 17px;
	padding-top: 4px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const DropDown = createClass({
	mixins: [ReactMenuAim],
	dropDownButton: undefined,
	getInitialState: function(): any {
		// First item is default when there is no default selection or only 1 item
		let selectedItemIndex = 0;

		if (
			this.props.defaultSelection &&
			this.props.data.length > 1 &&
			this.props.defaultSelection !== -1
		) {
			const indexOfItem = _.findIndex(
				this.props.data,
				(item: DropDownItem) => item.id === this.props.defaultSelection
			);
			if (indexOfItem !== -1) {
				selectedItemIndex = indexOfItem;
			}
		}
		const state = {
			type: this.props.type,
			isEnabled: this.props.data.length > 1,
			isToShowDropDown: false,
			selectedItemIndex
		};
		return state;
	},

	componentWillMount: function() {
		this.initMenuAim({
			delay: 300,
			tolerance: 75
		});
	},

	componentWillReceiveProps: function(nextProps: any) {
		if (nextProps.defaultSelection) {
			const selectedItemIndex = _.findIndex(
				nextProps.data,
				(item: DropDownItem) => item.id === nextProps.defaultSelection
			);
			if (selectedItemIndex !== -1) {
				this.setState({
					selectedItemIndex: selectedItemIndex
				});
			}
		}

		if (nextProps.reset && !this.props.reset) {
			this.setState({
				selectedItemIndex: nextProps.defaultSelection
			});
		}

		if (nextProps.data) {
			this.checkIfDropDownHasData(nextProps.data);
		}
	},

	toggleDropDown: function() {
		if (this.state.isToShowDropDown) {
			this.closeDropDown();
			return;
		}
		this.openDropDown();
	},

	openDropDown: function() {
		this.setState({ isToShowDropDown: true });
		document.addEventListener('mousedown', this.handleDropDownClick, true);
	},

	closeDropDown: function() {
		document.removeEventListener('mousedown', this.handleDropDownClick, true);
		this.setState({ isToShowDropDown: false });
	},

	onClickDropDown: function() {
		this.toggleDropDown();
	},

	handleDropDownClick: function(e) {
		if (this.dropDownButton.contains(e.target)) {
			return;
		}
		this.closeDropDown();
	},

	mapMenuItems: function(
		data: DropDownItem[],
		selectedItemIndex: number,
		fontWeight: number
	): Array<any> {
		const items = _.map(data, (menuItem, index) => {
			const active: boolean = index === selectedItemIndex ? true : false;

			if (this.props.type === 1) {
				return (
					<MenuItemType1
						active={active}
						key={index}
						onClick={() => this.handleDropDownChange(index)}
					>
						<MainMenuItemContainer>
							<MainMenuItemTitle1 title={menuItem.title} active={active} fontWeight={fontWeight}>
								{menuItem.title}
							</MainMenuItemTitle1>
							{menuItem.subtitle && (
								<MainMenuItemSubtitle title={menuItem.subtitle}>
									{menuItem.subtitle}
								</MainMenuItemSubtitle>
							)}
						</MainMenuItemContainer>
					</MenuItemType1>
				);
			} else if (this.props.type === 2) {
				return (
					<MenuItemType2
						active={active}
						key={index}
						onClick={() => this.handleDropDownChange(index)}
						fontWeight={fontWeight}
					>
						<MainMenuItemContainer>
							<MainMenuItemTitle2 title={menuItem.title} active={active} fontWeight={fontWeight}>
								{menuItem.title}
							</MainMenuItemTitle2>
							{menuItem.subtitle && (
								<MainMenuItemSubtitle title={menuItem.subtitle}>
									{menuItem.subtitle}
								</MainMenuItemSubtitle>
							)}
						</MainMenuItemContainer>
					</MenuItemType2>
				);
			}
		});
		return items;
	},

	checkIfDropDownHasData: function(data: DropDownItem[]) {
		const isEnabled = data.length > 1;
		this.setState({ isEnabled: isEnabled });
		if (!isEnabled) {
			this.setState({ isToShowDropDown: false });
		}
	},

	handleDropDownChange: function(index: number) {
		// if flow not changed
		if (index !== this.state.selectedItemIndex) {
			this.props.handleDropDownChange(this.props.data[index].id);
		}
		this.setState({ isToShowDropDown: false, selectedItemIndex: index });
	},

	render: function(): any {
		let displayTitle = '';

		const { isEnabled, isToShowDropDown, selectedItemIndex } = this.state;
		if (this.props.data) {
			if (this.props.data[selectedItemIndex]) {
				displayTitle = `${this.props.mainLabelPrefix} ${this.props.data[selectedItemIndex].title}`;
			} else if (this.props.data.length === 1) {
				displayTitle = `${this.props.mainLabelPrefix} ${this.props.data[0].title}`;
			}
		}

		if (this.props.type === 1) {
			return (
				<DropDownRelativeContainer
					height={this.props.height}
					width={this.props.width}
					ref={node => (this.dropDownButton = node)}
				>
					<DropDownHeaderContainerType1 disabled={!isEnabled} onClick={this.onClickDropDown}>
						<HeaderTextType1 disabled={!isEnabled} lineHeight={this.props.height - 2}>
							{displayTitle}
						</HeaderTextType1>
						<ArrowImage
							src={Images.arrowDownDropdown}
							disabled={!isEnabled}
							alt='arrow down'
							top={this.props.arrowTop}
						/>
					</DropDownHeaderContainerType1>
					{isToShowDropDown && (
						<DropDownBodyContainer maxHeightBody={this.props.maxHeightBody}>
							<DropDownMainMenu>
								{this.mapMenuItems(this.props.data, selectedItemIndex, this.props.fontWeight)}
							</DropDownMainMenu>
						</DropDownBodyContainer>
					)}
				</DropDownRelativeContainer>
			);
		} else if (this.props.type === 2) {
			return (
				<DropDownRelativeContainer
					height={this.props.height}
					width={this.props.width}
					ref={node => (this.dropDownButton = node)}
				>
					<DropDownHeaderContainerType2
						disabled={!isEnabled}
						borderRadius={this.props.borderRadius}
						onClick={this.onClickDropDown}
					>
						<HeaderTextType2 disabled={!isEnabled} lineHeight={this.props.height - 2}>
							{displayTitle}
						</HeaderTextType2>
						<ArrowImage
							src={Images.arrowDownDropdown}
							disabled={!isEnabled}
							alt='arrow down'
							top={this.props.arrowTop}
						/>
					</DropDownHeaderContainerType2>
					{isToShowDropDown && (
						<DropDownBodyContainer maxHeightBody={this.props.maxHeightBody}>
							<DropDownMainMenu>
								{this.mapMenuItems(this.props.data, selectedItemIndex, this.props.fontWeight)}
							</DropDownMainMenu>
						</DropDownBodyContainer>
					)}
				</DropDownRelativeContainer>
			);
		}
	}
});

export default DropDown;
