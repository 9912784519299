import * as _ from 'lodash';

import {
	AnalyticsDirectLinkParams,
	AnalyticsErrorTypes,
	App,
	AppSheet,
	Bookmark,
	SheetCell
} from '../types/types';
import { error } from '../services/logger.service';
interface TabbedCell {
	sheetIndex: number;
	cellId: string;
}

export const isValidParams = (params: AnalyticsDirectLinkParams) =>
	params !== undefined && !_.isEmpty(params.application);

export const formatApp = (app: any): App => ({
	id: app ? app.qDocId : '',
	name: app ? app.qDocName : '',
	sheets: [],
	isOpen: false,
	isLoaded: false,
	loadingSpinner: false,
	bookmarks: [],
	appObj: undefined,
	watchlistFilterEnabled: false
});

export const formatSheet = (sheet: any): AppSheet => ({
	id: sheet?.qInfo?.qId || '',
	title: sheet.qMeta?.title || '',
	description: sheet.qMeta?.description || '',
	rank: sheet?.qData?.rank || '',
	cells: formatCells(sheet?.qData?.cells)
});

export const formatCells = (cells: any[]): SheetCell[] =>
	_.map(cells, cell => ({ id: cell.name, type: cell.type }));

export const formatBookmark = (bookmark: any): Bookmark => ({
	id: (bookmark && bookmark.qInfo && bookmark.qInfo['qId']) || '',
	title: (bookmark && bookmark.qMeta && bookmark.qMeta['title']) || '',
	description: (bookmark && bookmark.qMeta && bookmark.qMeta['description']) || '',
	isPublic: (bookmark && bookmark.qMeta && bookmark.qMeta['published']) || false
});

export const extractTablesFromTabbedCell = async (
	appObj: any,
	sheetIndex: number,
	tabbedCellId: string,
	logError: boolean = true
): Promise<TabbedCell[]> => {
	try {
		const model = await appObj.getObjectProperties(tabbedCellId);
		return Promise.resolve(formatTabbedCells(sheetIndex, model?.properties?.props));
	} catch (err) {
		logError &&
			error({
				message: `Error in extractTablesFromTabbedCell, ${JSON.stringify(err)}`,
				file: 'analytics.store.util',
				functionName: 'extractTablesFromTabbedCell'
			});
		return Promise.resolve([]);
	}
};

export const formatTabbedCells = (sheetIndex: number, modelProps: any): TabbedCell[] =>
	_.reduce(
		modelProps,
		(res: any, value: any, key: string) => {
			return isTab(key) && value?.export ? [...res, { sheetIndex, cellId: value.chart }] : res;
		},
		[]
	);

export const isTab = (propName: string): boolean => _.includes(propName, 'tab');

export const isFactsetFieldExitOnApp = (appObj: any, logError: boolean = true): Promise<boolean> =>
	new Promise(resolve => {
		try {
			appObj.getList('FieldList', res => {
				resolve(_.some(res?.qFieldList?.qItems, { qName: 'ticker' }));
			});
		} catch (err) {
			logError &&
				error({
					message: `Error in getAppSheets, ${JSON.stringify(err)}`,
					file: 'analytics.store.util',
					functionName: 'getAppSheets'
				});
			resolve(false);
		}
	});

export const getAppBookmarks = (appObj: any, logError: boolean = true): Promise<Bookmark[]> =>
	new Promise((resolve, reject) => {
		try {
			appObj.getList('BookmarkList', (res: any) => {
				const bookmarks = res && res.qBookmarkList && res.qBookmarkList.qItems;
				const parsedBookmarks: Bookmark[] = _.map(bookmarks, b => formatBookmark(b));
				resolve(parsedBookmarks);
			});
		} catch (err) {
			logError &&
				error({
					message: `Error in getAppBookmarks, ${JSON.stringify(err)}`,
					file: 'analytics.store.util',
					functionName: 'getAppBookmarks'
				});
			resolve([]);
		}
	});

export const setFiltersForApp = (
	appObj: any,
	field: string,
	values: any[],
	logError: boolean = true
) => {
	try {
		appObj.clearAll(true).then(() => appObj.field(field).selectValues(values, true, true));
	} catch (err) {
		logError &&
			error({
				message: `Error in setPortfolioFilter, ${JSON.stringify(err)}`,
				file: 'analytics.store.util',
				functionName: 'setFiltersForApp'
			});
	}
};

export const setBookmarkForApp = async (
	appObj: any,
	bookmarkId: string,
	logError: boolean = true
): Promise<any> => {
	try {
		return await appObj.bookmark.apply(bookmarkId);
	} catch (err) {
		logError &&
			error({
				message: `Error in setBookmarkForApp, ${JSON.stringify(err)}`,
				file: 'analytics.store.util',
				functionName: 'setBookmarkForApp'
			});
	}
};

export const saveBookmark = async (
	appObj: any,
	sheetId: string,
	bookmarkName: string,
	description: string = undefined,
	logError: boolean = true
): Promise<any> => {
	try {
		return await appObj.bookmark.create(bookmarkName, description || bookmarkName, sheetId);
	} catch (err) {
		logError &&
			error({
				message: `Error in saveBookmark, ${JSON.stringify(err)}`,
				file: 'analytics.store.util',
				functionName: 'saveBookmark'
			});
	}
};

export const deleteBookmark = async (
	appObj: any,
	sheetId: string,
	bookmarkId: string,
	logError: boolean = true
): Promise<any> => {
	try {
		await appObj.bookmark.remove(bookmarkId);
	} catch (err) {
		logError &&
			error({
				message: `Error in deleteBookmark, ${JSON.stringify(err)}`,
				file: 'analytics.store.util',
				functionName: 'deleteBookmark'
			});
	}
};

export const calcErrorType = (errorMessage: string, logError: boolean = true) => {
	try {
		switch (true) {
			case errorMessage.includes('Network Error'):
				return AnalyticsErrorTypes.NETWORK;
			case errorMessage.includes('OnLicenseAccessDenied'):
				return AnalyticsErrorTypes.ON_LICENSE_ACCESS_DENIED;
			case errorMessage.includes('no data to export'):
				return AnalyticsErrorTypes.NO_DATA_TO_EXPORT;
			default:
				return AnalyticsErrorTypes.UNKNOWN;
		}
	} catch (err) {
		logError &&
			error({
				message: `Error in calcErrorType, ${JSON.stringify(err)}`,
				file: 'analytics.store.util',
				functionName: 'calcErrorType'
			});
		return AnalyticsErrorTypes.UNKNOWN;
	}
};

export const isAnalyticsTab = (pathname: string): boolean =>
	pathname && pathname.indexOf('analytics') !== -1;

export const checkIfSameSheet = (sheetA: AppSheet, sheetB: AppSheet) => {
	return sheetA && sheetB && sheetA.id === sheetB.id && sheetA.title === sheetB.title;
};

export const checkIfSameApp = (selectedApp: App, currentApp: App) => {
	return currentApp?.id === selectedApp.id;
};
