import * as React from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';

import { Category, PortfolioCompany } from '../../types/types';
import { KeyDriverChartNewArk } from '../../types/types_keydrivers';
import { displayCategoryInfo } from '../../services/util.service';
import Images from '../../shared/images';
import ColumnChartNewark from '../ColumnChart/columnChartNewark';
import { TOOL_TIPS } from '../../constants';
import * as ids from '../../../id.constants.js';
import { Colors, FontSizes, FontFamilies } from '../../../components/shared/styles';
import { Text } from '../../../components/Text/Text';

const SectionTitle = styled.div`
	display: flex;
	align-items: center;
	height: 20px;
	letter-spacing: -0.12px;
	margin-left: 25px;
	margin-bottom: 10px;
`;

const KeyDrivers = styled.div`
	width: 100%;
	height: 175px;
`;

const KeyDriversChartNewArk = styled.div`
	display: flex;
	height: 141px;
	border-width: 0 0 2px 0;
	border-color: #eeeeee;
	border-style: solid;
	padding-left: 15px;
`;

const KeyDriversEmpty = styled.div`
	width: 100%;
	text-align: center;
	line-height: 90px;
`;

const ChartWrapper = styled.div`
	border-right: 1px solid #eeeeee;
	width: calc((100% - 25px) / 5);

	&:last-child {
		border-right: none;
	}
`;

const InfoIcon = styled.img.attrs({
	src: Images.infoIcon
})`
	width: 17px;
	margin-left: 5px;
`;

const ChartInfoIcon = styled.img.attrs({
	src: Images.infoIcon
})`
	position: absolute;
	top: 8px;
	left: 88px;
	height: 17px;
	width: 17px;
`;

interface KeyDriversChartsProps {
	company: PortfolioCompany;
	keyDriversData: KeyDriverChartNewArk[];
	onClickTranscript?: any;
	categories: Category[];
}

const KeyDriversChartsNewArk: React.FunctionComponent<KeyDriversChartsProps> = (
	props: KeyDriversChartsProps
) => {
	const drawKeyDriverCharts = (
		charts: KeyDriverChartNewArk[],
		categories: Category[],
		isFiscalYear: boolean
	): any[] =>
		_.map(charts, (ch: KeyDriverChartNewArk, index: number) => {
			const category = _.find(categories, c => c.name === ch.title.text);
			return (
				<ChartWrapper
					key={ch.title.text}
					id={`${ids.WACTHLIST_PAGE.KEY_DRIVER_CHART_PREFIX}${index}`}
				>
					{displayGraph(ch, onClickTranscript, isFiscalYear)}
					{displayCategoryInfo(category) && (
						<ChartInfoIcon data-tip={category.chart_description} data-for='EXPAND_ROW_TOOL_TIP' />
					)}
				</ChartWrapper>
			);
		});

	const displayGraph = (ch: any, onClickTranscript: Function, isFiscalYear: boolean) => (
		<ColumnChartNewark data={ch} isFiscalYear={isFiscalYear} />
	);

	const { keyDriversData, company, onClickTranscript, categories } = props;

	const isFiscalYear = !_.some(
		company.documents,
		d => _.isEmpty(d.fiscalYear) || _.isEmpty(d.fiscalQuarter)
	);

	return (
		<>
			<KeyDrivers>
				<SectionTitle>
					<Text size={FontSizes.s3} color={Colors.black} fontFamily={FontFamilies.bold}>
						Key Drivers - Trend of last 4 documents
					</Text>
					<InfoIcon
						data-tip={TOOL_TIPS.KEY_DRIVER}
						data-for='EXPAND_ROW_TOOL_TIP'
						id={ids.TOOLTIP.EXPAND_ROW_TOOL_TIP_ICON}
					/>
				</SectionTitle>
				<KeyDriversChartNewArk>
					{keyDriversData.length > 0 ? (
						drawKeyDriverCharts(keyDriversData, categories, isFiscalYear)
					) : (
						<KeyDriversEmpty>
							<Text
								id={ids.WACTHLIST_PAGE.KEY_DRIVERS_EMPTY_LABEL}
								size={FontSizes.m1}
								fontFamily={FontFamilies.bold}
								color={Colors.gray}
							>
								No Key Drivers Available
							</Text>
						</KeyDriversEmpty>
					)}
				</KeyDriversChartNewArk>
			</KeyDrivers>
		</>
	);
};

export default KeyDriversChartsNewArk;
