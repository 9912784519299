import React, { FC } from 'react';
import styled from 'styled-components';
import { Text } from '../../../components';
import { Colors, FontFamilies, FontSizes } from '../../../components/shared/styles';
import Hightchart from '../Highchart/highchart';

const Graph = styled.div`
	display: inline-flex;
	flex-direction: column;
	justify-content: space-around;
	width: calc(100% - 490px);
	line-height: 31px;
`;

interface AmenityScoreGraphProps {
	amenityScoreChartData: { y: number; x: number }[];
}

const AmenityScoreGraph: FC<AmenityScoreGraphProps> = (props: AmenityScoreGraphProps) => {
	return (
		<Graph>
			<Text size={FontSizes.s2} fontFamily={FontFamilies.bold} color={Colors.black}>
				Amenity score changes:
			</Text>
			<Hightchart chartsData={props.amenityScoreChartData} />
		</Graph>
	);
};

export default AmenityScoreGraph;
