import * as React from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';
import Images from '../../shared/images';
import { APP_COLORS } from '../../constants';
import {
	ListContainer,
	InnerListContainer,
	ScrollContainer,
	Arrow,
	Spinner
} from '../../pages/sideBarElements';
import { Part, PartItem } from '../../types/types';
import { areValidSections, stripQuotesAndComma } from './sections.util';
import { decodeHTMLEntities } from '../../../components/shared/util';

const SECTION_HEADER_HEIGHT = 65;
const ITEM_HEIGHT = 60;

const SectionHeader = styled.div<{ clickable: boolean }>`
	position: relative;
	height: ${SECTION_HEADER_HEIGHT}px;
	line-height: ${SECTION_HEADER_HEIGHT}px;
	padding-left: 20px;
	font-size: 20px;
	font-family: Assistant SemiBold;
	border-radius: 2px;
	color: ${APP_COLORS.BLACK_32313B};
	box-shadow: 0 7px 11px -11px #177f99;
	cursor: ${props => (props.clickable ? 'pointer' : 'default')};
	user-select: none;
`;

const ItemContainer = styled.div`
	height: ${ITEM_HEIGHT}px;
	margin-left: 15px;
	background-color: #fbfbfb;
	box-sizing: border-box;
	border-bottom: 1px solid #eef2f3;
	cursor: pointer;
	font-family: Assistant SemiBold;
	padding: 7px 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	:hover {
		background-color: #fff;
		margin-left: 7px;
		border-radius: 2px;
		box-shadow: -3px 2px 14px -2px #dde5e7;
		padding-left: 18px;
	}

	transition: margin-left 0.2s, padding-left 0.2s;
`;

const TextSpan = styled.div<{ fontSize: number; fontFamily?: string }>`
	color: ${APP_COLORS.BLACK_32313B};
	font-size: ${props => props.fontSize}px;
	font-family: ${props => (props.fontFamily ? props.fontFamily : 'Assistant')};
`;

const EmptyStateContainer = styled.div`
	margin-top: 100px;
`;

const EmptyStateImage = styled.img`
	display: block;
	margin: 0 auto;
`;

const EmptyStateText = styled.div`
	padding-top: 10px;
	width: 100%;
	color: #dde5e7;
	font-family: Assistant SemiBold;
	font-size: 22px;
	text-align: center;
`;

interface SectionsListProps {
	show: boolean;
	sections: Part[];
	onItemClicked: Function;
	navReloadCounter: number;
	loading?: boolean;
}

interface SectionsListState {
	openSectionsIDS: any[];
	loadedSections: string[];
}

export default class SectionsList extends React.Component<SectionsListProps, SectionsListState> {
	sectionsInterval;

	constructor(props) {
		super(props);
		this.state = {
			openSectionsIDS: [],
			loadedSections: []
		};
	}

	componentDidUpdate(prevProps) {
		if (
			!_.isEqual(prevProps.sections, this.props.sections) ||
			prevProps.navReloadCounter !== this.props.navReloadCounter
		) {
			this.setState({ loadedSections: [], openSectionsIDS: [] });
		}
	}

	componentWillUnmount() {
		if (this.sectionsInterval) {
			clearInterval(this.sectionsInterval);
		}
	}

	onClickSection = (id: any) => {
		const indexOfSectionClicked = _.findIndex(this.state.openSectionsIDS, s => s === id);
		if (indexOfSectionClicked === -1) {
			this.setState({ openSectionsIDS: [...this.state.openSectionsIDS, id] });
		} else {
			this.setState({
				openSectionsIDS: _.filter(this.state.openSectionsIDS, s => s !== id)
			});
		}
	};

	renderSectionsList = (sections: Part[]): any => {
		return _.map(sections, (section: Part, index) => {
			const sectionKey: string = `SECTION_ID_${index}_${section.navigationId}`;
			const isSectionOpen = _.findIndex(this.state.openSectionsIDS, s => s === sectionKey) !== -1;

			return (
				<div
					key={sectionKey}
					onClick={() => this.onClickSection(sectionKey)}
					data-part={`PART_${index + 1}`}
				>
					<SectionHeader clickable={true}>
						{section.name}
						<Arrow myUrl={Images.arrowDownGrey} isOpen={isSectionOpen} />
					</SectionHeader>
					<InnerListContainer isOpen={isSectionOpen} myHeight={ITEM_HEIGHT * section.items.length}>
						{this.getItems(section)}
					</InnerListContainer>
				</div>
			);
		});
	};

	onItemClicked = (e, item: PartItem) => {
		e.stopPropagation();
		this.props.onItemClicked(item);
	};

	getItems = (section: Part): any => {
		return _.map(section.items, (item: PartItem, index) => {
			const decodedString = decodeHTMLEntities(item.name);
			return (
				<ItemContainer
					key={`${section.navigationId}_${index}_${item.navigationId}`}
					onClick={e => this.onItemClicked(e, item)}
				>
					<TextSpan
						fontSize={16}
						fontFamily={'Assistant SemiBold'}
						data-item={stripQuotesAndComma(decodedString)}
						title={decodedString}
					>
						{decodedString}
					</TextSpan>
					<br />
				</ItemContainer>
			);
		});
	};

	renderEmptyState = (): any => {
		return (
			<EmptyStateContainer>
				<EmptyStateImage src={Images.tenKEmptyState} />
				<EmptyStateText>Could not extract sections</EmptyStateText>
			</EmptyStateContainer>
		);
	};

	render() {
		const { sections, show, loading } = this.props;
		return (
			<ListContainer style={{ display: show ? 'block' : 'none' }}>
				{loading ? (
					<Spinner id='10K_SECTION_SPINNER_ID' src={Images.spinner} />
				) : (
					<ScrollContainer>
						{areValidSections(sections) ? (
							<div style={{ height: '100%' }}>{this.renderSectionsList(sections)}</div>
						) : (
							<div>{this.renderEmptyState()}</div>
						)}
					</ScrollContainer>
				)}
			</ListContainer>
		);
	}
}
