import * as React from 'react';
import styled from 'styled-components';
import ParagraphText from './paragraphText';
import SpeakerLine from './speakerLine';
import { FinancialSentence } from '../../../queries/autogenerate/schemas';

const SpeakerContainer = styled.div`
	width: 100%;
	margin-bottom: 30px;
`;

export interface Speaker {
	type: string;
	title: string;
	affiliation?: string;
	entity: string;
	name: string;
}

const QNASectionHeader = styled.div`
	width: 100%;
	height: 65px;
	box-shadow: 0 11px 11px -11px rgba(0, 0, 0, 0.2);
	color: #4a4a4a;
	font-family: Assistant Light;
	font-size: 24px;
	line-height: 65px;
	margin-bottom: 20px;
`;

export interface Section {
	speaker: Speaker;
	sentences: FinancialSentence[];
}

interface QNASectionProps {
	sections: Section[];
	printMode: boolean;
	selectedEventId: string;
}

const QNASection: React.FunctionComponent<QNASectionProps> = ({
	sections,
	printMode,
	selectedEventId
}: QNASectionProps) => {
	return (
		<div>
			<QNASectionHeader>Questions & Answers</QNASectionHeader>
			{sections?.map((section, sectionKeyIndex) => (
				<SpeakerContainer key={sectionKeyIndex}>
					<SpeakerLine speaker={section?.speaker} type={section.sentences?.[0]?.section} />
					{section?.sentences?.map((sentence, sentenceKeyIndex) => (
						<ParagraphText
							key={sentenceKeyIndex}
							selectedEventId={selectedEventId}
							sentence={sentence}
							printMode={printMode}
						/>
					))}
				</SpeakerContainer>
			))}
		</div>
	);
};

export default QNASection;
