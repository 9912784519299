import { action, observable } from 'mobx';
import * as _ from 'lodash';

import { Event, MobileNotSupportedMessage, EventElement, Paragraph } from '../types/types';
import * as localStorageSrv from '../services/localstorage.service';
import { LOCAL_STORAGE_KEYS, TABS, APP_URLS } from '../constants';
import { isCurrentRoute } from '../services/util.service';

export default class GlobalStore {
	@observable navigatedEvent = undefined;
	@observable isFirstSearch = false;
	@observable searchEventsRawCount = -1;

	@observable currentTab: number;
	@observable isEventsPanelOpened: boolean = true;

	@observable mobileNotSupported = MobileNotSupportedMessage.NO_SHOW;

	@observable previousRoute = '';
	@observable eventElements: EventElement[] = [];

	@observable showNeutralExtractions = true;

	@observable isRightSidePanelOpened = false;

	eventsNotFound: Event[] = [];

	firstDocumentSelected = false;
	@observable selectedDocumentYears: any = {};

	private static instance: GlobalStore;

	static getInstance() {
		return GlobalStore.instance || new GlobalStore();
	}

	constructor() {
		if (GlobalStore.instance) {
			return GlobalStore.instance;
		}
		GlobalStore.instance = this;
		setTimeout(() => {
			this.setTabsStateOnLoad();
		}, 100);
		this.setPreviousRoute(localStorageSrv.getString(LOCAL_STORAGE_KEYS.PREV_VISITED_PAGE));
	}

	formatDocumentType = (documentTypes: any): any[] => {
		return _.map(documentTypes, f => {
			if (!this.firstDocumentSelected) {
				this.firstDocumentSelected = true;
				return { ...f, isSelected: true };
			}
			return { ...f, isSelected: false };
		});
	};

	@action
	setNavigatedEvent = (event: { id?: number | string; hash?: number | string }) => {
		this.navigatedEvent = event;
	};

	@action
	setEventFirstSearch = (isSearchPerformed: boolean) => {
		this.isFirstSearch = isSearchPerformed;
	};

	@action
	setTabsStateOnLoad = () => {
		const location = document.location;
		let currentTab;

		if (
			isCurrentRoute(location, APP_URLS.ROOT, true) ||
			isCurrentRoute(location, APP_URLS.WACTHLIST)
		) {
			currentTab = TABS.WATCHLIST;
		} else if (isCurrentRoute(location, APP_URLS.QI)) {
			currentTab = TABS.QUERY;
		} else if (isCurrentRoute(location, APP_URLS.EVENTS_SUMMARY)) {
			currentTab = TABS.QUERY;
		} else if (isCurrentRoute(location, APP_URLS.DASHBOARD)) {
			currentTab = TABS.ANALYTICS_LOOKER;
		} else {
			currentTab = -1;
		}
		this.setCurrentTab(currentTab);
	};

	@action
	setCurrentTab = (nextTab: TABS) => {
		this.currentTab = nextTab;
	};

	@action
	toggleEventsPanel = () => {
		this.isEventsPanelOpened = !this.isEventsPanelOpened;
	};

	@action
	setMobileNotSupported = (val: MobileNotSupportedMessage) => {
		this.mobileNotSupported = val;
	};

	@action
	setEventElements = (eventElements: EventElement[]) => {
		this.eventElements = _.union(this.eventElements, eventElements);
	};

	@action
	setPreviousRoute = (val: string) => {
		this.previousRoute = val ? val : '';
		localStorageSrv.setString(LOCAL_STORAGE_KEYS.PREV_VISITED_PAGE, this.previousRoute);
	};

	initializeNonfoundEvents = () => {
		this.eventsNotFound = [];
	};

	addEventsToNonFound = (events: Event[], paragraph: Paragraph) => {
		this.setEventsNotFound(_.union(this.eventsNotFound, events));
	};

	setEventsNotFound = (events: Event[]) => {
		this.eventsNotFound = events;
	};

	@action
	setSelectedDocumentYear = (companyId: number | string, year: number) => {
		this.selectedDocumentYears = {
			...this.selectedDocumentYears,
			[companyId]: year
		};
	};

	@action
	setShowNeutralExtractions = (value: boolean) => {
		this.showNeutralExtractions = value;
	};

	@action
	toggleRightSidePanelOpened = () => {
		this.isRightSidePanelOpened = !this.isRightSidePanelOpened;
	};
}
