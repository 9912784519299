const ModalStyle = (w: number, h: number): any => {
	return {
		overlay: {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			zIndex: 10,
			backgroundColor: 'rgba(0, 0, 0, 0.5)'
		},
		content: {
			padding: '0',
			border: 'none',
			borderRadius: '0px',
			width: w,
			height: h,
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			transform: 'translate(-50%, -50%)',
			overflow: 'hidden',
			backgroundColor: '#fff'
		}
	};
};
export default ModalStyle;
