import * as React from 'react';
import * as ReactMenuAim from 'react-menu-aim';
import createClass from 'create-react-class';
import * as _ from 'lodash';
import styled from 'styled-components';
import Images from '../../shared/images';
import { DropDownItem } from '../../types/types';
import { APP_COLORS } from '../../constants';

interface DropDownRelativeContainerProps {
	width: number;
	maxHeightBody: number;
}
const DropDownRelativeContainer = styled.div<DropDownRelativeContainerProps>`
	position: relative;
	box-sizing: border-box;
	max-height: ${props => props.maxHeightBody}px;
	width: ${props => props.width}px;
	padding: 10px 0;
	z-index: 1;
	border: 1px solid #eee;
	border-radius: 10px;
	background-color: #fff;
	overflow-y: auto;
	box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
	animation-name: dropDownOccurrence;
	animation-duration: 0.2s;

	@keyframes dropDownOccurrence {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

const DropDownBodyContainer = styled.div`
	width: 100%;
`;

interface MenuItemProps {
	active: boolean;
}
const MenuItem = styled.div<MenuItemProps>`
	cursor: pointer;
	white-space: nowrap;
	font-size: 18px;
`;
const LINE_HEIGHT = 40;
const MenuItemLine = styled(MenuItem)`
	height: ${LINE_HEIGHT}px;
	padding: 0 4px;
	background-color: white;
	line-height: ${LINE_HEIGHT}px;
	:hover {
		background-color: ${APP_COLORS.LIGHT_GRAY_SEARCHING_EEF1F5};
	}
`;

const DropDownHeader = styled.div`
	height: ${LINE_HEIGHT}px;
	padding: 0 0 0 16px;
	line-height: ${LINE_HEIGHT}px;
	font-family: Assistant;
`;
interface MainMenuItemProps {
	active: boolean;
}
const MainMenuItemTitle2 = styled.div<MainMenuItemProps>`
	float: left;
	height: ${LINE_HEIGHT}px;
	width: calc(100% - 40px);
	line-height: ${LINE_HEIGHT}px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 14px;
	color: black;
`;

const MainMenuItemSubtitle = styled.div`
	height: 17px;
	color: #696868;
	font-size: 11px;
	line-height: 17px;
	padding-top: 4px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const IconContainer = styled.div`
	height: 100%;
	width: 40px;
	float: left;
`;
const DropDownItems = styled.div`
	width: 100%;
`;

interface SortImageProps {
	marginTop: number;
}
const SortImg = styled.img<SortImageProps>`
	position: absolute;
	right: 13px;
	margin: ${props => props.marginTop}px 0 0 15px;
`;
const SelectedV = styled.img`
	margin: 0 10px;
`;

const DropDown = createClass({
	mixins: [ReactMenuAim],
	dropDownButton: undefined,
	getInitialState: function(): any {
		// First item is default when there is no default selection or only 1 item
		const state = {
			isEnabled: this.props.data.length > 1
		};
		return state;
	},
	componentDidMount: function() {
		document.addEventListener('mousedown', this.handleDropDownClick, true);
	},

	componentWillMount: function() {
		this.initMenuAim({
			delay: 300,
			tolerance: 75
		});
	},
	componentWillUnmount: function() {
		document.removeEventListener('mousedown', this.handleDropDownClick, true);
	},

	onClickDropDown: function() {
		this.props.toggleSortMenuState(); // change SortMenuState
	},
	handleDropDownClick: function(e) {
		if (!this.dropDownButton.contains(e.target)) {
			this.props.toggleSortMenuState(); // change SortMenuState
		}
	},

	mapMenuItems: function(
		data: DropDownItem[],
		selectedItemIndex: number,
		id: string = 'default_id'
	): Array<any> {
		const items = _.map(data, (menuItem, index) => {
			const active: boolean = index === selectedItemIndex ? true : false;

			return (
				<MenuItemLine
					id={`${id}_${menuItem.title}`}
					active={active}
					key={index}
					onClick={() => this.handleDropDownChange(index)}
				>
					<IconContainer>{active && <SelectedV src={Images.blackSortV} />}</IconContainer>
					<MainMenuItemTitle2 title={menuItem.title} active={active}>
						{menuItem.title}
					</MainMenuItemTitle2>
					{menuItem.subtitle && (
						<MainMenuItemSubtitle title={menuItem.subtitle}>
							{menuItem.subtitle}
						</MainMenuItemSubtitle>
					)}
				</MenuItemLine>
			);
		});
		return items;
	},

	handleDropDownChange: function(index: number) {
		// if flow not changed
		if (index !== this.props.selectedItemIndex) {
			this.props.handleDropDownChange(this.props.data[index].id);
			this.props.changeSelectedItemIndex(index); // changes and save Index of Selected Item
		}
		this.props.toggleSortMenuState(); // change SortState
	},

	render: function(): any {
		const { isEnabled } = this.state;
		return (
			<DropDownRelativeContainer width={this.props.width} maxHeightBody={this.props.maxHeightBody}>
				<DropDownBodyContainer>
					<DropDownHeader>
						{this.props.dropDownTitle}{' '}
						<SortImg marginTop={this.props.imgMarginTop} src={this.props.dropDownImg} />
					</DropDownHeader>
					<DropDownItems ref={node => (this.dropDownButton = node)}>
						{isEnabled &&
							this.mapMenuItems(this.props.data, this.props.selectedItemIndex, this.props.id)}
					</DropDownItems>
				</DropDownBodyContainer>
			</DropDownRelativeContainer>
		);
	}
});

export default DropDown;
