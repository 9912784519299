import * as React from 'react';
import * as _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import BackOfficeStore from '../../stores/backOffice.store';
import styled from 'styled-components';
import { STORE_BACK_OFFICE } from '../../constants';
import { polarityToString } from '../../services/util.service';
import { Event, DropDownItem } from '../../types/types';
import DropDown from '../../widgets/DropDown/dropDown';
import { eventStatusToDropdownId, dropdownIdToEventStatus } from './backoffice.util';

const EVENT_STATUS_OPTIONS: DropDownItem[] = [
	{
		id: 0,
		title: 'All'
	},
	{
		id: 1,
		title: 'New'
	},
	{
		id: 2,
		title: 'Ignored'
	},
	{
		id: 3,
		title: 'In Evaluation'
	},
	{
		id: 4,
		title: 'Embedded'
	}
];
interface TableCellProps {
	elipssis?: boolean;
}
const TableCell = styled.td<TableCellProps>`
	max-width: 305px;
	white-space: nowrap;
	overflow: ${props => (props.elipssis ? 'hidden' : 'initial')};
	text-overflow: ellipsis;
	color: rgba(0, 0, 0, 0.87);
	font-size: 0.8125rem;
	padding: 4px 50px 4px 24px;
	text-align: left;
	border-bottom: 1px solid rgba(224, 224, 224);
	vertical-align: inherit;
`;

const WrapTable = styled.div`
	margin: 20px;
`;

const PageHeader = styled.div`
	margin: 20px;
`;

const PageTitle = styled.div`
	font-size: 16px;
	padding-top: 30px;
	padding-left: 22px;
`;

const Input = styled.input`
	height: 25px;
	width: 170px;
	padding-left: 10px;
`;

const HeaderDropDownWrap = styled.div`
	display: inline-block;
	vertical-align: top;
	margin-left: 20px;
	width: 184px;
	height: 31px;
`;

const StatusSpan = styled.span`
	margin-left: 50px;
`;

const styles = {
	paper: {
		maxHeight: '680px',
		minHeight: '300px',
		overflow: 'auto'
	}
};

interface AnnotationsTabProps {
	classes: any;
}

interface AnnotationsTabState {
	searchText: string;
	searchStatus: number;
}

@inject(STORE_BACK_OFFICE)
@observer
class AnnotationsTab extends React.Component<AnnotationsTabProps, AnnotationsTabState> {
	constructor(props: AnnotationsTabProps) {
		super(props);
		this.state = {
			searchText: '',
			searchStatus: 0
		};
		(this.props[STORE_BACK_OFFICE] as BackOfficeStore).getAllEvents();
	}

	handleKeyPress = event => {
		this.setState({ searchText: event.target.value });
	};

	onStatusChange = (statusSelectionId: number, eventId: number): void => {
		(this.props[STORE_BACK_OFFICE] as BackOfficeStore).updateEventStatus(
			eventId,
			dropdownIdToEventStatus(statusSelectionId)
		);
	};

	handleSelectionSearch = (status: number) => {
		this.setState({ searchStatus: status });
	};

	filteredEvents = (events: Event[]): Event[] => {
		const searchTerm = this.state.searchText.toLowerCase();
		const searchStatus = this.state.searchStatus;
		return _.filter(
			events,
			(e: Event) =>
				(eventStatusToDropdownId(e.status) === searchStatus || searchStatus === 0) &&
				((e.text && e.text.toLowerCase().indexOf(searchTerm) !== -1) ||
					(e.categoryName && e.categoryName.toLowerCase().indexOf(searchTerm) !== -1) ||
					(e.clientName && e.clientName.toLowerCase().indexOf(searchTerm) !== -1) ||
					(e.description && e.description.toLowerCase().indexOf(searchTerm) !== -1) ||
					(e.documentTitle && e.documentTitle.toLowerCase().indexOf(searchTerm) !== -1) ||
					e.flow.toString().indexOf(searchTerm) !== -1 ||
					e.startIndex.toString().indexOf(searchTerm) !== -1 ||
					(e.userName && e.userName.indexOf(searchTerm) !== -1) ||
					polarityToString(e.polarity)
						.toLowerCase()
						.indexOf(searchTerm) !== -1 ||
					String(e.transcriptId).indexOf(searchTerm) !== -1 ||
					String(e.createdAt)
						.toLowerCase()
						.indexOf(searchTerm) !== -1 ||
					e.ticker.toLowerCase().indexOf(searchTerm) !== -1 ||
					e.region.toLowerCase().indexOf(searchTerm) !== -1)
		);
	};

	renderUpperSection = () => {
		return (
			<div>
				<PageTitle>Annotation</PageTitle>
				<PageHeader>
					<Input
						type='text'
						placeholder='Search'
						onChange={e => this.handleKeyPress(e)}
						id='BACKOFFICE_ANNOTATIONS_SEARCH_BAR'
					/>
					<StatusSpan>Status</StatusSpan>
					<HeaderDropDownWrap>
						<DropDown
							type={2}
							maxHeightBody={160}
							height={31}
							width={183}
							arrowTop={1}
							mainLabelPrefix={''}
							defaultSelection={this.state.searchStatus}
							data={EVENT_STATUS_OPTIONS}
							handleDropDownChange={status => this.handleSelectionSearch(status)}
							id={`BACKOFFICE_ANNOTATIONS_STATUS_DROPDOWN_ITEM_???`}
						/>
					</HeaderDropDownWrap>
				</PageHeader>
			</div>
		);
	};

	renderTableBody = (filteredEvents: Event[]) => {
		return (
			<TableBody>
				{_.map(filteredEvents, event => {
					const tickerRegion =
						event.ticker && event.region ? `${event.ticker}: ${event.region}` : '';
					return (
						<TableRow key={event.id}>
							<TableCell elipssis>{event.clientName}</TableCell>
							<TableCell elipssis>{event.flow}</TableCell>
							<TableCell elipssis>{event.userName}</TableCell>
							<TableCell elipssis title={String(event.createdAt)}>
								{event.createdAt}
							</TableCell>
							<TableCell elipssis title={event.documentTitle}>
								{event.documentTitle}
							</TableCell>
							<TableCell elipssis title={tickerRegion}>
								{tickerRegion}
							</TableCell>
							<TableCell elipssis>{event.transcriptId}</TableCell>
							<TableCell elipssis title={event.text}>
								{event.text}
							</TableCell>
							<TableCell elipssis title={event.description}>
								{event.description}
							</TableCell>
							<TableCell elipssis title={event.categoryName}>
								{event.categoryName}
							</TableCell>
							<TableCell elipssis>{polarityToString(event.polarity)}</TableCell>
							<TableCell>
								<DropDown
									type={2}
									maxHeightBody={160}
									height={25}
									width={120}
									arrowTop={2}
									mainLabelPrefix={''}
									defaultSelection={eventStatusToDropdownId(event.status)}
									data={_.filter(EVENT_STATUS_OPTIONS, s => s.id !== 0)}
									handleDropDownChange={selectionId => this.onStatusChange(selectionId, event.id)}
									id={`BACKOFFICE_ANNOTATIONS_TABLE_ROW_STATUS_DROPDOWN_ITEM_???`}
								/>
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		);
	};

	renderTable = (filteredEvents: Event[]) => {
		return (
			<WrapTable>
				<Paper className={this.props.classes.paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Client</TableCell>
								<TableCell>Flow</TableCell>
								<TableCell>User</TableCell>
								<TableCell>Date</TableCell>
								<TableCell>Document Title</TableCell>
								<TableCell>Ticker: Region</TableCell>
								<TableCell>Document Id</TableCell>
								<TableCell>Sentence</TableCell>
								<TableCell>Comment</TableCell>
								<TableCell>Category</TableCell>
								<TableCell>Polarity</TableCell>
								<TableCell>Status</TableCell>
							</TableRow>
						</TableHead>
						{this.renderTableBody(filteredEvents)}
					</Table>
				</Paper>
			</WrapTable>
		);
	};

	render() {
		const { events } = this.props[STORE_BACK_OFFICE] as BackOfficeStore;
		const filteredEvents = this.filteredEvents(events);
		return (
			<div>
				{this.renderUpperSection()}
				{this.renderTable(filteredEvents)}
			</div>
		);
	}
}

export default withStyles(styles)(AnnotationsTab);
