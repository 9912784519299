import React from 'react';
import styled from 'styled-components';

import * as ids from '../../id.constants.js';
import { Colors, FontSizes, FontFamilies } from '../shared/styles';
import { Polarity } from '../shared/types';
import { polarityColor } from '../shared/util';

const ToggleContainer = styled.div`
	user-select: none;
	display: flex;
`;

interface ToggleOptProps {
	isActive: boolean;
	polarity: Polarity;
}

const TogglePositive = styled.div<ToggleOptProps>`
	height: 32px;
	width: 88px;
	line-height: 32px;
	text-align: center;
	color: ${({ isActive }) => (isActive ? Colors.white : Colors.gray)};
	font-size: ${FontSizes.s2};
	font-family: ${({ isActive }) => (isActive ? FontFamilies.bold : FontFamilies.regular)};
	border: 1px solid ${Colors.gray};
	border-radius: ${({ polarity }) =>
		polarity === Polarity.positive ? '16px 0 0 16px' : '0 16px 16px 0'};
	background-color: ${({ polarity, isActive }) => {
		if (!isActive) {
			return Colors.white;
		}
		return polarityColor(polarity);
	}}
	cursor: pointer;
`;

interface PolarityToggleProps {
	selectedPolarity: Polarity;
	onSelect?(polarity: Polarity): void;
}

export const PolarityToggle: React.FunctionComponent<PolarityToggleProps> = ({
	selectedPolarity = Polarity.positive,
	onSelect
}) => (
	<ToggleContainer>
		<TogglePositive
			id={ids.ANNOTATION_MODAL.POLARITY_POSITIVE}
			onClick={() => onSelect?.(Polarity.positive)}
			isActive={selectedPolarity === Polarity.positive}
			polarity={Polarity.positive}
		>
			Positive
		</TogglePositive>
		<TogglePositive
			id={ids.ANNOTATION_MODAL.POLARITY_NEGATIVE}
			onClick={() => onSelect?.(Polarity.negative)}
			isActive={selectedPolarity === Polarity.negative}
			polarity={Polarity.negative}
		>
			Negative
		</TogglePositive>
	</ToggleContainer>
);
