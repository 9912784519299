import React from 'react';
import styled from 'styled-components';
import GenericModal from '../../app/widgets/GenericModal/genericModal';
import { Colors, FontSizes } from '../shared/styles';

const modalStyle = {
	overlay: {},
	content: {
		width: '478px',
		borderRadius: '4px',
		boxShadow: '0 0 5px 0 rgba(43,43,43,0.5)',
		backgroundColor: '#FFFFFF'
	}
};

const Container = styled.div`
	background-color: #ffffff;
	height: 100%;
	text-align: center;
`;

const TitleIcon = styled.img`
	width: 78px;
	height: 78px;
`;

const Title = styled.div`
	font-family: Assistant;
	font-size: 24px;
	font-weight: 600;
	color: ${Colors.darkBlue};
	line-height: 2;
`;

const Text = styled.div`
	color: #4a4a4a;
	font-family: Assistant;
	font-size: ${FontSizes.s4};
	padding: 0 72px 0 72px;
`;

const ModalContent = styled.div<{ fixedHeight?: boolean }>`
	height: ${props => (props.fixedHeight ? '276px' : 'auto')};
	margin-top: 15px;
	padding: 0 72px 11px 72px;
	box-sizing: border-box;
	overflow-y: auto;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin: 21px 72px 55px 72px;
`;

interface ModalProps {
	icon?: any;
	title?: string;
	text?: string;
	isModalOpen: boolean;
	modalContent?: any;
	fixedContentHeight?: boolean;
	modalButtons: any;
	close: () => void;
}

const Modal: React.FunctionComponent<ModalProps> = ({
	icon,
	title,
	text,
	isModalOpen,
	modalContent,
	fixedContentHeight,
	modalButtons,
	close
}) => {
	return (
		<GenericModal
			isModalOpen={isModalOpen}
			closeModal={close}
			modalStyle={modalStyle}
			closeIconWithMargin={true}
		>
			<Container>
				{icon && <TitleIcon src={icon} />}
				{title && <Title>{title}</Title>}
				<Text>{text}</Text>
				{modalContent && (
					<ModalContent fixedHeight={fixedContentHeight}>{modalContent}</ModalContent>
				)}
				<ButtonContainer>{modalButtons}</ButtonContainer>
			</Container>
		</GenericModal>
	);
};

export default Modal;
