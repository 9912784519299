import { LOCAL_SOTARGE_PREFIX } from '../constants';
import { LocalStorageObject } from '../types/types';

export const setString = (key: string, value: string): void => {
	localStorage.setItem(LOCAL_SOTARGE_PREFIX + key, value);
};

export const setObject = (key: string, object: LocalStorageObject): boolean => {
	const str = JSON.stringify(object);
	try {
		localStorage.setItem(LOCAL_SOTARGE_PREFIX + key, str);
		return true;
	} catch (e) {
		console.error(e);
		return false;
	}
};

export const setBoolean = (key: string, value: boolean): void => {
	localStorage.setItem(LOCAL_SOTARGE_PREFIX + key, `${value}`);
};

export const getString = (key: string): string => {
	return localStorage.getItem(LOCAL_SOTARGE_PREFIX + key);
};

export const getObject = (key: string): LocalStorageObject => {
	const object = localStorage.getItem(LOCAL_SOTARGE_PREFIX + key);
	try {
		return JSON.parse(object);
	} catch (e) {
		console.error(e);
		return {};
	}
};

export const getBoolean = (key: string): boolean => {
	const value = localStorage.getItem(LOCAL_SOTARGE_PREFIX + key);
	return value === 'true';
};

export const removeItem = (key: string): void => {
	localStorage.removeItem(LOCAL_SOTARGE_PREFIX + key);
};
