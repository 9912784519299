import * as React from 'react';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import currentDocumentTypeState from '../../recoil/currentDocumentType';
import Images from '../../shared/images';
import { Colors } from '../../../components/shared/styles';
import { FEATURES_TYPE_SPLIT } from '../../constants';
import { useFeature } from '../../hooks/useFeature';
import { useGetSubscriptionsQuery } from '../../queries/autogenerate/hooks';
import {
	getAllOrderedDocumentTypes,
	DocumentType,
	DocumentTypeGrouping,
	documentTypefromCode,
	isDocumentTypeInGrouping
} from '../../types/DocTypes.types';

const Container = styled.div`
	height: 100%;
	width: 100%;
`;
const TitleText = styled.div`
	color: ${Colors.dark};
	font-family: Assistant;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	font-size: 18px;
`;

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
	padding-left: 18px;
`;

const BodyWrap = styled.div`
	height: calc(100% - 80px);
	overflow-y: auto;
`;

const CategoryHeader = styled.div`
	position: relative;
	height: 30px;
	line-height: 28px;
	border-bottom: 2px solid #ebeff1;
	box-sizing: border-box;
	cursor: pointer;
`;

interface CategoryItemProps {
	isOpen: boolean;
}
const CategoryItem = styled.div<CategoryItemProps>`
	height: ${props => (props.isOpen ? 'auto' : '30px')};
	background-color: ${Colors.white};
	box-sizing: border-box;
	overflow: hidden;
	transition: height 0.5s;
	margin-bottom: 20px;

	:(last-child) {
		margin-bottom: 0;
		padding-bottom: 10px;
	}
`;

const Center = styled.div`
	position: relative;
	width: calc(100% - 36px);
	height: 100%;
	margin: 0 auto;
`;

interface ArrowProps {
	isOpen: boolean;
	myUrl: string;
}
const Arrow = styled.div<ArrowProps>`
	position: absolute;
	top: 11px;
	right: 0px;
	width: 10px;
	height: 7px;
	transform: ${props => (props.isOpen ? 'rotate(-180deg)' : '')};
	mask-image: url(${props => props.myUrl});
	mask-size: contain;
	mask-position: center;
	-webkit-mask-repeat: no-repeat;
	background-color: ${props => (props.isOpen ? Colors.blue : Colors.darkGray)};
	transition: transform 0.5s, background-color 0.5s;
`;

interface ItemsContainerProps {
	show: boolean;
}
const ItemsContainer = styled.div<ItemsContainerProps>`
	background-color: ${Colors.white};
	opacity: ${props => (props.show ? 1 : 0)};
	transition: opacity 0.5s;
`;

const Item = styled.div`
	padding-top: 16px;
	&:first-child {
		padding-top: 13px;
	}
`;

interface ItemTextProps {
	selected: boolean;
}
const ItemText = styled.div<ItemTextProps>`
	color: ${props => (props.selected ? Colors.blue : Colors.dark)};
	font-weight: ${props => (props.selected ? '600' : 'none')};
	font-family: Assistant;
	font-size: 15px;
	cursor: pointer;

	:hover {
		color: ${Colors.blue};
	}
`;

const CloseIcon = styled.div`
	display: block;
	padding: 10px;
	margin-right: 15px;
	cursor: pointer;
	background-color: ${Colors.blue};
	-webkit-mask-image: url(${Images.closeWhite});
	-webkit-mask-position: center;
	-webkit-mask-repeat: no-repeat;
	-webkit-mask-size: 11px;
`;

interface TextProps {
	fontFamily?: string;
	fontSize?: number;
}
const Text = styled.div<TextProps>`
	color: ${props => props.color};
	font-family: ${props => (props.fontFamily ? props.fontFamily : 'Assistant')};
	font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '')};
`;

interface ViewerSubMenuProps {
	title: string;
	closeSideBar: () => void;
}

const ViewerSubMenu: React.FunctionComponent<ViewerSubMenuProps> = (props: ViewerSubMenuProps) => {
	// which categories have been expanded/collapsed
	const [isTranscriptsCategoryOpen, setIsTranscriptsCategoryOpen] = useState(true);
	const [isSecCategoryOpen, setIsSecCategoryOpen] = useState(true);

	// which current doctype is being viewed
	const [currentDocType, setCurrentDocType] = useRecoilState(currentDocumentTypeState);

	// get a unique list of all doctypes available to the user (as codes)
	const { error, data, loading } = useGetSubscriptionsQuery();
	const userDocTypeCodes = _.uniq(
		data?.me?.organization?.subscriptions?.map(subscription => subscription.types).flat()
	);

	const allDocumentTypes = getAllOrderedDocumentTypes();

	// used below to determine whether to show SEC filings or not
	const ffSecFilingsV2 = useFeature(FEATURES_TYPE_SPLIT.VIEWER_SEC_FILINGS_V2);

	// on load, examine global state
	useEffect(() => {
		// if a current document type has already been set, use it
		if (currentDocType) {
			return;
		}

		// otherwise, determine the first one available to the user and remember it
		if (userDocTypeCodes.length) {
			const userDocTypes = userDocTypeCodes.map(code => documentTypefromCode(code));
			const availableDocumentTypes = _.uniq(
				allDocumentTypes.groupOrdering.map(({ documentTypes }) => documentTypes).flat()
			);

			for (const userDocType of userDocTypes) {
				if (availableDocumentTypes.includes(userDocType)) {
					// if the first available doctype is for SEC filings BUT the user doesn't
					// have the feature flag, skip to the next one
					if (
						isDocumentTypeInGrouping(userDocType, DocumentTypeGrouping.SecFilings) &&
						!ffSecFilingsV2
					) {
						continue;
					}

					setCurrentDocType(userDocType);
					return;
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userDocTypeCodes, allDocumentTypes, currentDocType]);

	if (error || (!userDocTypeCodes && !loading)) {
		return <div>error</div>;
	}

	const toggleGroup = (group: DocumentTypeGrouping) => {
		switch (group) {
			case DocumentTypeGrouping.Transcripts:
				setIsTranscriptsCategoryOpen(!isTranscriptsCategoryOpen);
				break;
			case DocumentTypeGrouping.SecFilings:
				setIsSecCategoryOpen(!isSecCategoryOpen);
				break;
		}
	};

	const handleDocumentTypeChange = (clickedDocumentType: DocumentType) => {
		if (currentDocType === clickedDocumentType) {
			return;
		}

		setCurrentDocType(clickedDocumentType);
	};

	const renderGrouping = (
		grouping: DocumentTypeGrouping,
		docTypes: DocumentType[],
		isCategoryOpen: boolean
	) => (
		<CategoryItem isOpen={isCategoryOpen} key={grouping}>
			<CategoryHeader
				onClick={() => toggleGroup(grouping)}
				id={`VIEWER_SUB_MENU_TOP_CATEGORY_TYPE_${DocumentTypeGrouping[grouping]}`}
			>
				<Center>
					<Text fontFamily={'Assistant SemiBold'} fontSize={16} color={Colors.dark}>
						{grouping}
					</Text>
					<Arrow myUrl={Images.arrowDownGrey} isOpen={isCategoryOpen} />
				</Center>
			</CategoryHeader>
			<ItemsContainer show={isCategoryOpen}>
				<Center>
					{_.map(docTypes, docType => {
						const selected = currentDocType === docType;
						return (
							<Item
								key={docType}
								onClick={() => handleDocumentTypeChange(docType)}
								id={`VIEWER_SUB_MENU_API_ID_${Object.keys(DocumentType).find(
									key => DocumentType[key] === docType
								)}`}
							>
								<ItemText
									id={`VIEWER_SUB_MENU_ITEM_TEXT_${DocumentType[docType]}`}
									selected={selected}
								>
									{docType}
								</ItemText>
							</Item>
						);
					})}
				</Center>
			</ItemsContainer>
		</CategoryItem>
	);

	const { title, closeSideBar } = props;

	return (
		<Container>
			<TitleWrap>
				<TitleText>{title}</TitleText>
				<CloseIcon onClick={closeSideBar} id='SIDEBAR_TAB_CLOSE_ICON' />
			</TitleWrap>
			<BodyWrap>
				{allDocumentTypes.groupOrdering
					// filter out SEC Filings if the user doesn't have the feature flag set
					.filter(({ grouping }) => grouping !== DocumentTypeGrouping.SecFilings || ffSecFilingsV2)
					.map(({ grouping, documentTypes }) =>
						renderGrouping(
							grouping,
							documentTypes,
							grouping === DocumentTypeGrouping.Transcripts
								? isTranscriptsCategoryOpen
								: isSecCategoryOpen
						)
					)}
			</BodyWrap>
		</Container>
	);
};

export default ViewerSubMenu;
