import * as _ from 'lodash';
import { Document, ModelFlow } from '../../types/types';
import { RECENT_DOCUMENTS } from '../../constants';

export function getTranscriptDropdownItems(docs: Document[]): { title: any; id: number }[] {
	if (!docs || docs.length <= 4) {
		return [];
	}
	const years = docs.map(doc => new Date(getDocumentDate(doc)).getUTCFullYear());
	const sortedUniqYears = _.uniq(years)
		.sort()
		.reverse();
	const dropDownItems: any = _.map(sortedUniqYears, y => ({ title: y, id: y }));
	dropDownItems.unshift({ title: 'Most Recent', id: RECENT_DOCUMENTS });
	return dropDownItems;
}

export function getTenKDropdownItems(currentFlow: ModelFlow): { title: any; id: number }[] {
	if (!currentFlow) {
		return [];
	}
	const flowStartYear = new Date(currentFlow.startDate).getUTCFullYear();
	const currentYear = new Date().getUTCFullYear();
	if (currentYear - flowStartYear < 4) {
		return [];
	}
	const years = _.range(currentYear, flowStartYear - 1, -4);
	const dropDownItems: any = _.map(years, y => ({
		title: `${y} - ${y - 3 >= flowStartYear ? y - 3 : flowStartYear}`,
		id: y
	}));
	dropDownItems.unshift({ title: 'Most Recent', id: RECENT_DOCUMENTS });
	return dropDownItems;
}

export function isYearExists(docs: Document[], currentYear): boolean {
	const years = docs ? docs.map(doc => new Date(getDocumentDate(doc)).getUTCFullYear()) : [];
	return years.includes(currentYear);
}

export function getDocumentDate(document: Document) {
	return document.eventDate ? document.eventDate : document.publicationDate;
}
