import * as React from 'react';
import * as _ from 'lodash';
import { Router, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { StyleSheetManager } from 'styled-components';
import LogoutPage from '../../pages/logout/logout';
import SignupPage from '../../pages/signupPage/signupPage';
import DocumentContainer from '../../pages/document/documentContainer';
import NewsContainer from '../../pages/news/newsContainer';
import BackofficePage from '../../pages/backoffice/backoffice';
import NotFoundPage from '../../pages/error/notFound404';
import { getParamsFromURL, isCurrentRoute, userLogId } from '../../services/util.service';
import AuthStore from '../../stores/auth.store';
import FeaturesStore from '../../stores/features.store';
import {
	STORE_AUTH,
	STORE_DATA,
	STORE_GLOBAL,
	STORE_FEATURES,
	STORE_UI,
	USER_TRACKING as UT,
	LOCAL_STORAGE_KEYS,
	STORE_ANALYTICS,
	APP_URLS
} from '../../constants';
import PrintTranscriptPage from '../../pages/printTranscript/printTranscript';
import PrintSummaryPage from '../../pages/printSummary/printSummary';
import PrintArticlePage from '../../pages/printArticle/printArticle';
import MobileNotSupported from '../../pages/mobile/mobileNotSupported';
import NotSupportedPage from '../../pages/notSupportedPage/notSupportedPage';
import DataStore from '../../stores/data.store';
import GlobalStore from '../../stores/global.store';
import { info } from '../../services/logger.service';
import { MobileNotSupportedMessage, LoginExtractedData } from '../../types/types';
import UserTracking from '../../services/userTracking.service';
import * as localStorageSrv from '../../services/localstorage.service';
import IntercomService from '../../services/intercom.service';
import AccordionLayout from '../layouts/AccordionLayout';
import EmailSubscription from '../DtcSubscribe/EmailSubscription';
import NavigationService from '../../services/navigation.service';
import DataService from '../../services/data.service';
import PlaygroundContainer from '../../pages/playground/playgroundContainer';
import { GraphQlWrapper } from './GraphQlWrapper';
import PortfolioProvider from '../Watchlist/WatchlistProvider';
import { AllWatchlistsProvider } from '../Watchlist/AllWatchlistsProvider';
import TranscriptContainer from '../../pages/transcript/documentContainer';
import PrintTranscriptPageNewarc from '../../pages/printTranscript/printTranscriptNewarc';
import DashboardContainer from '../AnalyticsLooker/DashboardContainer';

declare var screen: any;

const UTC = UT.USER_TRACKING_CATEGORIES;
const UTA = UT.USER_TRACKING_ACTIONS;
const UTL = UT.USER_TRACKING_LABELS;

// @ts-ignore
const jwtDecode = require('jwt-decode');

interface AppContainerProps {
	history: any;
	userAttributes: any;
	setUserAttributes: Function;
}

@inject(STORE_AUTH, STORE_DATA, STORE_GLOBAL, STORE_ANALYTICS, STORE_FEATURES, STORE_UI)
@observer
export default class AppContainer extends React.Component<AppContainerProps, {}> {
	navService: NavigationService;
	dataService: DataService;

	static instance: AppContainer;

	constructor(props) {
		super(props);
		if (!AppContainer.instance) {
			AppContainer.instance = this;
		}
		this.navService = new NavigationService(props);
		this.dataService = new DataService(props.history, props[STORE_AUTH]);

		this.throwError = this.throwError.bind(this);
	}

	throwError() {
		throw new Error('react test error');
	}

	logout = () => {
		(this.props[STORE_AUTH] as AuthStore).logout();
	};

	changeLastVisitedPage = (reload: boolean, destination: string) => {
		const updated = destination;
		let lastVisitedPage = localStorageSrv.getString(LOCAL_STORAGE_KEYS.CURRENT_VISITED_PAGE);
		if (updated === lastVisitedPage) {
			localStorageSrv.setBoolean(LOCAL_STORAGE_KEYS.IS_REFRESH, true);
			return;
		}
		if (lastVisitedPage && lastVisitedPage.includes(APP_URLS.EVENTS_SUMMARY) && reload) {
			lastVisitedPage = APP_URLS.ROOT;
		}

		localStorageSrv.setBoolean(LOCAL_STORAGE_KEYS.IS_REFRESH, false);
		localStorageSrv.setString(LOCAL_STORAGE_KEYS.PREV_VISITED_PAGE, lastVisitedPage);
		localStorageSrv.setString(LOCAL_STORAGE_KEYS.CURRENT_VISITED_PAGE, updated);
		(this.props[STORE_GLOBAL] as GlobalStore).setPreviousRoute(lastVisitedPage);
	};

	setIsPrint = (pathname: string) => {
		const printTranscriptPath = pathname === APP_URLS.PRINT_TRANSCRIPT;
		const printTranscriptNewarcPath = pathname === APP_URLS.PRINT_TRANSCRIPT_NEWARC;
		const printSummaryPath = pathname === APP_URLS.PRINT_SUMMARY;
		if (printTranscriptPath || printTranscriptNewarcPath || printSummaryPath) {
			localStorageSrv.setBoolean(LOCAL_STORAGE_KEYS.IS_PRINT, true);
		} else {
			localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.IS_PRINT);
		}
	};

	addChangePageListen = () => {
		this.props.history.listen(e => {
			const authStore = this.props[STORE_AUTH] as AuthStore;
			const isAuthenticated = authStore.isAuthenticated;

			this.setIsPrint(this.props.history.location.pathname);

			this.changeLastVisitedPage(false, e.pathname + e.search);
			if (!isAuthenticated && (e.pathname === APP_URLS.ROOT || e.pathname === APP_URLS.LOGOUT)) {
				this.navService.goToLogin();
			}
		});
	};

	checkNotSupportedPlatforms = () => {
		if (this.navService.isMobile()) {
			this.navService.goToMobileNotSupported(MobileNotSupportedMessage.NOT_SUPPORTED_GENERAL);
			return true;
		}
		if (isCurrentRoute(this.props.history.location, APP_URLS.SIGNUP)) {
			return false;
		}

		if (this.isIE()) {
			this.navService.goToNotSupported();
			return true;
		}
		return false;
	};

	componentDidMount() {
		this.checkNotSupportedPlatforms();
		this.changeLastVisitedPage(
			true,
			this.props.history.location.pathname + this.props.history.location.search
		);

		(this.props[STORE_DATA] as DataStore).clearCurrentDocumentMetaData();
		this.setIsPrint(this.props.history.location.pathname);

		this.addChangePageListen();
		this.handleAuthentication();
	}

	handleAuthentication = () => {
		const authStore = this.props[STORE_AUTH] as AuthStore;
		const isAuthenticated = authStore.isAuthenticated;

		if (isAuthenticated) {
			this.authenticatedInit();
		} else {
			this.notAuthenticatedInit();
		}
	};

	initIntercom = () => {
		const icomSrv = new IntercomService();
		icomSrv.boot();
	};

	initUserAttributes = () => {
		const { userAttributes } = this.props[STORE_AUTH] as AuthStore;
		this.props.setUserAttributes(userAttributes);
	};

	authenticatedInit = () => {
		(this.props[STORE_FEATURES] as FeaturesStore).init();
		this.initIntercom();
		this.initUserAttributes();
	};

	notAuthenticatedInit = () => {
		const currentPath = this.props.history.location.pathname;
		if (currentPath === '/logout') {
			this.navService.goToLogin();
			return;
		}

		const url = window.location.href;
		const params = getParamsFromURL(url);
		const { error_description, id_token, email } = params;

		if (error_description) {
			this.logout();
		} else if (id_token) {
			this.authenticate(id_token);
			(this.props[STORE_FEATURES] as FeaturesStore).init();
			this.initUserAttributes();
		} else if (currentPath === APP_URLS.DTC_SUBSCRIBE) {
			this.navService.goToRoute(`${APP_URLS.SIGNUP}?email=${email}`);
			localStorageSrv.setString(LOCAL_STORAGE_KEYS.DIRECT_LINK, APP_URLS.DTC_SUBSCRIBE);
		} else if (!this.isPartOfSpecialPaths(currentPath)) {
			const { pathname, search } = this.props.history.location;
			const link = `${pathname}${search}`;
			localStorageSrv.setString(LOCAL_STORAGE_KEYS.DIRECT_LINK, link);
			this.navService.goToLogin();
		}
	};

	isPartOfSpecialPaths = (currentPath: string) => {
		const { SIGNUP } = APP_URLS;
		const specialPaths = [SIGNUP];
		return specialPaths.includes(currentPath);
	};

	authenticate = (id_token: string) => {
		const authStore = this.props[STORE_AUTH] as AuthStore;
		const ut = new UserTracking();

		const decoded = jwtDecode(id_token);
		decoded.username = decoded.email;

		const extractedData: LoginExtractedData = {
			role: undefined,
			sub: undefined,
			first_name: undefined,
			last_name: undefined,
			createdAt: undefined,
			intercom_hash: undefined,
			readmeio_token: undefined
		};

		Object.keys(decoded).forEach(key => {
			Object.keys(extractedData).forEach(field => {
				if (key.indexOf(field) !== -1) {
					if (extractedData[field] === undefined) {
						extractedData[field] = decoded[key];
					}
				}
			});
		});
		const {
			role,
			sub,
			first_name,
			last_name,
			createdAt,
			intercom_hash,
			readmeio_token
		} = extractedData;

		const signupOrigin = localStorageSrv.getString(LOCAL_STORAGE_KEYS.GOOGLE_SIGNUP_ORIGIN);
		if (signupOrigin && signupOrigin === APP_URLS.SIGNUP) {
			localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.GOOGLE_SIGNUP_ORIGIN);
			ut.setEvent(
				UTC.USER,
				UTA.SET_PASSWORD,
				`social ${UTL.SUCCESS}_${userLogId(decoded.email, sub)}`
			);
		}

		const profile = {
			username: decoded.username,
			role,
			sub,
			firstName: first_name,
			lastName: last_name,
			createdAt,
			fullName: `${first_name} ${last_name}`
		};
		// Add the user identity to the tracking
		ut.identify(authStore.getIdentityObject(profile));
		authStore.setProfile(
			decoded.username,
			role,
			sub,
			first_name,
			last_name,
			createdAt,
			intercom_hash,
			readmeio_token
		);
		authStore.setToken(id_token);
		authStore.afterLogin = true;

		let pathname = localStorageSrv.getString(LOCAL_STORAGE_KEYS.DIRECT_LINK);
		if (!pathname) {
			const latestPortfolio = localStorageSrv.getObject(LOCAL_STORAGE_KEYS.LATEST_PORTFOLIO);
			const portfolioId = _.get(latestPortfolio, 'portfolioId');
			const username = _.get(latestPortfolio, 'username');
			pathname =
				portfolioId && username === authStore.profile.username
					? `/portfolio/${portfolioId}`
					: APP_URLS.ROOT;
		}
		this.props.history.push(pathname);
		ut.setEvent(UTC.USER, UTA.LOGIN, `${UTL.SUCCESS}_${userLogId(decoded.email, sub)}`);
		info({
			message: 'Logged in successfully',
			file: 'appContainer',
			functionName: 'authenticate'
		});
	};

	isIE = (): boolean => false || !!(document as any).documentMode;

	render() {
		const { initFinished } = this.props[STORE_FEATURES] as FeaturesStore;

		if (!initFinished && !this.isPartOfSpecialPaths(this.props.history.location.pathname)) {
			return <></>;
		}

		return (
			<GraphQlWrapper>
				<StyleSheetManager disableVendorPrefixes={process.env.NODE_ENV === 'development'}>
					<div style={{ height: '100%' }}>
						<Router history={this.props.history}>
							<Switch>
								<AccordionLayout
									exact
									path={APP_URLS.DTC_SUBSCRIBE}
									component={EmailSubscription}
								/>
								<AccordionLayout exact path={APP_URLS.PLAYGROUND} component={PlaygroundContainer} />
								<AccordionLayout exact path={APP_URLS.DASHBOARD} component={DashboardContainer} />

								<AccordionLayout
									exact
									path={APP_URLS.DASHBOARD_NO_PARAMS}
									component={DashboardContainer}
								/>

								<AccordionLayout exact path={APP_URLS.NEWS} component={NewsContainer} />
								<AccordionLayout
									exact={false}
									path={APP_URLS.ANALYTICS_LOOKER}
									component={DashboardContainer}
								/>
								<AccordionLayout
									exact
									path={APP_URLS.EMAIL_SETTINGS}
									component={EmailSubscription}
								/>

								{/* Next items: Newarc document view (transcripts/SEC filings with/without event ID) */}
								<AccordionLayout
									exact
									path={APP_URLS.TRANSCRIPT_WITH_EVENT_ID}
									component={TranscriptContainer}
								/>
								<AccordionLayout exact path={APP_URLS.TRANSCRIPT} component={TranscriptContainer} />

								<AccordionLayout
									exact
									path={APP_URLS.FLOW_TRANSCRIPT_WITH_EVENT_ID}
									component={TranscriptContainer}
								/>
								<AccordionLayout
									exact
									path={APP_URLS.FLOW_TRANSCRIPT}
									component={TranscriptContainer}
								/>

								<AccordionLayout
									exact
									path={APP_URLS.FLOW_SEC_FILING_WITH_EVENT_ID}
									component={TranscriptContainer}
								/>
								<AccordionLayout
									exact
									path={APP_URLS.FLOW_SEC_FILING}
									component={TranscriptContainer}
								/>

								<AccordionLayout
									exact
									path={APP_URLS.SEC_FILING_WITH_EVENT_ID}
									component={TranscriptContainer}
								/>
								<AccordionLayout exact path={APP_URLS.SEC_FILING} component={TranscriptContainer} />

								<AccordionLayout
									exact={false}
									path={APP_URLS.DOCUMENT}
									component={DocumentContainer}
								/>
								<AccordionLayout
									exact={false}
									path={APP_URLS.EXTERNAL_DOCUMENT}
									component={DocumentContainer}
								/>
								<AccordionLayout exact path={APP_URLS.BACKOFFICE} component={BackofficePage} />
								<AccordionLayout exact path={APP_URLS.NOT_FOUND} component={NotFoundPage} />
								<Route exact path={APP_URLS.LOGOUT} component={LogoutPage} />
								<Route exact={false} path={APP_URLS.SIGNUP} component={SignupPage} />
								<Route exact path={APP_URLS.PRINT_TRANSCRIPT} component={PrintTranscriptPage} />
								<Route
									exact
									path={APP_URLS.PRINT_TRANSCRIPT_NEWARC}
									component={PrintTranscriptPageNewarc}
								/>
								<Route exact path={APP_URLS.PRINT_SUMMARY} component={PrintSummaryPage} />
								<Route exact path={APP_URLS.PRINT_NEWS} component={PrintArticlePage} />
								<Route exact path={APP_URLS.MOBILE_NOT_SUPPORTED} component={MobileNotSupported} />
								<Route exact path={APP_URLS.NOT_SUPPORTED} component={NotSupportedPage} />
								<AccordionLayout exact path={APP_URLS.ROOT} component={AllWatchlistsProvider} />
								<AccordionLayout
									exact
									path={APP_URLS.WATCHLISTS}
									component={AllWatchlistsProvider}
								/>
								<AccordionLayout exact path={APP_URLS.WACTHLIST} component={PortfolioProvider} />
								<AccordionLayout exact path={APP_URLS.WATCHLIST} component={PortfolioProvider} />
								<AccordionLayout exact={false} path='/' component={NotFoundPage} />
							</Switch>
						</Router>
					</div>
				</StyleSheetManager>
			</GraphQlWrapper>
		);
	}
}
