import * as React from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';

import DropDown from '../../widgets/DropDown/dropDownSort';
import { Event } from '../../types/types';
import EventsList from '../EventsList/eventsList';
import { EVENT_CATEGORY_SORT, STORE_FEATURES, STORE_GLOBAL } from '../../constants';
import Images from '../../shared/images';
import * as ids from '../../../id.constants.js';
import GlobalStore from '../../stores/global.store';

import { Checkbox } from '../../../components/Checkbox/Checkbox';
import EventsNavigationSkeleton from './eventsNavigationSkeleton';

const Wrapper = styled.div<{ show: boolean; pullUp?: boolean }>`
	height: calc(100% - 53px);
	display: ${props => (props.show ? 'block' : 'none')};
	margin-top: ${props => (props.pullUp ? '-62px' : '0')};
`;

const InnerWrapper = styled.div`
	height: 100%;
`;

const FlexHeader = styled.div`
	display: flex;
	min-height: 60px;
	padding-left: 25px;
	box-sizing: border-box;
	border-bottom: 1px solid #eeeeee;
	box-shadow: 0 1px 0 0 #dde5e7;
	justify-content: space-between;
	position: relative;
`;

const DropdownContainer = styled.div`
	position: absolute;
	top: 3px;
	right: 6px;
`;
const NewSortBoxContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100px;
	padding: 0 7px 0 0;
	cursor: pointer;
`;

const SortText = styled.span`
	font-family: Assistant;
	font-size: 16px;
	padding-left: 7px;
	vertical-align: top;
`;
const SortImg = styled.img`
	margin: 3px 0 0 15px;
`;

const EventsListContainer = styled.div<{ hasHeader: boolean }>`
	height: ${props => (props.hasHeader ? 'calc(100% - 60px)' : '100%')};
`;

const CheckboxContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
`;

interface EventsListProps {
	show: boolean;
	events: Event[];
	onEventSelection: Function;
	selectedEventId: number | string;
	elementIdPrefix: string;
	openCategoryContainingEvent?: boolean;
	watchEventsLoad?: boolean;
	navReloadCounter?: number;
	loading?: boolean;
	showSort?: boolean;
	singleTabLayout?: boolean;
	keyDriverModel?: boolean;
	showNeutralExtractions?: boolean;
	setShowNeutralExtractions?: (value: boolean) => void;
}

interface EventsListState {
	sortOptionID: number | string;
	sortMenuState: boolean;
	selectedItemIndex: number;
}

@inject(STORE_FEATURES, STORE_GLOBAL)
@observer
export default class EventsNavigation extends React.Component<EventsListProps, EventsListState> {
	constructor(props) {
		super(props);
		this.state = {
			selectedItemIndex: 0,
			sortOptionID: EVENT_CATEGORY_SORT[0].id,
			sortMenuState: false
		};
	}

	componentDidUpdate(prevProps: EventsListProps) {
		if (this.props.keyDriverModel && !prevProps.keyDriverModel) {
			this.setState({ selectedItemIndex: undefined, sortOptionID: undefined });
		}
	}

	handleCategorySortChange = (sortByID: string) => {
		if (sortByID !== this.state.sortOptionID) {
			this.setState({ sortOptionID: sortByID });
		}
	};

	toggleSortMenuState = () => {
		const currentState = this.state.sortMenuState;
		this.setState({ sortMenuState: !currentState });
	};

	changeSelectedItemIndex = (selectedIndex: number) => {
		this.setState({ selectedItemIndex: selectedIndex });
	};

	setShowNeutralExtractions = (value: boolean) => {
		const { setShowNeutralExtractions } = this.props[STORE_GLOBAL] as GlobalStore;
		setShowNeutralExtractions(value);
	};

	render() {
		const { events, show, loading, showSort, singleTabLayout } = this.props;
		const { sortOptionID } = this.state;

		const { showNeutralExtractions } = this.props[STORE_GLOBAL] as GlobalStore;
		// const { userFeaturePermissions } = this.props[STORE_FEATURES] as FeaturesStore;
		// const ffViewerNewarcMigartion = userFeaturePermissions?.viewer_newarc_migration;

		const neutralExtractionsChecked = this.props.setShowNeutralExtractions
			? this.props.showNeutralExtractions
			: showNeutralExtractions;

		return (
			<Wrapper show={show} pullUp={false}>
				<InnerWrapper>
					{
						<FlexHeader>
							<CheckboxContainer>
								<Checkbox
									id={ids.DOCUMENT_PAGE.NEUTRAL_EXTRACTIONS_CHECKBOX}
									checked={neutralExtractionsChecked}
									onChange={
										this.props.setShowNeutralExtractions
											? this.props.setShowNeutralExtractions
											: this.setShowNeutralExtractions
									}
								>
									Neutral extractions
								</Checkbox>
							</CheckboxContainer>
							{showSort && (
								<NewSortBoxContainer
									onClick={this.toggleSortMenuState}
									id={ids.DOCUMENT_PAGE.SORT_BY_EVENT_DOCUMENTS}
								>
									<SortText id={ids.DOCUMENT_PAGE.SIDE_PANEL_SORT_BY}>Sort By:</SortText>
									<SortImg src={Images.sortIcon} />
								</NewSortBoxContainer>
							)}
							{showSort && this.state.sortMenuState && (
								<DropdownContainer>
									<DropDown
										id={ids.DOCUMENT_PAGE.EVENT_SORT}
										toggleSortMenuState={this.toggleSortMenuState}
										maxHeightBody={300}
										width={190}
										imgMarginTop={8}
										data={EVENT_CATEGORY_SORT}
										dropDownTitle={'Sort by'}
										dropDownImg={Images.sortIcon}
										handleDropDownChange={this.handleCategorySortChange}
										changeSelectedItemIndex={this.changeSelectedItemIndex}
										selectedItemIndex={this.state.selectedItemIndex}
									/>
								</DropdownContainer>
							)}
						</FlexHeader>
					}
					<EventsListContainer hasHeader={!singleTabLayout}>
						{loading ? (
							<EventsNavigationSkeleton
								amount={20}
								marginPercent={4}
								tileHeight={60}
								tileSpacing={20}
								topMarginPixels={20}
							/>
						) : (
							<EventsList
								events={events}
								onEventSelection={(event: Event) => this.props.onEventSelection(event)}
								sortCondition={showSort && sortOptionID}
								showTag
								clickableEvents
								arrowPosRight
								selectedEventId={this.props.selectedEventId}
								elementIdPrefix={this.props.elementIdPrefix}
								navReloadCounter={this.props.navReloadCounter}
								keyDriverModel={this.props.keyDriverModel}
								showNeutralExtractions={neutralExtractionsChecked}
							/>
						)}
					</EventsListContainer>
				</InnerWrapper>
			</Wrapper>
		);
	}
}
