import { Colors } from './../../components/shared/styles';
import styled from 'styled-components';
import { Badge } from '../../components/Badge/Badge';

export const Arrow = styled.div<{ isOpen: boolean; myUrl: string }>`
	position: absolute;
	right: 22px;
	top: 30px;
	width: 12px;
	height: 8px;
	transform: ${props => (props.isOpen ? 'rotate(-180deg)' : '')};
	mask-image: url(${props => props.myUrl});
	mask-size: contain;
	mask-position: center;
	-webkit-mask-repeat: no-repeat;
	background-color: ${props => (props.isOpen ? Colors.blue : Colors.dark)};
	transition: transform 0.5s, background-color 0.5s;
`;

export const TabsContainer = styled.div`
	width: 100%;
	height: 53px;
	box-sizing: border-box;
`;

export const TabsInnerContainer = styled.div`
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: 50px;
	box-sizing: border-box;
`;

export const TabsUnderline = styled.div<{ marginLeft: number }>`
	width: calc(100% - 20px);
	height: 2px;
	background-color: #eeeeee;
	margin-left: ${props => props.marginLeft}px;
`;

export const Tab = styled.div<{
	width?: string;
	disablePointer?: boolean;
	ffViewerDocumentFilters?: boolean;
}>`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: ${props => props.width || '50%'};
	height: 100%;
	color: #4a4a4a;
	font-size: 16px;
	cursor: ${props => !props.disablePointer && 'pointer'};
	font-weight: 600;
`;

export const TabSelectedUnderline = styled.div<{
	leftPos: string;
	rightPos: string;
}>`
	position: absolute;
	bottom: -2px;
	left: ${props => props.leftPos};
	right: ${props => props.rightPos};
	height: 3px;
	background-color: ${Colors.blue};
	transition: left 0.3s, right 0.3s;
`;

interface CircleCountProps {
	backgroundColor: string;
	textColor: string;
	marginLeft?: number;
	border?: string;
}
export const EventsCount = styled.span<CircleCountProps>`
	color: ${props => props.textColor};
	border: ${props => (props.border ? props.border : '')};
	border-radius: 20px;
	background-color: ${props => props.backgroundColor};
	padding: 3px 12px;
	margin-left: ${props => (props.marginLeft ? `${props.marginLeft}px` : '')};
	font-family: Assistant Bold;
	font-size: 15px;
	vertical-align: top;
`;

export const BadgeWithMargin = styled(Badge)`
	margin-left: 5px;
`;

export const EventScoreBadge = styled(Badge)`
	margin-left: 5px;
	vertical-align: top;
`;

export const ListContainer = styled.div`
	width: 100%;
	height: calc(100% - 100px);
	overflow-y: hidden;
	box-sizing: border-box;
	display: inline-block;
`;

export const ScrollContainer = styled.div`
	height: 100%;
	overflow-y: auto;
`;

export const InnerListContainer = styled.div<{
	isOpen: boolean;
	myHeight: number;
}>`
	transition: height 0.5s;
	height: ${props => (props.isOpen && props.myHeight ? props.myHeight : '0')}px;
	overflow: hidden;
`;

export const Spinner = styled.img`
	margin: 60px auto;
	display: block;
`;

interface TextProps {
	color: string;
	fontFamily?: string;
	fontSize?: number;
	marginLeft?: number;
}
export const Text = styled.span<TextProps>`
	color: ${props => props.color};
	font-family: ${props => (props.fontFamily ? props.fontFamily : 'Assistant')};
	font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '')};
	margin-left: ${props => (props.marginLeft ? `${props.marginLeft}px` : '')};
`;

interface OpacityWrapperProps {
	opacity: string;
}
export const OpacityWrapper = styled.div<OpacityWrapperProps>`
	opacity: ${props => props.opacity};
`;
