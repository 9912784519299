import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

import { Colors, FontSizes, FontFamilies } from '../shared/styles';

interface TextAreaProps {
	id?: string;
	onChange?(event: ChangeEvent<HTMLTextAreaElement>): void;
	defaultValue?: string;
	placeholder?: string;
}

const StyledTextArea = styled.textarea<TextAreaProps>`
	height: 120px;
	line-height: 19px;
	width: 100%;
	padding: 10px 15px;
	border: 1px solid ${Colors.gray};
	border-radius: 2px;
	color: ${Colors.dark};
	background-color: ${Colors.white};
	font-family: ${FontFamilies.regular};
	font-size: ${FontSizes.s2};
	resize: none;
	box-sizing: border-box;
	overflow-y: auto;

	&::-webkit-input-placeholder {
		height: 19px;
		line-height: 19px;
		color: ${Colors.gray};
		font-family: ${FontFamilies.regular};
		font-size: ${FontSizes.s2};
	}
`;

export const TextArea: React.FunctionComponent<TextAreaProps> = ({
	id,
	onChange,
	defaultValue,
	placeholder
}) => {
	const [value, setValue] = useState(defaultValue);

	const onChangeInner = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setValue((event?.target as HTMLTextAreaElement)?.value);
		onChange?.(event);
	};

	return (
		<StyledTextArea
			id={id}
			onChange={(event: ChangeEvent<HTMLTextAreaElement>) => onChangeInner(event)}
			value={value}
			placeholder={placeholder}
		/>
	);
};
