import React from 'react';
import styled from 'styled-components';

import Images from '../../shared/images';

import { PORTFOLIO_LABEL } from '../../../id.constants';
import { Colors } from '../../../components/shared/styles';

const Container = styled.div`
	display: flex;
	align-items: center;
	font-family: Assistant;
	font-size: 18px;
`;

const BackBtn = styled.div`
	display: flex;
	align-items: center;
	width: fit-content;
	margin-right: 5px;
	font-family: Assistant SemiBold;
	color: ${Colors.blue};
	cursor: pointer;
	text-decoration: underline;
`;

const ArrowIcon = styled.div`
	height: 7px;
	width: 4px;
	margin-left: 5px;
	background-color: ${Colors.blue};
	-webkit-mask-image: url(${Images.arrowRightGrey});
	-webkit-mask-size: contain;
	-webkit-mask-repeat: no-repeat;
	-webkit-mask-position: center;
`;

interface PortfolioLabelProps {
	name: string;
	handleBack: () => void;
}

const WatchlistLabel: React.FunctionComponent<PortfolioLabelProps> = ({ name, handleBack }) => {
	return (
		<Container>
			<BackBtn id={PORTFOLIO_LABEL.BACK_BTN} onClick={handleBack}>
				Watchlists <ArrowIcon />
			</BackBtn>
			<div data-cy='wlName'>{name}</div>
		</Container>
	);
};

export default WatchlistLabel;
