import React, { useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import {
	Event,
	OutboundEvent,
	Part,
	Section,
	Speaker,
	SelectFunction,
	QNA
} from '../../types/types';
import { SIDE_BAR_TABS } from '../../constants';
import SidebarHeader from './sidebarHeader';
import SectionsList from '../../components/SectionsList/sectionsList';
import EventsNavigation from '../../components/EventsNavigation/eventsNavigation';
import SpeakerEntry from '../Transcript/SidebarElements/speakerEntry';
import QnaEntry from '../Transcript/SidebarElements/qnaEntry';
import SpeakersList from '../../components/ParticipantsList/participantsList';

import * as Util from '../../services/util.service';

const Container = styled.div`
	height: 100%;
`;

const Scrollable = styled.div`
	overflow-y: auto;
	height: calc(100% - 110px);
`;

export const countEventsByKeyDrivers = (events): number => {
	let totalEventsCount = 0;
	events.forEach(event => (totalEventsCount = totalEventsCount + event.keyDrivers?.length));
	// in case the events don't have key drivers because their type is OutboundEvent
	return totalEventsCount || events.length;
};

interface DocumentSideBarProps {
	tabs?: Record<number, string>;
	initialTab?: number;
	events?: Event[] | OutboundEvent[];
	sections?: Part[];
	discussionSection?: Section;
	qnaSection?: Section;
	selectedEventId?: number | string;
	elementIdPrefix?: string;
	showSort?: boolean;
	openCategoryContainingEvent?: boolean;
	loadingEvents?: boolean;
	loadingSections?: boolean;
	navReloadCounter?: number;
	onSectionClick?: Function;
	onEventSelectionList?: Function;
	onParticipantElementClicked?: SelectFunction;
	keyDriverModel?: boolean;
	showNeutralExtractions?: boolean;
	setShowNeutralExtractions?: (value: boolean) => void;
}

const DocumentSideBar: React.FunctionComponent<DocumentSideBarProps> = ({
	tabs,
	initialTab,
	events,
	sections,
	discussionSection,
	qnaSection,
	selectedEventId,
	elementIdPrefix,
	showSort,
	openCategoryContainingEvent,
	loadingEvents,
	loadingSections,
	navReloadCounter,
	onSectionClick,
	onEventSelectionList,
	onParticipantElementClicked,
	keyDriverModel,
	showNeutralExtractions,
	setShowNeutralExtractions
}) => {
	const [currentTab, setCurrentTab] = useState(initialTab ?? SIDE_BAR_TABS.KeyDrivers);

	const isCurrentTab = (tab: SIDE_BAR_TABS) => currentTab === tab;

	const hasTab = (tab: SIDE_BAR_TABS) => tabs[tab];

	const renderSpeakers = (speakers: Speaker[], selectedTab: number) => {
		if (selectedTab !== SIDE_BAR_TABS.ManagementDiscussion) {
			return;
		}
		return (
			<Scrollable>
				{_.map(speakers, (speaker: Speaker) => (
					<SpeakerEntry
						key={`Speaker-${speaker.speakingIndex}`}
						speaker={speaker}
						onSelect={onParticipantElementClicked}
					/>
				))}
			</Scrollable>
		);
	};

	const renderQnA = (qnas: QNA[], selectedTab: number) => {
		if (selectedTab !== SIDE_BAR_TABS.QnA) {
			return;
		}
		return (
			<Scrollable>
				{_.map(qnas, (qna: QNA) => (
					<QnaEntry
						key={`QnA-${qna.question.id}`}
						qna={qna}
						index={qna.index}
						onSelect={onParticipantElementClicked}
					/>
				))}
			</Scrollable>
		);
	};

	return (
		<Container>
			{!_.isEmpty(tabs) && (
				<SidebarHeader
					tabs={tabs}
					selectedTab={currentTab}
					eventsLength={events ? countEventsByKeyDrivers(events) : 0}
					setCurrentTab={setCurrentTab}
				/>
			)}
			{hasTab(SIDE_BAR_TABS.Sections) && (
				<SectionsList
					show={isCurrentTab(SIDE_BAR_TABS.Sections)}
					sections={sections}
					onItemClicked={onSectionClick}
					navReloadCounter={navReloadCounter}
					loading={loadingSections}
				/>
			)}
			{hasTab(SIDE_BAR_TABS.KeyDrivers) && (
				<EventsNavigation
					show={isCurrentTab(SIDE_BAR_TABS.KeyDrivers)}
					events={events as Event[]}
					onEventSelection={onEventSelectionList}
					selectedEventId={selectedEventId}
					openCategoryContainingEvent={openCategoryContainingEvent}
					elementIdPrefix={elementIdPrefix}
					navReloadCounter={navReloadCounter}
					loading={loadingEvents}
					showSort={showSort}
					singleTabLayout={tabs && _.keys(tabs).length === 1}
					keyDriverModel={keyDriverModel}
					showNeutralExtractions={showNeutralExtractions}
					setShowNeutralExtractions={setShowNeutralExtractions}
				/>
			)}
			{hasTab(SIDE_BAR_TABS.Participants) && (
				<SpeakersList
					show={currentTab === SIDE_BAR_TABS.Participants}
					speakers={discussionSection ? discussionSection.speakers : []}
					qnas={qnaSection ? Util.formatQNASpeakers(qnaSection.speakers) : []}
					onSpeakerClicked={onParticipantElementClicked}
					onQNAClicked={onParticipantElementClicked}
				/>
			)}
			{hasTab(SIDE_BAR_TABS.ManagementDiscussion)
				? renderSpeakers(discussionSection ? discussionSection.speakers : [], currentTab)
				: undefined}
			{hasTab(SIDE_BAR_TABS.QnA)
				? renderQnA(qnaSection ? Util.formatQNASpeakers(qnaSection.speakers) : [], currentTab)
				: undefined}
		</Container>
	);
};

export default DocumentSideBar;
