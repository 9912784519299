import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { observe } from 'mobx';
import * as _ from 'lodash';
import Transcript from '../../components/Transcript/transcript';
import { getObject, getBoolean } from '../../services/localstorage.service';
import { LOCAL_STORAGE_KEYS, STORE_GLOBAL, PRINT_LAYOUTS } from '../../constants';
import { getUserLocalTimeFromEST } from '../../helpers.timezone';
import { Event, FormattedText, EventElement, PrintLayout } from '../../types/types';
import { EventsList, Page } from '../../../components/Print/printPageElements';
import { limitArraysByLength } from '../../services/util.service';
import PrintEventElement from '../../../components/Print/printEventElement';
import PrintedPage from '../../../components/Print/printedPage';
import PrintPopup from '../../components/PrintLayoutPopup/printPopup';

const PAGE_BREAK_MARGIN = 20;

interface PrintTranscriptState {
	events: Event[];
	transcript: FormattedText;
	callTime: string;
	transcriptTime: string;
	transcriptTitle: string;
	eventElements: EventElement[];
	fontsLoaded: boolean;
	showPopup: boolean;
	selectedLayout: PrintLayout;
}

@inject(STORE_GLOBAL)
@observer
export default class PrintTranscriptPage extends React.Component<{}, PrintTranscriptState> {
	eventPositionsSub;

	constructor(props) {
		super(props);
		this.state = {
			events: [],
			transcript: undefined,
			callTime: '',
			transcriptTime: '',
			transcriptTitle: '',
			eventElements: [],
			fontsLoaded: false,
			showPopup: false,
			selectedLayout: undefined
		};

		this.eventPositionsSub = observe(this.props[STORE_GLOBAL], 'eventElements', change => {
			// if (!change.newValue || _.isEqual(change.newValue, this.state.eventElements)) { return; }
			this.setState({ eventElements: change.newValue });
		});

		(document as any).fonts.ready.then(() => {
			const showPopup = !getBoolean(LOCAL_STORAGE_KEYS.DONT_SHOW_PRINT_NOTICE);
			const selectedLayout =
				(getObject(LOCAL_STORAGE_KEYS.SELECTED_PRINT_LAYOUT) as any) || PRINT_LAYOUTS[0];
			this.setState({ fontsLoaded: true, showPopup, selectedLayout }, () => {
				if (!showPopup) {
					window.print();
				}
			});
		});
	}

	componentWillUnmount() {
		this.eventPositionsSub();
	}

	componentWillMount() {
		const printData = getObject(LOCAL_STORAGE_KEYS.PRINT_DATA) as PrintTranscriptState;
		this.setState(printData);
	}

	dismissNoticePopupNew = (selectedLayout: PrintLayout) => {
		this.setState({ showPopup: false, selectedLayout }, () => {
			window.print();
		});
	};

	getEventsList = (events: Event[], eventElements: EventElement[]) => {
		const obj = _.pickBy(
			// assign each event to a page
			_.groupBy(
				events,
				(e: Event) => {
					const element = _.find(eventElements, { id: e.id });
					if (element && element.element && element.element.getBoundingClientRect().top) {
						const top = element.element.getBoundingClientRect().top;
						const pageNum = this.state.selectedLayout.pageHeight
							? Math.floor(top / this.state.selectedLayout.pageHeight) + 1
							: 1;
						const pageBreakMarginCoefficient = pageNum === 1 ? pageNum : pageNum / 2;
						const correctedTop = top + pageBreakMarginCoefficient * PAGE_BREAK_MARGIN;
						const x = this.state.selectedLayout.pageHeight
							? Math.floor(correctedTop / this.state.selectedLayout.pageHeight)
							: 0;
						return x;
					}
				}
				// remove events with no page
			),
			(value, key) => +key > -1
		);
		// insert empty pages if any
		_.range(0, _.max(Object.keys(obj).map(k => +k))).forEach((n: number) => {
			if (obj[n] === undefined) {
				obj[n] = [];
			}
		});
		const orderedByPageNumber = _.orderBy(
			Object.keys(obj).map(key => ({ key: +key, value: obj[key] })),
			'key'
		).map(o => o.value);
		return limitArraysByLength(
			orderedByPageNumber,
			this.state.selectedLayout.maxNumOfEventsOnPage
		).map((events: Event[], i: number) => {
			const eventsOnPage = _.reduce(
				events,
				(res, event: Event) => {
					if (!!event.keyDrivers) {
						return [...res, ...this.getKeyDriversDisplay(event)];
					}
					return [
						...res,
						this.getEventDisplay(
							event.indexInTranscript,
							event.polarity,
							event.categoryName,
							event.typeName
						)
					];
				},
				[]
			);
			return (
				<Page key={i} pageHeight={this.state.selectedLayout.pageHeight}>
					{eventsOnPage}
				</Page>
			);
		});
	};

	getEventDisplay = (indexInTranscript, polarity, category, typeName) => (
		<PrintEventElement
			key={indexInTranscript}
			hash={indexInTranscript + 1}
			polarity={polarity}
			categoryName={category}
			typeName={typeName || 'Annotation'}
		/>
	);

	getKeyDriversDisplay = (event: Event) =>
		_.map(event.keyDrivers, keyDriver =>
			this.getEventDisplay(event.indexInTranscript, event.polarity, keyDriver, event.typeName)
		);

	render() {
		const {
			transcript,
			events,
			callTime,
			transcriptTime,
			transcriptTitle,
			eventElements,
			fontsLoaded,
			showPopup
		} = this.state;

		const printDetails = [
			{ name: 'Event Time', value: getUserLocalTimeFromEST(callTime) },
			{ name: 'Transcript Analysis Time', value: transcriptTime },
			{ name: 'Print Time', value: new Date().toLocaleString('en-US', { hour12: true }) }
		];

		return (
			<PrintedPage
				title={transcriptTitle}
				details={printDetails}
				text={<Transcript text={transcript} delimiterOffset={20} events={events} printMode />}
				events={<EventsList>{fontsLoaded && this.getEventsList(events, eventElements)}</EventsList>}
				popup={showPopup && <PrintPopup dismiss={this.dismissNoticePopupNew} />}
			/>
		);
	}
}
