import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FontSizes, Colors, FontFamilies } from '../shared/styles';

interface StyledInputProps {
	error?: boolean;
	disabled?: boolean;
}
export const StyledInput = styled.input<StyledInputProps>`
	height: 54px;
	width: 100%;
	padding-left: 14px;
	color: ${Colors.dark};
	font-size: ${FontSizes.s3};
	font-family: ${FontFamilies.regular};
	border: 1px solid;
	border-radius: 4px;
	border-color: ${({ error }) => (error ? Colors.negative : Colors.gray)}
	background-color: ${Colors.white};
	box-sizing: border-box;
	opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
	::placeholder {
		color: ${Colors.darkGray};
		font-size: ${FontSizes.s2};
	}
`;

export enum InputType {
	text = 'text',
	number = 'number',
	password = 'password'
}

export interface InputProps {
	id?: string;
	value?: string;
	onChange?: (event: any) => void;
	placeholder?: string;
	type?: InputType;
	autoComplete?: boolean;
	error?: boolean;
	disabled?: boolean;
	elementRef?: any;
}

export const Input: FunctionComponent<InputProps> = ({
	id,
	value,
	onChange,
	placeholder,
	type = InputType.text,
	autoComplete = true,
	disabled = false,
	error = false,
	elementRef
}) => (
	<StyledInput
		id={id}
		type={type}
		value={value}
		placeholder={placeholder}
		onChange={onChange}
		autoComplete={autoComplete ? 'on' : 'off'}
		disabled={disabled}
		error={error}
		ref={elementRef}
	/>
);
