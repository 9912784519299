import styled from 'styled-components';

import Images from '../../shared/images';
import { Colors } from '../../../components/shared/styles';

export const Empty = styled.div<{ padding?: string; new?: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	padding: ${props => props.padding};
	box-sizing: border-box;
	border: 1px solid #e0e7e9;
	border-radius: 6px;
	box-shadow: 0 1px 4px 0 ${props => (props.new ? Colors.darkGray : 'rgba(50, 49, 59, 0.32)')};
`;

export const EmptyIcon = styled.div`
	width: 77px;
	height: 77px;
	background: url(${Images.addCompanyIcon});
	margin-bottom: 20px;
`;

export const EmptyTitle = styled.div`
	font-family: Assistant;
	font-size: 24px;
	line-height: 0.92;
	text-align: center;
	color: rgba(74, 74, 74, 0.7);
	margin-bottom: 10px;
`;

export const EmptySubTitle = styled.div`
	font-family: Assistant;
	font-size: 16px;
	line-height: 1.5;
	text-align: center;
	letter-spacing: 0.4px;
	color: rgba(74, 74, 74);
`;

export const Blue = styled.span`
	font-family: Assistant
	color: ${Colors.blue};
	text-decoration: underline;
	cursor: pointer;
`;
