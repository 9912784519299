import * as _ from 'lodash';
import moment from 'moment';
import { Colors } from '../../../../components/shared/styles';
import * as Types from '../../../types/types';
import {
	DECEPTION_KEY_DRIVER,
	EXCLUDED_KEY_DRIVERS,
	MAX_NUM_OF_DOCUMENTS
} from '../../../constants';

export const orderDocumentsByDate = (
	documents: Types.Document[],
	direction?: 'asc' | 'desc'
): Types.Document[] =>
	_.orderBy(
		documents,
		(t: Types.Document) => (t.eventDate ? t.eventDate : t.publicationDate),
		direction || 'asc'
	);

export const getDataForGraph = (company: Types.PortfolioCompany): { y: number; label: string }[] =>
	_.map(orderDocumentsByDate(company.documents), t => ({
		y: t.total_daily_sentiment_score * 100,
		label: new Date(t.eventDate ? t.eventDate : t.publicationDate).toLocaleDateString()
	}));

export const getDataForAmenityScoreGraph = (
	documents: Types.Document[]
): { y: number; x: number }[] =>
	_.map(orderDocumentsByDate(documents), t => ({
		y: t.total_daily_sentiment_score * 100,
		x: moment(t.eventDate ? t.eventDate : t.publicationDate).valueOf()
	}));

export const getDataForDeceptionGraph = (
	company: Types.PortfolioCompany
): { y: number; label: string }[] =>
	_.map(orderDocumentsByDate(company.documents), t => ({
		y: t.deception_score,
		label: new Date(t.eventDate ? t.eventDate : t.publicationDate).toLocaleDateString()
	}));

export const getDataForKeyDriverCharts = (documents: Types.Document[]): Types.KeyDriverChart[] => {
	let ordered = orderDocumentsByDate(documents);
	ordered = ordered && ordered.slice(MAX_NUM_OF_DOCUMENTS);

	if (ordered.length < 2) {
		return [];
	}
	const latest = ordered[ordered.length - 1];
	const relevantKeyDrivers = _.reject(latest.driver_totals, (dt: Types.DriverTotals) =>
		_.includes(EXCLUDED_KEY_DRIVERS, dt.category_name)
	);
	const topKeyDrivers = _.sortBy(
		relevantKeyDrivers,
		(dt: Types.DriverTotals) => dt.category_name !== DECEPTION_KEY_DRIVER,
		(dt: Types.DriverTotals) => -((dt.total_neg || 0) + (dt.total_pos || 0))
	).slice(0, 5);
	let max = 0;
	const charts = _.map(topKeyDrivers, (dt: Types.DriverTotals) => {
		const chart: Types.KeyDriverChart = {
			title: { text: dt.category_name },
			series: [
				{ name: 'Positive', data: [], color: Colors.positive },
				{ name: 'Negative', data: [], color: Colors.negative }
			],
			xAxis: {
				categories: []
			},
			yAxis: {
				max: 0
			}
		};
		_.each(ordered, (doc: Types.Document) => {
			const driver = _.find(
				doc.driver_totals,
				(docDt: Types.DriverTotals) => docDt.category_name === dt.category_name
			);
			const total_pos = driver ? driver.total_pos : 0;
			const total_neg = driver ? driver.total_neg : 0;
			chart.series[0].data.push(total_pos);
			chart.series[1].data.push(total_neg);
			chart.xAxis.categories.push({
				documentId: doc.documentId,
				fiscalQuarter: doc.fiscalQuarter,
				fiscalYear: doc.fiscalYear,
				date: new Date(doc.eventDate ? doc.eventDate : doc.publicationDate).toLocaleDateString(
					'en-US'
				)
			});
			max = Math.max(max, total_pos, total_neg);
		});
		return chart;
	});
	_.each(charts, (chart: Types.KeyDriverChart) => {
		chart.yAxis.max = max;
	});
	return charts;
};
