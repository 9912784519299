import * as React from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';
import Images from '../../shared/images';

const DropDownRelativeContainer = styled.div`
	position: relative;
	height: 31px;
	width: 184px;
`;

const DropDownHeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 0 11px;
	cursor: pointer;
	box-sizing: border-box;
	border-radius: 1px;
	border: 1px solid #e1e8ea;
	background-color: #fff;
`;

const HeaderText = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	width: calc(100% - 35px);
	box-sizing: border-box;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-family: Assistant;
	font-size: 14px;
	color: #333;
`;

const Arrow = styled.div`
	height: 5px;
	width: 10px;
	mask-image: url(${Images.arrowDownDropdown});
	background-color: #32313b;
	mask-position: center;
	-webkit-mask-repeat: no-repeat;
`;

const DropDownBodyContainer = styled.div`
	max-height: 140px;
	width: 100%;
	position: absolute;
	left: -1px;
	z-index: 1;
	border: 1px solid #eee;
	border-radius: 3px;
	background-color: #fff;
	overflow-y: auto;
	box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
`;

const DropDownMainMenu = styled.div`
	border-right: 1px solid #eee;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
`;

interface MenuItemProps {
	active: boolean;
}
const MenuItem = styled.div<MenuItemProps>`
	cursor: pointer;
	white-space: nowrap;
	font-size: 18px;
	height: 26px;
	background-color: ${props => (props.active ? '#DBDBDB' : '#FFF')};

	&:hover {
		background-color: #dbdbdb;
	}
`;

const MainMenuItemTitle = styled.div`
	height: 19px;
	font-weight: 100;
	line-height: 19px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 3px 0 3px 11px;
	font-size: 14px;
	color: rgba(50, 49, 59, 0.87);
`;

interface Item {
	id: number;
	title: string;
}

interface YearsDropDownProps {
	data: Item[];
	handleDropDownChange: Function;
	currentYear: number;
}

interface YearsDropDownState {
	isToShowDropDown: boolean;
}

export default class YearsDropDown extends React.Component<YearsDropDownProps, YearsDropDownState> {
	dropDownButton = undefined;

	constructor(props: YearsDropDownProps) {
		super(props);

		this.state = {
			isToShowDropDown: false
		};
	}

	toggleDropDown = () => {
		if (this.state.isToShowDropDown) {
			this.closeDropDown();
			return;
		}
		this.openDropDown();
	};

	openDropDown = () => {
		this.setState({ isToShowDropDown: true });
		document.addEventListener('mousedown', this.handleDropDownClick, true);
	};

	closeDropDown = () => {
		document.removeEventListener('mousedown', this.handleDropDownClick, true);
		this.setState({ isToShowDropDown: false });
	};

	onClickDropDown = () => {
		this.toggleDropDown();
	};

	handleDropDownClick = e => {
		if (this.dropDownButton?.contains(e.target)) {
			return;
		}
		this.closeDropDown();
	};

	handleDropDownChange = (index: number) => {
		if (index !== this.getIndex()) {
			this.props.handleDropDownChange(this.props.data[index].id);
		}
		this.setState({ isToShowDropDown: false });
	};

	mapMenuItems = (data: Item[], selectedIndex: number) => {
		return _.map(data, (menuItem: Item, index: number) => (
			<MenuItem
				active={index === selectedIndex}
				key={`YEARS_DROP_DOWN_ITEM_${index}`}
				onClick={() => this.handleDropDownChange(index)}
			>
				<MainMenuItemTitle title={menuItem.title}>{menuItem.title}</MainMenuItemTitle>
			</MenuItem>
		));
	};

	getIndex() {
		const { data, currentYear } = this.props;
		return _.findIndex(data, { id: currentYear });
	}

	render() {
		const { data } = this.props;
		const index = this.getIndex();
		const { isToShowDropDown } = this.state;

		return (
			<DropDownRelativeContainer ref={node => (this.dropDownButton = node)}>
				<DropDownHeaderContainer onClick={this.onClickDropDown}>
					<HeaderText>{data[index]?.title}</HeaderText>
					<Arrow />
				</DropDownHeaderContainer>
				{isToShowDropDown && (
					<DropDownBodyContainer>
						<DropDownMainMenu>{this.mapMenuItems(data, index)}</DropDownMainMenu>
					</DropDownBodyContainer>
				)}
			</DropDownRelativeContainer>
		);
	}
}
