import moment from 'moment';
import jstz from 'jstimezonedetect';
import { DATETIME_FORMAT } from './constants';

export const getUserLocalTimeFromEST = date => {
	const estTime = new Date(date);
	estTime.setUTCMinutes(estTime.getUTCMinutes() + 300);

	const tz = jstz.determine();
	const userTimeZone = tz.name();

	const offset = moment(estTime).utcOffset();
	const localText = moment(estTime)
		.add(offset / 60, 'h')
		.format(`${DATETIME_FORMAT} [${userTimeZone}]`);

	return localText;
};

export const getUserLocalTimeFromUTC = time => {
	const offset = moment(time).utcOffset();
	const tz = jstz.determine();
	const userTimeZone = tz.name();

	return moment(time)
		.add(offset / 60, 'h')
		.format(`${DATETIME_FORMAT} [${userTimeZone}]`);
};
