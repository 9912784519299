import React from 'react';
import { Provider } from 'mobx-react';
import { RecoilRoot } from 'recoil';
import store from '../../../stores/store';

const ProviderWrapper = ({ children }) => (
	<Provider {...store}>
		<RecoilRoot>{children}</RecoilRoot>
	</Provider>
);

export default ProviderWrapper;
