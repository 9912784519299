import React from 'react';
import styled from 'styled-components';

import { Company } from '../../types/types';
import Images from '../../shared/images';
import { ADD_COMPANY_BANNER } from '../../../id.constants.js';

const Container = styled.div<{ show: boolean }>`
	display: ${props => (props.show ? 'flex' : 'none')};
	position: fixed;
	align-items: center;
	top: 21px;
	left: 50%;
	transform: translate(-50%, 0);
	height: 63px;
	width: 493px;
	padding: 12px 0 12px 23px;
	box-sizing: border-box;
	border: 1px solid #dadada;
	background-color: #fff;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
	font-size: 14px;
	font-family: Assistant SemiBold;
`;

const CloseIcon = styled.img.attrs({
	src: Images.closeBtnBlack
})`
	position: absolute;
	padding: 5px
	right: 6px;
	top: 6px;
	height: 8px;
	width: 8px;
	cursor: pointer;
`;

const TextContainer = styled.div`
	width: 275px;
`;

const AddBtn = styled.div`
	margin-left: 50px;
	color: #02adef;
	text-decoration: underline;
	cursor: pointer;
`;

interface AddCompanyBannerProps {
	company: Company;
	addAction: () => void;
	close: () => void;
	show: boolean;
}

const AddCompanyBanner: React.FunctionComponent<AddCompanyBannerProps> = ({
	company,
	addAction,
	close,
	show
}) => {
	const companyName = company && company.name;
	const companyTicker = company && company.ticker;
	return (
		<Container show={show} id={ADD_COMPANY_BANNER.CONTAINER}>
			<TextContainer>{`${companyName} (${companyTicker}) not part of your current watchlists`}</TextContainer>
			<AddBtn id={ADD_COMPANY_BANNER.ADD_BTN} onClick={addAction}>
				Add to watchlist
			</AddBtn>
			<CloseIcon id={ADD_COMPANY_BANNER.CLOSE_BTN} onClick={close} />
		</Container>
	);
};

export default AddCompanyBanner;
