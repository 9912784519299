import auth0 from 'auth0-js';
import {
	loginPromise,
	logoutPromise,
	userInfoPromise,
	googleLogin,
	signupPromise,
	resetPasswordPromise,
	universalLogin
} from './auth0.Promises';
import { Credentials } from '../types/types';

export default class AuthService {
	extractData = [
		'first_name',
		'last_name',
		'roles',
		'sub',
		'firstLogin',
		'role',
		'email_verified',
		'createdAt'
	];

	readonly webAuth = new auth0.WebAuth({
		domain: 'viewers-amenity-analytics.auth0.com',
		clientID: 'ixKqorWADVr41EWZ2WGoWhUzNCLDAZXt',
		audience: 'https://viewers-amenity-analytics.auth0.com/userinfo',
		responseType: 'token id_token',
		scope: 'openid email profile'
	});

	externalSignUp = async (user: Credentials) => {
		try {
			const profile = await signupPromise({
				email: user.username,
				password: user.password,
				first_name: user.firstname,
				last_name: user.lastname,
				webAuth: this.webAuth
			});
			return profile;
		} catch (e) {
			const text = e && e.description ? e.description.split('Error:') : e;
			throw new Error(text.length > 1 ? text[1] : text);
		}
	};

	externalLogin = async (credentials: Credentials) => {
		try {
			const { idToken, accessToken } = await loginPromise({
				username: credentials.username,
				password: credentials.password,
				webAuth: this.webAuth
			});

			const profile = await userInfoPromise({
				webAuth: this.webAuth,
				accessToken
			});
			const response = {
				idToken,
				accessToken
			};
			Object.keys(profile).forEach(key => {
				this.extractData.forEach(field => {
					if (key.indexOf(field) !== -1) {
						response[field] = profile[key];
					}
				});
			});
			return response;
		} catch (e) {
			const text = e && e.description ? e.description.split('Error:') : e;
			throw new Error(text.length > 1 ? text[1] : text);
		}
	};

	externalLoginWithGoogle = async () => {
		try {
			const { idToken, accessToken } = await googleLogin({
				webAuth: this.webAuth
			});

			// this.webAuth.authorize({
			//   connection: 'google-oauth2',
			//   redirect_uri: 'http://localhost:1234',
			//   accessToken,
			// }, (err, response) => {
			//   console.log('response ? ', response);
			// });
			const response = {
				idToken,
				accessToken
			};
			return response;
		} catch (e) {
			const text = e && e.description ? e.description.split('Error:') : e;
			throw new Error(text.length > 1 ? text[1] : text);
		}
	};

	externalLoginUniversal = async () => {
		try {
			await universalLogin({ webAuth: this.webAuth });
		} catch (e) {
			const text = e && e.description ? e.description.split('Error:') : e;
			throw new Error(text.length > 1 ? text[1] : text);
		}
	};

	externalLogout = () => {
		logoutPromise({ webAuth: this.webAuth });
	};

	resetPassword = async email => {
		const webAuth = this.webAuth;
		const response = await resetPasswordPromise({ webAuth, email });
		return response;
	};
}
