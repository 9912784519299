import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { SERVER_REMOTE_URL } from '../config';
import * as _ from 'lodash';
import { LOCAL_STORAGE_KEYS } from '../constants';
import { getString } from './localstorage.service';

export default class AjaxService {
	static authStore: any;
	private static instance: AjaxService;

	static getInstance() {
		return AjaxService.instance || new AjaxService(this.authStore);
	}

	static getServerURL = () => {
		return SERVER_REMOTE_URL();
	};

	static formatParams = (p: { [key: string]: any }): { [key: string]: any } => {
		const ret = {};
		_.forIn(p, (v: any, k: string) => {
			if (v instanceof Array) {
				ret[k] = _.reduce(v, (str: string, e: any) => (str === '' ? `${e}` : `${str},${e}`), '');
			} else {
				ret[k] = v;
			}
		});
		return ret;
	};

	constructor(authStore: any) {
		if (AjaxService.instance) {
			return AjaxService.instance;
		}
		AjaxService.instance = this;
		AjaxService.authStore = authStore;
	}

	private createHeadersToken = token => ({
		headers: { Authorization: `Bearer ${token}` }
	});

	private getOptions = methodOptions =>
		this.createHeadersToken(getString(LOCAL_STORAGE_KEYS.TOKEN));

	get = (
		partialUrl: string,
		params: { [key: string]: any } = {},
		onSuccess: (res: AxiosResponse) => any,
		onError?: (err: any) => any
	) => {
		const completeUrl = AjaxService.getServerURL() + partialUrl;

		if (onSuccess === undefined || onSuccess === undefined) {
			throw new Error('Null onSuccess in get()');
		}

		const formattedParams = AjaxService.formatParams(params);
		const options = this.getOptions(undefined);
		options['params'] = formattedParams;

		axios
			.get(completeUrl, options)
			.then((res: AxiosResponse) => onSuccess(res))
			.catch((err: AxiosError) => {
				if (err && err.response && err.response.status === 401) {
					// Unauthorized. Perhaps token expired
					if (AjaxService.authStore.isAuthenticated) {
						AjaxService.authStore.logout();
					}
				}
				console.error(`Error occured in GET ${completeUrl}: ${JSON.stringify(err.response)}`);
				if (onError) {
					onError(err.response);
				}
			});
	};

	post(
		partialUrl: string,
		params: { [key: string]: any },
		onSuccess: (res: AxiosResponse) => any,
		onError?: (err: any) => any
	) {
		const completeUrl = AjaxService.getServerURL() + partialUrl;

		if (onSuccess === undefined || onSuccess === undefined) {
			throw new Error('Null onSuccess in get()');
		}

		let headers, options: AxiosRequestConfig;

		try {
			headers = new Headers({ 'Content-Type': 'application/json' });
			options = this.getOptions(undefined);
			options.headers = { ...options.headers, headers };
		} catch (err) {
			console.error(`Error occured in POST ${completeUrl}: ${JSON.stringify(err.response)}`);
		}

		axios
			.post(completeUrl, params, options || {})
			.then((res: AxiosResponse) => onSuccess(res))
			.catch((err: AxiosError) => {
				if (onError) {
					onError(err);
				}
			});
	}
}
