import * as React from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';

import Images from '../../shared/images';
import { isFileAllowed } from './uploadFile.util';
import UploadSpinner from './uploadSpinner';
import UploadErrorMessage from './uploadErrorMessage';
import UploadSuccessMessage from './uploadSuccessMessage';
import DataService from '../../services/data.service';
import UserTracking from '../../services/userTracking.service';
import { USER_TRACKING as UT } from '../../constants';
import * as ids from '../../../id.constants.js';
import { error } from '../../services/logger.service';
import { Link, Underline } from '../../../components/Link/Link';
import { FontSizes } from '../../../components/shared/styles';
import { STORE_FEATURES } from '../../constants';
import { inject } from 'mobx-react';

const UTC = UT.USER_TRACKING_CATEGORIES;
const UTA = UT.USER_TRACKING_ACTIONS;

export const UPLOAD_ERROR_TYPES = {
	FILE_TYPE: {
		title: `Sorry, we can only import XLS / XLSX files`,
		description: 'Please save your file as Excel file, and '
	},
	CANCEL: {
		title: 'Upload was cancelled',
		description: 'Feel free to '
	},
	MULTIPLE_FILES: {
		title: `Sorry, multiple file upload isn't allowed`,
		description: 'Feel free to '
	}
};

interface DragAndDropContainerProps {
	canUpload: boolean;
}
const DragAndDropContainer = styled.div<DragAndDropContainerProps>`
	height: 100%;
	padding-top: 15px;
	box-sizing: border-box;
	border-radius: ${props => (props.canUpload ? '0' : '4px')};
	border: ${props => (props.canUpload ? 'dashed 2px #dde5e7' : 'solid 1px #dde5e7')};
	background-color: #ffffff;
`;

const StyledLink = styled(Link)`
	float: right;
	margin-right: 20px;
`;

const HelpContent = styled.div`
	display: inline-flex;
`;

const HelpIcon = styled.div`
	height: 20px;
	width: 20px;
	margin-right: 6px;
	background-image: url("${Images.helpBlueIcon}");
`;

const ContentContainer = styled.div`
	height: calc(100% - 25px);
	box-sizing: border-box;
`;

const UploadIcon = styled.div`
	height: 80px;
	width: 100px;
	margin: auto;
	background-image: url("${Images.uploadIconGrey}");
	background-repeat: no-repeat;
	background-position: center;
`;

interface DragTextProps {
	fontSize: number;
	marginTop: number;
}

const DragText = styled.div<DragTextProps>`
	margin-top: ${props => props.marginTop}px;
	text-align: center;
	color: #4a4a4a;
	font-weight: bold;
	font-family: Assistant;
	font-size: ${props => props.fontSize}px;
`;

const FileInputLink = styled(Link)`
	font-size: ${FontSizes.s3};
`;

const InputFile = styled.input`
	display: none;
`;

const Content = styled.div`
	height: 100%;
	padding-top: 50px;
	box-sizing: border-box;
`;

interface UploadFileProps {
	reload: Function;
	close: Function;
	closeEvent: boolean;
	toggleFinish: Function;
	portfolioName?: string;
	portfolioId?: number | string;
	isExcelImport: boolean;
	createWatchlist: Function;
}

interface UploadFileState {
	canUpload: boolean;
	uploadInProgress: boolean;
	uploadAnswer: {
		success: boolean;
		data: any;
	};
}

@inject(STORE_FEATURES)
export default class UploadFile extends React.Component<UploadFileProps, UploadFileState> {
	fileInputRef: any;
	constructor(props: any) {
		super(props);
		this.fileInputRef = React.createRef();
		this.state = {
			canUpload: true,
			uploadInProgress: false,
			uploadAnswer: undefined
		};
	}

	componentWillReceiveProps(nextProps: UploadFileProps) {
		if (nextProps.closeEvent) {
			this.cancelUpload();
		}
	}

	handleFilesFromBtn = (ev: any) => {
		this.handleFile(ev.target.files[0], true);
		this.fileInputRef.current.value = '';
	};

	allowDrop = (ev: any) => {
		if (this.state.canUpload) {
			ev.preventDefault();
		}
	};

	handleFilesFromDrop = (ev: any) => {
		ev.preventDefault();
		if (ev.dataTransfer.items.length > 1) {
			const uploadAnswer = {
				success: false,
				data: UPLOAD_ERROR_TYPES.MULTIPLE_FILES
			};
			this.setState({ uploadAnswer, canUpload: false });
		} else if (ev.dataTransfer.items) {
			this.handleFile(ev.dataTransfer.items[0]);
		}
		this.removeDragData(ev);
	};

	removeDragData = (ev: any) => {
		if (ev.dataTransfer.items) {
			ev.dataTransfer.items.clear();
		} else {
			ev.dataTransfer.clearData();
		}
	};

	handleFile = (file: any, fromBtn: boolean = false) => {
		if (!file) {
			return;
		}
		if (fromBtn || file.kind === 'file') {
			const tmpFile = fromBtn ? file : file.getAsFile();
			isFileAllowed(tmpFile).then(isAllowed => {
				if (isAllowed) {
					this.uploadFile(tmpFile);
				} else {
					const uploadAnswer = {
						success: false,
						data: UPLOAD_ERROR_TYPES.FILE_TYPE
					};
					this.setState({ uploadAnswer, canUpload: false });
				}
			});
		}
	};

	uploadFile = (file: File) => {
		this.setState({ uploadInProgress: true, canUpload: false });
		const importData = {
			filePromise: file.arrayBuffer(),
			id: _.toString(this.props.portfolioId),
			name: this.props.portfolioName,
			isExcelImport: this.props.isExcelImport
		};
		this.props.createWatchlist(importData, this.onUploadSuccess, this.onUploadError);
	};

	onUploadSuccess = res => {
		const { answerData, portfolioId } = res;
		if (this.state.uploadInProgress && !this.props.closeEvent) {
			const uploadAnswer = {
				success: true,
				data: answerData
			};
			this.props.toggleFinish(true, uploadAnswer, portfolioId);
			this.setState({ uploadAnswer, uploadInProgress: false });

			const utMessage = `${answerData.succeeded} succeeded ${answerData.failed} failed`;
			const ut = new UserTracking();
			ut.setEvent(UTC.TICKER_UPLOAD, UTA.WATCHLIST_FILE_UPLOADED, utMessage);
		}
	};

	onUploadError = err => {
		error({
			message: `Failed to upload file: ${JSON.stringify(err)}`,
			file: 'uploadFile',
			functionName: 'uploadFile'
		});
		if (this.state.uploadInProgress) {
			const uploadAnswer = {
				success: false,
				data: err.response.data
			};
			const ut = new UserTracking();
			ut.setEvent(UTC.TICKER_UPLOAD, UTA.UPLOAD_ERROR, uploadAnswer.data.title);
			this.setState({ uploadAnswer, uploadInProgress: false });
		}
	};

	resetUpload = () => {
		this.props.toggleFinish(false);
		this.setState({
			uploadInProgress: false,
			canUpload: true,
			uploadAnswer: undefined
		});
	};

	cancelUpload = () => {
		const ds = DataService.getInstance();

		ds.cancelUploadBloombergFile(
			{},
			() => {
				if (!this.props.closeEvent) {
					const uploadAnswer = {
						success: false,
						data: UPLOAD_ERROR_TYPES.CANCEL
					};
					this.setState({ uploadAnswer, uploadInProgress: false });
				}
			},
			err => {
				error({
					message: `Failed to cancel file upload: ${JSON.stringify(err)}`,
					file: 'cancelUpload',
					functionName: 'cancelUpload'
				});
				this.setState({ uploadInProgress: false, canUpload: true });
			}
		);
	};

	closeAfterSuccess = () => {
		this.props.close();
		const ut = new UserTracking();
		ut.setEvent(UTC.TICKER_UPLOAD, UTA.GO_TO_PORTFOLIO_AFTER_IMPORT);
	};

	renderDragAndDrop = () => (
		<Content>
			<UploadIcon />
			<DragText fontSize={24} marginTop={25}>
				Drop XLS/XLSX file here
			</DragText>
			<DragText fontSize={18} marginTop={15}>
				or &nbsp;
				<FileInputLink
					underline={Underline.hover}
					onClick={() => {
						this.fileInputRef.current.click();
					}}
					id={ids.BLOOMBERG_MODAL.UPLOAD_FILE_BTN_BLOOMBERG_MODEL}
				>
					Upload a file
				</FileInputLink>
				&nbsp; from your desktop
			</DragText>
			<DragText fontSize={15} marginTop={20}>
				The file should contain up to 400 tickers
			</DragText>
		</Content>
	);

	renderUploadSpinner = () => <UploadSpinner cancelUpload={this.cancelUpload} />;

	renderErrorMessage = (message: any) => (
		<UploadErrorMessage tryAgain={this.resetUpload} errorMessage={message} />
	);

	renderSuccessMessage = (message: any) => {
		return (
			<UploadSuccessMessage
				reload={this.props.reload}
				tryAgain={undefined}
				close={this.closeAfterSuccess}
				successMessage={message}
				downloadReport={undefined}
			/>
		);
	};

	renderContent = () => {
		const { uploadInProgress, uploadAnswer } = this.state;
		if (uploadInProgress) {
			return this.renderUploadSpinner();
		} else if (uploadAnswer) {
			return uploadAnswer.success
				? this.renderSuccessMessage(uploadAnswer.data)
				: this.renderErrorMessage(uploadAnswer.data);
		}
		return this.renderDragAndDrop();
	};

	render() {
		const { canUpload } = this.state;
		return (
			<DragAndDropContainer
				canUpload={canUpload}
				onDrop={this.handleFilesFromDrop}
				onDragOver={this.allowDrop}
			>
				<StyledLink href='https://help.amenityanalytics.com/en/' underline={Underline.hover}>
					<HelpContent>
						<HelpIcon />
						Need help?
					</HelpContent>
				</StyledLink>
				<ContentContainer>{this.renderContent()}</ContentContainer>
				<InputFile
					data-cy='file-input'
					type='file'
					ref={this.fileInputRef}
					onInput={this.handleFilesFromBtn}
				/>
			</DragAndDropContainer>
		);
	}
}
