import * as React from 'react';
import * as _ from 'lodash';
import ReactHighcharts from 'react-highcharts';

import './microchart.css';

interface HighchartProps {
	data: { y: number; label: string }[];
}

const getConfig = (data: any) => ({
	chart: {
		height: 72,
		margin: [30, 30, 0, 0],
		spacing: [0, 0, 0, 0],
		backgroundColor: 'transparent'
	},
	series: [
		{
			data,
			type: 'area',
			color: '#2A4D7E',
			fillOpacity: 0.15,
			states: {
				hover: {
					enabled: false
				}
			},
			marker: {
				enabled: false,
				states: {
					hover: {
						enabled: false
					}
				}
			},
			animation: false
		}
	],
	title: false,
	legend: false,
	tooltip: {
		enabled: false
	},
	xAxis: {
		visible: false
	},
	yAxis: {
		visible: false
	}
});

export default class Microchart extends React.Component<HighchartProps, {}> {
	shouldComponentUpdate(nextProps) {
		return !_.isEqual(nextProps, this.props);
	}

	render() {
		const min = _.minBy(this.props.data, 'y');
		const data = _.map(this.props.data, p => [p.label, p.y - (min ? min.y - 3 : 0)]);

		return <ReactHighcharts config={getConfig(data)} />;
	}
}
