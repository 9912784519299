import { RouterStore } from 'mobx-react-router';
import GlobalStore from './global.store';
import AuthStore from './auth.store';
import DataStore from './data.store';
import ConfigStore from './config.store';
import TranscriptLogStore from './transcriptLog.store';
import {
	STORE_ROUTER,
	STORE_GLOBAL,
	STORE_AUTH,
	STORE_DATA,
	STORE_BACK_OFFICE,
	STORE_TRANSCRIPT_LOG,
	STORE_ANALYTICS,
	STORE_FEATURES,
	STORE_CONFIG,
	STORE_UI
} from '../constants';
import BackOfficeStore from './backOffice.store';
import AnalyticsStore from './analytics.store';
import FeaturesStore from './features.store';
import UIStore from './ui.store';

export interface Store {
	[STORE_ROUTER]: RouterStore;
	[STORE_GLOBAL]: GlobalStore;
	[STORE_AUTH]: AuthStore;
	[STORE_DATA]: DataStore;
	[STORE_BACK_OFFICE]: BackOfficeStore;
	[STORE_TRANSCRIPT_LOG]: TranscriptLogStore;
	[STORE_ANALYTICS]: AnalyticsStore;
	[STORE_FEATURES]: FeaturesStore;
	[STORE_CONFIG]: ConfigStore;
	[STORE_UI]: UIStore;
}

const store: Store = {
	[STORE_ROUTER]: new RouterStore(),
	[STORE_GLOBAL]: new GlobalStore(),
	[STORE_AUTH]: new AuthStore(),
	[STORE_DATA]: new DataStore(),
	[STORE_BACK_OFFICE]: new BackOfficeStore(),
	[STORE_TRANSCRIPT_LOG]: new TranscriptLogStore(),
	[STORE_ANALYTICS]: new AnalyticsStore(),
	[STORE_FEATURES]: new FeaturesStore(),
	[STORE_UI]: new UIStore(),
	[STORE_CONFIG]: new ConfigStore()
};

export default store;
