import * as React from 'react';
import * as _ from 'lodash';
import { inject, observer } from 'mobx-react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import BackOfficeStore from '../../stores/backOffice.store';
import Images from '../../shared/images';
import styled from 'styled-components';
import { STORE_BACK_OFFICE } from '../../constants';
import { Category } from '../../types/types';
import { withStyles } from '@material-ui/core/styles';
import AddOrUpdateCategoryPopup from '../../components/BackofficePopups/addOrUpdateCategoryPopUp';
import DeletePopup from '../../components/BackofficePopups/backOfficeDeletePopUp';

const TableCell = styled.td`
	overflow: hidden;
	max-width: 305px;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: rgba(0, 0, 0, 0.87);
	font-size: 0.8125rem;
	display: table-cell;
	padding: 4px 56px 4px 24px;
	text-align: left;
	border-bottom: 1px solid rgba(224, 224, 224);
	vertical-align: inherit;
`;

const WrapTable = styled.div`
	margin: 20px;
`;

const ButtonWrap = styled.div`
	display: inline;
	float: right;
`;

const PageHeader = styled.div`
	margin: 20px;
`;

const PageTitle = styled.div`
	font-size: 16px;
	padding-top: 30px;
	padding-left: 22px;
`;

const Input = styled.input`
	height: 25px;
	width: 170px;
	padding-left: 10px;
`;

const Icons = styled.img`
	cursor: pointer;
`;

const styles = {
	paper: {
		maxHeight: '680px',
		overflow: 'auto'
	}
};

interface CategoriesTabProps {
	classes: any;
}

interface CategoriesTabState {
	isAddPopupOpen: boolean;
	isEditPopupOpen: boolean;
	isDeletePopupOpen: boolean;
	currentCategory: Category;
	deletedId: number;
	deletedName: string;
	searchText: string;
	filteredCategory: Category[];
}

@inject(STORE_BACK_OFFICE)
@observer
class CategoriesTab extends React.Component<CategoriesTabProps, CategoriesTabState> {
	constructor(props: CategoriesTabProps) {
		super(props);
		this.state = {
			isAddPopupOpen: false,
			isEditPopupOpen: false,
			isDeletePopupOpen: false,
			deletedId: 0,
			deletedName: '',
			searchText: '',
			currentCategory: this.getEmptyCategory(),
			filteredCategory: []
		};
		(this.props[STORE_BACK_OFFICE] as BackOfficeStore).getAllCategories();
		(this.props[STORE_BACK_OFFICE] as BackOfficeStore).getAllCollections();
	}

	closeAddPopup = () => {
		this.setState({ isAddPopupOpen: false });
	};

	closeEditPopup = () => {
		this.setState({ isEditPopupOpen: false });
	};

	closeDeletePopup = () => {
		this.setState({ isDeletePopupOpen: false });
	};

	deleteCategory = (id: number, name: string) => {
		this.setState({
			isDeletePopupOpen: true,
			deletedId: id,
			deletedName: name
		});
	};

	openAddPopup = () => {
		this.setState({ isAddPopupOpen: true });
	};

	openEditPopup = (
		id: number,
		name: string,
		collection_id: number,
		collection_name: string,
		chart_description: string,
		document_view_description: string
	) => {
		const currentCategoryCopy = { ...this.state.currentCategory };
		currentCategoryCopy.id = id;
		currentCategoryCopy.name = name;
		currentCategoryCopy.collection_id = collection_id;
		currentCategoryCopy.collection_name = collection_name;
		currentCategoryCopy.chart_description = chart_description;
		currentCategoryCopy.document_view_description = document_view_description;
		this.setState({
			isEditPopupOpen: true,
			currentCategory: currentCategoryCopy
		});
	};

	getEmptyCategory = (): Category => {
		return {
			id: 0,
			name: '',
			collection_id: -1,
			collection_name: '',
			chart_description: '',
			document_view_description: ''
		};
	};

	handleKeyPress = event => {
		this.setState({ searchText: event.target.value });
	};

	deleteCallback = () => {
		(this.props[STORE_BACK_OFFICE] as BackOfficeStore).deleteCategory(this.state.deletedId);
	};

	addCallback = (category: Category) => {
		(this.props[STORE_BACK_OFFICE] as BackOfficeStore).addCategory(category);
		this.closeAddPopup();
	};

	updateCallback = (category: Category) => {
		(this.props[STORE_BACK_OFFICE] as BackOfficeStore).updateCategory(category);
		this.closeAddPopup();
	};

	renderUpperSection = () => {
		return (
			<div>
				<PageTitle>Categories</PageTitle>
				<PageHeader>
					<Input
						type='text'
						placeholder='Search'
						onChange={e => this.handleKeyPress(e)}
						id='BACKOFFICE_CATEGORIES_SEARCH_BAR'
					/>
					<ButtonWrap>
						<Button
							variant='contained'
							size='large'
							style={{
								backgroundColor: '#21a6f2',
								color: '#ffff',
								boxShadow: 'none'
							}}
							onClick={() => this.openAddPopup()}
							id='BACKOFFICE_CATEGORIES_NEW_CATEGORY_BUTTON'
						>
							New Category
						</Button>
					</ButtonWrap>
				</PageHeader>
			</div>
		);
	};

	renderTableBody = (filteredCategories: Category[], collections) => {
		return (
			<TableBody id='CATEGORIES_TABLE'>
				{_.map(filteredCategories, (category, index) => {
					return (
						<TableRow key={category.id}>
							<TableCell>{category.id}</TableCell>
							<TableCell>{category.name}</TableCell>
							<TableCell>{category.collection_name}</TableCell>
							<TableCell>{category.chart_description}</TableCell>
							<TableCell>{category.document_view_description}</TableCell>
							<TableCell>
								<Icons
									src={Images.deleteTranscript}
									alt='Delete Organization'
									onClick={() => this.deleteCategory(category.id, category.name)}
									id={`BACKOFFICE_CATEGORIES_DELETE_CATEGORY_ROW_${index}`}
								/>
							</TableCell>
							<TableCell>
								<Icons
									src={Images.editIcon}
									onClick={() =>
										this.openEditPopup(
											category.id,
											category.name,
											category.collection_id,
											category.collection_name,
											category.chart_description,
											category.document_view_description
										)
									}
									id={`BACKOFFICE_CATEGORIES_EDIT_CATEGORY_ROW_${index}`}
								/>
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		);
	};

	renderTable = (filteredCategories, collections) => {
		return (
			<WrapTable>
				<Paper className={this.props.classes.paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Id</TableCell>
								<TableCell>Category Name</TableCell>
								<TableCell>Collection Name</TableCell>
								<TableCell>Chart Description</TableCell>
								<TableCell>Document View Description</TableCell>
								<TableCell />
								<TableCell />
							</TableRow>
						</TableHead>
						{this.renderTableBody(filteredCategories, collections)}
					</Table>
				</Paper>
			</WrapTable>
		);
	};

	render() {
		const { categories, collections } = this.props[STORE_BACK_OFFICE] as BackOfficeStore;
		const { isAddPopupOpen, isEditPopupOpen, currentCategory, isDeletePopupOpen } = this.state;
		const searchTerm = this.state.searchText.toLowerCase();
		const filteredCategories = _.filter(
			_.orderBy(categories, 'id'),
			item => item.name.toLowerCase().indexOf(searchTerm) !== -1
		);
		return (
			<div>
				{this.renderUpperSection()}
				{this.renderTable(filteredCategories, collections)}
				{isAddPopupOpen && (
					<AddOrUpdateCategoryPopup
						type={0}
						modalIsOpen={isAddPopupOpen}
						closePopup={() => this.closeAddPopup()}
						onConfirmFunc={this.addCallback}
						categories={categories}
						collections={collections}
					/>
				)}
				{isEditPopupOpen && (
					<AddOrUpdateCategoryPopup
						type={1}
						modalIsOpen={isEditPopupOpen}
						closePopup={() => this.closeEditPopup()}
						category={currentCategory}
						onConfirmFunc={this.updateCallback}
						categories={categories}
						collections={collections}
					/>
				)}
				{isDeletePopupOpen && (
					<DeletePopup
						modalIsOpen={isDeletePopupOpen}
						closePopup={() => this.closeDeletePopup()}
						deleteFunc={this.deleteCallback}
						name={'category'}
					/>
				)}
			</div>
		);
	}
}

export default withStyles(styles)(CategoriesTab);
