import React, { FC, ReactElement, useCallback, useState, useEffect } from 'react';
import {
	ComponentsProvider,
	Accordion,
	AccordionDisclosure,
	AccordionContent
} from '@looker/components';
import styled from 'styled-components';
import { useGetAvailableDashboardsQuery } from '../../queries/autogenerate/hooks';
import { Dashboard } from '../../queries/autogenerate/schemas';
import * as _ from 'lodash';
import { Colors } from '../../../components/shared/styles';
import Images from '../../shared/images';
import { error } from '../../services/logger.service';
import NavigationService from '../../services/navigation.service';
import DashboardsMenuSkeleton from './DashboardsMenuSkeleton';
import UserTracking from '../../services/userTracking.service';
import { UTA, UTC } from './AnalyticsSubMenu';

const Container = styled.div`
	height: 100%;
	width: 100%;
`;

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
	padding-left: 18px;
`;

const BodyWrap = styled.div`
	height: calc(100% - 65px);
	overflow-y: auto;
`;

const Center = styled.div`
	position: relative;
	width: calc(100% - 36px);
	height: 100%;
	margin: 0 auto;
`;

interface ItemTextProps {
	selected: boolean;
}

const ItemText = styled.div<ItemTextProps>`
	color: ${props => (props.selected ? Colors.blue : Colors.dark)};
	font-weight: ${props => (props.selected ? '600' : 'none')};
	font-family: Assistant;
	font-size: 15px;
	padding-left: 10px;
	cursor: pointer;

	:hover {
		color: ${Colors.blue};
	}
`;

const CloseIcon = styled.div`
	display: block;
	padding: 10px;
	margin-right: 15px;
	cursor: pointer;
	background-color: ${Colors.blue};
	-webkit-mask-image: url(${Images.closeWhite});
	-webkit-mask-position: center;
	-webkit-mask-repeat: no-repeat;
	-webkit-mask-size: 11px;
`;

const DashboardContainer = styled.div`
	margin-top: 10px;
	box-sizing: border-box;
	overflow: hidden;
	transition: height 0.5s;
	:hover ${ItemText} {
		color: ${Colors.blue};
	}
	&:last-child {
		margin-bottom: 10px;
	}
`;

const DashboardBox = styled.div`
	position: relative;
	cursor: pointer;
`;

interface Category {
	name: string;
	dashboards: Dashboard[];
}

interface AnalyticsLookerSubMenuProps {
	title: string;
	closeSideBar: Function;
	match: any;
}

const AnalyticsLookerSubMenu: FC<AnalyticsLookerSubMenuProps> = (
	props: AnalyticsLookerSubMenuProps
) => {
	const { error: fetchError, loading, data: dashboardsData } = useGetAvailableDashboardsQuery();
	const params = (props.match && props.match.params) || {};
	const [pathDashboardId, setPathDashboardId] = useState(params['dashboardId']);
	const [dashboards, setDashboards] = useState([]);
	const [categories, setCategories] = useState<Category[]>([]);
	const [categoriesAccordion, setCategoriesAccordion] = useState<ReactElement>();

	const onClickDashboard = useCallback((dashboard: Dashboard) => {
		const navService = NavigationService.instance;
		navService.goToDashboard(dashboard?.id.toString());
		const userTracking: UserTracking = UserTracking.getInstance();
		userTracking.setEvent(UTC.ANALYTICS, UTA.ANALYTICS_DASHBOARD_CLICKED, `${dashboard.name}`, {
			dashboardName: dashboard.name
		});

		setPathDashboardId(dashboard.id);
	}, []);

	useEffect(() => {
		setPathDashboardId(params['dashboardId']);
	}, [params]);

	const renderDashboards = useCallback(
		(dashboards: Dashboard[]) => {
			return _.map(dashboards, (dashboard: Dashboard) => {
				const isSelected: boolean = pathDashboardId
					? dashboard.id === Number(pathDashboardId)
					: dashboard.id === 1;
				const isHyphen: boolean = isHyphenExist(dashboard.name);
				return (
					<DashboardContainer key={`${dashboard.name}`}>
						<DashboardBox onClick={() => onClickDashboard(dashboard)} data-cy={`${dashboard.name}`}>
							<ItemText selected={isSelected}>
								{isHyphen
									? dashboard.name.substring(dashboard.name.indexOf('-') + 2, dashboard.name.length)
									: dashboard.name}
							</ItemText>
						</DashboardBox>
					</DashboardContainer>
				);
			});
		},
		[pathDashboardId, onClickDashboard]
	);

	/**
	 * Going over dashboards array and pulls out the unique names.
	 */
	const getUniqueDashboardNames = useCallback((dashboards: Dashboard[]): string[] => {
		const names: string[] = [];
		dashboards?.forEach(dashboard => {
			const isHyphen: boolean = isHyphenExist(dashboard.name);
			let nameSubstring: string = '';
			isHyphen
				? (nameSubstring = dashboard.name.substring(0, dashboard.name.indexOf('-') - 1))
				: (nameSubstring = dashboard.name);

			!names.find(name => nameSubstring === name) && names.push(nameSubstring);
		});
		return names;
	}, []);

	const isHyphenExist = (dashboardName: string): boolean => {
		return !!dashboardName.substring(0, dashboardName.indexOf('-') - 1);
	};

	/**
	 * find the category that holds the selected dashboard
	 */
	const getCategoryToOpenFromDashboardId = useCallback(
		(dashboardId: string): Category =>
			categories.find((category: Category) =>
				category.dashboards.find((dashboard: Dashboard) => dashboard.id === parseInt(dashboardId))
			),
		[categories]
	);

	const getAccordionFromCategories = useCallback(
		(categories: Category[], selectedDashboardId: string) => {
			const categoryToOpen: Category = getCategoryToOpenFromDashboardId(selectedDashboardId);
			return categories.map((category: Category, index) => (
				<Accordion
					id={category.name}
					key={category.name}
					defaultOpen={category?.name === categoryToOpen?.name}
				>
					<AccordionDisclosure>{category.name}</AccordionDisclosure>
					<AccordionContent>{renderDashboards(category.dashboards)}</AccordionContent>
				</Accordion>
			));
		},
		[renderDashboards, getCategoryToOpenFromDashboardId]
	);

	/**
	 * handle error while fetching the dashboards
	 */
	useEffect(() => {
		if (fetchError || (dashboardsData && _.isEmpty(dashboardsData.me.availableDashboards))) {
			error({
				message: `There was an error loading the dashboards, Error: ${fetchError}`,
				file: 'AnalyticsLookerSubMenu',
				functionName: 'useEffect'
			});
		}
	}, [fetchError, dashboardsData]);

	/**
	 * setting dashboards to state after loading finishes
	 */
	useEffect(() => {
		setDashboards(dashboardsData?.me.availableDashboards);
	}, [dashboardsData]);

	/**
	 * Creates and sets dashboards categories according to dashboards unique names (categories)
	 */
	useEffect(() => {
		const categoriesTemp: Category[] = [];
		const dashboardsCategoriesNames: string[] = getUniqueDashboardNames(dashboards);
		dashboardsCategoriesNames.forEach(categoryName => {
			const categoryDashboards = dashboards.filter(dashboard => {
				const isHyphen: boolean = isHyphenExist(dashboard.name);
				if (isHyphen) {
					return dashboard.name.substring(0, dashboard.name.indexOf('-') - 1) === categoryName;
				} else {
					return dashboard.name === categoryName;
				}
			});
			categoriesTemp.push({ name: categoryName, dashboards: categoryDashboards });
		});
		setCategories(categoriesTemp);
	}, [dashboards, getUniqueDashboardNames]);

	/**
	 * Populating the accordion with categories and dashboards
	 */
	useEffect(() => {
		// bail if no dashboards
		if (!dashboardsData) {
			return;
		}

		const userDashboards: Dashboard[] = dashboardsData.me?.availableDashboards;

		if (_.isEmpty(userDashboards)) {
			return;
		}

		const accordion: ReactElement[] = getAccordionFromCategories(categories, pathDashboardId);
		setCategoriesAccordion(<ComponentsProvider>{accordion}</ComponentsProvider>);
	}, [
		categories,
		renderDashboards,
		getCategoryToOpenFromDashboardId,
		pathDashboardId,
		dashboardsData,
		getAccordionFromCategories
	]);

	return (
		<Container>
			<TitleWrap>
				{props.title}
				<CloseIcon onClick={() => props.closeSideBar()} id='SIDEBAR_TAB_CLOSE_ICON' />
			</TitleWrap>
			<BodyWrap>
				{dashboards && dashboards.length > 0 ? (
					<Center>{loading ? <DashboardsMenuSkeleton /> : categoriesAccordion}</Center>
				) : (
					<Center>
						<DashboardsMenuSkeleton />
					</Center>
				)}
			</BodyWrap>
		</Container>
	);
};

export default AnalyticsLookerSubMenu;
