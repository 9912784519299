import React, { FC, useRef, useState, useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Header } from './transcriptHeader';
import { CommonDocumentData, useSecFilingData, useTranscriptData } from './transcript.service';
import DocumentSideBar from '../../components/DocumentSideBar/documentSideBar';
import { styles } from '@amenityllc/amenity-components';
import {
	APP_COLORS,
	EVENT_NAVIGATION_PREFIX_TRANSCRIPT,
	FEATURES_TYPE_SPLIT,
	SIDE_BAR_TABS,
	USER_TRACKING
} from '../../constants';
import TranscriptText from './Transcript/transcript';
import { Details } from './documentDetails';
import SecFilingIframe, {
	DocumentModelType,
	NavigationTarget,
	NavigationTargetType
} from './secFilingIframe';
import Images from '../../shared/images';
import DocumentTooltip from './Transcript/documentTooltip';
import * as Util from '../../services/util.service';
import NavigationService from '../../services/navigation.service';
import EventsFilters from './EventsFilters';
import UserTracking from '../../services/userTracking.service';
import * as ids from '../../../id.constants';
import Spinner from '../../widgets/Spinner/spinner';
import _ from 'lodash';
import { Event, OutboundEvent, Part, PartItem } from '../../types/types';
import { DocumentTypeGrouping } from '../../types/DocTypes.types';
import { ApolloError } from '@apollo/client';
import SeeNewVersionBanner from './Transcript/seeNewVersionBanner';
import { useFeature } from '../../hooks/useFeature';
import { useRecoilState } from 'recoil';
import currentProviderState from '../../recoil/currentProvider';
import md5 from 'blueimp-md5';

const { Colors } = styles;

export const DOCUMENT_SIDEBAR_WIDTH = 440;
export const DOCUMENT_SIDEBAR_MARGIN = 9;

const UTC = USER_TRACKING.USER_TRACKING_CATEGORIES;
const UTA = USER_TRACKING.USER_TRACKING_ACTIONS;

const Container = styled.div`
	width: 100%;
	height: 100%;
`;

const BodyContainer = styled.div`
	height: calc(100% - 100px);
	background-color: ${Colors.lightGray};
`;

interface LayoutProps {
	isEventsPanelOpened: boolean;
}

const Sidebar = styled.div<LayoutProps>`
	width: ${DOCUMENT_SIDEBAR_WIDTH}px;
	height: 100%;
	background-color: ${Colors.white};
	border: 1px solid ${Colors.gray};
	overflow-y: hidden;
	box-sizing: border-box;
	display: ${props => (props.isEventsPanelOpened ? 'inline-block' : 'none')};
`;

const DocumentSectionContainer = styled.div<LayoutProps>`
	height: 100%;
	width: ${({ isEventsPanelOpened }) => {
		return isEventsPanelOpened
			? `calc(100% - ${DOCUMENT_SIDEBAR_WIDTH + DOCUMENT_SIDEBAR_MARGIN}px)`
			: `calc(100% - ${DOCUMENT_SIDEBAR_MARGIN}px)`;
	}};
	overflow-y: hidden;
	overflow-x: hidden;
	box-sizing: border-box;
	display: inline-block;
	position: relative;
	background-color: ${Colors.lightGray};
`;

const DocumentInnerContainer = styled.div`
	height: 100%;
	background-color: white;
	margin: 0 ${DOCUMENT_SIDEBAR_MARGIN}px;
	border: 1px solid ${Colors.gray};
	padding-bottom: 20px;
`;

const OpenCloseEventsPanel = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 17px;
	height: 35px;
	cursor: pointer;
	border-radius: 4px;
	border: solid 1px ${APP_COLORS.LIGHT_GRAY_DDE5E7};
	background-color: ${Colors.lightGray};
	position: absolute;
	top: 50%;
	left: 8px;
	border-left: solid 1px ${Colors.lightGray};
	box-shadow: 3px 2px 4px -1px rgba(0, 0, 0, 0.15);
`;

const ArrowOpenCloseEventsPanel = styled.div<LayoutProps>`
	width: 14px;
	height: 21px;
	mask-image: url(${Images.arrowDownDropdown});
	transform: rotate(${props => (props.isEventsPanelOpened ? '90deg' : '-90deg')});
	mask-size: contain;
	mask-position: center;
	-webkit-mask-repeat: no-repeat;
	background-color: ${Colors.blue};
	margin-top: 2px;
`;

interface DocumentPageProps {
	subscriptionId: string;
	documentId: string;
	eventId: string;
	setClickedEventId: (eventId: string) => void;
	documentTypeGrouping: DocumentTypeGrouping;
}

const Transcript: FC<DocumentPageProps> = ({
	subscriptionId,
	documentId,
	eventId,
	setClickedEventId,
	documentTypeGrouping
}) => {
	// DURING TESTING ONLY:
	// DOC URL: https://trotter-main-output-bucket.s3.amazonaws.com/679/14645419/1.html
	// WE WILL GET: "htmlUrl": "https://d43pzodv7azfc.cloudfront.net/679/14645419/1.html"
	// END
	const navService = NavigationService.instance;
	const [secFilingModelType, setSecFilingModelType] = useState<DocumentModelType>(
		DocumentModelType.vip
	);
	const [showEventsFromQuery, setShowEventsFromQuery] = useState(true);
	const [isEventsPanelOpened, toggleEventsPanel] = useState(true);
	const [showNeutralExtractions, setShowNeutralExtractions] = useState(true);
	const [selectedEvent, setSelectedEvent] = useState<Event | null>();
	// const [selectedEventId, setSelectedEventId] = useState<string>(selectedEvent?.id.toString()); //sdfdsfdsf/sd/
	const [openCategoryContainingEvent, setOpenCategoryContainingEvent] = useState(false);
	const [currentProvider] = useRecoilState(currentProviderState);
	const ffNewerVersionBanner = useFeature(FEATURES_TYPE_SPLIT.VIEWER_NEW_VERSION_DOC_BANNER);
	const [showNewerVersionBanner, setShowNewerVersionBanner] = useState(false);
	const [alreadyShowedBanner, setAlreadyShowedBanner] = useState(false);
	const container = useRef();

	const selectedEventId = useMemo(() => selectedEvent?.id.toString(), [selectedEvent]);
	// skip the query type that we aren't supposed to render as
	const isTranscript = documentTypeGrouping === DocumentTypeGrouping.Transcripts;
	const transcriptData = useTranscriptData(
		subscriptionId,
		documentId,
		!isTranscript || !subscriptionId,
		currentProvider
	);
	const secFilingData = useSecFilingData(subscriptionId, documentId, isTranscript, currentProvider);

	const commonData: CommonDocumentData = isTranscript ? transcriptData : secFilingData;
	const {
		title,
		events,
		updateTime,
		eventTime,
		currentCompany,
		currentCompanyDocuments,
		vendor
	} = commonData;

	const loading: boolean = isTranscript
		? transcriptData.documentData?.loading
		: secFilingData.documentData?.loading;

	const error: ApolloError = isTranscript
		? transcriptData.documentData?.error
		: secFilingData.documentData?.error;

	// sidebar state
	const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);
	const [secFilingEvents, setSecFilingEvents] = useState<OutboundEvent[]>([]);
	const [secFilingEventsReceived, setSecFilingEventsReceived] = useState(false);

	const [filteredDiscussionSection, setFilteredDiscussionSection] = useState(
		transcriptData.discussionSection
	);
	const [filteredQnaSection, setFilteredQnaSection] = useState(transcriptData.qnaSection);
	const [secFilingSections, setSecFilingSections] = useState<Part[]>([]);
	const [secFilingSectionsReceived, setSecFilingSectionsReceived] = useState(false);

	// cross-navigation state
	const [iframeNavigationTarget, setIframeNavigationTarget] = useState<NavigationTarget | null>();

	const jsonDiffers = (a, b) => JSON.stringify(a) !== JSON.stringify(b);

	useEffect(() => {
		setShowEventsFromQuery(isTranscript || secFilingModelType === DocumentModelType.workshop);
	}, [isTranscript, secFilingModelType, setShowEventsFromQuery]);

	/**
	 * Filters out:
	 * - according to "neutral extraction checkbox"
	 * - UIVisble = false
	 */

	useEffect(() => {
		if (showEventsFromQuery && events) {
			const fullFiltered = EventsFilters(
				events.map(
					(event: any): Event => {
						const actualEvent = event as Event;

						// ugly workaround for event hash calc, to avoid messing around with the Event type:
						// use startIndex for event position and apiStartIndex for sentence start position
						actualEvent.startIndex = event.startPosition;
						actualEvent.startIndexAPI = event.sentenceStartPosition;

						return actualEvent;
					}
				),
				showNeutralExtractions
			);
			if (jsonDiffers(fullFiltered, filteredEvents)) {
				setFilteredEvents(fullFiltered);
			}
		}
	}, [events, showEventsFromQuery, showNeutralExtractions, filteredEvents]);

	useEffect(() => {
		// helper function
		const changeToExtractedEvents = financialSentence => {
			financialSentence.events = EventsFilters(financialSentence.events, showNeutralExtractions);
			return financialSentence;
		};

		if (showEventsFromQuery) {
			const discussionSectionDeepClone = _.cloneDeep(transcriptData.discussionSection);
			discussionSectionDeepClone.sentences.forEach(sentence =>
				sentence.sentences.map(changeToExtractedEvents)
			);

			if (jsonDiffers(discussionSectionDeepClone, filteredDiscussionSection)) {
				setFilteredDiscussionSection(discussionSectionDeepClone);
			}

			const qnaSectionDeepClone = _.cloneDeep(transcriptData.qnaSection);
			qnaSectionDeepClone.sentences.forEach(sentence =>
				sentence.sentences.map(changeToExtractedEvents)
			);

			if (jsonDiffers(qnaSectionDeepClone, filteredQnaSection)) {
				setFilteredQnaSection(qnaSectionDeepClone);
			}
		}
	}, [
		showEventsFromQuery,
		showNeutralExtractions,
		transcriptData.qnaSection,
		transcriptData.discussionSection,
		filteredDiscussionSection,
		filteredQnaSection
	]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const scroll = (scrollId: string) => {
		const element = document.getElementById(scrollId);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
		}
	};

	/**
	 * if event id exist in the url param => scroll to it.
	 */
	useEffect(() => {
		if (eventId) {
			if (showEventsFromQuery) {
				setOpenCategoryContainingEvent(true);
				const foundEvent = filteredEvents?.find(event => event.id.toString() === eventId);
				if (foundEvent) {
					scroll(EVENT_NAVIGATION_PREFIX_TRANSCRIPT + eventId);
					setSelectedEvent(foundEvent);
				} else {
					// there is no event with the event id in filteredTranscriptEvents, we are looking at a newer document version
					// pop a message to the user about the new version
					if (filteredEvents.length > 0 && !alreadyShowedBanner) {
						setShowNewerVersionBanner(true);
						setAlreadyShowedBanner(true);
					}
				}
			} else {
				if (secFilingEventsReceived) {
					setOpenCategoryContainingEvent(true);
				}
			}
		}
	}, [
		eventId,
		scroll,
		filteredEvents,
		alreadyShowedBanner,
		showEventsFromQuery,
		secFilingEventsReceived
	]);

	/**
	 * when the secFilingData.htmlUrl loaded,
	 * set the events source - query or iframe message
	 */
	useEffect(() => {
		const dataLoadedSuccessfully: boolean = !loading && !error && secFilingData !== undefined;
		const filingFromWorkshop: boolean = secFilingData.htmlUrl?.endsWith('_workshop.html');

		if (dataLoadedSuccessfully) {
			setSecFilingModelType(
				filingFromWorkshop ? DocumentModelType.workshop : DocumentModelType.vip
			);
		}
	}, [loading, error, commonData, secFilingData]);

	const onEventClicked = (event: Event) => {
		setClickedEventId(event.id.toString());
		setSelectedEvent(event);
	};

	const calculateEventHash = (event: Event): string => {
		// event hash defined as MD5 hexdigest of <sentenceStartIdx>-<eventStartIdx>-<eventText>
		// keep in mind the startIndex in API is relative to sentence start so we add them together
		const hashString = `${event.startIndexAPI}-${event.startIndex + event.startIndexAPI}-${
			event.text
		}`;
		return md5(hashString);
	};

	useEffect(() => {
		// if we're working with an event from a workshop doc, use its event hash to determine the nav target
		if (!isTranscript && (secFilingEventsReceived || (showEventsFromQuery && selectedEvent))) {
			setIframeNavigationTarget({
				type: NavigationTargetType.event,
				identifier:
					(showEventsFromQuery ? calculateEventHash(selectedEvent) : selectedEventId) || 'N/A'
			});
		}
	}, [
		isTranscript,
		selectedEventId,
		selectedEvent,
		showEventsFromQuery,
		setIframeNavigationTarget,
		secFilingEventsReceived
	]);

	const printTranscript = () => {
		const ut = new UserTracking();
		ut.setEvent(UTC.TRANSCRIPT, UTA.PRINT, `${title}`, {
			documentName: title,
			documentId: documentId
		});

		navService.goToPrintTranscript(subscriptionId, documentId);
	};

	const onSectionsFromIframe = useCallback((sections: Part[]) => {
		setSecFilingSections(sections);
		setSecFilingSectionsReceived(true);
	}, []);

	const onEventsFromIframe = useCallback((eventsFromIframe: OutboundEvent[]) => {
		setSecFilingEvents(eventsFromIframe);
		setSecFilingEventsReceived(true);
	}, []);

	const onSecFilingSectionClicked = (item: PartItem) => {
		setIframeNavigationTarget({
			type: NavigationTargetType.section,
			identifier: item.navigationId
		});
	};

	const sidebarTabs = {
		[SIDE_BAR_TABS.KeyDrivers]: '50%',
		[isTranscript ? SIDE_BAR_TABS.Participants : SIDE_BAR_TABS.Sections]: '50%'
	};

	if (error) {
		// NavigationService.getInstance().goToNotFound();
		return <div>error</div>;
	}

	return (
		<Container>
			<Header
				currentCompanyDocuments={currentCompanyDocuments}
				currentCompany={currentCompany}
				title={title}
				documentId={documentId}
				subscriptionId={subscriptionId}
				printTranscript={printTranscript}
				disablePrint={!isTranscript}
			/>
			{!loading && ffNewerVersionBanner && (
				<SeeNewVersionBanner
					close={() => setShowNewerVersionBanner(false)}
					show={showNewerVersionBanner}
				/>
			)}
			<BodyContainer>
				{/* only show the full-page spinner for transcripts, we have nicer loading for SEC filings */}
				{loading && isTranscript && <Spinner />}
				<Sidebar isEventsPanelOpened={isEventsPanelOpened}>
					<DocumentSideBar
						events={showEventsFromQuery ? filteredEvents : secFilingEvents}
						// we're loading events if:
						// - in transcript mode: loading
						// - in sec filings mode: loading, or havent got any from the iframe yet
						loadingEvents={showEventsFromQuery ? loading : loading || !secFilingEventsReceived}
						discussionSection={filteredDiscussionSection}
						qnaSection={filteredQnaSection}
						sections={secFilingSections}
						loadingSections={showEventsFromQuery ? loading : !secFilingSectionsReceived}
						onSectionClick={onSecFilingSectionClicked}
						onParticipantElementClicked={(index: number, elementTypeClicked: number) => {
							scroll(Util.getElementNavigationId(elementTypeClicked, index));
						}}
						onEventSelectionList={onEventClicked}
						selectedEventId={selectedEventId}
						showSort
						tabs={sidebarTabs}
						keyDriverModel
						showNeutralExtractions={showNeutralExtractions}
						setShowNeutralExtractions={setShowNeutralExtractions}
						openCategoryContainingEvent={openCategoryContainingEvent}
					/>
				</Sidebar>
				<DocumentSectionContainer isEventsPanelOpened={isEventsPanelOpened}>
					<DocumentInnerContainer id={ids.DOCUMENT_PAGE.TRANSCRIPT_DOCUMENT_CONTAINER}>
						<Details eventTime={eventTime} analysisTime={updateTime} />
						{isTranscript ? (
							<TranscriptText
								ref={container}
								discussionSection={filteredDiscussionSection.sentences}
								qnaSection={filteredQnaSection.sentences}
								vendor={vendor}
								selectedEventId={selectedEventId}
								printMode={false}
							/>
						) : (
							<SecFilingIframe
								loading={loading}
								documentId={documentId}
								htmlUrl={secFilingData.htmlUrl}
								documentModelType={secFilingModelType}
								crossFrameMessaging={{
									onSectionsFromIframe: onSectionsFromIframe,
									onEventsFromIframe: onEventsFromIframe
								}}
								navigationTarget={iframeNavigationTarget}
								markNeutralEvents={showNeutralExtractions}
							/>
						)}
					</DocumentInnerContainer>
					<OpenCloseEventsPanel onClick={() => toggleEventsPanel(!isEventsPanelOpened)}>
						<ArrowOpenCloseEventsPanel isEventsPanelOpened={isEventsPanelOpened} />
					</OpenCloseEventsPanel>
					<DocumentTooltip />
				</DocumentSectionContainer>
			</BodyContainer>
		</Container>
	);
};

export default Transcript;
