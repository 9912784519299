import _ from 'lodash';

import { SelectionData, Event, ModelFlow, Document, MatchingSelector } from '../../types/types';
import { EVENT_NAVIGATION_PREFIX_TRANSCRIPT, USER_TRACKING as UT } from '../../constants';
import { DOCUMENT_TYPES_LEGACY, DocumentTypeLegacy } from '../../types/DocTypes.types';
import * as ids from '../../../id.constants.js';
import UserTracking from '../../services/userTracking.service';

const UTC = UT.USER_TRACKING_CATEGORIES;
const UTA = UT.USER_TRACKING_ACTIONS;

export const getEventFromSelectionData = (selectedData: SelectionData): Event => {
	return {
		startIndex: selectedData.fromTo[0],
		endIndex: selectedData.fromTo[1],
		text: selectedData.text,
		polarity: undefined,
		isUserDefined: true,
		id: undefined,
		description: '',
		typeName: '',
		transcriptId: undefined,
		categoryName: ''
	};
};

export const isDocumentTranscriptType = (
	flow: ModelFlow,
	document: Document,
	currentDocumentType: DocumentTypeLegacy | string
): boolean => {
	const currentType =
		typeof currentDocumentType === 'string'
			? currentDocumentType
			: currentDocumentType && currentDocumentType.NAME;
	const documentTypeName = (document && document.documentType) || currentType;

	if (document && document.documentId === -1) {
		return isDataSourceTranscriptType(flow, DOCUMENT_TYPES_LEGACY);
	} else {
		return isTranscriptTypeByDocumentTypeName(documentTypeName);
	}
};

const isDataSourceTranscriptType = (flow: ModelFlow, documentTypes: any): boolean => {
	const currentFlowApiID = getApiIDFromFlow(flow);
	for (const key in documentTypes) {
		const currentDocumentType: DocumentTypeLegacy = documentTypes[key];
		if (currentDocumentType.API_ID === currentFlowApiID) {
			return currentDocumentType.IS_TRANSCRIPT;
		}
	}

	return false;
};

export const isTranscriptTypeByDocumentTypeName = (documentTypeName: string): boolean => {
	const documentType = _.find(
		DOCUMENT_TYPES_LEGACY,
		documenType => documenType.NAME === documentTypeName
	);
	return documentType ? documentType.IS_TRANSCRIPT : false;
};

// TODO: write test - M.K. 2.12.18
export const checkIfHighlightIsValid = (firstElement, lastElement): boolean => {
	const firstElementContainerSpan = firstElement.parentElement;
	const lastElementcontainerSpan = lastElement.parentElement;
	let currentElement = firstElementContainerSpan;
	let isHightlightValid = true;

	while (
		isHightlightValid &&
		currentElement &&
		currentElement !== lastElementcontainerSpan.nextSibling
	) {
		isHightlightValid =
			currentElement.firstElementChild.id.indexOf(EVENT_NAVIGATION_PREFIX_TRANSCRIPT) === -1;
		currentElement = currentElement.nextSibling;
	}

	return isHightlightValid;
};

export const getApiIDFromFlow = (flow: ModelFlow): number =>
	flow && flow.dataSourcesDetails && flow.dataSourcesDetails[0].documentTypeId;

export const getDocTypeFromFlow = (flow: ModelFlow): string =>
	flow && flow.dataSourcesDetails && flow.dataSourcesDetails[0].documentTypeName;

export const removeHighlightedMatches = (id: string) => {
	const previousStyle = document.getElementById(id);
	if (previousStyle) {
		previousStyle.remove();
	}
};

export const highlightMatches = (matchingSelectors: MatchingSelector[]) => {
	const style = document.createElement('style');
	style.id = ids.SEARCH_HIGHLIGHT_STYLE;
	const styleContent = _.map(matchingSelectors, (match: MatchingSelector) => {
		return getStyleForMatchingSelector(match);
	});
	style.innerText = styleContent.join('');
	document.head.appendChild(style);
};

export const highlightSelectedMatch = (selectedSpans: MatchingSelector[]) => {
	removeHighlightedMatches(ids.SEARCH_SELECTED_HIGHLIGHT_STYLE);
	const style = document.createElement('style');
	style.id = ids.SEARCH_SELECTED_HIGHLIGHT_STYLE;
	const styleContent = _.map(selectedSpans, (match: MatchingSelector) => {
		return getStyleForMatchingSelector(match, 'orange');
	});
	style.innerText = styleContent.join('');
	document.head.appendChild(style);
};

export const extractAllMatchingSelectors = (matches: any[], words: string[]) => {
	// eslint-disable-next-line
	const regex = /data-origin_index_start_end=\"\[[0-9]*,[0-9]*\]\"/g;
	return _.compact(
		_.reduce(
			matches,
			(init: MatchingSelector[], match: string, matchIndex: number) => {
				const goodSpans = _.filter(
					match.split('</span>'),
					(span: string) => span && span[span.length - 1] !== '>'
				);
				return _.union(
					init,
					_.map(goodSpans, (span: string, i: number) => {
						const word = span.split('>')[1];
						if (!word) {
							return;
						}
						const originalInput = words[i];
						const isFullWord = _.includes(words, word.toLowerCase());
						const prop = span.match(regex);
						return {
							selector: `[${prop[0]}]`,
							matchIndex,
							originalInput,
							isFullWord
						};
					})
				);
			},
			[]
		)
	);
};

export const createShadowDoppleganger = (element: any) => {
	const doppleganger = document.createElement(element.tagName);
	const originalComputedStyle = window.getComputedStyle(element);
	doppleganger.style = {
		position: 'absolute',
		opacity: '0'
	};
	_.each(['font-family', 'font-size', 'font-weight'], (attr: string) => {
		doppleganger.style[attr] = originalComputedStyle[attr];
	});
	doppleganger.innerText = element.innerText;
	document.body.appendChild(doppleganger);
	return doppleganger;
};

export const getStyleForMatchingSelector = (match: MatchingSelector, primaryColor = 'yellow') => {
	const { selector, isFullWord, originalInput } = match;
	const cssSelector = `span${selector}`;
	if (isFullWord) {
		return `${cssSelector} { background-color: ${primaryColor}; }`;
	}
	const originalElement: any = document.querySelectorAll(selector)[0];
	if (!originalElement) {
		return;
	}
	const doppleganger = createShadowDoppleganger(originalElement);
	const substringLeftCharOffset = doppleganger.innerText
		.toLowerCase()
		.indexOf(originalInput.toLowerCase());
	if (substringLeftCharOffset < 0) {
		return;
	}
	let firstStop, secondStop;
	if (substringLeftCharOffset === 0) {
		firstStop = 0;
	} else {
		doppleganger.innerText = doppleganger.innerText.substr(0, substringLeftCharOffset);
		firstStop = doppleganger.getBoundingClientRect().width;
	}
	const caseSensitiveSubstring = originalElement.innerText.substr(
		substringLeftCharOffset,
		originalInput.length
	);
	doppleganger.innerText = caseSensitiveSubstring;
	const wordWidth = doppleganger.getBoundingClientRect().width;
	doppleganger.remove();
	secondStop = firstStop + wordWidth;
	return `${cssSelector} { background: linear-gradient(to right, transparent ${firstStop}px, ${primaryColor} ${firstStop}px, ${primaryColor} ${secondStop}px, transparent ${secondStop}px); }`;
};

export const trackDocumentOpened = (
	flow: ModelFlow,
	document: Document,
	currentDocumentType: DocumentTypeLegacy,
	isDirectLink: boolean
) => {
	const isTranscript = isDocumentTranscriptType(flow, document, currentDocumentType);
	const ut = new UserTracking();
	const utLabel = `${document.title}. Id: ${document.documentId}`;
	let utAction;
	if (isDirectLink && isTranscript) {
		utAction = UTA.TRANSCRIPT_OPENED_DIRECT_LINK;
	} else if (isDirectLink && !isTranscript) {
		utAction = UTA.TEN_K_OPENED_DIRECT_LINK;
	} else if (!isDirectLink && isTranscript) {
		utAction = UTA.TRANSCRIPT_OPENED;
	} else {
		utAction = UTA.TEN_K_OPENED;
	}
	const utCategory = isTranscript ? UTC.TRANSCRIPT : UTC.TEN_K;
	ut.setEvent(utCategory, utAction, utLabel, {
		provider: document.vendor,
		documentName: document.title
	});
};
