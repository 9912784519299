import * as React from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';
import { getObject, removeItem } from '../../services/localstorage.service';
import { LOCAL_STORAGE_KEYS } from '../../constants';
import { Colors, FontFamilies, LogoAppearance } from '../../../components/shared/styles';
import { Logo } from '../../../components/Logo/Logo';

const PrintWrap = styled.div`
	width: 210mm;
	margin: 0 auto;
`;

const LogoContainer = styled.div`
	height: 63px;
	padding: 20px 42px;
	box-sizing: border-box;
	background-color: ${Colors.dark};
`;

const TextWrapper = styled.div`
	padding: 27px 40px;
	font-family: ${FontFamilies.regular};
`;

const CompanyName = styled.div`
	color: #2b4e7e;
	font-size: 32px;
	font-family: Assistant Bold;
	text-transform: uppercase;
`;

const Subtitle = styled.div`
	font-size: 14px;
	line-height: 1.17;
	color: #4b4a4b;
`;

const PublishTime = styled.div`
	font-size: 11.5px;
	line-height: 1.41;
	color: #9b9a9a;
`;

const Section = styled.div`
	margin-top: 20px;
`;

const SectionName = styled.div`
	font-family: Roboto;
	font-size: 21px;
	font-weight: bold;
	margin: 0 -10px;
	padding: 0 10px 5px;
	border-bottom: 1px solid #83a9b3;
	color: #83a9b3;
`;

const Subsection = styled.div`
	margin-top: 11px;
`;

const SubsectionTitle = styled.div`
	padding-left: 12px;
	font-family: Assistant Bold;
	text-transform: capitalize;
`;

const Text = styled.div`
	padding: 4px 0 0 12px;
	color: #32313b;
	font-size: 12px;
	line-height: 1.17;
`;

const Footer = styled.div`
	margin-top: 55px;
	border-top: 1px solid #e2e7ea;
	font-size: 11px;
	line-height: 29px;
	color: #606062;
`;

const FloatLeft = styled.div`
	float: left;
`;

const FloatRight = styled.div`
	float: right;
`;

const Type = styled.div`
	text-align: right;
	color: #9b9a9a;
	font-size: 12px;
`;

const Copyright = styled.div`
	color: #32313b;
	font-size: 12px;
`;

interface PrintSummaryState {
	companyName: string;
	publishTime: string;
	sections: any;
	footnote: string;
}

export default class PrintSummaryPage extends React.Component<{}, PrintSummaryState> {
	constructor(props) {
		super(props);
		this.state = {
			companyName: undefined,
			publishTime: undefined,
			sections: {},
			footnote: ''
		};
	}

	componentWillMount() {
		const printData = getObject(LOCAL_STORAGE_KEYS.PRINT_DATA) as PrintSummaryState;
		this.setState(printData, () => {
			removeItem(LOCAL_STORAGE_KEYS.PRINT_DATA);
		});
	}

	componentDidMount() {
		this.removePageBreaks();
		this.removeLinks();
		document.title = `${this.state.companyName} - 10K Summary`;
		setTimeout(() => {
			window.print();
		}, 0);
	}

	removePageBreaks = () => {
		const pageBreaks = Array.from(document.getElementsByTagName('hr'));
		_.each(pageBreaks, (pb: any) => pb.remove());
	};

	removeLinks = () => {
		const links = Array.from(document.getElementsByTagName('a'));
		_.each(links, (l: any) => {
			const innerText = l.innerText.toLowerCase().trim();
			if (innerText === 'table of contents' || innerText === 'index') {
				l.remove();
			}
		});
	};

	renderSubsections = (subsections: any[]) =>
		_.map(subsections, (ss: any) => (
			<Subsection key={ss.id}>
				<SubsectionTitle>{ss.title}</SubsectionTitle>
				<Text dangerouslySetInnerHTML={{ __html: ss.text }} />
				<Type>
					{ss.keyDriver} | {ss.eventType}
				</Type>
			</Subsection>
		));

	renderSections = (sections: any) =>
		_.map(_.keys(sections), (sectionName: string) => (
			<Section key={sectionName}>
				<SectionName>{sectionName}</SectionName>
				{this.renderSubsections(sections[sectionName])}
			</Section>
		));

	render() {
		const { companyName, publishTime, sections, footnote } = this.state;
		return (
			<PrintWrap>
				<LogoContainer>
					<Logo logoAppearance={LogoAppearance.LightGroup} />
				</LogoContainer>
				<TextWrapper>
					<CompanyName>{companyName}</CompanyName>
					<Subtitle>10-K Summary</Subtitle>
					<PublishTime>Publish time: {publishTime}</PublishTime>
					{this.renderSections(sections)}
					<Copyright>{footnote}</Copyright>
					<Footer>
						<FloatLeft>www.amenityanalytics.com</FloatLeft>
						<FloatRight>©2019 Amenity Analytics Ltd</FloatRight>
					</Footer>
				</TextWrapper>
			</PrintWrap>
		);
	}
}
