import * as React from 'react';
import styled from 'styled-components';
import * as ids from '../../../id.constants.js';
import { Colors } from '../../../components/shared/styles';
const TooltipContainer = styled.div`
	background-color: #ffffff;
	box-shadow: 0 -6px 22px -11px rgba(44, 48, 53, 0.4), 0 11px 22px -18px #2c3035;
	border-radius: 6px;
	border: 1px solid #dde5e7;
	width: 300px;

	&:before {
		content: '';
		width: 0;
		height: 0;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-bottom: 6px solid white;
		position: absolute;
		top: -5px;
		left: 144px;
	}
`;

const TextContainer = styled.div`
	padding: 15px 10px 25px;
	color: #2c3035;
	font-size: 13px;
	line-height: 18px;
	overflow: hidden;
	word-wrap: break-word;
`;

const ActionsContainer = styled.div`
	height: 18px;
	padding-right: 10px;
	padding-bottom: 10px;
`;

const ActionsButtonsContainer = styled.div`
	width: 100px;
	float: right;
`;

const ButtonContainer = styled.div`
	cursor: pointer;
	height: 18px;
	float: left;
	line-height: 18px;
	font-size: 13px;
	font-weight: 600;
	text-align: center;
	color: #2c3035;

	:hover {
		color: ${Colors.blue};
	}
`;

const EditButton = styled(ButtonContainer)`
	width: 25px;
	margin-right: 20px;
`;

const DeleteButton = styled(ButtonContainer)`
	width: 55px;
`;

interface Props {
	id: string;
	title: string;
	showActions: boolean;
	onEditEvent?: () => void;
	onDeleteEvent?: () => void;
}
const HighlightTooltip = (props: Props) => {
	return (
		<TooltipContainer>
			<TextContainer id={props.id}>{props.title}</TextContainer>
			{props.showActions && (
				<ActionsContainer id={'HOVER_ON_ANNOTATION'}>
					<ActionsButtonsContainer>
						<EditButton onClick={props.onEditEvent} id={ids.ANNOTATION_MODAL.EDIT_HIGHLIGHT}>
							Edit
						</EditButton>
						<DeleteButton onClick={props.onDeleteEvent} id={ids.ANNOTATION_MODAL.UNMARK_HIGHLIGHT}>
							Unmark
						</DeleteButton>
					</ActionsButtonsContainer>
				</ActionsContainer>
			)}
		</TooltipContainer>
	);
};

export default HighlightTooltip;
