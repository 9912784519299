import React, { useEffect, useState, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import styled from 'styled-components';
import { Button } from '../../../components/Button/Button';
import Modal from '../../../components/Modal/Modal';
import currentDocumentTypeState from '../../recoil/currentDocumentType';
import currentProviderState from '../../recoil/currentProvider';
import { useRecoilValue } from 'recoil';
import currentSubscriptionState from '../../recoil/currentSubscription';
import watchlistDataState from '../../recoil/watchlist';
import DataStore from '../../stores/data.store';
import GlobalStore from '../../stores/global.store';
import UIStore from '../../stores/ui.store';
import {
	STORE_FEATURES,
	STORE_DATA,
	ADD_COMPANY_DROPDOWN_PLACEHOLDER,
	USER_TRACKING as UT,
	STORE_GLOBAL,
	APP_COLORS,
	STORE_UI,
	TABS,
	GLOBAL_ERRORS
} from '../../constants';
import { DocumentType } from '../../types/DocTypes.types';
import { PortfolioCompany, SymbologyCompany, Category } from '../../types/types';
import { DocumentTypeLegacy } from '../../types/DocTypes.types';
import { Watchlist } from '../../types/Watchlist.types';
import * as ids from '../../../id.constants.js';

import { error } from '../../services/logger.service';
import UserTracking from '../../services/userTracking.service';
import NavigationService from '../../services/navigation.service';
import { findDocumentById } from '../../services/util.service';
import {
	getAllCompanies,
	addCompany,
	removeCompany,
	sortCompaniesByTicker
} from './watchlistCompanies.service';

import PortfolioTable from '../Table/portfolioTable';
import WatchlistLabel from './WatchlistLabel';
import { Empty, EmptyIcon, EmptySubTitle } from './sharedStyledComponents';
import { Appearance, Colors } from '../../../components/shared/styles';
import { SymbologySearch } from '../../widgets/PortfolioSymbologyAutoCompleteDropDown/SymbologySearch';
import { CreateWatchlistMenu } from './CreateWatchlistMenu/CreateWatchlistMenu';
import { Link, Text } from '../../../components';
import WatchlistLensNewarc from './WatchlistLensNewarc';
import Spinner from '../../widgets/Spinner/spinner';

const UTC = UT.USER_TRACKING_CATEGORIES;
const UTA = UT.USER_TRACKING_ACTIONS;

const Body = styled.div`
	height: 100%;
`;

const UpperSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 90px;
	padding: 0 15px;
	box-sizing: border-box;
	border-bottom: 1px solid #e0e7e9;
`;

const LeftUpperSection = styled.div`
	display: flex;
	flex-direction: column;
	width: calc(100% - 450px);
	height: 100%;
	padding: 10px 0;
	box-sizing: border-box;
`;

const RightUpperSection = styled.div`
	display: flex;
	align-items: center;
`;

const BottomSection = styled.div`
	height: calc(100% - 90px);
	width: 100%;
`;

const InnerSectionContainer = styled.div`
	height: 100%;
	width: 90%;
	min-width: 1040px;
	margin: 0 auto;
	padding: 10px 0 5px 10px;
	box-sizing: border-box;
`;

interface AddTickerContainerProps {
	enableHover: boolean;
}
const AddTickerContainer = styled.div<AddTickerContainerProps>`
	width: calc(100% - 220px);
	margin-top: 10px;
	cursor: ${props => (props.enableHover ? 'text' : 'default')};
	border-radius: 5px;
`;

const Separator = styled.div`
	height: 1px;
	width: 1px;
	margin: 0 5px;
`;

const PRE_TABLE_HEADER_HEIGHT = 50;

const PreTableHeader = styled.div`
	display: flex;
	height: ${PRE_TABLE_HEADER_HEIGHT}px;
	box-sizing: border-box;
	padding-left: 18px;
	justify-content: space-between;
	align-items: center;
`;

const TableTitle = styled.div`
	float: left;
	font-family: assistant;
	font-weight: 600;
	font-size: 14px;
	color: ${APP_COLORS.BLACK_32313B};
`;

const TableContainer = styled.div`
	height: calc(100% - ${PRE_TABLE_HEADER_HEIGHT}px);
`;

const HiddenCurrentFlowFlag = styled.div`
	position: absolute;
	height: 0;
	width: 0;
	opacity: 0;
`;

const EmptyContainer = styled.div`
	margin: 31px 21px;
`;

const ModalTitle = styled.h2`
	color: ${Colors.negative};
	margin-top: 0;
`;

const ModalContent = styled.div`
	white-space: break-spaces;
`;

const StyledButton = styled(Button)`
	cursor: pointer;
`;

interface PortfolioProps {
	match: any;
	watchlist: Watchlist;
	[STORE_UI]?: UIStore;
	[STORE_DATA]?: DataStore;
	[STORE_GLOBAL]?: GlobalStore;
}

const WatchlistPage: React.FunctionComponent<PortfolioProps> = (props: PortfolioProps) => {
	const {
		watchlist,
		[STORE_DATA]: dataStore,
		[STORE_GLOBAL]: globalStore,
		[STORE_UI]: uiStore
	} = props;
	const userTracking: UserTracking = UserTracking.getInstance();
	const navService: NavigationService = NavigationService.getInstance();
	const tableRef = useRef(undefined);
	const [categories, setCategories] = useState([]);
	const [watchlistIdString, refreshList] = useState(watchlist?.id);
	const [watchlistName, setWatchlistName] = useState<string>(watchlist?.name);
	const [errorString, setErrorString] = useState<string>(undefined);
	const [companies, setCompanies] = useState<any>([]);
	const [loading, setLoading] = useState<any>(true);
	const [addCompanyCounter, setAddCompanyCounter] = useState(0);
	const [addedCompanyId, setAddedCompanyId] = useState<number | string>(-1);
	const currentDocType = useRecoilValue(currentDocumentTypeState);
	const currentSubscriptionId = useRecoilValue(currentSubscriptionState);
	const currentProvider = useRecoilValue(currentProviderState);
	const watchlistData = useRecoilValue(watchlistDataState);

	useEffect(() => {
		setLoading(true);
		getAllCompanies(watchlist?.id, currentSubscriptionId, currentDocType, currentProvider)
			.then(data => setCompanies(data))
			.catch(err => setErrorString(err))
			.finally(() => setLoading(false));
	}, [watchlist, currentSubscriptionId, currentDocType, currentProvider, watchlistIdString]);

	useEffect(() => {
		setWatchlistName(watchlistData?.name || watchlist?.name);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		globalStore.setCurrentTab(TABS.WATCHLIST);
		uiStore.setSideBarState(true);
		uiStore.setCanToggleSideBar(true);
		dataStore.getAllCategories((categories: any) => {
			setCategories(categories);
		});
		tableRef?.current?.focusRow(addedCompanyId, true);
	}, [addedCompanyId, dataStore, globalStore, uiStore]);

	const onRemoveItem = companyId => {
		removeCompany(watchlist.id, companyId, currentSubscriptionId, currentDocType).then(() => {
			const newCompanies = [...companies];
			_.remove(newCompanies, { id: companyId });
			const sorted = sortCompaniesByTicker(newCompanies);
			setCompanies(sorted);
		});
	};

	const navigateToDocument = (documentId: number, company: PortfolioCompany) => {
		const { companyDocuments, currentFlow } = props[STORE_DATA];
		const document = findDocumentById(companyDocuments, company, documentId);

		if (document) {
			navService.goToDocument(currentFlow.id, document.documentId);
		} else {
			error({
				message: `Couldn't find document ${documentId} in the list of documents.`,
				file: 'WatchlistPage',
				functionName: 'navigateToDocument'
			});
		}
	};

	const collapseAllRows = () => {
		(props[STORE_UI] as UIStore).resetExpandedCompanies();
	};

	const { portfolioTableExpandedCompanies } = props[STORE_UI] as UIStore;
	const showCollapseAllButton = !_.isEmpty(portfolioTableExpandedCompanies);
	const showCollapseAllButtonDisabled = _.isEmpty(portfolioTableExpandedCompanies);

	const goToAllWatchlists = () => {
		userTracking.setEvent(UTC.WACTHLIST, UTA.BACK_TO_WACTHLISTS_CLICK);
		navService.goToAllWatchlists();
	};

	const renderEmpty = () => (
		<EmptyContainer>
			<Empty id={ids.MULTIPORTFOLIO.EMPTY_STATE} padding={'30px 0 54px'}>
				<EmptyIcon />
				<EmptySubTitle>
					Your watchlist is empty. Add a ticker from the search box above in order to view Amenity
					Scores and Documents
				</EmptySubTitle>
			</Empty>
		</EmptyContainer>
	);

	const renderTable = (
		currentDocumentType: DocumentTypeLegacy,
		companies:
			| {
					id: string;
					name: string;
					ticker: string;
					region: string;
					sector: string;
					keyDrivers: any[];
					last_document_date: any;
					score: number;
					prev_score: number;
					documents: any[];
			  }[]
			| PortfolioCompany[],
		addCompanyCounter: number,
		categories: Category[],
		showCollapseAllButton: boolean,
		showCollapseAllButtonDisabled: boolean
	) => {
		const currentDocumentLabel = DocumentType[currentDocType];
		return (
			<InnerSectionContainer>
				<PreTableHeader>
					<TableTitle>{currentDocumentLabel}</TableTitle>
					{showCollapseAllButton && <Link onClick={collapseAllRows}>Collapse All</Link>}
					{showCollapseAllButtonDisabled && (
						<Text appearance={Appearance.secondary} color={Colors.darkGray}>
							Collapse All
						</Text>
					)}
				</PreTableHeader>
				<TableContainer>
					{companies ? (
						<PortfolioTable
							ref={tableRef}
							onDeleteCompany={onRemoveItem}
							onNavigateToTicker={navigateToDocument}
							companies={companies}
							currentDocumentType={currentDocumentType}
							addedCompanyId={addedCompanyId}
							companyAddedCounter={addCompanyCounter}
							onScrolledToRow={companyDropDownFocus}
							categories={categories}
							isSuccessfulLoad={true}
							subscriptionId={currentSubscriptionId}
						/>
					) : (
						<Spinner />
					)}
				</TableContainer>
			</InnerSectionContainer>
		);
	};

	const renderContent = (
		isSuccessfulLoad: boolean,
		showOnBoarding: boolean,
		currentDocumentType: DocumentTypeLegacy,
		companies: PortfolioCompany[],
		addCompanyCounter: number,
		categories: Category[]
	) => {
		if (showOnBoarding && isSuccessfulLoad) {
			companyDropDownFocus();
			return renderEmpty();
		}
		return renderTable(
			currentDocumentType,
			companies,
			addCompanyCounter,
			categories,
			showCollapseAllButton,
			showCollapseAllButtonDisabled
		);
	};

	const companyDropDownFocus = () => {
		const input = document.getElementById('PORTFOLIO_SEARCH_FIELD');
		input?.focus();
	};

	const formatSearchResults = companiesIds => (results: SymbologyCompany[]) => {
		const [inWatchlist, notInWatchlist] = _.partition(results, (company: SymbologyCompany) =>
			_.some(
				companiesIds,
				(watchlistCompany: PortfolioCompany) => watchlistCompany?.id === company?.id
			)
		);

		return {
			inPortfolio: inWatchlist,
			notInPortfolio: notInWatchlist
		};
	};

	const onSearchItemSelect = (company: SymbologyCompany, shouldAddToWatchlist: boolean) => {
		setAddCompanyCounter(addCompanyCounter + 1);
		if (shouldAddToWatchlist) {
			if (company && company.id !== '') {
				addCompany(
					watchlist.id,
					company.id,
					currentSubscriptionId,
					currentDocType,
					currentProvider
				).then((fetchedCompany: any) => {
					const newCompanies = [...companies, fetchedCompany];
					const sorted = sortCompaniesByTicker(newCompanies);
					setCompanies(sorted);
					setAddedCompanyId(company.id);
				});
			}
		} else {
			tableRef?.current?.focusRow(company.id, true);
		}
	};

	const { currentFlow, isSuccessfulLoad, currentDocumentType } = props[STORE_DATA];

	const showOnBoardingPage = companies?.length === 0;

	const reloadWatchlist = newWatchlistIdString => {
		refreshList(watchlistIdString + newWatchlistIdString);
	};

	const cleanError = () => setErrorString(undefined);

	const parseEmailWithError = () => {
		const email_regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
		const bodyText = GLOBAL_ERRORS[errorString]?.DESCRIPTION || '';
		const result = bodyText.replace(email_regex, '<a href="mailto:$1">$1</a>');
		return <div dangerouslySetInnerHTML={{ __html: result }} />;
	};

	return (
		<Body style={{ backgroundColor: showOnBoardingPage ? '#FBFBFB' : 'unset' }}>
			<Modal
				isModalOpen={!!errorString}
				close={cleanError}
				modalContent={
					<>
						<ModalTitle>{GLOBAL_ERRORS[errorString]?.TITLE}</ModalTitle>
						<ModalContent>{parseEmailWithError()}</ModalContent>
					</>
				}
				modalButtons={
					<StyledButton onClick={cleanError}>{GLOBAL_ERRORS[errorString]?.BUTTON}</StyledButton>
				}
			/>
			{currentFlow && <HiddenCurrentFlowFlag id={ids.CURRENT_FLOW_LOADED} />}
			<UpperSection style={{ backgroundColor: Colors.lightGray }}>
				<LeftUpperSection>
					<WatchlistLabel name={watchlistName} handleBack={goToAllWatchlists} />
					<AddTickerContainer enableHover={showOnBoardingPage} onClick={companyDropDownFocus}>
						<SymbologySearch
							placeholder={ADD_COMPANY_DROPDOWN_PLACEHOLDER}
							onSelect={onSearchItemSelect}
							formatSearchResults={formatSearchResults(companies)}
						/>
					</AddTickerContainer>
				</LeftUpperSection>
				<RightUpperSection>
					<CreateWatchlistMenu
						refetch={reloadWatchlist}
						watchlistId={watchlist?.id}
						watchlistName={watchlistName}
					/>
					<Separator />
					<WatchlistLensNewarc />
				</RightUpperSection>
			</UpperSection>
			{loading ? (
				<Spinner />
			) : (
				<BottomSection>
					{renderContent(
						isSuccessfulLoad,
						showOnBoardingPage,
						currentDocumentType,
						companies,
						addCompanyCounter,
						categories
					)}
				</BottomSection>
			)}
		</Body>
	);
};

export default inject(STORE_DATA, STORE_GLOBAL, STORE_UI, STORE_FEATURES)(observer(WatchlistPage));
