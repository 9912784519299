import DataSrv from './data.service';
export default class SalesforceService {
	sendContactOnSignup = (contactData: any) => {
		const ds = new DataSrv();
		const fullContactData = {
			Signup_Date__c: new Date(),
			App_Notification_Opt_In__c: contactData.sendNotifications,
			FirstName: contactData.firstName,
			LastName: contactData.lastName,
			Email: contactData.email
		};
		ds.salesforceHandleContact(
			fullContactData,
			res => {},
			err => console.error('SALESFORCE ERROR', err)
		);
	};

	sendContactOnLogin = (contactData: any, cb?: Function) => {
		const ds = new DataSrv();
		const fullContactData = {
			Email: contactData.email,
			First_Login__c: new Date(),
			Last_Login__c: new Date()
		};
		ds.salesforceHandleContact(
			fullContactData,
			res => {
				if (cb) {
					cb(res);
				}
			},
			err => {
				console.error('SALESFORCE ERROR', err);
				if (cb) {
					cb(err);
				}
			}
		);
	};
}
