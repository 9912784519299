import React from 'react';
import _ from 'lodash';

import { Colors, FontSizes } from '../../../components/shared/styles';
import ids from '../../../id.constants';
import { FEATURES_TYPE_SPLIT, SIDE_BAR_TABS } from '../../constants';
import { getTabUnderlineLeftRight } from '../../services/util.service';
import {
	BadgeWithMargin,
	Tab,
	TabsContainer,
	TabSelectedUnderline,
	TabsInnerContainer,
	TabsUnderline
} from '../../pages/sideBarElements';
import { useFeature } from '../../hooks/useFeature';

interface SideBarTabsProps {
	tabs: Record<number, string>;
	selectedTab: SIDE_BAR_TABS;
	eventsLength?: number;
	setCurrentTab: (tab: SIDE_BAR_TABS) => void;
}

const TAB_UNDERLINE_MARGIN = 10;

const SidebarHeader: React.FunctionComponent<SideBarTabsProps> = ({
	tabs,
	selectedTab,
	eventsLength,
	setCurrentTab
}) => {
	const ffViewerDocumentFilters = useFeature(FEATURES_TYPE_SPLIT.VIEWER_DOCUMENT_FILTERS);
	const tabsExist = (tab: number) => !!tabs[tab];
	const leftTabLeftRight = getTabUnderlineLeftRight(
		false,
		selectedTab === SIDE_BAR_TABS.Sections ||
			selectedTab === SIDE_BAR_TABS.QnA ||
			selectedTab === SIDE_BAR_TABS.Participants,
		TAB_UNDERLINE_MARGIN
	);
	const rightTabLeftRight = getTabUnderlineLeftRight(
		true,
		selectedTab === SIDE_BAR_TABS.KeyDrivers || selectedTab === SIDE_BAR_TABS.ManagementDiscussion,
		TAB_UNDERLINE_MARGIN
	);
	const isSingleTab = _.keys(tabs).length === 1;

	return (
		<TabsContainer>
			<TabsInnerContainer>
				{tabsExist(SIDE_BAR_TABS.KeyDrivers) && (
					<Tab
						width={tabs[SIDE_BAR_TABS.KeyDrivers]}
						onClick={() => setCurrentTab(SIDE_BAR_TABS.KeyDrivers)}
						id={ids.DOCUMENT_PAGE.EVENTS_TAB}
					>
						Events{' '}
						<BadgeWithMargin color={Colors.blue} fontSize={FontSizes.s3}>
							{eventsLength}
						</BadgeWithMargin>
						{!isSingleTab && (
							<TabSelectedUnderline
								leftPos={rightTabLeftRight.left}
								rightPos={rightTabLeftRight.right}
							/>
						)}
					</Tab>
				)}
				{tabsExist(SIDE_BAR_TABS.Sections) && (
					<Tab
						width={tabs[SIDE_BAR_TABS.Sections]}
						onClick={() => setCurrentTab(SIDE_BAR_TABS.Sections)}
						id={ids.DOCUMENT_PAGE.SECTIONS_TAB}
					>
						Sections
						{!isSingleTab && (
							<TabSelectedUnderline
								leftPos={leftTabLeftRight.left}
								rightPos={leftTabLeftRight.right}
							/>
						)}
					</Tab>
				)}
				{tabsExist(SIDE_BAR_TABS.Participants) && (
					<Tab
						width={tabs[SIDE_BAR_TABS.Participants]}
						onClick={() => setCurrentTab(SIDE_BAR_TABS.Participants)}
						id={ids.DOCUMENT_PAGE.PARTICIPANTS_TRANSCRIPT_TAB}
					>
						Participants
						{!isSingleTab && (
							<TabSelectedUnderline
								leftPos={leftTabLeftRight.left}
								rightPos={leftTabLeftRight.right}
							/>
						)}
					</Tab>
				)}
				{tabsExist(SIDE_BAR_TABS.ManagementDiscussion) && (
					<Tab
						width={tabs[SIDE_BAR_TABS.ManagementDiscussion]}
						onClick={() => setCurrentTab(SIDE_BAR_TABS.ManagementDiscussion)}
						id={ids.DOCUMENT_PAGE.MANAGEMENT_DISCUSSION_TRANSCRIPT_TAB}
						ffViewerDocumentFilters={ffViewerDocumentFilters}
					>
						Management Discussion
						{!isSingleTab && (
							<TabSelectedUnderline
								leftPos={rightTabLeftRight.left}
								rightPos={rightTabLeftRight.right}
							/>
						)}
					</Tab>
				)}
				{tabsExist(SIDE_BAR_TABS.QnA) && (
					<Tab
						width={tabs[SIDE_BAR_TABS.QnA]}
						onClick={() => setCurrentTab(SIDE_BAR_TABS.QnA)}
						id={ids.DOCUMENT_PAGE.QNA_TRANSCRIPT_TAB}
						ffViewerDocumentFilters={ffViewerDocumentFilters}
					>
						{`Q&A`}
						{!isSingleTab && (
							<TabSelectedUnderline
								leftPos={leftTabLeftRight.left}
								rightPos={leftTabLeftRight.right}
							/>
						)}
					</Tab>
				)}
			</TabsInnerContainer>
			<TabsUnderline marginLeft={TAB_UNDERLINE_MARGIN} />
		</TabsContainer>
	);
};

export default SidebarHeader;
