import * as React from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';

import Images from '../../shared/images';
import { APP_COLORS, STORE_ANALYTICS, STORE_DATA, USER_TRACKING as UT } from '../../constants';
import { App, AppSheet } from '../../types/types';
import { CloseIcon } from './SideBar.styles';
import { Colors } from '../../../components/shared/styles';
import { inject, observer } from 'mobx-react';
import AnalyticsStore from '../../stores/analytics.store';
import UserTracking from '../../services/userTracking.service';

export const UTC = UT.USER_TRACKING_CATEGORIES;
export const UTA = UT.USER_TRACKING_ACTIONS;

const Container = styled.div`
	height: 100%;
	width: 100%;
`;

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
	padding-left: 18px;
	color: #4a4a4a;
	font-family: Assistant;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	font-size: 18px;
`;

const BodyWrap = styled.div`
	height: calc(100% - 65px);
	overflow-y: auto;
`;

const Center = styled.div`
	position: relative;
	margin: 0 18px;
`;

const AppBox = styled.div`
	position: relative;
	cursor: pointer;
`;

const ItemText = styled.div<{ selected: Boolean }>`
	width: 116px;
	min-height: 20px;
	word-break: break-word;
	color: ${props => (props.selected ? Colors.blue : APP_COLORS.BLACK_32313B)};
	font-weight: ${props => (props.selected ? '600' : '')};
	font-family: Assistant;
	font-size: 15px;
	cursor: pointer;
`;

interface ArrowProps {
	isOpen: boolean;
	myUrl: string;
}
const Arrow = styled.div<ArrowProps>`
	position: absolute;
	top: 6px;
	right: 0px;
	width: 13px;
	height: 8px;
	transform: ${props => (props.isOpen ? 'rotate(-180deg)' : '')};
	mask-image: url(${props => props.myUrl});
	mask-size: contain;
	mask-position: center;
	background-color: ${props => (props.isOpen ? Colors.blue : '#9B9B9B')};
	transition: transform 0.5s, background-color 0.5s;
`;

const SpinnerIcon = styled.div`
	position: absolute;
	top: 0px;
	right: 0px;
	height: 20px;
	width: 20px;
	margin: auto;
	background-image: url("${Images.spinner}");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
`;

const AppContainer = styled.div`
	margin-top: 20px;
	box-sizing: border-box;
	overflow: hidden;
	position: relative;
	transition: height 0.5s;
	:hover ${ItemText} {
		color: ${Colors.blue};
	}
	&:last-child {
		margin-bottom: 10px;
	}
`;

interface AnalyticsSubMenuProps {
	title: string;
	closeSideBar: Function;
}
@inject(STORE_DATA, STORE_ANALYTICS)
@observer
export class AnalyticsSubMenu extends React.Component<AnalyticsSubMenuProps, {}> {
	onAppChange = (app: App) => {
		(this.props[STORE_ANALYTICS] as AnalyticsStore).toggleApp(app);
	};

	onSheetChange = (app: App, appSheet: AppSheet) => {
		const ut = new UserTracking();
		ut.setEvent(UTC.ANALYTICS, UTA.ANALYTICS_DASHBOARD_CLICKED, `${app.name}`, {
			dashboardName: app.name
		});
		(this.props[STORE_ANALYTICS] as AnalyticsStore).changeSheet(app, appSheet);
	};

	renderApps = (appsData: App[], onClickApp, onClickSheet, currentApp, currentAppSheet) => {
		return _.map(appsData, (app: App) => {
			const selected = currentApp && app.id === currentApp.id;
			const isOpen = (selected || app.isOpen) && app.sheets && app.sheets.length > 0;
			return (
				<AppContainer key={`${app.id}_${app.name}`}>
					<AppBox onClick={() => onClickApp(app)}>
						<ItemText selected={selected}>{app.name}</ItemText>
						{app.loadingSpinner ? (
							<SpinnerIcon id='ANALYTICS_APP_SPINNER' />
						) : (
							<Arrow myUrl={Images.arrowDownGrey} isOpen={isOpen} />
						)}
					</AppBox>
				</AppContainer>
			);
		});
	};

	render() {
		const { currentApp, apps, currentAppSheet } = this.props[STORE_ANALYTICS] as AnalyticsStore;
		const { title, closeSideBar } = this.props;
		const onClickApp = (app: App) => this.onAppChange(app);
		const onClickSheet = (app: App, appSheet: AppSheet) => this.onSheetChange(app, appSheet);

		return (
			<Container>
				<TitleWrap>
					{title}
					<CloseIcon onClick={() => closeSideBar()} id='SIDEBAR_TAB_CLOSE_ICON' />
				</TitleWrap>
				<BodyWrap>
					{apps && apps.length > 0 ? (
						<Center>
							{this.renderApps(apps, onClickApp, onClickSheet, currentApp, currentAppSheet)}
						</Center>
					) : (
						<Center />
					)}
				</BodyWrap>
			</Container>
		);
	}
}
