import React, { useEffect } from 'react';
import DashboardIframeStateService from './dashboardService';

export const useDashboardLoadDone = (
	dashboardService: DashboardIframeStateService,
	dashboardId: string
) => {
	const [showDashboard, setShowDashboard] = React.useState(false);
	const [currentDashboardIdWorkedOn, setCurrentDashboardId] = React.useState('');
	useEffect(() => {
		// wait for the service to signal that it's ready with the user's filters updated on the dashboard
		async function waitOnServiceState() {
			await dashboardService.waitStateComplete(Number(dashboardId));
			setShowDashboard(true);
			setCurrentDashboardId(dashboardId);
		}

		// if the user didn't changed the dashboard she is using
		if (dashboardService && dashboardId && currentDashboardIdWorkedOn === dashboardId) {
			waitOnServiceState();
		} else {
			// the user did changed the dashboard, return to show loading and recall this component with
			// new dashboard id to begin the waitOnServiceState promise
			setShowDashboard(false);
			setCurrentDashboardId(dashboardId);
		}
	}, [dashboardId, dashboardService, currentDashboardIdWorkedOn]);
	return { showDashboard };
};
