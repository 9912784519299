import { APP_URLS, LOCAL_STORAGE_KEYS, TABS } from '../constants';
import * as localStorageSrv from './localstorage.service';

declare var screen: any;

export default class NavigationService {
	history: any;
	dataStore: any;
	analyticsStore: any;
	globalStore: any;
	authStore: any;

	static instance: NavigationService;

	static getInstance() {
		return NavigationService.instance || new NavigationService();
	}

	constructor(props?: any) {
		if (NavigationService.instance) {
			return NavigationService.instance;
		}
		NavigationService.instance = this;
		if (props) {
			this.history = props.history;
			this.dataStore = props.data;
			this.analyticsStore = props.analytics;
			this.globalStore = props.global;
			this.authStore = props.auth;
		}
	}

	isIE = (): boolean => false || !!(document as any).documentMode;

	isMobile = (): boolean => {
		const Android: Function = () => navigator.userAgent.match(/Android/i);
		const BlackBerry: Function = () => navigator.userAgent.match(/BlackBerry/i);
		const iOS: Function = () => navigator.userAgent.match(/iPhone|iPad|iPod/i);
		const Opera: Function = () => navigator.userAgent.match(/Opera Mini/i);
		const Windows: Function = () => navigator.userAgent.match(/IEMobile/i);

		const checkAll: Function = () => Android() || BlackBerry() || iOS() || Opera() || Windows();
		if (checkAll()) {
			return true;
		}

		return false;
	};

	goToNotSupported = (push?: boolean) => {
		if (push) {
			this.history.push(APP_URLS.NOT_SUPPORTED);
		} else {
			this.history.replace(APP_URLS.NOT_SUPPORTED);
		}
	};

	goToMobileNotSupported = (message: any) => {
		this.globalStore.setMobileNotSupported(message);
		this.history.replace(APP_URLS.MOBILE_NOT_SUPPORTED);
	};

	goToNotFound = () => {
		this.history.replace(APP_URLS.NOT_FOUND);
	};

	goToLogin = () => this.authStore.universalLogin();

	back = () => {
		this.history.goBack();
	};

	goToRoute = (route: string) => {
		this.history.push(route);
	};

	goToQueryInsights = () => {
		this.history.push(APP_URLS.QI);
		this.globalStore.setCurrentTab(TABS.QUERY);
	};

	goToEmailSubscription = () => {
		this.history.push(APP_URLS.EMAIL_SETTINGS);
		this.globalStore.setCurrentTab(TABS.EMAIL_SUBSCRIPTION);
	};

	goToAllWatchlists = () => {
		this.history.push(APP_URLS.WATCHLISTS);
		localStorageSrv.removeItem(LOCAL_STORAGE_KEYS.LATEST_PORTFOLIO);
	};

	goToDocument = (flowId: number, documentId: number) => {
		const route = `/document?flowId=${flowId}&documentId=${documentId}`;
		this.history.push(route);
	};

	goToSubscriptionDocument = (
		subscriptionId: string,
		documentId: number | string,
		documentTypePath: string
	) => {
		const route = `/subscription/${subscriptionId}/${documentTypePath}/document/${documentId}`;
		this.history.push(route);
	};

	goToAnalytics = () => {
		const route = `/analytics`;
		this.history.push(route);
	};

	goToDashboard = (dashboardId: string) => {
		const route = `/analytics/dashboard/${dashboardId}`;
		this.history.push(route);
	};

	goToRoot = () => {
		this.globalStore.setCurrentTab(TABS.WATCHLIST);
		this.dataStore.documentCleanUp();
		this.dataStore.clearTranscriptEvents();
		this.dataStore.documentMetaDataCleanUp();

		const { portfolioId, username } = localStorageSrv.getObject(
			LOCAL_STORAGE_KEYS.LATEST_PORTFOLIO
		) || { portfolioId: undefined, username: undefined };
		if (portfolioId && username === this.authStore.profile.username) {
			this.goToWatchlist(portfolioId);
			return;
		}
		this.history.push(APP_URLS.ROOT);
	};

	goToWatchlist = (watchlistId: number | string) => {
		const route = `/watchlist/${watchlistId}`;
		const { username } = (this.authStore && this.authStore.profile) || { username: '' };
		this.history.push(route);
		localStorageSrv.setObject(LOCAL_STORAGE_KEYS.LATEST_PORTFOLIO, {
			portfolioId: watchlistId,
			username
		});
	};

	goToBackoffice = () => {
		this.history.push(APP_URLS.BACKOFFICE);
		this.globalStore.setCurrentTab(-1);
	};

	goToPrintArticle = (
		flowId: number,
		modelId: number,
		documentId: number,
		eventId: string,
		showNeutralExtractions: boolean
	) => {
		const subRoute = `news/${documentId}/print`;
		if (modelId) {
			window.open(`${window.location.origin}/models/${modelId}/${subRoute}`);
			return;
		}
		window.open(`${window.location.origin}/flows/${flowId}/${subRoute}`);
	};

	goToPrintTranscript = (subscriptionId: string, documentId: string) => {
		window.open(
			`${window.location.origin}/subscription/${subscriptionId}/transcript/document/${documentId}/print_transcript`
		);
	};

	goToPlayground = () => {
		this.history.push(APP_URLS.PLAYGROUND);
		this.globalStore.setCurrentTab(-1);
	};

	openSupportPage = () => {
		window.open('https://help.amenityanalytics.com/en/', '_blank');
	};
}
