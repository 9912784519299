import React from 'react';
import { EVENT_TOOLTIP_ID } from '../../../constants';
import HighlightTooltip from '../../document/highlightTooltip';
import * as ids from '../../../../id.constants';
import ReactTooltip from 'react-tooltip';

const DocumentTooltip = () => (
	<ReactTooltip
		id={EVENT_TOOLTIP_ID}
		className='customeTheme'
		place='bottom'
		type='light'
		offset={{ bottom: 3, right: 0 }}
		getContent={dataTip => (
			<HighlightTooltip id={ids.DOCUMENT_PAGE.EVENT_HOVER} title={dataTip} showActions={false} />
		)}
	/>
);

export default DocumentTooltip;
