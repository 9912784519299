import React from 'react';
import styled from 'styled-components';

import { Polarity } from '../shared/types';
import { polarityColor } from '../shared/util';

interface PolarityDotProps {
	polarity?: Polarity;
	isSubsection?: boolean;
}

const StyledPolarityDot = styled.div<PolarityDotProps>`
	height: 10px;
	width: 10px;
	border-radius: 50%;
	background-color: ${({ polarity, isSubsection }) => polarityColor(polarity, { isSubsection })};
`;

export const PolarityDot: React.FunctionComponent<PolarityDotProps> = ({
	polarity,
	isSubsection
}) => <StyledPolarityDot polarity={polarity} isSubsection={isSubsection} />;
