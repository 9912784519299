import * as _ from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import Images from '../../shared/images';
import DataSrv from '../../services/data.service';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GenericPopup from '../../widgets/GenericPopup/genericPopup';

const Container = styled.div`
	height: calc(100% - 50px);
`;

const UpperSection = styled.div`
	height: 90px;
	padding: 15px;
	margin-bottom: 20px;
	box-sizing: border-box;
	border-bottom: 1px solid #e0e7e9;
`;

const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	height: calc(100% - 90px);
`;

const LeftHeading = styled.div`
	width: 180px;
	padding: 19px 0 0 0;
	box-sizing: border-box;
	margin-left: 10px;
	color: #32313b;
	font-family: Assistant;
	font-size: 20px;
	font-weight: 600;
`;

const SettingsIcon = styled.div`
	float: left;
	width: 24px;
	height: 24px;
	margin-right: 8px;
	background-image: url('${Images.devIcon}');
	background-size: contain;
`;

const ConfigContainer = styled.div`
	width: 900px;
	padding: 10px 25px;
`;

const MultiConfigContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 900px;
	padding: 10px 25px;
`;

const ConfigContent = styled.div`
	display: flex;
	align-items: center;
	min-height: 98px;
	border-radius: 6px;
	box-sizing: border-box;
	background-color: #ffffff;
	box-shadow: 0 1px 4px 0 rgba(50, 49, 59, 0.32);
`;

const ColumnContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 20px;
`;

const RowsContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 20px;
`;

const ConfigTitle = styled.div`
	color: #32313b;
	font-family: Assistant;
	font-size: 16px;
	font-weight: 600;
	box-sizing: border-box;
	margin-bottom: 5px;
`;

const ButtonContainer = styled.div`
	height: 45px;
`;

const Row = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	margin-bottom: 10px;
`;

const Config = styled.div`
	display: flex;
	flex-direction: column;
`;

interface ApiTokensInfo {
	domain: string;
	api_tokens: {
		dev: {
			client_id: string;
			token: string;
		};
		stag: {
			client_id: string;
			token: string;
		};
		prod: {
			client_id: string;
			token: string;
		};
	};
}

interface DevelopmentPageProps {
	env: string;
}

interface DevelopmentPageState {
	apiConfig: string;
	apiTokens: ApiTokensInfo;
	confirmPopUp: boolean;
	saveSetting: string;
}

class DevelopmentPage extends React.Component<DevelopmentPageProps, DevelopmentPageState> {
	constructor(props: DevelopmentPageProps) {
		super(props);

		this.state = {
			apiConfig: 'dev',
			apiTokens: undefined,
			confirmPopUp: false,
			saveSetting: ''
		};
	}

	componentDidMount() {
		const ds = new DataSrv();
		ds.getDevConfigurations(
			{},
			response => {
				console.log('response ? ', response);
				this.setState({
					apiConfig: response.API_ENV,
					apiTokens: response.API_TOKENS
				});
			},
			err => console.error(err)
		);
	}

	handleChange = (event: any) => {
		const { name, value }: any = event.target;
		const newState = { ...this.state, [name]: value };
		this.setState(newState);
	};

	handleTokenChange = (env: string, event: any) => {
		const { name, value }: any = event.target;
		const apiTokens = { ...this.state.apiTokens };
		apiTokens.api_tokens[env] = {
			...this.state.apiTokens.api_tokens[env],
			[name]: value
		};
		this.setState({ apiTokens });
	};

	handleDomainChange = (event: any) => {
		const apiTokens = { ...this.state.apiTokens };
		apiTokens.domain = event.target.value;
		this.setState({ apiTokens });
	};

	save = (config: string) => {
		this.toggleConfirmPopup();
		this.setState({ saveSetting: config });
	};

	onConfirm = () => {
		this.toggleConfirmPopup();
		const ds = new DataSrv();
		const params = {
			configName: this.state.saveSetting,
			data: this.state[this.state.saveSetting]
		};
		ds.saveDevConfig(
			params,
			() => console.log('success'),
			err => console.error('ERROR: ', err)
		);
	};

	toggleConfirmPopup = () => this.setState({ confirmPopUp: !this.state.confirmPopUp });

	renderApiTokens = (apiToken: ApiTokensInfo, opt: string) => (
		<RowsContainer>
			<Row key='API_TOKEN_DOMAIN'>
				<TextField
					id='outlined-multiline-static'
					defaultValue={apiToken.domain}
					margin='dense'
					variant='outlined'
					label='Domain'
					name='domain'
					inputProps={{
						style: { textAlign: 'center' }
					}}
					onChange={this.handleDomainChange}
				/>
			</Row>
			{_.map(apiToken.api_tokens, (a, key) => (
				<Row key={`API_TOKEN_${key}`}>
					<FormLabel style={{ width: '75px' }}>{_.upperFirst(key)} env:</FormLabel>
					<TextField
						multiline
						id='outlined-multiline-static'
						defaultValue={a.client_id}
						rows='1'
						margin='dense'
						variant='outlined'
						label='Client id'
						name='client_id'
						onChange={ev => this.handleTokenChange(key, ev)}
					/>
					<TextField
						style={{ width: '500px' }}
						multiline
						id='outlined-multiline-static'
						defaultValue={a.token}
						rows='1'
						margin='dense'
						variant='outlined'
						label='Token'
						name='token'
						onChange={ev => this.handleTokenChange(key, ev)}
					/>
				</Row>
			))}
			<ButtonContainer>
				<Button variant='contained' color='primary' onClick={() => this.save(opt)}>
					Save
				</Button>
			</ButtonContainer>
		</RowsContainer>
	);

	renderApiRadioBtns = (arr: string[], opt: string) => (
		<ColumnContainer>
			<RadioGroup
				aria-label='position'
				name={opt}
				value={this.state[opt]}
				onChange={this.handleChange}
				row
			>
				{_.map(arr, o => (
					<FormControlLabel
						key={`${opt}_${o}`}
						value={o}
						control={<Radio color='primary' />}
						label={_.upperFirst(o)}
						labelPlacement='top'
					/>
				))}
			</RadioGroup>
			<ButtonContainer>
				<Button variant='contained' color='primary' onClick={() => this.save(opt)}>
					Save
				</Button>
			</ButtonContainer>
		</ColumnContainer>
	);

	render() {
		const { apiTokens } = this.state;
		const apiArr = ['dev', 'stage', 'prod'];

		console.log('state ? ', this.state);
		return (
			<Container>
				<UpperSection>
					<LeftHeading>
						<SettingsIcon />
						Development
					</LeftHeading>
				</UpperSection>
				<ContentContainer>
					<ConfigContainer>
						<ConfigTitle>Api Tokens</ConfigTitle>
						<ConfigContent>
							{apiTokens && this.renderApiTokens(apiTokens, 'apiTokens')}
						</ConfigContent>
					</ConfigContainer>
					<MultiConfigContainer>
						<Config>
							<ConfigTitle>Amenity Api</ConfigTitle>
							<ConfigContent>{this.renderApiRadioBtns(apiArr, 'apiConfig')}</ConfigContent>
						</Config>
					</MultiConfigContainer>
					<GenericPopup
						isOpen={this.state.confirmPopUp}
						message={`CAUTION, ${_.toUpper(
							this.props.env
						)} environment!!!\n You want to save/change ${
							this.state.saveSetting
						}, are you sure you want to make the change? `}
						acceptButtonText={'JUST DO IT!'}
						acceptCallback={() => this.onConfirm()}
						dismissButtonText={'CANCEL'}
						dismissCallback={() => this.toggleConfirmPopup()}
						clickOutsideCallback={() => this.toggleConfirmPopup()}
						width={550}
					/>
				</ContentContainer>
			</Container>
		);
	}
}

export default DevelopmentPage;
