import * as React from 'react';
import { getString } from '../../services/localstorage.service';
import { LOCAL_STORAGE_KEYS } from '../../constants';
import { GRAPHQL_SERVER_REMOTE_URL } from '../../config';
import { FunctionComponent, ReactElement, useState } from 'react';
import {
	ApolloProvider as ApolloHooksProvider,
	ApolloClient,
	InMemoryCache,
	ApolloLink,
	createHttpLink,
	from
} from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import { useEffect } from 'react';
import { logInfo } from '../../logger';

interface GraphQlWrapperProps {
	children: ReactElement;
}

export const GraphQlWrapper: FunctionComponent<GraphQlWrapperProps> = ({ children }) => {
	const [client, setClient] = useState();
	useEffect(() => {
		const jwtToken = getString(LOCAL_STORAGE_KEYS.TOKEN);

		const httpLink = createHttpLink({
			uri: GRAPHQL_SERVER_REMOTE_URL(),
			headers: {
				Authorization: `Bearer ${jwtToken}`,
				'x-amenity-source': 'web'
			}
		});
		const consoleLink = new ApolloLink((operation, forward) => {
			const logContextId = Math.floor(Math.random() * 1000000); // random id for the log context
			const logContext = { name: operation.operationName, id: logContextId };
			const userEmail = getString(LOCAL_STORAGE_KEYS.USERNAME);
			operation.setContext({ start: new Date(), user: userEmail });
			return forward(operation).map(data => {
				// @ts-ignore
				const time = new Date() - operation.getContext().start;
				const ctxHeader = operation.getContext().response.headers.get('x-amenity-ctx');
				logInfo(
					`Operation ${operation.operationName} ctx ${ctxHeader} for user ${
						operation.getContext().user
					} took ${time} ms to complete`,
					logContext
				);
				return data;
			});
		});

		const client = new ApolloClient({
			uri: GRAPHQL_SERVER_REMOTE_URL(),
			headers: {
				Authorization: `Bearer ${jwtToken}`,
				'x-amenity-source': 'web'
			},
			cache: new InMemoryCache({
				typePolicies: {
					Query: {
						fields: {
							// causes different data on org subscriptions to update the cache correctly
							organizationSubscription: {
								merge(existing, incoming) {
									return { ...existing, ...incoming };
								}
							}
						}
					}
				}
			}),
			link: from([consoleLink, (httpLink as unknown) as ApolloLink])
		});
		// @ts-ignore
		setClient(client);
	}, []);

	if (!client) {
		return <div />;
	}

	return (
		<ApolloHooksProvider client={client}>
			<ApolloProvider client={client}>{children}</ApolloProvider>
		</ApolloHooksProvider>
	);
};
