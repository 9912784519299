import * as React from 'react';
import * as History from 'history';
import { syncHistoryWithStore } from 'mobx-react-router';
import { STORE_ROUTER } from '../../constants';
import { useRecoilState } from 'recoil';
import userAttributesState from '../../recoil/userAttributes';
import store from '../../stores/store';
import AppContainer from './appContainer';
import { FunctionComponent } from 'react';

const browserHistory = History.createBrowserHistory();
const history = store ? syncHistoryWithStore(browserHistory, store[STORE_ROUTER]) : {};

export const AppContainerWrapper: FunctionComponent = () => {
	const [userAttributes, setUserAttributes] = useRecoilState(userAttributesState);

	return (
		<AppContainer
			history={history}
			userAttributes={userAttributes}
			setUserAttributes={setUserAttributes}
		/>
	);
};
