import * as React from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { STORE_AUTH, STORE_DATA, STORE_GLOBAL, FEATURES_TYPE_SPLIT } from '../../constants';
import AuthStore from '../../stores/auth.store';
import DataStore from '../../stores/data.store';
import GlobalStore from '../../stores/global.store';
import NavigationService from '../../services/navigation.service';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { Colors } from '../../../components/shared/styles';
import { HelpCenter } from './HelpCenter';
import ProfileMenu from './ProfileMenu';
import { MenuWrapper } from 'components/MenuWrapper/MenuWrapper';
import * as ids from '../../../id.constants';
import { DropdownOption } from '../../types/types';
import { useFeature } from '../../hooks/useFeature';

const Container = styled.div`
	display: grid;
	grid-template-columns: 101px 1px auto;
	grid-gap: 29px;
	align-items: center;
`;

const Separator = styled.div`
	height: 19px;
	width: 1px;
	background-color: ${Colors.white};
`;

enum MenuOption {
	Admin,
	ApiExplorer,
	Logout
}

interface Props {
	[STORE_AUTH]?: AuthStore;
	[STORE_DATA]?: DataStore;
	[STORE_GLOBAL]?: GlobalStore;
}

const NewHeader: React.FunctionComponent<Props> = (props: Props) => {
	const navService = NavigationService.instance;
	const ffAllowPlayground = useFeature(FEATURES_TYPE_SPLIT.VIEWER_PLAYGROUND);

	const clearAll = () => {
		props[STORE_DATA].clearAll();
	};

	const navigateToHome = () => {
		clearAll();
		navService.goToRoot();
	};

	const navigateToBackoffice = () => {
		clearAll();
		navService.goToBackoffice();
	};

	const navigateToPlayground = () => {
		clearAll();
		navService.goToPlayground();
	};

	const logout = () => {
		props[STORE_AUTH].logout();
	};

	const buildMenuOptions = (isAdmin: boolean, ffAllowPlayground: boolean): DropdownOption[] => {
		const options = [];
		if (isAdmin) {
			options.push({
				label: 'Admin',
				id: MenuOption.Admin,
				elementId: ids.USER_MENU_ID.BACKOFFICE
			});
		}
		if (ffAllowPlayground) {
			options.push({
				label: 'API Explorer',
				id: MenuOption.ApiExplorer,
				elementId: ids.USER_MENU_ID.PLAYGROUND_TOP_RIGHT_MENU
			});
		}
		options.push({
			label: 'Log out',
			id: MenuOption.Logout,
			elementId: ids.USER_MENU_ID.LOGOUT
		});
		return options;
	};

	const actionsMap = {
		[MenuOption.Admin]: navigateToBackoffice,
		[MenuOption.ApiExplorer]: navigateToPlayground,
		[MenuOption.Logout]: logout
	};

	const onMenuSelect = (id: number | string): void => {
		actionsMap[id]();
	};

	const isAdmin = props[STORE_AUTH].isAdmin;

	return (
		<PageHeader onLogoClick={navigateToHome}>
			<Container>
				<HelpCenter />
				<Separator />
				<MenuWrapper
					options={buildMenuOptions(isAdmin, ffAllowPlayground)}
					onSelect={onMenuSelect}
					menuPosition={{ top: 36 }}
				>
					<ProfileMenu authStore={props[STORE_AUTH]} />
				</MenuWrapper>
			</Container>
		</PageHeader>
	);
};

export default inject(STORE_DATA, STORE_GLOBAL, STORE_AUTH)(observer(NewHeader));
