import * as React from 'react';
import * as _ from 'lodash';
import { inject, observer } from 'mobx-react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import BackOfficeStore from '../../stores/backOffice.store';
import Images from '../../shared/images';
import styled from 'styled-components';
import { STORE_BACK_OFFICE } from '../../constants';
import { Collection } from '../../types/types';
import { withStyles } from '@material-ui/core/styles';
import AddOrUpdateCollectionPopup from '../../components/BackofficePopups/addOrUpdateCollectionPopUp';
import DeletePopup from '../../components/BackofficePopups/backOfficeDeletePopUp';

const TableCell = styled.td`
	overflow: hidden;
	max-width: 305px;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: rgba(0, 0, 0, 0.87);
	font-size: 0.8125rem;
	display: table-cell;
	padding: 4px 56px 4px 24px;
	text-align: left;
	border-bottom: 1px solid rgba(224, 224, 224);
	vertical-align: inherit;
`;

const WrapTable = styled.div`
	margin: 20px;
`;

const ButtonWrap = styled.div`
	display: inline;
	float: right;
`;

const PageHeader = styled.div`
	margin: 20px;
`;

const PageTitle = styled.div`
	font-size: 16px;
	padding-top: 30px;
	padding-left: 22px;
`;

const Input = styled.input`
	height: 25px;
	width: 170px;
	padding-left: 10px;
`;

const Icons = styled.img`
	cursor: pointer;
`;

const styles = {
	paper: {
		maxHeight: '680px',
		overflow: 'auto'
	}
};

interface CollectionsTabProps {
	classes: any;
}

interface CollectionsTabState {
	isAddPopupOpen: boolean;
	isEditPopupOpen: boolean;
	isDeletePopupOpen: boolean;
	currentCollection: Collection;
	deletedId: number;
	deletedName: string;
	searchText: string;
	filteredCollection: Collection[];
}

@inject(STORE_BACK_OFFICE)
@observer
class CollectionsTab extends React.Component<CollectionsTabProps, CollectionsTabState> {
	constructor(props: CollectionsTabProps) {
		super(props);
		this.state = {
			isAddPopupOpen: false,
			isEditPopupOpen: false,
			isDeletePopupOpen: false,
			deletedId: -1,
			deletedName: '',
			searchText: '',
			currentCollection: this.getEmptyCollection(),
			filteredCollection: []
		};
		(this.props[STORE_BACK_OFFICE] as BackOfficeStore).getAllCollections();
	}

	closeAddPopup = () => {
		this.setState({ isAddPopupOpen: false });
	};

	closeEditPopup = () => {
		this.setState({ isEditPopupOpen: false });
	};

	closeDeletePopup = () => {
		this.setState({ isDeletePopupOpen: false });
	};

	deleteCollection = (id: number, name: string) => {
		this.setState({
			isDeletePopupOpen: true,
			deletedId: id,
			deletedName: name
		});
	};

	openAddPopup = () => {
		this.setState({ isAddPopupOpen: true });
	};

	openEditPopup = (id: number, name: string) => {
		const currentCollectionCopy = { ...this.state.currentCollection };
		currentCollectionCopy.id = id;
		currentCollectionCopy.name = name;
		this.setState({
			isEditPopupOpen: true,
			currentCollection: currentCollectionCopy
		});
	};

	getEmptyCollection = (): Collection => {
		return {
			id: 0,
			name: ''
		};
	};

	handleKeyPress = event => {
		this.setState({ searchText: event.target.value });
	};

	deleteCallback = () => {
		(this.props[STORE_BACK_OFFICE] as BackOfficeStore).deleteCollection(this.state.deletedId);
		this.setState({ deletedId: -1 });
	};

	addCallback = (collection: Collection) => {
		(this.props[STORE_BACK_OFFICE] as BackOfficeStore).addCollection(collection);
		this.closeAddPopup();
	};

	updateCallback = (collection: Collection) => {
		(this.props[STORE_BACK_OFFICE] as BackOfficeStore).updateCollection(collection);
		this.closeAddPopup();
	};

	renderUpperSection = () => {
		return (
			<div>
				<PageTitle>Collections</PageTitle>
				<PageHeader>
					<Input
						type='text'
						placeholder='Search'
						onChange={e => this.handleKeyPress(e)}
						id='BACKOFFICE_COLLECTIONS_SEARCH_BAR'
					/>
					<ButtonWrap>
						<Button
							variant='contained'
							size='large'
							style={{
								backgroundColor: '#21a6f2',
								color: '#fff',
								boxShadow: 'none'
							}}
							onClick={() => this.openAddPopup()}
							id='BACKOFFICE_COLLECTIONS_NEW_COLLECTION_BUTTON'
						>
							New Collection
						</Button>
					</ButtonWrap>
				</PageHeader>
			</div>
		);
	};

	renderTableBody = (filteredCollections: Collection[]) => {
		return (
			<TableBody id='COLLECTIONS_TABLE'>
				{_.map(filteredCollections, (collection, index) => {
					return (
						<TableRow key={collection.id}>
							<TableCell>{collection.id}</TableCell>
							<TableCell>{collection.name}</TableCell>
							<TableCell>
								<Icons
									src={Images.deleteTranscript}
									alt='Delete Organization'
									onClick={() => this.deleteCollection(collection.id, collection.name)}
									id={`BACKOFFICE_COLLECTIONS_DELETE_COLLECTION_ROW_${index}`}
								/>
							</TableCell>
							<TableCell>
								<Icons
									src={Images.editIcon}
									onClick={() => this.openEditPopup(collection.id, collection.name)}
									id={`BACKOFFICE_COLLECTIONS_EDIT_COLLECTION_ROW_${index}`}
								/>
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		);
	};

	renderTable = filteredCollections => {
		return (
			<WrapTable>
				<Paper className={this.props.classes.paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Id</TableCell>
								<TableCell>Collection Name</TableCell>
								<TableCell />
								<TableCell />
							</TableRow>
						</TableHead>
						{this.renderTableBody(filteredCollections)}
					</Table>
				</Paper>
			</WrapTable>
		);
	};

	render() {
		const { collections } = this.props[STORE_BACK_OFFICE] as BackOfficeStore;
		const { isAddPopupOpen, isEditPopupOpen, currentCollection, isDeletePopupOpen } = this.state;
		const searchTerm = this.state.searchText.toLowerCase();
		const filteredCollections = _.filter(
			_.orderBy(collections, 'id'),
			item => item.name.toLowerCase().indexOf(searchTerm) !== -1
		);
		return (
			<div>
				{this.renderUpperSection()}
				{this.renderTable(filteredCollections)}
				{isAddPopupOpen && (
					<AddOrUpdateCollectionPopup
						type={0}
						modalIsOpen={isAddPopupOpen}
						closePopup={() => this.closeAddPopup()}
						onConfirmFunc={this.addCallback}
						collections={collections}
					/>
				)}
				{isEditPopupOpen && (
					<AddOrUpdateCollectionPopup
						type={1}
						modalIsOpen={isEditPopupOpen}
						closePopup={() => this.closeEditPopup()}
						collection={currentCollection}
						onConfirmFunc={this.updateCallback}
						collections={collections}
					/>
				)}
				{isDeletePopupOpen && (
					<DeletePopup
						modalIsOpen={isDeletePopupOpen}
						closePopup={() => this.closeDeletePopup()}
						deleteFunc={this.deleteCallback}
						name={'collection'}
					/>
				)}
			</div>
		);
	}
}

export default withStyles(styles)(CollectionsTab);
