import { WEB_SERVER_URL, GRAPHQL_SERVER_URL } from './constants';

export const SERVER_REMOTE_URL = (): string => {
	// @ts-ignore
	const env = process.env.REACT_APP_CLIENT_ENV;
	let remote = '';
	switch (env) {
		case 'prod':
			remote = WEB_SERVER_URL.PROD;
			break;
		case 'stage':
			remote = WEB_SERVER_URL.STAGE;
			break;
		case 'dev':
			remote = WEB_SERVER_URL.DEV;
			break;
		default:
			remote = WEB_SERVER_URL.PROD;
	}
	return remote;
};

export const GRAPHQL_SERVER_REMOTE_URL = (): string => {
	// @ts-ignore
	const env = process.env.REACT_APP_CLIENT_ENV;
	let remote = '';
	switch (env) {
		case 'prod':
			remote = GRAPHQL_SERVER_URL.PROD;
			break;
		case 'stage':
			remote = GRAPHQL_SERVER_URL.STAGE;
			break;
		case 'dev':
			remote = GRAPHQL_SERVER_URL.DEV;
			break;
		default:
			remote = GRAPHQL_SERVER_URL.PROD;
	}
	return remote;
};
