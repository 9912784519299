import * as React from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';
import { Collection } from '../../types/types';
import Images from '../../shared/images';
import Modal from 'react-modal';
import ModalStyles from '../../shared/styled/general-modal-style';

const PopTitle = styled.div`
	color: #ffffff;
	margin-left: 40px;
	padding-top: 20px;
	font-weight: 600;
`;

const PopUpWarpTitle = styled.div`
	background-color: #586876;
	height: 60px;
	width: 100%;
	top: 0;
	left: 0;
`;

const InputTitle = styled.div`
	height: 24px;
	color: #4a4a4a;
	font-size: 13px;
	line-height: 24px;
	text-align: left;
	margin: 20px auto;
	width: 100%;
`;

const SubmitWrap = styled.div`
	position: absolute;
	right: 63px;
	bottom: 20px;
	width: 250px;
	font-size: 13px;
	font-weight: 600;
	height: 32px;
`;

interface ConfirmButtonProps {
	isConfirm: boolean;
}
const ConfirmButton = styled.button<ConfirmButtonProps>`
	width: 85px;
	height: 30px;
	float: right;
	margin-left: ${props => (props.isConfirm ? '10px' : '0')};
	font-size: 13px;
	color: ${props => (props.isConfirm ? '#fff' : '#696868')};
	background-color: ${props => (props.isConfirm ? '#00aeef' : '#ccc')};
	cursor: pointer;
`;

const ClosePopUp = styled.div`
	background-image: url("${Images.close}");
	position: absolute;
	right: -1px;
	top: 2px;
	width: 43px;
	height: 43px;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
  background-size: 13px;
  z-index:1;
`;

const Input = styled.input`
	width: 340px;
	border: 1px solid #ccc;
	padding: 5px;
`;

const InputWrap = styled.div`
	margin-top: 35px;
	margin-left: 20px;
`;

const ErrorSpan = styled.div`
	color: red;
	font-size: 10px;
`;

interface AddOrUpdateCollectionPopUpProps {
	type: number;
	modalIsOpen: boolean;
	closePopup: Function;
	collection?: Collection;
	onConfirmFunc: Function;
	collections: Collection[];
}

interface AddOrUpdateCollectionPopUpState {
	collectionName: string;
	currentCollection: Collection;
	isErr: boolean;
	errMsg: string;
}

export default class AddOrUpdateCollectionPopUp extends React.Component<
	AddOrUpdateCollectionPopUpProps,
	AddOrUpdateCollectionPopUpState
> {
	constructor(props: AddOrUpdateCollectionPopUpProps) {
		super(props);
		Modal.setAppElement('#root');
		if (this.props.type === 1) {
			this.state = {
				currentCollection: this.props.collection,
				collectionName: this.props.collection.name,
				isErr: false,
				errMsg: ''
			};
		} else if (this.props.type === 0) {
			this.state = {
				currentCollection: undefined,
				collectionName: '',
				isErr: false,
				errMsg: ''
			};
		}
	}

	updateNameInput = evt => {
		this.setState({ collectionName: evt.target.value });
	};

	addNewCollection = () => {
		this.setState({ isErr: false });
		if (!this.verifyInput()) {
			return;
		}

		const newCollection = {
			name: this.state.collectionName.trim()
		};
		this.props.onConfirmFunc(newCollection);
		this.close();
	};

	editCollection = () => {
		this.setState({ isErr: false });
		if (!this.verifyInput()) {
			return;
		}

		const editColelction = {
			id: this.state.currentCollection.id,
			name: this.state.collectionName.trim()
		};
		this.props.onConfirmFunc(editColelction);
		this.close();
	};

	close = () => {
		this.props.closePopup();
	};

	verifyInput = (): boolean => {
		let collections = _.map(this.props.collections, c => c.name);
		// If this is update process - don't check vs. original domain name
		if (this.props.type === 1) {
			collections = _.filter(collections, d => d !== this.props.collection.name);
		}
		const trimmedCollectionName = this.state.collectionName.trim();
		if (_.find(collections, c => c.toLowerCase() === trimmedCollectionName.toLowerCase())) {
			this.setState({ isErr: true, errMsg: 'Collection already exists' });
			return false;
		}
		if (trimmedCollectionName === '') {
			this.setState({ isErr: true, errMsg: 'Collection cannot be empty' });
			return false;
		}
		return true;
	};

	getTitle = () => {
		if (this.props.type === 0) {
			return 'Add Collection';
		} else if (this.props.type === 1) {
			return 'Edit Collection';
		}
	};

	saveCollection = () => {
		if (this.props.type === 0) {
			this.addNewCollection();
		} else if (this.props.type === 1) {
			this.editCollection();
		}
	};

	render() {
		const { isErr, errMsg } = this.state;
		return (
			<Modal
				isOpen={this.props.modalIsOpen}
				onRequestClose={() => this.close()}
				style={ModalStyles(430, 350)}
			>
				<ClosePopUp
					onClick={() => this.close()}
					id='BACKOFFICE_COLLECTIONS_ADDEDIT_MODAL_CLOSE_BUTTON'
				/>
				<PopUpWarpTitle>
					<PopTitle>{this.getTitle()}</PopTitle>
				</PopUpWarpTitle>
				<InputWrap>
					<InputTitle>Collection name:</InputTitle>
					<Input
						type='text'
						value={this.state.collectionName}
						onChange={this.updateNameInput}
						id='BACKOFFICE_COLLECTIONS_ADDEDIT_MODAL_COLLECTION_NAME_FIELD'
					/>
					{isErr && <ErrorSpan id='ERROR_ADDING_COLLECTION'>{errMsg}</ErrorSpan>}
				</InputWrap>
				<SubmitWrap>
					<ConfirmButton
						isConfirm
						onClick={() => this.saveCollection()}
						id='BACKOFFICE_COLLECTIONS_ADDEDIT_MODAL_SAVE_BUTTON'
					>
						Save
					</ConfirmButton>
					<ConfirmButton
						isConfirm={false}
						onClick={() => this.close()}
						id='BACKOFFICE_COLLECTIONS_ADDEDIT_MODAL_CANCEL_BUTTON'
					>
						Cancel
					</ConfirmButton>
				</SubmitWrap>
			</Modal>
		);
	}
}
