import * as React from 'react';

import { Text } from '../../../components/Text/Text';
import { Link } from '../../../components/Link/Link';
import { Colors, FontSizes } from '../../../components/shared/styles';

interface Props {
	title: string;
	source: string;
	sourceUrl?: string;
}

const ArticleTitle: React.FunctionComponent<Props> = ({ title, source, sourceUrl }) => {
	return (
		<>
			<Text block ellipsis color={Colors.black} size={FontSizes.s4} lineHeight={24}>
				{title}
			</Text>
			<Text block color={Colors.darkGray} size={FontSizes.s3} lineHeight={24}>
				Published by:{'\u00A0'}
				{sourceUrl ? (
					<Link href={sourceUrl}>{source}</Link>
				) : (
					<Text color={Colors.darkGray} size={FontSizes.s3}>
						{source}
					</Text>
				)}
			</Text>
		</>
	);
};

export default ArticleTitle;
