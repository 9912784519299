import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { Colors, LogoAppearance } from '../shared/styles';
import { Logo } from '../Logo/Logo';

const StyledHeader = styled.header`
	display: grid;
	align-items: center;
	grid-template-columns: 335px auto;
	height: 54px;
	padding: 0 16px;
	background-color: ${Colors.dark};
`;

const StyledChildren = styled.div`
	display: grid;
	justify-content: right;
`;

export interface PageHeaderProps {
	onLogoClick?(): void;
	children?: ReactNode;
}

export const PageHeader: FunctionComponent<PageHeaderProps> = ({ onLogoClick, children }) => (
	<StyledHeader>
		<Logo logoAppearance={LogoAppearance.LightGroup} onClick={onLogoClick}></Logo>
		<StyledChildren>{children}</StyledChildren>
	</StyledHeader>
);
