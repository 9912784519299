import React from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';

import * as ids from '../../../id.constants.js';
import { stringToArraySplit } from '../Transcript/transcript.util';
import { EventItem } from '../../../components/EventItem/EventItem';
import { Event } from '../../types/types';

interface EventsContainerStyledProps {
	show: boolean;
}

const EventsContainerStyled = styled.div<EventsContainerStyledProps>`
	opacity: ${props => (props.show ? 1 : 0)};
	transition: opacity 0.5s;
	margin-top: 1px;
`;

interface Props {
	show: boolean;
	events: Event[];
	categoryId: number;
	selectedEventId: number | string;
	showFullSentence: boolean;
	showTag: boolean;
	onEventSelection: Function;
}

const EventsContainer: React.FunctionComponent<Props> = ({
	show,
	events,
	categoryId,
	selectedEventId,
	showFullSentence,
	showTag,
	onEventSelection
}) => {
	const mapEventsListToElements = (
		show: boolean,
		events: Event[],
		categoryId: number,
		selectedEventId: number | string,
		showFullSentence: boolean,
		showTag: boolean
	): any[] =>
		show &&
		_.map(events, (event, index) => {
			const { isSubsection, section } = event;
			const showSection = isSubsection && section && section.length > 0;
			const eventTypeName = event.typeName || event.name || 'Annotation';

			if (event.id === undefined) {
				event.id = index;
			}

			const key = `event-${event.id}-category-${categoryId}`;

			const splitSentence =
				showFullSentence && stringToArraySplit(event.sentence, [event], 0, 0, false)?.res;

			const isSelected = selectedEventId === event.id;
			return (
				<EventItem
					key={key}
					event={event}
					index={index}
					isLastChild={index === events.length - 1}
					isSelected={isSelected}
					eventTypeName={eventTypeName}
					showSection={showSection}
					showFullSentence={showFullSentence}
					showTag={showTag}
					isSubsection={isSubsection}
					onEventSelection={onEventSelection}
					splitSentence={splitSentence}
					tagId={ids.DOCUMENT_PAGE.TITLE_EVENT}
				/>
			);
		});

	return (
		<EventsContainerStyled id='DOCUMENT_PAGE_EVENTS_CONTAINER' show={show}>
			{mapEventsListToElements(
				show,
				events,
				categoryId,
				selectedEventId,
				showFullSentence,
				showTag
			)}
		</EventsContainerStyled>
	);
};

export default EventsContainer;
