import React, { FC } from 'react';
import styled from 'styled-components';

import Images from '../../../shared/images';
import { SEE_NEWER_VERSION_BANNER } from '../../../../id.constants';

const Container = styled.div<{ show: boolean }>`
	display: ${props => (props.show ? 'flex' : 'none')};
	position: fixed;
	align-items: center;
	top: 21px;
	left: 50%;
	transform: translate(-50%, 0);
	height: 70px;
	width: 493px;
	padding: 12px 0 12px 23px;
	box-sizing: border-box;
	border: 1px solid #dadada;
	background-color: #fff;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
	font-size: 14px;
	font-family: Assistant SemiBold;
`;

const CloseIcon = styled.img.attrs({
	src: Images.closeBtnBlack
})`
	position: absolute;
	padding: 5px
	right: 6px;
	top: 11px;
	height: 8px;
	width: 8px;
	cursor: pointer;
`;

const TextContainer = styled.div`
	margin-left: 19px;
	width: 90%;
`;

interface SeeNewVersionBannerProps {
	close: () => void;
	show: boolean;
}

const SeeNewVersionBanner: FC<SeeNewVersionBannerProps> = ({ close, show }) => {
	return (
		<Container show={show} id={SEE_NEWER_VERSION_BANNER.CONTAINER}>
			<TextContainer>
				You have clicked on an event present in an older version of a transcript. This is an updated
				version of the transcript and the event is no longer available.
			</TextContainer>
			<CloseIcon id={SEE_NEWER_VERSION_BANNER.CLOSE_BTN} onClick={close} />
		</Container>
	);
};

export default SeeNewVersionBanner;
