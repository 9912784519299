import React from 'react';
import styled from 'styled-components';

import Header from '../Header/header';
// import Spinner from '../../widgets/Spinner/spinner';
import {
	SIDE_BAR_OPEN_WIDTH,
	SIDE_BAR_CLOSE_WIDTH,
	HEADER_HEIGHT
} from '../../pages/layoutElements';
import SideBar from '../SideBar/SideBar';

interface IsSideBarOpenProps {
	isSideBarOpen: boolean;
}

const SideBarContainer = styled.div<IsSideBarOpenProps>`
	float: left;
	height: calc(100% - ${HEADER_HEIGHT}px);
	width: ${props => (props.isSideBarOpen ? `${SIDE_BAR_OPEN_WIDTH}` : `${SIDE_BAR_CLOSE_WIDTH}`)}px;
`;

const Body = styled.div<IsSideBarOpenProps>`
	float: left;
	height: calc(100% - ${HEADER_HEIGHT}px);
	width: calc(
		100% -
			${props => (props.isSideBarOpen ? `${SIDE_BAR_OPEN_WIDTH}` : `${SIDE_BAR_CLOSE_WIDTH}`)}px
	);
	position: relative;
`;

interface DynamicContentProps {
	isFullHeight: boolean;
}

const DynamicContent = styled.div<DynamicContentProps>`
	width: 100%;
	height: ${props => (props.isFullHeight ? 'calc(100% - 70px)' : '100%')};
`;

interface Props {
	component: any;
	isFullHeight?: boolean;
	generalPageProps?: any;
	bypassRoute?: boolean;
	match?: any;
	isToShowSideBarSubMenu: boolean;
	canToggleSideBarSideMenu: boolean;
	currentTab: number;
	toggleSideBar: () => void;
	isShowSpinner?: boolean;
	matchProps: any;
}

export default class InsightPlatformPage extends React.Component<Props, {}> {
	render() {
		const {
			component: PageComponent,
			isFullHeight,
			bypassRoute,
			match,
			isToShowSideBarSubMenu,
			canToggleSideBarSideMenu,
			currentTab,
			toggleSideBar,
			matchProps
		} = this.props;
		return (
			<div
				style={{
					overflow: 'hidden',
					height: '100vh',
					width: '100vw',
					minWidth: '1280px'
				}}
			>
				<Header />
				<SideBarContainer isSideBarOpen={isToShowSideBarSubMenu}>
					<SideBar
						isSideBarSubMenuOpen={isToShowSideBarSubMenu}
						canToggleSideBarSubMenu={canToggleSideBarSideMenu}
						currentTab={currentTab}
						toggleSideBar={toggleSideBar}
						{...matchProps}
					/>
				</SideBarContainer>
				<Body isSideBarOpen={isToShowSideBarSubMenu}>
					<DynamicContent isFullHeight={isFullHeight}>
						<div style={{ overflow: 'hidden', height: '100%' }}>
							{bypassRoute ? <PageComponent match={match} /> : <PageComponent {...matchProps} />}
						</div>
					</DynamicContent>
				</Body>
			</div>
		);
	}
}
