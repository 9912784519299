import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { APP_URLS } from '../../constants';
import Spinner from '../../widgets/Spinner/spinner';
import { error } from '../../services/logger.service';
import AllPortfoliosPage from './AllWatchlists';
import NavigationService from '../../services/navigation.service';
import { WATCHLISTS_QUERY } from '../../queries/Watchlist.queries';
import { Watchlist } from '../../types/types';

function isOnWatchlistsPage() {
	return window.location.pathname.endsWith(APP_URLS.WATCHLISTS);
}

function shouldRedirectToWatchlist(data) {
	return data?.watchlists?.length === 1 && !isOnWatchlistsPage();
}

export const AllWatchlistsProvider = () => {
	const { error: fetchError, data, loading, refetch } = useQuery(WATCHLISTS_QUERY, {
		fetchPolicy: 'cache-and-network'
	});

	useEffect(() => {
		if (fetchError) {
			error({
				message: `There was an error loading the watchlists, Error: ${fetchError}`,
				file: 'PortfolioProvider',
				functionName: 'useEffect'
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchError, data]);

	useEffect(() => {
		if (!loading && shouldRedirectToWatchlist(data)) {
			NavigationService.getInstance().goToWatchlist(data.watchlists[0].id);
		} else if (!loading && !fetchError && !isOnWatchlistsPage()) {
			NavigationService.getInstance().goToAllWatchlists();
		}
	}, [data, loading, fetchError]);

	if (loading) {
		return <Spinner />;
	}

	const wathlists: Watchlist[] = data?.watchlists?.map(watchlist => ({
		...watchlist,
		size: watchlist.companyEntities.length
	}));

	return <AllPortfoliosPage watchlists={wathlists} refetch={refetch} />;
};
