import * as React from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';
import { Speaker, Event, Paragraph } from '../../types/types';
import SpeakerText from './speakerText';
import { SCROLL_IDS, IS_QUESTION } from '../../constants';
import { Element } from 'react-scroll';

const QN0SectionContainer = styled.div``;

const QNASectionHeader = styled.div`
	width: 100%;
	height: 65px;
	box-shadow: 0 11px 11px -11px rgba(0, 0, 0, 0.2);
	color: #4a4a4a;
	font-family: Assistant Light;
	font-size: 24px;
	line-height: 65px;
`;

const SpeakerContainer = styled.div`
	width: 100%;
	clear: both;
`;

const QNAHeader = styled.div`
	width: 100%;
	height: 35px;
	line-height: 35px;
	margin-top: 20px;
`;

const OperatorParagraphUnderline = styled.div`
	width: calc(100% - 10px);
	height: 2px;
	background-color: #eeeeee;
`;

interface QNATypeContainerProps {
	isQuestion: boolean;
}
const QNATypeContainer = styled.div<QNATypeContainerProps>`
	height: 100%;
	margin-right: 9px;
	padding-left: 5px;
	float: left;
	position: relative;
	top: -1px;
	color: ${props => (props.isQuestion ? '#F44336' : '4A4A4A')};
	font-size: 14px;
	letter-spacing: 0.1px;
	box-sizing: border-box;
`;

const QNAType = styled.div`
	height: 100%;
`;

const FirstLetter = styled.span`
	font-size: 20px;
`;

const RestOfLetters = styled.span``;

interface SeparatorProps {
	width: number;
	height: number;
	marginTop: number;
	marginRight: number;
	color: string;
}
const Separator = styled.div<SeparatorProps>`
	width: ${props => props.width}px;
	height: ${props => props.height}px;
	float: left;
	margin-top: ${props => props.marginTop}px;
	margin-right: ${props => props.marginRight}px;
	background-color: ${props => props.color};
`;

const SpeakerName = styled.div`
	height: 100%;
	float: left;
	color: #014a66;
	margin-right: 5px;
	font-size: 18px;
	font-weight: 300;
`;

const SpeakerCompany = styled.div`
	height: 100%;
	float: left;
	padding-top: 1px;
	color: #9b9b9b;
	font-size: 13px;
	box-sizing: border-box;
`;

interface QNAProps {
	speakers: Speaker[];
	events: Event[];
	lostEvents: any[];
	delimiterOffset: number;
	sectionOffset: number;
	printMode: boolean;
}

export default class QNASection extends React.Component<QNAProps, {}> {
	mapSpeakersToQNA = (speakers: Speaker[]): any[] => {
		const { sectionOffset, delimiterOffset, printMode, events, lostEvents } = this.props;

		const speakersRender: any[] = _.map(speakers, (s: Speaker, index: number) => {
			const paragraphs: Paragraph[] = s.paragraphs;
			const speakerName = s.name || '';
			const speakerCompany = s.affiliation || '';
			const isOperator = s.name === 'Operator';
			const filteredEvents = _.filter(events, (e: Event) => {
				const eventsStartInSpeaker =
					e.startIndex - (e.isUserDefined ? 0 : delimiterOffset) >=
						s.offsetInText + sectionOffset || e.startIndex >= s.offsetInText + sectionOffset;
				const eventEndsInSpeaker =
					e.endIndex - (e.isUserDefined ? 0 : delimiterOffset) <
					s.offsetInText + s.textLength + sectionOffset + 1;
				return eventsStartInSpeaker && eventEndsInSpeaker;
			});

			return (
				<SpeakerContainer key={`Speaker_Paragraph_${index}`}>
					{this.mapSpeakerDetailsToHeader(s, speakerName, speakerCompany, isOperator)}
					<SpeakerText
						paragraphs={paragraphs}
						events={filteredEvents}
						lostEvents={lostEvents}
						speaker={s}
						delimiterOffset={delimiterOffset}
						sectionOffset={sectionOffset}
						type={1}
						printMode={printMode}
					/>
					{isOperator && <OperatorParagraphUnderline />}
				</SpeakerContainer>
			);
		});

		return speakersRender;
	};

	mapSpeakerDetailsToHeader = (
		speaker: Speaker,
		speakerName: string,
		speakerCompany: string,
		isOperator: boolean
	): any => {
		const isQuestion = IS_QUESTION(speaker.type);
		return (
			<QNAHeader>
				<Element name={SCROLL_IDS.QNA.NAME + String(speaker.speakingIndex)} />
				{speaker.type !== null && (
					<QNATypeContainer isQuestion={isQuestion}>
						{isQuestion ? (
							<QNAType>
								<FirstLetter>Q</FirstLetter>
								<RestOfLetters>uestion</RestOfLetters>
							</QNAType>
						) : (
							<QNAType>
								<FirstLetter>A</FirstLetter>
								<RestOfLetters>nswer</RestOfLetters>
							</QNAType>
						)}
					</QNATypeContainer>
				)}
				{/* if has name that different from operator and has type - question / answer */}
				{speakerName && !isOperator && speaker.type !== null && (
					<Separator width={1.5} height={20} marginTop={7} marginRight={5} color={'#014A66'} />
				)}
				{speakerName && <SpeakerName>{speakerName}</SpeakerName>}
				{speakerCompany && (
					<Separator width={2} height={13} marginTop={12} marginRight={5} color={'#9B9B9B'} />
				)}
				{speakerCompany && <SpeakerCompany>{speakerCompany}</SpeakerCompany>}
			</QNAHeader>
		);
	};

	render() {
		return (
			<QN0SectionContainer>
				<QNASectionHeader>Questions & Answers</QNASectionHeader>
				{this.mapSpeakersToQNA(this.props.speakers)}
			</QN0SectionContainer>
		);
	}
}
