import * as React from 'react';
import styled from 'styled-components';
import Images from '../../shared/images';
import { FEATURES_TYPE_SPLIT, TABS, USER_TRACKING as UT } from '../../constants';
import {
	TAB_WIDTH,
	TAB_HEIGHT,
	SIDE_BAR_OPEN_WIDTH,
	SIDE_BAR_CLOSE_WIDTH
} from '../../pages/layoutElements';
import ViewerSubMenu from './ViewerSubMenu';
import ViewerSubMenuGQL from './ViewerSubMenuGQL';
import { AnalyticsSubMenu } from './AnalyticsSubMenu';
import UserTracking from '../../services/userTracking.service';
import { BlueArrow } from './SideBar.styles';
import { error } from '../../services/logger.service';
import NavigationService from '../../services/navigation.service';
import { useFeature } from '../../hooks/useFeature';
import { useOrganizationFeatures } from '../../hooks/useOrganizationFeatures';
import AnalyticsLookerSubMenu from './AnalyticsLookerSubMenu';
import { useEffect, useState } from 'react';

const UTC = UT.USER_TRACKING_CATEGORIES;
const UTA = UT.USER_TRACKING_ACTIONS;

const TAB_USER_ACTIONS = [UTA.VIEWER_CLICKED, UTA.ANALYTICS_CLICKED];

interface IsOpenProps {
	isOpen?: boolean;
}

const Container = styled.div<IsOpenProps>`
	width: ${props => (props.isOpen ? `${SIDE_BAR_OPEN_WIDTH}` : `${SIDE_BAR_CLOSE_WIDTH}`)}px;
	height: 100%;
`;

const Body = styled.div`
	height: 100%;
`;

const MainMenuContainer = styled.div`
	width: ${TAB_WIDTH}px;
	height: 100%;
	float: left;
	background-color: #f5f5f5;
	border-right: 1px solid #e8edef;
	box-sizing: border-box;
`;

const SubMenuContainer = styled.div<IsOpenProps>`
	position: relative;
	width: calc(100% - ${TAB_WIDTH}px - 1px);
	height: 100%;
	float: left;
	overflow: hidden;
	background-color: #fff;
	box-shadow: 11px 0 22px -11px #dde5e7, 1px 0 0 0 #e8edef;
`;

const Tooltip = styled.div`
	height: 50px;
	background-color: #fff;
	border: 1px solid #e0e7e9;
	box-shadow: 0 0 8px 0 #dde5e7;
	padding: 15px 14px 15px 12px;
	border-radius: 5px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	height: 18px;
	line-height: 18px;
	white-space: nowrap;
	font-size: 14px;
	font-weight: bold;
	font-family: Assistant Semibold;
	color: #32313b;
	&:before {
		content: '';
		position: absolute;
		top: -1px;
		left: -31px;
		border-top: 25px solid transparent;
		border-right: 11px solid #e0e7e9;
		border-bottom: 25px solid transparent;
		border-left: 19px solid transparent;
		height: 0px;
		width: 0px;
	}
	&:after {
		content: '';
		position: absolute;
		top: 0px;
		left: -29px;
		border-top: 24px solid transparent;
		border-right: 10px solid #fff;
		border-bottom: 24px solid transparent;
		border-left: 19px solid transparent;
		height: 0px;
		width: 0px;
	}
`;

interface TabProps {
	isSelected: boolean;
}

const Tab = styled.div<TabProps>`
	height: ${TAB_HEIGHT}px;
	background-color: ${props => (props.isSelected ? '#FFF' : '#F5F5F5')};
	box-shadow: ${props => (props.isSelected ? '1px 0 0 0 #FFF' : '')};
	position: relative;
	cursor: pointer;

	:not(:last-child) {
		border-bottom: 1px solid #e0e7e9;
	}
	> .tab-tooltip {
		display: none;
	}
	&:hover {
		> .tab-tooltip {
			display: block;
			position: absolute;
			left: 70px;
			top: 14px;
			z-index: 10;
		}
	}
`;

const SettingsTab = styled(Tab)`
	position: absolute;
	bottom: 0;
	width: ${TAB_WIDTH}px;
`;

const TabImg = styled.img`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 20px;
	height: 20px;
`;

const TabArrow = styled(BlueArrow)`
	position: absolute;
	padding: 0;
	right: 4px;
	top: 24px;
	width: 14px;
	height: 32px;
`;

interface SideBarProps {
	isSideBarSubMenuOpen: boolean;
	canToggleSideBarSubMenu: boolean;
	currentTab: number;
	toggleSideBar: () => void;
	match: any;
}

const SideBar = (props: SideBarProps) => {
	const { isSideBarSubMenuOpen, currentTab, canToggleSideBarSubMenu, toggleSideBar, match } = props;
	const ffViewerNewarc = useFeature(FEATURES_TYPE_SPLIT.VIEWER_NEWARC_MIGRATION);
	const ffViewerDtcEmail = useFeature(FEATURES_TYPE_SPLIT.VIEWER_DTC_EMAIL);
	const orgFeatures = useOrganizationFeatures();
	const [showDTCSettings, setShowDTCSettings] = useState(false);

	useEffect(() => {
		setShowDTCSettings(orgFeatures?.dtc && ffViewerDtcEmail);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ffViewerDtcEmail, orgFeatures]);

	const onClickTab = (clickedTab: number, isSelected: boolean) => {
		if (isSelected) {
			toggleSideBar();
		} else {
			const ut = new UserTracking();
			ut.setEvent(UTC.TOP_NAVIGATION, TAB_USER_ACTIONS[clickedTab], '');

			changeTab(clickedTab);
		}
	};

	const changeTab = (clickedTab: number) => {
		const nav = NavigationService.getInstance();
		switch (clickedTab) {
			case TABS.WATCHLIST:
				nav.goToRoot();
				break;
			case TABS.QUERY:
				nav.goToQueryInsights();
				break;
			case TABS.ANALYTICS_LOOKER:
				nav.goToAnalytics();
				break;
			case TABS.EMAIL_SUBSCRIPTION:
				nav.goToEmailSubscription();
				break;
			default:
				error({
					message: `Tab ${clickedTab} doesn't exist`,
					file: 'SideBar',
					functionName: 'changeTab'
				});
		}
	};

	const isTabSelected = (currentTab: number, clickedTab: number) => currentTab === clickedTab;

	const showOpenTabArrow = (currentTab: number, clickedTab: number, shouldShowSubMenuArrow) => {
		return shouldShowSubMenuArrow && isTabSelected(currentTab, clickedTab);
	};

	const renderTabs = (currentTab: number, shouldRenderSubMenuArrow: boolean): any => {
		return (
			<React.Fragment>
				<Tab
					isSelected={isTabSelected(currentTab, TABS.WATCHLIST)}
					onClick={() => onClickTab(TABS.WATCHLIST, isTabSelected(currentTab, TABS.WATCHLIST))}
					id='SIDEBAR_TAB_VIEWER_ICON'
				>
					<Tooltip className='tab-tooltip'>Document Viewer</Tooltip>
					<TabImg
						src={
							isTabSelected(currentTab, TABS.WATCHLIST)
								? Images.sideBarDocumentBlue
								: Images.sideBarDocument
						}
					/>
					{showOpenTabArrow(currentTab, TABS.WATCHLIST, shouldRenderSubMenuArrow) && (
						<TabArrow pointTo='right' id='SIDEBAR_TAB_VIEWER_OPEN_ARROW_ICON' />
					)}
				</Tab>
				<Tab
					isSelected={isTabSelected(currentTab, TABS.ANALYTICS_LOOKER)}
					onClick={() =>
						onClickTab(TABS.ANALYTICS_LOOKER, isTabSelected(currentTab, TABS.ANALYTICS_LOOKER))
					}
					id='SIDEBAR_TAB_ANALYTICS_LOOKER_ICON'
				>
					<Tooltip className='tab-tooltip'>Analytics</Tooltip>
					<TabImg
						src={
							isTabSelected(currentTab, TABS.ANALYTICS_LOOKER)
								? Images.sideBarDashboardBlue
								: Images.sideBarDashboard
						}
					/>
					{showOpenTabArrow(currentTab, TABS.ANALYTICS_LOOKER, shouldRenderSubMenuArrow) && (
						<TabArrow pointTo='right' id='SIDEBAR_TAB_ANALYTICS_LOOKER_OPEN_ARROW_ICON' />
					)}
				</Tab>
				{showDTCSettings && (
					<SettingsTab
						isSelected={isTabSelected(currentTab, TABS.EMAIL_SUBSCRIPTION)}
						onClick={() =>
							onClickTab(
								TABS.EMAIL_SUBSCRIPTION,
								isTabSelected(currentTab, TABS.EMAIL_SUBSCRIPTION)
							)
						}
						id='SIDEBAR_TAB_SETTINGS_ICON'
					>
						<Tooltip className='tab-tooltip'>Email Subscription</Tooltip>
						<TabImg
							src={
								isTabSelected(currentTab, TABS.EMAIL_SUBSCRIPTION)
									? Images.settingsBlue
									: Images.settings
							}
						/>
						{showOpenTabArrow(currentTab, TABS.EMAIL_SUBSCRIPTION, shouldRenderSubMenuArrow) && (
							<TabArrow pointTo='right' id='SIDEBAR_TAB_ANALYTICS_OPEN_ARROW_ICON' />
						)}
					</SettingsTab>
				)}
			</React.Fragment>
		);
	};

	const shouldRenderSubMenuArrow = canToggleSideBarSubMenu && !isSideBarSubMenuOpen;

	return (
		<Container isOpen={isSideBarSubMenuOpen}>
			<Body>
				<MainMenuContainer>{renderTabs(currentTab, shouldRenderSubMenuArrow)}</MainMenuContainer>
				{isSideBarSubMenuOpen && (
					<SubMenuContainer>
						{isTabSelected(currentTab, TABS.WATCHLIST) && ffViewerNewarc && (
							<ViewerSubMenuGQL title={'VIEWER'} closeSideBar={toggleSideBar} />
						)}
						{isTabSelected(currentTab, TABS.WATCHLIST) && !ffViewerNewarc && (
							<ViewerSubMenu title={'VIEWER'} closeSideBar={toggleSideBar} />
						)}
						{isTabSelected(currentTab, TABS.ANALYTICS) && (
							<AnalyticsSubMenu title={'ANALYTICS'} closeSideBar={toggleSideBar} />
						)}
						{isTabSelected(currentTab, TABS.ANALYTICS_LOOKER) && (
							<AnalyticsLookerSubMenu
								title={'ANALYTICS'}
								closeSideBar={toggleSideBar}
								match={match}
							/>
						)}
					</SubMenuContainer>
				)}
			</Body>
		</Container>
	);
};
export default SideBar;
