import React, { FC } from 'react';
import styled from 'styled-components';
import Spinner from '../../widgets/Spinner/spinner';

interface SpinnerProps {
	isHidden: boolean;
}

const SpinnerStyle = styled.div<SpinnerProps>`
	visibility: ${props => (props.isHidden ? 'hidden' : 'visible')};
`;

const SpinnerWithHide: FC<SpinnerProps> = ({ isHidden }) => {
	return (
		<SpinnerStyle isHidden={isHidden}>
			<Spinner />
		</SpinnerStyle>
	);
};

export default SpinnerWithHide;
