import * as React from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import CommonTable from '../Table/commonTable';
import DataStore from '../../stores/data.store';
import GlobalStore from '../../stores/global.store';
import UIStore from '../../stores/ui.store';

import { STORE_DATA, STORE_FEATURES, STORE_GLOBAL, STORE_UI, TABS } from '../../constants';
import { Watchlist, PortfolioActionModalProps, PortfolioActionModalType } from '../../types/types';
import { ALL_PORTFOLIOS_TABLE_COLUMNS } from '../Table/columnConstants';
import NavigationService from '../../services/navigation.service';
import EditIcon from '../../../assets/icons/edit_large.svg';
import UserTracking from '../../services/userTracking.service';
import { sortByCaseInsensitive } from '../../services/util.service';
import { Empty, EmptySubTitle, EmptyTitle } from './sharedStyledComponents';
import { MULTIPORTFOLIO } from '../../../id.constants';
import { Link } from '../../../components/Link/Link';
import { Text } from '../../../components/Text/Text';
import { Icon } from '../../../components/Icon/Icon';
import { Colors, FontSizes } from '../../../components/shared/styles';
import addIcon from '../../../assets/icons/add.svg';
import { WatchlistsTable } from './WatchlistsTable';
import { CreateWatchlistMenu } from './CreateWatchlistMenu/CreateWatchlistMenu';

const TableContainer = styled.div`
	padding: 31px 21px;
	height: calc(100% - 90px);
	box-sizing: border-box;
`;

const RowsScroller = styled.div`
	height: calc(100% - 72px);
	overflow-y: auto;
	width: 100%;
`;

interface State {
	modal: PortfolioActionModalProps;
	selected: Watchlist;
	isPreImportModalOpen: boolean;
	isImportModalOpen: boolean;
	isExcelImport: boolean;
	sortedCol: any;
	isAscending: boolean;
}

interface AllPortfolioPageProps {
	watchlists: Watchlist[];
	refetch?: () => void;
}

@inject(STORE_DATA, STORE_GLOBAL, STORE_UI, STORE_FEATURES)
@observer
export default class AllPortfoliosPage extends React.Component<AllPortfolioPageProps, State> {
	navService: NavigationService;
	userTracking: UserTracking;
	allPortfoliosObserver: any;

	constructor(props) {
		super(props);
		this.state = {
			modal: undefined,
			selected: undefined,
			isPreImportModalOpen: false,
			isImportModalOpen: false,
			isExcelImport: false,
			sortedCol: ALL_PORTFOLIOS_TABLE_COLUMNS[1],
			isAscending: true
		};
		this.navService = NavigationService.getInstance();
		this.userTracking = UserTracking.getInstance();
		const dataStore = props[STORE_DATA] as DataStore;
		const globalStore = props[STORE_GLOBAL] as GlobalStore;
		const ui = props[STORE_UI] as UIStore;
		dataStore.setPortfolio([]);
		globalStore.setCurrentTab(TABS.WATCHLIST);
		ui.setSideBarState(false);
		ui.setCanToggleSideBar(false);
	}

	componentDidUpdate() {
		ReactTooltip.rebuild();
	}

	componentWillUnmount() {
		if (this.allPortfoliosObserver) {
			this.allPortfoliosObserver();
		}
	}

	openCreatePortfolio = () => {
		const modal: PortfolioActionModalProps = {
			open: true,
			action: 'create',
			type: PortfolioActionModalType.Edit,
			icon: EditIcon,
			text: 'Enter a name for the new watchlist:',
			confirmText: 'Create Watchlist',
			error: '',
			defaultValue: 'New Watchlist'
		};
		this.setState({ selected: undefined, modal });
	};

	closeModal = () => {
		this.setState({
			selected: undefined,
			modal: undefined,
			isPreImportModalOpen: false,
			isExcelImport: false
		});
	};

	displayError = (err: string) => {
		this.setState({ modal: { ...this.state.modal, error: err } });
	};

	togglePreuploadPopup = (isExcelImport: boolean) => {
		this.setState({
			isPreImportModalOpen: !this.state.isPreImportModalOpen,
			isExcelImport: isExcelImport !== undefined ? isExcelImport : this.state.isExcelImport
		});
	};

	getTooltipPosition = (
		cursorPosition: { left: number; top: number },
		currentEvent: any,
		currentTarget: any
	) => {
		const rect = currentTarget.getBoundingClientRect();
		const left = rect.right - rect.width / 2 - 35;
		const top = rect.bottom - rect.height / 2 - 40;
		return { left, top };
	};

	sortTable = (clickedColumn: any) => {
		this.setState({
			sortedCol: clickedColumn,
			isAscending: clickedColumn.name !== this.state.sortedCol.name || !this.state.isAscending
		});
	};

	renderEmpty = () => (
		<TableContainer>
			<Empty id={MULTIPORTFOLIO.EMPTY_STATE} padding={'215px 0 0 0'} new>
				<div style={{ marginBottom: '20px' }}>
					<Icon src={addIcon} width={77} />
				</div>
				<EmptyTitle>
					<Text size={FontSizes.m2} color={Colors.darkGray}>
						No watchlists yet
					</Text>
				</EmptyTitle>
				<EmptySubTitle>
					<Text color={Colors.darkGray}>Start by&nbsp;</Text>
					<Link onClick={this.openCreatePortfolio}>creating an empty watchlist</Link>
					<Text color={Colors.darkGray}>&nbsp;or Importing watchlist from&nbsp;</Text>
					<Link onClick={() => this.togglePreuploadPopup(false)}>Bloomberg</Link>
					<Text color={Colors.darkGray}>&nbsp;or&nbsp;</Text>
					<Link onClick={() => this.togglePreuploadPopup(true)}>Excel</Link>
				</EmptySubTitle>
			</Empty>
		</TableContainer>
	);

	renderContent = (
		isPortfoliosLoaded: boolean,
		allPortfolios: Watchlist[],
		sortBy: string,
		isAscending: boolean
	) => {
		if (!allPortfolios) {
			return;
		}
		if (allPortfolios.length === 0 && isPortfoliosLoaded) {
			return this.renderEmpty();
		}

		return (
			<TableContainer>
				<CommonTable
					tableId='ALL_PORTFOLIOS_TABLE'
					columns={ALL_PORTFOLIOS_TABLE_COLUMNS}
					nameOfSortedColumn={sortBy}
					isSortedAscending={isAscending}
					sortFunction={this.sortTable}
					isSortable
				>
					<RowsScroller>
						{<WatchlistsTable watchlists={allPortfolios} refetch={this.props.refetch} />}
					</RowsScroller>
				</CommonTable>
			</TableContainer>
		);
	};

	render() {
		const {
			modal,
			selected,
			isPreImportModalOpen,
			isExcelImport,
			sortedCol,
			isAscending
		} = this.state;

		const allPortfolios = this.props.watchlists;
		const isPortfoliosLoaded = !!allPortfolios;
		const orderedPortfolios = sortByCaseInsensitive(allPortfolios, sortedCol.name, isAscending);

		return (
			<>
				{
					<CreateWatchlistMenu
						watchlists={orderedPortfolios}
						refetch={() => {
							this.props.refetch();
							this.closeModal();
						}}
						openBloombergImport={isPreImportModalOpen && !isExcelImport}
						openExcelImport={isPreImportModalOpen && isExcelImport}
						openNewWatchist={modal?.open && !selected}
					/>
				}

				{this.renderContent(isPortfoliosLoaded, orderedPortfolios, sortedCol.name, isAscending)}
				<ReactTooltip
					id={MULTIPORTFOLIO.TOOL_TIP}
					type='light'
					border
					className='portfolios-button-tooltip'
					overridePosition={this.getTooltipPosition}
				/>
			</>
		);
	}
}
