import React from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';

import { QNA, QNAElement, SelectFunction } from '../../../types/types';
import { SCROLL_IDS } from '../../../constants';
import { Colors, FontSizes } from '../../../../components/shared/styles';
import { Text } from '../../../../components/Text/Text';
import avatarIcon from '../../../../assets/icons/speaker_avatar_sidebar.png';
import { Icon } from '../../../../components/Icon/Icon';

const QnAItem = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: start;
	padding: 5px 15px;
	cursor: pointer;
	&:hover {
		background-color: ${Colors.lightGray};
	}
`;

const QNAEntryStyled = styled.div`
	border-bottom: 1px solid ${Colors.lightGray};
	padding: 10px 0;
`;

const Details = styled.div`
	width: calc(100% - 30px);
`;

const renderTitle = (QNAElement: QNAElement): string => {
	const { title, affiliation } = QNAElement;
	return `${title ? title : ''}${title && affiliation ? ', ' : ''}${
		affiliation ? affiliation : ''
	}`;
};

const colorForType = (isAnswer: boolean): Colors => {
	return isAnswer ? Colors.blue : Colors.darkBlue;
};

const renderItem = (
	QNAElement: QNAElement,
	index: number,
	onSelect: SelectFunction,
	options: { isAnswer: boolean }
): any => {
	const hasNoType = !QNAElement || QNAElement.type === null;
	const { isAnswer } = options;

	return (
		<QnAItem onClick={() => onSelect(QNAElement.id, SCROLL_IDS.QNA.ID)}>
			{hasNoType ? (
				<Icon src={avatarIcon} />
			) : (
				<Text color={colorForType(isAnswer)} size={FontSizes.s2}>
					{`${isAnswer ? 'A' : 'Q'}${index}`}
				</Text>
			)}
			<Details>
				<Text title={`${QNAElement.name}`} size={FontSizes.s2} color={Colors.dark} block ellipsis>
					{QNAElement.name}
				</Text>
				<Text size={FontSizes.s2} color={Colors.gray} block>
					{renderTitle(QNAElement)}
				</Text>
			</Details>
		</QnAItem>
	);
};

const renderAnswers = (answers: QNAElement[], index: number, onSelect: SelectFunction): any =>
	_.map(answers, (answer: QNAElement) => (
		<div key={`A${answer.id}`}>{renderItem(answer, index, onSelect, { isAnswer: true })}</div>
	));

const renderQuestion = (QNAElement: QNAElement, index: number, onSelect: SelectFunction) =>
	renderItem(QNAElement, index, onSelect, { isAnswer: false });

interface Props {
	qna: QNA;
	index: number;
	onSelect: SelectFunction;
}

const QnaEntry: React.FunctionComponent<Props> = ({ qna, index, onSelect }) => (
	<QNAEntryStyled>
		{renderQuestion(qna.question, index, onSelect)}
		{renderAnswers(qna.answerElements, index, onSelect)}
	</QNAEntryStyled>
);

export default QnaEntry;
