import { FunctionComponent, default as React } from 'react';
import styled from 'styled-components';
import { Colors, FontFamilies, FontSizes } from '../../../components/shared/styles';
import { Icon } from '../../../components/Icon/Icon';
import helpIcon from '../../../assets/icons/help_icon.svg';

const HelpCenterLink = styled.a`
	display: grid;
	grid-template-columns: 20px auto;
	align-items: center;
	grid-gap: 12px;
	line-height: 26px;
	text-decoration: none;
	color: ${Colors.white};
	font-size: ${FontSizes.s2};
	font-family: ${FontFamilies.regular};
	&:hover {
		text-decoration: underline;
	}
`;

export const HelpCenter: FunctionComponent = () => (
	<HelpCenterLink
		href={'https://support.symphony.com/hc/en-us/categories/13249345293716-Data-Analytics'}
		target={'_blank'}
	>
		<Icon src={helpIcon} width={20} />
		<span>Help Center</span>
	</HelpCenterLink>
);
