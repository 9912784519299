import * as React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Images from '../../shared/images';
import DataSrv from '../../services/data.service';
import GenericPopup from '../../widgets/GenericPopup/genericPopup';
import { error } from '../../services/logger.service';

const Container = styled.div`
	height: calc(100% - 50px);
`;

const UpperSection = styled.div`
	height: 90px;
	padding: 15px;
	margin-bottom: 20px;
	box-sizing: border-box;
	border-bottom: 1px solid #e0e7e9;
`;

const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	height: calc(100% - 90px);
`;

const LeftHeading = styled.div`
	width: 180px;
	padding: 19px 0 0 0;
	box-sizing: border-box;
	margin-left: 10px;
	color: #32313b;
	font-family: Assistant;
	font-size: 20px;
	font-weight: 600;
`;

const SettingsIcon = styled.div`
	float: left;
	width: 24px;
	height: 24px;
	margin-right: 8px;
	background-image: url('${Images.playgroundIcon}');
	background-size: contain;
`;

const ConfigContainer = styled.div`
	width: 900px;
	padding: 10px 25px;
`;

const ConfigContent = styled.div`
	display: flex;
	align-items: center;
	min-height: 98px;
	border-radius: 6px;
	box-sizing: border-box;
	background-color: #ffffff;
`;

const ConfigItems = styled.div`
	box-shadow: 0 1px 4px 0 rgba(50, 49, 59, 0.32);
`;

const RowsContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 20px;
`;

const ConfigTitle = styled.div`
	color: #32313b;
	font-family: Assistant;
	font-size: 16px;
	font-weight: 600;
	box-sizing: border-box;
	margin-bottom: 5px;
`;

const ButtonContainer = styled.div`
	height: 45px;
	margin: 0px 417px;
`;

const TextContainer = styled.div`
	padding: 0 80px;
	margin-bottom: 10px;
	font-weight: bold;
	font-size: 14px;
	text-align: center;
`;

interface ConfigurationPageProps {
	env: string;
}

interface ConfigurationPageState {
	defaultToken: string;
	defaultFlowId: string;
	confirmPopUp: boolean;
	saveSetting: string;
	errorPopup: boolean;
	descriptionError: string;
}

class ConfigurationPage extends React.Component<ConfigurationPageProps, ConfigurationPageState> {
	constructor(props: ConfigurationPageProps) {
		super(props);
		this.state = {
			defaultToken: undefined,
			defaultFlowId: undefined,
			confirmPopUp: false,
			saveSetting: '',
			errorPopup: false,
			descriptionError: ''
		};
	}

	componentDidMount() {
		const ds = new DataSrv();
		ds.getDevConfigurations(
			{},
			response => {
				console.log('response ? ', response);
				this.setState({
					defaultToken: response.DEFAULT_TOKEN,
					defaultFlowId: response.API
				});
			},
			err =>
				error({
					message: `Error fetching configurations: ${JSON.stringify(err)}`,
					file: 'configurationTab',
					functionName: 'componentDidMount'
				})
		);
	}

	handleChange = (event: any) => {
		const { name, value }: any = event.target;
		const newState = { ...this.state, [name]: value };
		this.setState(newState);
	};

	save = (config: string) => {
		this.toggleConfirmPopup();
		this.setState({ saveSetting: config });
	};

	onConfirm = () => {
		this.toggleConfirmPopup();
		const ds = new DataSrv();
		const params = {
			flowId: this.state.defaultFlowId,
			token: this.state.defaultToken
		};
		ds.saveDefaultTokenAndFlowId(
			params,
			res => {
				if (res.success === false) {
					this.openErrorPopup('Invalid flow or token');
				}
			},
			err => {
				error({
					message: `Error saving default token and flow: ${JSON.stringify(err)}`,
					file: 'configurationTab',
					functionName: 'onConfirm'
				});
				this.openErrorPopup(err.statusText);
			}
		);
	};

	openErrorPopup = (message: string) => {
		this.setState({ descriptionError: message });
		this.toggleErrorPopup(true);
	};

	toggleConfirmPopup = () => this.setState({ confirmPopUp: !this.state.confirmPopUp });

	renderDefaultToken = (defaultToken: string) => (
		<RowsContainer>
			<TextField
				style={{ width: '100%' }}
				multiline
				id='outlined-multiline-static'
				value={defaultToken ? defaultToken : ''}
				rows='2'
				margin='dense'
				variant='outlined'
				label='Token'
				name='defaultToken'
				inputProps={{
					style: { fontSize: 10, height: 75 }
				}}
				onChange={this.handleChange}
			/>
		</RowsContainer>
	);

	renderDefaultFlowId = (defaultFlowId: string) => (
		<RowsContainer>
			<TextField
				style={{ width: '100%' }}
				id='outlined-multiline-static'
				value={defaultFlowId ? defaultFlowId : ''}
				margin='dense'
				variant='outlined'
				label='Flow'
				name='defaultFlowId'
				onChange={this.handleChange}
			/>
		</RowsContainer>
	);
	toggleErrorPopup = (state: boolean) => this.setState({ errorPopup: state });

	render() {
		const { defaultToken, defaultFlowId } = this.state;
		console.log('state ? ', this.state);
		return (
			<Container>
				<UpperSection>
					<LeftHeading>
						<SettingsIcon />
						Configurations
					</LeftHeading>
				</UpperSection>
				<ContentContainer>
					<ConfigContainer>
						<ConfigTitle>Default</ConfigTitle>
						<ConfigItems>
							<ConfigContent>{this.renderDefaultFlowId(defaultFlowId)}</ConfigContent>
							<ConfigContent>{this.renderDefaultToken(defaultToken)}</ConfigContent>
							<TextContainer>
								Warning! Changing the default flow will affect a large number of viewer customers.
								Do not change this setting without communicating the change to customer support
							</TextContainer>
							<ButtonContainer>
								<Button
									variant='contained'
									color='primary'
									onClick={() => this.save('defaultTokenAndFlowId')}
								>
									Save
								</Button>
							</ButtonContainer>
						</ConfigItems>
					</ConfigContainer>
					<GenericPopup
						isOpen={this.state.confirmPopUp}
						message={`CAUTION\n You want to change ${this.state.saveSetting}, are you sure you want to make the change? `}
						acceptButtonText={'JUST DO IT!'}
						acceptCallback={() => this.onConfirm()}
						dismissButtonText={'CANCEL'}
						dismissCallback={() => this.toggleConfirmPopup()}
						clickOutsideCallback={() => this.toggleConfirmPopup()}
						width={550}
					/>
					<GenericPopup
						isOpen={this.state.errorPopup}
						message={this.state.descriptionError}
						acceptCallback={() => this.toggleErrorPopup(false)}
						acceptButtonText={'OK'}
					/>
				</ContentContainer>
			</Container>
		);
	}
}

export default ConfigurationPage;
