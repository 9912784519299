import * as React from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';

import { DropdownOption, SectionDropdownOptions } from '../shared/types';
import { FontSizes, FontFamilies, Colors, Sizes } from '../shared/styles';

const Section = styled.div`
	color: ${Colors.dark};
`;

const SectionTitle = styled.div`
	display: flex;
	align-items: center;
	height: 32px;
	padding: 0 18px;
	background-color: ${Colors.lightGray};
	font-family: ${FontFamilies.bold};
	cursor: pointer;
`;

const EmptyState = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	font-size: ${FontSizes.s3};
	color: ${Colors.darkGray};
`;

interface DropdownMenuProps {
	withPadding?: boolean;
	size?: Sizes;
	maxHeight?: number;
}

const DropdownMenu = styled.div<DropdownMenuProps>`
	max-height: ${({ maxHeight, size }) => maxHeight || (size === Sizes.small ? '220' : '450')}px;
	overflow-y: auto;
	font-family: ${FontFamilies.regular};
	font-size: ${FontSizes.s3};
	color: ${Colors.dark};
	box-sizing: border-box;
	border-radius: 4px;
	box-shadow: 0.7px 0.7px 5.6px 0.4px ${Colors.gray};
	background-color: ${Colors.white};
	padding: ${props => props.withPadding && '14px 0px'};
	z-index: 10;
`;

const DropdownMenuItem = styled.div<{
	extraPadding?: boolean;
	withDivider?: boolean;
	selected?: boolean;
	size?: Sizes;
}>`
	font-size: ${FontSizes.s3};
	line-height: ${({ size }) => (size === Sizes.small ? '17px' : '35px')};
	font-family: ${FontFamilies.regular};
	padding: 5px ${props => (props.extraPadding ? '27px' : '18px')};
	font-style: normal;
	font-stretch: normal;
	text-align: left;
	color: ${Colors.dark};
	white-space: nowrap;
	cursor: pointer;
	background-color: ${props => (props.selected ? Colors.gray : 'initial')};
	&:hover {
		background-color: ${Colors.gray};
	}
	box-sizing: border-box;
	border-top: ${props => (props.withDivider ? '1px solid #e0e7e9' : 'none')};
`;

interface Props {
	options?: DropdownOption[];
	sections?: SectionDropdownOptions[];
	selectedOptionId?: number | string;
	emptyStateText?: string;
	size?: Sizes;
	maxHeight?: number;
	onSelect?: (id: number | string, sectionId: string) => void;
}

export const Menu: React.FunctionComponent<Props> = ({
	options,
	onSelect,
	sections,
	selectedOptionId,
	emptyStateText,
	size = Sizes.medium,
	maxHeight
}) => {
	const renderOptions = (options: DropdownOption[], sectionId?: string) =>
		_.map(options, (option: DropdownOption) => (
			<DropdownMenuItem
				onClick={() => onSelect(option.id, sectionId)}
				id={option.elementId}
				key={option.elementId}
				extraPadding={!!sectionId}
				selected={option.id === selectedOptionId}
				size={size}
			>
				{option.renderOption ? option.renderOption() : option.label}
			</DropdownMenuItem>
		));

	const renderAction = (label: string, onClick: () => void) => (
		<DropdownMenuItem
			onClick={onClick}
			extraPadding={true}
			withDivider={true}
			style={{ fontFamily: `${FontFamilies.semiBold}` }}
			size={size}
		>
			{label}
		</DropdownMenuItem>
	);

	const renderSections = (sections: SectionDropdownOptions[]) =>
		_.map(sections, (section: SectionDropdownOptions) => (
			<Section id={`DROPDOWN_SECTION_${section.id}`} key={section.id}>
				{section.name && <SectionTitle>{section.name}</SectionTitle>}
				{section.options && renderOptions(section.options, section.id)}
				{_.isEmpty(section.options) &&
					section.name &&
					emptyState(emptyStateText || `No ${section.name}`)}
				{section.action &&
					!_.isEmpty(section.options) &&
					renderAction(section.action.label, section.action.onClick)}
			</Section>
		));

	const emptyState = (emptyStateText: string) => (
		<EmptyState id={'EMPTY_STATE'}>{emptyStateText || 'No items to show'}</EmptyState>
	);

	return (
		<DropdownMenu withPadding={!!options} size={size} maxHeight={maxHeight}>
			{options ? renderOptions(options) : renderSections(sections)}
			{_.isEmpty(options) && _.isEmpty(sections) && emptyState(emptyStateText)}
		</DropdownMenu>
	);
};
