import { gql } from '@apollo/client';

export const COMPANIES_QUERY = gql`
	query GetCompanies($searchTerm: String!) {
		companyEntitySearch(search: $searchTerm) {
			id
			name
			mainIdentifier {
				region
				ticker
			}
		}
	}
`;

export const BLOOMBERG_COMPANIES_QUERY = gql`
	query GetCompaniesByBloomberg($ticker: String!) {
		companyEntitySearchByBloomberg(ticker: $ticker) {
			id
		}
	}
`;
