import React, { useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useRecoilValue, useRecoilState } from 'recoil';
import { Button } from '@amenityllc/amenity-components';
import { useLocalStorage, writeStorage } from '@rehooks/local-storage';
import currentDocumentTypeState from '../../recoil/currentDocumentType';
import currentSubscriptionState from '../../recoil/currentSubscription';
import currentProviderState from '../../recoil/currentProvider';
import * as ids from '../../../id.constants.js';
import { MenuWrapper } from '../../../components/MenuWrapper/MenuWrapper';
import { Text } from '../../../components/Text/Text';
import { Appearance, Colors, FontSizes } from '../../../components/shared/styles';
import { OrganizationSubscriptionInfo } from '../../queries/autogenerate/schemas';
import { useGetSubscriptionsQuery } from '../../queries/autogenerate/hooks';
import { error } from '../../services/logger.service';
import { DropdownOption } from '../../../components/shared/types';
import { codeFromDocumentType } from '../../types/DocTypes.types';

const Ellipsis = styled.div`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	text-align: left;
`;

const TwoRowsText = styled(Ellipsis)`
	line-height: 20px;
`;

const FlowName = styled(Ellipsis)`
	width: 200px;
`;

const SUBSCRIPTION_KEY = 'subscriptionId';
const PROVIDER_KEY = 'providerName';

const WatchlistLensNewarc: React.FunctionComponent = () => {
	const { error: fetchError, data, loading } = useGetSubscriptionsQuery();
	const currentDocType = useRecoilValue(currentDocumentTypeState);
	const [currentSubscriptionId, setCurrentSubscription] = useRecoilState(currentSubscriptionState);
	const [currentProvider, setCurrentProvider] = useRecoilState(currentProviderState);
	const [savedSubscriptionId] = useLocalStorage<string>(SUBSCRIPTION_KEY);
	const [savedProvider] = useLocalStorage<string>(PROVIDER_KEY);

	useEffect(() => {
		if (savedSubscriptionId && !currentSubscriptionId) {
			setCurrentSubscription(savedSubscriptionId);
		}
		if (savedProvider && !currentProvider) {
			setCurrentProvider(savedProvider);
		}
	}, [
		setCurrentSubscription,
		savedSubscriptionId,
		currentSubscriptionId,
		setCurrentProvider,
		savedProvider,
		currentProvider
	]);

	useEffect(() => {
		if (fetchError || (data && _.isEmpty(data?.me.organization.subscriptions))) {
			error({
				message: `There was an error loading the subscriptions: Error: ${fetchError}`,
				file: 'WatchlistPage',
				functionName: 'useEffect'
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchError, data]);

	const renderOption = (subscription: OrganizationSubscriptionInfo) => (
		<>
			<TwoRowsText id={`${subscription.id}`}>
				<Text color={Colors.dark}> {subscription.description || subscription.id} </Text>
			</TwoRowsText>
			<TwoRowsText>
				<Text color={Colors.darkGray} size={FontSizes.s1}>
					{`Subscription id: ${subscription.id}`}
				</Text>
			</TwoRowsText>
		</>
	);

	const options: DropdownOption[] = data?.me.organization.subscriptions
		.filter(subscription =>
			subscription?.types?.includes(codeFromDocumentType(currentDocType, currentProvider))
		)
		.map(
			(subs, index): DropdownOption => {
				return {
					renderOption: () => renderOption(subs),
					id: subs.id,
					elementId: `${ids.WACTHLIST_PAGE.LENS_DROPDOWN_MENU_FLOW_ID_INDEX}${index}`,
					label: subs.description || subs.id,
					provider: subs.provider // "SNP" "FACTSET" "EDGAR_HTML"
				};
			}
		);

	const currentSubscription = data?.me.organization.subscriptions.find(
		subscription => subscription.id === currentSubscriptionId
	);

	const defaultSubscription = () =>
		data?.me.organization.subscriptions.find(subscription =>
			subscription.types?.includes(codeFromDocumentType(currentDocType, currentProvider))
		);

	const selectedSubscriptionTitle = currentSubscription?.types?.includes(
		codeFromDocumentType(currentDocType, currentProvider)
	)
		? currentSubscription?.description || currentSubscription?.id || ''
		: defaultSubscription()?.description || defaultSubscription()?.id || '';

	useEffect(() => {
		// set the default subscription id and provider
		if ((!currentSubscription || !currentProvider) && options?.length > 0) {
			setCurrentSubscription(options[0].id);
			setCurrentProvider(options[0].provider);
		}
		// eslint-disable-next-line
	}, [currentSubscription, options, currentDocType]);

	if (loading) {
		return (
			<Button id={ids.WACTHLIST_PAGE.SELECT_LENS} appearance={Appearance.secondary} withMenuArrow>
				<FlowName>Loading...</FlowName>
			</Button>
		);
	}

	if (fetchError) {
		return <div>error</div>;
	}

	return (
		<MenuWrapper
			options={options}
			onSelect={item => {
				writeStorage(SUBSCRIPTION_KEY, item);
				writeStorage(PROVIDER_KEY, options.find(subs => subs.id === item).provider);
				setCurrentProvider(options.find(subs => subs.id === item).provider);
				setCurrentSubscription(item);
			}}
			menuPosition={{ right: 0 }}
			selectedOptionId={currentSubscriptionId}
		>
			<Button id={ids.WACTHLIST_PAGE.SELECT_LENS} appearance={Appearance.secondary} withMenuArrow>
				<FlowName id={ids.WACTHLIST_PAGE.LENS_DROPDOWN_MENU_CURRENT_FLOW}>
					{selectedSubscriptionTitle}
				</FlowName>
			</Button>
		</MenuWrapper>
	);
};

export default WatchlistLensNewarc;
