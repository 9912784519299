import * as React from 'react';
import styled from 'styled-components';
import ParagraphText from './paragraphText';
import SpeakerLine from './speakerLine';
import { FinancialSentence } from '../../../queries/autogenerate/schemas';

const SpeakerContainer = styled.div`
	width: 100%;
	margin-bottom: 30px;
`;

export interface Speaker {
	type: string;
	title: string;
	affiliation?: string;
	entity: string;
	name: string;
}

export interface Section {
	speaker: Speaker;
	sentences: FinancialSentence[];
}

interface DiscussionSectionProps {
	sections: Section[];
	printMode: boolean;
	selectedEventId: string;
}

const DiscussionSection: React.FunctionComponent<DiscussionSectionProps> = ({
	sections,
	printMode,
	selectedEventId
}: DiscussionSectionProps) => {
	return (
		<div>
			{sections.map((section, sectionKeyIndex) => (
				<SpeakerContainer key={sectionKeyIndex}>
					<SpeakerLine speaker={section?.speaker} />
					{section?.sentences?.map((sentence, sentenceKeyIndex) => (
						<ParagraphText
							key={sentenceKeyIndex}
							selectedEventId={selectedEventId}
							sentence={sentence}
							printMode={printMode}
						/>
					))}
				</SpeakerContainer>
			))}
		</div>
	);
};

export default DiscussionSection;
