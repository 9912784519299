// @ts-ignore
module.exports = {
	USER_MENU_ID: {
		BACKOFFICE: 'ADMIN_LINK_TOP_RIGHT_MENU',
		LOGOUT: 'USER_LOGOUT_TOP_RIGHT_MENU',
		PLAYGROUND_TOP_RIGHT_MENU: 'PLAYGROUND_TOP_RIGHT_MENU',
		USER_MENU_DROP_DOWN: 'TOGGLE_USER_LOGOUT_TOP_RIGHT_MENU'
	},

	ANNOTATION_MODAL: {
		ADD_ANNOTATION: 'ADD_ANNOTATION',
		APPLY_TO_ADD_ANNOTATION_POPUP: 'APPLY_TO_ADD_ANNOTATION_POPUP',
		AUTOCOMPLETE_DROPDOWN_SEARCH_BAR: 'AUTOCOMPLETE_DROPDOWN_SEARCH_BAR',
		BUTTON_OK: 'BUTTON_OK',
		BUTTON_UNMARK: 'BUTTON_Unmark',
		CLEAR_TO_ADD_ANNOTATION_POPUP: 'CLEAR_TO_ADD_ANNOTATION_POPUP',
		DESCRIPTION_ON_ANNOTATION: 'DESCRIPTION_ON_ANNOTATION',
		EDIT_HIGHLIGHT: 'EDIT_HIGHLIGHT',
		MESSAGE_OVERLAPPING: 'MESSAGE_OVERLAPPING',
		POLARITY_NEGATIVE: 'POLARITY_NEGATIVE',
		POLARITY_POSITIVE: 'POLARITY_POSITIVE',
		TEXT_TO_ANNOTATION_POPUP: 'TEXT_TO_ANNOTATION_POPUP',
		UNMARK_HIGHLIGHT: 'UNMARK_HIGHLIGHT',
		WORD_IN_PARAGRAGH: 'WORD_IN_PARAGRAGH_'
	},

	WACTHLIST_PAGE: {
		AUTOCOMPLETE_DROPDOWN_SEARCH_BAR_RESULTS_IN_YOUR_WATCHLIST:
			'AUTOCOMPLETE_DROPDOWN_SEARCH_BAR_RESULTS_IN_YOUR_WATCHLIST',
		CLOSE_BLOOMBERG_UPLOAD_TICKERS_MODAL_BTN: 'BLOOMBERG_MODAL_BUTTON_CLOSE_ICON',
		DIV_SEARCH_COMPANY_NAME_OR_TICKER: 'DIV_SEARCH_COMPANY_NAME_OR_TICKER',
		EXPAND_ROW_CONTAINER: 'EXPAND_ROW_CONTAINER',
		HIGHTCHART: 'HIGHTCHART',
		KEY_DRIVERS_EMPTY_LABEL: 'KEY_DRIVERS_EMPTY_LABEL',
		KEY_DRIVER_CHART_PREFIX: 'KEY_DRIVER_CHART_',
		LENS_DROPDOWN_MENU_CURRENT_FLOW: 'LENS_DROPDOWN_MENU_CURRENT_FLOW',
		LENS_DROPDOWN_MENU_FLOW_ID: 'LENS_DROPDOWN_MENU_FLOW_ID_',
		LENS_DROPDOWN_MENU_FLOW_ID_INDEX: 'LENS_DROPDOWN_MENU_FLOW_ID_INDEX_',
		LENS_DROPDOWN_MODEL_NAME: 'LENS_DROPDOWN_MODEL_NAME_',
		LENS_DROPDOWN_PROVIDER_NAME: 'LENS_DROPDOWN_PROVIDER_NAME_',
		MICRO_CHART: 'MICRO_CHART',
		NAME_PREFIX: 'COMPANY_NAME_IN_PORTFOLIO_',
		ONBOARDING_RIGHT_COLUMN: 'ONBOARDING_RIGHT_COLUMN',
		OPEN_BLOOMBERG_UPLOAD_TICKERS_MODAL_BTN:
			'VIEWER_UPLOAD_AN_EXISTING_PORTFOLIO_BUTTON_IMPORT_FROM_BLOOMBERG',
		OPEN_BLOOMBERG_UPLOAD_TICKERS_MODAL_DROP_DOWN:
			'VIEWER_UPLOAD_AN_EXISTING_PORTFOLIO_DROP_DOWN_IMPORT_FROM_BLOOMBERG',
		OPEN_BLOOMBERG_UPLOAD_TICKERS_MODAL_EXCEL_BTN:
			'VIEWER_UPLOAD_AN_EXISTING_PORTFOLIO_BUTTON_IMPORT_EXCEL',
		OPEN_BLOOMBERG_UPLOAD_TICKERS_MODAL_EXCEL_DROP_DOWN:
			'VIEWER_UPLOAD_AN_EXISTING_PORTFOLIO_DROP_DOWN_IMPORT_EXCEL',
		PORTFOLIO_FIRST_COMPANY_ELEMENT: 'DELETE_COMPANY_FROM_PORTFOLIO_ROW',
		PORTFOLIO_MODAL_SEARCH_RESULT_ROW: 'PORTFOLIO_MODAL_SEARCH_RESULT_ROW_',
		PORTFOLIO_SCROLLABLE: 'PORTFOLIO_SCROLLABLE',
		PORTFOLIO_SEARCH_FIELD: 'PORTFOLIO_SEARCH_FIELD',
		PORTFOLIO_SEARCH_RESULT_ROW: 'PORTFOLIO_SEARCH_RESULT_ROW',
		PORTFOLIO_TABLE: 'PORTFOLIO_TABLE',
		PORTFOLIO_TABLE_SECTOR: 'PORTFOLIO_TABLE_SECTOR',
		PORTFOLIO_TABLE_TICKER: 'PORTFOLIO_TABLE_TICKER',
		PORTFOLIO_UPLOAD_TICKERS: 'PORTFOLIO_BUTTON_IMPORT_FILE',
		SCORE_CHANGE: 'SCORE_CHANGE',
		SCROLL_ROW: 'SCROLL_ROW',
		SCROLL_ROW_PREFIX: 'SCROLL_ROW_',
		SELECT_LENS: 'SELECT_LENS',
		TICKER_PREFIX: 'NAME_TICKER_IN_PORTFOLIO_',
		TOGGLE_EXPAND_DATA_BY_COMPANYID: 'TOGGLE_EXPAND_DATA_BY_COMPANYID',
		VIEWER_SUB_MENU_API_ID_EARNINGS_CALL: 'VIEWER_SUB_MENU_API_ID_EarningsCall',
		VIEWER_SUB_MENU_ITEM_TEXT_10K: 'VIEWER_SUB_MENU_ITEM_TEXT_10K',
		VIEWER_SUB_MENU_ITEM_TEXT_EARNINGS_CALL: 'VIEWER_SUB_MENU_ITEM_TEXT_Earnings Call'
	},

	TOOLTIP: {
		AMENITY_CHANGE_ICON: 'AMENITY_CHANGE_ICON',
		AMENITY_SCORE_ICON: 'AMENITY_SCORE_ICON',
		AMENITY_SCORE_TOOL_TIP: 'AMENITY_SCORE_TOOL_TIP',
		EXPAND_ROW_TOOL_TIP: 'EXPAND_ROW_TOOL_TIP',
		EXPAND_ROW_TOOL_TIP_ICON: 'EXPAND_ROW_TOOL_TIP_ICON'
	},

	DOCUMENT_PAGE: {
		ANNOTATION_TOOL_TIP: 'ANNOTATION_TOOL_TIP',
		CATEGORY_CALSS: 'CATEGORY_NAME',
		CATEGORY_ITEM: 'category_Item',
		COUNT_NEGATIVE: 'count_negative',
		COUNT_NEUTRAL: 'count_neutral',
		COUNT_POSITIVE: 'count_positive',
		DOCUMENT_DROP_DOWN: 'DOCUMENT_DROP_DOWN_',
		DOCUMENT_LIST_DROP_DOWN: 'DOCUMENT_LIST_DROP_DOWN',
		DOCUMENT_PAGE_EVENTS_CONTAINER: 'DOCUMENT_PAGE_EVENTS_CONTAINER',
		DOCUMENT_PAGE_NAVIGATE_BACK: 'DOCUMENT_PAGE_NAVIGATE_BACK',
		DOCUMENT_PRINT_ICON: 'DOCUMENT_PRINT_ICON',
		DOCUMENT_VIEW_CATEGORY_ITEM: 'DOCUMENT_VIEW_CATEGORY_ITEM',
		EVENTS_10K_TAB: 'EVENTS_10K_TAB',
		EVENTS_LIST_WRAPPER: 'EVENTS_LIST_WRAPPER',
		EVENTS_TAB: 'EVENTS_TAB',
		EVENTS_TRANSCRIPT_TAB: 'EVENTS_TAB',
		EVENT_HOVER: 'EVENT_HOVER_',
		EVENT_NAVIGATION_ITEM: 'event-navigation-',
		EVENT_SCORE: 'EVENT_SCORE_BADGE_',
		EVENT_SORT: 'event_sort',
		EVENT_TOOLTIP: 'EVENT_TOOLTIP',
		MANAGEMENT_DISCUSSION_SECTION: 'MANAGEMENT_DISCUSSION_SECTION',
		MANAGEMENT_DISCUSSION_SECTION_IS_OPEN: 'MANAGEMENT_DISCUSSION_SECTION_IS_OPEN',
		MANAGEMENT_DISCUSSION_TRANSCRIPT_TAB: 'MANAGEMENT_DISCUSSION_TRANSCRIPT_TAB',
		NEGATIVE: 'NEGATIVE',
		NEUTRAL_EXTRACTIONS_CHECKBOX: 'NEUTRAL_EXTRACTIONS_CHECKBOX',
		OPEN_NEW_DOCUMENT: 'OPEN_NEW_DOCUMENT_',
		OPEN_NEW_TAB_NEW_DOCUMENT_ICON: 'OPEN_NEW_TAB_NEW_DOCUMENT_ICON_',
		PARTICIPANTS_TRANSCRIPT_TAB: 'PARTICIPANTS_TRANSCRIPT_TAB',
		POSITIVE: 'POSITIVE',
		QNA_TRANSCRIPT_TAB: 'QNA_TRANSCRIPT_TAB',
		QUESTIONS_AND_ANSWERS_SECTION: 'QUESTIONS_AND_ANSWERS_SECTION',
		QUESTIONS_AND_ANSWERS_SECTION_IS_OPEN: 'QUESTIONS_AND_ANSWERS_SECTION_IS_OPEN',
		RECENT_DOCUMENTS_OPEN: 'RECENT_DOCUMENTS_OPEN_inum_',
		RECENT_DOCUMENT_DROP_DOWN_BUTTON: 'RECENT_DOCUMENT_DROP_DOWN_BUTTON',
		SECTIONS_TAB: 'SECTIONS_TAB',
		SIDE_PANEL_SORT_BY: 'SIDE_PANEL_SORT_BY',
		SORT_BY_EVENT_DOCUMENTS: 'SORT_BY_EVENT_DOCUMENTS',
		TEN_K_DOCUMENT_CONTAINER: 'DOCUMENT_IFRAME',
		TEN_K_EVENTS_SPINNER: '10K_EVENTS_SPINNER_ID',
		TEXT_EVENT: 'EVENT_TEXT_CONTAINER_',
		TEXT_PREFIX_TRANSCRIPT: 'TEXT_EVENT_NAVIGATION_PREFIX_TRANSCRIPT_',
		TITLE_EVENT: 'TITLE_EVENT_OF_',
		TRANSCRIPT_DOCUMENT_CONTAINER: 'TRANSCRIPT_DOCUMENT_CONTAINER'
	},

	BLOOMBERG_MODAL: {
		BLOOMBERG_MODAL_BUTTON_CLOSE_ICON: 'BLOOMBERG_MODAL_BUTTON_CLOSE_ICON',
		FIRST_INSTRUCTION_BLOOMBERG: 'FIRST_INSTRUCTION_BLOOMBERG',
		FIRST_INSTRUCTION_EXCEL: 'FIRST_INSTRUCTION_EXCEL',
		IMPORT_ANOTHER_FILE: 'UPLOAD_SUCCESS_MESSAGE_IMPORT_ANOTHER_FILE',
		NUM_SUCCESSFUL_IMPORTED_TICKERS: 'NUM_SUCCESSFUL_IMPORTED_TICKERS',
		NUM_UNSUCCESSFUL_IMPORTED_TICKERS: 'NUM_UNSUCCESSFUL_IMPORTED_TICKERS',
		SHOW_MY_PORTFOLIO_BTN: 'UPLOAD_SUCCESS_MESSAGE_BUTTON_SHOW_MY_PORTFOLIO',
		UPLOAD_FILE_BTN_BLOOMBERG_MODEL: 'UPLOAD_FILE_BTN_BLOOMBERG_MODEL',
		UPLOAD_FILE_ERROR_TITLE: 'UPLOAD_FILE_ERROR_TITLE',
		UPLOAD_FILE_LINK_TRY_AGAIN: 'UPLOAD_FILE_LINK_TRY_AGAIN',
		VIDEO_BLOOMBERG: 'VIDEO_BLOOMBERG',
		VIDEO_EXCEL: 'VIDEO_EXCEL'
	},

	QUERY_INSIGHTS: {
		CATCH_SEC_CHECKBOX: 'CATCH_SEC_CHECKBOX_',
		CATCH_TRANSCRIPT_CHECKBOX: 'CATCH_TRANSCRIPT_CHECKBOX_',
		DOC_TYPE: 'DOC_TYPE_',
		EVENT_DATE: 'EVENT_DATE_',
		INPUT_FIELD: 'QUERY_INSIGHTS_INPUT_FIELD',
		MODAL_SEC_CHECKBOX: 'QUERY_INSIGHTS_MODAL_SEC_CHECKBOX_',
		MODAL_TRANSCRIPT_CHECKBOX: 'QUERY_INSIGHTS_MODAL_TRANSCRIPT_CHECKBOX_',
		PORTFOLIO_SEARCH_FIELD: 'PORTFOLIO_SEARCH_FIELD',
		PORTFOLIO_SEARCH_RESULT_ROW: 'PORTFOLIO_SEARCH_RESULT_ROW',
		QUERY_INSIGHTS_CAROUSEL_DATA_RANGE: 'QUERY_INSIGHTS_CAROUSEL_DATA_RANGE_',
		QUERY_INSIGHTS_CAROUSEL_ELEMENT_ID: 'QUERY_INSIGHTS_CAROUSEL_ELEMENT_ID_',
		QUERY_INSIGHTS_SEARCH_RESULTS_XMATCHES_LINK: 'QUERY_INSIGHTS_SEARCH_RESULTS_XMATCHES_LINK_',
		SEC_FILINGS_NUMBER: 'SEC_FILINGS_NUMBER',
		SUBMIT_SEARCH_QUERY: 'QUERY_INSIGHTS_SUBMIT_SEARCH_QUERY',
		TABLE_ROW_COUNTER: 'TABLE_ROW_COUNTER',
		TICKER_ROW: 'TICKER_ROW_',
		TOGGLE_MY_PORTFOLIO: 'TOGGLE_MY_PORTFOLIO',
		TOP_MENU_COMPANY_FILTER: 'QUERY_INSIGHTS_TOP_MENU_COMPANY_FILTER_BUTTON',
		TOP_MENU_COMPANY_FILTER_CLEAR: 'QUERY_INSIGHTS_TOP_MENU_COMPANY_FILTER_CLEAR_BUTTON',
		TOP_MENU_COMPANY_FILTER_SAVE: 'QUERY_INSIGHTS_TOP_MENU_COMPANY_FILTER_SAVE_BUTTON',
		TOP_MENU_DATE_RANGE: 'QUERY_INSIGHTS_TOP_MENU_DATE_RANGE_BUTTON',
		TOP_MENU_DATE_RANGE_FORM_OPTION_ID: 'QUERY_INSIGHTS_TOP_MENU_DATE_RANGE_FORM_OPTION_ID_',
		TOP_MENU_DATE_RANGE_SAVE: 'QUERY_INSIGHTS_TOP_MENU_DATE_RANGE_FORM_SAVE',
		TOP_MENU_DOCUMENT_TYPE: 'QUERY_INSIGHTS_TOP_MENU_DOCUMENT_TYPE_BUTTON',
		TOP_MENU_DOCUMENT_TYPE_APPLY: 'QUERY_INSIGHTS_TOP_MENU_DOCUMENT_TYPE_FORM_APPLY',
		TOP_MENU_DOCUMENT_TYPE_CLEAR: 'QUERY_INSIGHTS_TOP_MENU_DOCUMENT_TYPE_FORM_CLEAR',
		TRANSCRIPTS_NUMBER: 'TRANSCRIPTS_NUMBER',
		VIEW_DOCUMENT_BUTTON_QUERY_INSIGHTS: 'VIEW_DOCUMENT_BUTTON_QUERY_INSIGHTS'
	},

	AUTOCOMPLETE_DROPDOWN_SEARCH_BAR: 'AUTOCOMPLETE_DROPDOWN_SEARCH_BAR',

	CURRENT_FLOW_LOADED: 'CURRENT_FLOW_LOADED',
	SEARCH_HIGHLIGHT_STYLE: 'SEARCH_HIGHLIGHT_STYLE',
	SEARCH_SELECTED_HIGHLIGHT_STYLE: 'SEARCH_SELECTED_HIGHLIGHT_STYLE',

	PRE_IMPORT: {
		ERROR: 'PRE_IMPORT_ERROR',
		NAME_INPUT: 'PRE_IMPORT_NAME_INPUT',
		NEXT_BTN: 'PRE_IMPORT_NEXT_BTN'
	},

	MULTIPORTFOLIO: {
		DELETE: 'MULTIPORTFOLIO_DELETE',
		DROPDOWN_OPTION_CREATE_NEW_PORTFOLIO_BTN: 'DROPDOWN_OPTION_CREATE_NEW_PORTFOLIO_BTN',
		EMPTY_STATE: 'MULTIPORTFOLIO_EMPTY',
		EXPORT: 'MULTIPORTFOLIO_EXPORT',
		NEW_PORTFOLIO: 'CREATE_NEW_PORTFOLIO_BTN',
		RENAME: 'MULTIPORTFOLIO_RENAME',
		TOOL_TIP: 'MULTIPORTFOLIO_TOOL_TIP'
	},

	ADD_COMPANY_MODAL: {
		CONFIRM_BTN: 'ADD_COMPANY_MODAL_CONFIRM_BTN',
		ICON_PREFIX: 'ADD_COMPANY_MODAL_ICON_',
		ROW_PREFIX: 'ADD_COMPANY_MODAL_ROW_'
	},

	PRINT_TRANSCRIPT_MODAL: {
		CONFIRM_BTN: 'PRINT_TRANSCRIPT_MODAL_CONFIRM_BTN'
	},

	PRINT_TRANSCRIPT: {
		DOCUMENT_DETAIL: 'PRINT_DOCUMENT_DETAIL',
		KEY_DRIVERS_TITLE: 'EVENTS_COLUMN_TITLE',
		KEY_DRIVER_EVENT: 'EVENT',
		TRANSCRIPT_TITLE: 'PRINT_TRANSCRIPT_TITLE'
	},

	EDIT_BOOKMARK_MODAL: {
		CONFIRM_BTN: 'EDIT_BOOKMARK_MODAL_CONFIRM_BTN',
		ICON_PREFIX: 'EDIT_BOOKMARK_MODAL_ICON_',
		ROW_PREFIX: 'EDIT_BOOKMARK_MODAL_ROW_'
	},

	ADD_COMPANY_BANNER: {
		ADD_BTN: 'ADD_COMPANY_BANNER_ADD_BTN',
		CLOSE_BTN: 'ADD_COMPANY_BANNER_CLOSE_BTN',
		CONTAINER: 'ADD_COMPANY_BANNER_CONTAINER'
	},

	SEE_NEWER_VERSION_BANNER: {
		CLOSE_BTN: 'SEE_NEWER_VERSION_BANNER_CLOSE_BTN',
		CONTAINER: 'SEE_NEWER_VERSION_BANNER_CONTAINER'
	},

	PORTFOLIO_ACTION_MODAL: {
		CANCEL_BTN: 'PORTFOLIO_ACTION_MODAL_CANCEL_BUTTON',
		CONFIRM_BTN: 'PORTFOLIO_ACTION_MODAL_CONFIRM_BUTTON',
		INPUT: 'PORTFOLIO_ACTION_MODAL_INPUT',
		TEXT: 'PORTFOLIO_ACTION_MODAL_TEXT',
		TITLE: 'PORTFOLIO_ACTION_MODAL_TITLE'
	},

	PORTFOLIO_LABEL: {
		BACK_BTN: 'PORTFOLIO_LABEL_BACK_BTN',
		ICON: 'PORTFOLIO_LABEL_ICON',
		INPUT: 'PORTFOLIO_LABEL_INPUT',
		TOOL_TIP: 'PORTFOLIO_LABEL_TOOL_TIP'
	},

	TABLE: {
		ROW_TOOL_TIP: 'TABLE_ROW_TOOL_TIP'
	},

	MESSAGE_MODAL: {
		CANCEL_BTN: 'MESSAGE_MODAL_CANCEL_BTN',
		CONFIRM_BTN: 'MESSAGE_MODAL_CONFIRM_BTN',
		CONTAINER: 'MESSAGE_MODAL_CONTAINER',
		ICON: 'MESSAGE_MODAL_ICON',
		TEXT: 'MESSAGE_MODAL_TEXT',
		TITLE: 'MESSAGE_MODAL_TITLE'
	},

	FETCH_MORE_ROW: {
		FETCH_MORE: 'FETCHMORE'
	},

	CYPRESS: {
		SCROLL_TO_EVENT_QAndA: '/subscription/sub-3ffb01e207/transcript/document/2436218',
		SCROLL_TO_EVENT_URL:
			'/subscription/sub-3ffb01e207/transcript/document/2436218/event/1105_2436218_4952763_103623'
	}
};
