import React, { FC, ReactElement } from 'react';
import styled, { CSSProperties } from 'styled-components';
import {
	calcCompanySentimentChange,
	fractionToPercent,
	calcPercents
} from '../../services/util.service';
import { WACTHLIST_PAGE } from '../../../id.constants';
import { Polarity, Document, PortfolioCompany } from '../../types/types';
import { polarityColor } from '../../../components/shared/util';
import {
	INVALID_VALUE,
	ARROW_CONTAINER_WIDTH,
	DELETE_CONTAINER_WIDTH,
	AMENITY_CHANGE_WIDTH
} from '../../constants';

interface SentimentScoreProps {
	withGraph?: boolean;
}

interface Sentiment {
	transcript: Document;
	company: PortfolioCompany;
	style?: CSSProperties;
	align?: string;
	width?: string;
	withGraph?: boolean;
	id?: string;
	sentimentScoreDisplay: boolean; // true for display , false for display changes
}

interface MultisizeBorderBoxProps {
	width?: string;
	large?: boolean;
	align?: string;
	style?: any;
}
export const MultisizeBorderBox = styled.div<MultisizeBorderBoxProps>`
	box-sizing: border-box;
`;

const SentimentStyle = styled(MultisizeBorderBox)`
	width: ${props =>
		props.width ||
		`calc((100% - ${ARROW_CONTAINER_WIDTH}px  - ${DELETE_CONTAINER_WIDTH}px - ${AMENITY_CHANGE_WIDTH}px) * 0.25)`};
	text-align: ${props => props.align || 'left'};
	position: relative;
	margin-right: 50px;
`;

const SentimentScoreStyle = styled.span<SentimentScoreProps>`
	position: ${props => (props.withGraph ? 'absolute' : '')};
	z-index: ${props => (props.withGraph ? '10' : '0')};
	top: ${props => (props.withGraph ? '-15px' : '')};
	right: ${props => (props.withGraph ? '30px' : '')};
`;

export const Sentiment: FC<Sentiment> = ({
	transcript,
	style,
	align,
	withGraph,
	width,
	id,
	company,
	sentimentScoreDisplay,
	children
}): ReactElement => {
	const getSentimentColor = (val: number) => {
		if (!val || val === INVALID_VALUE) {
			return { color: '' };
		}
		let retval = 'black';
		if (val > 0) {
			retval = polarityColor(Polarity.positive);
		} else if (val < 0) {
			retval = polarityColor(Polarity.negative);
		}
		return { color: retval };
	};

	/**
	 * Going over a transcript and pulls the total score fraction
	 * @param transcript
	 */
	const getTotalScore = (transcript): number => {
		let aggregatedSections: [] = [];
		let totalScore: number;
		transcript &&
			transcript.documentVersions &&
			(aggregatedSections = transcript?.documentVersions[0]?.aggregatedSections);
		aggregatedSections !== []
			? (totalScore = (aggregatedSections?.find((element: any) => element.name === 'total') as any)
					?.score)
			: (totalScore = NaN);

		return totalScore;
	};

	let sentimentDisplay = transcript ? fractionToPercent(getTotalScore(transcript)) : 'N/A';
	if (sentimentDisplay.toString() === 'NaN') {
		sentimentDisplay = 'N/A';
	}

	const sentimentChange = transcript
		? calcCompanySentimentChange(company.documents)
		: { change: 0 };

	const sentimentChangeDisplay = calcPercents(sentimentChange.change, company.documents?.length);

	const sentimentChangeDirection = sentimentChange.change > 0 ? '+' : '';

	const sentimentDisplayColor = transcript
		? getSentimentColor(fractionToPercent(getTotalScore(transcript)))
		: { color: '' };

	const sentimentChangeDisplayColor = getSentimentColor(fractionToPercent(sentimentChange.change));

	const finalStyle: CSSProperties = sentimentScoreDisplay
		? { ...style, ...sentimentDisplayColor }
		: { ...style, ...sentimentChangeDisplayColor };

	const returnObj = sentimentScoreDisplay ? (
		<SentimentStyle
			style={finalStyle}
			align={align}
			width={width && width}
			id={WACTHLIST_PAGE.MICRO_CHART}
		>
			<SentimentScoreStyle withGraph={withGraph}>{sentimentDisplay}</SentimentScoreStyle>
			{children}
		</SentimentStyle>
	) : (
		<SentimentStyle style={finalStyle} align={align} width={width && width}>
			<SentimentScoreStyle id={id}>
				{sentimentChangeDirection}
				{sentimentChangeDisplay}
			</SentimentScoreStyle>
		</SentimentStyle>
	);

	return returnObj;
};
