import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import { IFRAME_DOCUMENT_TYPE, STORE_DATA, STORE_GLOBAL, STORE_UI, TABS } from '../../constants';
import IframeDocument from '../document/iframeDocument';

import DataStore from '../../stores/data.store';
import GlobalStore from '../../stores/global.store';
import UIStore from '../../stores/ui.store';

interface NewsContainerProps {
	history: any;
	location: any;
	match: any;
	[STORE_DATA]?: DataStore;
	[STORE_GLOBAL]?: GlobalStore;
	[STORE_UI]?: UIStore;
}

const NewsContainer: React.FunctionComponent<NewsContainerProps> = (props: NewsContainerProps) => {
	const params = (props.match && props.match.params) || {};
	const { search } = props.location;
	const query = new URLSearchParams(search);
	const flowId = +params.flowId;
	const modelId = +params.modelId;
	const documentId = +params.documentId;
	const eventId = query.get('eventid') || query.get('eventId');

	useEffect(() => {
		const dataStore = props[STORE_DATA];
		const globalStore = props[STORE_GLOBAL];
		const ui = props[STORE_UI] as UIStore;
		dataStore.getEventTypes();
		globalStore.setCurrentTab(TABS.WATCHLIST);
		ui.setSideBarState(false);
		ui.setCanToggleSideBar(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if ((_.isNaN(flowId) && _.isNaN(modelId)) || _.isNaN(documentId)) {
		props.history.push('/not_found');
	}
	const { eventTypes } = props[STORE_DATA];
	if (_.isEmpty(eventTypes)) {
		return <div />;
	}
	return (
		<IframeDocument
			flowId={flowId}
			modelId={modelId}
			documentId={documentId}
			eventId={eventId}
			documentType={IFRAME_DOCUMENT_TYPE.ARTICLE}
		/>
	);
};

export default inject(STORE_DATA, STORE_GLOBAL, STORE_UI)(observer(NewsContainer));
