import { atom, selector } from 'recoil';

const currentSubscription = atom({
	key: 'currentSubscription',
	default: undefined
});

const changeSubscription = selector({
	key: 'changeSubscription',
	get: ({ get }) => get(currentSubscription),
	set: ({ set }, newSubscriptionId: any) => {
		set(currentSubscription, newSubscriptionId);
	}
});

export default changeSubscription;
