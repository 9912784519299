import React, { FunctionComponent, useState } from 'react';
import _ from 'lodash';
import SymbologyAutoCompleteDropDown from './SymbologyAutoCompleteDropDown';
import { useQuery } from '@apollo/client';
import { debounce } from 'lodash';
import { COMPANIES_QUERY } from '../../queries/Symbology.queries';
import { SymbologyCompany } from '../../types/types';

interface SymbologySearchDropDownProps {
	placeholder: string;
	onSelect: Function;
	formatSearchResults: Function;
}

export const SymbologySearch: FunctionComponent<SymbologySearchDropDownProps> = (
	props: SymbologySearchDropDownProps
) => {
	const [searchTerm, setSearchTerm] = useState('');

	const { loading, data, refetch } = useQuery(COMPANIES_QUERY, {
		variables: { searchTerm },
		skip: searchTerm === ''
	});

	const onSearchSymbology = debounce((searchTerm: string) => {
		setSearchTerm(searchTerm);
		refetch({ searchTerm: searchTerm });
	}, 200);

	const restructureEntities = (entitySearchResults: any[]): SymbologyCompany[] => {
		return _.map(entitySearchResults, entity => ({
			id: entity?.id,
			name: entity?.name,
			ticker: entity?.mainIdentifier?.ticker,
			region: entity?.mainIdentifier?.region
		}));
	};
	let results;
	const restructuredEntities = restructureEntities(data?.companyEntitySearch);
	results = props.formatSearchResults(restructuredEntities);

	return (
		<SymbologyAutoCompleteDropDown
			onSearch={onSearchSymbology}
			onSelect={(...params) => {
				props.onSelect(...params);
				setSearchTerm('');
			}}
			results={results}
			placeholder={props.placeholder}
			isDropdownOpen={!!searchTerm}
			resultsLoaded={!loading}
		/>
	);
};
