import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import * as H from 'history';

import { error } from '../../services/logger.service';
import DataService from '../../services/data.service';
import { formatDate } from '../../services/util.service';

import { OutboundEvent, Polarity } from '../../types/types';
import { GENERAL_CATEGORY_NAME } from '../../constants';

import { EventsList } from '../../../components/Print/printPageElements';
import PrintEventElement from '../../../components/Print/printEventElement';
import PrintedPage from '../../../components/Print/printedPage';
import { Text } from '../../shared/styled/text-layout-components';
import { polarityColor } from '../../../components/shared/util';

interface RawArticlePart {
	text: string;
	eventType?: string;
	polarity?: string;
	keyDrivers?: string[];
}

interface RawArticleMetadata {
	title: string;
	publicationTime: string;
	source: string;
	url: string;
}

interface RawArticle {
	metadata: RawArticleMetadata;
	title: RawArticlePart[];
	body: RawArticlePart[];
}

interface Props {
	match?: any;
	location?: H.Location;
}

const polarities = {
	pos: Polarity.positive,
	neg: Polarity.negative,
	neutral: Polarity.neutral
};

const isEvent = (part: RawArticlePart) => !!part.eventType;
const isNeutral = (part: RawArticlePart) => part.polarity === 'neutral';
const isMeaningfulEvent = (part: RawArticlePart, showNeutral: boolean) =>
	isEvent(part) && (showNeutral || !isNeutral(part));

export const extractEvents = (article: RawArticle, showNeutral: boolean) =>
	_.chain(article.title)
		.union(article.body)
		.reduce((init: OutboundEvent[], part: RawArticlePart) => {
			if (!isMeaningfulEvent(part, showNeutral)) {
				return init;
			}
			const index = init.length + 1;
			return init.concat({
				hash: index.toString(),
				id: index,
				typeName: part.eventType,
				keyDrivers: !_.isEmpty(part.keyDrivers) ? part.keyDrivers : [GENERAL_CATEGORY_NAME],
				polarity: part.polarity,
				text: undefined
			});
		}, [])
		.value();

const PrintArticlePage: React.FunctionComponent<Props> = (props: Props) => {
	const params = (props.match && props.match.params) || {};
	const search = props.location?.search || {};
	const flowId = +params.flowId;
	const modelId = +params.modelId;
	const documentId = +params.documentId;
	const query = new URLSearchParams(search);
	const eventId = query.get('eventId');
	const showNeutral = query.get('showNeutral') === 'true';
	const [article, setArticle] = useState(undefined);
	const [events, setEvents] = useState([]);

	useEffect(() => {
		(document as any).fonts.ready.then(() => {
			DataService.getInstance().getRawArticle(
				{ flowId, modelId, documentId, eventId },
				(article: RawArticle) => {
					setArticle(article);
					setEvents(extractEvents(article, showNeutral));
				},
				err => {
					error({
						message: `Error in PrintArticlePage: ${JSON.stringify(err)}`,
						functionName: 'PrintArticlePage',
						file: 'printArticle.tsx'
					});
				}
			);
		});
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (article) {
			setTimeout(() => {
				window.print();
			}, 500);
		}
	}, [article]);

	if (!article) {
		return <div />;
	}

	const renderedEventsList = _.map(events, (event: OutboundEvent) =>
		_.map(event.keyDrivers, keyDriver => (
			<PrintEventElement
				key={`${event.hash}_${keyDriver}`}
				hash={+event.hash}
				polarity={polarities[event.polarity]}
				categoryName={keyDriver}
				typeName={event.typeName}
			/>
		))
	);

	const renderPartialText = (text: string, innerI: number, outerI: number) => (
		<span key={`${outerI}-${innerI}`}>
			{innerI > 0 && <br />}
			{text}
		</span>
	);

	const renderEvent = (event: RawArticlePart, text: any, index: number, key: number) => {
		const polarity = polarities[event.polarity];
		return (
			<Text
				key={key}
				color={polarityColor(polarity)}
				transparentColor={polarityColor(polarity, { transparent: true })}
				isEvent
				isOutbound
				printMode
				polarity={polarity}
				eventIndex={index}
			>
				{text}
			</Text>
		);
	};

	const renderPlainText = (text: JSX.Element[], i: number) => <span key={i}>{text}</span>;

	const renderText = (parts: RawArticlePart[], showNeutral: boolean, startingIndex = 0) => {
		let eventIndex = startingIndex;
		const result = [];
		_.each(parts, (part: RawArticlePart, i: number) => {
			const textWithBreaks = _.map(part.text.split(/\n/gi), (text: string, innerI: number) =>
				renderPartialText(text, innerI, i)
			);
			if (!isMeaningfulEvent(part, showNeutral)) {
				result.push(renderPlainText(textWithBreaks, i));
			} else {
				result.push(renderEvent(part, textWithBreaks, eventIndex, i));
				eventIndex += 1;
			}
		});
		return result;
	};

	const printDetails = [
		{ name: 'Publish Time', value: formatDate(article?.metadata?.publicationTime) },
		{ name: 'Print Time', value: formatDate(new Date()) }
	];

	const numberOfTitleEvents = _.filter(article?.title, (part: RawArticlePart) =>
		isMeaningfulEvent(part, showNeutral)
	).length;

	return (
		<PrintedPage
			title={article?.metadata?.title}
			details={printDetails}
			text={
				<div style={{ lineHeight: '20px', color: 'black' }}>
					{renderText(article?.title, showNeutral)}
					<br />
					<br />
					{renderText(article?.body, showNeutral, numberOfTitleEvents)}
				</div>
			}
			events={<EventsList>{renderedEventsList}</EventsList>}
		/>
	);
};

export default PrintArticlePage;
