import React, { useEffect } from 'react';
import GraphiQL from 'graphiql';
import 'graphiql/graphiql.css';
import { getString } from '../../services/localstorage.service';
import { LOCAL_STORAGE_KEYS, STORE_UI } from '../../constants';
import { GRAPHQL_SERVER_REMOTE_URL } from '../../config';
import UIStore from '../../stores/ui.store';
import { inject, observer } from 'mobx-react';
import './graphql.css';

const Logo = () => <span>API Explorer</span>;
const Toolbar = () => <span />;

// See GraphiQL Readme - Advanced Usage section for more examples like this
GraphiQL.Logo = Logo;
GraphiQL.Toolbar = Toolbar;

const App = props => {
	const jwtToken = getString(LOCAL_STORAGE_KEYS.TOKEN);

	useEffect(() => {
		const ui = props[STORE_UI] as UIStore;
		ui?.setSideBarState(false);
		ui?.setCanToggleSideBar(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<GraphiQL
			style={{ height: '100vh' }}
			fetcher={async graphQLParams => {
				const data = await fetch(GRAPHQL_SERVER_REMOTE_URL(), {
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`
					},
					body: JSON.stringify(graphQLParams),
					credentials: 'same-origin'
				});
				return data.json().catch(() => data.text());
			}}
		/>
	);
};

export default inject(STORE_UI)(observer(App));
