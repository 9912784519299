import { useEffect, useState } from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';

export const QUERY = gql`
	query IsUserSubscribedToDTCEmail {
		me {
			isUserSubscribedToDTCEmailDigest
		}
	}
`;

const SUBSCRIBE = gql`
	mutation SubscribeToDTCEmail {
		subscribeToDTCEmailDigest
	}
`;

const UNSUBSCRIBE = gql`
	mutation UnsubscribeFromDTCEmail {
		unsubscribeFromDTCEmailDigest
	}
`;

export const useDTCSubscribe = () => {
	const [isSubscribed, setIsSubscribed] = useState<boolean>();
	const { loading, data } = useQuery(QUERY);
	useEffect(() => {
		if (!loading) {
			setIsSubscribed(data?.me?.isUserSubscribedToDTCEmailDigest);
		}
	}, [loading, data]);
	const [subscribe] = useMutation(SUBSCRIBE);
	const [unsubscribe] = useMutation(UNSUBSCRIBE);

	const toggleSubscription = async () => {
		if (isSubscribed) {
			await unsubscribe();
		} else {
			await subscribe();
		}

		setIsSubscribed(!isSubscribed);
	};

	return { isSubscribed, toggleSubscription, loading };
};
