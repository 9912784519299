import * as React from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';
import ReactTooltip from 'react-tooltip';

import { TableColumn } from '../../types/types';
import * as TableStyles from './commonTableStyles';
import { TABLE } from '../../../id.constants';

const Cell = styled.div<{ width?: string; padding?: string }>`
	box-sizing: border-box;
	width: ${props => props.width};
	padding: ${props => props.padding};
`;

const TableColumnTooltip = styled(ReactTooltip)`
	padding: 6px 10px;
	min-width: 20px;
	line-height: 10px;
	text-align: center;
	font-size: 12px;
`;

interface TableRowProps {
	columns: TableColumn[];
	row: any;
	onClick: any;
}

const CommonTableRow = (props: TableRowProps) => {
	const { columns, row, onClick } = props;

	const renderCells = (columns: TableColumn[], row: any) =>
		_.map(columns, (column: TableColumn) => (
			<Cell
				width={column.width}
				padding={column.padding}
				className={`ellipsis ${column.primary ? 'underline' : ''}`}
				key={column.name}
				data-for={column.showTooltip ? TABLE.ROW_TOOL_TIP : ''}
				data-tip={row[column.name]}
			>
				{row[column.name]}
			</Cell>
		));

	return (
		<TableStyles.TableRow enabled onClick={onClick}>
			{renderCells(columns, row)}
			<TableColumnTooltip id={TABLE.ROW_TOOL_TIP} type='light' border delayShow={200} />
		</TableStyles.TableRow>
	);
};

export default CommonTableRow;
