import { action, computed, observable } from 'mobx';
import * as _ from 'lodash';

export default class UIStore {
	@observable spinnerCounter = 0;
	@observable portfolioTableExpandedCompanies: (number | string)[] = [];
	@observable isSideBarSubMenuOpen: boolean;
	@observable canToggleSideBarSideMenu = false;

	private static instance: UIStore;

	static getInstance() {
		return UIStore.instance || new UIStore();
	}

	constructor() {
		if (UIStore.instance) {
			return UIStore.instance;
		}
		UIStore.instance = this;
	}

	@action
	setShowSpinner = (value: boolean, override = false) => {
		if (override) {
			this.spinnerCounter = value ? 1 : 0;
		} else {
			this.spinnerCounter += value ? 1 : -1;
		}
	};

	@action
	addExpandedCompany = (companyId: number | string) => {
		this.portfolioTableExpandedCompanies = [...this.portfolioTableExpandedCompanies, companyId];
	};

	@action
	removeExpandedCompany = (companyId: number | string) => {
		this.portfolioTableExpandedCompanies = _.filter(
			this.portfolioTableExpandedCompanies,
			(id: number | string) => id !== companyId
		);
	};

	@action
	resetExpandedCompanies = () => {
		this.portfolioTableExpandedCompanies = [];
	};

	@action
	closeSideBar = () => {
		this.setSideBarState(false);
	};

	@action
	toggleSideBar = () => {
		if (this.canToggleSideBarSideMenu) {
			this.setSideBarState(!this.isSideBarSubMenuOpen);
		}
	};

	@action
	setSideBarState = (open: boolean) => {
		this.isSideBarSubMenuOpen = open;
	};

	@action
	setCanToggleSideBar(canToggle = false) {
		this.canToggleSideBarSideMenu = canToggle;
	}

	@computed
	get isShowSpinner(): boolean {
		return this.spinnerCounter > 0;
	}
}
