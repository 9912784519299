import * as React from 'react';
import styled from 'styled-components';
import { Polarity } from '../../../types/types';
import SentenceText from './sentenceText';
import { FinancialSentence } from '../../../queries/autogenerate/schemas';
import { sortEventsInSentence } from '../transcript.service';

const ParagraphTextContainer = styled.div`
	width: 100%;
	margin-top: 10px;
`;

interface ParagraphTextProps {
	sentence: FinancialSentence;
	printMode: boolean;
	selectedEventId: string;
}

const getTextParts = sentence => {
	const parts = [];
	let startIndex = 0;

	for (const event of sentence?.events) {
		const regularText = sentence.text.substring(startIndex, event.startPosition);
		const regularPart = {
			text: regularText,
			index: startIndex,
			pol: Polarity.neutral,
			isEvent: false
		};
		parts.push(regularPart);

		const eventText = sentence.text.substring(
			event.startPosition,
			event.startPosition + event.text.length
		);
		const eventPart = {
			text: eventText,
			index: event.startPosition,
			pol: event.polarity,
			isEvent: true,
			indexInTranscript: event.indexInTranscript,
			event
		};

		parts.push(eventPart);
		startIndex = event.startPosition + event.text.length;
	}

	const lastText = sentence?.text?.substring(startIndex);
	const lastPart = {
		text: lastText,
		index: startIndex,
		pol: Polarity.neutral,
		isEvent: false
	};
	parts.push(lastPart);

	return parts;
};

const ParagraphText: React.FunctionComponent<ParagraphTextProps> = ({
	selectedEventId,
	sentence,
	printMode
}) => {
	const sentenceWithSortedEvents = sortEventsInSentence(sentence);
	const textParts = getTextParts(sentenceWithSortedEvents);

	return (
		<ParagraphTextContainer key={`Paragraph_${sentenceWithSortedEvents.startPosition}`}>
			{textParts.map((text, textPartKeyIndex) => (
				<SentenceText
					key={`Paragraph_${sentenceWithSortedEvents.startPosition}_${textPartKeyIndex}`}
					selectedEventId={selectedEventId}
					textPart={text}
					printMode={printMode}
				/>
			))}
		</ParagraphTextContainer>
	);
};

export default ParagraphText;
