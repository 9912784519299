import React, { useRef, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { Element } from 'react-scroll';
import _ from 'lodash';
import { Text } from '../../../shared/styled/text-layout-components';
import { getEventPolarity, polarityColor } from '../../../../components/shared/util';
import { EVENT_NAVIGATION_PREFIX_TRANSCRIPT, EVENT_TOOLTIP_ID } from '../../../constants';
import { FinancialEvent } from '../../../queries/autogenerate/schemas';
import * as ids from '../../../../id.constants';
import eventElementsState from '../../../recoil/eventElements';
import { Polarity } from '../../../types/types';

export interface TextPart {
	event?: FinancialEvent;
	index: number;
	text: string;
	pol: Polarity;
	isEvent: boolean;
	eventId?: number;
	indexInTranscript?: number;
}

interface SentenceTextProps {
	textPart: TextPart;
	printMode: boolean;
	selectedEventId: string;
}

const SentenceText: React.FunctionComponent<SentenceTextProps> = ({
	textPart,
	selectedEventId,
	printMode
}) => {
	const [eventElements, setEventElements] = useRecoilState(eventElementsState);
	// keeping a ref to the dom element of the text (if it contains an event)
	// so that it is possible to print the event's key drivers in the same page of the event's text
	const refToEventDomElement = useRef();

	const addElementToGlobalEventsElements = (prevEventsElements: any[]) => {
		const newEventElements = [...prevEventsElements];
		newEventElements.push({
			ref: refToEventDomElement,
			id: textPart.event.id
		});
		return newEventElements;
	};

	// add a ref to the event dom element to the events elements list
	useEffect(() => {
		const needToAddRefToEventsElements =
			printMode && textPart.event?.id && !_.some(eventElements, { id: textPart.event?.id });
		if (needToAddRefToEventsElements) {
			setEventElements(addElementToGlobalEventsElements);
		}
		// Despite eslint warning to add to the dependencies list all of the variables used in the useEffect,
		// some of those variables (namely, printMode, textPart.event, addElementToGlobalEventsElements
		// and setEventElements) are omitted
		// because the are not expected to change
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const wrapWordsInSpan = (text: string): any[] => {
		// eslint-disable-next-line
		const words = text.replace(/\n|\r/g, '').split(/([^,|\.|;|:|-|\s]+)/);
		return _.map(words, (w, i: number) => {
			const element = (
				<span id={ids.ANNOTATION_MODAL.WORD_IN_PARAGRAGH + i} key={`Word_In_Paragraph_${i}`}>
					{w}
				</span>
			);
			return element;
		});
	};

	const getEventTooltipDisplay = (event: FinancialEvent): string => {
		const keydrivers =
			event?.keyDrivers.length !== 0
				? _.chain(event.keyDrivers)
						.map(key => `${key}`)
						.join(', ')
						.value()
				: 'General';

		return `${keydrivers} - ${event?.name}`;
	};

	return textPart.isEvent ? (
		<Text
			key={`Paragraph_Text_${textPart.index}`}
			color={polarityColor(textPart.pol)}
			transparentColor={polarityColor(textPart.pol, { transparent: true })}
			backgroundColor={textPart.event?.id === selectedEventId ? '#E7E7E7' : undefined}
			isEvent={textPart.isEvent}
			printMode={printMode}
			polarity={getEventPolarity(textPart.pol)}
			eventIndex={textPart.indexInTranscript}
			data-tip={getEventTooltipDisplay(textPart.event)}
			data-for={EVENT_TOOLTIP_ID}
			data-event='mouseenter'
			data-event-off='mouseleave'
			id={'TEXT_EVENT_NAVIGATION_PREFIX_TRANSCRIPT_' + textPart.event.id}
			ref={refToEventDomElement}
		>
			<Element
				id={EVENT_NAVIGATION_PREFIX_TRANSCRIPT + textPart.event.id}
				name={textPart.event.id}
				style={{ display: 'inline-block' }}
			/>
			{wrapWordsInSpan(textPart.text)}
		</Text>
	) : (
		<Text
			id={`PARAGRAPH_TEXT_${textPart.index}`}
			key={`Paragraph_Text_${textPart.index}`}
			color={textPart.isEvent ? polarityColor(textPart.pol) : undefined}
			transparentColor={
				textPart.isEvent ? polarityColor(textPart.pol, { transparent: true }) : undefined
			}
			isEvent={textPart.isEvent}
			printMode={printMode}
			polarity={textPart.pol}
			eventIndex={textPart.indexInTranscript}
		>
			{wrapWordsInSpan(textPart.text)}
		</Text>
	);
};

export default SentenceText;
