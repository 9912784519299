import styled from 'styled-components';

import { Polarity } from '../../types/types';
import Images from '../images';
import { Colors } from '../../../components/shared/styles';

const DocumentDetailsContainer = styled.div`
	min-height: 22px;
	padding-bottom: 8px;
	border-bottom: 1px solid #dde5e7;
`;

const DocumentDetail = styled.span`
	display: inline-block;
	height: 17px;
	color: #9b9b9b;
	font-size: 14px;
	line-height: 17px;
	&:not(:last-child) {
		margin-right: 25px;
	}
`;

interface TextProps {
	color?: string;
	transparentColor?: string;
	isEvent?: boolean;
	isOutbound?: boolean;
	printMode?: boolean;
	polarity?: Polarity;
	eventIndex?: number;
	backgroundColor?: string;
}
const Text = styled.span<TextProps>`
	color: ${props => props.color};
	font-weight: ${props => (props.isEvent && !props.printMode ? 'bold' : 'normal')};
	position: relative;
	cursor: ${props => (props.isEvent ? 'pointer' : 'initial')};
	&:before {
		content: ${props =>
			props.printMode && props.isEvent ? "'" + (props.eventIndex + 1) + "'" : ''};
		width: 25px;
		height: 20px;
		border-radius: 100%;
		${({ polarity }) => {
			switch (polarity) {
				case Polarity.positive:
					return 'background-color: rgba(76,175,80,0.6);';
				case Polarity.negative:
					return 'background-color: #F44336;';
				default:
					return `background-color: ${Colors.blue};`;
			}
		}}
		display: inline-block;
		text-align: center;
		font-size: 12px;
		line-height: 20px;
		color: #fff;
		border: 2px solid #fff;
		margin-left: 8px;
		position: relative;
		top: -2px;
	}
	&:after {
		content: ${props => {
			if (props.printMode && props.isEvent) {
				return props.polarity === Polarity.positive
					? `url('${Images.arrowPrintPositive}')`
					: props.polarity === Polarity.negative && `url('${Images.arrowPrintNegative}')`;
			}
			return '';
		}};
		position: absolute;
		left: 0;
		top: ${props => (props.polarity === 1 ? '0' : '1')}px;
	}
	> span {
		background-color: ${props =>
			props.backgroundColor || (props.printMode && props.isEvent && props.transparentColor)};
		color: ${props => props.printMode && props.isEvent && '#000'};
		border-radius: ${props => (props.isOutbound ? '10px' : '')};
		padding: ${props => (props.isOutbound ? '0 5px' : '')};
		&:nth-child(2) {
			padding-left: ${props => props.printMode && props.isEvent && '10px'};
			border-top-left-radius: ${props => props.printMode && props.isEvent && '10px'};
			border-bottom-left-radius: ${props => props.printMode && props.isEvent && '10px'};
		}
		&:nth-last-child(2) {
			border-top-right-radius: ${props => props.printMode && props.isEvent && '10px'};
			border-bottom-right-radius: ${props => props.printMode && props.isEvent && '10px'};
			padding-right: ${props => props.printMode && props.isEvent && '10px'};
		}
	}
`;

export { DocumentDetailsContainer, DocumentDetail, Text };
