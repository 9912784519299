import React, { FC, useEffect, useState } from 'react';
import { LookerEmbedSDK } from '@looker/embed-sdk';
import './dash.css';
import SpinnerWithHide from './SpinnerWithHide';
import DashboardLoader from './DashboardLoader';
import { useDashboardLoadDone } from './DashboardServiceCustomHook';
import DashboardIframeStateService from './dashboardService';
import { IAmenityDashboard } from '../../types/Dashboards.types';
interface DashboardFromSdkWithDashboardLoadProps {
	currentDashboard: IAmenityDashboard;
}

const DashboardFromSdkWithDashboardLoad: FC<DashboardFromSdkWithDashboardLoadProps> = props => {
	// in order to load a dashboard, we need to know how looker id'd it, it's not the same
	// as the id given by lookerizer. Inference it from the embed url
	const lookerBasedDashboardId = props.currentDashboard?.embedUrl
		.split('/')
		.slice(-1)
		.pop();
	const [dashboardService, setDashboardService] = useState<DashboardIframeStateService>(undefined);

	// by using the looker based dashboard id and the internal state of dashboard service,
	// we can know if to show or not show the user looker iframe
	const { showDashboard } = useDashboardLoadDone(dashboardService, lookerBasedDashboardId);

	useEffect(() => {
		// looker instance, must be initialized once for this component
		LookerEmbedSDK.init('dashboards.amenityanalytics.com');

		// the service that handles all the calls from looker and
		// interacts with local storage, we must also initialize it only once
		// since it holds a state of current dashboard and the loading phases
		const service = new DashboardIframeStateService();
		setDashboardService(service);
	}, []);

	useEffect(() => {
		// upon a change by the user to load a different dashboard
		// than the current one displayed
		if (dashboardService && lookerBasedDashboardId) {
			dashboardService.loadNewDashboard(lookerBasedDashboardId);
		}
	}, [lookerBasedDashboardId, dashboardService]);

	return (
		<>
			<SpinnerWithHide isHidden={showDashboard} />
			<DashboardLoader
				isHidden={!showDashboard}
				dashboard={props.currentDashboard}
				dashboardService={dashboardService}
			/>
		</>
	);
};

export default DashboardFromSdkWithDashboardLoad;
