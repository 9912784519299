import { FunctionComponent, default as React } from 'react';
import { STORE_AUTH } from '../../constants';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import AuthStore from '../../stores/auth.store';
import { Colors, FontSizes } from '../../../components/shared/styles';
import arrowDownIcon from '../../../assets/icons/arrow_down.svg';
import profileIcon from '../../../assets/icons/profile.svg';
import { Icon } from '../../../components/Icon/Icon';

const Profile = styled.div`
	display: grid;
	grid-template-columns: 16px auto auto;
	grid-gap: 5px;
	align-items: center;
	cursor: pointer;
`;

const Username = styled.div`
	color: ${Colors.white};
	font-size: ${FontSizes.s2};
`;

interface ProfileMenuProps {
	onClick?(): void;
	authStore: AuthStore;
}

const ProfileMenu: FunctionComponent<ProfileMenuProps> = ({ onClick, authStore }) => (
	<Profile onClick={onClick} id='TOGGLE_USER_LOGOUT_TOP_RIGHT_MENU'>
		<Icon src={profileIcon} width={16} />
		<Username>{authStore.getUsername || ''}</Username>
		<Icon src={arrowDownIcon} width={11} />
	</Profile>
);

export default inject(STORE_AUTH)(observer(ProfileMenu));
