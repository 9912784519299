import React, { RefObject, useState, useEffect } from 'react';
import * as _ from 'lodash';

import { DropdownOption } from '../shared/types';
import { Sizes } from '../shared/styles';
import { MenuWrapper } from '../MenuWrapper/MenuWrapper';
import { Input } from '../Input/Input';

interface Props {
	placeholder?: string;
	defaultValueId?: number | string;
	options?: DropdownOption[];
	disabled?: boolean;
	size?: Sizes;
	elementRef?: RefObject<HTMLInputElement>;
	maxHeight?: number;
	onSelect?: (id: number | string, sectionName: string) => void;
}

export const AutocompleteDropdown: React.FunctionComponent<Props> = ({
	placeholder,
	defaultValueId,
	options = [],
	disabled = false,
	size = Sizes.medium,
	elementRef,
	maxHeight,
	onSelect
}) => {
	const [filteredOptions, setFilteredOptions] = useState(options);
	const [value, setValue] = useState('');

	useEffect(() => {
		const defaultOption = _.find(options, (option: DropdownOption) => option.id === defaultValueId);
		setValue(defaultOption?.label);
	}, [options, defaultValueId]);

	const filterOptions = (value: string) => {
		if (_.isEmpty(value)) {
			setFilteredOptions(options);
		} else {
			const relevantOptions = _.filter(
				options,
				(option: DropdownOption) => option.label.toLowerCase().indexOf(value) > -1
			);
			setFilteredOptions(relevantOptions);
		}
	};

	const onChange = (event: Event) => {
		const target = event?.target as HTMLInputElement;
		const value = target?.value || '';
		setValue(value);
		filterOptions(value.toLowerCase());
	};

	const onSelectWrap = (id: number | string, sectionName: string) => {
		const selectedOption = _.find(options, (option: DropdownOption) => option.id === id);
		setValue(selectedOption?.label);
		onSelect?.(id, sectionName);
	};

	return (
		<MenuWrapper
			options={filteredOptions}
			onSelect={onSelectWrap}
			size={size}
			maxHeight={maxHeight}
		>
			<Input
				placeholder={placeholder}
				value={value || ''}
				disabled={disabled}
				elementRef={elementRef}
				onChange={(event: Event) => onChange(event)}
			/>
		</MenuWrapper>
	);
};
