import * as React from 'react';
import ContentLoader from 'react-content-loader';

export interface EventsNavigationSkeletonProps {
	amount: number;
	marginPercent: number; // must be between 0 and 50 (applied on each side)
	tileHeight: number;
	tileSpacing: number;
	topMarginPixels: number;
}

const EventsNavigationSkeleton: React.FunctionComponent<EventsNavigationSkeletonProps> = ({
	amount,
	marginPercent,
	tileHeight,
	tileSpacing,
	topMarginPixels
}) => {
	const totalHeight = topMarginPixels + amount * (tileHeight + tileSpacing);
	let tileWidth = 100 - marginPercent * 2;
	if (tileWidth <= 0 || tileWidth >= 100) {
		tileWidth = 90;
		marginPercent = 5;
	}

	return (
		<ContentLoader
			speed={2}
			width='100%'
			height={totalHeight}
			backgroundColor='#f3f3f3'
			foregroundColor='#ecebeb'
		>
			{[...Array(amount)].map((_, tileIdx) => {
				const yOffset = topMarginPixels + tileIdx * (tileHeight + tileSpacing);
				return (
					<rect
						key={`event-nav-skeleton-tile-${tileIdx}`}
						x={`${marginPercent}%`}
						y={yOffset}
						rx='5'
						ry='5'
						width={`${tileWidth}%`}
						height={tileHeight}
					/>
				);
			})}
		</ContentLoader>
	);
};

export default EventsNavigationSkeleton;
