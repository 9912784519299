import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import DataStore from '../../stores/data.store';
import GlobalStore from '../../stores/global.store';
import UIStore from '../../stores/ui.store';

import * as DocumentUtil from './document.util';
import {
	STORE_DATA,
	STORE_GLOBAL,
	STORE_UI,
	TABS,
	IFRAME_DOCUMENT_TYPE,
	FEATURES_TYPE_SPLIT
} from '../../constants';

import Document from './transcriptDocument';
import IframeDocument from './iframeDocument';
import { useFeature } from '../../hooks/useFeature';
import { GetSubscriptionData } from './subscription.service';

interface DocumentContainerProps {
	history: any;
	location: any;
	match?: any;
	[STORE_DATA]?: DataStore;
	[STORE_GLOBAL]?: GlobalStore;
	[STORE_UI]?: UIStore;
}

const DocumentContainer: React.FunctionComponent<DocumentContainerProps> = (
	props: DocumentContainerProps
) => {
	const { currentFlow, currentDocumentMetaData, currentDocumentType } = props[STORE_DATA];

	const { search } = props.location;
	const query = new URLSearchParams(search);
	const flowId = query.get('flowId');
	const documentId = query.get('documentId');
	const eventId = query.get('eventid') || query.get('eventId');
	const ffViewerNewarcMigartion = useFeature(FEATURES_TYPE_SPLIT.VIEWER_NEWARC_MIGRATION);
	const subscriptionId = GetSubscriptionData(flowId);

	useEffect(() => {
		if (ffViewerNewarcMigartion === false && (!flowId || !documentId)) {
			props.history.push('/not_found');
			return;
		}

		if (ffViewerNewarcMigartion === true && flowId && documentId && subscriptionId) {
			eventId ? changePageUrl(+flowId, +documentId, eventId) : changePageUrl(+flowId, +documentId);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [flowId, documentId, subscriptionId]);

	useEffect(() => {
		if (!flowId || !documentId) {
			return;
		}
		const dataStore = props[STORE_DATA];
		const globalStore = props[STORE_GLOBAL];
		const ui = props[STORE_UI] as UIStore;
		if (ffViewerNewarcMigartion === false) {
			const isDirectLink = !dataStore.appInitialized;
			dataStore
				.initDocument(+flowId, +documentId)
				.then(() => notifyUserTracker(dataStore, isDirectLink))
				.catch(() => props.history.push('/not_found'));
		}
		dataStore.fetchAllPortfolios();
		globalStore.setCurrentTab(TABS.WATCHLIST);
		ui.setSideBarState(false);
		ui.setCanToggleSideBar(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ffViewerNewarcMigartion]);

	const notifyUserTracker = (dataStore: any, isDirectLink: any) => {
		const { currentFlow, currentDocumentMetaData, currentDocumentType } = dataStore;
		return DocumentUtil.trackDocumentOpened(
			currentFlow,
			currentDocumentMetaData,
			currentDocumentType,
			isDirectLink
		);
	};

	const fetchDocument = (document: any) => {
		const { currentFlow, currentCompany, fetchDocument } = props[STORE_DATA];
		fetchDocument(currentFlow, currentCompany, document);
		changePageUrl(+flowId, document.documentId);
	};

	const changePageUrl = (flowId: number, documentId: number, eventId: string = '') => {
		if (!eventId) {
			const newPath = ffViewerNewarcMigartion
				? `/subscription/${subscriptionId}/transcript/document/${documentId}`
				: `/document?flowId=${flowId}&documentId=${documentId}`;
			props.history.replace(newPath);
		} else {
			const newPath = ffViewerNewarcMigartion
				? `/subscription/${subscriptionId}/transcript/document/${documentId}/event/${eventId}`
				: `/document?flowId=${flowId}&documentId=${documentId}&eventId=${eventId}`;
			props.history.replace(newPath);
		}
	};

	if (!currentDocumentMetaData || currentDocumentMetaData.documentId === -1) {
		return <div />;
	}
	const isTranscriptDocument = DocumentUtil.isDocumentTranscriptType(
		currentFlow,
		currentDocumentMetaData,
		currentDocumentType
	);

	return !isTranscriptDocument ? (
		<IframeDocument
			documentId={+documentId}
			flowId={+flowId}
			documentType={IFRAME_DOCUMENT_TYPE.SEC_FILINGS}
			fetchDocument={fetchDocument}
			eventId={eventId}
		/>
	) : (
		<Document />
	);
};

export default inject(STORE_GLOBAL, STORE_DATA, STORE_UI)(observer(DocumentContainer));
