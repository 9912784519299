import * as React from 'react';
import { Button } from '@amenityllc/amenity-components';

import Modal from '../../../components/Modal/Modal';
import { Sizes, Appearance } from '../../../components/shared/styles';

interface BackOfficeDeletePopUpProps {
	modalIsOpen: boolean;
	closePopup: Function;
	deleteFunc: Function;
	name: string;
}

export default class BackOfficeDeletePopUp extends React.Component<BackOfficeDeletePopUpProps> {
	confirm = () => {
		this.props.deleteFunc();
		this.props.closePopup();
	};

	renderButtons = () => [
		<Button
			key='save'
			id='BACKOFFICE_DELETE_MODAL_YES_BUTTON'
			size={Sizes.medium}
			appearance={Appearance.primary}
			children={'Yes'}
			onClick={() => this.confirm()}
		/>,
		<Button
			key='cancel'
			id='BACKOFFICE_DELETE_MODAL_NO_BUTTON'
			size={Sizes.medium}
			appearance={Appearance.secondary}
			children={'No'}
			onClick={() => this.props.closePopup()}
		/>
	];

	render() {
		return (
			<Modal
				text={`Are you sure you want to delete the selected ${this.props.name}?`}
				isModalOpen={this.props.modalIsOpen}
				modalButtons={this.renderButtons()}
				close={() => this.props.closePopup()}
			/>
		);
	}
}
