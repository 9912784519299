import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Button } from '@amenityllc/amenity-components';

import { ModelFlow } from '../../types/types';
import * as ids from '../../../id.constants.js';
import { parseFlow } from '../../services/util.service';
import { MenuWrapper } from '../../../components/MenuWrapper/MenuWrapper';
import { Text } from '../../../components/Text/Text';
import { Appearance, Colors, FontSizes } from '../../../components/shared/styles';

const Ellipsis = styled.div`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	text-align: left;
`;

const TwoRowsText = styled(Ellipsis)`
	line-height: 20px;
`;

const FlowName = styled(Ellipsis)`
	width: 200px;
`;

interface PortfolioLensProps {
	flows: ModelFlow[];
	currentFlow: ModelFlow;
	handleFlowChange: (flowId: number) => void;
}

const WatchlistLens: React.FunctionComponent<PortfolioLensProps> = ({
	flows,
	currentFlow,
	handleFlowChange
}) => {
	const parsedFlows = _.map(flows, f => parseFlow(f));
	const renderOption = (parsedFlow: any) => (
		<>
			<TwoRowsText id={`${ids.WACTHLIST_PAGE.LENS_DROPDOWN_MENU_FLOW_ID}${parsedFlow.id}`}>
				<Text color={Colors.dark}> {parsedFlow.displayName || parsedFlow.title} </Text>
			</TwoRowsText>
			<TwoRowsText>
				<Text
					color={Colors.darkGray}
					size={FontSizes.s1}
				>{`Flow id: ${parsedFlow.id}   Flow start date: ${parsedFlow.creationDate}   Model name: ${parsedFlow.title}   Provider name: ${parsedFlow.providerName}`}</Text>
			</TwoRowsText>
		</>
	);

	const getFlowPrimaryDisplayNameById = (flowId: number, flows: ModelFlow[]) => {
		const flow = _.find(flows, f => f.id === flowId);
		const parsedFlow = parseFlow(flow);
		return parsedFlow.displayName !== '' && parsedFlow.displayName
			? parsedFlow.displayName
			: parsedFlow.title;
	};

	const onSelect = id => {
		handleFlowChange(id);
	};

	const options = _.map(parsedFlows, (pf, index) => {
		return {
			renderOption: () => renderOption(pf),
			id: pf.id,
			elementId: `${ids.WACTHLIST_PAGE.LENS_DROPDOWN_MENU_FLOW_ID_INDEX}${index}`
		};
	});

	return (
		<MenuWrapper
			options={options}
			onSelect={onSelect}
			menuPosition={{ right: 0 }}
			selectedOptionId={currentFlow && currentFlow.id}
		>
			<Button id={ids.WACTHLIST_PAGE.SELECT_LENS} appearance={Appearance.secondary} withMenuArrow>
				<FlowName id={ids.WACTHLIST_PAGE.LENS_DROPDOWN_MENU_CURRENT_FLOW}>
					{!currentFlow ? '' : getFlowPrimaryDisplayNameById(currentFlow.id, flows)}
				</FlowName>
			</Button>
		</MenuWrapper>
	);
};

export default WatchlistLens;
