import React, { FC, ReactElement, useEffect, useState } from 'react';
import { WATCHLISTS_QUERY } from '../../queries/Watchlist.queries';
import { useQuery } from '@apollo/client';
import { error } from '../../services/logger.service';
import Spinner from '../../widgets/Spinner/spinner';
import styled from 'styled-components';
import { LookerWatchlist } from 'app/types/Watchlist.types';

const Select = styled.select`
	width: 100%;
	height: 100%;
	background: white;
	color: gray;
	padding-left: 5px;
	font-size: 14px;
	margin-left: 10px;
	border: 1px solid rgb(222, 225, 229);

	option {
		color: black;
		background: white;
		display: flex;
		white-space: pre;
		min-height: 20px;
		padding: 0px 2px 1px;
	}
`;

const DropdownWrapper = styled.div<LookerWatchlistsDropDownProps>`
	position: absolute;
	z-index: 1000;
	width: 15%;
	height: 35px;
	left: 60%;
	top: 22px;

	visibility: ${props => (props.isHidden ? 'hidden' : 'visible')};
`;

interface CompanyEntity {
	mainIdentifier: {
		ticker: string;
		region: string;
	};
}

type SetTickers = (tickers: string[]) => void;

interface LookerWatchlistsDropDownProps {
	setTickers?: SetTickers;
	isHidden: boolean;
}

const LookerWatchlistsDropDown: FC<LookerWatchlistsDropDownProps> = props => {
	const [selectOptions, setSelectOptions] = useState([]);
	const [selectedWatchlistId, setSelectedWatchlistId] = useState<string | undefined>(undefined);
	const { setTickers, isHidden } = props;

	const { error: fetchError, data, loading } = useQuery(WATCHLISTS_QUERY, {
		fetchPolicy: 'cache-and-network'
	});

	useEffect(() => {
		if (fetchError) {
			error({
				message: `There was an error loading the watchlists, Error: ${fetchError}`,
				file: 'PortfolioProvider',
				functionName: 'useEffect'
			});
		}
	}, [fetchError, data]);

	/**
	 * Creating dropdown with the user's watchlists
	 */
	useEffect(() => {
		const dropdownOptions: ReactElement[] = data?.watchlists.map((watchlist: LookerWatchlist) => (
			<option key={watchlist.id} value={watchlist.id}>
				{watchlist.name}
			</option>
		));
		dropdownOptions?.unshift(
			<option key='default' value=''>
				Select Watchlist
			</option>
		);
		setSelectOptions(dropdownOptions);
	}, [data]);

	const onSelect = event => {
		setSelectedWatchlistId(event.target.value);
	};

	/**
	 *  Get all the tickers of the selected watchlist
	 */
	useEffect(() => {
		const extractedTickers = (watchlist: LookerWatchlist) =>
			watchlist.companyEntities.map(
				(company: CompanyEntity) =>
					`${company?.mainIdentifier?.ticker}:${company?.mainIdentifier?.region}`
			);

		const selectedWatchlist: LookerWatchlist = data?.watchlists.find(
			(watchlist: LookerWatchlist) => watchlist.id === selectedWatchlistId
		);
		selectedWatchlist && setTickers(extractedTickers(selectedWatchlist));
	}, [selectedWatchlistId, data, setTickers]);

	if (loading) {
		return <Spinner />;
	}

	return (
		<DropdownWrapper isHidden={isHidden}>
			<Select onChange={onSelect}>{selectOptions}</Select>
		</DropdownWrapper>
	);
};

export default LookerWatchlistsDropDown;
