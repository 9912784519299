import * as React from 'react';
import styled from 'styled-components';
import Images from '../../shared/images';

const SpinnerContainer = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Background = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #fff;
	opacity: 0.25;
`;

const SpinnerImg = styled.img`
	background-color: #fff;
	opacity: 0.25;
`;

const Spinner = () => (
	<SpinnerContainer id='MAIN_SPINNER'>
		<Background />
		<SpinnerImg src={Images.spinner} alt='spinner gif' />
	</SpinnerContainer>
);

export default Spinner;
