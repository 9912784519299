import * as React from 'react';
import moment from 'moment';
import { getUserLocalTimeFromUTC } from '../../helpers.timezone';
import { DATETIME_FORMAT } from '../../constants';
import {
	DocumentDetail,
	DocumentDetailsContainer
} from '../../shared/styled/text-layout-components';
import styled from 'styled-components';

const DocumentHeaderContainer = styled.div`
	height: 50px;
	padding: 20px 0 0 20px;
	box-sizing: border-box;
	min-width: 550px;
`;

const format = (date: string) => moment(date).format(DATETIME_FORMAT);

export const Details = ({
	eventTime,
	analysisTime,
	publicationTime
}: {
	eventTime?: string;
	analysisTime?: string;
	publicationTime?: string;
}) => {
	return (
		<DocumentHeaderContainer>
			<DocumentDetailsContainer>
				{eventTime && (
					<DocumentDetail>Event Time: {getUserLocalTimeFromUTC(eventTime)}</DocumentDetail>
				)}
				{analysisTime && <DocumentDetail>Analysis Time: {format(analysisTime)}</DocumentDetail>}
				{publicationTime && (
					<DocumentDetail>Publish Time: {format(publicationTime)}</DocumentDetail>
				)}
			</DocumentDetailsContainer>
		</DocumentHeaderContainer>
	);
};
