const loginPromise = ({ webAuth, password, username }) =>
	new Promise<any>((resolve, reject) => {
		if (!webAuth) {
			reject(new Error('Missing WebAuth'));
		}
		webAuth.client.login(
			{
				realm: 'Username-Password-Authentication',
				username,
				password,
				scope: 'openid',
				responseType: 'id_token'
			},
			(error, response) => {
				if (error) {
					return reject(error);
				}
				return resolve(response);
			}
		);
	});

const googleLogin = ({ webAuth }) =>
	new Promise<any>((resolve, reject) => {
		if (!webAuth) {
			reject(new Error('Missing WebAuth'));
		}
		const splittedUrl = document.URL.split('/');
		const loginPart = splittedUrl[splittedUrl.length - 1];
		const redirectUri = encodeURI(document.URL.replace(loginPart, ''));
		webAuth.authorize({
			connection: 'google-oauth2',
			redirectUri: redirectUri,
			clientID: 'ixKqorWADVr41EWZ2WGoWhUzNCLDAZXt',
			responseType: 'id_token'
		});
	});

const universalLogin = ({ webAuth }): any => {
	if (!webAuth) {
		throw new Error('Missing WebAuth');
	}
	const redirectUri = document.location.origin;
	webAuth.authorize({
		redirectUri,
		clientID: 'ixKqorWADVr41EWZ2WGoWhUzNCLDAZXt',
		scope: 'openid email profile',
		responseType: 'token id_token'
	});
};

const logoutPromise = ({ webAuth }) => {
	if (!webAuth) {
		return;
	}
	const id = Math.floor(Math.random() * 1000000); // Add random param to prevent caching of logout page
	webAuth.logout({
		returnTo: encodeURI(`${window.location.origin}/logout?id=${id}`)
	});
};

const userInfoPromise = ({ webAuth, accessToken }) =>
	new Promise((resolve, reject) => {
		if (!webAuth) {
			reject(new Error('Missing WebAuth'));
		}
		webAuth.client.userInfo(accessToken, (err, profile) => {
			if (err) {
				return reject(err);
			}
			return resolve(profile);
		});
	});

const signupPromise = ({ email, password, webAuth, first_name, last_name }) =>
	new Promise((resolve, reject) => {
		if (!webAuth) {
			reject(new Error('Missing WebAuth'));
		}
		webAuth.signup(
			{
				connection: 'Username-Password-Authentication',
				email,
				password,
				user_metadata: { first_name, last_name }
			},
			(err, profile) => {
				if (err) {
					return reject(err);
				}
				return resolve(profile);
			}
		);
	});

const resetPasswordPromise = ({ webAuth, email }) =>
	new Promise((resolve, reject) => {
		if (!webAuth) {
			reject(new Error('Missing WebAuth'));
		}
		webAuth.client.dbConnection.changePassword(
			{
				connection: 'Username-Password-Authentication',
				email
			},
			(err, response) => {
				if (err) {
					return reject(err);
				}
				return resolve(response);
			}
		);
	});

const updateUserMetadataPromise = ({ webAuth, email }) =>
	new Promise((resolve, reject) => {
		if (!webAuth) {
			reject(new Error('Missing WebAuth'));
		}
		webAuth.updateUserMetadata(
			{
				connection: 'Username-Password-Authentication',
				email
			},
			(err, response) => {
				if (err) {
					return reject(err);
				}
				return resolve(response);
			}
		);
	});

export {
	loginPromise,
	userInfoPromise,
	logoutPromise,
	googleLogin,
	signupPromise,
	resetPasswordPromise,
	updateUserMetadataPromise,
	universalLogin
};
