import { gql } from '@apollo/client';

export const WATCHLISTS_QUERY = gql`
	query Watchlists {
		watchlists {
			id
			userId
			name
			companyEntities {
				id
				mainIdentifier {
					ticker
					region
				}
			}
		}
	}
`;

export const GET_WATCHLIST = gql`
	query GetWatchlist($watchlistId: String) {
		watchlist(id: $watchlistId) {
			name
			id
			companyEntities {
				id
			}
		}
	}
`;

export const GET_WATCHLIST_WITH_DATA = gql`
	query GetWatchlistWithData($watchlistId: String) {
		watchlist(id: $watchlistId) {
			name
			id
			companyEntities {
				id
				mainIdentifier {
					ticker
					region
				}
			}
		}
	}
`;

export const RENAME_WATCHLIST = gql`
	mutation RenameWatchlist($id: String, $newName: String) {
		renameWatchlist(id: $id, newName: $newName) {
			id
		}
	}
`;

export const DELETE_WATCHLIST = gql`
	mutation DeleteWatchlist($id: String) {
		deleteWatchlist(id: $id) {
			id
		}
	}
`;

export const CREATE_WATCHLIST = gql`
	mutation CreateWatclist($name: String) {
		createWatchlist(name: $name) {
			id
		}
	}
`;

export const ADD_COMPANY_TO_WATCHLIST = gql`
	mutation AddCompanyToWatchlist($portfolioId: String, $factsetId: String) {
		addCompanyToWatchlist(id: $portfolioId, company: $factsetId) {
			id
			companyEntities {
				id
			}
		}
	}
`;

export const ADD_COMPANIES_TO_WATCHLIST = gql`
	mutation AddCompaniesToWatchlist($id: String, $companies: [String!]) {
		addCompaniesToWatchlist(id: $id, companies: $companies) {
			id
		}
	}
`;

export const REMOVE_COMPANY_FROM_WATCHLIST = gql`
	mutation RemoveCompanyFromWatchlist($portfolioId: String, $factsetId: String) {
		removeCompanyFromWatchlist(id: $portfolioId, company: $factsetId) {
			id
			companyEntities {
				id
			}
		}
	}
`;
