import React from 'react';
import * as _ from 'lodash';

import { DocumentDetail } from '../../app/shared/styled/text-layout-components';
import {
	PrintWrap,
	SubHeader,
	TranscriptDetailsContainerPrint,
	ColumnsGrid,
	TextColumn,
	EventsColumn,
	EventsTitle
} from './printPageElements';
import { LogoAppearance } from '../shared/styles';
import { Logo } from '../Logo/Logo';
import styled from 'styled-components';
import * as ids from '../../id.constants';

interface PrintDetail {
	name: string;
	value: string;
}

interface Props {
	title: string;
	details: PrintDetail[];
	text: any;
	events: any;
	popup?: any;
	spinner?: any;
}

const LogoContainer = styled.div`
	padding-top: 20px;
	padding-bottom: 90px;
`;

const PrintedPage: React.FunctionComponent<Props> = (props: Props) => {
	const details = _.map(props.details, (detail: PrintDetail) => (
		<DocumentDetail
			key={detail.name}
			id={`${ids.PRINT_TRANSCRIPT.DOCUMENT_DETAIL}${detail.name.replace(/\s/g, '')}`}
		>
			{detail.name}: {detail.value}
		</DocumentDetail>
	));

	return (
		<PrintWrap>
			{props.popup}
			{props.spinner}
			<LogoContainer>
				<Logo logoAppearance={LogoAppearance.DarkGroup} />
			</LogoContainer>
			<SubHeader id={ids.PRINT_TRANSCRIPT.TRANSCRIPT_TITLE}>{props.title}</SubHeader>
			<ColumnsGrid>
				<TranscriptDetailsContainerPrint>{details}</TranscriptDetailsContainerPrint>
				<TextColumn>{props.text}</TextColumn>
				<EventsColumn>
					<EventsTitle id={ids.PRINT_TRANSCRIPT.KEY_DRIVERS_TITLE}>Key Drivers</EventsTitle>
					{props.events}
				</EventsColumn>
			</ColumnsGrid>
		</PrintWrap>
	);
};

export default PrintedPage;
