import * as React from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';
import Modal from 'react-modal';
import { Button } from '@amenityllc/amenity-components';

import './toggle.css';
import { getPolarityIndex, isEventChanged } from '../../services/util.service';
import { Category, Event } from '../../types/types';
import { GENERAL_CATEGORY_NAME } from '../../constants';
import * as ids from '../../../id.constants.js';
import { PolarityToggle } from '../../../components/PolarityToggle/PolarityToggle';
import { Polarity, DropdownOption } from '../../../components/shared/types';
import {
	Colors,
	Sizes,
	FontFamilies,
	FontSizes,
	Appearance
} from '../../../components/shared/styles';
import { AutocompleteDropdown } from '../../../components/AutocompleteDropdown/AutocompleteDropdown';
import { TextArea } from '../../../components/TextArea/TextArea';
import { Text } from '../../../components/Text/Text';
import ModalWithHeader from '../../../components/ModalWithHeader/ModalWithHeader';

const ModalDimensions = {
	width: 530,
	height: 556
};

const customStyles = {
	overlay: {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 10,
		backgroundColor: 'rgba(0, 0, 0, 0.5)'
	},
	content: {
		padding: '0',
		border: 'none',
		borderRadius: '0px',
		width: ModalDimensions.width,
		height: ModalDimensions.height + 70, // Dropdown is overflowing on bottom, so added height so it will be visible
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		transform: 'translate(-50%, -50%)',
		overflow: 'hidden',
		backgroundColor: 'rgba(0, 0, 0, 0)'
	}
};

const ModalContainer = styled.div`
	box-sizing: border-box;
	height: ${ModalDimensions.height + 6}px;
`;

const ModalBody = styled.div`
	height: 100%;
	padding: 20px 30px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const HighlightedText = styled(Text)`
	background-color: ${Colors.transparentWarning};
`;

const SectionTitle = styled(Text)`
	margin-bottom: 10px;
`;

const DropDownContainer = styled.div`
	width: 240px;
	cursor: pointer;
`;

const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: row-reverse;
`;

const ActionsContainer = styled.div`
	width: 260px;
	display: flex;
	justify-content: space-around;
`;

interface AnnotationPopUpProps {
	closePopup: Function;
	confirmPopup: Function;
	eventCategories: Category[];
	eventData: Event;
	textOffset: number;
	isInEdit: boolean; // tells if the annotation is now on edit status or creation status
}

interface AnnotationPopUpState {
	polarity: Polarity;
	description: string;
}

export default class AnnotationPopUp extends React.Component<
	AnnotationPopUpProps,
	AnnotationPopUpState
> {
	eventCategories: DropdownOption[] = [];
	autocomplete = React.createRef<HTMLInputElement>();
	readonly defaultPolarity: Polarity = Polarity.positive;

	constructor(props: AnnotationPopUpProps) {
		super(props);
		this.eventCategories = _.map(_.compact(this.props.eventCategories), (category: Category) => ({
			label: category.name,
			id: category.id,
			elementId: `annotation-popup-category-${category.id}`
		}));
		this.state = {
			polarity: this.props.isInEdit ? this.props.eventData.polarity : this.defaultPolarity,
			description: this.props.eventData.description
		};
		Modal.setAppElement('#root');
	}

	onChangePolarity = (polarity: Polarity) => {
		this.setState({ polarity });
	};

	onChangeDescription = e => {
		const currentDescription = e.target.value;
		this.setState({ description: currentDescription });
	};

	onConfirm = () => {
		const updatedFields = {
			polarity: this.state.polarity,
			categoryName: this.autocomplete?.current?.value,
			description: this.state.description
		};

		if (this.props.isInEdit && !isEventChanged(updatedFields, this.props.eventData)) {
			this.props.closePopup();
			return;
		}

		const updatedEvent: Event = { ...this.props.eventData };
		updatedEvent.startIndex = this.props.eventData.startIndex;
		updatedEvent.endIndex = this.props.eventData.endIndex;
		updatedEvent.categoryName = this.autocomplete?.current?.value;
		updatedEvent.polarity = getPolarityIndex(this.state.polarity);
		updatedEvent.description = this.state.description;

		this.props.confirmPopup(updatedEvent, this.props.isInEdit);
	};

	getPreviewText = (text: string): string => {
		if (!text) {
			return;
		}
		const charCount = 180;
		return text.length < charCount ? text : text.substr(0, charCount) + '...';
	};

	getDefaultSelection = (categories: Category[], selectedDataEvent: Event): number => {
		const categoryName: string = selectedDataEvent?.categoryName || GENERAL_CATEGORY_NAME;
		const category = _.find(categories, (category: Category) => category.name === categoryName);
		return category?.id;
	};

	render() {
		return (
			<ModalWithHeader
				title='Add Annotation'
				isModalOpen={true}
				close={() => this.props.closePopup()}
				modalStyle={customStyles}
			>
				<ModalContainer>
					<ModalBody>
						<div>
							<SectionTitle
								block
								lineHeight={20}
								color={Colors.dark}
								fontFamily={FontFamilies.semiBold}
								size={FontSizes.s3}
							>
								Highlighted text
							</SectionTitle>
							<HighlightedText
								id={ids.ANNOTATION_MODAL.TEXT_TO_ANNOTATION_POPUP}
								size={FontSizes.s2}
								color={Colors.dark}
								lineHeight={19}
							>
								{this.getPreviewText(this.props.eventData.text)}
							</HighlightedText>
						</div>
						<TextArea
							id={ids.ANNOTATION_MODAL.DESCRIPTION_ON_ANNOTATION}
							onChange={e => this.onChangeDescription(e)}
							defaultValue={this.state.description}
							placeholder='Why is this important?'
						/>
						<div>
							<SectionTitle
								block
								lineHeight={20}
								color={Colors.dark}
								fontFamily={FontFamilies.semiBold}
								size={FontSizes.s3}
							>
								Annotation category
							</SectionTitle>
							<DropDownContainer>
								<AutocompleteDropdown
									options={this.eventCategories}
									placeholder='Select category'
									defaultValueId={this.getDefaultSelection(
										this.props.eventCategories,
										this.props.eventData
									)}
									size={Sizes.small}
									elementRef={this.autocomplete}
									maxHeight={185}
								/>
							</DropDownContainer>
						</div>
						<div>
							<SectionTitle
								block
								lineHeight={20}
								color={Colors.dark}
								fontFamily={FontFamilies.semiBold}
								size={FontSizes.s3}
							>
								Annotation sentiment
							</SectionTitle>
							<PolarityToggle
								selectedPolarity={this.state.polarity}
								onSelect={this.onChangePolarity}
							/>
						</div>
						<ButtonsContainer>
							<ActionsContainer>
								<Button
									appearance={Appearance.primary}
									onClick={() => this.onConfirm()}
									id={ids.ANNOTATION_MODAL.APPLY_TO_ADD_ANNOTATION_POPUP}
								>
									Apply
								</Button>
								<Button
									appearance={Appearance.secondary}
									onClick={() => this.props.closePopup()}
									id={ids.ANNOTATION_MODAL.CLEAR_TO_ADD_ANNOTATION_POPUP}
								>
									Clear
								</Button>
							</ActionsContainer>
						</ButtonsContainer>
					</ModalBody>
				</ModalContainer>
			</ModalWithHeader>
		);
	}
}
