import React from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';

import { EventListCategory } from '../../types/types';
import Images from '../../shared/images';
import { Badge, BadgeType } from '../../../components/Badge/Badge';
import { EventsCount, OpacityWrapper, Text } from '../../pages/sideBarElements';
import { APP_COLORS } from '../../constants';
import * as ids from '../../../id.constants.js';
import { Colors, FontSizes } from '../../../components/shared/styles';
import EventsContainer from './EventsContainer';

interface CategoryItemProps {
	clickable: boolean;
	paddingLeft: number;
	eventsNum: number;
	isOpen: boolean;
}

const CategoryItem = styled.div<CategoryItemProps>`
	position: relative;
	height: auto;
	cursor: ${props => (props.clickable ? 'pointer' : 'unset')};
	box-sizing: border-box;
	&:after {
		position: absolute;
		content: '';
		height: 1px;
		bottom: 0px;
		left: 0px;
		right: 0px;
		box-shadow: 0 -1px 0 0 #dde5e7, 0 1px 0 0 #dde5e7;
	}
	transition: height 0.5s;
	overflow: hidden;
`;

interface CategoryTitleWrapperProps {
	open: boolean;
	marginLeft: number;
}
const CategoryTitleWrapper = styled.div<CategoryTitleWrapperProps>`
	margin-left: ${props => props.marginLeft}px;
	cursor: inherit;
	padding: 13px 0px 13px 25px;
	&:after {
		content: ${props => (props.open ? "''" : 'none')} '';
		position: absolute;
		left: 1px;
		top: -1px;
		right: 0px;
		height: 85px;
		border-bottom: 1px solid #eeeeee;
		box-shadow: 0 -1px 0 0 #dde5e7, 0 1px 0 0 #dde5e7;
	}
`;

const CategoryTitle = styled.div`
	color: ${APP_COLORS.BLACK_32313B};
	font-family: Assistant SemiBold;
	font-size: 15px;
	margin-bottom: 5px;
`;

const PolarityCount = styled.div`
	display: inline-block;
	color: #4a4a4a;
	font-size: 15px;
	height: 30px;
	&:not(:last-child) {
		margin-right: 20px;
	}
`;

interface ArrowContainerProps {
	posRight: boolean;
}
const ArrowContainer = styled.div<ArrowContainerProps>`
	position: absolute;
	top: 53px;
	right: ${props => (props.posRight ? '42px' : 'unset')};
	left: ${props => (props.posRight ? 'unset' : '22px')};
`;

interface ArrowProps {
	isOpen: boolean;
	myUrl: string;
}
const Arrow = styled.div<ArrowProps>`
	width: 13px;
	height: 8px;
	transform: ${props => (props.isOpen ? 'rotate(-180deg)' : '')};
	mask-image: url(${props => props.myUrl});
	mask-size: contain;
	mask-position: center;
	-webkit-mask-repeat: no-repeat;
	background-color: ${props => (props.isOpen ? Colors.blue : Colors.dark)};
	transition: transform 0.5s, background-color 0.5s;
`;

const InfoIcon = styled.img.attrs({
	src: Images.infoIcon
})`
	position: relative;
	top: 4px;
	height: 17px;
	width: 17px;
	margin-left: 5px;
	z-index: 1;
`;

interface Props {
	category: EventListCategory;
	arrowPosRight?: boolean;
	selectedEventId?: number | string;
	showTag?: boolean;
	clickableEvents?: boolean;
	showFullSentence?: boolean;
	similarEventsLoaded?: boolean;
	showNeutralExtractions?: boolean;
	onEventSelection?: Function;
	toggleCategory?: Function;
}

const SidebarEventCategory: React.FunctionComponent<Props> = ({
	category,
	arrowPosRight,
	selectedEventId,
	showTag,
	clickableEvents,
	showFullSentence,
	showNeutralExtractions,
	onEventSelection,
	toggleCategory
}) => {
	const isSubsection = category.events[0] && category.events[0].isSubsection;
	return (
		<CategoryItem
			id={`category_Item_${category.id}`}
			paddingLeft={showFullSentence ? 22 : 25}
			onClick={() => toggleCategory(category.id)}
			clickable={clickableEvents}
			eventsNum={category.events.length}
			isOpen={category.open}
			data-event={`EVENT_ITEM_${category.id}`}
		>
			<ArrowContainer posRight={arrowPosRight}>
				<Arrow myUrl={Images.arrowDownGrey} isOpen={category.open} />
			</ArrowContainer>
			<CategoryTitleWrapper open={category.open} marginLeft={arrowPosRight ? 0 : 40}>
				<OpacityWrapper opacity={_.some(category.events, event => !event.hide) ? '1' : '0.3'}>
					<CategoryTitle className={ids.DOCUMENT_PAGE.CATEGORY_CALSS}>
						{category.name}
						{category.documentViewDescription && (
							<InfoIcon
								data-tip={category.documentViewDescription}
								data-for='DOCUMENT_VIEW_TOOL_TIP'
							/>
						)}
					</CategoryTitle>
					<div>
						{isSubsection ? (
							<PolarityCount>
								<EventsCount
									backgroundColor={'#FFF'}
									textColor={APP_COLORS.SUBSECTION}
									border={`1.5px solid ${APP_COLORS.SUBSECTION}`}
								>
									{category.positiveCount + category.negativeCount}
								</EventsCount>
								<Text color={`${APP_COLORS.BLACK_32313B}`} fontSize={16} marginLeft={5}>
									Subsections
								</Text>
							</PolarityCount>
						) : (
							<div>
								{category.positiveCount > 0 && (
									<PolarityCount>
										<Badge type={BadgeType.outline} color={Colors.positive} fontSize={FontSizes.s3}>
											{category.positiveCount}
										</Badge>
										<Text
											id={ids.DOCUMENT_PAGE.POSITIVE}
											color={`${APP_COLORS.BLACK_32313B}`}
											fontSize={16}
											marginLeft={5}
										>
											{'Pos'}
										</Text>
									</PolarityCount>
								)}
								{category.negativeCount > 0 && (
									<PolarityCount>
										<Badge type={BadgeType.outline} color={Colors.negative} fontSize={FontSizes.s3}>
											{category.negativeCount}
										</Badge>
										<Text
											id={ids.DOCUMENT_PAGE.NEGATIVE}
											color={`${APP_COLORS.BLACK_32313B}`}
											fontSize={16}
											marginLeft={5}
										>
											{'Neg'}
										</Text>
									</PolarityCount>
								)}
								{showNeutralExtractions &&
									category.events?.length > category.positiveCount + category.negativeCount && (
										<PolarityCount>
											<Badge type={BadgeType.outline} color={Colors.blue} fontSize={FontSizes.s3}>
												{category.events?.length -
													(category.positiveCount + category.negativeCount)}
											</Badge>
											<Text
												id={ids.DOCUMENT_PAGE.NEGATIVE}
												color={`${APP_COLORS.BLACK_32313B}`}
												fontSize={16}
												marginLeft={5}
											>
												Neutral
											</Text>
										</PolarityCount>
									)}
							</div>
						)}
					</div>
				</OpacityWrapper>
			</CategoryTitleWrapper>
			<EventsContainer
				show={category.open}
				events={category.events}
				categoryId={category.id}
				selectedEventId={selectedEventId}
				showTag={showTag}
				showFullSentence={showFullSentence}
				onEventSelection={onEventSelection}
			/>
		</CategoryItem>
	);
};

export default SidebarEventCategory;
