import { gql, useQuery } from '@apollo/client';

export const QUERY = gql`
	query GetOrgFeatures {
		me {
			organization {
				features {
					analytics
					dtc
				}
			}
		}
	}
`;

export const useOrganizationFeatures = () => {
	const { data, loading } = useQuery(QUERY);

	if (loading) {
		return undefined;
	}

	return data?.me?.organization?.features || { dtc: false, analytics: false };
};
