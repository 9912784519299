export const eventStatusToDropdownId = (status: number): number => {
	let res = -1;
	switch (status) {
		case 0:
			res = 1;
			break;
		case 1:
			res = 2;
			break;
		case 2:
			res = 3;
			break;
		case 3:
			res = 4;
			break;
		default:
			res = 0;
			break;
	}
	return res;
};

export const dropdownIdToEventStatus = (id: number): number => {
	let res = -1;
	switch (id) {
		case 0:
			res = -1;
			break;
		case 1:
			res = 0;
			break;
		case 2:
			res = 1;
			break;
		case 3:
			res = 2;
			break;
		case 4:
			res = 3;
			break;
		default:
			res = -1;
			break;
	}
	return res;
};
