import React from 'react';
import styled from 'styled-components';

import Modal from '../../../components/Modal/Modal';
import { Appearance, FontFamilies, FontSizes, Sizes } from '../../../components/shared/styles';
import { MESSAGE_MODAL } from '../../../id.constants';
import { Button } from '@amenityllc/amenity-components';

const ContactUsModalText = styled.div`
	color: #4a4a4a;
	font-family: ${FontFamilies.regular};
	font-size: ${FontSizes.s4};
	margin-top: 20px;
`;

interface ContactSalesModalProps {
	isModalOpen: boolean;
	closeModal: () => void;
}

const ContactSalesModal: React.FunctionComponent<ContactSalesModalProps> = ({
	isModalOpen,
	closeModal
}) => {
	const contactUs = () => {
		window.location.href = 'mailto:contactus@amenityanalytics.com';
	};

	const renderContent = () => (
		<>
			<ContactUsModalText>GET ACCESS TO ANALYTICS FEATURES</ContactUsModalText>
			<ContactUsModalText>View dashboards with our unique datasets</ContactUsModalText>
		</>
	);

	const renderModalButtons = () => [
		<Button
			key='ACTION_MODAL_CONTACT_SALES'
			id={MESSAGE_MODAL.CONFIRM_BTN}
			size={Sizes.medium}
			appearance={Appearance.primary}
			text={'Contact Us'}
			onClick={() => contactUs()}
		/>
	];

	return (
		<Modal
			isModalOpen={isModalOpen}
			modalContent={renderContent()}
			modalButtons={renderModalButtons()}
			close={closeModal}
		/>
	);
};

export default ContactSalesModal;
