import React from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';

import { Colors, FontSizes } from '../shared/styles';
import { decodeHTMLEntities, getEventPolarity, polarityColor } from '../shared/util';
import { Event, Polarity } from '../shared/types';
import { Text } from '../Text/Text';
import { PolarityDot } from '../PolarityDot/PolarityDot';
import { Tag } from '../Tag/Tag';
import { EventScoreBadge } from '../../app/pages/sideBarElements';
import { useFeature } from '../../app/hooks/useFeature';
import { FEATURES_TYPE_SPLIT } from '../../app/constants';
import * as ids from '../../id.constants';

interface OpacityWrapperProps {
	opacity: string;
}
export const OpacityWrapper = styled.div<OpacityWrapperProps>`
	opacity: ${props => props.opacity};
`;

interface EventItemStyledProps {
	isSelected?: boolean;
	name?: string;
}
const EventItemStyled = styled.div<EventItemStyledProps>`
	padding: 16px 25px 0 25px;
	background-color: ${props => (props.isSelected ? Colors.lightBlue : '')};
	border-left: ${props => (props.isSelected ? `4px solid ${Colors.blue}` : '')};
`;

const TopLine = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const TagWrap = styled.div<{ width: string }>`
	width: ${({ width }) => width};
`;

interface EventDataWrapperProps {
	isLastChild: boolean;
}
const EventDataWrapper = styled.div<EventDataWrapperProps>`
	border-bottom: ${props => (props.isLastChild ? '' : `1px solid ${Colors.lightGray}`)};
	padding-bottom: '6px';
`;

const EventSentence = styled.div`
	padding: 8px 0;
	display: flex;
	align-items: baseline;
	justify-content: space-between;
`;

const SectionName = styled.div`
	max-width: 40%;
`;

const TextWithWidth = styled(Text)`
	width: calc(100% - 20px);
`;

const HighlightedSentence = styled.div`
	width: calc(100% - 20px);
`;

interface SentenceChunk {
	text: string;
	pol: Polarity;
	isEvent: boolean;
	eventId: number;
	indexInTranscript?: number;
}

interface EventItemProps {
	event?: Event;
	index?: number;
	eventTypeName?: string;
	isSelected?: boolean;
	isLastChild?: boolean;
	showSection?: boolean;
	showFullSentence?: boolean;
	showTag?: boolean;
	isSubsection?: boolean;
	splitSentence?: SentenceChunk[];
	tagId?: string;
	onEventSelection?: Function;
}

export const EventItem: React.FunctionComponent<EventItemProps> = ({
	event,
	index = 0,
	eventTypeName = '',
	isLastChild = false,
	isSelected = false,
	showSection = false,
	showFullSentence = false,
	showTag = false,
	isSubsection = false,
	splitSentence = [],
	tagId = '',
	onEventSelection
}) => {
	// used below to determine whether to show event score or not
	const ffSecFilingsV2 = useFeature(FEATURES_TYPE_SPLIT.VIEWER_SEC_FILINGS_V2);
	const getSentenceTitle = (str: string) => {
		const arr = str
			.replace(/^\s*\n/gm, '')
			.substring(0, 203)
			.split('\n');
		if (arr.length > 5) {
			arr.length = 5;
		}
		let retval = arr.join('\n');
		if (retval.length > 200) {
			retval = retval.concat('...');
		}
		return retval;
	};

	const twoLinesSentence = (text: string, id: number): any => {
		const decodedString = decodeHTMLEntities(text);
		const getTitle = getSentenceTitle(decodedString);
		return (
			<TextWithWidth
				title={getTitle}
				size={FontSizes.s3}
				color={Colors.dark}
				ellipsis
				numberOfLines={2}
				className='TEXT_EVENT'
				id={`EVENT_TEXT_CONTAINER_${id}`}
			>
				{decodedString}
			</TextWithWidth>
		);
	};

	const highlightedSentence = (splittedText: any[], isSubsection: boolean): any => {
		if (!splittedText) {
			return;
		}
		return (
			<HighlightedSentence>
				{_.map(splittedText, (textPart: SentenceChunk, i: number) => {
					const decodedString = decodeHTMLEntities(textPart.text);
					return (
						<TextWithWidth
							key={`event_text_${i}`}
							color={textPart.isEvent ? polarityColor(textPart.pol, { isSubsection }) : Colors.dark}
						>
							{decodedString}
						</TextWithWidth>
					);
				})}
			</HighlightedSentence>
		);
	};

	const onEventSelectionInner = (domEvent: any, event: Event) => {
		domEvent.stopPropagation();
		if (!onEventSelection) {
			return;
		}
		onEventSelection(event);
	};

	const eventPolarity = getEventPolarity(event.polarity);
	const shouldShowSection = showSection && event.section;
	const eventScore = parseFloat(
		event.properties?.filter(property => property.key === 'Score')[0]?.value
	)?.toFixed(1);

	return (
		<EventItemStyled
			id={`event-navigation-${event.id}`}
			name={`event-navigation-${index}`}
			isSelected={isSelected}
			onClick={domEvent => onEventSelectionInner(domEvent, event)}
		>
			<OpacityWrapper opacity={event.hide ? '0.3' : '1'}>
				<EventDataWrapper isLastChild={isLastChild}>
					{showTag && (
						<TopLine>
							<TagWrap width={shouldShowSection ? '50%' : '100%'}>
								<Tag
									text={decodeHTMLEntities(eventTypeName)}
									title={eventTypeName}
									id={tagId + event.id}
									color={Colors.darkGray}
								/>
								{ffSecFilingsV2 && eventScore !== undefined && eventScore !== 'NaN' && (
									<EventScoreBadge
										color={Colors.darkGray}
										fontSize={FontSizes.s2}
										id={`${ids.DOCUMENT_PAGE.EVENT_SCORE}${event.id}`}
									>
										{eventScore}
									</EventScoreBadge>
								)}
							</TagWrap>
							{shouldShowSection && (
								<SectionName>
									<Text title={`Section: ${event.section}`} color={Colors.darkGray} ellipsis>
										{event.section}
									</Text>
								</SectionName>
							)}
						</TopLine>
					)}
					<EventSentence>
						<PolarityDot polarity={eventPolarity} isSubsection={isSubsection} />
						{!showFullSentence && twoLinesSentence(event.text, event.id)}
						{showFullSentence && highlightedSentence(splitSentence, isSubsection)}
					</EventSentence>
				</EventDataWrapper>
			</OpacityWrapper>
		</EventItemStyled>
	);
};
