import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

import { Appearance, FontSizes } from '../shared/styles';
import { Text } from '../Text/Text';

export const StyledLink = styled.a<{ underlineHover: boolean }>`
	cursor: pointer;
	text-decoration: none;
	&:hover > * {
		text-decoration: ${({ underlineHover }) => underlineHover && 'underline'};
	}
`;

export enum Underline {
	none,
	hover,
	permanent
}

export interface LinkProps {
	id?: string;
	title?: string;
	size?: FontSizes;
	underline?: Underline;
	children?: ReactNode;
	href?: string;
	onClick?(): void;
	className?: string;
}

export const Link: FunctionComponent<LinkProps> = ({
	id,
	title,
	size = FontSizes.s3,
	children,
	href,
	onClick,
	className,
	underline = Underline.permanent
}) => (
	<StyledLink
		id={id}
		href={href}
		target={href && '_blank'}
		onClick={() => onClick && onClick()}
		className={className}
		underlineHover={underline === Underline.hover}
		title={title}
	>
		<Text
			appearance={underline === Underline.permanent ? Appearance.secondary : Appearance.primary}
			size={size}
		>
			{children}
		</Text>
	</StyledLink>
);
