import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';
import { Button } from '@amenityllc/amenity-components';

import { PRINT_LAYOUTS, LOCAL_STORAGE_KEYS } from '../../constants';
import { PrintLayout } from '../../types/types';
import { getObject, setObject, setBoolean } from '../../services/localstorage.service';

import Modal from '../../../components/Modal/Modal';
import Radio from '../../../components/Radio/Radio';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { Text } from '../../../components/Text/Text';
import { Colors } from '../../../components/shared/styles';
import * as ids from '../../../id.constants';

const StyledText = styled(Text)`
	margin-bottom: 20px;
	text-align: start;
`;

const RadioButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 75px;
	margin-bottom: 35px;
`;

interface PrintPopupProps {
	dismiss: (selectedLayout: PrintLayout) => void;
}

const PrintPopup: React.FunctionComponent<PrintPopupProps> = ({ dismiss }) => {
	const [showPopupAgain, setShowPopupAgain] = useState(false);
	const [selectedLayout, setSelectedLayout] = useState(PRINT_LAYOUTS[0]);

	useEffect(() => {
		const savedPrintLayout = getObject(LOCAL_STORAGE_KEYS.SELECTED_PRINT_LAYOUT);
		const actualPrintLayout =
			savedPrintLayout &&
			_.find(PRINT_LAYOUTS, (layout: any) => layout.label === savedPrintLayout.label);
		if (actualPrintLayout) {
			setSelectedLayout(actualPrintLayout);
		}
	}, []);

	const renderButtons = () => (
		<Button id={ids.PRINT_TRANSCRIPT_MODAL.CONFIRM_BTN} key={'ok'} text={'OK'} onClick={close} />
	);

	const getModalText = () => `
    Select paper size for print callouts alignment: \n
    Make sure the printer settings are set to the same paper size selected.
  `;

	const renderContent = () => (
		<>
			<StyledText color={Colors.black}>{getModalText()}</StyledText>
			<RadioButtonContainer>
				{_.map(PRINT_LAYOUTS, (layout: PrintLayout) => (
					<Radio
						key={layout.label}
						onClick={() => setSelectedLayout(layout)}
						checked={selectedLayout.label === layout.label}
					>
						{layout.label}
					</Radio>
				))}
			</RadioButtonContainer>
			<Checkbox checked={showPopupAgain} onChange={setShowPopupAgain}>
				Don't show this message again
			</Checkbox>
		</>
	);

	const close = () => {
		setObject(LOCAL_STORAGE_KEYS.SELECTED_PRINT_LAYOUT, selectedLayout);
		if (showPopupAgain) {
			setBoolean(LOCAL_STORAGE_KEYS.DONT_SHOW_PRINT_NOTICE, true);
		}
		dismiss(selectedLayout);
	};

	return (
		<Modal
			modalContent={renderContent()}
			modalButtons={renderButtons()}
			isModalOpen={true}
			close={close}
		/>
	);
};

export default PrintPopup;
