import { action, observable } from 'mobx';
import * as _ from 'lodash';
import {
	App,
	AppSheet,
	AnalyticsErrorTypes,
	AnalyticsError,
	AnalyticsDirectLinkParams,
	Bookmark,
	Watchlist
} from '../types/types';
import { USER_TRACKING } from '../constants';
import { ANALYTICS_ERRORS } from '../dashboard.constants';
import UserTracking from '../services/userTracking.service';
import { error } from '../services/logger.service';
import {
	calcErrorType,
	isAnalyticsTab,
	setFiltersForApp,
	setBookmarkForApp,
	saveBookmark,
	deleteBookmark,
	getAppBookmarks,
	checkIfSameSheet,
	checkIfSameApp
} from './analytics.store.util';
import UIStore from './ui.store';
import DataService from '../services/data.service';
import WatchlistService from '../services/watchlist.service';

const UTC = USER_TRACKING.USER_TRACKING_CATEGORIES;
const UTA = USER_TRACKING.USER_TRACKING_ACTIONS;
export default class AnalyticsStore {
	@observable initStarted = false;
	@observable apps: App[];
	@observable currentApp: App;
	@observable currentAppSheet: AppSheet;
	@observable iframeSrc: string;
	@observable uiLoaded = false;
	@observable analyticsError: AnalyticsError;
	@observable watchlistsFilter: Watchlist[] = undefined;
	@observable initParams: AnalyticsDirectLinkParams = undefined;

	analyticsLoadInitiated = false;
	getRequireCount = 0;

	private static instance: AnalyticsStore;

	static getInstance() {
		return AnalyticsStore.instance || new AnalyticsStore();
	}

	constructor() {
		if (AnalyticsStore.instance) {
			return AnalyticsStore.instance;
		}
		AnalyticsStore.instance = this;
	}

	@action
	setInitParams = (params?: AnalyticsDirectLinkParams) => {
		this.initParams = params;
	};

	@action
	init = async () => {
		if (this.initStarted) {
			console.log('init started already');
			return;
		}
		console.log('init started');
		this.initStarted = true;
	};

	@action
	setCurrentApp = (app: App) => {
		this.currentApp = app;
	};

	@action
	updateAppData = (updatedApp: App) => {
		this.apps = _.map(this.apps, (app: App) => {
			return app.id === updatedApp.id ? updatedApp : app;
		});
		if (this.currentApp?.id === updatedApp.id) {
			this.currentApp = updatedApp;
		}
	};

	@action
	onAnalyticsError = (errorMessage: string) => {
		error({
			message: `onAnalyticsError(), ${JSON.stringify(errorMessage)}`,
			file: 'analytics.store',
			functionName: 'onAnalyticsError'
		});
		const analyticsErrorType: AnalyticsErrorTypes = calcErrorType(errorMessage);
		switch (analyticsErrorType) {
			case AnalyticsErrorTypes.ON_LICENSE_ACCESS_DENIED:
				this.setNoLicenseError();
				break;
			case AnalyticsErrorTypes.NETWORK:
				this.setNetworkError();
				break;
			case AnalyticsErrorTypes.UNKNOWN:
				this.setUnknownError();
				break;
			case AnalyticsErrorTypes.NO_DATA_TO_EXPORT:
				this.setNewAnalyticsError(ANALYTICS_ERRORS.NO_DATA_TO_EXPORT);
		}
		if (isAnalyticsTab(document.location.pathname)) {
			UIStore.getInstance().setShowSpinner(false, true);
		}
	};

	@action
	handleAnalyticsError = (handle: boolean = true) => {
		switch (this.analyticsError.type) {
			case AnalyticsErrorTypes.UNKNOWN:
			case AnalyticsErrorTypes.NETWORK:
			case AnalyticsErrorTypes.EMPTY_WATCHLIST:
				this.resetAnalyticsError();
				break;
			default:
				this.resetAnalyticsError();
				return;
		}
	};

	@action
	setIframesCrashedError = () => {
		this.iframeSrc = undefined;
		this.setNewAnalyticsError(ANALYTICS_ERRORS.IFRAMES_CRASHED);
	};

	@action
	setNoLicenseError = () => {
		UIStore.getInstance().setSideBarState(false);
	};

	@action
	getRequireJsByDocument = (requireSrcUrl: string) => {
		console.log(`getting ${requireSrcUrl}`);
		const scriptElem = document.createElement('script');
		scriptElem.type = 'text/javascript';
		scriptElem.src = requireSrcUrl;
		scriptElem.referrerPolicy = 'no-referrer';
		scriptElem.async = true;
		scriptElem.crossOrigin = 'use-credentials';
		scriptElem.onerror = error => {
			// @ts-ignore
			Rollbar?.error('Failed to load require.js');
		};

		scriptElem.onload = ev => {
			console.info('loaded require.js');
			this.init();
		};
		const head = document.getElementsByTagName('head')[0];
		head.appendChild(scriptElem);
	};

	@action
	getRequire = async () => {
		if (this.analyticsLoadInitiated || this.getRequireCount > 10) {
			return;
		}
		this.analyticsLoadInitiated = true;
		this.getRequireCount += 1;
	};

	@action
	setNetworkError = () => {
		this.analyticsLoadInitiated = false;
		console.log('network error, trying to get require js');
		this.getRequire();
	};

	@action
	setUnknownError = () => {
		this.turnOffAllAppSpinners();
		this.setNewAnalyticsError(ANALYTICS_ERRORS.UNKNOWN);
	};

	@action
	resetAnalyticsError = () => {
		this.analyticsError = undefined;
	};

	@action
	setNewAnalyticsError = (error: AnalyticsError) => {
		this.analyticsError = error;
	};

	@action
	setApps = (apps: App[]) => {
		this.apps = apps;
	};

	@action
	turnOffAllAppSpinners = () => {
		this.apps = _.map(this.apps, a => {
			return { ...a, loadingSpinner: false };
		});
	};

	@action
	changeSheet = (selectedApp: App, appSheet: AppSheet) => {
		if (
			checkIfSameApp(selectedApp, this.currentApp) &&
			checkIfSameSheet(appSheet, this.currentAppSheet)
		) {
			return;
		}
		this.setCurrentApp(selectedApp);
	};
	@action
	changeAppOpenState = (app: App) => {
		this.apps = _.map(this.apps, a => {
			return a.id === app.id ? { ...app, isOpen: !a.isOpen, loadingSpinner: false } : a;
		});
	};

	@action
	changeAppSpinnerState = (app: App, state?: boolean) => {
		this.apps = _.map(this.apps, a => {
			return a.id === app.id ? { ...app, loadingSpinner: state ?? true } : a;
		});
	};

	@action
	toggleApp = (app: App) => {
		this.changeAppOpenState(app);
	};

	@action
	handleBookmarkClick = (bookmark: Bookmark) => {
		const ut = new UserTracking();
		if (_.isEmpty(this.currentApp) || _.isEmpty(bookmark)) {
			this.onAnalyticsError('No app or corrupted bookmark');
		} else {
			ut.setEvent(UTC.ANALYTICS, UTA.LOAD_FILTERS, `${bookmark.title}_${this.currentApp.name}`, {
				dashboardName: this.currentApp.name
			});
			setBookmarkForApp(this.currentApp.appObj, bookmark.id);
		}
	};

	@action
	setWatchlistFilter = async (watchlistId: string, watchlistCompanies?: []) => {
		const ut = new UserTracking();
		const ws = new WatchlistService();
		ut.setEvent(UTC.ANALYTICS, UTA.PORTFOLIO_LOADED, this.currentApp.name, {
			dashboardName: this.currentApp.name
		});
		const app = this.currentApp;
		try {
			const companies = watchlistCompanies ?? (await ws.fetchUserCompanies(watchlistId));
			if (_.isEmpty(companies)) {
				this.setNewAnalyticsError(ANALYTICS_ERRORS.EMPTY_WATCHLIST);
			} else {
				const companiesTickers: any = _.map(companies, c => `${c.ticker}:${c.region}`);
				setFiltersForApp(app.appObj, 'ticker', companiesTickers);
			}
		} catch (err) {
			this.onAnalyticsError(err);
		}
	};

	@action
	saveFilters = (filterName: string): Promise<any> => {
		if (_.isEmpty(this.currentApp) || _.isEmpty(filterName)) {
			this.onAnalyticsError(`App doesn't exist or filterName wasn't specified`);
			return Promise.resolve();
		} else {
			return saveBookmark(this.currentApp.appObj, this.currentAppSheet.id, filterName)
				.then(() => {
					const ut = new UserTracking();
					ut.setEvent(UTC.ANALYTICS, UTA.SAVE_FILTERS, this.currentApp.name, {
						dashboardName: this.currentApp.name
					});
					return getAppBookmarks(this.currentApp.appObj);
				})
				.then(updatedBookmarks => this.updateAppBookmarks(updatedBookmarks))
				.catch(err => this.onAnalyticsError(err));
		}
	};

	@action
	deleteFilters = async (deletedBookmarks: Bookmark[], newBookmarks: Bookmark[]): Promise<any> => {
		if (_.isEmpty(this.currentApp) || _.isEmpty(deletedBookmarks[0].title)) {
			this.onAnalyticsError(`App doesn't exist or filterName wasn't specified`);
			return Promise.resolve();
		} else {
			try {
				await Promise.all(
					_.map(deletedBookmarks, deletedBookmark =>
						deleteBookmark(this.currentApp.appObj, this.currentAppSheet.id, deletedBookmark.id)
					)
				);

				const ut = new UserTracking();
				ut.setEvent(UTC.ANALYTICS, UTA.SAVE_FILTERS, this.currentApp.name, {
					dashboardName: this.currentApp.name
				});
				this.updateAppBookmarks(newBookmarks);
			} catch (err) {
				this.onAnalyticsError(err);
			}
		}
	};

	@action
	fetchWatchlistFilters = () => {
		const ds = DataService.getInstance();
		ds.getAllPortfolios(
			{ withoutSize: true },
			(portfolios: any) => {
				this.setWatchlistsFilter(portfolios);
			},
			(err: any) => {
				error({
					message: `Failed fetching all portfolios, ${JSON.stringify(err)}`,
					functionName: 'fetchAllPortfolios',
					file: 'analytics.store'
				});
			}
		);
	};

	@action
	setWatchlistsFilter = (watchlists: Watchlist[]) => {
		this.watchlistsFilter = watchlists;
	};

	updateAppBookmarks = (newBookmarks: Bookmark[]) => {
		const updatedApp = { ...this.currentApp };
		updatedApp.bookmarks = newBookmarks;
		this.updateAppData(updatedApp);
		this.setCurrentApp(updatedApp);
	};

	formatFilters = (select: string[], bookmark: string) => {
		let filters = '';
		filters += select ? `&select=clearall&select=${_.join(_.castArray(select), '&select=')}` : '';
		filters += bookmark ? `&bookmark=${bookmark}` : '';
		return filters;
	};

	@action
	setUILoaded = (loaded: boolean) => {
		this.uiLoaded = loaded;
	};
}
