import * as React from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';
import { Colors, FontFamilies } from '../../../components/shared/styles';
import Images from '../../shared/images';
import SearchIconSVG from '../../../assets/icons/search.svg';
import { Icon } from '../../../components/Icon/Icon';

const SearchBarContainer = styled.div`
	height: 40px;
	width: 300px;
	border-bottom: 1px solid ${Colors.darkGray};
	font-size: 16px;
	font-family: ${FontFamilies.regular};
	float: left;
	color: ${Colors.gray};
	position: relative;
	> input {
		background-color: transparent;
		border: none;
		margin-top: 9px;
		height: 30px;
		font-size: 14px;
		width: 60%;
		outline: none;
		padding: 0 10px;
		&::placeholder {
			font-style: italic;
			color: ${Colors.gray};
		}
	}
	> #matches {
		font-size: 12px;
		color: ${Colors.darkGray};
		position: absolute;
		right: 0;
		margin-top: 8px;
	}
`;

const ClearSearchBtn = styled.img`
	position: absolute;
	cursor: pointer;
	width: 10px;
	right: 13px;
	top: 20px;
`;

const SearchNavigation = styled.div`
	display: inline-block;
`;

interface ArrowContainerProps {
	isDisabled: boolean;
	isUp: boolean;
}
const ArrowContainer = styled.div<ArrowContainerProps>`
	display: inline-block;
	position: relative;
	top: 5px;
	height: 21px;
	width: 21px;
	text-align: center;
	background-image: url(${Images.arrowDownBlackBorder});
	background-position: center;
	background-repeat: no-repeat;
	transform: ${props => (props.isUp ? 'rotate(180deg)' : '')};
	cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
	pointer-events: ${props => (props.isDisabled ? 'none' : '')};
	opacity: ${props => (props.isDisabled ? '0.3' : '1')};
`;

const SearchIcon = styled(Icon)`
	position: relative;
	margin-left: 4px;
	top: 4px;
`;

interface SearchBarDocumentProps {
	navigateSearch: Function;
	searchText: Function;
	selectedMatch: number;
	totalMatches: number;
}

export default class SearchBarDocument extends React.Component<SearchBarDocumentProps, {}> {
	searchInputRef: any;
	searchBarRef: any;

	componentWillUnmount() {
		this.unSubscribeToEvents();
	}

	subscribeToEvents = () => {
		document.addEventListener('mousedown', this.handleSearchBarClick, true);
		window.onkeypress = e => {
			const key = e.keyCode ? e.keyCode : e.which;
			const shift = e.shiftKey;
			if (shift && key === 13 && this.searchInputRef && !_.isEmpty(this.searchInputRef.value)) {
				this.props.navigateSearch(-1);
			} else if (key === 13 && this.searchInputRef && !_.isEmpty(this.searchInputRef.value)) {
				this.props.navigateSearch(1);
			}
		};
	};

	unSubscribeToEvents = () => {
		document.removeEventListener('mousedown', this.handleSearchBarClick, true);
		window.onkeyup = undefined;
	};

	clearSearch = () => {
		this.searchInputRef.value = '';
		this.props.searchText('');
		this.searchInputRef.focus();
	};

	handleSearchBarClick = (e: any) => {
		if (this.searchBarRef && this.searchBarRef.contains(e.target)) {
			return;
		}
		this.unSubscribeToEvents();
	};

	navigateSearch = (direction: number) => {
		this.props.navigateSearch(direction);
		this.searchInputRef.focus();
	};

	render() {
		const { selectedMatch, totalMatches } = this.props;
		const canNavigateSearchFrwd = selectedMatch < totalMatches;
		const canNavigateSearchBack = selectedMatch > 1;
		const haveMatches = selectedMatch > 0 && totalMatches > 0;

		return (
			<SearchBarContainer ref={node => (this.searchBarRef = node)}>
				<SearchIcon src={SearchIconSVG} alt='search icon' width={15} />
				<input
					id='DOCUMENT_SEARCH_INPUT'
					type='text'
					ref={node => (this.searchInputRef = node)}
					placeholder='Search in document'
					onFocus={this.subscribeToEvents}
					onChange={event => this.props.searchText(event.target.value)}
				/>
				{haveMatches && (
					<SearchNavigation>
						<ArrowContainer
							isUp={true}
							isDisabled={!canNavigateSearchBack}
							onClick={() => this.navigateSearch(-1)}
						/>
						<ArrowContainer
							isUp={false}
							isDisabled={!canNavigateSearchFrwd}
							onClick={() => this.navigateSearch(1)}
						/>
					</SearchNavigation>
				)}
				{this.searchInputRef && this.searchInputRef.value && (
					<ClearSearchBtn src={Images.close} onClick={this.clearSearch} />
				)}
				{haveMatches && (
					<div id='matches'>
						{selectedMatch} of {totalMatches} matches
					</div>
				)}
			</SearchBarContainer>
		);
	}
}
