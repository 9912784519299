import { datadogLogs } from '@datadog/browser-logs';

datadogLogs.init({
	clientToken: 'pubab81c7991aaac228238be00a13d3c539',
	site: 'datadoghq.com',
	forwardErrorsToLogs: true,
	sampleRate: 100
});

export const logInfo = (message: string, messageContext: { name: string; id: number }) => {
	datadogLogs.logger.info(message, messageContext);
};
