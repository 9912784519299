import React, { FunctionComponent, useEffect, useState } from 'react';
import { Input, InputType, MenuWrapper } from '../../../../components';
import { Button } from '@amenityllc/amenity-components';
import {
	default as ids,
	MULTIPORTFOLIO,
	PORTFOLIO_ACTION_MODAL,
	WACTHLIST_PAGE
} from '../../../../id.constants';
import { Appearance, Colors, Sizes } from '../../../../components/shared/styles';
import plusIcon from '../../../../assets/icons/plus.svg';
import { DropdownOption } from '../../../types/types';
import EditIcon from '../../../../assets/icons/edit_large.svg';
import { useApolloClient, useMutation } from '@apollo/client';
import Modal from '../../../../components/Modal/Modal';
import styled from 'styled-components';
import PreImportModalNew from '../../PreImportModal/preImportModalNew';
import BloombergModal from '../../BloombergModal/bloombergModal';
import { parseXlsx } from '../Watchlist.utils';
import { MAX_COMPANIES_PORTFOLIO_UPLOAD, UPLOAD_FILE_ERRORS } from '../Watchlist.consts';
import UserTracking from '../../../services/userTracking.service';
import { USER_TRACKING as UT } from '../../../constants';
import { ADD_COMPANIES_TO_WATCHLIST, CREATE_WATCHLIST } from '../../../queries/Watchlist.queries';
import { BLOOMBERG_COMPANIES_QUERY, COMPANIES_QUERY } from '../../../queries/Symbology.queries';
import _ from 'lodash';
import importIcon from '../../../../assets/icons/import.svg';
import NavigationService from '../../../services/navigation.service';

const UTC = UT.USER_TRACKING_CATEGORIES;
const UTA = UT.USER_TRACKING_ACTIONS;

const CreateWatchlistHeader = styled.div`
	display: flex;
	align-items: center;
	height: 90px;
	background-color: ${Colors.lightGray};
	padding-left: 21px;
`;

enum CreateOptions {
	empty,
	bloomberg,
	excel
}

const newPortfolioOptions: DropdownOption[] = [
	{
		id: CreateOptions.empty,
		label: 'Start with an Empty Watchlist',
		elementId: MULTIPORTFOLIO.DROPDOWN_OPTION_CREATE_NEW_PORTFOLIO_BTN
	},
	{
		id: CreateOptions.bloomberg,
		label: 'Import from Bloomberg Exported File',
		elementId: WACTHLIST_PAGE.OPEN_BLOOMBERG_UPLOAD_TICKERS_MODAL_DROP_DOWN
	},
	{
		id: CreateOptions.excel,
		label: 'Import from Excel File',
		elementId: WACTHLIST_PAGE.OPEN_BLOOMBERG_UPLOAD_TICKERS_MODAL_EXCEL_DROP_DOWN
	}
];

const existingPortfolioOptions = [
	{
		label: 'Import from Bloomberg',
		id: CreateOptions.bloomberg,
		elementId: ids.WACTHLIST_PAGE.OPEN_BLOOMBERG_UPLOAD_TICKERS_MODAL_DROP_DOWN
	},
	{
		label: 'Import from Excel',
		id: CreateOptions.excel,
		elementId: ids.WACTHLIST_PAGE.OPEN_BLOOMBERG_UPLOAD_TICKERS_MODAL_EXCEL_DROP_DOWN
	}
];

interface WatchlistState {
	isOpen: boolean;
	watchlistName?: string;
	inputRef?: any;
}

interface WatchlistImportState {
	isOpen: boolean;
	isNextStepOpen?: boolean;
	isExcel?: boolean;
	watchlistName?: string;
	watchlistId?: number | string;
}

interface CreateWatchlistMenuProps {
	watchlists?: any;
	refetch?: (id?: string) => void;
	openExcelImport?: boolean;
	openBloombergImport?: boolean;
	openNewWatchist?: boolean;
	watchlistId?: string;
	watchlistName?: string;
}

interface ImportData {
	filePromise: Promise<File>;
	id: string;
	name: string;
	isExcelImport: boolean;
}

export const CreateWatchlistMenu: FunctionComponent<CreateWatchlistMenuProps> = (
	props: CreateWatchlistMenuProps
) => {
	const client = useApolloClient();
	const [createWatchlistMutation] = useMutation(CREATE_WATCHLIST);
	const [addCompaniesMutation] = useMutation(ADD_COMPANIES_TO_WATCHLIST);

	const {
		watchlists,
		watchlistId,
		watchlistName,
		openExcelImport,
		openBloombergImport,
		openNewWatchist
	} = props;

	const userTracking: UserTracking = UserTracking.getInstance();

	const initialState: WatchlistState = { isOpen: false, watchlistName: 'New Watchlist' };
	const [state, setState] = useState(initialState);

	const initialImportState: WatchlistImportState = { isOpen: false, isNextStepOpen: false };
	const [importState, setImportState] = useState(initialImportState);

	const openCreateModal = () => {
		setState({ isOpen: true, watchlistName: state.watchlistName, inputRef: state.inputRef });
	};

	const openImportExcelModal = () => {
		const isOpen = !watchlistId;
		const isNextStepOpen = !!watchlistId;
		setImportState({ isOpen, isNextStepOpen, isExcel: true, watchlistName, watchlistId });
	};

	const openImportBloombergModal = () => {
		const isOpen = !watchlistId;
		const isNextStepOpen = !!watchlistId;
		setImportState({ isOpen, isNextStepOpen, isExcel: false, watchlistName, watchlistId });
	};

	useEffect(() => {
		if (openBloombergImport) {
			openImportBloombergModal();
		}
		if (openExcelImport) {
			openImportExcelModal();
		}
		if (openNewWatchist) {
			openCreateModal();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openExcelImport, openBloombergImport, openNewWatchist]);

	const onSelectNewPortfolioOption = (option: string | number): void => {
		const createOptionsMap = {
			[CreateOptions.empty]: openCreateModal,
			[CreateOptions.bloomberg]: openImportBloombergModal,
			[CreateOptions.excel]: openImportExcelModal
		};
		createOptionsMap[option]();
	};

	const createWatchlist = () => {
		createWatchlistMutation({ variables: { name: state.watchlistName } })
			.then(() => {
				userTracking.setEvent(UTC.PORTFOLIO_MANAGEMENT, UTA.CREATE_EMPTY_WATCHLIST, '', {
					emptyWatchlistName: state.watchlistName
				});
				closeModal(true);
			})
			.catch(displayError);
	};

	const findFactsetIdsByTickers = async (tickers: string[]) => {
		const chunks = _.chunk(tickers, 10);
		const factsetIds = [];
		for (const chunk of chunks) {
			factsetIds.push(
				await Promise.all(
					chunk.map(ticker =>
						client.query({
							query: COMPANIES_QUERY,
							variables: { searchTerm: ticker }
						})
					)
				)
			);
		}
		// @ts-ignore
		return factsetIds.flat().map(result => result?.data?.companyEntitySearch[0]?.id);
	};

	const findFactsetIdsByBloombergTickers = async (tickers: string[]) => {
		const chunks = _.chunk(tickers, 10);
		const factsetIds = [];
		for (const chunk of chunks) {
			factsetIds.push(
				await Promise.all(
					chunk.map(ticker =>
						client.query({
							query: BLOOMBERG_COMPANIES_QUERY,
							variables: { ticker }
						})
					)
				)
			);
		}
		// @ts-ignore
		return factsetIds.flat().map(result => result?.data?.bloomberg?.id);
	};

	const createWatchlistFromImport = async (
		importData: ImportData,
		onSuccess: Function,
		onError: Function
	) => {
		try {
			const file = await importData.filePromise;
			const { factsets, tickers, tickersColumnExist, total } = parseXlsx(
				file,
				importData.isExcelImport
			);
			const error = checkForErrors(factsets, tickers, tickersColumnExist);
			if (error) {
				onError(error);
				return;
			}

			let factsetsToImport: string[];

			if (factsets.length > 0) {
				factsetsToImport = factsets;
			} else {
				factsetsToImport = importData.isExcelImport
					? await findFactsetIdsByTickers(tickers)
					: await findFactsetIdsByBloombergTickers(tickers);
			}

			factsetsToImport = factsetsToImport.filter(factset => !!factset);
			factsetsToImport = _.uniq(factsetsToImport);

			let createdWatchlistId: string;
			if (!importData.id) {
				const createWatchlistMutationResponse = await createWatchlistMutation({
					variables: { name: importData.name }
				});
				createdWatchlistId = createWatchlistMutationResponse.data.createWatchlist.id;
			}

			await addCompaniesMutation({
				variables: {
					id: createdWatchlistId ?? importData.id,
					companies: factsetsToImport
				}
			});
			userTracking.setEvent(UTC.WACTHLIST, UTA.COMPANY_ADDED, '', {
				totalWatchlistSize: factsetsToImport.length
			});
			const successData = {
				answerData: { succeeded: factsetsToImport.length, failed: total - factsetsToImport.length },
				portfolioId: createdWatchlistId
			};
			onSuccess(successData);
		} catch (e) {
			const errorData = {
				response: {
					data: {
						tickersFailed: []
					}
				}
			};
			onError(errorData);
		}
	};

	const checkForErrors = (factsets: string[], tickers: string[], isTickersColumnExist: boolean) => {
		let data = undefined;

		if (!tickers || !isTickersColumnExist) {
			data = UPLOAD_FILE_ERRORS.NO_TICKERS_COLUMN;
		}
		if (
			tickers.length > MAX_COMPANIES_PORTFOLIO_UPLOAD ||
			factsets.length > MAX_COMPANIES_PORTFOLIO_UPLOAD
		) {
			data = UPLOAD_FILE_ERRORS.OVER_MAX_TICKERS(MAX_COMPANIES_PORTFOLIO_UPLOAD);
		}
		if (tickers.length === 0 && factsets.length === 0) {
			data = UPLOAD_FILE_ERRORS.NO_TICKERS;
		}

		if (!data) {
			return;
		}

		return {
			response: {
				data
			}
		};
	};

	const closeModal = (shouldRefresh: boolean = false): void => {
		setState({ ...state, isOpen: false });
		shouldRefresh && props.refetch();
	};

	const displayError = (): void => {
		// Show error
		closeModal();
	};

	const proceedWithUploadWatchlist = (name: string, id: number | string) => {
		setTimeout(() => {
			setImportState({
				isOpen: false,
				isNextStepOpen: true,
				isExcel: importState.isExcel,
				watchlistName: name,
				watchlistId: id
			});
		}, 0);
	};

	const renderModalButtons = () => [
		<Button
			key='ACTION_MODAL_SAVE'
			id={PORTFOLIO_ACTION_MODAL.CONFIRM_BTN}
			size={Sizes.medium}
			appearance={Appearance.primary}
			text={'Create Watchlist'}
			onClick={() => createWatchlist()}
		/>,
		<Button
			key='ACTION_MODAL_CANCEL'
			id={PORTFOLIO_ACTION_MODAL.CANCEL_BTN}
			size={Sizes.medium}
			appearance={Appearance.secondary}
			text={'Cancel'}
			onClick={() => closeModal()}
		/>
	];

	const renderModalInput = () => (
		<Input
			id={PORTFOLIO_ACTION_MODAL.INPUT}
			type={InputType.text}
			value={state.watchlistName}
			onChange={event => setState({ isOpen: state.isOpen, watchlistName: event.target.value })}
		/>
	);

	const reloadWatchlist = watchlistId
		? props.refetch
		: NavigationService.getInstance().goToWatchlist;

	return (
		<>
			<CreateWatchlistHeader>
				<MenuWrapper
					options={watchlistId ? existingPortfolioOptions : newPortfolioOptions}
					menuPosition={{ zIndex: 2, top: 50 }}
					onSelect={onSelectNewPortfolioOption}
				>
					<Button
						id={MULTIPORTFOLIO.NEW_PORTFOLIO}
						appearance={Appearance.secondary}
						icon={watchlistId ? importIcon : plusIcon}
						text={watchlistId ? 'Import' : 'Create Watchlist'}
						withMenuArrow
					/>
				</MenuWrapper>
			</CreateWatchlistHeader>
			<Modal
				icon={EditIcon}
				text={'Enter a name for the new watchlist:'}
				isModalOpen={state.isOpen}
				modalContent={renderModalInput()}
				modalButtons={renderModalButtons()}
				close={closeModal}
			/>
			<PreImportModalNew
				isOpen={importState.isOpen}
				portfolios={watchlists}
				header={importState.isExcel ? 'Import Your Watchlist' : 'Import Your Bloomberg Watchlist'}
				close={() => setImportState({ isOpen: false })}
				onSelect={proceedWithUploadWatchlist}
			/>
			<BloombergModal
				portfolioName={importState.watchlistName}
				portfolioId={importState.watchlistId}
				isExcelImport={importState.isExcel}
				isModalOpen={importState.isNextStepOpen}
				closeModal={() => setImportState({ isOpen: false, isNextStepOpen: false })}
				reloadPortfolio={reloadWatchlist}
				createWatchlist={createWatchlistFromImport}
			/>
		</>
	);
};
