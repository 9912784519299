import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { STORE_GLOBAL, STORE_UI, TABS } from '../../constants';
import GlobalStore from '../../stores/global.store';
import UIStore from '../../stores/ui.store';
import DashboardFromSdk from './DashboardFromSdk';
import { useGetAvailableDashboardsQuery } from '../../queries/autogenerate/hooks';
import ContactSalesModal from './ContactSalesModal';
import { inject, observer } from 'mobx-react';
import NavigationService from '../../services/navigation.service';

const PageContainer = styled.div`
	width: 100%;
	height: 100%;
`;

interface DashboardContainerProps {
	match: any;
	[STORE_GLOBAL]?: GlobalStore;
	[STORE_UI]?: UIStore;
}

const DashboardContainer = (props: DashboardContainerProps) => {
	// The dashboard the user is requesting to show, it can differ from initial
	// by moving back in the Analytics left panel
	const [currentDashboard, setCurrentDashboard] = useState(undefined);
	const [isContactSalesModalOpen, setIsContactSalesModalOpen] = useState(false);
	const { loading, data: dashboardsData } = useGetAvailableDashboardsQuery();
	const params = (props.match && props.match.params) || {};
	const dashboardId = Number(params?.dashboardId);

	useEffect(() => {
		const globalStore = props[STORE_GLOBAL];
		const ui = props[STORE_UI] as UIStore;
		globalStore.setCurrentTab(TABS.ANALYTICS_LOOKER);
		ui.setSideBarState(true);
		ui.setCanToggleSideBar(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const dashboardsLength: number = dashboardsData?.me.availableDashboards.length;
		setIsContactSalesModalOpen(!loading && dashboardsLength === 0);
		if (!loading && dashboardsLength > 0) {
			const availableDashboardsArray = dashboardsData?.me.availableDashboards;

			// check if the user has a dashboard with an id from params
			const currentDash = availableDashboardsArray?.find(dash => dash.id === dashboardId);
			if (currentDash === undefined) {
				isNaN(dashboardId) // no dashboard is in the path
					? NavigationService.instance.goToDashboard(availableDashboardsArray[0]?.id.toString())
					: setIsContactSalesModalOpen(true);
			} else {
				setCurrentDashboard(currentDash);
			}
		}
	}, [loading, dashboardsData, dashboardId]);

	if (isContactSalesModalOpen) {
		return (
			<PageContainer>
				<ContactSalesModal
					closeModal={() => setIsContactSalesModalOpen(false)}
					isModalOpen={isContactSalesModalOpen}
				/>
			</PageContainer>
		);
	}

	if (currentDashboard) {
		return <DashboardFromSdk currentDashboard={currentDashboard} />;
	}
};

export default inject(STORE_GLOBAL, STORE_UI)(observer(DashboardContainer));
