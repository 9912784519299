import * as React from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';
import { Event, Speaker, Paragraph } from '../../types/types';
import ParagraphElement from './paragraph';

interface ParagraphTextContainerProps {
	marginTop: number;
}
const ParagraphTextContainer = styled.div<ParagraphTextContainerProps>`
	width: 100%;
	margin-top: ${props => props.marginTop}px;
`;

interface SpeakerTextProps {
	events: Event[];
	lostEvents: Event[];
	paragraphs: Paragraph[];
	type: number;
	speaker: Speaker;
	delimiterOffset: number;
	sectionOffset: number;
	printMode: boolean;
}

enum Types {
	discussion,
	qna
}

export default class SpeakerText extends React.PureComponent<SpeakerTextProps, {}> {
	getMarginTopOfText = (paragraphTextIndex): number => {
		let spaceFromTop: number = 0;
		// if QNA
		if (this.props.type === Types.qna) {
			// if not first child
			if (paragraphTextIndex !== 0) {
				spaceFromTop = 20;
			}
		} else {
			spaceFromTop = 20;
		}

		return spaceFromTop;
	};

	mapParagraphsDataToText = (paragraphs: Paragraph[], events: Event[]): any[] => {
		const { sectionOffset, delimiterOffset, printMode, lostEvents } = this.props;

		const paragraphsRender: any[] = _.map(paragraphs, (p: Paragraph, i: number) => {
			const paragraphEvents = _.filter(events, (e: Event) => {
				const eventStartInParagraph =
					e.startIndex - (e.isUserDefined ? 0 : delimiterOffset) >=
						p.offsetInText + sectionOffset || e.startIndex >= p.offsetInText + sectionOffset;
				const eventEndsInParagraph =
					e.endIndex - (e.isUserDefined ? 0 : delimiterOffset) <
					p.offsetInText + p.textLength + sectionOffset + 1;
				const inParagraphRange = eventStartInParagraph && eventEndsInParagraph;

				let isExist = false;
				if (
					(eventStartInParagraph && !eventEndsInParagraph) ||
					(!eventStartInParagraph && eventEndsInParagraph)
				) {
					isExist = p.text.indexOf(e.text) !== -1 ? true : false;
				}
				return inParagraphRange || isExist;
			});
			return (
				<ParagraphTextContainer key={`Paragraph_${i}`} marginTop={this.getMarginTopOfText(i)}>
					<ParagraphElement
						paragraph={p}
						events={paragraphEvents}
						lostEvents={lostEvents}
						sectionOffset={sectionOffset}
						delimiterOffset={delimiterOffset}
						printMode={printMode}
					/>
				</ParagraphTextContainer>
			);
		});

		return paragraphsRender;
	};

	render() {
		const { paragraphs, events } = this.props;
		return <div>{this.mapParagraphsDataToText(paragraphs, events)}</div>;
	}
}
