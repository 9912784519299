import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { DropdownOption, SectionDropdownOptions } from '../shared/types';
import useComponentVisible from '../../app/widgets/IsComponentVisible/isComponentVisible';
import { Menu } from '../Menu/Menu';
import { Sizes } from '../shared/styles';

const RelativelyPositioned = styled.div`
	position: relative;
`;

interface AbsolutelyPositionedProps {
	top?: number;
	right?: number;
	bottom?: number;
	left?: number;
	zIndex?: number;
}

const AbsolutelyPositioned = styled.div<AbsolutelyPositionedProps>`
	position: absolute;
	min-width: 100%;
	${({ top, right, bottom, left, zIndex }) =>
		`
			${top !== undefined ? `top: ${top}px;` : ''}
			${right !== undefined ? `right: ${right}px;` : ''}
			${bottom !== undefined ? `bottom: ${bottom}px;` : ''}
			${left !== undefined ? `left: ${left}px;` : ''}
			${zIndex !== undefined ? `z-index: ${zIndex};` : 'z-index: 100'}
		`}
`;

interface Props {
	buttonId?: string;
	options?: DropdownOption[];
	sections?: SectionDropdownOptions[];
	selectedOptionId?: number | string;
	onSelect?: (id: number | string, sectionName: string) => void;
	menuPosition?: { top?: number; right?: number; bottom?: number; left?: number; zIndex?: number };
	children?: ReactNode;
	disabled?: boolean;
	size?: Sizes;
	maxHeight?: number;
}

export const MenuWrapper: React.FunctionComponent<Props> = ({
	buttonId,
	options,
	sections,
	selectedOptionId,
	onSelect,
	menuPosition,
	children,
	disabled,
	size = Sizes.medium,
	maxHeight
}) => {
	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

	const closeAndSelect = (id: number | string, sectionId: string) => {
		setIsComponentVisible(false);
		onSelect(id, sectionId);
	};

	return (
		<RelativelyPositioned ref={ref}>
			<div id={buttonId} onClick={() => !disabled && setIsComponentVisible(!isComponentVisible)}>
				{children}
			</div>
			{isComponentVisible && (
				<AbsolutelyPositioned {...menuPosition}>
					<Menu
						options={options}
						sections={sections}
						onSelect={closeAndSelect}
						selectedOptionId={selectedOptionId}
						size={size}
						maxHeight={maxHeight}
					/>
				</AbsolutelyPositioned>
			)}
		</RelativelyPositioned>
	);
};
