import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Button } from '@amenityllc/amenity-components';

import { DOCUMENT_SIDEBAR_RIGHT_WIDTH, DOCUMENT_SIDEBAR_WIDTH_CLOSED } from '../../constants';
import { Text } from '../../../components/Text/Text';
import { Colors, Appearance, FontSizes, FontFamilies } from '../../../components/shared/styles';
import arrowIcon from '../../../assets/icons/arrow_blue.svg';

const RightSidebar = styled.div<{ isOpen: boolean }>`
	width: ${props =>
		props.isOpen ? `${DOCUMENT_SIDEBAR_RIGHT_WIDTH}px` : `${DOCUMENT_SIDEBAR_WIDTH_CLOSED}px`};
	height: 100%;
	border: 1px solid ${Colors.gray};
	border-right: none;
	background-color: ${Colors.white};
	overflow-y: hidden;
	box-sizing: border-box;
	display: inline-block;
	cursor: ${props => !props.isOpen && 'pointer'};
`;

const RightSidePlaceholder = styled.div`
	transform: rotate(90deg);
	transform-origin: bottom left;
	white-space: nowrap;
	margin: 0px 10px;
`;

enum Direction {
	left,
	right
}

const ArrowButton = styled(Button)<{ direction: Direction }>`
	${({ direction }) => (direction === Direction.right ? 'transform: rotate(180deg)' : '')};
`;

interface Props {
	isOpen: boolean;
	onToggle: Function;
	label: string;
	children: ReactNode;
}

const DocumentRightSidebar: React.FunctionComponent<Props> = ({
	isOpen,
	onToggle,
	label,
	children
}) => (
	<RightSidebar isOpen={isOpen} onClick={() => isOpen || onToggle()}>
		<ArrowButton
			direction={isOpen ? Direction.right : Direction.left}
			appearance={Appearance.secondary}
			icon={arrowIcon}
			onClick={event => {
				onToggle();
				event.stopPropagation();
			}}
		/>
		{isOpen ? (
			children
		) : (
			<RightSidePlaceholder>
				<Text size={FontSizes.s2} fontFamily={FontFamilies.semiBold} color={Colors.dark}>
					{label}
				</Text>
			</RightSidePlaceholder>
		)}
	</RightSidebar>
);

export default DocumentRightSidebar;
