import { USER_TRACKING as UT } from '../../constants';
import { PortfolioActionModalProps, PortfolioActionModalType } from '../../types/types';
import AddIcon from '../../../assets/icons/add_large.svg';
import deleteWatchlistIcon from '../../../assets/icons/delete_watchlist.svg';

export const UTC = UT.USER_TRACKING_CATEGORIES;
export const UTA = UT.USER_TRACKING_ACTIONS;

export const renameWatchlistModal: PortfolioActionModalProps = {
	open: true,
	action: 'rename',
	type: PortfolioActionModalType.Edit,
	icon: AddIcon,
	text: 'Enter a new name for the watchlist:',
	confirmText: 'Rename Watchlist',
	error: ''
};

export const deleteWatchlistModal = (name: string): PortfolioActionModalProps => {
	return {
		open: true,
		action: 'erase',
		type: PortfolioActionModalType.Delete,
		icon: deleteWatchlistIcon,
		title: 'Delete Watchlist',
		text: `Are you sure you want to delete ${name}?`,
		confirmText: 'Delete Watchlist',
		error: ''
	};
};

export const UPLOAD_FILE_ERRORS = {
	OVER_MAX_TICKERS: (maxCount: number) => ({
		title: `Sorry, your file contains over ${maxCount} tickers`,
		description: `Please edit your file to ${maxCount} tickers or less and try again`
	}),
	NO_TICKERS: {
		title: "Sorry, we couldn't find any tickers in your file",
		description:
			"Please make sure there's a Tickers column in your document (with some tickers listed) and try again"
	},
	NO_TICKERS_COLUMN: {
		title: 'Sorry, we could not find a ticker column in your file',
		description: 'Please make sure you follow the steps in the Bloomberg instructions video'
	}
};

export const MAX_COMPANIES_PORTFOLIO_UPLOAD = 400;
