import React, { FunctionComponent, SyntheticEvent, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import watchlistDataState from '../../recoil/watchlist';
import CommonTableRow from '../Table/commonTableRow';
import { ALL_PORTFOLIOS_TABLE_COLUMNS } from '../Table/columnConstants';
import PortfoliosActionModalNew from '../PortfolioActionModal/portfolioActionModalNew';
import { MULTIPORTFOLIO } from '../../../id.constants';
import deleteIcon from '../../../assets/icons/delete.svg';
import editIcon from '../../../assets/icons/edit.svg';
import excelIcon from '../../../assets/icons/excel.svg';
import { Icon } from '../../../components';
import * as Util from '../../services/util.service';
import UserTracking from '../../services/userTracking.service';
import NavigationService from '../../services/navigation.service';
import { Actions, Watchlist } from '../../types/Watchlist.types';
import { deleteWatchlistModal, renameWatchlistModal, UTA, UTC } from './Watchlist.consts';
import {
	DELETE_WATCHLIST,
	GET_WATCHLIST_WITH_DATA,
	RENAME_WATCHLIST
} from '../../queries/Watchlist.queries';
import * as Types from '../../types/types';

const ActionIcon = styled(Icon)`
	padding: 15px;
	vertical-align: middle;
`;

interface WatchlistTableProps {
	watchlists: any;
	refetch: () => void;
}

export const WatchlistsTable: FunctionComponent<WatchlistTableProps> = (
	props: WatchlistTableProps
) => {
	const userTracking: UserTracking = UserTracking.getInstance();
	const navService: NavigationService = NavigationService.getInstance();
	const [modalState, setState] = useState({ selected: undefined, modal: undefined });
	const { watchlists, refetch } = props;
	const [renameWatchlist] = useMutation(RENAME_WATCHLIST);
	const [deleteWatchlist] = useMutation(DELETE_WATCHLIST);
	const setWatchlistData = useSetRecoilState(watchlistDataState);

	const [exportWatchlist, setExportWatchlist] = useState<Watchlist>(undefined);
	useQuery(GET_WATCHLIST_WITH_DATA, {
		variables: { watchlistId: exportWatchlist?.id },
		onCompleted: (data: { watchlist: Watchlist }) =>
			Util.exportFactsetsToExcel(
				restructureEntities(data?.watchlist?.companyEntities),
				exportWatchlist?.name
			),
		skip: !exportWatchlist
	});

	const restructureEntities = (entitiesFromQuery: any[]): Types.Company[] => {
		const entities = [];
		entitiesFromQuery.forEach(e => {
			entities.push({
				id: e?.id,
				name: e?.name,
				ticker: e?.mainIdentifier?.ticker,
				region: e?.mainIdentifier?.region
			});
		});
		return entities;
	};

	const openRenamePortfolio = (selected: Watchlist): void => {
		userTracking.setEvent(UTC.PORTFOLIO_MANAGEMENT, UTA.RENAME_PORTFOLIO_CLICKED, selected.name);
		setState({ selected, modal: renameWatchlistModal });
	};

	const openErasePortfolio = (selected: Watchlist): void => {
		userTracking.setEvent(UTC.PORTFOLIO_MANAGEMENT, UTA.DELETE_PORTFOLIO_CLICKED, selected.name);
		setState({ selected, modal: deleteWatchlistModal(selected.name) });
	};

	const savePortfolio = (id?: number, value?: string): void => {
		const actionsMap = {
			[Actions.renameWatchlist]: () => renamePortfolio(id, value),
			[Actions.deleteWatchlist]: () => erasePortfolio(id),
			[Actions.unsupported]: () => console.log('handle error')
		};
		actionsMap[modalState.modal?.action || Actions.unsupported]();
	};

	const renamePortfolio = (id: number, value: string): void => {
		renameWatchlist({ variables: { id, newName: value } })
			.then(onSuccess)
			.catch(displayError);
	};

	const erasePortfolio = (id: number): void => {
		deleteWatchlist({ variables: { id } })
			.then(onSuccess)
			.catch(displayError);
	};

	const onSuccess = (): void => {
		closeModal();
		refetch();
	};

	const closeModal = (): void => {
		setState({ selected: undefined, modal: undefined });
	};

	const displayError = (err: string): void => {
		// setState({ modal: { modal, error: err } });
	};

	const renderActions = (watchlist: Watchlist) => (
		<div>
			<ActionIcon
				id={MULTIPORTFOLIO.EXPORT}
				data-tip='Export'
				data-for={MULTIPORTFOLIO.TOOL_TIP}
				src={excelIcon}
				onClick={(event: SyntheticEvent) => {
					event.stopPropagation();
					setExportWatchlist(watchlist);
				}}
			/>
			<ActionIcon
				id={MULTIPORTFOLIO.RENAME}
				data-tip='Rename'
				data-for={MULTIPORTFOLIO.TOOL_TIP}
				src={editIcon}
				onClick={(event: SyntheticEvent) => {
					event.stopPropagation();
					openRenamePortfolio(watchlist);
				}}
			/>
			<ActionIcon
				id={MULTIPORTFOLIO.DELETE}
				data-tip='Delete'
				data-for={MULTIPORTFOLIO.TOOL_TIP}
				src={deleteIcon}
				onClick={(event: SyntheticEvent) => {
					event.stopPropagation();
					openErasePortfolio(watchlist);
				}}
			/>
		</div>
	);

	const createClickHandler = watchlist => async () => {
		await setWatchlistData({
			id: watchlist.id,
			name: watchlist.name,
			companyEntities: watchlist.companyEntities
		});
		navService.goToWatchlist(watchlist.id);
	};

	return (
		<>
			{_.map(watchlists, (watchlist: Watchlist) => (
				<div key={watchlist.id}>
					<CommonTableRow
						columns={ALL_PORTFOLIOS_TABLE_COLUMNS}
						row={{
							...watchlist,
							actions: renderActions(watchlist)
						}}
						onClick={createClickHandler(watchlist)}
					/>
				</div>
			))}
			<PortfoliosActionModalNew
				modal={modalState.modal}
				portfolio={modalState.selected}
				close={closeModal}
				save={savePortfolio}
			/>
		</>
	);
};
