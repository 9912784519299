import * as React from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';
import * as Scroll from 'react-scroll';
import { Waypoint } from 'react-waypoint';
import Microchart from '../Microchart/microchart';
import { PortfolioCompany, Category } from '../../types/types';
import { getDataForGraph } from '../ExpandedRow/utils/utilsJarvis';
import * as TableStyles from './commonTableStyles';
import ExpandedRow from '../ExpandedRow/expandedRowNewarc';
import Images from '../../shared/images';
import UserTracking from '../../services/userTracking.service';
import {
	USER_TRACKING as UT,
	ARROW_CONTAINER_WIDTH,
	DELETE_CONTAINER_WIDTH,
	AMENITY_CHANGE_WIDTH
} from '../../constants';
import { WACTHLIST_PAGE } from '../../../id.constants';
import { Text } from '../../../components/Text/Text';
import { Link } from '../../../components/Link/Link';
import { Colors, FontSizes } from '../../../components/shared/styles';
import { Sentiment } from './Sentiment';

const UTC = UT.USER_TRACKING_CATEGORIES;
const UTA = UT.USER_TRACKING_ACTIONS;

const ArrowWrapper = styled.div`
	width: ${ARROW_CONTAINER_WIDTH}px;
	text-align: center;
`;

interface ExpandArrowProps {
	transformDown?: boolean;
}
const ExpandArrow = styled.img<ExpandArrowProps>`
	transform: ${props => (props.transformDown ? 'rotate(0deg)' : 'rotate(-90deg)')};
`;

const Ticker = styled.div`
	width: calc(
		(100% - ${ARROW_CONTAINER_WIDTH}px - ${DELETE_CONTAINER_WIDTH}px - ${AMENITY_CHANGE_WIDTH}px) *
			0.1
	);
	text-align: left;
	box-sizing: border-box;
`;

interface MultisizeBorderBoxProps {
	width?: string;
	large?: boolean;
	align?: string;
	style?: any;
}
const MultisizeBorderBox = styled.div<MultisizeBorderBoxProps>`
	box-sizing: border-box;
`;

const EmptyCell = styled.div<{
	width?: string;
	marginRight?: string;
	style?: any;
	multiplier?: string;
}>`
	box-sizing: border-box;
	${props => (props.marginRight ? `margin-right: ${props.marginRight};` : '')}
	width: ${props =>
		props.width
			? props.width
			: `calc((100% - ${ARROW_CONTAINER_WIDTH}px - ${DELETE_CONTAINER_WIDTH}px - ${AMENITY_CHANGE_WIDTH}px) * ${props.multiplier});`}
`;

const CompanyName = styled(MultisizeBorderBox)`
	width: ${props =>
		`calc((100% - ${ARROW_CONTAINER_WIDTH}px - ${DELETE_CONTAINER_WIDTH}px - ${AMENITY_CHANGE_WIDTH}px) * 0.25)`};
`;

const Region = styled.div`
	width: calc(
		(100% - ${ARROW_CONTAINER_WIDTH}px - ${DELETE_CONTAINER_WIDTH}px - ${AMENITY_CHANGE_WIDTH}px) *
			0.07
	);
	box-sizing: border-box;
`;

const Sector = styled(MultisizeBorderBox)`
	width: ${props =>
		`calc((100% - ${ARROW_CONTAINER_WIDTH}px  - ${DELETE_CONTAINER_WIDTH}px - ${AMENITY_CHANGE_WIDTH}px) * 0.18)`};
`;

const EarningCallDate = styled(MultisizeBorderBox)`
	width: ${props =>
		`calc((100% - ${ARROW_CONTAINER_WIDTH}px  - ${DELETE_CONTAINER_WIDTH}px - ${AMENITY_CHANGE_WIDTH}px) * 0.15)`};
	text-align: left;
`;
const DeleteContainer = styled.div`
	position: relative;
	padding-top: 4px;
	margin-right: 20px;
	text-align: right;
	flex-grow: 4;
`;

const EmptyPlaceholder = styled.span`
	line-height: 10px;
	display: flex;
	align-items: center;
	justify-content: start;
	position: relative;
`;

const EmptyPlaceholderText = styled.span`
	position: absolute;
	right: 60px;
`;

interface TableRowProps {
	company: PortfolioCompany;
	expanded: boolean;
	onDelete: Function;
	onNavigateToTicker: Function;
	onClick: Function;
	isFocus: boolean; // whether to focus on this row
	scrollContainer: any; // parent of row - to scroll
	onMountCB: Function; // parent of row - to scroll
	categories: Category[];
	subscriptionId?: string;
}

interface TableRowState {
	shouldRenderMicrograph: boolean;
	shouldRenderExpanded: boolean;
}

export default class WatchlistTableRow extends React.Component<TableRowProps, TableRowState> {
	constructor(props) {
		super(props);
		this.state = {
			shouldRenderMicrograph: false,
			shouldRenderExpanded: false
		};
	}

	componentDidMount() {
		if (this.props.isFocus) {
			this.scroll(`${WACTHLIST_PAGE.SCROLL_ROW_PREFIX}${this.props.company.id}`);
			this.props.onMountCB();
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		const stateChanged = !_.isEqual(nextState, this.state);
		const propsChanged = !this.propsEqual(nextProps);
		return stateChanged || propsChanged;
	}

	propsEqual = (nextProps: TableRowProps) =>
		_.isEqual(nextProps.company, this.props.company) &&
		_.isEqual(nextProps.scrollContainer, this.props.scrollContainer) &&
		nextProps.expanded === this.props.expanded &&
		nextProps.isFocus === this.props.isFocus;

	onClickRow = (company: PortfolioCompany) => {
		const ut = new UserTracking();
		ut.setEvent(UTC.WACTHLIST, UTA.COMPANY_EXPANDED, company.ticker);
		this.props.onClick(company);
	};

	scroll = (scrollId: string) => {
		Scroll.scroller.scrollTo(scrollId, {
			duration: 750,
			offset: -300,
			delay: 0,
			smooth: true,
			container: this.props.scrollContainer
		});
	};

	rowWithoutDocuments = () => {
		const { company, expanded, onDelete } = this.props;

		return (
			<TableStyles.TableRow
				id={`TOGGLE_EXPAND_DATA_BY_COMPANYID_${company.id}`}
				name={`TOGGLE_EXPAND_DATA_BY_TICKER_${company.ticker}`}
				enabled
				selected={expanded}
				onClick={() => this.onClickRow(company)}
			>
				<ArrowWrapper>
					<ExpandArrow transformDown={expanded} src={Images.arrowTableDownBlack} />
				</ArrowWrapper>
				<Ticker className='ellipsis' id={`${WACTHLIST_PAGE.TICKER_PREFIX}${company.ticker}`}>
					{company.ticker}
				</Ticker>
				<CompanyName className='ellipsis' id={`${WACTHLIST_PAGE.NAME_PREFIX}${company.ticker}`}>
					{company.name}
				</CompanyName>
				<EmptyPlaceholder>
					<Text color={Colors.black} size={FontSizes.s2}>
						No current documents available for this company&nbsp;&nbsp;&nbsp;&nbsp;
					</Text>
					<Link size={FontSizes.s2}>Click here to view historical documents</Link>
				</EmptyPlaceholder>
				<DeleteContainer>
					<TableStyles.DeleteIcon
						show={expanded}
						src={Images.deleteIcon}
						alt='Delete Transcript'
						onClick={event => {
							onDelete(event, company.id);
						}}
						className='DELETE_COMPANY_FROM_PORTFOLIO_ROW'
					/>
				</DeleteContainer>
			</TableStyles.TableRow>
		);
	};

	rowWithDocuments = () => {
		const { company, expanded, onDelete } = this.props;
		const latestTranscript = company.latestDocument;
		let formattedDate = '';
		if (latestTranscript?.eventDate) {
			formattedDate = new Date(latestTranscript.eventDate).toLocaleDateString();
		} else if (latestTranscript?.publicationDate) {
			formattedDate = new Date(latestTranscript.publicationDate).toLocaleDateString();
		}

		const micrographData = getDataForGraph(company);

		const micrograph =
			micrographData && micrographData.length > 1 ? <Microchart data={micrographData} /> : '';

		return (
			<TableStyles.TableRow
				id={`TOGGLE_EXPAND_DATA_BY_COMPANYID_${company.id}`}
				name={`TOGGLE_EXPAND_DATA_BY_TICKER_${company.ticker}`}
				enabled
				selected={expanded}
				onClick={() => this.onClickRow(company)}
			>
				<ArrowWrapper>
					<ExpandArrow transformDown={expanded} src={Images.arrowTableDownBlack} />
				</ArrowWrapper>

				<Ticker className='ellipsis' id={`${WACTHLIST_PAGE.TICKER_PREFIX}${company.ticker}`}>
					{company.ticker}
				</Ticker>
				<CompanyName className='ellipsis' id={`${WACTHLIST_PAGE.NAME_PREFIX}${company.ticker}`}>
					{company.name}
				</CompanyName>
				<Region className='ellipsis'>{company.region}</Region>
				<Sector className='ellipsis'>{company.sector}</Sector>
				{formattedDate ? (
					<>
						<EarningCallDate className='ellipsis' title={formattedDate}>
							<span title={formattedDate}>{formattedDate}</span>
						</EarningCallDate>
						<Sentiment
							transcript={latestTranscript}
							company={company}
							align='right'
							style={{ right: micrograph === '' ? '30px' : '0' }}
							withGraph={micrograph !== ''}
							sentimentScoreDisplay={true}
							id={`${WACTHLIST_PAGE.SCORE_CHANGE}_${company.ticker}`}
						>
							<Waypoint
								onEnter={() => {
									this.setState({ shouldRenderMicrograph: true });
								}}
							/>
							{this.state.shouldRenderMicrograph ? micrograph : undefined}
						</Sentiment>
						<Sentiment
							transcript={latestTranscript}
							company={company}
							align='center'
							width='130px'
							id={`${WACTHLIST_PAGE.SCORE_CHANGE}`}
							sentimentScoreDisplay={false}
						/>
					</>
				) : (
					<>
						<EmptyCell multiplier='0.15' />
						<EmptyCell marginRight='50px' multiplier='0.25' />
						<EmptyCell marginRight='50px' width='130px' />
						<EmptyPlaceholder>
							<EmptyPlaceholderText>
								<Text color={Colors.black} size={FontSizes.s2}>
									No current documents available for this company&nbsp;&nbsp;&nbsp;&nbsp;
								</Text>
							</EmptyPlaceholderText>
						</EmptyPlaceholder>
					</>
				)}
				<DeleteContainer>
					<TableStyles.DeleteIcon
						show={expanded}
						src={Images.deleteIcon}
						alt='Delete Company'
						onClick={event => {
							onDelete(event, company.id);
						}}
						className='DELETE_COMPANY_FROM_PORTFOLIO_ROW'
					/>
				</DeleteContainer>
			</TableStyles.TableRow>
		);
	};

	render() {
		const { company, expanded, categories } = this.props;
		const hasRecentDocuments = true;

		const companyId = company.id;

		return (
			<TableStyles.TableRowContainer
				isSelected={expanded}
				enabled={hasRecentDocuments}
				id={`${WACTHLIST_PAGE.SCROLL_ROW_PREFIX}${companyId}`}
				className={WACTHLIST_PAGE.SCROLL_ROW}
			>
				{hasRecentDocuments ? this.rowWithDocuments() : this.rowWithoutDocuments()}
				{expanded && (
					<div>
						<Waypoint
							onEnter={() => {
								this.setState({ shouldRenderExpanded: true });
							}}
						/>
						{this.state.shouldRenderExpanded && (
							<ExpandedRow company={company} categories={categories} />
						)}
					</div>
				)}
			</TableStyles.TableRowContainer>
		);
	}
}
