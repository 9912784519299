import * as React from 'react';
import styled from 'styled-components';

import YearsDropDown from './yearsDropDown';
import { Document, PortfolioCompany } from '../../types/types';
import { Colors, FontSizes, FontFamilies } from '../../../components/shared/styles';
import { Text } from '../../../components/Text/Text';
import { useState } from 'react';
import * as _ from 'lodash';
import { getDocumentDate, getTranscriptDropdownItems } from './documentList.util';
import DocumentInList from './DocumentInList';
import { RECENT_DOCUMENTS } from '../../constants';

const RecentTranscriptsData = styled.div`
	display: inline-block;
	width: 470px;
	min-height: 220px;
`;

const Title = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 20px;
`;

const DropDownWrap = styled.div`
	margin-left: 10px;
`;

interface DocumentListNewarcProps {
	company: PortfolioCompany;
	companyDocuments: {
		updateDate: number;
		data: Document[];
	};
	onClickTranscript: Function;
	subscriptionId: string;
	cursor: string;
	getMoreDocuments: () => void;
}

const DocumentListNewarc: React.FunctionComponent<DocumentListNewarcProps> = (
	props: DocumentListNewarcProps
) => {
	const [selectedYear, setSelectedYear] = useState(RECENT_DOCUMENTS);
	const dropDownItems: { title: any; id: number }[] = getTranscriptDropdownItems(
		props.companyDocuments.data
	);
	if (props.cursor?.length > 0) {
		dropDownItems.push({ title: 'Get more', id: 9999 });
	}

	const filterDocumentsByYear = (year: number, docs: Document[]) => {
		if (year === RECENT_DOCUMENTS) {
			return _.orderBy(docs, t => new Date(getDocumentDate(t)), 'desc').slice(0, 4);
		}
		if (year === 9999) {
			props.getMoreDocuments();
			return [];
		} else {
			return docs.filter(doc => new Date(getDocumentDate(doc)).getUTCFullYear() === year);
		}
	};

	const relevantDocumentsForSelectedYear = filterDocumentsByYear(
		selectedYear,
		props.companyDocuments.data
	);

	const getTranscriptsList = (
		transcripts: Document[],
		onClickTranscript,
		currentSubscriptionId: string
	): any[] => {
		const sorted: Document[] = _.orderBy(transcripts, t => new Date(getDocumentDate(t)), 'desc');
		const unique: Document[] = _.uniqBy(sorted, (t: any) => t.documentId);
		const displayUpToFourDocs: Document[] = unique.slice(0, 4);

		return _.map(displayUpToFourDocs, (transcript, index) => {
			return (
				<DocumentInList
					key={index}
					document={transcript}
					index={index}
					currentSubscriptionId={currentSubscriptionId}
					onClickDocument={onClickTranscript}
				/>
			);
		});
	};

	return (
		<RecentTranscriptsData>
			<Title>
				<Text size={FontSizes.s2} fontFamily={FontFamilies.bold} color={Colors.black}>
					Show Documents:
				</Text>
				{dropDownItems.length > 0 && (
					<DropDownWrap id='DOCUMENT_LIST_DROP_DOWN'>
						<YearsDropDown
							data={dropDownItems}
							currentYear={selectedYear}
							handleDropDownChange={year => setSelectedYear(year)}
						/>
					</DropDownWrap>
				)}
			</Title>
			{getTranscriptsList(
				relevantDocumentsForSelectedYear,
				props.onClickTranscript,
				props.subscriptionId
			)}
		</RecentTranscriptsData>
	);
};

export default DocumentListNewarc;
