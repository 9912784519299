import styled from 'styled-components';

import { DocumentDetailsContainer } from '../../app/shared/styled/text-layout-components';
import Images from '../../app/shared/images';
import { Polarity } from '../../app/types/types';
import { Colors } from '../shared/styles';

export const PrintWrap = styled.div`
	width: 210mm;
	margin: 0 auto;
`;

export const Logo = styled.img`
	height: 21px;
`;

export const SubHeader = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 60px;
	padding: 0 calc((100% - 210mm) / 2);
	height: 60px;
	line-height: 60px;
	background-color: #f2f2f2;
	color: #4a4a4a;
	font-size: 18px;
	font-family: 'Open Sans';
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const TranscriptDetailsContainerPrint = styled(DocumentDetailsContainer)`
	margin-bottom: 15px;
	width: 100%;
`;

export const ColumnsGrid = styled.div`
	font-size: 0;
	> div {
		height: 100%;
		box-sizing: border-box;
		float: left;
	}
`;

export const TextColumn = styled.div`
	font-size: 14px;
	color: #4a4a4a;
	width: 76%;
	padding-right: 40px;
`;

export const EventsColumn = styled.div`
	font-size: 16px;
	width: calc(24% - 1px);
	border-left: 1px solid rgba(0, 0, 0, 0.18);
	padding: 11px 0 0 17px;
`;

export const EventsTitle = styled.div`
	font-size: 18px;
	color: #555555;
	font-family: Assistant;
	border-bottom: 2px solid #eeeeee;
`;

export const EventsList = styled.ul`
	padding: 6px 0;
	margin: 0;
`;

export const EventElementItem = styled.li`
	list-style: none;
	margin-bottom: 17px;
	> div {
		display: inline-block;
		vertical-align: middle;
	}
`;

export const EventNumber = styled.div<{ number: number; polarity: Polarity }>`
	height: 26px;
	width: 26px;
	border-radius: 100%;
	${({ polarity }) => {
		switch (polarity) {
			case Polarity.positive:
				return 'background-color: rgba(76,175,80,0.6);';
			case Polarity.negative:
				return 'background-color: #F44336;';
			default:
				return `background-color: ${Colors.blue};`;
		}
	}}
	color: #fff;
	text-align: center;
	margin-right: 7px;
	line-height: 26px;
	font-size: 12px;
`;

export const EventTitleSection = styled.div`
	font-family: 'Open Sans';
	max-width: 138px;
`;

export const EventCategoryName = styled.div<{ polarity: Polarity }>`
	${({ polarity }) => {
		switch (polarity) {
			case Polarity.positive:
				return 'color: #A3A3A3;';
			case Polarity.negative:
				return 'color: #5C5C5C;';
			default:
				return `color: ${Colors.darkGray};`;
		}
	}}
	font-size: 13px;
	font-weight: bold;
	&:before {
		content: ${props =>
			props.polarity === Polarity.positive
				? `url('${Images.arrowPrintPositive}')`
				: props.polarity === Polarity.negative && `url('${Images.arrowPrintNegative}')`};
		top: ${props => (props.polarity === 1 ? 0 : 1)}px;
		position: relative;
		margin-right: 3px;
	}
`;

export const Page = styled.div<{ pageHeight?: number }>`
	height: ${props => (props.pageHeight ? props.pageHeight + 'px' : 'auto')};
	&:first-child {
		height: ${props => (props.pageHeight ? props.pageHeight - 234 + 'px' : 'auto')};
	}
	&:last-child {
		height: ${props => (props.pageHeight ? props.pageHeight - 4 + 'px' : 'auto')};
	}
`;

export const EventTypeName = styled.div<{ polarity: Polarity }>`
	${({ polarity }) => {
		switch (polarity) {
			case Polarity.positive:
				return 'color: #A3A3A3;';
			case Polarity.negative:
				return 'color: #6C6C6C;';
			default:
				return `color: ${Colors.darkGray};`;
		}
	}}
	font-size: 12px;
`;
