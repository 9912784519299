import { useState, useEffect } from 'react';
import _ from 'lodash';
import { useRecoilValue, useRecoilState } from 'recoil';
import userFeaturesState from '../recoil/userFeatures';
import userAttributesState from '../recoil/userAttributes';

import { FEATURES_TYPE_SPLIT, SPLIT_AUTH_KEY } from '../constants';
import { FeatureDictionary } from '../types/types';

const loadSplits = (
	splitio: any,
	userAttributes: { email: string; domain: string },
	setUserFeatures: (features: FeatureDictionary) => void
) => {
	const splitSdk = splitio({
		core: {
			authorizationKey: SPLIT_AUTH_KEY,
			key: userAttributes.email,
			trafficType: 'user'
		},
		startup: {
			readyTimeout: 30
		}
	});
	const client = splitSdk.client();
	client.on(client.Event.SDK_READY, () => {
		const treatments = client.getTreatments(_.values(FEATURES_TYPE_SPLIT), userAttributes);
		const features: FeatureDictionary = _.chain(treatments)
			.map((t: string, index: string) => [index, t === 'on'])
			.fromPairs()
			.value();
		setUserFeatures(features);
	});
};

export const useFeature = (feature: string): boolean => {
	const [isOn, setIsOn] = useState<boolean>(undefined);
	const [userFeatures, setUserFeatures] = useRecoilState(userFeaturesState);
	const userAttributes = useRecoilValue(userAttributesState);

	useEffect(() => {
		if (userAttributes) {
			if ((window as any).splitio) {
				loadSplits((window as any).splitio, userAttributes, setUserFeatures);
			} else {
				document.addEventListener('splitio-ready', () => {
					loadSplits((window as any).splitio, userAttributes, setUserFeatures);
				});
			}
		}
	}, [userAttributes, setUserFeatures]);

	useEffect(() => {
		if (userFeatures) {
			setIsOn(!!userFeatures[feature]);
		}
	}, [userFeatures, feature]);

	return isOn;
};
