import * as _ from 'lodash';
import { runInAction } from 'mobx';

import AjaxService from './ajax.service';
import UIStore from '../stores/ui.store';

type TgetFunction = (
	params: { [key: string]: any },
	successFunc: (data: any) => void,
	errFunc: (err: any) => void
) => void;
type TpostFunction = (
	params: { [key: string]: any },
	successFunc: (data: any) => void,
	errFunc: (err: any) => void
) => void;

export default class DataService {
	private static instance: DataService;

	static getInstance() {
		return DataService.instance || new DataService();
	}

	private ajaxSrv: AjaxService;

	history: any;

	/* API declarations */
	updateUser: TgetFunction;
	getPortfolioFull: TgetFunction;
	getAllPortfolios: TgetFunction;

	getCompanyDocuments: TgetFunction;
	getCompanyDocumentsWithoutSpinner: TgetFunction;
	getAPIEnv: TgetFunction;
	saveDefaultTokenAndFlowId: TpostFunction;
	getFlows: TgetFunction;
	getFlow: TgetFunction;
	getFormattedTranscript: TgetFunction;
	getDocumentMetaData: TgetFunction;

	getTenKJson: TgetFunction;

	getEventsByUser: TgetFunction;
	createEvent: TgetFunction;
	deleteEvent: TgetFunction;
	getEventTypes: TgetFunction;
	getBackOfficeEventTypes: TgetFunction;
	updateBackOfficeEvent: TgetFunction;
	addBackOfficeEvent: TgetFunction;
	deleteBackOfficeEvent: TgetFunction;
	getAllOrganizationsBackoffice: TgetFunction;
	updateOrganization: TgetFunction;
	addOrganization: TgetFunction;
	deleteOrganization: TgetFunction;
	getAllCategoriesBackoffice: TgetFunction;
	updateCategory: TgetFunction;
	addCategory: TgetFunction;
	deleteCategory: TgetFunction;
	getAllCollections: TgetFunction;
	updateCollection: TgetFunction;
	addCollection: TgetFunction;
	deleteCollection: TgetFunction;
	getAllEvents: TgetFunction;
	updateEventStatus: TgetFunction;
	updateUserEvent: TgetFunction;
	sendVerificationEmail: TgetFunction;
	searchEvents: TgetFunction;
	getSearchEventsForDocument: TgetFunction;

	userActionSearchMatchesClicked: TgetFunction;
	userActionTranscriptOpened: TgetFunction;
	userActionCompanyAdded: TgetFunction;

	salesforceHandleContact: TgetFunction;

	getAllTrendingSearches: TgetFunction;

	getUserFeaturePermissions: TgetFunction;

	uploadBloombergFile: TpostFunction;
	cancelUploadBloombergFile: TgetFunction;

	saveDevConfig: TgetFunction;
	getDevConfigurations: TgetFunction;

	initAppEnv: TgetFunction;

	searchCompanies: TgetFunction;

	getUserPortfolio: TgetFunction;
	getUserPortfolioFull: TgetFunction;

	updateUserCompanies: TgetFunction;
	addCompanyToUserPortfolios: TgetFunction;
	deleteUserCompany: TpostFunction;

	createNewPortfolio: TpostFunction;
	renamePortfolio: TpostFunction;
	erasePortfolio: TpostFunction;

	getAllUserCompanies: TgetFunction;
	getPortfolioUserCompanies: TgetFunction;

	getRawArticle: TgetFunction;
	getCompaniesData: TgetFunction;

	constructor(history?: any, authStore?: any) {
		if (DataService.instance) {
			return DataService.instance;
		}

		DataService.instance = this;

		this.history = history;

		this.ajaxSrv = new AjaxService(authStore);

		this.getPortfolioFull = this.ajaxGet('getPortfolioFull', '/get_portfolio', false);
		this.getAllPortfolios = this.ajaxGet('getAllPortfolios', '/get_all_portfolios', true, ['/']);

		this.getCompanyDocuments = this.ajaxGet('get_company', '/company_docs');
		this.getCompanyDocumentsWithoutSpinner = this.ajaxGet('get_company', '/company_docs', false);

		this.updateUser = this.ajaxGet('updateUser', '/update_user');

		this.getAPIEnv = this.ajaxGet('getAPIEnv', '/get_api_env', true, ['/']);
		this.saveDefaultTokenAndFlowId = this.ajaxGet(
			'saveDefaultTokenAndFlowId',
			'/save_default_token_and_flow_id'
		);

		this.getFlows = this.ajaxGet('getFlows', '/get_flows', true, ['/']);
		this.getFlow = this.ajaxGet('getFlow', '/get_flow');

		this.getFormattedTranscript = this.ajaxGet(
			'getFormattedTranscript',
			'/get_formatted_transcript'
		);
		this.getDocumentMetaData = this.ajaxGet(
			'getDocumentMetaData',
			'/get_document_meta_data',
			false
		);

		this.getTenKJson = this.ajaxGet('getTenK', '/get_ten_k');
		// this.getTenKNew = this.ajaxGet('getTenKJsonNew', '/get_ten_k_new');

		this.getEventsByUser = this.ajaxGet('getEventsByUser', '/get_events_by_user');
		this.createEvent = this.ajaxGet('createEvent', '/create_event');
		this.deleteEvent = this.ajaxGet('deleteEvent', '/delete_event');
		this.getEventTypes = this.ajaxGet('getEventTypes', '/get_event_types', false);
		this.getBackOfficeEventTypes = this.ajaxGet(
			'getBackOfficeEventTypes',
			'/get_backoffice_event_types'
		);
		this.updateBackOfficeEvent = this.ajaxGet('updateBackOfficeEvent', '/update_backoffice_event');
		this.addBackOfficeEvent = this.ajaxGet('addBackOfficeEvent', '/add_backoffice_event');
		this.deleteBackOfficeEvent = this.ajaxGet('deleteBackOfficeEvent', '/delete_backoffice_event');
		this.addOrganization = this.ajaxGet('addOrganization', '/add_organization');
		this.updateOrganization = this.ajaxGet('updateOrganization', '/update_organization');
		this.deleteOrganization = this.ajaxGet('deleteOrganization', '/delete_organization');
		this.getAllOrganizationsBackoffice = this.ajaxGet(
			'getAllOrganizationBackOffice',
			'/get_all_organizations_backoffice'
		);
		this.addCategory = this.ajaxGet('addCategory', '/add_category');
		this.updateCategory = this.ajaxGet('updateCategory', '/update_category');
		this.deleteCategory = this.ajaxGet('deleteCategory', '/delete_category');
		this.getAllCategoriesBackoffice = this.ajaxGet(
			'getAllCategoriesBackoffice',
			'/get_all_categories_backoffice'
		);

		this.addCollection = this.ajaxGet('addCollection', '/add_collection');
		this.updateCollection = this.ajaxGet('updateCollection', '/update_collection');
		this.deleteCollection = this.ajaxGet('deleteCollection', '/delete_collection');
		this.getAllCollections = this.ajaxGet('getAllCollections', '/get_all_collections');

		this.getAllEvents = this.ajaxGet('getAllEvents', '/get_all_events');
		this.updateEventStatus = this.ajaxGet('updateEventStatus', '/update_event_status');
		this.updateUserEvent = this.ajaxGet('updateUserEvent', '/update_user_event');

		this.sendVerificationEmail = this.ajaxGet(
			'sendVerificationEmail',
			'/send_verification_mail',
			false
		);
		this.searchEvents = this.ajaxGet('searchEvents', '/search_events');
		this.getSearchEventsForDocument = this.ajaxGet(
			'getSearchEventsForDocument',
			'/get_search_events_for_document'
		);

		this.userActionSearchMatchesClicked = this.ajaxGet(
			'userActionSearchMatchesClicked',
			'/user_action_search_matches_clicked',
			false
		);
		this.userActionTranscriptOpened = this.ajaxGet(
			'userActionTranscriptOpened',
			'/user_action_transcript_opened',
			false
		);
		this.userActionCompanyAdded = this.ajaxGet(
			'userActionCompanyAdded',
			'/user_action_company_added',
			false
		);

		this.salesforceHandleContact = this.ajaxGet(
			'salesforceHandleContact',
			'/salesforce_handle_contact',
			false
		);

		this.getAllTrendingSearches = this.ajaxGet(
			'getAllTrendingSearches',
			'/get_all_trending_searches',
			false
		);
		this.getUserFeaturePermissions = this.ajaxGet(
			'getUserFeaturesEligibility',
			'/get_user_features_eligibility',
			true,
			['/']
		);

		this.uploadBloombergFile = this.ajaxPost(
			'uploadBloombergFile',
			'/upload_bloomberg_file',
			false
		);
		this.cancelUploadBloombergFile = this.ajaxGet(
			'uploadBloombergFile',
			'/cancel_upload_bloomberg_file',
			false
		);

		this.saveDevConfig = this.ajaxGet('saveDevConfig', '/save_dev_config');
		this.getDevConfigurations = this.ajaxGet('getDevConfigurations', '/get_dev_configurations');

		this.initAppEnv = this.ajaxGet('initAppEnv', '/init_app_env', true, ['/']);

		this.searchCompanies = this.ajaxGet('searchCompanies', '/search_companies', false);

		this.getUserPortfolio = this.ajaxGet('portfolio', '/portfolio', true, [
			'/',
			'/portfolio/[0-9]+'
		]);
		this.getUserPortfolioFull = this.ajaxGet('portfolio', '/portfolio', false);

		this.updateUserCompanies = this.ajaxGet('updateUserCompanies', '/add_portfolio_company');
		this.addCompanyToUserPortfolios = this.ajaxGet(
			'addCompanyToUserPortfolios',
			'/add_company_to_multiple_portfolios'
		);
		this.deleteUserCompany = this.ajaxPost('deleteUserCompany', '/remove_portfolio_company');

		this.createNewPortfolio = this.ajaxPost('createNewPortfolio', '/create_portfolio');
		this.renamePortfolio = this.ajaxPost('renamePortfolio', '/rename_portfolio');
		this.erasePortfolio = this.ajaxPost('erasePortfolio', '/delete_portfolio');
		this.getAllUserCompanies = this.ajaxGet('getAllUserCompanies', '/all_user_companies');
		this.getPortfolioUserCompanies = this.ajaxGet(
			'getPortfolioUserCompanies',
			'/get_portfolio_user_companies',
			false
		);

		this.getRawArticle = this.ajaxGet('getRawArticle', '/raw_article');

		this.getCompaniesData = this.ajaxGet('getCompaniesData', '/companies_data_by_factset_ids');
	}

	private handleSpinnerIn = (routes: string[]): boolean =>
		_.some(routes, (route: string) =>
			new RegExp(`^${route}$`).test(this.history.location.pathname)
		);

	/*
	 * Return a function that will call the given API
	 */
	private ajaxGet(callName: string, url: string, handleSpinner: boolean = true, where?: string[]) {
		return (
			params: { [key: string]: any },
			onSuccess: (data: any) => void,
			onError: (data: any) => void
		) => {
			const overideHandleSpinner =
				where === undefined ? handleSpinner : this.handleSpinnerIn(where);

			runInAction(() => {
				const spinner = UIStore.getInstance();
				if (overideHandleSpinner) {
					spinner.setShowSpinner(true);
				}
			});
			this.ajaxSrv.get(
				url,
				params,
				(res: any) => {
					// const ret = JSON.parse(res['_body']);
					runInAction(() => {
						const spinner = UIStore.getInstance();
						if (overideHandleSpinner) {
							spinner.setShowSpinner(false);
						}
					});
					if (res && res.data) {
						return onSuccess(res.data);
					}
					onSuccess(undefined);
				},
				(err: any) => {
					runInAction(() => {
						const spinner = UIStore.getInstance();
						if (overideHandleSpinner) {
							spinner.setShowSpinner(false);
						}
					});
					onError(err);
				}
			);
		};
	}

	private ajaxPost(callName: string, url: string, handleSpinner: boolean = true) {
		return (params: any, onSuccess: (data: any) => void, onError: (err: any) => void) => {
			runInAction(() => {
				const spinner = UIStore.getInstance();
				if (handleSpinner) {
					spinner.setShowSpinner(true);
				}
			});
			this.ajaxSrv.post(
				url,
				params,
				(res: any) => {
					runInAction(() => {
						const spinner = UIStore.getInstance();
						if (handleSpinner) {
							spinner.setShowSpinner(false);
						}
					});
					if (res && res.data) {
						return onSuccess(res.data);
					}
					onSuccess(undefined);
				},
				err => {
					runInAction(() => {
						const spinner = UIStore.getInstance();
						if (handleSpinner) {
							spinner.setShowSpinner(false);
						}
					});
					onError(err);
				}
			);
		};
	}
}
