import * as React from 'react';
import { Button } from '@amenityllc/amenity-components';

import { MessageModalProps } from '../../types/types';
import AddCompanyIcon from '../../../assets/icons/add_large.svg';
import { MESSAGE_MODAL } from '../../../id.constants';
import Modal from '../../../components/Modal/Modal';
import { Appearance, Sizes } from '../../../components/shared/styles';

const MessageModal: React.FunctionComponent<MessageModalProps> = ({
	open,
	title,
	icon,
	text,
	confirmBtnText,
	confirm,
	closeBtnText,
	close
}) => {
	const renderButtons = () => [
		confirmBtnText && (
			<Button
				key='MESSAGE_MODAL_CONFIRM'
				id={MESSAGE_MODAL.CONFIRM_BTN}
				size={Sizes.medium}
				appearance={Appearance.primary}
				text={confirmBtnText || 'Confirm'}
				onClick={confirm}
			/>
		),
		closeBtnText && (
			<Button
				key='MESSAGE_MODAL_CANCEL'
				id={MESSAGE_MODAL.CANCEL_BTN}
				size={Sizes.medium}
				appearance={Appearance.primary}
				text={closeBtnText || 'Cancel'}
				onClick={close}
			/>
		)
	];

	if (!open) {
		return <div />;
	}
	return (
		<Modal
			icon={icon || AddCompanyIcon}
			title={title}
			text={text}
			isModalOpen={open}
			modalButtons={renderButtons()}
			close={close}
		/>
	);
};

export default MessageModal;
