import * as _ from 'lodash';
import { Event, EventListCategory, Polarity } from '../../types/types';
import { EVENT_CATEGORY_SORT, GENERAL_KEY_DRIVER_NAME } from '../../constants';
import { getEventPolarity } from '../../../components/shared/util';

export const aggregateEventsByCategory = (
	events: Event[],
	previousCategories: EventListCategory[],
	forceExpand: boolean,
	forceCollapse = false,
	selectedEventId?: number
): EventListCategory[] => {
	const categories = _.chain(events)
		.uniqBy(e => e.categoryName)
		.map(e => ({
			name: e.categoryName,
			documentViewDescription: e.documentViewDescription
		}))
		.sortBy('name')
		.value();
	return _.map(
		categories,
		(c: { name: string; documentViewDescription: string }, index: number) => {
			const relevantEvents = _.chain(events)
				.filter((e: Event) => e.categoryName === c.name)
				.sortBy('startIndex')
				.value();
			const previousCategory = _.find(
				previousCategories,
				(pc: EventListCategory) => pc.name === c.name
			);

			const positiveCount = _.filter(
				relevantEvents,
				(event: Event) => getEventPolarity(event.polarity) === Polarity.positive
			).length;
			const negativeCount = _.filter(
				relevantEvents,
				(event: Event) => getEventPolarity(event.polarity) === Polarity.negative
			).length;
			const containsSelectedEvent =
				selectedEventId && _.some(relevantEvents, (e: Event) => e.id === selectedEventId);
			const open =
				!forceCollapse &&
				(forceExpand ||
					containsSelectedEvent ||
					(previousCategory && previousCategory.open) ||
					categories.length === 1);
			return {
				id: index,
				name: c.name,
				positiveCount: positiveCount,
				negativeCount: negativeCount,
				netScore: positiveCount - negativeCount,
				total: positiveCount + negativeCount,
				events: relevantEvents,
				open,
				documentViewDescription: c.documentViewDescription
			};
		}
	);
};

export const aggregateEventsByKeyDrivers = (
	events: Event[],
	previousState: EventListCategory[],
	forceExpand: boolean,
	forceCollapse = false,
	selectedEventId?: number
): EventListCategory[] => {
	let keyDrivers: EventListCategory[] = getAggregatedKeyDrivers(events, selectedEventId);
	_.each(keyDrivers, (keyDriver: EventListCategory, index: number) => {
		const keyDriverPrevState = getKeyDriverPrevState(keyDriver.name, previousState);
		keyDriver.id = index;
		keyDriver.events = _.sortBy(keyDriver.events, 'startIndex');
		keyDriver.open =
			!forceCollapse &&
			(forceExpand ||
				keyDriver.containsSelectedEvent ||
				keyDriverPrevState ||
				keyDrivers.length === 1);
	});
	keyDrivers = _.sortBy(keyDrivers, 'name');
	return keyDrivers;
};

export const getAggregatedKeyDrivers = (
	events: Event[],
	selectedEventId: number
): EventListCategory[] => {
	const reducedEvents: EventListCategory[] = _.reduce(
		events,
		(result: EventListCategory[], event: Event) => {
			const isSelected = selectedEventId && event.id === selectedEventId;
			_.each(event.keyDrivers, keyDriver => {
				const keyDriverIndex = _.findIndex(result, { name: keyDriver });
				if (keyDriverIndex !== -1) {
					result[keyDriverIndex] = updateKeyDriver(
						event,
						keyDriver,
						result[keyDriverIndex],
						isSelected
					);
				} else {
					const newKeyDriver = updateKeyDriver(event, keyDriver, initKeyDriver(), isSelected);
					result.push(newKeyDriver);
				}
			});
			return result;
		},
		[]
	);
	return reducedEvents;
};

export const getKeyDriverPrevState = (
	keyDriverName: string,
	allKeyDrivers: EventListCategory[]
): boolean => {
	const previousKeyDriver = _.find(
		allKeyDrivers,
		(pc: EventListCategory) => pc.name === keyDriverName
	);
	return previousKeyDriver && previousKeyDriver.open;
};

export const initKeyDriver = (): EventListCategory => ({
	id: -1,
	name: '',
	events: [],
	positiveCount: 0,
	negativeCount: 0,
	netScore: 0,
	total: 0,
	containsSelectedEvent: false,
	open: false
});

export const updateKeyDriver = (
	event: Event,
	keyDriver: string,
	original: EventListCategory,
	isSelectedEvent: boolean = false
): EventListCategory => {
	if (!event || !original || !keyDriver) {
		return original || initKeyDriver();
	}
	const positiveCount =
		original.positiveCount + getPolarityValue(event.polarity, Polarity.positive);
	const negativeCount =
		original.negativeCount + getPolarityValue(event.polarity, Polarity.negative);
	const netScore = positiveCount - negativeCount;
	const total = positiveCount + negativeCount;
	const containsSelectedEvent = original.containsSelectedEvent || isSelectedEvent;
	return {
		id: -1,
		name: keyDriver,
		events: [...original.events, event],
		positiveCount,
		negativeCount,
		netScore,
		total,
		containsSelectedEvent,
		open: false
	};
};

export const getPolarityValue = (eventPolarity: any, equalTo: Polarity): number => {
	if (eventPolarity === undefined || equalTo === undefined) {
		return 0;
	}
	return +(getEventPolarity(eventPolarity) === equalTo);
};

export const sortCategories = (
	categories: EventListCategory[],
	filterID: string | number
): EventListCategory[] => {
	let sortedCategories: any[] = [];

	if (categories.length === 0) {
		return [];
	}

	const keyToSortBy = getKeyToSortByDropdownCategories(filterID);

	if (keyToSortBy !== undefined) {
		sortedCategories = _.orderBy(
			categories,
			(category: EventListCategory) => category[keyToSortBy],
			'desc'
		);
	} else {
		const [categoriesWithoutGeneral, generalCategories] = _.partition(
			categories,
			(category: EventListCategory) => category.name !== GENERAL_KEY_DRIVER_NAME
		);
		sortedCategories = _.orderBy(
			categoriesWithoutGeneral,
			(category: EventListCategory) => category.positiveCount,
			'desc'
		);
		sortedCategories = _.union(sortedCategories, generalCategories);
	}

	return sortedCategories;
};

export const getKeyToSortByDropdownCategories = (id: string | number): string => {
	let sortByKey: string;
	switch (id) {
		case EVENT_CATEGORY_SORT[0].id:
			sortByKey = 'positiveCount';
			break;
		case EVENT_CATEGORY_SORT[1].id:
			sortByKey = 'negativeCount';
			break;
		case EVENT_CATEGORY_SORT[2].id:
			sortByKey = 'netScore';
			break;
		case EVENT_CATEGORY_SORT[3].id:
			sortByKey = 'total';
			break;
	}

	return sortByKey;
};
