import * as React from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import * as _ from 'lodash';
import * as Scroll from 'react-scroll';
import Images from '../../shared/images';
import {
	DOCUMENT_SIDEBAR_WIDTH,
	DOCUMENT_SIDEBAR_RIGHT_WIDTH,
	DOCUMENT_SIDEBAR_WIDTH_CLOSED,
	DOCUMENT_SIDEBAR_MARGIN,
	DOCUMENT_RIGHT_OFFSET,
	USER_TRACKING,
	SIDE_BAR_TABS,
	APP_COLORS,
	STORE_GLOBAL,
	STORE_DATA,
	STORE_FEATURES,
	STORE_UI,
	LOCAL_STORAGE_KEYS,
	EVENT_NAVIGATION_PREFIX_TRANSCRIPT,
	SEPARATOR_REGEX,
	DELIMETER_OFFSET,
	EVENT_TOOLTIP_ID
} from '../../constants';
import { getUserLocalTimeFromEST } from '../../helpers.timezone';
import * as Util from '../../services/util.service';
import DataStore from '../../stores/data.store';
import GlobalStore from '../../stores/global.store';
import FeaturesStore from '../../stores/features.store';
import { Event, SelectionData, MatchingSelector, Polarity } from '../../types/types';
import Toolbar from '../../components/Toolbar/toolbar';
import AnnotationPopUp from '../../components/AnnotationPopup/annotationPopup';
import GenericPopup from '../../widgets/GenericPopup/genericPopup';
import { setObject } from '../../services/localstorage.service';
import {
	DocumentDetailsContainer,
	DocumentDetail
} from '../../shared/styled/text-layout-components';
import UserTracking from '../../services/userTracking.service';
import ReactTooltip from 'react-tooltip';
import HighlightTooltip from './highlightTooltip';
import * as DocumentUtil from './document.util';
import AddCompanyContainer from '../../components/AddCompanyBanner/AddCompanyContainer';
import Transcript from '../../components/Transcript/transcript';
import NavigationService from '../../services/navigation.service';
import {
	SubHeader,
	BackButtonContainer,
	BackButtonImg,
	BackButtonTextContainer,
	ToolBarContainer
} from '../pageElements';
import * as localStorageSrv from '../../services/localstorage.service';
import * as ids from '../../../id.constants.js';
import RecentDocumentsDropDown from './recentDocumentsDropDown';
import { Colors } from '../../../components/shared/styles';
import DocumentRightSidebar from '../../components/DocumentSideBar/documentRightSidebar';
import DocumentSideBar from '../../components/DocumentSideBar/documentSideBar';

const UTC = USER_TRACKING.USER_TRACKING_CATEGORIES;
const UTA = USER_TRACKING.USER_TRACKING_ACTIONS;

const Container = styled.div`
	width: 100%;
	height: 100%;
`;

const BodyContainer = styled.div`
	height: calc(100% - 100px);
	background-color: ${Colors.lightGray};
`;

interface LayoutProps {
	isEventsPanelOpened: boolean;
	ffViewerDocumentFilters?: boolean;
	isRightSidePanelOpened?: boolean;
}

const DocumentSectionContainer = styled.div<LayoutProps>`
	height: 100%;
	width: ${({ isEventsPanelOpened, ffViewerDocumentFilters, isRightSidePanelOpened }) => {
		if (ffViewerDocumentFilters) {
			if (isRightSidePanelOpened) {
				return isEventsPanelOpened
					? `calc(100% - ${DOCUMENT_SIDEBAR_WIDTH +
							DOCUMENT_SIDEBAR_RIGHT_WIDTH +
							DOCUMENT_SIDEBAR_MARGIN * 2 -
							DOCUMENT_RIGHT_OFFSET}px)`
					: `calc(100% - ${DOCUMENT_SIDEBAR_RIGHT_WIDTH +
							DOCUMENT_SIDEBAR_MARGIN -
							DOCUMENT_RIGHT_OFFSET}px)`;
			}
			return isEventsPanelOpened
				? `calc(100% - ${DOCUMENT_SIDEBAR_WIDTH +
						DOCUMENT_SIDEBAR_WIDTH_CLOSED +
						DOCUMENT_SIDEBAR_MARGIN * 2 -
						DOCUMENT_RIGHT_OFFSET}px)`
				: `calc(100% - ${DOCUMENT_SIDEBAR_WIDTH_CLOSED +
						DOCUMENT_SIDEBAR_MARGIN -
						DOCUMENT_RIGHT_OFFSET}px)`;
		}
		return isEventsPanelOpened
			? `calc(100% - ${DOCUMENT_SIDEBAR_WIDTH + DOCUMENT_SIDEBAR_MARGIN}px)`
			: `calc(100% - ${DOCUMENT_SIDEBAR_MARGIN}px)`;
	}};
	overflow-y: hidden;
	overflow-x: hidden;
	box-sizing: border-box;
	display: inline-block;
	position: relative;
	background-color: ${Colors.lightGray};
`;

const DocumentInnerContainer = styled.div`
	height: 100%;
	background-color: white;
	margin: 0 ${DOCUMENT_SIDEBAR_MARGIN}px;
	border: 1px solid ${Colors.gray};
	display: flex;
	flex-direction: column;
`;

const DocumentHeaderContainer = styled.div`
	min-height: 50px;
	padding: 20px 0 16px 20px;
	box-sizing: border-box;
	min-width: 550px;
`;

const OpenCloseEventsPanel = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 17px;
	height: 35px;
	cursor: pointer;
	border-radius: 4px;
	border: solid 1px ${APP_COLORS.LIGHT_GRAY_DDE5E7};
	background-color: ${Colors.lightGray};
	position: absolute;
	top: 50%;
	left: 8px;
	border-left: solid 1px ${Colors.lightGray};
	box-shadow: 3px 2px 4px -1px rgba(0, 0, 0, 0.15);
`;

const ArrowOpenCloseEventsPanel = styled.div<LayoutProps>`
	width: 14px;
	height: 21px;
	mask-image: url(${Images.arrowDownDropdown});
	transform: rotate(${props => (props.isEventsPanelOpened ? '90deg' : '-90deg')});
	mask-size: contain;
	mask-position: center;
	-webkit-mask-repeat: no-repeat;
	background-color: ${Colors.blue};
	margin-top: 2px;
`;

interface DocumentTextScrollProps {
	isTranscript: boolean;
}
const DocumentTextScroll = styled.div<DocumentTextScrollProps>`
	height: ${props => (props.isTranscript ? 'calc(100% - 50px)' : 'calc(100% - 42px)')};
	box-sizing: border-box;
	overflow-y: auto;
	padding-left: 40px;
	padding-top: ${props => (props.isTranscript ? '' : '2px')};
`;

interface TranscriptTextProps {
	editMode: boolean;
}
const TranscriptContainer = styled.div<TranscriptTextProps>`
	box-sizing: border-box;
	color: #4a4a4a;
	font-size: 14px;
	line-height: 19px;
	white-space: pre-wrap;
	padding: 0 40px 0 0;
	text-align: justify;
`;

const Sidebar = styled.div<LayoutProps>`
	width: ${DOCUMENT_SIDEBAR_WIDTH}px;
	height: 100%;
	background-color: ${Colors.white};
	border: 1px solid ${Colors.gray};
	overflow-y: hidden;
	box-sizing: border-box;
	display: ${props => (props.isEventsPanelOpened ? 'inline-block' : 'none')};
`;

interface DocumentPageState {
	editMode: boolean;
	isAnnotationPopupOpen: boolean; // annotation popup is open when creating / editing an event
	isOverlappingEventsPopupOpen: boolean; // popup for ovelapping events will open if we mark an already existing event
	isDeletingEventPopupOpen: boolean; // delete popup is open when clicking on the trash button in the tooltip
	addedOrEditedEvent: Event; // highlighted text or edited event is passed using this to annotation pop up
	selectedTab: number;
	isEditingAnnotation: boolean;
	isEditDisabledWarningPopupOpen: boolean;
	currentViewedEventId: number;
	selectedEventId: number;
	openCategoryContainingEvent: boolean;
	navReloadCounter: number;
	loadingEvents: boolean;
	loadingSections: boolean;
	selectedMatch: number;
	totalMatches: number;
	matchingSelectors: MatchingSelector[];
	errorPopup: boolean;
	errorPopupDescription: string;
}

@inject(STORE_DATA, STORE_GLOBAL, STORE_FEATURES, STORE_UI)
@observer
export default class Document extends React.Component<{}, DocumentPageState> {
	scrollContainer;
	flowId;
	documentId;
	navService: NavigationService;
	history: any;

	constructor(props) {
		super(props);
		this.state = {
			editMode: false,
			isAnnotationPopupOpen: false,
			isOverlappingEventsPopupOpen: false,
			isDeletingEventPopupOpen: false,
			addedOrEditedEvent: undefined,
			selectedTab: SIDE_BAR_TABS.KeyDrivers,
			isEditingAnnotation: false, // in popup
			isEditDisabledWarningPopupOpen: false,
			currentViewedEventId: -1,
			selectedEventId: undefined,
			openCategoryContainingEvent: false,
			navReloadCounter: 0,
			loadingEvents: true,
			loadingSections: true,
			selectedMatch: undefined,
			totalMatches: undefined,
			matchingSelectors: [],
			errorPopup: false,
			errorPopupDescription: ''
		};

		this.navService = NavigationService.instance;
		this.history = this.navService.history;
	}

	changeDocumentUrl = (flowId: number, documentId: number) => {
		const pageUrl = `${document.location.origin}/document?flowId=${flowId}&documentId=${documentId}`;
		window.history.replaceState({}, '', pageUrl);
	};

	clearData = () => {
		this.setState({
			loadingEvents: false,
			loadingSections: false
		});
		DocumentUtil.removeHighlightedMatches(ids.SEARCH_HIGHLIGHT_STYLE);
		DocumentUtil.removeHighlightedMatches(ids.SEARCH_SELECTED_HIGHLIGHT_STYLE);
		(this.props[STORE_DATA] as DataStore).clearTranscriptEvents();
		(this.props[STORE_DATA] as DataStore).clearFormattedTranscript();
		const el: any = document.getElementById('DOCUMENT_SEARCH_INPUT');
		if (el) {
			el.value = '';
		}
	};

	changeTranscriptDocument = (document: any) => {
		const { currentFlow, currentCompany, fetchDocument } = this.props[STORE_DATA] as DataStore;
		this.clearData();
		this.changeDocumentUrl(currentFlow.id, document.documentId);
		fetchDocument(currentFlow, currentCompany, document);
	};

	componentDidUpdate() {
		ReactTooltip.rebuild();
	}

	componentDidMount() {
		const params = Util.getParamsFromURL(window.location.href);
		const seqNum = parseInt(params['eventid'] || params['eventId'] || 0);
		if (seqNum) {
			this.scrollToEventByOffset(seqNum);
		}
		document.addEventListener('mouseup', this.handleMouseUp);
	}

	scrollToEventByOffset = (offset: number) => {
		const dataStore: DataStore = this.props[STORE_DATA];
		const TIMEOUT_SEC = 30;
		const scrollToEventInterval = setInterval(() => {
			const foundEvent = _.find(
				dataStore.transcriptEvents,
				(e: Event) => e.startIndexAPI === offset
			);
			if (foundEvent) {
				if (foundEvent.polarity === Polarity.neutral) {
					(this.props[STORE_GLOBAL] as GlobalStore).setShowNeutralExtractions(true);
				}
				this.onEventClicked(foundEvent, true);
				// this.scroll(this.scrollContainer, foundEvent.id);
				// TODO: added this as a backup - sometimes the event wasn't highlighted
				this.changeBackgroundColorOfEventAndEventsWithSameHash(String(foundEvent.id), true);

				clearInterval(scrollToEventInterval);
				clearTimeout(stopIntervalTimeOut);
			}
		}, 1 * 1000);
		const stopIntervalTimeOut = setTimeout(() => {
			console.warn(`clearing interval after ${TIMEOUT_SEC} seconds`);
			clearInterval(scrollToEventInterval);
		}, TIMEOUT_SEC * 1000);
	};

	componentWillUnmount() {
		DocumentUtil.removeHighlightedMatches(ids.SEARCH_HIGHLIGHT_STYLE);
		DocumentUtil.removeHighlightedMatches(ids.SEARCH_SELECTED_HIGHLIGHT_STYLE);
		document.removeEventListener('mouseup', this.handleMouseUp);
	}

	saveEvent = (updatedEvent: Event, isEdit: boolean) => {
		const ut = new UserTracking();

		if (isEdit) {
			if (updatedEvent.isUserDefined) {
				(this.props[STORE_DATA] as DataStore).updateEvent(updatedEvent);
				ut.setEvent(
					UTC.TRANSCRIPT,
					UTA.ANNOTATION_EDITED,
					`Document id: ${updatedEvent.transcriptId},
					Highlight id: ${updatedEvent.id},
					Offset: ${updatedEvent.startIndex} - ${updatedEvent.endIndex}`,
					{ documentName: updatedEvent.documentTitle }
				);
			} else {
				(this.props[STORE_DATA] as DataStore).createEvent(updatedEvent);
				ut.setEvent(
					UTC.TRANSCRIPT,
					UTA.HIGHLIGHT_EDITED,
					`Document id: ${updatedEvent.transcriptId},
					Highlight id: ${updatedEvent.id},
					Offset: ${updatedEvent.startIndex} - ${updatedEvent.endIndex}`,
					{ documentName: updatedEvent.documentTitle }
				);
			}
		} else {
			(this.props[STORE_DATA] as DataStore).createEvent(updatedEvent);
		}
		this.setState({ isAnnotationPopupOpen: false });
		this.clearTextSelections();
	};

	clearTextSelections = () => {
		if (window.getSelection) {
			if (window.getSelection().empty) {
				// Chrome
				window.getSelection().empty();
			} else if (window.getSelection().removeAllRanges) {
				// Firefox
				window.getSelection().removeAllRanges();
			}
		}
	};

	navigateBack = () => {
		if (this.state.editMode) {
			this.setState({ editMode: false });
		}
		const prevRoute = localStorageSrv.getString(LOCAL_STORAGE_KEYS.PREV_VISITED_PAGE);

		if (prevRoute && !prevRoute.includes('events_summary')) {
			this.navService.goToRoot();
		} else {
			this.navService.back();
		}
	};

	toggleEditMode = () => {
		const { currentCompany } = this.props[STORE_DATA] as DataStore;
		if (!currentCompany.isInPortfolio) {
			this.setState({ isEditDisabledWarningPopupOpen: true });
			return;
		}

		const updatedEditMode = !this.state.editMode;

		this.setState({ editMode: updatedEditMode });
	};

	handleMouseUp = () => {
		if (
			!this.state.editMode ||
			window
				.getSelection()
				.toString()
				.trim() === ''
		) {
			return;
		}
		const startFirstParent = document.getSelection().getRangeAt(0).startContainer.parentElement;
		const endFirstParent = document.getSelection().getRangeAt(0).endContainer.parentElement;

		// Currently the Element div which responsible for the scroll on click is the first child of the event container element
		const isValid = DocumentUtil.checkIfHighlightIsValid(startFirstParent, endFirstParent);

		// HOTFIX: removed blocking popup when annotations overlap - M.K 8.7.18
		// const isValid = true;

		const startWordIndices = startFirstParent.getAttribute('data-origin_index_start_end');
		const endWordIndices = endFirstParent.getAttribute('data-origin_index_start_end');
		if (!startWordIndices || !endWordIndices) {
			return;
		}

		const startIndex = JSON.parse(startWordIndices)[0];
		const endIndex = JSON.parse(endWordIndices)[1];

		// Adding +1 to endIndex so user defined events will be compatible to events received from API in
		// terms of index range. System events are inclusive - i.e. if end index is 5, the substring to take
		// from the text is to 5, including.
		const selectedData: SelectionData = {
			fromTo: [startIndex, endIndex + 1],
			text: document.getSelection().toString(),
			valid: isValid
		};
		this.onTextAnnotationMarking(selectedData);
	};

	// When marking text in the transcript, parse the selection data to event
	onTextAnnotationMarking = (selectedData: SelectionData) => {
		const selectionDataEvent = DocumentUtil.getEventFromSelectionData(selectedData);
		this.openAnnotationPopup(selectionDataEvent, selectedData.valid, false);
	};

	openAnnotationPopup = (selectionDataEvent: Event, isValid: boolean, isEdit: boolean) => {
		if (isValid) {
			this.setState({
				addedOrEditedEvent: selectionDataEvent,
				isAnnotationPopupOpen: true,
				isEditingAnnotation: isEdit
			});
		} else {
			this.setState({ isOverlappingEventsPopupOpen: true });
		}
	};

	closeAnnotationPopup = () => {
		this.setState({
			addedOrEditedEvent: undefined,
			isAnnotationPopupOpen: false
		});
		this.clearTextSelections();
	};

	closeOverlappingEventsPopup = () => {
		this.setState({ isOverlappingEventsPopupOpen: false });
		this.clearTextSelections();
	};

	closeEditDisabledWarningPopup = () => {
		this.setState({ isEditDisabledWarningPopupOpen: false });
	};

	closeDeletingEventPopup = () => {
		this.setState({ isDeletingEventPopupOpen: false });
	};

	resetSelectedEvent = (hash: string) => {
		this.changeBackgroundColorOfEventAndEventsWithSameHash(hash, false);
		this.setState({ selectedEventId: undefined });
	};

	onEventClicked = (event: Event, fromLink?: boolean) => {
		const idPrefix = EVENT_NAVIGATION_PREFIX_TRANSCRIPT;
		const lastClickedEvent = (this.props[STORE_GLOBAL] as GlobalStore).navigatedEvent;
		const lastClickedEventId = lastClickedEvent ? lastClickedEvent.id : undefined;
		const lastEventNavigationId =
			lastClickedEventId && lastClickedEventId.toString().startsWith(idPrefix)
				? lastClickedEventId
				: idPrefix + lastClickedEventId;

		if (!event) {
			this.resetSelectedEvent(lastEventNavigationId);
			return;
		}

		const eventNavigationId = idPrefix + event.id;
		const container = this.scrollContainer;

		(this.props[STORE_GLOBAL] as GlobalStore).setNavigatedEvent(event);

		if (fromLink) {
			this.setState({ selectedEventId: event.id, openCategoryContainingEvent: true }, () => {
				// need this so that the category won't be reopened on re-render
				setTimeout(() => {
					const element = document.getElementById(`event-navigation-${event.id}`);
					if (element) {
						element.scrollIntoView({ inline: 'nearest' });
					}
				}, 0);
				this.setState({ openCategoryContainingEvent: false });
			});
		} else {
			this.setState({ selectedEventId: event.id });
		}

		this.changeBackgroundColorOfEventAndEventsWithSameHash(lastEventNavigationId, false);
		this.changeBackgroundColorOfEventAndEventsWithSameHash(eventNavigationId, true);

		const ut = new UserTracking();
		const title = (this.props[STORE_DATA] as DataStore).currentDocumentMetaData.title;
		ut.setEvent(
			UTC.TRANSCRIPT,
			UTA.TRANSCRIPT_EVENT_NAVIGATION_CLICKED,
			`Document ${title}_${eventNavigationId}`,
			{ documentName: title }
		);
		if (fromLink) {
			setTimeout(() => {
				this.scroll(container, eventNavigationId);
			}, 400);
		} else {
			this.scroll(container, eventNavigationId);
		}
	};

	changeBackgroundColorOfEventAndEventsWithSameHash = (
		eventNavigationId: string,
		highlight: boolean
	) => {
		if (!eventNavigationId) {
			return;
		}
		const domElm = document.getElementById(eventNavigationId);
		if (!domElm) {
			return;
		}
		const hashOfNavigatedEvent = domElm.getAttribute('name');
		const amnDomElmsWithSameHash = document.getElementsByName(hashOfNavigatedEvent);
		_.each(amnDomElmsWithSameHash, sameHashDomElm => {
			sameHashDomElm.parentElement.style.backgroundColor = highlight ? '#E7E7E7' : '#FFF';
		});
	};

	onParticipantClicked = (index: number, elementTypeClicked: number) => {
		const elementNavigationId = Util.getElementNavigationId(elementTypeClicked, index);
		const container = this.scrollContainer;

		this.scroll(container, elementNavigationId);
	};

	scroll = (container: any, scrollId: string) => {
		Scroll.scroller.scrollTo(scrollId, {
			duration: 1500,
			offset: -300,
			delay: 0,
			smooth: true,
			container
		});
	};

	printTranscript = () => {
		const { showNeutralExtractions } = this.props[STORE_GLOBAL] as GlobalStore;
		const { formattedTranscript, transcriptEvents, currentDocumentMetaData } = this.props[
			STORE_DATA
		] as DataStore;
		const callTime = new Date(currentDocumentMetaData.publicationDate).toLocaleString('en-US', {
			hour12: true
		});
		const transcriptTime = new Date(currentDocumentMetaData.analysisDate).toLocaleString('en-US', {
			hour12: true
		});
		const transcriptTitle = currentDocumentMetaData.title;

		const filteredEvents = showNeutralExtractions
			? transcriptEvents
			: this.filterEvents(transcriptEvents);

		const events = _.orderBy(filteredEvents, (e: Event) => e.startIndex, 'asc').map(
			(e: Event, index: number) => ({
				...e,
				indexInTranscript: index
			})
		);
		setObject(LOCAL_STORAGE_KEYS.PRINT_DATA, {
			transcript: formattedTranscript,
			events,
			callTime,
			transcriptTime,
			transcriptTitle
		});

		const ut = new UserTracking();
		ut.setEvent(UTC.TRANSCRIPT, UTA.PRINT, `${currentDocumentMetaData.title}`, {
			documentName: currentDocumentMetaData.title
		});

		window.open(`${window.location.origin}/print_transcript`);
	};

	onTooltipButton = (isEdit: boolean, currentTooltipEvent: Event) => {
		const { currentCompany } = this.props[STORE_DATA] as DataStore;
		// Block edit here if company not in portfolio
		if (!currentCompany.isInPortfolio) {
			this.setState({ isEditDisabledWarningPopupOpen: true });
			return;
		}
		if (isEdit) {
			this.onEditEvent(currentTooltipEvent);
		} else {
			this.onDeleteEvent();
		}
	};

	onEditEvent = (currentTooltipEvent: Event) => {
		this.openAnnotationPopup(currentTooltipEvent, true, true);
	};

	onDeleteEvent = () => {
		this.setState({ isDeletingEventPopupOpen: true });
	};

	onConfirmDelete = (currentTooltipEvent: Event) => {
		const ut = new UserTracking();
		const wasSystemEvent = currentTooltipEvent.systemEventId !== '';

		if (currentTooltipEvent.isUserDefined) {
			if (wasSystemEvent) {
				// when deleting annotation that was highlight before - need to update it
				(this.props[STORE_DATA] as DataStore).updateEvent(currentTooltipEvent, wasSystemEvent);
			} else {
				// when deleting annotation - need to remove it from db
				(this.props[STORE_DATA] as DataStore).deleteUserEvent(currentTooltipEvent);
			}

			ut.setEvent(
				UTC.TRANSCRIPT,
				UTA.ANNOTATION_DELETED,
				`Document id: ${currentTooltipEvent.transcriptId},
				Highlight id: ${currentTooltipEvent.id},
				Offset: ${currentTooltipEvent.startIndex} - ${currentTooltipEvent.endIndex}`,
				{ documentName: currentTooltipEvent.documentTitle }
			);
		} else {
			// when deleting highlight - need to create an annotation for it
			(this.props[STORE_DATA] as DataStore).deleteSystemEvent(currentTooltipEvent);
			(this.props[STORE_GLOBAL] as GlobalStore).setEventsNotFound([]);
			ut.setEvent(
				UTC.TRANSCRIPT,
				UTA.HIGHLIGHT_DELETED,
				`Document id: ${currentTooltipEvent.transcriptId},
				Highlight id: ${currentTooltipEvent.id},
				Offset: ${currentTooltipEvent.startIndex} - ${currentTooltipEvent.endIndex}`,
				{ documentName: currentTooltipEvent.documentTitle }
			);
		}
		this.setState({ isDeletingEventPopupOpen: false });
	};

	closePopupAndAddCompanyToPortfolio = () => {
		this.closeEditDisabledWarningPopup();
	};

	searchInTranscript = _.debounce((text: string) => {
		DocumentUtil.removeHighlightedMatches(ids.SEARCH_HIGHLIGHT_STYLE);
		DocumentUtil.removeHighlightedMatches(ids.SEARCH_SELECTED_HIGHLIGHT_STYLE);
		if (!text || text.length < 2 || text.trim().length === 0) {
			this.setState({
				selectedMatch: undefined,
				totalMatches: undefined,
				matchingSelectors: []
			});
			return;
		}
		this.searchInDocumentSendEvent(text);
		const textEndsWithSeparator = new RegExp(`(${SEPARATOR_REGEX.source})$`, 'g').test(text);
		const textStartsWithSeparator = new RegExp(`^(${SEPARATOR_REGEX.source})`, 'g').test(text);
		const allowedCharsInTagAttributes = `[a-zA-Z0-9'"=:\\-\\s=_,%;.#()\\/+]`;
		const allowedCharsInSpan = "[a-zA-Z0-9-'.$%+:()&;[\\]\\/]";
		// eslint-disable-next-line
		const eventStart = `(<\/span><span${allowedCharsInTagAttributes}*><div${allowedCharsInTagAttributes}*><\/div><span id="WORD_IN_PARAGRAGH_[0-9]{1,20}" data-origin_index_start_end="[[0-9]{1,20},[0-9]{1,20}]"><\/span>){0,1}`;
		// eslint-disable-next-line
		const eventEnd = `(<span id="WORD_IN_PARAGRAGH_[0-9]{1,20}" data-origin_index_start_end="[[0-9]{1,20},[0-9]{1,20}]"><\/span><\/span><span${allowedCharsInTagAttributes}*>){0,1}`;
		// eslint-disable-next-line
		const spanOpening = `${eventEnd}${eventStart}\<span id\=\"WORD_IN_PARAGRAGH_[0-9]{1,20}\" data-origin_index_start_end\=\"\[[0-9]{1,20},[0-9]{1,20}\]\"\>`;
		const spanClosing = '</span>';
		const wordSeparator = `${spanClosing}${spanOpening}\\$&\\s{0,1}${spanClosing}${spanOpening}`;
		const prefix = textStartsWithSeparator ? '' : `${spanOpening}${allowedCharsInSpan}*`;
		const postfix = textEndsWithSeparator ? '' : `${allowedCharsInSpan}*${spanClosing}`;
		const textInHtml = `${prefix}${Util.stringToHTMLEntity(text)
			.replace(/[$+()&[\]]/g, '\\$&')
			.replace(SEPARATOR_REGEX, wordSeparator)}${postfix}`;
		const regex = new RegExp(textInHtml, 'gi');
		const html = this.scrollContainer?.innerHTML;
		const matches: any = [];
		let match = regex.exec(html);
		while (match) {
			matches.push(match[0]);
			match = regex.exec(html);
		}
		if (matches.length === 0 && this.state.totalMatches !== 0) {
			this.setState({
				selectedMatch: undefined,
				totalMatches: undefined,
				matchingSelectors: []
			});
			return;
		}
		const words = text.split(new RegExp(`(${SEPARATOR_REGEX.source})`, 'g'));
		const matchingSelectors = DocumentUtil.extractAllMatchingSelectors(matches, _.compact(words));
		DocumentUtil.highlightMatches(matchingSelectors);
		this.selectMatch(matchingSelectors, 0);
		this.setState({
			selectedMatch: 0,
			totalMatches: matches.length,
			matchingSelectors
		});
	}, 200);

	scrollToMatch = (selectedSpans: MatchingSelector[]) => {
		const firstElement = document.querySelectorAll(selectedSpans[0].selector)[0];
		if (!firstElement) {
			return;
		}
		setTimeout(() => {
			firstElement.scrollIntoView({ block: 'center' });
		}, 0);
	};

	selectMatch = (matchingSelectors: MatchingSelector[], index: number) => {
		const selectedSpans = _.filter(matchingSelectors, match => match.matchIndex === index);
		DocumentUtil.highlightSelectedMatch(selectedSpans);
		if (selectedSpans.length > 0) {
			this.scrollToMatch(selectedSpans);
		}
	};

	navigateSearchInTranscript = (direction: number) => {
		const { selectedMatch, matchingSelectors } = this.state;
		let newIndex = selectedMatch + direction / Math.abs(direction);
		if (newIndex === matchingSelectors.length) {
			newIndex = 0;
		} else if (newIndex < 0) {
			newIndex = matchingSelectors.length - 1;
		}
		this.selectMatch(matchingSelectors, newIndex);
		this.setState({ selectedMatch: newIndex });
	};

	searchInDocumentSendEvent = (text: string) => {
		const ut = new UserTracking();
		const { currentDocumentMetaData } = this.props[STORE_DATA] as DataStore;
		const docTitle = currentDocumentMetaData && currentDocumentMetaData.title;
		ut.setEvent(UTC.DOCUMENT_VIEW, UTA.SEARCH_ON_DOCUMENT, `${docTitle}_${text}_document`);
	};

	toggleErrorPopup = (state: boolean, message?: string) =>
		this.setState({ errorPopup: state, errorPopupDescription: message });

	filterEvents = (events: Event[]) =>
		_.filter(events, (event: Event) => event.polarity !== Polarity.neutral);

	render() {
		const { userFeaturePermissions } = this.props[STORE_FEATURES] as FeaturesStore;
		const {
			currentDocumentMetaData,
			formattedTranscript,
			eventTypes,
			transcriptEvents,
			lastHoveredEvent,
			currentCompany,
			currentFlow,
			companyDocuments,
			currentDocumentType,
			isKeyDriverModel
		} = this.props[STORE_DATA] as DataStore;
		const {
			isEventsPanelOpened,
			toggleEventsPanel,
			showNeutralExtractions,
			isRightSidePanelOpened,
			toggleRightSidePanelOpened
		} = this.props[STORE_GLOBAL] as GlobalStore;
		const { editMode, addedOrEditedEvent, selectedMatch, totalMatches } = this.state;

		const ffViewerDocumentFilters = userFeaturePermissions?.viewer_document_filters;

		const isTranscriptDocument = DocumentUtil.isDocumentTranscriptType(
			currentFlow,
			currentDocumentMetaData,
			currentDocumentType
		);

		const title = currentDocumentMetaData.documentDisplayName || currentDocumentMetaData.title;

		const callTime =
			currentDocumentMetaData.eventDate || currentDocumentMetaData.publicationDate
				? getUserLocalTimeFromEST(
						currentDocumentMetaData.eventDate
							? currentDocumentMetaData.eventDate
							: currentDocumentMetaData.publicationDate
				  )
				: '';

		const transcriptTime = currentDocumentMetaData.analysisDate
			? new Date(currentDocumentMetaData.analysisDate).toLocaleString('en-US', {
					hour12: true
			  })
			: '';
		const publicationTime =
			currentDocumentMetaData.eventDate &&
			currentDocumentMetaData.publicationDate &&
			new Date(currentDocumentMetaData.publicationDate).toLocaleString('en-US', { hour12: true });

		const discussionSection = Util.getDiscussionSection(formattedTranscript.sections);
		const qnaSection = Util.getQNASection(formattedTranscript.sections);

		const filteredEvents = showNeutralExtractions
			? transcriptEvents
			: this.filterEvents(transcriptEvents);

		const leftSidebarTabs = ffViewerDocumentFilters
			? { [SIDE_BAR_TABS.KeyDrivers]: '37%' }
			: {
					[SIDE_BAR_TABS.KeyDrivers]: '50%',
					[SIDE_BAR_TABS.Participants]: '50%'
			  };

		const rightSidebarTabs = {
			[SIDE_BAR_TABS.ManagementDiscussion]: '70%',
			[SIDE_BAR_TABS.QnA]: '30%'
		};

		const currentCompanyDocuments =
			currentCompany && currentCompany.id && companyDocuments[currentCompany.id];
		return (
			<Container>
				<SubHeader isTwoLines={false}>
					<BackButtonContainer>
						<BackButtonImg
							id='DOCUMENT_PAGE_NAVIGATE_BACK'
							onClick={this.navigateBack}
							src={Images.backIcon}
							size={15}
						/>
						<BackButtonTextContainer>
							<RecentDocumentsDropDown
								companyDocuments={currentCompanyDocuments ? currentCompanyDocuments.data : []}
								currentCompany={currentCompany}
								documentFlow={currentFlow}
								isTranscriptDocument
								documentTitle={title}
								currentDocumentId={currentDocumentMetaData.documentId}
								changeDocument={this.changeTranscriptDocument}
							/>
						</BackButtonTextContainer>
					</BackButtonContainer>
					<ToolBarContainer>
						<Toolbar
							toggleEditMode={this.toggleEditMode}
							printTranscript={this.printTranscript}
							searchText={this.searchInTranscript}
							navigateSearch={this.navigateSearchInTranscript}
							editMode={editMode}
							printLabel={'Print'}
							disablePrint={false}
							disableEdit={isKeyDriverModel}
							selectedMatch={selectedMatch + 1}
							totalMatches={totalMatches}
							isKeyDriverModel={isKeyDriverModel}
						/>
					</ToolBarContainer>
				</SubHeader>
				<BodyContainer>
					<Sidebar isEventsPanelOpened={isEventsPanelOpened}>
						<DocumentSideBar
							initialTab={SIDE_BAR_TABS.KeyDrivers}
							events={filteredEvents}
							discussionSection={discussionSection}
							qnaSection={qnaSection}
							onParticipantElementClicked={this.onParticipantClicked}
							onEventSelectionList={event => this.onEventClicked(event)}
							selectedEventId={this.state.selectedEventId}
							openCategoryContainingEvent={this.state.openCategoryContainingEvent}
							keyDriverModel={isKeyDriverModel}
							elementIdPrefix={EVENT_NAVIGATION_PREFIX_TRANSCRIPT}
							tabs={leftSidebarTabs}
							showSort
						/>
					</Sidebar>
					<DocumentSectionContainer
						isEventsPanelOpened={isEventsPanelOpened}
						ffViewerDocumentFilters={ffViewerDocumentFilters}
						isRightSidePanelOpened={isRightSidePanelOpened}
					>
						<DocumentInnerContainer>
							<DocumentHeaderContainer>
								<DocumentDetailsContainer>
									{callTime && <DocumentDetail>Event Time: {callTime}</DocumentDetail>}
									{transcriptTime && (
										<DocumentDetail>Analysis Time: {transcriptTime}</DocumentDetail>
									)}
									{publicationTime && (
										<DocumentDetail>Publish Time: {publicationTime}</DocumentDetail>
									)}
								</DocumentDetailsContainer>
							</DocumentHeaderContainer>
							<DocumentTextScroll
								id='TRANSCRIPT_DOCUMENT_CONTAINER'
								isTranscript={isTranscriptDocument}
								ref={node => (this.scrollContainer = node)}
							>
								<TranscriptContainer editMode={editMode}>
									{formattedTranscript && (
										<Transcript
											text={formattedTranscript}
											delimiterOffset={DELIMETER_OFFSET}
											events={filteredEvents}
											editMode={editMode}
										/>
									)}
								</TranscriptContainer>
								<OpenCloseEventsPanel onClick={() => toggleEventsPanel()}>
									<ArrowOpenCloseEventsPanel isEventsPanelOpened={isEventsPanelOpened} />
								</OpenCloseEventsPanel>
							</DocumentTextScroll>
						</DocumentInnerContainer>
						<ReactTooltip
							id={EVENT_TOOLTIP_ID}
							className='customeTheme'
							place='bottom'
							type='light'
							offset={{ bottom: 3, right: 0 }}
							delayHide={500}
							delayShow={200}
							getContent={dataTip => (
								<HighlightTooltip
									id={ids.DOCUMENT_PAGE.EVENT_HOVER + (lastHoveredEvent ? lastHoveredEvent.id : '')}
									showActions={isTranscriptDocument && !isKeyDriverModel}
									onEditEvent={() => this.onTooltipButton(true, lastHoveredEvent)}
									onDeleteEvent={() => this.onTooltipButton(false, undefined)}
									title={dataTip}
								/>
							)}
						/>
					</DocumentSectionContainer>
					{ffViewerDocumentFilters && (
						<DocumentRightSidebar
							isOpen={isRightSidePanelOpened}
							onToggle={toggleRightSidePanelOpened}
							label={`Management Discussion  |  Questions & Answers`}
						>
							<DocumentSideBar
								initialTab={SIDE_BAR_TABS.ManagementDiscussion}
								discussionSection={discussionSection}
								qnaSection={qnaSection}
								keyDriverModel={isKeyDriverModel}
								onParticipantElementClicked={this.onParticipantClicked}
								tabs={rightSidebarTabs}
							/>
						</DocumentRightSidebar>
					)}
				</BodyContainer>
				{this.state.isAnnotationPopupOpen && (
					<AnnotationPopUp
						closePopup={() => this.closeAnnotationPopup()}
						confirmPopup={this.saveEvent}
						eventData={addedOrEditedEvent}
						eventCategories={Util.getUniqCategories(eventTypes)}
						isInEdit={this.state.isEditingAnnotation}
						textOffset={21 + 1}
					/>
				)}
				{this.state.isOverlappingEventsPopupOpen && (
					<GenericPopup
						isOpen={this.state.isOverlappingEventsPopupOpen}
						message={'The selected text is overlapping an existing highlight'}
						acceptCallback={() => this.closeOverlappingEventsPopup()}
						acceptButtonText={'OK'}
					/>
				)}
				{this.state.isDeletingEventPopupOpen && (
					<GenericPopup
						isOpen={this.state.isDeletingEventPopupOpen}
						message={'Are you sure you want to unmark the highlight?'}
						dismissCallback={() => this.closeDeletingEventPopup()}
						dismissButtonText={'Cancel'}
						acceptCallback={() => this.onConfirmDelete(lastHoveredEvent)}
						acceptButtonText={'Unmark'}
						clickOutsideCallback={() => this.closeDeletingEventPopup()}
					/>
				)}
				{this.state.isEditDisabledWarningPopupOpen && (
					<GenericPopup
						isOpen={this.state.isEditDisabledWarningPopupOpen}
						message={'To create an annotation add the company to your watchlist'}
						dismissCallback={() => this.closeEditDisabledWarningPopup()}
						acceptCallback={() => this.closePopupAndAddCompanyToPortfolio()}
						dismissButtonText={'Cancel'}
						acceptButtonText={'Add to watchlist'}
						clickOutsideCallback={() => this.closeEditDisabledWarningPopup()}
					/>
				)}
				{this.state.errorPopup && (
					<GenericPopup
						isOpen={this.state.errorPopup}
						message={this.state.errorPopupDescription}
						acceptCallback={() => this.toggleErrorPopup(false)}
						acceptButtonText={'OK'}
						dismissCallback={() => {
							Util.openContact();
							this.toggleErrorPopup(false);
						}}
						dismissButtonText={'Contact us'}
						clickOutsideCallback={() => this.toggleErrorPopup(false)}
					/>
				)}
				<AddCompanyContainer company={currentCompany} />
			</Container>
		);
	}
}
